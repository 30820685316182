import {
  PUSH_NOTIFICATIONS_DEFINITION,
  PUSH_NOTIFICATIONS_CTA,
  PUSH_NOTIFICATIONS_IMAGE_URL,
  RESET_STATE
} from "../../action/actionType";

const initialState = {
  title: "",
  body: "",
  imageUrl: "",
  callToAction: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PUSH_NOTIFICATIONS_DEFINITION:
      return {
        ...state,
        title: action.payload.title,
        body: action.payload.body
      };

    case PUSH_NOTIFICATIONS_CTA:
      return {
        ...state,
        callToAction: action.payload
      };

    case PUSH_NOTIFICATIONS_IMAGE_URL:
      return {
        ...state,
        imageUrl: action.payload
      };

    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};
