import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  fetchAdsRewards,
  clearStateValue
} from "../../../../redux/action/PointsSystem/adsListViewActions";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// redux actions
// core components
import Moment from "moment";
import { Link } from "react-router-dom";
import Table from "../../../../components/Table/Table.js";
import CardBody from "../../../../components/Card/CardBody.js";

import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import ActionButtons from "../../../ActionButtons/ActionButtons";

import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const AdsItem = props => {
  const [pageNum, setPageNum] = useState(1);

  const {
    location,
    match,
    rewardsItems,
    fetchAdsRewards,
    clearStateValue,
    availableError
  } = props;

  const adsItem = location.state || [];

  const classes = useStyles();

  useEffect(() => {
    if (
      Object.keys(rewardsItems).length === 0 ||
      Object.keys(rewardsItems).length !== 0
    ) {
      if (pageNum >= 1) {
        fetchAdsRewards(adsItem.id, pageNum);
      }
    }
    clearStateValue();
  }, [pageNum]);

  let adsRewards = [];
  if (Object.keys(rewardsItems).length !== 0) {
    adsRewards = rewardsItems.items.map((reward, index) => {
      const parasDate = Moment(reward.createdDate).format("DD/MM/YYYY");
      let validateDateNow =
        parasDate ===
        Moment(Date.now())
          .utc()
          .format("DD/MM/YYYY");

      let actionEdit;

      if (index === 0 && validateDateNow) {
        actionEdit = (
          <ActionButtons
            item={reward}
            adsId={reward.id}
            adsItem={adsItem}
            adsText
            viewType="Edit"
            deleteButton={false}
            editButton={true}
          />
        );
      }
      return [
        parasDate,
        reward.estimatedValue,
        reward.actualValue,
        reward.compensationPercentage,
        actionEdit
      ];
    });
  }

  const handleNext = () => {
    if (!rewardsItems.isLast) {
      setPageNum(pageNum + 1);
    }
  };

  const handlePrevious = () => {
    if (pageNum !== 1) {
      setPageNum(pageNum - 1);
    }
  };

  if (availableError) {
    throw new Error();
  }

  return (
    <GridContainer id="list">
      <div className={classes.cardLinkContainer}>
        <Link
          to={{
            pathname: `${match.url}/Add`,
            state: { viewType: "Add", adsItem },
            id: adsItem.id
          }}
          className={classes.cardLinkAdd}
        >
          Add Reward
        </Link>
      </div>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Ads {adsItem.type}</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="warning"
              tableHead={[
                "Date",
                "Estimated Reward",
                "Actual Reward",
                "compensation Percentage",
                "Actions"
              ]}
              tableData={adsRewards}
            ></Table>
          </CardBody>
          <div
            style={{
              display: "flex",
              alignItems: "ceneter",
              justifyContent: "center"
            }}
          >
            <Button
              type="button"
              onClick={handlePrevious}
              disabled={pageNum === 1}
              color="primary"
            >
              Previous
            </Button>
            <Button
              type="button"
              onClick={handleNext}
              color="primary"
              disabled={rewardsItems.isLast}
            >
              Next
            </Button>
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    rewardsItems: state.AdsListView.rewardsItems,
    availableError: state.availableError.availableError
  };
};

export default connect(
  mapStateToProps,
  { fetchAdsRewards, clearStateValue }
)(AdsItem);

AdsItem.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  fetchAdsRewards: PropTypes.func,
  clearStateValue: PropTypes.func,
  rewardsItems: PropTypes.object,
  availableError: PropTypes.bool
};
