const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  marginBlock: {
    marginBlockEnd: "16px",
    marginBlockStart: "24px"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: theme.spacing(0),
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "46%"
  },
  textFieldFile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "130px",
    lineHeight: "40px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: "4px",
    textAlign: "center",
    borderRadius: "5px",
    color: "#fff",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)"
  }
});
export default styles;
