import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";

const headCells = [
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: true,
    label: "User Numbers",
    paddingLeft: 5
  },
  {
    id: "operator",
    numeric: false,
    disablePadding: true,
    label: "Operator",
    paddingLeft: 0
  },
  {
    id: "transactionValue",
    numeric: false,
    disablePadding: true,
    label: "Transaction Value",
    paddingLeft: 0
  },
  {
    id: "success",
    numeric: false,
    disablePadding: true,
    label: "Status",
    paddingLeft: 0
  },
  {
    id: "Edit",
    numeric: false,
    disablePadding: true,
    label: "Edit",
    paddingLeft: 0,
    textAlign: "center"
  }
];

const TableHeader = React.memo(props => {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              paddingLeft: headCell?.paddingLeft,
              textAlign: headCell?.textAlign
            }}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

TableHeader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default TableHeader;
