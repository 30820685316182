import * as redemptionService from "../../../../services/redemption";

export const GET_CONFIGURATIONS = "get configurations";

export const getConfigurations = country => dispatch =>
  redemptionService.getMeta(country).then(data => {
    dispatch({
      type: GET_CONFIGURATIONS,
      payload: data
    });
  });
