import { waslaRewardBaseurl, authURL } from "../../../components/config";

import {
  FETCH_BATCHES_LIST,
  SET_LOADING_BATCHES_LIST,
  RESET_BATCH_STATE,
  OPEN_SNACK_BAR
} from "../actionType";

export const fetchBatchesList = (id, pageNum) => async dispatch => {
  dispatch({ type: SET_LOADING_BATCHES_LIST, payload: true });

  await waslaRewardBaseurl
    .get(`/external/project/${id}/batch?pageNo=${pageNum || 1}&pageSize=10`)
    .then(res => {
      dispatch({ type: SET_LOADING_BATCHES_LIST, payload: false });

      dispatch({
        type: FETCH_BATCHES_LIST,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING_BATCHES_LIST, payload: false });
      dispatch({ type: OPEN_SNACK_BAR, payload: true });
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_SERVER_PROGRESS",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_SERVER_PROGRESS",
          payload: error?.response?.data?.error || "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_SERVER_PROGRESS",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const fetchProgressRecharges = (
  projectId,
  batchId
) => async dispatch => {
  await waslaRewardBaseurl
    .get(`/external/project/${projectId}/batch/${batchId}/progress`)
    .then(res => {
      dispatch({ type: "PROGRESS_SUCCESS", payload: res.data });
    })
    .catch(error => {
      dispatch({ type: OPEN_SNACK_BAR, payload: true });
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_SERVER_PROGRESS",
          payload: error?.response?.data?.error || "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_SERVER_PROGRESS",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const resetBatchState = () => {
  return {
    type: RESET_BATCH_STATE
  };
};
