import authenticatedHttpClientService from "../authenticated-http-client";

export const updateRemoteConfig = config =>
  authenticatedHttpClientService.post(
    `${process.env.REACT_APP_REMOTE_CONFIG_BASE_URL}`,
    config,
    {
      headers: { "Content-Type": "application/json" }
    }
  );
