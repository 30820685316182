import { UPDATE_COUNTRY } from "../../actions/country";

const initialState = "";

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COUNTRY:
      return action.payload;
    default:
      return state;
  }
};
