import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

// core components
import EditRechargeSteps from "./stepperFaild";

import { clearRewardApiState } from "../../../redux/action/WaslaRewards/UsersLogsActions";
import { resetRechargeState } from "../../../redux/action/WaslaRewards/RechargeUsersActions";
import { resetEditRechargeState } from "../../../redux/action/WaslaRewards/EditUsersRechargeAction";
import { fetchRechargesFailedList } from "../../../redux/action/WaslaRewards/EditUsersRechargeAction";

import CustomModal from "../Modal";

function EditRechargeModal(props) {
  const {
    clearRewardApiState,
    open,
    closeModal,
    resetRechargeState,
    resetEditRechargeState,
    fetchRechargesFailedList
  } = props;
  const location = useLocation();

  const batchId = location?.state?.batchId;

  useEffect(() => {
    resetRechargeState();
    return () => {
      clearRewardApiState();
      resetEditRechargeState();
      fetchRechargesFailedList({ batchId, projectId: location?.state?.id });
    };
  }, []);

  return (
    <CustomModal
      open={open}
      title="Retry Failed"
      close={props.closeModal}
      style={{ width: 620, padding: "16px 14px 24px" }}
    >
      <EditRechargeSteps onCloseModal={closeModal} />
    </CustomModal>
  );
}

const mapStateToProps = state => {
  return {
    openManualModal: state.waslaRewards.openModal
  };
};
export default connect(
  mapStateToProps,
  {
    clearRewardApiState,
    resetRechargeState,
    resetEditRechargeState,
    fetchRechargesFailedList
  }
)(EditRechargeModal);

EditRechargeModal.propTypes = {
  clearRewardApiState: PropTypes.func,
  open: PropTypes.bool,
  resetRechargeState: PropTypes.func,
  fetchRechargesFailedList: PropTypes.func
};
