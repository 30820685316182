const initialState = {
  list: [],
  errCashbackConfigPoints: false,
  cashbackItem: {},
  redirect: false,
  errUpdateCashbackConfig: false,
  historyList: [],
  historyError: false,
  historySuccess: false,
  hasNext: false,
  currentId: "",
  historyLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CASHBACK_CONFIG":
      return {
        ...state,
        list: action.payload,
        errCashbackConfigPoints: false
      };
    case "ERROR_FETCH_CASHBACK_CONFIG":
      return {
        ...state,
        errCashbackConfigPoints: true
      };
    case "UPDATE_CASHBACK_CONFIG":
      return {
        ...state,
        cashbackItem: action.payload
      };
    case "UPDATE_CASHBACK_CONFIG_SUCCESS":
      return {
        ...state,
        redirect: true
      };
    case "UPDATE_CASHBACK_CONFIG_ERROR":
      return {
        ...state,
        errUpdateCashbackConfig: true
      };
    case "FETCH_LOADING_HISTORY_CASHBACK":
      return {
        ...state,
        historyLoading: true
      };
    case "FETCH_HISTORY_CASHBACK":
      return {
        ...state,
        historyList: action.payload?.res.data,
        hasNext: action.payload?.res?.meta?.has_next,
        currentId: action.payload.id,
        historySuccess: true,
        historyLoading: false
      };
    case "FETCH_HISTORY_CASHBACK_FAILED":
      return {
        ...state,

        historyError: true,
        historyLoading: false
      };
    case "CLEAR_HISTORY_LIST":
      return {
        ...state,
        historyList: [],
        historyError: false,
        historySuccess: false
      };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
