import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-react/components/headerStyle.js";
import ProgressComponent from "../WaslaRewards/ProgressComponent";
const useStyles = makeStyles(styles);

function Header(props) {
  const { handleLogout } = props;
  const location = useLocation();
  const convertStringToArray = location.pathname.split("/");
  function isSame(value) {
    return convertStringToArray.some(item => item.includes(value));
  }
  const showCatalogue = isSame("batch");
  const classes = useStyles();
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (window.location.href.indexOf(prop.path) !== -1 && prop.isPrivate) {
        name = props.rtlActive ? prop.rtlName : `${prop.name}`;
      }
      return null;
    });
    return name;
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar
      className={classes.appBar + appBarClasses}
      style={{
        zIndex: 1,
        backgroundColor:
          props.roles.length !== 0 &&
          props.roles[0].toLowerCase() === "merchant"
            ? "#fff"
            : "#eee",
        boxShadow:
          "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)"
      }}
    >
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          {!showCatalogue ? (
            <Button
              color="transparent"
              className={classes.title}
              style={{ cursor: "default" }}
            >
              {makeBrand()}
            </Button>
          ) : (
            <ProgressComponent />
          )}
        </div>
        <Hidden smDown implementation="css">
          {props.rtlActive ? (
            <RTLNavbarLinks handleLogout={handleLogout} />
          ) : (
            <AdminNavbarLinks handleLogout={handleLogout} />
          )}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
const mapStateToProps = state => {
  return {
    roles: state.roles.roles
  };
};
export default connect(mapStateToProps)(Header);

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.array
};
