import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setMerchant } from "../../Actions/FetchTargetList";
// core components
import styles from "../../customTable/styles.css";

const useStyles = makeStyles(styles);

const SelectMerchant = ({
  merchantsList,
  setMerchant,
  merchantIdSelected,
  id
}) => {
  const classes = useStyles();

  const handleSelection = event => {
    const selectedValue = event.target.value;
    // Send selected category name to the reducer
    const currentMerchant = merchantsList.find(
      item => item.id === selectedValue
    );

    const MerchantName = selectedValue !== "none" ? currentMerchant.nameEn : "";
    setMerchant(selectedValue, MerchantName);
  };

  return (
    <>
      <TextField
        id="Merchant-name"
        select
        label="Merchant Name"
        className={classes.textField}
        name="merchantId"
        value={merchantIdSelected || id}
        onChange={handleSelection}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="outlined"
        required
      >
        <option value="none">Select an Option</option>
        {merchantsList.map(option => (
          <option key={option.id} value={option.id}>
            {option.nameEn}
          </option>
        ))}
      </TextField>
    </>
  );
};

const mapStateToProps = state => {
  return {
    merchantsList: state.merchantsList.list,
    merchantNameSelected: state.targetList.merchantNameSelected,
    merchantIdSelected: state.targetList.merchantIdSelected
  };
};

export default connect(
  mapStateToProps,
  { setMerchant }
)(SelectMerchant);

SelectMerchant.propTypes = {
  merchantsList: PropTypes.arrayOf(PropTypes.object)
};
