export const UPDATE_PAGE = "update user activities pagination page";
export const UPDATE_LIMIT = "update user activities pagination limit";
export const UPDATE_TOTAL = "update user activities pagination total";

export const updatePage = page => dispatch =>
  dispatch({
    type: UPDATE_PAGE,
    payload: { page }
  });

export const updateLimit = limit => dispatch =>
  dispatch({
    type: UPDATE_LIMIT,
    payload: { limit }
  });

export const updateTotal = total => dispatch =>
  dispatch({
    type: UPDATE_TOTAL,
    payload: { total }
  });
