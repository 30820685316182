import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import withReducer from "../../../../../../store/withReducer";
import MerchantReducers from "../../../../../../main/deals/merchants/add-n-edit/reducers";

import Link from "@mui/material/Link";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

import Avatar from "@mui/material/Avatar";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import FormCard from "../../../../../../components/FormCard";
import FormCardHead from "../../../../../../components/FormCardHead";
import FormCardBody from "../../../../../../components/FormCardBody";
import FormCardBodyContent from "../../../../../../components/FormCardBodyContent";
import FormCardBodyActions from "../../../../../../components/FormCardBodyActions";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

import Editor from "material-ui-editor";

import AddIcon from "@mui/icons-material/Add";
import DeleteImg from "./assets/img/delete.png";

import configurations from "../../../../../../configurations";
import { dealsMerchantsPath } from "../../../../../../main/deals/merchants/paths";

import {
  updateOrder,
  updateNameEn,
  updateNameAr,
  updateLogoUrl,
  updateUrl,
  updateDescriptionEn,
  updateDescriptionAr,
  updateIsPopular,
  updateCountries,
  updateTargetUrls,
  updateTncEn,
  updateTncAr
} from "../../../../../../main/deals/merchants/add-n-edit/actions/local";
import { updateMerchant } from "../../../../../../main/deals/merchants/add-n-edit/actions/origin";

import avatar from "./assets/avatar.svg";
import egypt from "./assets/egypt.png";
import nigeria from "./assets/nigeria.png";
import CheckIcon from "./assets/check.svg";
import UploadContainer from "../../../../../../components/UploadContainer";
import "./style.css";

const TargetUrlRecord = props => {
  const {
    index,
    item,
    avialableCountries,
    deleteItem,
    updateItem,
    deletable
  } = props;

  const getFlag = countryCode => {
    switch (countryCode) {
      case "EG":
        return egypt;
      case "NG":
        return nigeria;
      default:
        return null;
    }
  };

  const handleUrlChange = index => e => {
    updateItem(index, { ...item, url: e.target.value });
  };

  const handleCountryChange = index => e => {
    updateItem(index, { ...item, country: e.target.value });
  };
  const handleDeleteClick = index => e => {
    deleteItem(index);
  };

  const DeleteIcon = () => (
    <img src={DeleteImg} alt="" style={{ width: "20px" }} />
  );

  return (
    <>
      <Grid item xs={5}>
        <FormControl fullWidth>
          <InputLabel
            shrink
            htmlFor={`url-${index}`}
            sx={{ transform: "none" }}
          >
            Enter Domain
          </InputLabel>
          <OutlinedInput
            id={`url-${index}`}
            type="url"
            value={item.url}
            onChange={handleUrlChange(index)}
            label=""
            placeholder="Domain ..."
            required
            disabled={false}
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth>
          <InputLabel
            shrink
            htmlFor={`country-${index}`}
            sx={{ transform: "none" }}
          >
            Country
          </InputLabel>
          <Select
            displayEmpty
            // value={
            //   avialableCountries.find(country => country === item.country)
            //     ? item.country
            //     : avialableCountries.length === 1
            //     ? avialableCountries[0]
            //     : ""
            // }
            value={item.country}
            id={`country-${index}-dropdown`}
            inputProps={{
              id: `country-${index}`
            }}
            MenuProps={{
              id: `country-${index}-menu`
            }}
            onChange={handleCountryChange(index)}
            required
            disabled={avialableCountries.length === 1}
          >
            {avialableCountries.map(avialableCountry => {
              const country = configurations.application.countries.find(
                country => country.code === avialableCountry
              );
              return (
                <MenuItem key={country.code} value={country.code}>
                  <Stack spacing={1} direction="row">
                    <img src={getFlag(country.code)} />
                    <span>{country.name}</span>
                  </Stack>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} alignSelf="end">
        <IconButton
          aria-label="delete"
          onClick={handleDeleteClick(index)}
          disabled={!deletable}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </>
  );
};

const Form = props => {
  const {
    merchant: { origin, local },
    loading,
    sync,
    submitForm,
    operation,
    updateOrder,
    updateNameEn,
    updateNameAr,
    updateLogoUrl,
    updateUrl,
    updateDescriptionEn,
    updateDescriptionAr,
    updateIsPopular,
    updateCountries,
    updateTargetUrls,
    updateTncEn,
    updateTncAr
  } = props;

  const [logoUrlFile, setLogoUrlFile] = useState(null);
  const [engPlaceholder, setEngPlaceholder] = useState(
    "Enter terms & conditions"
  );
  const [arPlaceholder, setArPlaceholder] = useState("الشروط و الاحكام ...");

  const tncEnRef = useRef();
  const tncArRef = useRef();
  const logoUrlElement = useRef(null);

  const handleEditorClick = ref => e => {
    if (ref.current.id === "en") setEngPlaceholder("");
    if (ref.current.id === "ar") setArPlaceholder("");
    if (!ref.current.contains(window.getSelection().baseNode))
      e.stopPropagation();
  };

  useEffect(() => {
    tncEnRef?.current &&
      tncEnRef.current.addEventListener("click", handleEditorClick(tncEnRef));
    tncArRef?.current &&
      tncArRef.current.addEventListener("click", handleEditorClick(tncArRef));
    return () => {
      updateMerchant({
        order: null,
        nameEn: "",
        nameAr: "",
        logoUrl: null,
        url: "",
        descriptionEn: "",
        descriptionAr: "",
        isPopular: false,
        countries:
          configurations.application.countries.length === 1
            ? configurations.application.countries.map(country => country.code)
            : [],
        targetUrls: [],
        tncEn: "",
        tncAr: ""
      });
      updateOrder(1);
      updateNameEn("");
      updateNameAr("");
      updateLogoUrl(null);
      updateUrl("");
      updateDescriptionEn("");
      updateDescriptionAr("");
      updateIsPopular(false);
      updateCountries(
        configurations.application.countries.length === 1
          ? configurations.application.countries.map(country => country.code)
          : []
      );
      updateTargetUrls([
        {
          url: "",
          country:
            configurations.application.countries.length === 1
              ? configurations.application.countries[0].code
              : ""
        }
      ]);
      updateTncEn("");
      updateTncAr("");
      tncEnRef?.current &&
        tncEnRef.current.removeEventListener(
          "click",
          handleEditorClick(tncEnRef)
        );
      tncArRef?.current &&
        tncArRef.current.removeEventListener(
          "click",
          handleEditorClick(tncArRef)
        );
    };
  }, []);

  const getFlag = countryCode => {
    switch (countryCode) {
      case "EG":
        return egypt;
      case "NG":
        return nigeria;
      default:
        return null;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitForm({
      order: local.order,
      nameEn: local.nameEn,
      nameAr: local.nameAr,
      logoUrl: logoUrlFile,
      url: local.url,
      descriptionEn: local.descriptionEn,
      descriptionAr: local.descriptionAr,
      isPopular: local.isPopular,
      countries: local.countries,
      targetUrls: local.targetUrls,
      tncEn: local.tncEn,
      tncAr: local.tncAr
    });
  };

  const handleOrderChange = e => {
    const value = e.target.value.slice(1);
    if (/^([1-9][0-9]*)?$/.test(value)) {
      updateOrder(value);
    }
  };

  const handleNameEnChange = e => {
    updateNameEn(e.target.value);
  };

  const handleNameArChange = e => {
    updateNameAr(e.target.value);
  };

  const handleLogoUrlClick = e => {
    logoUrlElement.current.click();
  };

  const handleLogoUrlChange = e => {
    if (e.target.files && e.target.files[0]) {
      setLogoUrlFile(e.target.files[0]);
      updateLogoUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleUrlChange = e => {
    updateUrl(e.target.value);
  };

  const handleDescriptionEnChange = e => {
    updateDescriptionEn(e.target.value);
  };

  const handleDescriptionArChange = e => {
    updateDescriptionAr(e.target.value);
  };

  const handleIsPopularChange = e => {
    updateIsPopular(e.target.checked);
  };

  const handleCountriesChange = e => {
    updateCountries(e.target.value);
    updateTargetUrls(
      local.targetUrls.map(targetUrl => ({
        ...targetUrl,
        country: e.target.value.length === 1 ? e.target.value[0] : ""
      }))
    );
  };

  const handleAddTargetUrlClick = e => {
    updateTargetUrls([
      ...local.targetUrls,
      {
        url: "",
        country:
          configurations.application.countries.length === 1
            ? configurations.application.countries[0].code
            : ""
      }
    ]);
  };

  const handleDeleteTargetUrl = index => {
    updateTargetUrls(
      local.targetUrls.filter(
        (targetUrl, targetUrlIndex) => targetUrlIndex !== index
      )
    );
  };

  const handleUpdateTargetUrl = (index, item) => {
    updateTargetUrls(
      local.targetUrls.map((targetUrl, targetUrlIndex) =>
        targetUrlIndex === index ? item : targetUrl
      )
    );
  };

  const handleTncEnChange = e => {
    updateTncEn(e.target.value);
  };

  const handleTncEnContentChange = content => {
    updateTncEn(content);
  };

  const handleTncArChange = e => {
    updateTncAr(e.target.value);
  };

  const handleTncArContentChange = content => {
    updateTncAr(content);
  };

  const editorStyle = {
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    lineHeight: "24px",
    fontFamily: "Inter",
    fontSize: "14px",
    color: "#667085",
    overflow: "hidden",
    "& > .MuiBox-root": {
      "& > .MuiGrid-root": {
        margin: 0,
        width: "100%",
        justifyContent: "flex-start",
        padding: "2px 4px",
        backgroundColor: "#F9FAFB",
        color: "#98A2B3",
        "& > .MuiGrid-item": {
          padding: 0,
          "&:nth-of-type(1), &:nth-of-type(4)": {
            display: "none"
          },
          "& .MuiButtonBase-root": {
            padding: "8px",
            fontSize: "10px",
            lineHeight: "16px",
            "&[title='Remove Format'], &[title='Print']": {
              display: "none"
            }
          }
        }
      },
      "& > .MuiContainer-root": {
        padding: "10px 14px"
      }
    }
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <FormCard>
          <FormCardHead variant="underline">
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                color: "#101828"
              }}
            >
              Merchant Details
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#667085"
              }}
            >
              Update or create a merchant
            </Typography>
          </FormCardHead>
          <FormCardBody>
            <FormCardBodyContent>
              <Stack spacing={3}>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="name-en"
                        sx={{ transform: "none" }}
                      >
                        English Title
                      </InputLabel>
                      <OutlinedInput
                        id="name-en"
                        value={local.nameEn}
                        onChange={handleNameEnChange}
                        label=""
                        placeholder="Enter English title ..."
                        required
                        inputProps={{
                          pattern: ".*\\S+.*"
                        }}
                        disabled={false}
                      />
                      {false && (
                        <FormHelperText>
                          English Title is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="name-ar"
                        sx={{ transform: "none" }}
                      >
                        Arabic Title
                      </InputLabel>
                      <OutlinedInput
                        id="name-ar"
                        value={local.nameAr}
                        onChange={handleNameArChange}
                        label=""
                        placeholder="الأسم ..."
                        sx={{ direction: "rtl" }}
                        required
                        inputProps={{
                          pattern: ".*\\S+.*"
                        }}
                        disabled={false}
                      />
                      {false && (
                        <FormHelperText>
                          Arabic Title is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="tnc-en"
                        sx={{ transform: "none" }}
                      >
                        EN Terms & Conditions
                      </InputLabel>
                      <Box id="en" sx={editorStyle} ref={tncEnRef}>
                        <Editor
                          content={origin.tncEn || engPlaceholder}
                          onChange={handleTncEnContentChange}
                          editorStyle={{
                            minHeight: 24 * 3,
                            maxHeight: 24 * 3,
                            overflowY: "scroll",
                            outline: "none"
                          }}
                        />
                      </Box>
                      {false && (
                        <FormHelperText>
                          EN Terms & Conditions is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="tnc-ar"
                        sx={{ transform: "none" }}
                      >
                        AR Terms & Conditions
                      </InputLabel>
                      <Box id="ar" sx={editorStyle} ref={tncArRef}>
                        <Editor
                          content={origin.tncAr || arPlaceholder}
                          onChange={handleTncArContentChange}
                          editorStyle={{
                            minHeight: 24 * 3,
                            maxHeight: 24 * 3,
                            overflowY: "scroll",
                            outline: "none",
                            direction: "rtl"
                          }}
                        />
                      </Box>
                      {false && (
                        <FormHelperText>
                          AR Terms & Conditions is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="description-en"
                        sx={{ transform: "none" }}
                      >
                        English Description
                      </InputLabel>
                      <OutlinedInput
                        id="description-en"
                        value={local.descriptionEn}
                        onChange={handleDescriptionEnChange}
                        label=""
                        placeholder="Enter a description..."
                        multiline={true}
                        minRows="4"
                        maxRows="4"
                        required
                        inputProps={{
                          pattern: ".*\\S+.*"
                        }}
                        disabled={false}
                      />
                      {false && (
                        <FormHelperText>
                          English Description is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="description-ar"
                        sx={{ transform: "none" }}
                      >
                        Arabic Description
                      </InputLabel>
                      <OutlinedInput
                        id="description-ar"
                        value={local.descriptionAr}
                        onChange={handleDescriptionArChange}
                        label=""
                        placeholder="الوصف ..."
                        multiline={true}
                        minRows="4"
                        maxRows="4"
                        required
                        inputProps={{
                          pattern: ".*\\S+.*"
                        }}
                        disabled={false}
                        sx={{ direction: "rtl" }}
                      />
                      {false && (
                        <FormHelperText>
                          Arabic Description is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="url"
                        sx={{ transform: "none" }}
                      >
                        Merchant URL
                      </InputLabel>
                      <OutlinedInput
                        id="url"
                        type="url"
                        value={local.url}
                        onChange={handleUrlChange}
                        label=""
                        placeholder="Enter a merchant URL ..."
                        required
                        disabled={false}
                      />
                      {false && (
                        <FormHelperText>
                          Merchant URL is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="order-no"
                        sx={{ transform: "none" }}
                      >
                        Order No.
                      </InputLabel>
                      <OutlinedInput
                        id="order-no"
                        value={`#${local.order}`}
                        onInput={handleOrderChange}
                        label=""
                        disabled={false}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={local.isPopular}
                            icon={
                              <SvgIcon sx={{ color: "transparent" }}>
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="19"
                                  height="19"
                                  rx="3.5"
                                  fill="white"
                                />
                                <rect
                                  x="2"
                                  y="2"
                                  width="19"
                                  height="19"
                                  rx="3.5"
                                  stroke="#D0D5DD"
                                />
                              </SvgIcon>
                            }
                            checkedIcon={
                              <SvgIcon sx={{ color: "transparent" }}>
                                <rect
                                  x="2"
                                  y="2"
                                  width="19"
                                  height="19"
                                  rx="3.5"
                                  fill="#FFF8E2"
                                />
                                <path
                                  d="M16.6666 8.5L10.24992 14.9167L7.33325 12"
                                  stroke="#FFCC0A"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <rect
                                  x="2"
                                  y="2"
                                  width="19"
                                  height="19"
                                  rx="3.5"
                                  stroke="#FFCC0A"
                                />
                              </SvgIcon>
                            }
                          />
                        }
                        onChange={handleIsPopularChange}
                        label="Is it popular?"
                        sx={{
                          "& .MuiFormControlLabel-label	": {
                            fontWeight: 500,
                            fontSize: "18px",
                            lineHeight: "28px",
                            color: "#101828"
                          }
                        }}
                      />
                    </FormGroup>
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        color: "#101828"
                      }}
                    >
                      Marking a merchant as &quot;popular&quot; will display it
                      under the &quot;Trending&quot; deals section <br /> in the
                      Mobile App, and will display it in the Desktop Extension
                      Homepage
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="countries"
                        sx={{ transform: "none" }}
                      >
                        Countries
                      </InputLabel>
                      <Select
                        displayEmpty
                        value={local.countries}
                        id="countries-dropdown"
                        inputProps={{
                          id: "countries"
                        }}
                        MenuProps={{
                          id: "countries-menu"
                        }}
                        multiple
                        onChange={handleCountriesChange}
                        renderValue={value => (
                          <Stack spacing={1} direction="row">
                            {value.map(value => {
                              const country = configurations.application.countries.find(
                                country => country.code === value
                              );
                              return (
                                <Stack
                                  key={country.code}
                                  spacing={1}
                                  direction="row"
                                >
                                  <img src={getFlag(country.code)} />
                                  <span>{country.name}</span>
                                </Stack>
                              );
                            })}
                          </Stack>
                        )}
                        required
                        disabled={
                          configurations.application.countries.length === 1
                        }
                      >
                        {configurations.application.countries.map(country => (
                          <MenuItem key={country.code} value={country.code}>
                            <Stack
                              spacing={1}
                              sx={{ width: "100%" }}
                              direction="row"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Stack spacing={1} direction="row">
                                <img src={getFlag(country.code)} />
                                <span>{country.name}</span>
                              </Stack>
                              <img src={CheckIcon} alt="" className="check" />
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                      {false && (
                        <FormHelperText>Countries is required</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={7}></Grid>
                  <Grid item xs={12}>
                    <Stack
                      spacing={2.5}
                      direction="row"
                      justifyContent="stretch"
                    >
                      <Avatar
                        alt={local.nameEn}
                        src={local.logoUrl || avatar}
                        sx={{
                          width: 64,
                          height: 64,
                          textTransform: "uppercase"
                        }}
                      >
                        {local.nameEn
                          .split(" ")
                          .map(word => word[0])
                          .join("")}
                      </Avatar>
                      <UploadContainer
                        handleLogoUrlClick={handleLogoUrlClick}
                        logoUrlElement={logoUrlElement}
                        logoUrl={local.logoUrl}
                        handleLogoUrlChange={handleLogoUrlChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </FormCardBodyContent>
          </FormCardBody>
        </FormCard>
        <FormCard>
          <FormCardHead variant="underline">
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                color: "#101828"
              }}
            >
              New Target
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#667085"
              }}
            >
              Create a new Target under this section
            </Typography>
          </FormCardHead>
          <FormCardBody>
            <FormCardBodyContent>
              <Grid container spacing={3}>
                {local.targetUrls.map((targetUrl, targetUrlIndex) => (
                  <TargetUrlRecord
                    key={targetUrlIndex}
                    index={targetUrlIndex}
                    item={targetUrl}
                    avialableCountries={local.countries}
                    deleteItem={handleDeleteTargetUrl}
                    updateItem={handleUpdateTargetUrl}
                    deletable={local.targetUrls.length > 1}
                  />
                ))}
              </Grid>
            </FormCardBodyContent>
            <FormCardBodyActions>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                sx={{ ml: "auto" }}
              >
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={handleAddTargetUrlClick}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#FFF8E2",
                    borderColor: "#FFF8E2",
                    color: "#DAAC22",
                    "&:hover": {
                      backgroundColor: "#FFF8E2"
                    }
                  }}
                >
                  Add Target
                </Button>
              </Stack>
            </FormCardBodyActions>
          </FormCardBody>
        </FormCard>
        <Divider />
        <Stack direction="row" spacing={2} px={7} justifyContent="flex-end">
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            component={RouterLink}
            to={[configurations.dashboard.basePath, dealsMerchantsPath].join(
              ""
            )}
          >
            <Button
              variant="neutral"
              sx={{
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "inherit",
                  color: "inherit"
                }
              }}
            >
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            type="submit"
            sx={{ textTransform: "capitalize" }}
          >
            {operation === "add" ? "Create Merchant" : <></>}
            {operation === "edit" ? "Save Changes" : <></>}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default withReducer("merchant", MerchantReducers)(
  connect(
    state => ({ merchant: state.merchant }),
    {
      updateOrder,
      updateNameEn,
      updateNameAr,
      updateLogoUrl,
      updateUrl,
      updateDescriptionEn,
      updateDescriptionAr,
      updateIsPopular,
      updateCountries,
      updateTargetUrls,
      updateTncEn,
      updateTncAr,
      updateMerchant
    }
  )(Form)
);
