import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import WarningIcon from "./assets/warning.png"

import styles from "./ModalsStyles";

const useStyles = makeStyles(styles);

export default function WarningModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      onClose={props.onClose}
    >
      <div className={`${classes.paper} ${classes.warningModalContainer}`}>
        <img src={WarningIcon} alt="warning" />
        <h2>{props.title}</h2>
        <p> {props.message || ""}</p>
        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => props.onClose()}
          >
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
}

WarningModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
};
