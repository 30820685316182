import Add from "./Add";
import { dealsCategoriesAddPath } from "./paths";

export default {
  routes: [
    {
      path: dealsCategoriesAddPath,
      component: Add,
      layout: "main",
      isPrivate: false,
      roles: ["admin"],
      routes: []
    }
  ]
};
