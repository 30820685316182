import authenticatedHttpClientService from "../authenticated-http-client";

export const getCategory = id =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/categories`)
    .then(res => res.data)
    .then(data => {
      return data.find(category => category.id === id);
    });

export const addCategory = data =>
  authenticatedHttpClientService.post(
    `${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/category`,
    data
  );

export const editCategory = (id, data) =>
  authenticatedHttpClientService.patch(
    `${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/categories/${id}`,
    data
  );

export const removeCategory = id =>
  authenticatedHttpClientService.delete(
    `${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/categories/${id}`
  );

export const getCategories = () =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/categories`)
    .then(response => response.data);

export const editCategories = data =>
  authenticatedHttpClientService.put(
    `${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/categories`,
    data
  );
