import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
// redux actions
import { setInAPpType } from "../../../../redux/action/AnnouncementCards/CTAButtonActions";
// core components
import styles from "../../styles.css";
import { inAppType } from "./CallToActionConfig";

const useStyles = makeStyles(styles);

const InAppComponent = ({ announcementCards, setInAPpType }) => {
  const classes = useStyles();

  const handleSelection = event => {
    setInAPpType(event.target.value);
  };

  return (
    <Grid className={classes.container}>
      <TextField
        id="in-app-type"
        select
        label="In-app Type"
        className={classes.textField}
        name="buttonLinkType"
        value={announcementCards.inAppType}
        onChange={handleSelection}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="outlined"
        required
      >
        <option value="none">Select an Option</option>
        {inAppType.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    announcementCards: state.announcementCards
  };
};

export default connect(
  mapStateToProps,
  { setInAPpType }
)(InAppComponent);

InAppComponent.propTypes = {
  announcementCards: PropTypes.object,
  setInAPpType: PropTypes.func
};
