const initialState = {
  isLoading: false,
  list: [],
  error: false,
  success: false,
  hasNext: false,
  serverError: "",
  merchantsUploadList: [],
  UploadLoading: false,
  uploadSuccessMessage: "",
  updateMessage: "",
  limit: 10,
  totalPages: 0,
  deleteSuccessMessage: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING_MERCHANT_LIST": {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case "FETCH_MERCHANT_LIST": {
      return {
        ...state,
        list: action.payload?.data,
        hasNext: action.payload?.meta?.has_next,
        totalPages: action.payload?.meta?.total,
        limit: action.payload?.meta?.limit || state.limit
      };
    }
    case "SET_ERROR_LIST": {
      return {
        ...state,
        serverError: action.payload
      };
    }
    case "DELETE_MERCHANT_SUCCESS": {
      return {
        ...state,
        success: true,
        deleteSuccessMessage: action.payload
      };
    }
    case "SET_MERCHANT_LIST_UPLOAD": {
      return {
        ...state,
        merchantsUploadList: action.payload
      };
    }
    case "UPLOAD_MERCHANT_LOADING": {
      return {
        ...state,
        UploadLoading: action.payload
      };
    }
    case "UPLOAD_MERCHANT_SUCCESS": {
      return {
        ...state,
        success: true,
        uploadSuccessMessage: action.payload
      };
    }
    case "SUCCESS_UPDATE_MERCHANT": {
      return {
        ...state,
        success: true,
        updateMessage: action.payload
      };
    }
    case "UPLOAD_MERCHANT_FAILED": {
      return {
        ...state,
        error: true,
        serverError: action.payload
      };
    }
    case "ERROR_UPDATE_MERCHANT": {
      return {
        ...state,
        error: true
      };
    }
    case "RESET_ERROR": {
      return {
        ...state,
        serverError: "",
        success: false,
        updateMessage: "",
        uploadSuccessMessage: "",
        error: false,
        deleteSuccessMessage: ""
      };
    }
    case "RESET_UPLOAD_MERCHANT_LIST": {
      return {
        ...state,
        merchantsUploadList: [],
        UploadLoading: false,
        success: false,
        error: false,
        uploadSuccessMessage: "",
        updateMessage: "",
        deleteSuccessMessage: ""
      };
    }
    case "REST_MERCHANT_STATE": {
      return initialState;
    }
    default:
      return state;
  }
};
