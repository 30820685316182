import qs from "qs";
import Moment from "moment";
import {
  FETCH_REWARD_API_LIST,
  RESET_REWARD_API_STATE,
  SELECT_USERS_TO_RECHARGE,
  SET_SEARCH_PHONE_NUMBER,
  SET_LOADING_REWARD_LIST,
  SELECT_USERS_TO_Export,
  SET_PROJECT_NAME,
  FETCH_PROJECT_LIST,
  SET_ERROR_CREATE_PROJECT,
  SET_LOADING_PROJECT_LIST,
  REST_PROJECT_STATE,
  SET_SUCCESS_CREATE_PROJECT,
  OPEN_SNACK_BAR
} from "../actionType";
import { waslaRewardBaseurl, authURL } from "../../../components/config";
import { toggleErrorMessage } from "../ErrorMessageActions";

export const fetchRewardApiList = ({
  pageNum,
  phoneNumber,
  startDate,
  endDate,
  operator,
  success,
  batchId,
  projectId
}) => async dispatch => {
  const convertStartDate = startDate && Moment(startDate).format("YYYY-MM-DD");
  const convertEndDate = endDate && Moment(endDate).format("YYYY-MM-DD");
  dispatch({ type: SET_LOADING_REWARD_LIST, payload: true });
  await waslaRewardBaseurl
    .get(`/external/cashout/charge/?pageNo=${pageNum || 1}&pageSize=10`, {
      params: {
        searchQuery: phoneNumber,
        startDate: convertStartDate,
        endDate: convertEndDate,
        operator,
        success,
        bulkId: batchId,
        projectId
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "comma",
          encode: false,
          skipNulls: true
        });
      }
    })
    .then(res => {
      dispatch({
        type: FETCH_REWARD_API_LIST,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING_REWARD_LIST, payload: false });
      dispatch(toggleErrorMessage());
    });
};

export const setSearchPhoneNumber = phoneNumber => {
  return {
    type: SET_SEARCH_PHONE_NUMBER,
    payload: phoneNumber
  };
};

export const clearRewardApiState = () => {
  return {
    type: RESET_REWARD_API_STATE
  };
};

export const selectUsersToRecharge = users => {
  return {
    type: SELECT_USERS_TO_RECHARGE,
    payload: users
  };
};

export const selectUsersToExport = users => {
  return {
    type: SELECT_USERS_TO_Export,
    payload: users
  };
};

export const setProjectName = name => {
  return {
    type: SET_PROJECT_NAME,
    payload: name
  };
};

export const fetchProjectList = num => async dispatch => {
  dispatch({ type: SET_LOADING_PROJECT_LIST, payload: true });
  await waslaRewardBaseurl
    .get(`/external/project?pageNo=${num || 1}&pageSize=10`)
    .then(res => {
      dispatch({ type: SET_LOADING_PROJECT_LIST, payload: false });

      dispatch({
        type: FETCH_PROJECT_LIST,
        payload: res?.data
      });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING_PROJECT_LIST, payload: false });
      dispatch({ type: OPEN_SNACK_BAR, payload: true });
      if (error?.response?.status === 401) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication error"
        });
        window.location.assign(authURL);
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const createProject = (name, integrationType) => async dispatch => {
  await waslaRewardBaseurl
    .post(`/external/project`, {
      name,
      integrationType
    })
    .then(res => {
      dispatch({
        type: SET_SUCCESS_CREATE_PROJECT
      });
      dispatch(fetchProjectList());
    })
    .catch(error => {
      dispatch({ type: SET_LOADING_REWARD_LIST, payload: false });
      if (error?.response?.status === 401) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication error"
        });
        window.location.assign(authURL);
      }
      if (error?.response?.status !== 400) {
        dispatch({ type: SET_ERROR_CREATE_PROJECT, payload: true });
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const restProjectState = () => {
  return {
    type: REST_PROJECT_STATE
  };
};
