import { UPDATE_SPLASH_SCREEN, UPDATE_SPLASH_SCREEN_TYPE, UPDATE_SPLASH_SCREEN_SUCCESS } from "../../../action/actionType";

const initialState = {
  splashScreens: { ar: "", en: "" },
  uploadSuccess: { ar: false, en: false },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SPLASH_SCREEN:
      return {
        ...state,
        splashScreens: action.payload
      };
    case UPDATE_SPLASH_SCREEN_SUCCESS:
      return {
        ...state,
        uploadSuccess: { ...state.uploadSuccess, ...action.payload}
      };
    case UPDATE_SPLASH_SCREEN_TYPE:
      return {
        ...state,
        splashScreens: { ...state.splashScreens, ...action.payload}
      };
    default:
      return state;
  }
};
