import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

const headCells = [
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: true,
    label: "User Numbers",
    minWidth: 125
  },
  {
    id: "transactionID",
    numeric: false,
    disablePadding: true,
    label: "Transaction ID",
    minWidth: 175
  },
  {
    id: "operator",
    numeric: false,
    disablePadding: true,
    label: "Operator",
    minWidth: 105
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date",
    minWidth: 175
  },
  {
    id: "beginningBalance",
    numeric: false,
    disablePadding: true,
    label: "Beginning Balance",
    minWidth: 165
  },
  {
    id: "endingBalance",
    numeric: false,
    disablePadding: true,
    label: "Ending Balance",
    minWidth: 155
  },
  {
    id: "transactionValue",
    numeric: false,
    disablePadding: true,
    label: "Transaction Value",
    minWidth: 165
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: true,
    label: "Comment",
    minWidth: 165
  },
  {
    id: "success",
    numeric: false,
    disablePadding: true,
    label: "Status",
    minWidth: 145
  },
  {
    id: "failReson",
    numeric: false,
    disablePadding: true,
    label: "Fail Status",
    minWidth: 165
  }
  // {
  //   id: "export",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Export",
  //   minWidth: 150,
  // maxWidth: 100
  // }
];

function TableHeader(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            color="primary"
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default TableHeader;
