const customStyle = {
  cardContainer: {
    marginTop: 0
  },
  containerHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "10px",
    alignItems: "center",
    "& div:last-of-type": {
      margin: 0,
      borderRadius: "50%",
      padding: 0,
      background: "linear-gradient(60deg, #556EE6, #556EE6)",
      "& svg": {
        width: "30px",
        height: "30px",
        lineHeight: "30px"
      }
    }
  },
  cardContent: {
    textAlign: "left",
    "& > *": {
      color: "#000",
      fontWeight: "400"
    }
  },
  cardIconContainer: {
    margin: 0,
    fontSize: 12
  }
};
export default customStyle;
