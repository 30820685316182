import { lighten } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
    "& .MuiTableRow-root.Mui-selected": {
      backgroundColor: lighten(theme.palette.primary.light, 0.85)
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
  title: {
    flex: "0 0 140px"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  containerContentSpaceBetween: {
    justifyContent: "space-between"
  },
  flexDirectionColumn: {
    flexDirection: "column"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  primaryButton: {
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    color: "#ffff",
    "&:hover": {
      background: "transparent",
      color: "#ffa726",
      border: "1px solid #ffa726"
    }
  },
  defaultButton: {
    fontWeight: "500",
    border: "1px solid transparent",
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      fontSize: "1.2rem"
    },
    "& a": {
      color: "#000"
    }
  },
  arrowButton: {
    borderRadius: "50%",
    height: "40px",
    minWidth: "40px",
    padding: 0
  },
  retryBtn: {
    background: "#fafafa",
    color: "#000",
    margin: "0px 5px",
    borderRadius: "25px",
    textTransform: "none",
    "&:hover": {
      background: "#fafafa",
      color: "#000"
    },
    minWidth: "140px"
  },
  sendRechargeBtn: {
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    color: "#000",
    "&:hover": {
      background: "linear-gradient(60deg, #ffa726, #fb8c00)",
      color: "#000",
      border: "1px solid #ffa726"
    },
    margin: "0px 5px",
    borderRadius: "25px",
    textTransform: "none",
    minWidth: "140px"
  },
  modalContainer: {
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -48%)",
    textAlign: "center",
    position: "absolute",
    width: 500,
    backgroundColor: "#fff",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
    padding: "16px 32px 24px"
  },
  textAlignLeft: {
    textAlign: "left"
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "47%"
  },
  radioBtnContainer: {
    width: "100%",
    margin: theme.spacing(2)
  },
  radioBtnIcon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    root: {
      "&.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2
      }
    },
    "input:hover ~ &": {
      backgroundColor: "#ffa726"
    }
  },
  checkedIcon: {
    backgroundColor: "#ffa726",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#ffa726"
    }
  },
  media: {
    height: 0,
    paddingTop: "36.25%", // 16:9
    backgroundSize: "30%"
  },
  textFieldsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  editTable: {
    minWidth: "auto"
  },
  iconContainer: {
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0, 0.3)",
    zIndex: 10
  },
  ColumnWithImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "42px",
    "& img": {
      paddingLeft: " 10px",
      width: "20px"
    }
  },
  detailsBtn: {
    backgroundColor: "#fff",
    border: "1px solid #95989A",
    borderRadius: 5,
    textTransform: "capitalize",
    boxShadow: "none",
    color: "#000",
    padding: "6px 12px"
  }
});

export default styles;
