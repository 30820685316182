const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    padding: "10px 25px",
    borderRadius: "5px",
    color: "#ffff",
    border: "1px solid transparent",
    fontWeight: "500",
    "&:hover": {
      background: "transparent",
      color: "#ffa726",
      border: "1px solid #ffa726"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

export default styles;
