import { FETCH_CATEGORY_TYPES } from "../../action/actionType";

const initialState = {
  categoryTypes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_TYPES:
      return {
        ...state,
        categoryTypes: action.payload
      };

    default:
      return state;
  }
};
