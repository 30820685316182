import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setMissionType } from "../../../../redux/action/Missions/MissionsDefinitionActions";
// core components
import styles from "../../styles.css";

const useStyles = makeStyles(styles);
// Survey, Game, Challenge, Download
const missionTypes = [
  { id: "1", name: "Survey" },
  { id: "2", name: "Game" },
  { id: "3", name: "Challenge" },
  { id: "4", name: "Download" }
];
const MissionSection = ({ missions, setMissionType, viewType }) => {
  const classes = useStyles();

  const handleSelection = event => {
    const selectedValue = event.target.value;

    // Send selected MissionType name to the reducer
    const currentMissionType = missionTypes.filter(
      item => item?.id === selectedValue
    );
    const missionTypeName =
      selectedValue !== "none" ? currentMissionType[0].name : "";
    const typeId = selectedValue !== "none" ? selectedValue : "";

    setMissionType(typeId, missionTypeName);
  };

  useEffect(() => {
    if (viewType === "Edit") {
      const currentMissionType = missionTypes.filter(
        item => missions?.missionTypeName === item.name
      );
      const missionTypeId = currentMissionType[0]?.id;
      setMissionType(missionTypeId, missions.missionTypeName);
    }
  }, [viewType, missions?.missionTypeName]);

  return (
    <>
      {missionTypes.length !== 0 && (
        <TextField
          id="mission-type"
          select
          label="Mission Type"
          className={classes.textField}
          name="missionType"
          value={missions.missionTypeId}
          onChange={handleSelection}
          SelectProps={{
            native: true,
            MenuProps: {
              className: classes.menu
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        >
          <option value="none">Select an Option</option>
          {missionTypes.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </TextField>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    missions: state.missions
  };
};

export default connect(
  mapStateToProps,
  {
    setMissionType
  }
)(MissionSection);

MissionSection.propTypes = {
  missions: PropTypes.object,
  setMissionType: PropTypes.func
};
