import { fade } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      width: "270px",
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginInlineStart: "20px",
    marginInlineEnd: "20px",
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "20px",
    "& .MuiInputBase-root": {
      fontSize: "0.9rem",
      "& input::placeholder": {
        fontSize: "0.8rem"
      }
    },
    [theme.breakpoints.up("sm")]: {
      width: "270px"
    },
    "@media screen and (max-width: 1300px) ": {
      width: "190px"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ccc",
    cursor: "pointer",
    zIndex: "5",
    "& svg": {
      width: "0.9em"
    }
  },
  clearIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "0",
    right: "-2%",
    cursor: "pointer",
    color: "#ccc"
  },
  inputRoot: {
    color: "inherit",
    width: "98%"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    },
    height: "10px",
    fontSize: "0.95em"
  },
  navCsWidth: {
    width: "350px",
    "&:hover": {
      width: "350px"
    }
  }
});

export default styles;
