import {
  FETCH_ALL_REASONS,
  ADD_SELECTED_REASON
} from "../../action/actionType";
import * as pointsService from "../../../services/points";

export const fetchAllReasons = type => async disPatch => {
  await pointsService
    .getTransferPointsReasons(type)
    .then(data => {
      disPatch({
        type: FETCH_ALL_REASONS,
        payload: data
      });
    })
    .catch(err => {
      // disPatch({
      //   type: TRANSFER_ERROR,
      //   payload: err?.response?.status
      // });
    });
};

export const addSelectedReason = reason => async disPatch => {
  disPatch({
    type: ADD_SELECTED_REASON,
    payload: reason
  });
};
