import {
  PUSH_NOTIFICATIONS_DEFINITION,
  PUSH_NOTIFICATIONS_CTA,
  PUSH_NOTIFICATIONS_IMAGE_URL
} from "../actionType";

export const pushNotificationDefinition = data => {
  return {
    type: PUSH_NOTIFICATIONS_DEFINITION,
    payload: { ...data }
  };
};

export const pushNotificationCTA = url => {
  return {
    type: PUSH_NOTIFICATIONS_CTA,
    payload: url
  };
};

export const addPushNotificationImage = imageUrl => {
  return {
    type: PUSH_NOTIFICATIONS_IMAGE_URL,
    payload: imageUrl
  };
};
