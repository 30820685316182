import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import CompareArrowsRoundedIcon from "@material-ui/icons/CompareArrowsRounded";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Button } from "@material-ui/core";
// redux actions
import {
  calculatePoints,
  calculateCash
} from "../../../redux/action/CashPointsActions";
import { addBonusPoints } from "../../../redux/action/PointsSystem/BonusPointsBucketActions";
import { addPointsForSingleUser } from "../../../redux/action/PointsSystem/TestingNumbersBucketActions";
// core components
import styles from "../styles.css";
import CardContainer from "../../CardContainer";
import { oneCashToPoints } from "../../CashPointsConfig";

const useStyles = makeStyles(styles);

const AddPoints = ({
  cashPointsBudget,
  calculatePoints,
  calculateCash,
  addBonusPoints,
  addPointsForSingleUser,
  match,
  bonusAddError,
  bonusAddSuccess,
  availableError
}) => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const [messageErr, setMessageErr] = useState("");

  const pointCallback = useCallback(
    pointsValue => {
      let calcCash = Math.floor(parseInt(pointsValue) / oneCashToPoints);
      calculateCash(calcCash);
      calculatePoints(pointsValue);
    },
    [calculateCash]
  );

  const handlePointsChange = event => {
    pointCallback(event.target.value);
  };

  const cashCallback = useCallback(
    cashValue => {
      let calcPoints = parseInt(cashValue) * oneCashToPoints;
      calculatePoints(calcPoints);
      calculateCash(cashValue);
    },
    [calculatePoints]
  );

  const handleCashChange = event => {
    cashCallback(event.target.value);
  };

  useEffect(() => {
    if (bonusAddSuccess && bonusAddSuccess.status) {
      setRedirect(true);
    }
    const errorAdd = bonusAddError && bonusAddError.status;
    switch (errorAdd) {
      case 500:
        setMessageErr("Server error");
        break;
      case 400:
        setMessageErr("Invalid bucket name");
        break;
      case 404:
        setMessageErr("Bucket Not found");
        break;
      case 401:
        setMessageErr("Your action is Unauthorized");
        break;
      case 403:
        setMessageErr("You have no permission for this action");
        break;
      default:
        break;
    }
  }, [bonusAddError, bonusAddSuccess]);

  const addPoints = amount => {
    if (match.url.includes("bonusPoints")) {
      addBonusPoints("bonus", amount);
    } else {
      addPointsForSingleUser(amount);
    }
  };

  if (availableError) {
    throw new Error();
  }

  return (
    <CardContainer title="Add points">
      <div
        className={classes.cashContainer}
        style={{
          marginBlockStart: "24px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <TextField
          id="cash"
          label="Cash"
          name="cash"
          type="number"
          className={classes.textField}
          onChange={handleCashChange}
          value={cashPointsBudget.cash || ""}
          margin="normal"
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.endAdornment}>
                <span className={classes.endAdornmentText}>EGP</span>
              </InputAdornment>
            )
          }}
        />
        <CompareArrowsRoundedIcon
          style={{
            fontSize: 32,
            fontWeight: "bold",
            marginTop: "18px",
            marginBottom: "8px"
          }}
        />
        <TextField
          id="points"
          label="Points"
          name="points"
          value={cashPointsBudget.points || ""}
          type="number"
          className={classes.textField}
          onChange={handlePointsChange}
          margin="normal"
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.endAdornment}>
                <span className={classes.endAdornmentText}>PTS</span>
              </InputAdornment>
            )
          }}
        />
      </div>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        style={{
          width: "10%",
          margin: "16px"
        }}
        onClick={() => addPoints(cashPointsBudget.points)}
      >
        Add
      </Button>
      <>
        {messageErr && (
          <Typography
            component="p"
            variant="subtitle1"
            color="error"
            align="center"
            style={{ fontSize: 16, fontWeight: 500 }}
          >
            {messageErr}
          </Typography>
        )}
      </>
      {redirect ? (
        <Redirect
          to={{
            pathname: `/admin/PointsSystem/`
          }}
        />
      ) : null}
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    cashPointsBudget: state.cashPointsBudget,
    bonusAddError: state.pointsSystem.bonusAddError,
    bonusAddSuccess: state.pointsSystem.bonusAddSuccess,
    availableError: state.availableError.availableError
  };
};

export default connect(
  mapStateToProps,
  { calculatePoints, calculateCash, addBonusPoints, addPointsForSingleUser }
)(AddPoints);

AddPoints.propTypes = {
  cashPointsBudget: PropTypes.object,
  calculatePoints: PropTypes.func,
  calculateCash: PropTypes.func,
  addBonusPointsForSingleUser: PropTypes.func,
  addBonusPoints: PropTypes.func,
  addPointsForSingleUser: PropTypes.func,
  match: PropTypes.object,
  availableError: PropTypes.bool
};
