import Edit from "./Edit";
import { quickLinksCategoriesEditPath } from "../../paths";

export default {
  routes: [
    {
      path: quickLinksCategoriesEditPath,
      component: Edit,
      layout: "main",
      isPrivate: false,
      roles: ["admin"]
    }
  ]
};
