import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import styles from "./ModalsStyles";

const useStyles = makeStyles(styles);

export default function ConfirmModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      onClose={props.onClose}
    >
      <div className={`${classes.paper} ${classes.modalContainer}`}>
        {props.type === "submit" ? (
          <h4>Create a new campaign?</h4>
        ) : props.type === "edit" ? (
          <h4>Do you want to save the changes?</h4>
        ) : props.type === "blocking" ? (
          <h3 id="simple-modal-title">Are you sure ?</h3>
        ) : (
          <>
            {props?.name && (
              <h3 id="simple-modal-title">{`Are you sure you want to delete (${
                props.name
              }) ${props.message || ""}?`}</h3>
            )}
            {!props?.name && <h3 id="simple-modal-title">Are you sure ?</h3>}
          </>
        )}
        <div className={classes.buttonsContainer}>
          {props.type === "submit" ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={e => props.handleSubmit(e)}
              className={classes.button}
            >
              Submit
            </Button>
          ) : props.type === "edit" ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={e => props.handleEdit(e)}
              className={classes.button}
            >
              Save
            </Button>
          ) : props.type === "blocking" ? (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => props.handleDelete(props.Id)}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => props.handleDelete(props.Id)}
            >
              Delete
            </Button>
          )}
          <>
            <Button
              variant="contained"
              className={classes.button}
              onClick={props.onClose}
            >
              Cancel
            </Button>
          </>
        </div>
      </div>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleDelete: PropTypes.func,
  Id: PropTypes.string,
  type: PropTypes.string,
  handleEdit: PropTypes.func,
  message: PropTypes.string
};
