import { UPDATE_DATA_FROM, UPDATE_DATA_TO, UPDATE_DATE_RANGE } from "../../actions/filtration";

const initialState = {
  dateFrom: null,
  dateTo: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATA_FROM:
      return {
        ...state,
        dateFrom: action.payload.dateFrom
      };
    case UPDATE_DATA_TO:
      return {
        ...state,
        dateTo: action.payload.dateTo
      };
    case UPDATE_DATE_RANGE:
      return {
        dateFrom: action.payload.dateRange[0],
        dateTo: action.payload.dateRange[1]
      };
    default:
      return state;
  }
};
