import {
  SET_IMAGE_URL,
  RESET_STATE,
  EDIT_ANNOUNCEMENT_CARDS,
  EDIT_QUICK_LINKS,
  EDIT_MISSIONS,
  SET_IMAGE_ICON_URL
} from "../action/actionType";

const initialState = {
  url: "",
  iconUrlMission: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IMAGE_URL:
      return {
        ...state,
        url: action.payload
      };

    case SET_IMAGE_ICON_URL:
      return {
        ...state,
        iconUrlMission: action.payload
      };

    case RESET_STATE:
      return {
        ...state,
        url: "",
        iconUrlMission: ""
      };

    case EDIT_ANNOUNCEMENT_CARDS:
      return {
        ...state,
        url: action.payload.imgUrl
      };

    case EDIT_QUICK_LINKS:
      return {
        ...state,
        url: action.payload.iconUrl
      };

    case EDIT_MISSIONS:
      return {
        ...state,
        url: action.payload.thumbnailUrl,
        iconUrlMission: action.payload.iconUrl
      };

    default:
      return state;
  }
};
