export const modifyUsers = (price = 0, users) => {
  return users.map(user => {
    user = {
      phoneNumber: user.phoneNumber,
      operator: user.operator,
      price
    };
    return user;
  });
};

export const filterRechargesFailed = users => {
  return users.filter(user => {
    return !user.success;
  });
};

export const totalUsersAndValue = users => {
  let numOfUsers = users.length;
  let transactionGrossValue = users.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.price;
  }, 0);
  let transactionNetValue = (transactionGrossValue / 1.4285).toFixed(2);
  return {
    numOfUsers,
    transactionGrossValue,
    transactionNetValue
  };
};
