import qs from "qs";

import {
  dealsBaseurl,
  authURL,
  missionsBaseUrl
} from "../../../components/config";
import MockAdapter from "axios-mock-adapter";
import axios from "axios";

let mock = new MockAdapter(axios);

// Mock GET request to /users when param `searchText` is 'John'
// arguments for reply are (status, data, headers)
mock.onGet("/sections").reply(200, {
  data: [
    {
      id: 0,
      titleEn: "title en",
      titleAr: "titleAr",
      noteEn: "noteEn",
      noteAr: "noteAr",
      order: 1
    },
    {
      id: 1,
      titleEn: "title en 1",
      titleAr: "titleAr 1",
      noteEn: "noteEn 2",
      noteAr: "noteAr 2",
      order: 2
    }
  ],
  meta: { has_next: false, total: 1 }
});
export const fetchSectionsList = ({
  pageNum,
  limit = 10,
  countrySelected
}) => async dispatch => {
  dispatch({ type: "SET_LOADING_SECTIONS_LIST", payload: true });
  await dealsBaseurl
    .get(`/sections?page=${pageNum}&limit=${limit}`, {
      params: {
        countyCode: countrySelected?.toUpperCase()
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "comma",
          encode: false,
          skipNulls: true
        });
      }
    })
    .then(res => {
      dispatch({ type: "SET_LOADING_SECTIONS_LIST", payload: false });
      dispatch({
        type: "FETCH_SECTIONS_LIST",
        payload: res?.data
      });
    })
    .catch(error => {
      dispatch({ type: "SET_LOADING_SECTIONS_LIST", payload: false });
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const submitCreateSection = sections => async dispatch => {
  dispatch({
    type: "UPLOAD_SECTIONS_LOADING",
    payload: true
  });
  await dealsBaseurl
    .post(`/sections`, sections)
    .then(res => {
      dispatch({ type: "CREATE_SECTIONS_LOADING", payload: false });
      dispatch({
        type: "CREATE_SECTIONS_SUCCESS",
        payload: "Sections is added successfully"
      });
      dispatch(fetchSectionsList({ pageNum: 1 }));
    })
    .catch(error => {
      dispatch({ type: "CREATE_SECTIONS_LOADING", payload: false });

      if (error?.response?.status === 401) {
        window.location.assign(authURL);
      }

      if (error?.response?.status === 400) {
        dispatch({
          type: "CREATE_SECTIONS_FAILED",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "CREATE_SECTION_FAILED",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const handleDeleteSection = (id, pageNum) => async dispatch => {
  dispatch(resetSectionList());

  await dealsBaseurl
    .delete(`/sections/${id}`)
    .then(() => {
      dispatch(fetchSectionsList({ pageNum: 1 }));
      dispatch({
        type: "DELETE_SECTION_SUCCESS",
        payload: "Section is deleted successfully"
      });
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const resetSectionList = () => {
  return {
    type: "RESET_SECTION_LIST"
  };
};

export const closeNotification = () => {
  return {
    type: "RESET_ERROR"
  };
};
export const submitUpdateSection = ({ data, id }) => async dispatch => {
  await dealsBaseurl
    .put(`/sections/${id}`, data)
    .then(() => {
      dispatch(fetchSectionsList({ pageNum: 1 }));
      dispatch({
        type: "SUCCESS_UPDATE_SECTION",
        payload: "Section is updated successfully"
      });
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "ERROR_UPDATE_SECTION",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "ERROR_UPDATE_SECTION",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "ERROR_UPDATE_SECTION",
          payload: "Something went wrong with the server"
        });
      }
    });
};
