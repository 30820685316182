import Edit from "./Edit";
import { cashbacksConversionsEditRequestEditPath } from "./paths";

export default {
  routes: [
    {
      path: cashbacksConversionsEditRequestEditPath,
      component: Edit,
      layout: "main",
      isPrivate: false,
      roles: ["admin", "cs"],
      routes: []
    }
  ]
};
