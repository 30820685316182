const styles = theme => ({
  card: {
    marginTop: theme.spacing(6)
  },
  cardTitle: {
    margin: theme.spacing(0)
  }
});

export default styles;
