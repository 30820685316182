import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// redux actions
import {
  setMissionMaxUsage,
  setMissionTotalPoints,
  setMissionRewardedPoints
} from "../../../../redux/action/Missions/MissionsBudgetActions";

import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
// import MissionRewardedPoints from "./MissionRewardedPoints";
import styles from "../../styles.css";
import { content } from "../MissionsConfig";

const useStyles = makeStyles(styles);

const MissionBudget = props => {
  const classes = useStyles();
  const [totalPoint, setTotalPoint] = useState("");
  const {
    missions,
    setMissionMaxUsage,
    checkRequiredFields,
    setMissionRewardedPoints
  } = props;

  // const handleTotalPointsChange = event => {
  //   setMissionTotalPoints(event.target.value);
  // };

  const handleChange = event => {
    setMissionRewardedPoints(event.target.value);
  };

  const handleMaxUsageChange = event => {
    setMissionMaxUsage(event.target.value);
  };

  // const handleMaxUsagePerUserChange = event => {
  //   setMissionMaxUsagePerUser(event.target.value);
  // };

  useEffect(() => {
    setTotalPoint(+missions.rewardedPoints * +missions.maxUsage);
    if (missions.rewardedPoints > 0 && missions.maxUsage > 0) {
      checkRequiredFields(true);
    } else {
      checkRequiredFields(false);
    }
  }, [missions]);

  return (
    <CardContainer title={`2. ${content.stepsTitle[1]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <Grid className={`${classes.container} ${classes.marginBlock}`}>
            <TextField
              id="mission-max-usage"
              label="Mission max usage"
              type="number"
              className={classes.textField}
              value={missions.maxUsage || ""}
              onChange={handleMaxUsageChange}
              margin="normal"
              variant="outlined"
              error={missions.maxUsage < 0}
              helperText={
                missions.maxUsage < 0
                  ? "Number of users can do this mission Min. 1"
                  : "Number of users can do this mission"
              }
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: 1,
                pattern: "[0-9]*"
              }}
            />
            {/* <TextField
              id="mission-max-usage-perUser"
              label="Mission max usage per user"
              type="number"
              className={classes.textField}
              value={missions.maxUsagePerUser}
              onChange={handleMaxUsagePerUserChange}
              helperText="Number of times a user can do this mission"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: 1
              }}
            /> */}
            <TextField
              id="awarded-points"
              label="Awarded points"
              type="number"
              className={classes.textField}
              value={missions.rewardedPoints || ""}
              onChange={handleChange}
              error={missions.rewardedPoints < 0}
              helperText={
                missions.rewardedPoints < 0
                  ? "Points awarded for mission completion Min. 1"
                  : "Points awarded for mission completion"
              }
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: 0,
                pattern: "[0-9]*"
              }}
            />
            <TextField
              id="mission-total-points"
              label="Total mission campaign points"
              type="number"
              className={classes.textField}
              value={totalPoint || ""}
              // onChange={handleTotalPointsChange}
              helperText="Total points for mission campaign"
              margin="normal"
              variant="outlined"
              // required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: 1,
                readOnly: true
              }}
            />

            {/* <MissionRewardedPoints /> */}
          </Grid>
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    missions: state.missions
  };
};
export default connect(
  mapStateToProps,
  {
    setMissionMaxUsage,
    setMissionTotalPoints,
    checkRequiredFields,
    setMissionRewardedPoints
  }
)(MissionBudget);

MissionBudget.propTypes = {
  missions: PropTypes.object,
  setMissionMaxUsage: PropTypes.func,
  setMissionTotalPoints: PropTypes.func,
  checkRequiredFields: PropTypes.func,
  setMissionRewardedPoints: PropTypes.func
};
