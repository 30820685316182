import { Tune } from "@mui/icons-material";
import CurrencyNPointsConfiguration from "./CurrencyNPointsConfiguration";
import { currencyNPointsConfigurationPath } from "./paths";

export default {
  routes: [
    {
      path: currencyNPointsConfigurationPath,
      name: "Currency & Pts Config",
      icon: Tune,
      component: CurrencyNPointsConfiguration,
      layout: "main",
      isPrivate: null,
      roles: ["admin"]
    }
  ]
};
