import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import styles from "../styles.css";

const useStyles = makeStyles(styles);

function Info(props) {
  const classes = useStyles();
  const { list, type } = props;
  return (
    <div className={classes.container}>
      <TextField
        id={type}
        name="uploadUsersRecharge"
        label={`Number of ${type}`}
        type="number"
        className={classes.textField}
        value={list?.length}
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          readOnly: true
        }}
      />
    </div>
  );
}

export default Info;

Info.propTypes = {
  list: PropTypes.array
};
