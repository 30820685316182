import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setCardTitles } from "../../../redux/action/AnnouncementCards/CardsDefinitionActions";
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem.js";
import CardContainer from "../../CardContainer";
import ImageUpload from "../../ImageUpload";
import styles from "../styles.css";
import content from "./ContentConfig";

const useStyles = makeStyles(styles);

const CardDefinition = ({
  announcementCards,
  setCardTitles,
  imageUrl,
  checkRequiredFields
}) => {
  const classes = useStyles();

  const handleNameChange = event => {
    setCardTitles({
      ...announcementCards,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    if (
      imageUrl &&
      announcementCards.englishTitle &&
      announcementCards.englishSubtitle &&
      announcementCards.arabicTitle &&
      announcementCards.arabicSubtitle
    ) {
      checkRequiredFields(true);
    }
  }, [announcementCards, imageUrl]);

  return (
    <CardContainer title={`1. ${content.stepsTitle[0]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <Grid className={`${classes.container} ${classes.marginBlock}`}>
            <ImageUpload fieldTitle="Card image" />
            <TextField
              id="english-title"
              name="englishTitle"
              label="Announcement Title (En)"
              className={classes.textField}
              value={announcementCards.englishTitle}
              onChange={handleNameChange}
              helperText="Max no. of characters is 30"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 30
              }}
            />
            <TextField
              id="english-subtitle"
              name="englishSubtitle"
              label="Subtitle (En)"
              className={classes.textField}
              value={announcementCards.englishSubtitle}
              onChange={handleNameChange}
              helperText="Max no. of characters is 100"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
            />
            <TextField
              id="arabic-title"
              name="arabicTitle"
              label="Announcement Title (Ar)"
              className={classes.textField}
              value={announcementCards.arabicTitle}
              onChange={handleNameChange}
              helperText="Max no. of characters is 30"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 30
              }}
            />
            <TextField
              id="arabic-subtitle"
              name="arabicSubtitle"
              label="Subtitle (Ar)"
              className={classes.textField}
              value={announcementCards.arabicSubtitle}
              onChange={handleNameChange}
              helperText="Max no. of characters is 100"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
            />
          </Grid>
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    announcementCards: state.announcementCards,
    imageUrl: state.imageUrl.url
  };
};

export default connect(
  mapStateToProps,
  { setCardTitles, checkRequiredFields }
)(CardDefinition);

CardDefinition.propTypes = {
  announcementCards: PropTypes.object,
  imageUrl: PropTypes.string,
  setCardTitles: PropTypes.func,
  checkRequiredFields: PropTypes.func
};
