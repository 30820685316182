import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
//date inupt
import MomentUtils from "@date-io/moment";
// redux actions
import { setStartDate, setEndDate } from "../../redux/action/SetDateActions";
// core components
import DateTimePicker from "./DateTimePicker";
import DatePicker from "./DatePicker";
const DateComponent = ({
  dates,
  setStartDate,
  setEndDate,
  disableFutureDays,
  disablePastDays,
  setStartDateFilter,
  setEndDateFilter,
  endDateFilter,
  startDateFilter,
  type,
  nonDefault = false
}) => {
  const [dateError, setDateError] = React.useState(false);

  const changeState = (setDateFilter, setDate, value) => {
    if (type) {
      setDateFilter(value?.format());
    } else {
      setDate(value?.format());
    }
  };

  const handleStartDateTimeChange = useCallback(
    date => {
      setDateError(false);
      changeState(setStartDateFilter, setStartDate, date);
    },
    [setDateError, setStartDate, setStartDateFilter]
  );

  const handleEndDateTimeChange = useCallback(
    date => {
      setDateError(false);
      changeState(setEndDateFilter, setEndDate, date);
    },
    [setDateError, setEndDate, setEndDateFilter]
  );

  useEffect(() => {
    if (dates.startDate && dates.endDate && dates.startDate > dates.endDate) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [dates]);

  useEffect(() => {
    if (endDateFilter && startDateFilter && startDateFilter > endDateFilter) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [endDateFilter, startDateFilter]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        {!type && (
          <Grid container>
            <DateTimePicker
              date={dates.startDate}
              title="Start Date"
              dateError={dateError}
              onChange={handleStartDateTimeChange}
              disableFuture={disableFutureDays}
              disablePast={disablePastDays}
            />
            <DateTimePicker
              date={dates.endDate}
              title="End Date"
              dateError={dateError}
              onChange={handleEndDateTimeChange}
              disablePast={disablePastDays}
            />
          </Grid>
        )}
      </>
      {type && (
        <Grid style={{ alignItems: "baseline", display: "flex" }}>
          <DatePicker
            date={
              (nonDefault && startDateFilter) ||
              (!nonDefault && (startDateFilter || dates.startDate))
            }
            title="Start Date"
            onChange={handleStartDateTimeChange}
            disableFuture={disableFutureDays}
            nonDefault={nonDefault}
          />
          <DatePicker
            date={
              (nonDefault && endDateFilter) ||
              (!nonDefault && (endDateFilter || dates.startDate))
            }
            title="End Date"
            dateError={dateError}
            onChange={handleEndDateTimeChange}
            disableFuture={disableFutureDays}
            nonDefault={nonDefault}
          />
        </Grid>
      )}
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = state => ({
  dates: state.dates,
  startDateFilter: state.filtersQuery.startDate,
  endDateFilter: state.filtersQuery.endDate
});

export default connect(
  mapStateToProps,
  { setStartDate, setEndDate }
)(DateComponent);

DateComponent.propTypes = {
  dates: PropTypes.object,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  startDateFilter: PropTypes.string,
  endDateFilter: PropTypes.string
};
