import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
// @material-ui/core components
import { Typography } from "@material-ui/core";
export default function RecordStatus({ startDate, endDate }) {
  const [status, setStatus] = useState("Not started");

  useEffect(() => {
    let dateNow = Moment.utc().format();
    let currentStatus;
    if (startDate > dateNow) {
      currentStatus = "Not started";
    } else if (startDate <= dateNow && endDate > dateNow) {
      currentStatus = "Ongoing";
    } else {
      currentStatus = "Ended";
    }
    setStatus(currentStatus);
  }, [startDate, endDate]);

  return (
    <>
      {status === "Not started" && (
        <Typography
          variant="subtitle1"
          component="p"
          style={{
            backgroundColor: "rgba(93,120,255,.1)",
            color: "#5d78ff",
            padding: "8px 24px",
            display: "inline-block"
          }}
        >
          Not Started
        </Typography>
      )}
      {status === "Ongoing" && (
        <Typography
          variant="subtitle1"
          component="p"
          style={{
            backgroundColor: "rgba(10,187,135,.1)",
            color: "#0abb87",
            padding: "8px 24px",
            display: "inline-block"
          }}
        >
          Ongoing
        </Typography>
      )}
      {status === "Ended" && (
        <Typography
          variant="subtitle1"
          component="p"
          style={{
            backgroundColor: "rgba(253,57,122,.1)",
            color: "#fd397a",
            padding: "8px 24px",
            display: "inline-block"
          }}
        >
          Ended
        </Typography>
      )}
    </>
  );
}

RecordStatus.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string
};
