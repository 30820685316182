import { lighten } from "@material-ui/core/styles";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "10%",
    textTransform: "capitalize",
    marginTop: 0,
    marginBottom: 0,
    "&  > div": {
      height: 40
    }
  },
  menu: {
    textTransform: "capitalize"
  }
});

export default styles;
