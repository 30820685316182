export default {
  application: {
    // https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
    countries: [
      {
        name: "Egypt",
        code: "EG",
        currency: "EGP"
      },
      {
        name: "Nigeria",
        code: "NG",
        currency: "NGN"
      }
    ],
    defaultCountryCode: "NG",
    // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
    languages: [
      {
        name: "English",
        code: "EN"
      },
      {
        name: "Arabic",
        code: "AR"
      }
    ]
  },
  dashboard: {
    redemption: {
      option: {
        denomination: {
          constraints: { min: 0, max: 2147483648 }
        }
      }
    },
    deals: {
      countriesDelimiter: "$$$"
    },
    basePath:
      process.env.NODE_ENV === "production"
        ? "/admin"
        : "/admin-dashboard/admin"
  }
};
