import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, generatePath } from "react-router-dom";

import withReducer from "../../../../store/withReducer";

import WaslaTheme from "../../../../modules/wasla-theme";

import * as pointsService from "../../../../services/points";

import activityReducers from "../../../../main/users/user/activity/reducers";

import { updateActivity } from "../../../../main/users/user/activity/actions/activity";

import configurations from "../../../../configurations";

import { usersPath } from "../../paths";
import { userPath } from "../paths";

import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import CashbackActivity from "./cashbackActivityForm";
import CsActivity from "./csActivityForm";

const Activity = props => {
  const { activity, updateActivity } = props;
  const [loading, setLoading] = useState(true);

  const { id, activityId } = useParams();

  useEffect(async () => {
    setLoading(true);
    const res = await pointsService
      .getUserActivity(id, activityId)
      .catch(error => ({}));
    updateActivity(res);
    setLoading(false);
  }, []);

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={configurations.dashboard.basePath}
          >
            CS Dashboard
          </Link>
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, usersPath].join("")}
          >
            CS Table
          </Link>
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[
              configurations.dashboard.basePath,
              generatePath(userPath, {
                id
              })
            ].join("")}
          >
            History
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            History Details
          </Typography>
        </Breadcrumbs>
        <Grid container justifyContent="center">
          <Grid item lg={6}>
            {activity.sourceType !== "cs" && (
              <CashbackActivity activity={activity} loading={loading} />
            )}
            {activity.sourceType === "cs" && (
              <CsActivity activity={activity} loading={loading} />
            )}
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default withReducer("activity", activityReducers)(
  connect(
    state => ({ activity: state.activity }),
    {
      updateActivity
    }
  )(Activity)
);
