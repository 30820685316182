import {
  GET_OPTIONS,
  DELETE_OPTION,
  UPDATE_OPTION_DENOMINATION_STATUS
} from "../../actions/options";

const initialState = {
  data: [],
  meta: {
    total: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPTIONS:
      return {
        ...state,
        ...action.payload
      };
    case DELETE_OPTION:
      return {
        ...state,
        data: state.data.filter(option => option.id !== action.payload.option),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    case UPDATE_OPTION_DENOMINATION_STATUS:
      return {
        ...state,
        data: state.data.map(option => {
          if (option.id === action.payload.option) {
            return {
              ...option,
              denominations: option.denominations.map(denomination => {
                if (denomination.id === action.payload.denomination) {
                  return {
                    ...denomination,
                    isActive: action.payload.status
                  };
                } else {
                  return denomination;
                }
              })
            };
          } else {
            return option;
          }
        })
      };
    default:
      return state;
  }
};
