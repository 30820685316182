import qs from "qs";
import { missionsBaseUrl } from "../../../components/config";
import { FETCH_MISSIONS } from "../actionType";
import { toggleErrorMessage } from "../ErrorMessageActions";

export const fetchMissions = countrySelected => async dispatch => {
  await missionsBaseUrl
    .get("/missions", {
      params: {
        countryCode: countrySelected?.toUpperCase()
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "comma",
          encode: false,
          skipNulls: true
        });
      }
    })
    .then(res => {
      dispatch({
        type: FETCH_MISSIONS,
        payload: res.data
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};

export const deleteMission = (id, countrySelected) => async dispatch => {
  await missionsBaseUrl
    .delete(`/missions/${id}`)
    .then(() => {
      dispatch(fetchMissions(countrySelected));
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};
