import React from "react";
import PropTypes from "prop-types";
import { Route, useRouteMatch } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";

import DealsTable from "./DealsTable";
import MerchantTable from "./MerchantTable";
import HomePage from "./HomePage";

export default function Main() {
  let match = useRouteMatch();

  return (
    <>
      <Route
        exact
        path={match.path}
        render={routeProps => (
          <ErrorBoundary>
            <HomePage {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={`${match.path}/deals`}
        render={routeProps => (
          <ErrorBoundary>
            <DealsTable {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={`${match.path}/merchant`}
        render={routeProps => (
          <ErrorBoundary>
            <MerchantTable {...routeProps} />
          </ErrorBoundary>
        )}
      />
    </>
  );
}

Main.propTypes = {
  match: PropTypes.object
};
