import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setMissionCardDefinition } from "../../../../redux/action/Missions/MissionsDefinitionActions";
// core components
import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const MissionDescription = ({ missions, setMissionCardDefinition }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const checkFieldLimit = (input, limit) => {
    if (input.length <= limit) {
      return true;
    } else {
      return false;
    }
  };

  const handleNameChange = event => {
    let getLimitNumber = event.target.name.includes("Title") ? 50 : 3000;
    let checkLimit = checkFieldLimit(event.target.value, getLimitNumber);

    if (checkLimit) {
      setMissionCardDefinition({
        ...missions,
        [event.target.name]: event.target.value
      });
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <TextField
        id="english-title"
        name="englishTitle"
        label="Mission Title (En)"
        className={classes.textField}
        value={missions.englishTitle}
        onChange={handleNameChange}
        error={error}
        helperText="Max no. of characters is 50"
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 50
        }}
      />

      <TextField
        id="arabic-title"
        name="arabicTitle"
        label="Mission Title (Ar)"
        className={classes.textField}
        value={missions.arabicTitle}
        onChange={handleNameChange}
        error={error}
        helperText="Max no. of characters is 50"
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 50
        }}
      />

      <TextField
        id="english-description"
        name="englishDescription"
        label="Description (En)"
        className={classes.textField}
        value={missions.englishDescription}
        onChange={handleNameChange}
        error={error}
        helperText="Max no. of characters is 3000"
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 3000
        }}
        multiline
        fullWidth
        rows="4"
      />

      <TextField
        id="arabic-description"
        name="arabicDescription"
        label="Description (Ar)"
        className={classes.textField}
        value={missions.arabicDescription}
        onChange={handleNameChange}
        error={error}
        helperText="Max no. of characters is 3000"
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 3000
        }}
        multiline
        fullWidth
        rows="4"
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    missions: state.missions
  };
};

export default connect(
  mapStateToProps,
  { setMissionCardDefinition }
)(MissionDescription);

MissionDescription.propTypes = {
  missions: PropTypes.object,
  setMissionCardDefinition: PropTypes.func
};
