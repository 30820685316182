import React from "react";
import {
  Router,
  Route,
  Switch,
  Redirect,
  withRouter,
  matchPath
} from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";
import routes, { dashboardRoutes } from "./routes.js";
// core components
import Admin from "./layouts/Admin/index";
import Main from "./layouts/Main/index";
import Blank from "./layouts/Blank";
// import RTL from "./layouts/RTL.js";
import { basename } from "./config";
import { init as initApm } from "@elastic/apm-rum";

const apm = initApm({
  serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
  serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
  environment: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT,
  serviceVersion: "1.4.5"
});

const App = props => {
  const { location } = props;

  const currentPath = routes.find(route =>
    matchPath(location.pathname, {
      path: route.path
    })
  );

  return (
    <Switch>
      {(layout => {
        if (layout === "main")
          return (
            <Route
              render={props => (
                <Main
                  {...props}
                  requiredRoles={["admin", "merchant", "cs"]}
                  basename={basename}
                />
              )}
              path={basename}
            />
          );
        if (layout === "blank")
          return <ApmRoute component={Blank} path={basename} />;
        return (
          <Route
            render={props => (
              <Admin
                {...props}
                requiredRoles={["admin", "merchant", "cs"]}
                basename={basename}
              />
            )}
            path={basename}
          />
        );
      })(currentPath?.layout)}
      {/* <Route path="/rtl" component={RTL} /> */}
      {/* <Redirect to="/admin/dashboard" /> */}
    </Switch>
  );
};

export default withRouter(App);
