export const headCells = [
  {
    id: "nameEn",
    numeric: false,
    disablePadding: false,
    label: "Name (En)",
    minWidth: 140
  },
  {
    id: "nameAr",
    numeric: false,
    disablePadding: true,
    label: "Name (Ar)",
    minWidth: 140
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "ID",
    minWidth: 170
  },
  {
    id: "descriptionEn",
    numeric: false,
    disablePadding: false,
    label: "Description (En)",
    minWidth: 180
  },
  {
    id: "descriptionAr",
    numeric: false,
    disablePadding: false,
    label: "Description (Ar)",
    minWidth: 180
  },
  {
    id: "logo",
    numeric: false,
    disablePadding: false,
    label: "Logo",
    minWidth: 170
  },
  {
    id: "Terms&ConditionsEn",
    numeric: false,
    disablePadding: false,
    label: "Terms & Conditions (En)",
    minWidth: 180
  },
  {
    id: "Terms&ConditionsAr",
    numeric: false,
    disablePadding: false,
    label: "Terms & Conditions (Ar)",
    minWidth: 180
  },
  {
    id: "CountryCode",
    numeric: false,
    disablePadding: false,
    label: "Countries",
    minWidth: 170
  },
  {
    id: "Url",
    numeric: false,
    disablePadding: false,
    label: "Url",
    minWidth: 170
  },
  {
    id: "isPopular",
    numeric: false,
    disablePadding: true,
    label: "isPopular",
    minWidth: 170
  },
  {
    id: "order",
    numeric: false,
    disablePadding: true,
    label: "order",
    minWidth: 170
  },
  {
    id: "delete-action",
    numeric: false,
    disablePadding: true,
    label: "Delete",
    minWidth: 100
  },
  {
    id: "edit-action",
    numeric: false,
    disablePadding: true,
    label: "Edit",
    minWidth: 100
  }
];
