import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// redux actions
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import PointConfigList from "./ListView";
import styles from "../styles.css";

const useStyles = makeStyles(styles);

const PointConfig = props => {
  const classes = useStyles();
  const { availableError } = props;

  if (availableError) {
    throw new Error();
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Points config</h4>
          </CardHeader>
        </Card>
      </GridItem>
      <PointConfigList />
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    configItems: state.configItems.configItems,
    errConfigPoints: state.configItems.errConfigPoints,
    availableError: state.availableError.availableError
  };
};
export default connect(mapStateToProps)(PointConfig);

PointConfig.propTypes = {
  availableError: PropTypes.bool,
  configItems: PropTypes.object
};
