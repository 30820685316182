import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CardContent } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
// core components
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody.js";

import styles from "./styles.css";

const useStyles = makeStyles(styles);

const AnnouncementCard = ({
  bgPrimaryColor,
  bgSecondaryColor,
  textColor,
  btnBgColor,
  btnTextColor,
  title,
  body,
  btnText,
  imgUrl,
  flexBasis,
  direction
}) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.cardContainer}
      style={{
        background: `linear-gradient(${bgPrimaryColor}, ${bgSecondaryColor})`,
        flexBasis: flexBasis,
        direction: direction
      }}
    >
      <CardBody className={classes.cardBody}>
        <CardContent className={classes.content}>
          <Typography
            component="h5"
            variant="h5"
            gutterBottom={true}
            style={{
              color: textColor
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            paragraph={true}
            style={{
              color: textColor
            }}
          >
            {body}
          </Typography>
          {btnText && (
            <Button
              variant="contained"
              size="medium"
              style={{
                backgroundColor: btnBgColor,
                color: btnTextColor,
                borderRadius: "30px"
              }}
            >
              {btnText}
            </Button>
          )}
        </CardContent>
        <CardMedia
          className={classes.cover}
          image={imgUrl}
          title="Announcement card icon"
        />
      </CardBody>
    </Card>
  );
};

export default AnnouncementCard;

AnnouncementCard.propTypes = {
  bgPrimaryColor: PropTypes.string,
  textColor: PropTypes.string,
  bgSecondaryColor: PropTypes.string,
  btnBgColor: PropTypes.string,
  btnTextColor: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  btnText: PropTypes.string,
  imgUrl: PropTypes.string,
  flexBasis: PropTypes.string,
  direction: PropTypes.string
};
