import {
  SET_PROJECT_NAME,
  SET_LOADING_PROJECT_LIST,
  FETCH_PROJECT_LIST,
  SET_ERROR_CREATE_PROJECT,
  REST_PROJECT_STATE,
  SET_SUCCESS_CREATE_PROJECT
} from "../../action/actionType";

const initialState = {
  name: "",
  isLoading: false,
  list: [],
  error: false,
  success: false,
  hasNext: false,
  serverError: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_NAME:
      return {
        ...state,
        name: action.payload
      };
    case SET_LOADING_PROJECT_LIST: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case FETCH_PROJECT_LIST: {
      const arr = [...state.list, ...action.payload?.data];
      let uniqueItem = arr.filter((c, index) => {
        return arr.indexOf(c) === index;
      });
      return {
        ...state,
        list: uniqueItem,
        hasNext: action.payload?.meta?.has_next
      };
    }
    case SET_ERROR_CREATE_PROJECT: {
      return {
        ...state,
        error: action.payload
      };
    }
    case SET_SUCCESS_CREATE_PROJECT: {
      return {
        ...state,
        success: true,
        list: []
      };
    }
    case "SET_ERROR_LIST": {
      return {
        ...state,
        serverError: action.payload
      };
    }
    case REST_PROJECT_STATE: {
      return {
        ...initialState,
        list: state.list,
        hasNext: state.hasNext
      };
    }
    default:
      return state;
  }
};
