import {
  CREATE_AND_UPDATE_ADS_RECORD,
  RESET_STATE
} from "../../action/actionType";
import Moment from "moment";

let dateNow = Moment(Date.now()).utc();
const initialState = {
  id: "",
  estimatedValue: "",
  actualValue: "",
  compensationPercentage: "",
  createdDate: dateNow.format()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_AND_UPDATE_ADS_RECORD:
      return {
        ...state,
        id: action.payload.id,
        estimatedValue: action.payload.estimatedValue,
        actualValue: action.payload.actualValue || "",
        compensationPercentage: action.payload.compensationPercentage,
        createdDate: action.payload.createdDate
      };
    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
