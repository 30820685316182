import { UPDATE_CATEGORY } from "../../actions/origin";

const initialState = {
  nameEn: "",
  nameAr: "",
  logoUrl: null,
  status: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CATEGORY:
      return {
        ...state,
        ...action.payload.category
      };
    default:
      return state;
  }
};
