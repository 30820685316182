import { combineReducers } from "redux";

import country from "./country";
import configurations from "./configurations";
import userRequests from "./user-requests";
import options from "./options";
import option from "./option";
import layout from "./layout";

export default combineReducers({
  country,
  configurations,
  userRequests,
  options,
  option,
  layout
});
