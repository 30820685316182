import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";

import styles from "./CardContainerStyles";

const CardContainer = ({ title, children, subtitle }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <Card className={classes.card}>
          {title && (
            <CardHeader
              color="warning"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4 className={classes.cardTitle}>{title}</h4>
              {subtitle && <h6 className={classes.cardTitle}>{subtitle}</h6>}
            </CardHeader>
          )}
          {children}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CardContainer;

CardContainer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};
