import React from "react";

import Stack from "@mui/material/Stack";

const FormCard = props => {
  return <Stack {...props} />;
};

FormCard.defaultProps = {
  spacing: 3
};

export default FormCard;
