import { combineReducers } from "redux";

import layout from "./layout";
import exchangeRates from "./exchange-rates";
import exchangeRatesHistory from "./exchange-rates/history";

export default combineReducers({
  layout,
  exchangeRates,
  exchangeRatesHistory
});
