import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

// redux actions
import {
  UpdateCashbackConfig,
  submitUpdateCashbackConfig
} from "../../../../redux/action/Configuration/cashbackConfig/FetchCashbackConfig";
// core components

import CardBody from "../../../../components/Card/CardBody.js";
import ConfirmModal from "../../../Modals/ConfirmModal";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import ErrorMessage from "../../../ErrorMessage";

import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const MissionsConfigUpdate = props => {
  const classes = useStyles();
  const {
    location,
    cashbackItem,
    UpdateCashbackConfig,
    submitUpdateCashbackConfig,
    redirect,
    errUpdateCashbackConfig,
    error
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const { pathname } = useLocation();
  const viewType = location.state ? location.state.viewType : "";
  const item = location?.state?.item;

  useEffect(() => {
    if (location?.state && viewType === "Edit") {
      UpdateCashbackConfig(
        location?.state && { exchangeRateId: item.id, rate: item.exchangeRate }
      );
    }
    if (errUpdateCashbackConfig) {
      setOpenModal(false);
    }
  }, [UpdateCashbackConfig, errUpdateCashbackConfig]);

  const handleChange = event => {
    UpdateCashbackConfig({
      exchangeRateId: item.id,
      [event.target.name]: Number(event.target.value)
    });
  };

  const handleSubmit = () => {
    submitUpdateCashbackConfig(cashbackItem);
  };

  useEffect(() => {
    if (redirect || error) {
      setOpenModal(false);
    }
  }, [redirect, error]);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  const removeLastDirectoryPartOf = () => {
    let the_arr = pathname.split("/");

    if (viewType === "Edit") {
      the_arr.pop();
      the_arr.pop();
      return the_arr.join("/");
    }
    the_arr.pop();

    return the_arr.join("/");
  };

  return (
    <GridContainer>
      {error && (
        <ErrorMessage message="Sorry something went wrong!" msgStatus="error" />
      )}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Points config</h4>
          </CardHeader>
          <CardBody>
            <Grid>
              <TextField
                id="rate"
                name="rate"
                label="Rate"
                value={cashbackItem?.rate}
                className={classes.textField}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
              />
              {/* <TextField
                id="USD"
                name="USD"
                label="USD"
                defaultValue={1}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              /> */}
            </Grid>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              style={{
                width: "10%",
                margin: "16px"
              }}
              onClick={handleOpen}
            >
              save
            </Button>
          </CardBody>
        </Card>
      </GridItem>
      {redirect ? (
        <Redirect from="/:url*(/+)" to={removeLastDirectoryPartOf()} />
      ) : null}
      {openModal && (
        <ConfirmModal
          open={openModal}
          handleEdit={handleSubmit}
          onClose={handleClose}
          type="edit"
        />
      )}
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    cashbackItem: state.cashbackConfig.cashbackItem,
    redirect: state.cashbackConfig.redirect,
    errUpdateCashbackConfig: state.cashbackConfig.errUpdateCashbackConfig,
    error: state.errorMessage.error
  };
};

export default connect(
  mapStateToProps,
  { UpdateCashbackConfig, submitUpdateCashbackConfig }
)(MissionsConfigUpdate);

MissionsConfigUpdate.propTypes = {
  UpdateCashbackConfig: PropTypes.func,
  submitUpdateCashbackConfig: PropTypes.func,
  cashbackItem: PropTypes.object,
  location: PropTypes.object,
  redirect: PropTypes.bool,
  errUpdateCashbackConfig: PropTypes.bool
};
