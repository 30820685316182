import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// core components
// redux actions
import { clearTypeOfTransfer } from "../../../redux/action/PointsSystem/TransferPointsActions";
import styles from "./styles.css";

import TransferPointsContainer from "../../PointsSystem/TestingPoints/Form/index";

const useStyles = makeStyles(styles);

const PointsView = props => {
  const classes = useStyles();
  const { clearTypeOfTransfer } = props;

  useEffect(() => {
    return () => {
      clearTypeOfTransfer();
    };
  }, []);

  return (
    <>
      <h4 className={classes.sectionTitle}>Points Dashboard</h4>
      <TransferPointsContainer />
    </>
  );
};

export default connect(
  null,
  { clearTypeOfTransfer }
)(PointsView);

PointsView.propTypes = {
  clearTypeOfTransfer: PropTypes.func
};
