import { FETCH_ADS_BUCKET, FETCH_ADS_REWARDS } from "../../action/actionType";

const initialState = {
  adsItems: [],
  rewardsItems: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADS_BUCKET:
      return {
        ...state,
        adsItems: action.payload
      };
    case FETCH_ADS_REWARDS:
      return {
        ...state,
        rewardsItems: action.payload
      };
    default:
      return state;
  }
};
