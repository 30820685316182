import { UPDATE_DISPLAYED_COLUMNS } from "../../actions/layout";

const initialState = {
  displayedColumns: [
    "countryCode",
    "conversionDate",
    "userId",
    "dealId",
    "shoppingId",
    "transactionId",
    "transactionValue",
    "commission",
    "cashback",
    "preApprovedCashbackPoints",
    "preApprovedExchangeRate",
    "postApprovedExchangeRate",
    "postApprovedCashbackPoints",
    "status",
    "details",
    "actionBy",
    "actionAt"
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DISPLAYED_COLUMNS:
      return {
        ...state,
        displayedColumns: action.payload.displayedColumns
      };
    default:
      return state;
  }
};
