import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// redux actions
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { Typography } from "@material-ui/core";

import styles from "./styles.css";
const useStyles = makeStyles(styles);

const ConfigList = props => {
  const classes = useStyles();
  const {
    availableError,
    match: { path = "" }
  } = props;

  if (availableError) {
    throw new Error();
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Configuration</h4>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardBody className={classes.cardBody}>
            <Link
              to={{
                pathname: `${path}/MissionsConfig`
              }}
            >
              <Typography
                component="h2"
                variant="h5"
                color="textPrimary"
                style={{
                  textDecoration: "none"
                }}
              >
                Missions Config
              </Typography>
            </Link>
          </CardBody>
        </Card>
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardBody className={classes.cardBody}>
            <Link
              to={{
                pathname: `${path}/pointConfig`
              }}
            >
              <Typography
                component="h2"
                variant="h5"
                color="textPrimary"
                style={{
                  textDecoration: "underline"
                }}
              >
                Points Config
              </Typography>
            </Link>
          </CardBody>
        </Card>
      </GridItem> */}
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardBody className={classes.cardBody}>
            <Link
              to={{
                pathname: `${path}/cashBackConfig`
              }}
            >
              <Typography
                component="h2"
                variant="h5"
                color="textPrimary"
                style={{
                  textDecoration: "none"
                }}
              >
                Cashback Config
              </Typography>
            </Link>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardBody className={classes.cardBody}>
            <Link
              to={{
                pathname: `${path}/splashScreenConfig`
              }}
            >
              <Typography
                component="h2"
                variant="h5"
                color="textPrimary"
                style={{
                  textDecoration: "none"
                }}
              >
                Splash Screen Config
              </Typography>
            </Link>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    availableError: state.availableError.availableError
  };
};
export default connect(mapStateToProps)(withRouter(ConfigList));

ConfigList.propTypes = {
  availableError: PropTypes.bool
};
