import {
  UPDATE_Mission_CONFIG,
  UPDATE_Mission_CONFIG__SUSSES,
  UPDATE_Mission_CONFIG__Error
} from "../../actionType";
import { adminBaseUrl } from "../../../../components/config";

export const UpdateMissionConfig = data => {
  return {
    type: UPDATE_Mission_CONFIG,
    payload: data
  };
};

export const submitUpdateMissionConfig = data => async dispatch => {
  await adminBaseUrl
    .put(`/config`, data)
    .then(() => {
      dispatch({
        type: UPDATE_Mission_CONFIG__SUSSES
      });
    })
    .catch(err => {
      dispatch({
        type: UPDATE_Mission_CONFIG__Error
      });
    });
};
