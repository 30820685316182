import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormCard from "../../components/FormCard";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormCardHead from "../../components/FormCardHead";
import FormCardBody from "../../components/FormCardBody";
import configurations from "../../configurations";

import egypt from "./assets/EG.png";
import nigeria from "./assets/nigeria.png";
import styles from "./CountryForm.css";

const useStyles = makeStyles(styles);

const CountryForm = ({ nextPath }) => {
  const classes = useStyles();
  const history = useHistory();
  const [countryCode, setCountryCode] = useState(
    configurations.application.defaultCountryCode
  );

  const getFlag = countryCode => {
    switch (countryCode) {
      case "EG":
        return egypt;
      case "NG":
        return nigeria;
      default:
        return null;
    }
  };

  const handleCountrySubmit = () => {
    history.push(
      [configurations.dashboard.basePath, `${nextPath}/${countryCode}`].join("")
    );
  };

  const handleCountryChange = e => {
    setCountryCode(e.target.value);
  };
  return (
    <FormCard>
      <FormCardHead variant="underline">
        <Typography
          variant="p"
          component="p"
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "28px",
            color: "#101828"
          }}
        >
          Country
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{
            fontSize: "14px",
            lineHeight: "20px",
            color: "#667085"
          }}
        >
          Select country to proceed
        </Typography>
      </FormCardHead>
      <FormCardBody>
        <Card variant="outlined" className={classes.cardCountryForm}>
          <CardContent>
            <FormControl sx={{ width: "100%" }}>
              <Select
                value={countryCode}
                onChange={handleCountryChange}
                displayEmpty
                id="country-dropdown"
                inputProps={{
                  id: "=country"
                }}
                MenuProps={{
                  id: "country-menu"
                }}
                disabled={configurations.application.countries.length === 1}
              >
                {configurations.application.countries.map(country => (
                  <MenuItem key={country.code} value={country.code}>
                    <Stack spacing={1} direction="row">
                      <img src={getFlag(country.code)} />
                      <span>{country.name}</span>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
          <CardActions
            sx={{
              borderTop: 1,
              borderColor: "divider",
              pb: 1,
              justifyContent: "end"
            }}
          >
            <Stack spacing={2} p={1} direction="row">
              <Button
                variant="contained"
                onClick={handleCountrySubmit}
                id="countryForm-next"
                className={classes.nextBTN}
              >
                Next
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </FormCardBody>
    </FormCard>
  );
};

export default CountryForm;
