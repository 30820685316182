import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import customStyle from "./style.css";

const useStyles = makeStyles(customStyle);

function ServiceCatalogueContent(props) {
  const classes = useStyles();

  return (
    <div aria-label="service catalogue">
      <ul className={classes.tableHeader}>
        <li>Available Operators</li>
        <li>Minimum Recharge</li>
        <li>Maximum Recharge</li>
        <li>Taxes</li>
      </ul>
      <ul className={classes.tableContent}>
        <li>
          <div className={classes.tableRow}>
            <p>Etisalat Egypt</p>
            <p>
              Gross = 7.14 <br /> Net = 5
            </p>
            <p>
              Gross = 357 <br /> Net = 250
            </p>
            <p>42.8%</p>
          </div>
        </li>
        <li>
          <div className={classes.tableRow}>
            <p>Vodafone Egypt</p>
            <p>
              Gross = 7.14 <br /> Net = 5
            </p>
            <p>
              Gross = 285.6 <br /> Net = 200
            </p>
            <p>42.8%</p>
          </div>
        </li>
        <li>
          <div className={classes.tableRow}>
            <p>Orange Egypt</p>
            <p>
              Gross = 7.14 <br /> Net = 5
            </p>
            <p>
              Gross = 714 <br /> Net = 500
            </p>
            <p>42.8%</p>
          </div>
        </li>
        <li>
          <div className={classes.tableRow}>
            <p>We Egypt</p>
            <p>
              Gross = 7.14 <br /> Net = 5
            </p>
            <p>
              Gross = 428.4 <br /> Net = 300
            </p>
            <p>42.8%</p>
          </div>
        </li>
      </ul>
      <div>
        <div className={classes.tableHeader}>
          <h5 className={classes.headerInfo}>Other Information</h5>
        </div>
        <ul className={classes.listInfo}>
          <li>Telecom tax in Egypt is 42.8%.</li>
          <li>All amounts entered are Gross (incl. Taxes).</li>
          <li>
            Due to limitations set by the telecom operators, unique numbers must
            be charged with a 10-15 minute buffer. This is to protect from
            fraudulent activity.
          </li>
          <li>
            TIP: Easily calculate Gross/Net conversion as follows
            <ul>
              <li>Net = Gross amount / 1.428</li>
              <li>Gross = Net amount x 1.428</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ServiceCatalogueContent;
