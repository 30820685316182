export const UPDATE_ITEMS = "update users list items";
export const UPDATE_ITEM = "update users list item";

export const updateItems = items => dispatch =>
  dispatch({
    type: UPDATE_ITEMS,
    payload: { items }
  });

export const updateItem = (id, item) => dispatch =>
  dispatch({
    type: UPDATE_ITEM,
    payload: { id, item }
  });
