export const buttonLinkType = [
  {
    value: "url",
    label: "URL"
  },
  {
    value: "pwa",
    label: "PWA"
  },
  {
    value: "inApp",
    label: "In-App"
  }
];

export const pwaType = [
  {
    value: "missions",
    label: "Wasla mission"
  }
];

export const inAppType = [
  {
    value: "donation",
    label: "Charity Donation"
  },
  {
    value: "fakka",
    label: "Fakka"
  },
  {
    value: "wallet",
    label: "Wallet"
  },
  {
    value: "register",
    label: "Registration"
  },
  {
    value: "for-you",
    label: "For you"
  },
  {
    value: "trending",
    label: "Trending"
  },
  {
    value: "videos",
    label: "Videos"
  }
];

export const missionsType = [
  {
    value: "survey",
    label: "Survey"
  },
  {
    value: "download",
    label: "Download"
  },
  {
    value: "watch ads video",
    label: "Watch ads video"
  }
];
