import React, { useRef } from "react";
import PropTypes from "prop-types";
import { KeyboardDatePicker } from "@material-ui/pickers";

const DateTimePicker = React.memo(
  ({ date, title, dateError, onChange, disableFuture }) => {
    return (
      <>
        <KeyboardDatePicker
          disableFuture={disableFuture}
          error={dateError}
          helperText={dateError && "End date should be greater than Start date"}
          style={{ margin: "8px", width: "37.5%" }}
          disableToolbar
          variant="outlined"
          format="YYYY/MM/DD"
          margin="normal"
          id={title}
          label={title}
          value={date || null}
          onChange={onChange}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          inputVariant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />
      </>
    );
  }
);
export default DateTimePicker;
DateTimePicker.propTypes = {
  title: PropTypes.string,
  dateError: PropTypes.bool,
  onChange: PropTypes.func
};
