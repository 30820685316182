import { FETCH_QUICK_LINKS, DELETE_QUICK_LINK } from "../../action/actionType";

const initialState = {
  linksList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUICK_LINKS:
      return {
        ...state,
        linksList: action.payload
      };

    case DELETE_QUICK_LINK:
      return {
        ...state,
        linksList: state.linksList.filter(item => item.id !== action.payload)
      };

    default:
      return state;
  }
};
