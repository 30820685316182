import { announcementBaseUrl } from "../../../components/config";
import { GET_ANNOUNCEMENT_CARD_THEMES, SET_CARD_THEME } from "../actionType";

export const fetchThemes = () => async dispatch => {
  await announcementBaseUrl.get(`/themes`).then(response => {
    dispatch({
      type: GET_ANNOUNCEMENT_CARD_THEMES,
      payload: response.data
    });
  });
};

export const setCardTheme = value => {
  return {
    type: SET_CARD_THEME,
    payload: value
  };
};
