import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";
import { setFinialTotalRecharge } from "../../../redux/action/WaslaRewards/EditUsersRechargeAction.js";
// core components
import styles from "../styles.css";
import { flatten } from "./helper";
const useStyles = makeStyles(styles);

function RechargeInfo(props) {
  const classes = useStyles();
  const {
    checkRequiredFields,
    currentBalance,
    totalUsersRechargeFailed,
    setFinialTotalRecharge
  } = props;
  const [error, setError] = useState(false);
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalTransactionValue, setTotalTransactionValue] = useState(0);
  const [totalTransactionNetValue, setTotalTransactionNetValue] = useState(0);
  let flattened = [];

  useEffect(() => {
    if (totalUsersRechargeFailed.length !== 0) {
      flatten(totalUsersRechargeFailed, flattened);
    }
  }, [totalUsersRechargeFailed]);

  useEffect(() => {
    if (flattened.length !== 0) {
      setFinialTotalRecharge(flattened);
      setTotalUsers(flattened);
    }
  }, [flattened]);

  const transactionValue = totalUsers.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.price;
  }, 0);

  const getTotalTransaction = () => {
    setTotalTransactionValue(transactionValue);
    let transactionNetValue = (transactionValue / 1.4285).toFixed(2);
    setTotalTransactionNetValue(transactionNetValue);
  };

  useEffect(() => {
    if (totalUsers.length !== 0) {
      getTotalTransaction();
    }
  }, [totalUsers]);

  useEffect(() => {
    if (currentBalance && totalTransactionValue > currentBalance) {
      setError(true);
      checkRequiredFields(false);
    } else {
      setError(false);
      checkRequiredFields(true);
    }
  }, [totalTransactionValue, currentBalance]);

  return (
    <div className={classes.container}>
      <TextField
        id="number-of-users"
        name="uploadUsersRecharge"
        label="Number of users"
        type="number"
        className={classes.textField}
        value={totalUsers.length}
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          readOnly: true
        }}
      />
      <div className={classes.textFieldsContainer}>
        <TextField
          id="Gross Value (Amount Paid)"
          name="grossValue"
          label="Gross Value (Amount Paid)"
          aria-label="gross value"
          className={classes.textField}
          value={`${totalTransactionValue.toLocaleString()} EGP`}
          margin="normal"
          variant="outlined"
          error={error}
          aria-label="grossValue"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          id="Net Value (Amount send)"
          name="netValue"
          label="Net Value (Amount send)"
          aria-label="net value"
          className={classes.textField}
          value={`${totalTransactionNetValue.toLocaleString()} EGP`}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
        />
      </div>
      {error && (
        <p
          aria-label="error message"
          style={{
            color: "#f44336",
            marginTop: "-16px",
            fontSize: "0.8rem",
            marginLeft: "16px",
            marginRight: "16px"
          }}
        >
          * Not enough balance. Please contact Wasla team to topup your account
        </p>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    currentBalance: state.balanceList.balanceList.currentBalance,
    totalUsersRechargeFailed: state.usersRechargeToEdit.totalUsersRechargeFailed
  };
};

export default connect(
  mapStateToProps,
  {
    checkRequiredFields,
    setFinialTotalRecharge
  }
)(RechargeInfo);

RechargeInfo.propTypes = {
  checkRequiredFields: PropTypes.func,
  setFinialTotalRecharge: PropTypes.func,
  totalUsersRechargeFailed: PropTypes.array,
  currentBalance: PropTypes.number
};
