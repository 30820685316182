import React from "react";

import CardActions from "@mui/material/CardActions";

const FormCardBodyActions = ({ sx, ...props }) => {
  return (
    <CardActions
      disableSpacing
      {...props}
      sx={{ ...sx, borderTop: "1px solid #E4E7EC", padding: "24px 56px" }}
    />
  );
};

export default FormCardBodyActions;
