import {
  addCategory,
  editCategory,
  removeCategory,
  getCategories,
  editCategories
} from "./categories";

export default {
  categories: {
    add: addCategory,
    remove: removeCategory,
    get: getCategories,
    edit: editCategories
  },
  category: {
    add: addCategory,
    edit: editCategory,
    remove: removeCategory
  }
};
