import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Switch, Route } from "react-router-dom";
import routes from "../../routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import BlankAppBar from "./BlankAppBar";
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const theme = createTheme({
  palette: {
    background: {
      default: "#ffffff"
    }
  }
});

const Blank = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.app}>
        <BlankAppBar />
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                path={route.path}
                component={route.component}
                key={index}
              />
            );
          })}
        </Switch>
      </div>
    </ThemeProvider>
  );
};

export default Blank;
