import Categories from "./Categories";
import addConfig from "./add/addConfig";
import editConfig from "./edit/editConfig";
import { quickLinksCategoriesPath } from "../paths";

export default {
  routes: [
    {
      path: quickLinksCategoriesPath,
      component: Categories,
      layout: "main",
      isPrivate: false,
      roles: ["admin"],
      routes: [...addConfig.routes, ...editConfig.routes]
    }
  ]
};
