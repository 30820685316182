import React from "react";

import Typography from "@mui/material/Typography";

const PageTitle = props => {
  const { title } = props;

  return (
    <Typography
      variant="h4"
      component="h1"
      sx={{
        color: "#101828"
      }}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
