import UserRequests from "./UserRequests";
import { redemptionUserRequestsPath } from "../paths";

export default {
  routes: [
    {
      path: redemptionUserRequestsPath,
      component: UserRequests,
      layout: "main",
      isPrivate: false,
      roles: ["admin"],
      routes: []
    }
  ]
};
