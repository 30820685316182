import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, generatePath } from "react-router-dom";

import * as dealsService from "../../../../../services/deals";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Link from "@mui/material/Link";

import Typography from "@mui/material/Typography";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import FormCard from "../../../../../components/FormCard";
import FormCardHead from "../../../../../components/FormCardHead";
import FormCardBody from "../../../../../components/FormCardBody";
import FormCardBodyContent from "../../../../../components/FormCardBodyContent";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";

import WaslaTheme from "../../../../../modules/wasla-theme";

import configurations from "../../../../../configurations";

import { cashbacksPath } from "../../../paths";
import { cashbacksConversionsPath } from "../../paths";

import { updateReasons } from "../../../../../main/cashbacks/conversions/edit-request/actions/reasons";
import {
  updateOldTransactionValue,
  updateOldCommission,
  updateOldCashback,
  updateNewTransactionValue,
  updateNewCommission,
  updateNewCashback,
  updateStatus,
  updateReason,
  updateNotes
} from "../../../../../main/cashbacks/conversions/edit-request/actions/local";
import { updateEditRequest } from "../../../../../main/cashbacks/conversions/edit-request/actions/origin";

import FlagIcon from "../assets/flag.svg";

const HistoryConversionEditRequest = props => {
  const {
    conversionEditRequest: { reasons, origin, local },
    updateReasons,
    updateOldTransactionValue,
    updateOldCommission,
    updateOldCashback,
    updateNewTransactionValue,
    updateNewCommission,
    updateNewCashback,
    updateStatus,
    updateReason,
    updateNotes,
    updateEditRequest
  } = props;

  const [loading, setLoading] = useState(true);

  const { countryCode, id } = useParams();

  useEffect(async () => {
    setLoading(true);
    const [
      conversionEditRequestReasonsRes,
      convertionEditRequestRes
    ] = await Promise.all([
      dealsService.getConversionEditRequestReasons(1, 1000),
      dealsService.getConversionEditRequest(id)
    ]);
    updateReasons(conversionEditRequestReasonsRes.data);
    updateEditRequest({
      ...convertionEditRequestRes,
      oldTransactionValue: convertionEditRequestRes.oldTransactionValue ?? "",
      oldCommission: convertionEditRequestRes.oldCommission ?? "",
      oldCashBack: convertionEditRequestRes.oldCashBack ?? "",
      reason: convertionEditRequestRes.reason ?? "",
      notes: convertionEditRequestRes.notes ?? ""
    });
    updateOldTransactionValue(
      convertionEditRequestRes.oldTransactionValue ?? ""
    );
    updateOldCommission(convertionEditRequestRes.oldCommission ?? "");
    updateOldCashback(convertionEditRequestRes.oldCashBack ?? "");
    updateNewTransactionValue(convertionEditRequestRes.newTransactionValue);
    updateNewCommission(convertionEditRequestRes.newCommission);
    updateNewCashback(convertionEditRequestRes.newCashBack);
    updateStatus(convertionEditRequestRes.status);
    updateReason(convertionEditRequestRes.reason ?? "");
    updateNotes(convertionEditRequestRes.notes ?? "");
    setLoading(false);
  }, []);

  useEffect(() => {
    if (local.reason) {
      const currentReason = reasons.find(item => item.value === local.reason);
      if (currentReason) updateReason(currentReason.id);
    }
  }, [local.reason]);

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, cashbacksPath].join("")}
          >
            Select a country for Cashback
          </Link>
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[
              configurations.dashboard.basePath,
              generatePath(cashbacksConversionsPath, {
                countryCode
              })
            ].join("")}
          >
            Cashbacks Table
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            History
          </Typography>
        </Breadcrumbs>
        <Grid container justifyContent="center">
          <Grid item lg={7}>
            <Box component="form" autoComplete="off">
              <Stack spacing={4}>
                <FormCard>
                  <FormCardHead variant="underline">
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "28px",
                        color: "#101828"
                      }}
                    >
                      History
                    </Typography>
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085"
                      }}
                    >
                      History view for the cashback History
                    </Typography>
                  </FormCardHead>
                  <FormCardBody>
                    <FormCardBodyContent>
                      <Stack spacing={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="reasons"
                                sx={{ transform: "none" }}
                              >
                                Select Reason
                              </InputLabel>
                              <Select
                                displayEmpty
                                value={local.reason}
                                id="reasons-dropdown"
                                inputProps={{
                                  id: "reasons"
                                }}
                                MenuProps={{
                                  id: "reasons-menu"
                                }}
                                required
                                disabled={true}
                                autoFocus={false}
                                sx={{
                                  "&.Mui-disabled": {
                                    backgroundColor: "#F9FAFB",
                                    borderColor: "#D0D5DD"
                                  }
                                }}
                              >
                                {reasons.map(reason => (
                                  <MenuItem key={reason.id} value={reason.id}>
                                    {reason.value}
                                  </MenuItem>
                                ))}
                              </Select>
                              {false && (
                                <FormHelperText>
                                  Reason is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="old-transaction-value"
                                sx={{ transform: "none" }}
                              >
                                GMV old
                              </InputLabel>
                              <Typography>
                                {local.oldTransactionValue
                                  ? `${local.oldTransactionValue} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  GMV old is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="new-transaction-value"
                                sx={{ transform: "none" }}
                              >
                                <span>
                                  <img src={FlagIcon} alt="GMV new" /> GMV new
                                </span>
                              </InputLabel>
                              <Typography sx={{ marginLeft: "26px" }}>
                                {local.newTransactionValue
                                  ? `${local.newTransactionValue} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  GMV new is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="old-commission"
                                sx={{ transform: "none" }}
                              >
                                Wasla commission old
                              </InputLabel>
                              <Typography>
                                {local.oldCommission
                                  ? `${local.oldCommission} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  Wasla commission old is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="new-commission"
                                sx={{ transform: "none" }}
                              >
                                <span>
                                  <img
                                    src={FlagIcon}
                                    alt="Wasla commission new"
                                  />
                                  Wasla commission new
                                </span>
                              </InputLabel>
                              <Typography sx={{ marginLeft: "26px" }}>
                                {local.newCommission
                                  ? `${local.newCommission} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  Wasla commission new is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="old-cashback"
                                sx={{ transform: "none" }}
                              >
                                CB Value old
                              </InputLabel>

                              <Typography>
                                {local.oldCashback
                                  ? `${local.oldCashback} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  CB Value old is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="new-cashback"
                                sx={{ transform: "none" }}
                              >
                                <span>
                                  <img src={FlagIcon} alt="CB Value new" />
                                  CB Value new
                                </span>
                              </InputLabel>
                              <Typography sx={{ marginLeft: "26px" }}>
                                {local.newCashback
                                  ? `${local.newCashback} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  CB Value new is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl error={false} fullWidth>
                              <OutlinedInput
                                value={local.notes}
                                label=""
                                multiline={true}
                                minRows="4"
                                maxRows="4"
                                required
                                disabled={true}
                                placeholder="Detailed notes for the edit*"
                                sx={{
                                  "&.Mui-disabled": {
                                    backgroundColor: "#F9FAFB",
                                    borderColor: "#D0D5DD"
                                  }
                                }}
                              />
                              {false && (
                                <FormHelperText>
                                  Notes is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Stack>
                    </FormCardBodyContent>
                  </FormCardBody>
                </FormCard>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default connect(
  state => ({ conversionEditRequest: state.ConversionEditRequest }),
  {
    updateReasons,
    updateOldTransactionValue,
    updateOldCommission,
    updateOldCashback,
    updateNewTransactionValue,
    updateNewCommission,
    updateNewCashback,
    updateStatus,
    updateReason,
    updateNotes,
    updateEditRequest
  }
)(HistoryConversionEditRequest);
