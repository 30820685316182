const styles = theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    padding: 2,
    fontSize: "24px",
    fontWeight: 500,
    textTransform: "capitalize"
  },

  completedType: {
    backgroundColor: "rgba(10,187,135,.1)",
    color: "#0abb87",
    padding: "6px 32px",
    display: "inline-block",
    margin: "0.2em 0",
    borderRadius: "50px",
    fontSize: "14px",
    width: "45%"
  },
  pendingType: {
    backgroundColor: "rgba(253,57,122,.1)",
    color: "#fd397a",
    padding: "6px 32px",
    display: "inline-block",
    margin: "0.2em 0",
    borderRadius: "50px",
    fontSize: "14px",
    width: "45%"
  },
  reviewType: {
    backgroundColor: "rgba(255,208,11,.8)",
    color: "#000",
    padding: "6px 32px",
    display: "inline-block",
    margin: "0.2em 0",
    borderRadius: "50px",
    fontSize: "14px",
    width: "45%"
  },
  tableContainer: {
    "& .MuiTable-root": {
      backgroundColor: "#fff"
    },
    "& .MuiTableHead-root": {
      color: "#000",
      backgroundColor: "#fafafa",
      "& .MuiTableCell-root": {
        fontWeight: 400,
        minWidth: "160px"
      }
    }
  },
  disabledRow: {
    cursor: "default"
  },
  enableRow: {
    cursor: "pointer"
  },
  defaultButton: {
    fontWeight: "500",
    border: "1px solid transparent",
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      fontSize: "1.2rem"
    },
    "& a": {
      color: "#000"
    }
  },
  primaryButton: {
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    color: "#ffff",
    "&:hover": {
      background: "transparent",
      color: "#ffa726",
      border: "1px solid #ffa726"
    }
  }
});

export default styles;
