import React from "react";
import { Link, withRouter } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import styles from "./styles.css";

import withReducer from "../../store/withReducer";
import QuickLinksReducers from "../../main/quick-links/reducers";

import { basename } from "../../config";
import { quickLinksCategoriesPath, quickLinksLinksPath } from "./paths";

const useStyles = makeStyles(styles);

const QuickLinks = props => {
  const { match } = props;

  const links = [
    {
      key: 1,
      url: [basename, quickLinksCategoriesPath].join(""),
      label: "Categories"
    },
    {
      key: 2,
      url: [basename, quickLinksLinksPath].join(""),
      label: "َQuick Links"
    }
  ];

  const classes = useStyles();

  return (
    <>
      <Card>
        <CardHeader color="warning">
          <h4 className={classes.cardTitleWhite}>Quick Links</h4>
        </CardHeader>
      </Card>
      <Grid container spacing={2}>
        {links.map(link => (
          <Grid key={link.key} item xs={12} sm={6} md={4} lg={3}>
            <Link to={link.url}>
              <p className={classes.paper}>{link.label}</p>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default withReducer("_quickLinks", QuickLinksReducers)(
  withRouter(QuickLinks)
);
