import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { Typography } from "@material-ui/core";

const ErrorsContainer = ({ errors }) => {
  return (
    <>
      {errors.dimension && (
        <Typography component="p" variant="body2" color="error">
          Upload an image with equal width &amp; height
        </Typography>
      )}
      {errors.isRequired && (
        <Typography component="p" variant="body2" color="error">
          This field is required
        </Typography>
      )}
      {errors.size && (
        <Typography component="p" variant="body2" color="error">
          Upload an image with max size of 50KB
        </Typography>
      )}
      {errors.extensions && (
        <Typography component="p" variant="body2" color="error">
          Upload a .png or .jpeg or .jpg or .webp image
        </Typography>
      )}
    </>
  );
};

export default ErrorsContainer;

ErrorsContainer.propTypes = {
  errors: PropTypes.objectOf(PropTypes.bool)
};
