import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

// redux actions
import {
  UpdateMissionConfig,
  submitUpdateMissionConfig
} from "../../../../redux/action/Configuration/MissionsConfig/MissionConfigAction";
// core components
// import Moment from "moment";

// import Table from "components/Table/Table.js";
import CardBody from "../../../../components/Card/CardBody.js";
// import ConfirmModal from "../Modals/ConfirmModal";
import ConfirmModal from "../../../Modals/ConfirmModal";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import ErrorMessage from "../../../ErrorMessage";

import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const MissionsConfigUpdate = props => {
  const classes = useStyles();
  const {
    location,
    usdToEgp,
    UpdateMissionConfig,
    submitUpdateMissionConfig,
    redirect,
    errUpdateMissionsConfig
  } = props;

  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (location.state && location.state.viewType === "Edit") {
      UpdateMissionConfig(location.state && location.state.item);
    }
    if (errUpdateMissionsConfig) {
      setOpenModal(false);
    }
  }, [UpdateMissionConfig, errUpdateMissionsConfig]);

  const handleChange = event => {
    UpdateMissionConfig({
      [event.target.name]: Number(event.target.value)
    });
  };

  const handleSubmit = () => {
    submitUpdateMissionConfig(usdToEgp);
  };

  useEffect(() => {
    if (redirect) {
      setOpenModal(false);
    }
  }, [redirect]);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  return (
    <GridContainer>
      {errUpdateMissionsConfig && (
        <ErrorMessage message="Sorry something went wrong!" />
      )}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Points config</h4>
          </CardHeader>
          <CardBody>
            <Grid>
              <TextField
                id="EGP"
                name="usdToEgp"
                label="EGP"
                value={`${usdToEgp.usdToEgp}`}
                className={classes.textField}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="USD"
                name="USD"
                label="USD"
                defaultValue={1}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              style={{
                width: "10%",
                margin: "16px"
              }}
              onClick={handleOpen}
            >
              save
            </Button>
          </CardBody>
        </Card>
      </GridItem>
      {redirect ? <Redirect to="/admin/Config/MissionsConfig" /> : null}
      {openModal && (
        <ConfirmModal
          open={openModal}
          handleEdit={handleSubmit}
          onClose={handleClose}
          type="edit"
        />
      )}
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    usdToEgp: state.missionConfigUpdate.usdToEgp,
    redirect: state.missionConfigUpdate.redirect,
    errUpdateMissionsConfig: state.missionConfigUpdate.errUpdateMissionsConfig
  };
};

export default connect(
  mapStateToProps,
  { UpdateMissionConfig, submitUpdateMissionConfig }
)(MissionsConfigUpdate);

MissionsConfigUpdate.propTypes = {
  UpdateMissionConfig: PropTypes.func,
  submitUpdateMissionConfig: PropTypes.func,
  usdToEgp: PropTypes.object,
  location: PropTypes.object,
  redirect: PropTypes.bool,
  errUpdateMissionsConfig: PropTypes.bool
};
