import { makeStyles } from "@material-ui/core/styles";

import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import styles from "../styles.css";

import Form from "./Form";

const useStyles = makeStyles(styles);

const SplashScreenConfig = props => {
  const classes = useStyles();
  const { availableError } = props;

  if (availableError) {
    throw new Error();
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Splash Screen Config</h4>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Form />
      </GridItem>
    </GridContainer>
  );
};

export default SplashScreenConfig;
