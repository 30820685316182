const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  fileFieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "20px",
    marginTop: "20px"
  },
  imageViewContainer: {
    width: "100px",
    height: "100px",
    padding: "10px",
    border: "1px solid #ffa726",
    marginRight: "15px"
  },
  imageIcon: {
    width: "100px",
    height: "100px",
    objectFit: "cover"
  },
  textFileContainer: {
    display: "flex",
    flexDirection: "column"
  },
  textField: {
    width: "30%",
    marginLeft: "20px"
  },
  textFieldFile: {
    width: "100px",
    lineHeight: "40px",
    padding: "5px 20px",
    textAlign: "center",
    borderRadius: "5px",
    marginTop: "12px",
    color: "#fff",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    padding: "0 15px",
    margin: "0 15px",
    alignItems: "center"
  },
  cardLinkAdd: {
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    padding: "10px 25px",
    borderRadius: "5px",
    color: "#ffff",
    border: "1px solid transparent",
    fontWeight: "500",
    "&:hover": {
      background: "transparent",
      color: "#ffa726",
      border: "1px solid #ffa726"
    }
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    alignItems: "center"
  },
  cardSituation: {
    display: "flex"
  },
  ItemSituation: {
    marginRight: 15
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexBasis: "80%"
  },
  cover: {
    height: "100px",
    width: "100px",
    borderRadius: "50%"
  },
  recordLink: {
    maxWidth: "250px",
    maxHeight: "60px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  checkboxGroup: {
    display: "block",
    width: "100%"
  },
  checkboxField: {
    marginLeft: "20px"
  },
  imageError: { color: "red", margin: 0 }
};
export default styles;
