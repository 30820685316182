// Automate the definition of the variables that declares routes and/or API URLs based on the environment

const prod = {
  url: {
    baseUrl: "https://gw.waslabrowser.com",
    adminBaseUrl: "https://gw.waslabrowser.com/backendadmin",
    announcementBaseUrl:
      "https://gw.waslabrowser.com/backendadmin/announcements",
    missionsBaseUrl: "https://gw.waslabrowser.com/backendadmin/missions",
    authenticationBaseUrl:
      "http://identity-master-255772623.eu-central-1.elb.amazonaws.com/Login.html",
    pointsBaseUrl: "https://gw.waslabrowser.com/points",
    missionsPromoCodeUrl: "http://promocodes-mig.waslabrowser.com/admin",
    authClientId: "test_implicit_app",
    authRequiredScopes: "openid+email+role",
    pushNotificationBaseurl:
      "https://europe-west2-zoser-7f5c3.cloudfunctions.net/fcm/send",
    waslaRewardBaseUrl: "https://gw.waslabrowser.com/points",
    rolesUsersBaseUrl: "https://identity-master.waslabrowser.com/account",
    identityBaseUrl: "https://gw.waslabrowser.com/identity-v2/admin",
    dealsBaseurl: "https://gw.waslabrowser.com/affiliate",
    cashbackConfigBaseUrl: "https://gw.waslabrowser.com/points",
    redemptionBaseUrl: "https://gw.waslabrowser.com/redemption",
    userProfileBaseUrl: "https://gw.waslabrowser.com/userprofile/admin/profiles"
  },
  auth: {
    clientId: "wasla-dashboard",
    OPServer: "https://gw.waslabrowser.com/ouropenid/realms/wasla-users",
    redirectURL: "http://localhost:3000/",
    scope: "openid"
  }
};

const qc = {
  url: {
    baseUrl: "https://qc.waslabrowser.com",
    adminBaseUrl: "https://qc.waslabrowser.com/admin-backend",
    announcementBaseUrl:
      "https://qc.waslabrowser.com/admin-backend/announcements",
    missionsBaseUrl: "https://qc.waslabrowser.com/admin-backend/affiliate",
    authenticationBaseUrl: "https://qc.waslabrowser.com/identity-v2/Login.html",
    pointsBaseUrl: "https://qc.waslabrowser.com/points",
    missionsPromoCodeUrl: "https://qc.waslabrowser.com/promocodes/admin",
    authClientId: "test_implicit_app",
    authRequiredScopes: "openid+email+role",
    pushNotificationBaseurl:
      "https://europe-west2-wasladev.cloudfunctions.net/fcm/send",
    waslaRewardBaseUrl: "https://qc.waslabrowser.com/points",
    rolesUsersBaseUrl: "https://qc.waslabrowser.com/identity-v2/account",
    identityBaseUrl: "https://qc.waslabrowser.com/identity-v2/admin",
    dealsBaseurl: "https://qc.waslabrowser.com/affiliate",
    cashbackConfigBaseUrl: "https://qc.waslabrowser.com/points",
    redemptionBaseUrl: "https://qc.waslabrowser.com/redemption",
    userProfileBaseUrl: "https://qc.waslabrowser.com/userprofile/admin/profiles"
  },
  auth: {
    clientId: "wasla-dashboard",
    OPServer: "https://qc.waslabrowser.com/ouropenid/realms/wasla-users",
    redirectURL: "http://localhost:3000/",
    scope: "openid"
  }
};

const dev = {
  url: {
    baseUrl: "https://apigw.waslabrowser.com",
    adminBaseUrl: "https://apigw.waslabrowser.com/admin-backend",
    announcementBaseUrl:
      "https://apigw.waslabrowser.com/admin-backend/announcements",
    missionsBaseUrl: "https://apigw.waslabrowser.com/admin-backend/affiliate",
    authenticationBaseUrl:
      "https://apigw.waslabrowser.com/identity-v2/Login.html",
    pointsBaseUrl: "https://apigw.waslabrowser.com/points",
    missionsPromoCodeUrl: "http://apigw.waslabrowser.com/promocodes/admin",
    authClientId: "test_implicit_app",
    authRequiredScopes: "openid+email+role",
    pushNotificationBaseurl:
      "https://europe-west2-wasladev.cloudfunctions.net/fcm/send",
    waslaRewardBaseUrl: "https://apigw.waslabrowser.com/points",
    rolesUsersBaseUrl: "https://apigw.waslabrowser.com/identity-v2/account",
    identityBaseUrl: "https://apigw.waslabrowser.com/identity-v2/admin",
    dealsBaseurl: "https://apigw.waslabrowser.com/affiliate",
    cashbackConfigBaseUrl: "https://apigw.waslabrowser.com/points",
    redemptionBaseUrl: "https://apigw.waslabrowser.com/redemption",
    userProfileBaseUrl:
      "https://apigw.waslabrowser.com/userprofile/admin/profiles"
  },
  auth: {
    clientId: "wasla-dashboard",
    OPServer: "https://apigw.waslabrowser.com/ouropenid/realms/wasla-users",
    redirectURL: "http://localhost:3000/",
    scope: "openid"
  }
};

export const config =
  process.env.REACT_APP_ENV === "development"
    ? dev
    : process.env.REACT_APP_ENV === "qc"
    ? qc
    : prod;
//export const config = !process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : dev;
