import React from "react";
// @material-ui/core components
import { Typography } from "@material-ui/core";
// core components
import Moment from "moment";

const DatesDetails = ({ startDate, endDate }) => {
  const isEnded = Moment(endDate) > Moment(Date.now());

  return (
    <>
      <Typography
        component="p"
        variant="body1"
        style={{ flexBasis: "33%" }}
        gutterBottom={true}
      >
        Start Date:
        <Typography
          component="span"
          variant="body1"
          style={{ marginLeft: "8px" }}
        >
          {Moment(startDate).format("MMMM DD, YYYY")}
        </Typography>
      </Typography>
      <Typography
        component="p"
        variant="body1"
        style={{ flexBasis: "33%" }}
        gutterBottom={true}
      >
        End Date:
        <Typography
          component="span"
          variant="body1"
          style={{ marginLeft: "8px" }}
        >
          {Moment(endDate).format("MMMM DD, YYYY")}
        </Typography>
      </Typography>
      {isEnded && (
        <Typography
          component="p"
          variant="body1"
          style={{ flexBasis: "33%" }}
          gutterBottom={true}
        >
          Remaining Days:
          <Typography
            component="span"
            variant="body1"
            style={{
              marginLeft: "8px",
              color: "#009900"
            }}
          >
            {`${Moment(endDate).diff(Moment(Date.now()), "days")} days`}
          </Typography>
        </Typography>
      )}
    </>
  );
};

export default DatesDetails;
