import React from "react";
import PropTypes from "prop-types";
// core components
import AnnouncementCard from "../AnnouncementCard";

const CardDetails = ({ item }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <AnnouncementCard
        flexBasis="48.5%"
        title={item.title.en}
        body={item.body.en}
        btnText={item.btn.text ? item.btn.text.en : ""}
        imgUrl={item.imgUrl}
        bgPrimaryColor={item.theme.startBgColor}
        bgSecondaryColor={item.theme.endBgColor}
        textColor={item.theme.textColor}
        btnBgColor={item.theme.btnBgColor}
        btnTextColor={item.theme.btnTextColor}
        className="announcementCardContainer"
      />
      <AnnouncementCard
        flexBasis="48.5%"
        direction="rtl"
        title={item.title.ar}
        body={item.body.ar}
        btnText={item.btn.text ? item.btn.text.ar : ""}
        imgUrl={item.imgUrl}
        bgPrimaryColor={item.theme.startBgColor}
        bgSecondaryColor={item.theme.endBgColor}
        textColor={item.theme.textColor}
        btnBgColor={item.theme.btnBgColor}
        btnTextColor={item.theme.btnTextColor}
        className="announcementCardContainer"
      />
    </div>
  );
};

export default CardDetails;

CardDetails.propTypes = {
  item: PropTypes.object
};
