import {
  SET_TYPE_OF_TRANSFER,
  CLEAR_TYPE_OF_TRANSFER,
  SET_AMOUNT_OF_POINTS,
  SET_USER_MOBILE_NUMBER,
  TRANSFER_SUCCESS,
  TRANSFER_ERROR,
  OPEN_SNACK_BAR
} from "../../action/actionType";

import * as pointsService from "../../../services/points";

export const setTypeOfTransfer = value => {
  return {
    type: SET_TYPE_OF_TRANSFER,
    payload: value
  };
};

export const clearTypeOfTransfer = () => {
  return {
    type: CLEAR_TYPE_OF_TRANSFER
  };
};

export const setTypeOfPoints = value => {
  return {
    type: "SET_TYPE_OF_POINTS",
    payload: value
  };
};

export const setAmountOfPoints = points => {
  return {
    type: SET_AMOUNT_OF_POINTS,
    payload: points
  };
};

export const setUserMobileNumber = mobile => {
  return {
    type: SET_USER_MOBILE_NUMBER,
    payload: mobile
  };
};

export const fetchAllBonusPoints = () => async disPatch => {
  await pointsService
    .getBonusBucket()
    .then(data => {
      disPatch({
        type: "Fetch_BUCKETS_BONUS_POINTS_CS",
        payload: data?.value
      });
    })
    .catch(err => {
      // disPatch({
      //   type: TRANSFER_ERROR,
      //   payload: err?.response?.status
      // });
    });
};

export const fetchAllFraudPoints = () => async disPatch => {
  await pointsService
    .getFraudBucket()
    .then(data => {
      disPatch({
        type: "Fetch_BUCKETS_FRAUD_POINTS_CS",
        payload: data?.value
      });
    })
    .catch(err => {
      // disPatch({
      //   type: TRANSFER_ERROR,
      //   payload: err?.response?.status
      // });
    });
};

export const addTransferPoints = (
  amount,
  mobilesAsArray,
  reason,
  type
) => async disPatch => {
  let point = amount;
  if (type === "Take Points") {
    point = point * -1;
  }
  disPatch({
    type: TRANSFER_ERROR,
    payload: null
  });
  disPatch({
    type: TRANSFER_SUCCESS,
    payload: null
  });
  await pointsService
    .transferPoints(mobilesAsArray, point, {
      valueEn: reason.value_en,
      valueAr: reason.value_ar
    })
    .then(res => {
      disPatch(fetchAllBonusPoints());
      disPatch(fetchAllFraudPoints());
      disPatch({
        type: TRANSFER_SUCCESS,
        payload: res
      });
      disPatch({
        type: OPEN_SNACK_BAR,
        payload: true
      });
      disPatch({
        type: TRANSFER_ERROR,
        payload: null
      });
    })
    .catch(err => {
      disPatch({
        type: TRANSFER_ERROR,
        payload: err?.response?.status
      });
      disPatch({
        type: OPEN_SNACK_BAR,
        payload: true
      });
      disPatch({
        type: TRANSFER_SUCCESS,
        payload: null
      });
    });
};

export const restTransferRequestResult = () => {
  return {
    type: "REST_TRANSFER_REQUEST_RESULT"
  };
};
