import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const SectionHead = props => {
  const { title, description } = props;

  return (
    <Box>
      {title && (
        <Typography variant="h5" component="p" sx={{ color: "#101828" }}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="p" component="p" sx={{ color: "#667085" }}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default SectionHead;
