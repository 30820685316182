import { GET_STATUS } from "../../actions/status";

const initialState = "";

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS:
      return action.payload;
    default:
      return state;
  }
};
