import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// redux actions
import { clearMissionsState } from "../../../redux/action/Missions/CreateMissionActions";
// core components
import {
  fetchMissions,
  deleteMission
} from "../../../redux/action/Missions/MissionsListViewActions";
import { fetchSectionsList } from "../../../redux/action/Missions/SectionsActions";

import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";

// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import MissionCard from "../MissionCard";
import ActionButtons from "../../ActionButtons/ActionButtons";
import ErrorMessage from "../../ErrorMessage";
import styles from "../styles.css";
import SelectCountry from "../../CountryComponent";

const useStyles = makeStyles(styles);

const CardsListView = props => {
  const classes = useStyles();
  const {
    fetchMissions,
    clearMissionsState,
    checkRequiredFields,
    fetchSectionsList,
    missionsCardsList,
    match,
    deleteMission,
    errorMessage,
    countrySelected,
    list
  } = props;

  useEffect(() => {
    fetchMissions(countrySelected);
    clearMissionsState();
    checkRequiredFields(false);
  }, [countrySelected]);

  useEffect(() => {
    fetchSectionsList({
      pageNum: 1,
      limit: 1000000,
      countryCode: countrySelected
    });
  }, [countrySelected]);

  const getSectionName = id => {
    const currentSection = list.filter(item => item.id === id);
    return currentSection[0]?.titleEn;
  };

  return (
    <>
      <GridContainer>
        <div className={classes.cardLinkContainer}>
          <SelectCountry />
          <Link
            to={{
              pathname: `${match.path}/form`,
              state: { viewType: "Add" }
            }}
            className={classes.cardLinkAdd}
          >
            Add New Mission
          </Link>
        </div>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Missions</h4>
            </CardHeader>
          </Card>
        </GridItem>
        {missionsCardsList.length === 0 ? (
          <Typography
            component="p"
            variant="subtitle1"
            align="center"
            gutterBottom={true}
            style={{ width: "100%" }}
          >
            No Missions added yet!
          </Typography>
        ) : (
          missionsCardsList.map(card => {
            return (
              <GridItem xs={12} sm={12} md={6} key={card._id || card.id}>
                <div className={classes.customContainer}>
                  <Link
                    to={{
                      pathname: `${match.path}/details/${card._id || card.id}`,
                      state: {
                        item: card,
                        sectionName: getSectionName(card?.sectionId)
                      }
                    }}
                    style={{ display: "block", width: " 100%" }}
                  >
                    <MissionCard
                      title={card.titleEn}
                      body={card.descriptionEn}
                      btnText={card?.buttonTextEn}
                      imgUrl={card?.thumbnailUrl}
                      buttonUrl={card?.buttonUrl}
                      iconUrl={card?.iconUrl}
                      sectionName={getSectionName(card?.sectionId)}
                      className="announcementCardContainer"
                    />
                  </Link>
                  <ActionButtons
                    item={card}
                    viewType="Edit"
                    deleteButton={true}
                    editButton={true}
                    handleDelete={deleteMission}
                    countrySelected={countrySelected}
                  />
                </div>
              </GridItem>
            );
          })
        )}
      </GridContainer>
      {errorMessage && <ErrorMessage message="Sorry something went wrong!" />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    missionsCardsList: state.missionsCardsList.missionsCardsList,
    errorMessage: state.errorMessage.error,
    list: state.SectionsMissions.list,
    countrySelected: state.Countries.countryNameSelected
  };
};

export default connect(
  mapStateToProps,
  {
    clearMissionsState,
    fetchMissions,
    deleteMission,
    checkRequiredFields,
    fetchSectionsList
  }
)(CardsListView);

CardsListView.propTypes = {
  match: PropTypes.object,
  missionsCardsList: PropTypes.arrayOf(PropTypes.object),
  clearMissionsState: PropTypes.func,
  fetchMissions: PropTypes.func,
  deleteMission: PropTypes.func,
  checkRequiredFields: PropTypes.func,
  fetchSectionsList: PropTypes.func,
  errorMessage: PropTypes.bool
};
