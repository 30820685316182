import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// React-Csv
import { CSVLink } from "react-csv";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";

// redux actions
import { resetRechargeState } from "../../../redux/action/WaslaRewards/RechargeUsersActions";

import RechargeUsersModal from "../RechargeUsersModal";
import EditRechargeModal from "../EditRechargeModal";
import SearchBar from "../SearchBar/SearchBar";
import styles from "../styles.css";
import DetailedAccordion from "../Filters";
import ExportButton from "../ExportButton/ExportButton";
import filterIcon from "../../../assets/img/Filter.svg";
import { useLocation } from "react-router-dom";
import { fetchRewardApiList } from "../../../redux/action/WaslaRewards/UsersLogsActions";
const useStyles = makeStyles(styles);

const TableToolbar = props => {
  const classes = useStyles();
  const {
    numSelected,
    resetRechargeState,
    rewardApiList,
    usersRechargeFailed,
    pageNumber,
    setPageNum,
    projectId,
    fetchRewardApiList,
    phoneNumber
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const batchId = location?.state?.batchId;
  var transactionList = [];
  for (var i = 0; i < rewardApiList.length; i++) {
    var newRecored = {
      ...rewardApiList[i],
      success: rewardApiList[i].success.toString()
    };
    transactionList.push(newRecored);
  }

  const handleClick = (type = "select") => {
    setOpenModal(true);
  };

  const handleClickFailed = () => {
    setOpenModalEdit(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenModalEdit(false);
    resetRechargeState();
  };

  const openFilterMenu = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const idPopper = !!anchorEl ? "spring-popper" : undefined;
  const handleSearch = () => {
    let pageNum = pageNumber;
    if (pageNum !== 1) {
      setPageNum();
    } else {
      fetchRewardApiList({
        pageNum,
        phoneNumber,
        batchId,
        projectId: location?.state?.id
      });
    }
  };

  const clearSearch = () => {
    let pageNum = pageNumber;

    if (pageNum !== 1) {
      setPageNum();
    } else {
      fetchRewardApiList({ pageNum, batchId, projectId: location?.state?.id });
    }
  };
  return (
    <>
      <Toolbar
        className={`${classes.toolbarRoot} ${numSelected > 0 &&
          classes.highlight}`}
      >
        {numSelected > 0 && (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        )}
        <div
          style={{
            flex: "1 1 50%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div>
            {!!anchorEl && <div className={classes.overlay}></div>}
            <div
              aria-describedby={idPopper}
              onClick={openFilterMenu}
              className={classes.iconContainer}
            >
              <img
                src={filterIcon}
                alt="..."
                style={{ objectFit: "contain", width: 35, height: 35 }}
              />
            </div>
            <DetailedAccordion
              closeToggle={openFilterMenu}
              pageNum={pageNumber}
              idPopper={idPopper}
              anchorElement={anchorEl}
              setPageNum={setPageNum}
            />
          </div>

          <SearchBar
            fetchDataSearch={handleSearch}
            clearSearchType={clearSearch}
            title="Search by number or transaction ID"
          />

          <ExportButton />
          {projectId && (
            <Button
              variant="contained"
              className={`${classes.retryBtn} ${usersRechargeFailed.length !==
                0 && classes.retryBtn}`}
              disabled={usersRechargeFailed.length === 0}
              onClick={() => handleClickFailed("retry")}
            >
              Retry Failed
            </Button>
          )}

          {projectId && (
            <Button
              variant="contained"
              className={`${classes.sendRechargeBtn} ${numSelected > 0 &&
                classes.sendRechargeBtn}`}
              disabled={numSelected === 0}
              onClick={() => handleClick()}
            >
              Send Recharge
            </Button>
          )}
        </div>
      </Toolbar>

      {openModal && (
        <RechargeUsersModal open={openModal} closeModal={handleClose} />
      )}

      {openModalEdit && (
        <EditRechargeModal open={openModalEdit} closeModal={handleClose} />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    rewardApiList: state.waslaRewards.rewardApiList,
    usersRechargeFailed: state.usersRechargeToEdit.usersRechargeFailed,
    phoneNumber: state.waslaRewards.phoneNumber
  };
};

export default connect(
  mapStateToProps,
  {
    resetRechargeState,
    fetchRewardApiList
  }
)(TableToolbar);

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  resetRechargeState: PropTypes.func,
  setPageNum: PropTypes.func,
  pageNumber: PropTypes.number,
  projectId: PropTypes.number
};
