import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ConfigItem from "./ConfigItem";
// redux actions
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import Card from "../../../../components/Card/Card.js";
import styles from "../../styles.css";
import CardBody from "../../../../components/Card/CardBody.js";
import { Typography } from "@material-ui/core";
import ActionButtons from "../../../ActionButtons/ActionButtons";
import ErrorMessage from "../../../ErrorMessage";
const useStyles = makeStyles(styles);

const PointConfigList = props => {
  const classes = useStyles();
  const { availableError, configItems, errConfigPoints } = props;

  if (availableError) {
    throw new Error();
  }
  return (
    <>
      {errConfigPoints ? (
        <ErrorMessage message={errConfigPoints} />
      ) : (
        <>
          {Object.keys(configItems).length !== 0 && (
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ alignItems: "center" }}>
                <CardBody className={classes.cardBody} style={{ width: "70%" }}>
                  <ActionButtons
                    item={configItems}
                    viewType="Edit"
                    deleteButton={false}
                    editButton={true}
                    typeFieldConfig="PointConfigUpdate"
                  />
                  <Card>
                    <CardBody className={classes.cardBody}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBlockStart: "16px",
                          alignItems: "center"
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          color="textPrimary"
                          style={{
                            textTransform: "capitalize"
                          }}
                        >
                          Minimum ads reserve limit:
                        </Typography>
                        <Typography
                          component="h2"
                          variant="subtitle1"
                          style={{ color: "#0abb87" }}
                        >
                          {Object.keys(configItems).length !== 0 &&
                            `${configItems.MinAdsReserveLimit.toLocaleString()} pts`}
                        </Typography>
                      </div>
                    </CardBody>
                  </Card>

                  {Object.keys(configItems).length !== 0 &&
                    configItems.RewardValues.map((configItem, index) => {
                      return <ConfigItem configItem={configItem} key={index} />;
                    })}
                </CardBody>
              </Card>
            </GridItem>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    configItems: state.configItems.configItems,
    errConfigPoints: state.configItems.errConfigPoints,
    availableError: state.availableError.availableError
  };
};
export default connect(mapStateToProps)(PointConfigList);

PointConfigList.propTypes = {
  availableError: PropTypes.bool,
  configItems: PropTypes.object,
  errConfigPoints: PropTypes.string
};
