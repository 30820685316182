import {
  SET_CALL_TO_ACTION,
  CLEAR_CTA_VALUES,
  SET_CTA_BUTTON_TEXT,
  SET_CTA_BUTTON_TYPE,
  SET_BUTTON_URL,
  SET_PWA_TYPE,
  SET_IN_APP_TYPE
} from "../actionType";

export const setCallToAction = value => {
  return {
    type: SET_CALL_TO_ACTION,
    payload: value
  };
};

export const clearCallToActionValues = () => {
  return {
    type: CLEAR_CTA_VALUES
  };
};

export const setCTAButtonText = data => {
  return {
    type: SET_CTA_BUTTON_TEXT,
    payload: { ...data }
  };
};

export const setCTAButtonType = value => {
  return {
    type: SET_CTA_BUTTON_TYPE,
    payload: value
  };
};

export const setButtonUrl = url => {
  return {
    type: SET_BUTTON_URL,
    payload: url
  };
};

export const setPWAType = type => {
  return {
    type: SET_PWA_TYPE,
    payload: type
  };
};

export const setInAPpType = type => {
  return {
    type: SET_IN_APP_TYPE,
    payload: type
  };
};
