import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//redux actions
import {
  toggleErrorMessage,
  toggleSuccessMessage
} from "../../redux/action/ErrorMessageActions";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// core components
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const ErrorMessage = ({
  message,
  toggleErrorMessage,
  toggleSuccessMessage,
  msgStatus
}) => {
  const classes = useStyles();

  const handleClick = () => {
    msgStatus === "success" ? toggleSuccessMessage() : toggleErrorMessage();
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: msgStatus === "success" ? "green" : "#f44336"
      }}
    >
      <Typography component="p" variant="h6">
        {message}
      </Typography>
      <IconButton
        aria-label="close"
        className={classes.iconButton}
        color="inherit"
        onClick={handleClick}
      >
        <HighlightOffIcon />
      </IconButton>
    </div>
  );
};

export default connect(
  null,
  { toggleErrorMessage, toggleSuccessMessage }
)(ErrorMessage);

ErrorMessage.propTypes = {
  message: PropTypes.string,
  toggleErrorMessage: PropTypes.func,
  toggleSuccessMessage: PropTypes.func,
  msgStatus: PropTypes.string
};
