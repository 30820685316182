import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";

// Redux
import {
  setProjectName,
  createProject
} from "../../../../redux/action/WaslaRewards/UsersLogsActions";
// core components
import styles from "./styles.css";
import Warning from "../../../../assets/img/Warning.1.svg";
import SuccessImage from "../../../../assets/img/success.svg";
import InputBase from "@material-ui/core/InputBase";
import { Typography } from "@material-ui/core";

import TabPanel from "./TabPanel";

const useStyles = makeStyles(styles);

function CreateProjectSteps(props) {
  const classes = useStyles();
  const {
    onCloseModal,
    setProjectName,
    projectName,
    createProject,
    error,
    success
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const handleChangeIndex = index => {
    setValue(index);
  };

  const handleNameChange = event => {
    setProjectName(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    createProject(projectName, "manual");
    setDisabled(true);
  };
  useEffect(() => {
    if (projectName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (error || success) {
      setIsLoading(false);
      setDisabled(true);
    }
  }, [projectName, error, success]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <LinearProgress
          variant="determinate"
          value={100}
          style={{ margin: 15 }}
        />
        {!isLoading && !error && !success && (
          <Grid container>
            <Grid item xs={6}>
              <div className={classes.containerType}>
                <h3>Integration Type:</h3>
                <div className={classes.btnContainer}>
                  <Button
                    variant="contained"
                    size="medium"
                    disabled
                    onClick={() => handleChangeIndex(0)}
                  >
                    API
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => handleChangeIndex(1)}
                  >
                    Manual
                  </Button>
                </div>

                <TabPanel value={value} index={0}>
                  API
                </TabPanel>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.containerField}>
              <h3>Name of Project:</h3>
              <TabPanel value={value} index={1}>
                <InputBase
                  placeholder="Enter name of Project"
                  classes={classes.inputName}
                  inputProps={{
                    "aria-label": "Name of Project"
                  }}
                  onChange={handleNameChange}
                />
              </TabPanel>
            </Grid>
          </Grid>
        )}
        {isLoading && (
          <Grid item xs={12}>
            <CircularProgress aria-label="loading" />
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <CardMedia
              className={classes.media}
              image={Warning}
              title="Warning"
            />
            <Typography color="error">
              An error occurred, please try again
            </Typography>
          </Grid>
        )}
        {success && (
          <Grid item xs={12} aria-label="success">
            <CardMedia
              className={classes.media}
              image={SuccessImage}
              title="Success"
            />
            <Typography style={{ color: "#4caf50", fontSize: 16 }}>
              Project created successfully
            </Typography>
          </Grid>
        )}
        {!success ? (
          <Grid item xs={12}>
            <div className={classes.btnContainerFinish}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#EBEBEB" }}
                size="medium"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="medium"
                onClick={handleSubmit}
                disabled={disabled}
              >
                Submit
              </Button>
            </div>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <div className={classes.btnContainerFinish}></div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
const mapStateToProps = state => {
  return {
    projectName: state.project.name,
    error: state.project.error,
    success: state.project.success
  };
};
export default connect(
  mapStateToProps,
  { setProjectName, createProject }
)(CreateProjectSteps);

CreateProjectSteps.propTypes = {
  setProjectName: PropTypes.func,
  createProject: PropTypes.func,
  projectName: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool
};
