import {
  UPDATE_Mission_CONFIG,
  UPDATE_Mission_CONFIG__SUSSES,
  UPDATE_Mission_CONFIG__Error,
  RESET_STATE
} from "../../../action/actionType";

const initialState = {
  usdToEgp: {},
  redirect: false,
  errUpdateMissionsConfig: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_Mission_CONFIG:
      return {
        ...state,
        usdToEgp: action.payload
      };
    case UPDATE_Mission_CONFIG__SUSSES:
      return {
        ...state,
        redirect: true
      };
    case UPDATE_Mission_CONFIG__Error:
      return {
        ...state,
        errUpdateMissionsConfig: true
      };
    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
