import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// core components
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardBody from "../../../../components/Card/CardBody.js";
import Snackbar from "../../../../components/Snackbar/Snackbar.js";

import styles from "./styles.css";

import quickLinksActions from "../../../../main/quick-links/actions";

import { basename } from "../../../../config";
import { quickLinksCategoriesPath } from "../../paths";

const useStyles = makeStyles(styles);

const Categories = props => {
  const [categoryEN, setCategoryEN] = useState("");
  const [categoryAR, setCategoryAR] = useState("");
  const { match, addCategory } = props;
  const [errors, setErrors] = useState([]);
  const [messagesId, setMessagesId] = useState(1);
  const [messages, setMessages] = useState([]);
  const history = useHistory();

  const addError = error => {
    setErrors(errors => [...errors, error]);
  };

  const removeError = id => {
    setErrors(errors => errors.filter(error => error.id !== id));
  };

  const addMessage = message => {
    setMessages(messages => [...messages, message]);
  };

  const removeMessage = id => {
    setMessages(messages => messages.filter(message => message.id !== id));
  };

  const handleCategoryENChange = e => {
    setCategoryEN(e.target.value);
    if (e.target.value) removeError("categoryEN");
    else addError({ id: "categoryEN", content: "Category EN is required" });
  };

  const handleCategoryARChange = e => {
    setCategoryAR(e.target.value);
    if (e.target.value) removeError("categoryAR");
    else addError({ id: "categoryAR", content: "Category AR is required" });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (categoryEN && categoryAR) {
      addCategory(categoryEN, categoryAR)
        .then(() => {
          addMessage({
            id: messagesId,
            type: "success",
            content: "Category is added successfully"
          });
          setMessagesId(setMessagesId => setMessagesId + 1);
          setCategoryEN("");
          setCategoryAR("");
          setTimeout(() => {
            history.push([basename, quickLinksCategoriesPath].join(""));
          }, 100);
        })
        .catch(error => {
          addMessage({
            id: messagesId,
            type: "danger",
            content: error.message
          });
          setMessagesId(setMessagesId => setMessagesId + 1);
        });
    } else {
      if (!categoryEN)
        addError({ id: "categoryEN", content: "Category EN is required" });
      if (!categoryAR)
        addError({ id: "categoryAR", content: "Category AR is required" });
    }
  };

  const classes = useStyles();
  return (
    <>
      <Card>
        <CardHeader color="warning">
          <h4 className={classes.cardTitleWhite}>Create or edit category</h4>
        </CardHeader>
        <CardBody>
          <form
            className={classes.container}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      type="text"
                      id="categoryEN"
                      label="Category EN"
                      name="categoryEN"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      error={errors.find(error => error.id === "categoryEN")}
                      helperText={
                        errors.find(error => error.id === "categoryEN")?.content
                      }
                      value={categoryEN}
                      onChange={handleCategoryENChange}
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      type="text"
                      id="categoryAR"
                      label="Category AR"
                      name="categoryAR"
                      margin="normal"
                      variant="outlined"
                      error={errors.find(error => error.id === "categoryAR")}
                      helperText={
                        errors.find(error => error.id === "categoryAR")?.content
                      }
                      value={categoryAR}
                      onChange={handleCategoryARChange}
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.button}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardBody>
      </Card>
      {messages.map(message => (
        <Snackbar
          key={message.id}
          place="tc"
          color={message.type}
          // icon={AddAlert}
          message={message.content}
          open={true}
          closeNotification={() => removeMessage(message.id)}
          close
        />
      ))}
    </>
  );
};

export default connect(
  null,
  {
    addCategory: quickLinksActions.categories.add
  }
)(withRouter(Categories));
