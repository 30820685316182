import React from "react";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

const FormCardHead = ({ variant, children, ...props }) => {
  return (() => {
    switch (true) {
      case variant === "underline":
        return (
          <Stack spacing={2.5}>
            <Stack {...props}>{children}</Stack>
            <Divider />
          </Stack>
        );
      default:
        return <Stack {...props}>{children}</Stack>;
    }
  })();
};

FormCardHead.defaultProps = {
  spacing: 0.5
};

export default FormCardHead;
