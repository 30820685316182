import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ActionButtons from "../../ActionButtons/ActionButtons";
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";

// @material-ui/core components
import { TableBody, TableCell, TableRow, Button } from "@material-ui/core";

// redux actions
import {
  fetchMerchantList,
  handleDeleteMerchant,
  closeNotification,
  submitUpdateMerchant,
  resetUploadMerchantList
} from "../Actions/FetchMerchantList";

// core components

import CustomTable from "../customTable";
import EditMerchant from "./EditMerchant";

import {
  getComparator,
  stableSort
} from "../../WaslaRewards/UsersLogsTable/helper";
import { headCells } from "./headCells";
import ModalContainer from "./ModalContainer";
import EditIcon from "../../../assets/img/EditIcon.svg";
import { Validation } from "./Validtion";

function MerchantTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [dense, setDense] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [editRow, setEditRow] = useState(false);
  const [itemToEdit, setItemToEdit] = useState({});
  const [itemIndex, setItemIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    nameEn: "",
    nameAr: "",
    id: "",
    descriptionEn: "",
    descriptionAr: "",
    logo: "",
    tncEn: "",
    tncAr: "",
    countryCode: "",
    url: "",
    order: ""
  });

  const {
    list,
    hasNext,
    fetchMerchantList,
    error,
    isLoading,
    handleDeleteMerchant,
    closeNotification,
    checkRequiredFields,
    submitUpdateMerchant,
    success,
    updateMessage,
    totalPages,
    limit,
    uploadSuccessMessage,
    deleteSuccessMessage,
    resetUploadMerchantList
  } = props;

  const createRows = useCallback(() => {
    const retrieveRechargeList = list.map(item => {
      return {
        nameEn: item.nameEn,
        nameAr: item.nameAr,
        id: item.id,
        descriptionEn: item.descriptionEn,
        descriptionAr: item.descriptionAr,
        logo: item.logo,
        tncEn: item.tncEn,
        tncAr: item.tncAr,
        countryCode: item.countryCode,
        url: item.url,
        isPopular: item.isPopular,
        order: item.order,
        edit: (item.edit = false)
      };
    });
    setRows([...retrieveRechargeList]);
  }, [list]);

  useEffect(() => {
    if (list) {
      createRows(list);
    }
  }, [list]);

  const emptyRows = 10 - Math.min(10, rows.length - 0 * 10);

  useEffect(() => {
    if (pageNum >= 1) {
      fetchMerchantList({
        pageNum
      });
    }
  }, [pageNum]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    resetUploadMerchantList();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const validatedFiled = (name, value) => {
    if (name === "nameEn") {
      if (value.length > 30 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            nameEn: "Invalid name (En) 30-character limit"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          nameEn: ""
        }));
      }
    }
    if (name === "nameAr") {
      if (value.length > 30 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            nameAr: "Invalid name (Ar) 30-character limit"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          nameAr: ""
        }));
      }
    }
    if (name === "descriptionEn") {
      if (value.length > 50 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            descriptionEn: "Invalid description (En) 50-character limit "
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          descriptionEn: ""
        }));
      }
    }
    if (name === "descriptionAr") {
      if (value.length > 50 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            descriptionAr: "Invalid description (Ar) 50-character limit "
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          descriptionAr: ""
        }));
      }
    }
    if (name === "logo") {
      if (Validation.urlValidation(value?.trim()) || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            logo: "Please enter logo a http or https url"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          logo: ""
        }));
      }
    }
    if (name === "countryCode") {
      if (!value || !value?.length) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            countryCode: "Please enter Countries sparetd by -"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          countryCode: ""
        }));
      }
    }
    if (name === "url") {
      if (Validation.urlValidation(value?.trim()) || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            url: "Please enter Domain a http or https url"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          url: ""
        }));
      }
    }
    if (name === "order") {
      if (!Number.isInteger(+value) || +value < 0) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            order: `Order is integer and positive integer`
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          order: ""
        }));
      }
    }

    if (name === "tncEn") {
      if (value.length > 1000 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            tncEn: `Invalid tnc (En) 1000-character limit `
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          tncEn: ""
        }));
      }
    }

    if (name === "tncAr") {
      if (value.length > 1000 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            tncAr: `Invalid tnc (Ar) 1000-character limit `
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          tncAr: ""
        }));
      }
    }
  };

  const handleEdit = index => {
    handleOpenEdit();
    setItemIndex(index);
    setItemToEdit(rows[index]);
    setEditRow(true);
    checkRequiredFields(editRow);
  };

  const updateFieldChanged = index => e => {
    validatedFiled(e.target.name, e.target.value);
    if (e.target.name == "isPopular") {
      setItemToEdit({
        ...itemToEdit,
        isPopular: e.target.checked
      });
    } else if (e.target.name == "countryCode") {
      setItemToEdit({
        ...itemToEdit,
        countryCode: e.target.value
          ?.filter(countryCode => countryCode)
          ?.join(",")
      });
    } else {
      setItemToEdit({
        ...itemToEdit,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSaveEdit = index => {
    let newItem = (rows[index] = itemToEdit);
    setRows(prev => [...new Set(prev, newItem)]);
    setEditRow(false);
    delete rows[index].edit;
    submitUpdateMerchant({
      data: itemToEdit,
      id: rows[index].id
    });
    setEditRow(false);
    setPageNum(1);
  };

  const handleRestEdit = () => {
    setEditRow(false);
  };

  const handleOpenEdit = () => {
    setEditRow(true);
  };

  const handleCloseEdit = () => {
    setEditRow(false);
  };
  const handleChangePagination = (event, value) => {
    setPageNum(value);
  };

  return (
    <>
      <CustomTable
        headCells={headCells}
        list={list}
        isLoading={isLoading}
        error={error}
        closeNotification={closeNotification}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        orderBy={orderBy}
        order={order}
        hasNext={hasNext}
        title="All Merchants"
        BtnType="Create Merchants"
        pageNum={pageNum}
        setPageNum={setPageNum}
        handleOpenModal={handleOpenModal}
        success={success}
        updateMessage={updateMessage}
        totalPages={totalPages}
        limit={limit}
        handleChangePagination={handleChangePagination}
        uploadSuccessMessage={uploadSuccessMessage}
        deleteSuccessMessage={deleteSuccessMessage}
        type="merchant"
      >
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={labelId}
                style={{ padding: 12 }}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "10px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    minWidth: "140px"
                  }}
                  title={row.nameEn}
                >
                  {row.nameEn}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    minWidth: "140px",
                    paddingRight: "10px"
                  }}
                  title={row.nameAr}
                >
                  {row.nameAr}
                </TableCell>

                <TableCell
                  scope="row"
                  padding="none"
                  style={{ paddingRight: "12px", paddingLeft: "16px" }}
                >
                  {row.id}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px",
                    paddingLeft: "16px"
                  }}
                  title={row.descriptionEn}
                >
                  {row.descriptionEn}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px",
                    paddingLeft: "16px"
                  }}
                  title={row.descriptionAr}
                >
                  {row.descriptionAr}
                </TableCell>

                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px",
                    paddingLeft: "16px"
                  }}
                  title={row.logo}
                >
                  {row.logo && (
                    <a href={row.logo} target="_blank" rel="noreferrer">
                      <img
                        src={row.logo}
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50px",
                          objectFit: "cover",
                          marginBlock: 5
                        }}
                      />
                    </a>
                  )}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px",
                    paddingLeft: "16px"
                  }}
                  title={row.tncEn}
                >
                  {row.tncEn}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px",
                    paddingLeft: "16px"
                  }}
                  title={row.tncAr}
                >
                  {row.tncAr}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px",
                    textTransform: "uppercase"
                  }}
                  title={row.countryCode}
                >
                  {row.countryCode}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.url}
                >
                  <a href={row.url} target="__blank">
                    {row.url}
                  </a>
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row?.isPopular?.toString()}
                >
                  {row?.isPopular?.toString()}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.order}
                >
                  {row.order}
                </TableCell>
                <TableCell scope="row" padding="none">
                  <ActionButtons
                    item={row}
                    deleteButton={true}
                    editButton={false}
                    handleDelete={() => handleDeleteMerchant(row.id, pageNum)}
                    disabled={editRow || row.edit}
                    message="with all its linked target and deals"
                  />
                </TableCell>
                <TableCell scope="row" padding="none">
                  {(!editRow || !row.edit) && (
                    <Button
                      onClick={() => handleEdit(index)}
                      disabled={editRow || row.edit}
                    >
                      <img src={EditIcon} alt="" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {editRow && (
          <EditMerchant
            item={itemToEdit}
            open={editRow}
            updateFieldChanged={updateFieldChanged}
            validatedFiled={validatedFiled}
            errorMessage={errorMessage}
            itemIndex={itemIndex}
            handleSaveEdit={handleSaveEdit}
            handleRestEdit={handleRestEdit}
            handleCloseEdit={handleCloseEdit}
            setErrorMessage={setErrorMessage}
          />
        )}
      </CustomTable>
      {openModal && (
        <ModalContainer
          open={openModal}
          handleClose={handleCloseModal}
          title="Create Merchants"
        />
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    list: state.merchantsList.list,
    hasNext: state.merchantsList.hasNext,
    isLoading: state.merchantsList.isLoading,
    error: state.merchantsList.serverError,
    success: state.merchantsList.success,
    updateMessage: state.merchantsList.updateMessage,
    totalPages: state.merchantsList.totalPages,
    limit: state.merchantsList.limit,
    uploadSuccessMessage: state.merchantsList.uploadSuccessMessage,
    deleteSuccessMessage: state.merchantsList.deleteSuccessMessage
  };
};

export default connect(
  mapStateToProps,
  {
    fetchMerchantList,
    handleDeleteMerchant,
    closeNotification,
    checkRequiredFields,
    submitUpdateMerchant,
    resetUploadMerchantList
  }
)(MerchantTable);

MerchantTable.propTypes = {
  list: PropTypes.array,
  hasNext: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleDeleteMerchant: PropTypes.func,
  closeNotification: PropTypes.func,
  error: PropTypes.string,
  checkRequiredFields: PropTypes.func,
  submitUpdateMerchant: PropTypes.func,
  updateMessage: PropTypes.string,
  totalPages: PropTypes.number,
  limit: PropTypes.number,
  uploadSuccessMessage: PropTypes.string,
  deleteSuccessMessage: PropTypes.string
};
