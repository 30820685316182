import {
  GET_EXCHANGE_RATES,
  UPDATE_EXCHANGE_RATES
} from "../../actions/exchange-rates";

const initialState = {
  data: [],
  meta: {
    total: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXCHANGE_RATES:
      return action.payload;
    case UPDATE_EXCHANGE_RATES:
      const changeIds = action.payload.map(exchangeRate => exchangeRate.id);
      return {
        ...state,
        data: state.data.map(exchangeRate => {
          if (changeIds.indexOf(exchangeRate.id) !== -1) {
            const layoutExchangeRate = action.payload.find(
              layoutExchangeRate => layoutExchangeRate.id === exchangeRate.id
            );
            return {
              ...exchangeRate,
              exchangeRate: layoutExchangeRate.exchangeRate
            };
          } else {
            return exchangeRate;
          }
        })
      };
    default:
      return state;
  }
};
