import { waslaRewardBaseurl, authURL } from "../../../components/config";

import { FETCH_BALANCE_API_LIST } from "../actionType";

export const fetchBalanceList = () => async dispatch => {
  await waslaRewardBaseurl
    .get(`/external/cashout/statistics/`)
    .then(res => {
      dispatch({
        type: FETCH_BALANCE_API_LIST,
        payload: res.data
      });
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_SERVER_RECHARGE",
          payload: "Authentication expired"
        });
      }
      dispatch({
        type: "SET_ERROR_SERVER_RECHARGE",
        payload: "Something went wrong"
      });
    });
};
