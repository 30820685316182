import addConfig from "./add/addConfig";
import editConfig from "./edit/editConfig";

export default {
  routes: [
    {
      routes: [...addConfig.routes, ...editConfig.routes]
    }
  ]
};
