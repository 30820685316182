import qs from "qs";
import { waslaRewardBaseurl, authURL } from "../../../components/config";
import {
  ERROR_EXPORT,
  RESET_ERROR_EXPORT,
  OPEN_SNACK_BAR
} from "../actionType";
export const fetchExportFileUrl = (
  fileType,
  ids,
  bulkTransactionId
) => async dispatch => {
  dispatch({ type: RESET_ERROR_EXPORT });
  const Timezone = (new Date().getTimezoneOffset() / 60) * -1;
  await waslaRewardBaseurl
    .get(`external/cashout/charge/${fileType}`, {
      headers: {
        Timezone
      },
      params: {
        ids,
        bulkTransactionId
      },

      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "comma",
          encode: false,
          skipNulls: true
        });
      }
    })
    .then(res => {
      window.location.href = res.data.pdfUrl;
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: ERROR_EXPORT,
          payload: "Authentication expired"
        });
      }
      if (error.response.status === 400) {
        dispatch({ type: OPEN_SNACK_BAR, payload: true });
        dispatch({
          type: ERROR_EXPORT,
          payload: "Please select the user number you want to export"
        });
      }
    });
};

export const restErrorExport = () => {
  return {
    type: RESET_ERROR_EXPORT
  };
};
