import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// redux actions
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
import QuestionComponent from "../../QuestionComponent";
import TransferPoints from "../../TransferPoints";
// import MultipleUsers from "./MultipleUsers";

import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const TransferPointsComponent = ({ pointsSystem }) => {
  const classes = useStyles();

  return (
    <CardContainer title="Bonus Points System">
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <QuestionComponent question="Do you want to reward a single user?" />
          {pointsSystem.typeOfTransfer && <TransferPoints />}
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    pointsSystem: state.pointsSystem
  };
};

export default connect(mapStateToProps)(TransferPointsComponent);

TransferPointsComponent.propTypes = {
  pointsSystem: PropTypes.object,
  checkRequiredFields: PropTypes.func,
  clearCallToActionValues: PropTypes.func
};
