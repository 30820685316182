import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, generatePath } from "react-router-dom";

import * as dealsService from "../../../services/deals";
import moment from "moment";

import {
  updateQuery,
  updateSearchBy
} from "../../../main/cashbacks/conversions/actions/search";
import { updateDateRange } from "../../../main/cashbacks/conversions/actions/filtration";
import { updateDisplayedColumns } from "../../../main/cashbacks/conversions/actions/layout";
import {
  updateOrderBy,
  updateItems,
  addSelectedItems,
  deleteSelectedItems,
  addSelectedItem,
  deleteSelectedItem,
  updateItem
} from "../../../main/cashbacks/conversions/actions/list";
import {
  updatePage,
  updateLimit,
  updateTotal
} from "../../../main/cashbacks/conversions/actions/pagination";
import { addItem as addNotification } from "../../../widgets/notifications/actions/list";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import Button from "@mui/material/Button";

import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";

import Loader from "../../../components/loader";

import CircularProgress from "@mui/material/CircularProgress";

import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CSVLink } from "react-csv";

import Pagination from "../../../components/Pagination";

import WaslaTheme from "../../../modules/wasla-theme";

import configurations from "../../../configurations";

import { cashbacksPath } from "../paths";
import { cashbacksConversionsConversionPath } from "./conversion/paths";
import { cashbacksConversionsEditRequestEditPath } from "./edit-request/edit/paths";
import { cashbacksConversionsEditRequestHistoryPath } from "./edit-request/history/paths";

import { DateRangePicker, Stack as StackDate } from "rsuite";
import { predefinedRanges } from "./DateRanges";
import "rsuite/dist/rsuite.css";
import "./DateRanges.css";
import CalendarIcon from "../assets/calendar.png";
import ProcessingImg from "./assets/img/processing.png";
import EditImg from "./assets/img/edit.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConversionRecord = props => {
  const {
    item,
    checked,
    displayedColumns,
    addSelectedItem,
    deleteSelectedItem,
    updateItem,
    addNotification
  } = props;

  const [confirmApprove, setConfirmApprove] = useState(false);
  const [sync, setSync] = useState(false);

  let { countryCode } = useParams();

  const conversionDate = new Date(item.conversionDate);
  const actionAt = new Date(item.action.modificationDate);

  const handleSelectChange = e => {
    if (e.target.checked) {
      addSelectedItem(item);
    } else {
      deleteSelectedItem(item.id);
    }
  };

  const handleRejectClick = (id, shoppingId) => async e => {
    setSync(true);
    await dealsService
      .rejectConversion(id)
      .then(
        async res =>
          await dealsService
            .getConversions(
              countryCode,
              shoppingId,
              "shoppingId",
              "",
              "",
              "createdDate",
              1,
              1
            )
            .then(conversionRes => {
              updateItem(id, { ...conversionRes.data[0] });
            })
            .catch(error => {
              addNotification({
                id: new Date().getTime(),
                type: "error",
                message:
                  "Error in retriving conversion update after approve please refresh the page."
              });
            })
      )
      .catch(error => {
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: "Error in Reject the conversion try again."
        });
      });
    setSync(false);
  };

  const handleApproveClick = e => {
    updateItem(item.id, {
      ...item,
      onApprovedExchangeRate: item.preApprovedExchangeRate
    });
    setConfirmApprove(true);
  };

  const handleConfirmApproveClose = e => {
    setConfirmApprove(false);
  };

  const handleConfirmApproveCancel = e => {
    setConfirmApprove(false);
  };

  const handleReloadClick = (id, shoppingId) => async e => {
    setSync(true);
    await dealsService
      .getConversions(
        countryCode,
        shoppingId,
        "shoppingId",
        "",
        "",
        "createdDate",
        1,
        1
      )
      .then(conversionRes => {
        updateItem(id, { ...conversionRes.data[0] });
      })
      .catch(error => {
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message:
            "Error in retriving conversion update after approve please refresh the page."
        });
      });
    setSync(false);
  };

  const handleConfirmApproveApprove = (id, shoppingId) => async e => {
    setSync(true);
    await dealsService
      .approveConversion(id, item.onApprovedExchangeRate)
      .then(
        async res =>
          await dealsService
            .getConversions(
              countryCode,
              shoppingId,
              "shoppingId",
              "",
              "",
              "createdDate",
              1,
              1
            )
            .then(conversionRes => {
              updateItem(id, { ...conversionRes.data[0] });
            })
            .catch(error => {
              addNotification({
                id: new Date().getTime(),
                type: "error",
                message:
                  "Error in retriving conversion update after approve please refresh the page."
              });
            })
            .finally(() => {
              setConfirmApprove(false);
            })
      )
      .catch(error => {
        addNotification({
          id: new Date().getTime(),
          type: "error",
          message: "Error in Approve the conversion try again."
        });
      });
    setSync(false);
  };

  const ProcessingIcon = () => (
    <img src={ProcessingImg} alt="" style={{ width: "20px" }} />
  );

  const EditIcon = () => <img src={EditImg} alt="" style={{ width: "20px" }} />;

  return (
    <TableRow
      sx={{
        ...(() =>
          item.status.toLowerCase() == "processing"
            ? {
                backgroundColor: "rgba(217, 217, 217, 0.4)"
              }
            : {})()
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={checked}
          onChange={handleSelectChange}
          inputProps={{
            "aria-label": "select desserts"
          }}
          icon={
            <SvgIcon sx={{ color: "transparent" }}>
              <rect
                x="0.5"
                y="0.5"
                width="19"
                height="19"
                rx="3.5"
                fill="white"
              />
              <rect
                x="2"
                y="2"
                width="19"
                height="19"
                rx="3.5"
                stroke="#D0D5DD"
              />
            </SvgIcon>
          }
          checkedIcon={
            <SvgIcon sx={{ color: "transparent" }}>
              <rect
                x="2"
                y="2"
                width="19"
                height="19"
                rx="3.5"
                fill="#FFF8E2"
              />
              <path
                d="M16.6666 8.5L10.24992 14.9167L7.33325 12"
                stroke="#FFCC0A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="2"
                y="2"
                width="19"
                height="19"
                rx="3.5"
                stroke="#FFCC0A"
              />
            </SvgIcon>
          }
        />
      </TableCell>
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "countryCode"
        )) && <TableCell>{item.country}</TableCell>}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "conversionDate"
        )) && (
        <TableCell>{`${conversionDate.getDate()} ${conversionDate.toLocaleString(
          "default",
          {
            month: "short"
          }
        )}, ${conversionDate.getFullYear()}`}</TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "userId"
        )) && (
        <TableCell sx={{ fontWeight: 500, color: "#101828" }}>
          {item.userId}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "dealId"
        )) && (
        <TableCell sx={{ fontWeight: 500, color: "#101828" }}>
          {item.dealId}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "shoppingId"
        )) && (
        <TableCell sx={{ fontWeight: 500, color: "#101828" }}>
          {item.shoppingId}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "transactionId"
        )) && (
        <TableCell sx={{ fontWeight: 500, color: "#101828" }}>
          {item.externalId}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "transactionValue"
        )) && (
        <TableCell>
          {item.transactionValue} {item.currency}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "commission"
        )) && (
        <TableCell>
          {item.commission} {item.currency}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "cashback"
        )) && (
        <TableCell>
          {item.cashbackValue} {item.currency}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "preApprovedCashbackPoints"
        )) && (
        <TableCell>
          {item.preApprovedCashbackPoints?.toLocaleString("en-US", {})} pts
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "preApprovedExchangeRate"
        )) && <TableCell>{item.preApprovedExchangeRate}</TableCell>}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "postApprovedExchangeRate"
        )) && (
        <TableCell
          sx={{
            ...(() =>
              item.status.toLowerCase() == "processing"
                ? {
                    textAlign: "center"
                  }
                : {})()
          }}
        >
          {item.status.toLowerCase() == "processing" ? (
            <ProcessingIcon />
          ) : item.status.toLowerCase() != "pending" ? (
            item.postApprovedExchangeRate
          ) : (
            <></>
          )}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "postApprovedCashbackPoints"
        )) && (
        <TableCell
          sx={{
            ...(() =>
              item.status.toLowerCase() == "processing"
                ? {
                    textAlign: "center"
                  }
                : {})()
          }}
        >
          {item.status.toLowerCase() == "processing" ? (
            <ProcessingIcon />
          ) : item.status.toLowerCase() != "pending" &&
            item.postApprovedCashbackPoints ? (
            `${item.postApprovedCashbackPoints?.toLocaleString(
              "en-US",
              {}
            )} pts`
          ) : (
            <></>
          )}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "status"
        )) && (
        <>
          <TableCell>
            <Typography
              sx={{
                ...(status => {
                  switch (status) {
                    case "approved":
                      return { backgroundColor: "#EBFFE5", color: "#3D9530" };
                    case "rejected":
                      return { backgroundColor: "#FEF3F2", color: "#B42318" };
                    case "pending":
                      return { backgroundColor: "#F2F4F7", color: "#344054" };
                    default:
                      return { backgroundColor: "#F2F4F7", color: "#344054" };
                  }
                })(item.status.toLowerCase()),
                py: "2px",
                px: "8px",
                borderRadius: "16px",
                width: "max-content",
                display: "flex",
                gap: "6px",
                alignItems: "center",
                textAlign: "center",
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "18px",
                "&::before": {
                  content: "''",
                  display: "inline-block",
                  width: 6,
                  height: 6,
                  borderRadius: "50%",
                  ...(status => {
                    switch (status) {
                      case "approved":
                        return { backgroundColor: "#3D9530" };
                      case "rejected":
                        return { backgroundColor: "#F04438" };
                      case "pending":
                        return { backgroundColor: "#667085" };
                      default:
                        return { backgroundColor: "#667085" };
                    }
                  })(item.status.toLowerCase())
                }
              }}
            >
              {item.status}
            </Typography>
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              startIcon={sync && <CircularProgress size={24} />}
              sx={{
                borderColor: "#039855",
                padding: "8px 14px",
                backgroundColor: "#039855",
                textTransform: "capitalize",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#FCFCFD",
                "&:hover": {
                  backgroundColor: "#039855"
                },
                ...(() =>
                  item.status.toLowerCase() == "pending" &&
                  item.hasEditRequest &&
                  item.editRequestStatus.toLowerCase() == "pending"
                    ? {
                        "&.Mui-disabled": {
                          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                          borderColor: "#FFD125",
                          backgroundColor: "#FFF8E2",
                          color: "#FFD125"
                        }
                      }
                    : {})()
              }}
              onClick={handleApproveClick}
              disabled={
                sync ||
                item.status.toLowerCase() != "pending" ||
                (item.hasEditRequest &&
                  item.editRequestStatus.toLowerCase() == "pending")
              }
            >
              Approved
            </Button>
            <Dialog
              open={confirmApprove}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleConfirmApproveClose}
              aria-describedby={`delete-${item.id}`}
              maxWidth="xs"
            >
              <DialogTitle>
                <Stack spacing={2}>
                  <Box textAlign="center">
                    <IconButton
                      sx={{
                        backgroundColor: "#FEE4E2",
                        border: "8px solid #FEF3F2",
                        "&:hover": { backgroundColor: "#FEE4E2" }
                      }}
                    >
                      <ErrorOutlineOutlinedIcon color="error" />
                    </IconButton>
                  </Box>
                  <Typography
                    variant="inherit"
                    sx={{
                      fontWeight: 500,
                      fontSize: "18px",
                      lineHeight: "28px",
                      color: "#101828"
                    }}
                  >
                    Confirm The Approved Amount FX
                  </Typography>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <DialogContentText></DialogContentText>
                <TextField
                  value={item.onApprovedExchangeRate ?? ""}
                  inputProps={{
                    sx: {
                      textAlign: "center"
                    }
                  }}
                  sx={{ width: "100%" }}
                  onChange={e =>
                    updateItem(item.id, {
                      ...item,
                      onApprovedExchangeRate: e.target.value
                    })
                  }
                />
              </DialogContent>
              <DialogActions>
                <Stack
                  direction="row"
                  sx={{ width: "100%" }}
                  spacing={2}
                  justifyContent="stretch"
                >
                  <Button
                    onClick={handleConfirmApproveCancel}
                    variant="neutral"
                    sx={{
                      width: "100%",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "inherit",
                        color: "inherit"
                      }
                    }}
                    disabled={sync}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmApproveApprove(
                      item.id,
                      item.shoppingId
                    )}
                    variant="contained"
                    color="error"
                    sx={{
                      width: "100%",
                      borderColor: "#D92D20",
                      backgroundColor: "#D92D20",
                      textTransform: "capitalize",
                      color: "#FCFCFD",
                      "&:hover": {
                        backgroundColor: "#D92D20",
                        color: "#FCFCFD"
                      }
                    }}
                    disabled={sync}
                  >
                    Confirm
                  </Button>
                </Stack>
              </DialogActions>
            </Dialog>
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              startIcon={sync ? <CircularProgress size={24} /> : <BlockIcon />}
              sx={{
                borderColor: "#D92D20",
                padding: "8px 14px",
                backgroundColor: "#D92D20",
                textTransform: "capitalize",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#FCFCFD",
                "&:hover": {
                  backgroundColor: "#D92D20"
                },
                ...(() =>
                  item.status.toLowerCase() == "pending" &&
                  item.hasEditRequest &&
                  item.editRequestStatus.toLowerCase() == "pending"
                    ? {
                        "&.Mui-disabled": {
                          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                          borderColor: "#FFD125",
                          backgroundColor: "#FFF8E2",
                          color: "#FFD125"
                        }
                      }
                    : {})()
              }}
              onClick={handleRejectClick(item.id, item.shoppingId)}
              disabled={
                sync ||
                item.status.toLowerCase() != "pending" ||
                (item.hasEditRequest &&
                  item.editRequestStatus.toLowerCase() == "pending")
              }
            >
              Rejected
            </Button>
          </TableCell>
        </>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "details"
        )) && (
        <TableCell>
          {!sync ? (
            item.status.toLowerCase() == "processing" ? (
              <LoopOutlinedIcon
                onClick={handleReloadClick(item.id, item.shoppingId)}
              />
            ) : item.hasEditRequest ||
              item.status.toLowerCase() != "pending" ? (
              <Link
                underline="none"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    color: "inherit"
                  }
                }}
                color="inherit"
                href={[
                  configurations.dashboard.basePath,
                  generatePath(
                    item.hasEditRequest &&
                      item.editRequestStatus.toLowerCase() == "pending"
                      ? cashbacksConversionsEditRequestEditPath
                      : item.hasEditRequest
                      ? cashbacksConversionsEditRequestHistoryPath
                      : cashbacksConversionsConversionPath,
                    {
                      countryCode,
                      id: item.shoppingId
                    }
                  )
                ].join("")}
              >
                {item.hasEditRequest &&
                item.editRequestStatus.toLowerCase() == "pending" ? (
                  <EditIcon />
                ) : (
                  <HistoryOutlinedIcon />
                )}
              </Link>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "actionBy"
        )) && (
        <TableCell
          sx={{
            ...(() =>
              item.status.toLowerCase() == "processing"
                ? {
                    textAlign: "center"
                  }
                : {})()
          }}
        >
          {item.status.toLowerCase() == "processing" ? (
            <ProcessingIcon />
          ) : item.status.toLowerCase() != "pending" ? (
            item.action.modifiedBy
          ) : (
            <></>
          )}
        </TableCell>
      )}
      {(!displayedColumns.length ||
        displayedColumns.find(
          displayedColumn => displayedColumn === "actionAt"
        )) && (
        <TableCell
          sx={{
            ...(() =>
              item.status.toLowerCase() == "processing"
                ? {
                    textAlign: "center"
                  }
                : {})()
          }}
        >
          {item.status.toLowerCase() == "processing" ? (
            <ProcessingIcon />
          ) : item.status.toLowerCase() != "pending" ? (
            `${actionAt.getDate()} ${actionAt.toLocaleString("default", {
              month: "short"
            })} ${actionAt.getFullYear()} at ${String(
              actionAt.getHours() % 12
            ).padStart(2, "0")}:${String(actionAt.getMinutes()).padStart(
              2,
              "0"
            )} ${actionAt.getHours() < 12 ? "AM" : "PM"}`
          ) : (
            <></>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

const Conversions = props => {
  const {
    conversions: {
      search: { query, searchBy },
      filtration: { dateFrom, dateTo },
      layout: { displayedColumns },
      list: { orderBy, items, selectedItems },
      pagination: { page, limit, total }
    },
    updateQuery,
    updateSearchBy,
    updateDateRange,
    updateDisplayedColumns,
    updateOrderBy,
    updateItems,
    updatePage,
    addSelectedItems,
    deleteSelectedItems,
    addSelectedItem,
    deleteSelectedItem,
    updateItem,
    updateLimit,
    updateTotal,
    addNotification
  } = props;

  const [loading, setLoading] = useState(true);

  let { countryCode } = useParams();

  const retrieveConversions = async (
    countryCode,
    query,
    dateFrom,
    dateTo,
    searchBy,
    orderBy,
    page,
    limit
  ) => {
    setLoading(true);
    const res = await dealsService
      .getConversions(
        countryCode,
        query.trim(),
        searchBy,
        dateFrom,
        dateTo,
        orderBy,
        page,
        limit
      )
      .catch(error => ({ data: [], meta: { total: 0 } }));
    updateItems(
      res.data.map(item => ({
        ...item,
        onApprovedExchangeRate: item.preApprovedExchangeRate
      }))
    );
    updateTotal(res.meta.total);
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      updateQuery("");
    };
  }, []);

  useEffect(() => {
    retrieveConversions(
      countryCode,
      query,
      dateFrom,
      dateTo,
      searchBy,
      orderBy,
      page,
      limit
    );
  }, [page]);

  useEffect(() => {
    if (page == 1) {
      retrieveConversions(
        countryCode,
        query,
        dateFrom,
        dateTo,
        searchBy,
        orderBy,
        page,
        limit
      );
    } else {
      updatePage(1);
    }
  }, [query, dateFrom, dateTo, orderBy, limit]);

  useEffect(() => {
    if (query) {
      if (page == 1) {
        retrieveConversions(
          countryCode,
          query,
          dateFrom,
          dateTo,
          searchBy,
          orderBy,
          page,
          limit
        );
      } else {
        updatePage(1);
      }
    }
  }, [searchBy]);

  const handleDisplayedColumnsChange = e => {
    if (e.target.value.length) updateDisplayedColumns(e.target.value);
  };

  const handleSelectChange = e => {
    if (e.target.checked) {
      addSelectedItems(
        items.filter(
          item =>
            !Boolean(
              selectedItems.find(selectedItem => selectedItem.id === item.id)
            )
        )
      );
    } else {
      deleteSelectedItems(
        items
          .filter(item =>
            Boolean(
              selectedItems.find(selectedItem => selectedItem.id === item.id)
            )
          )
          .map(item => item.id)
      );
    }
  };

  const handlePageClick = (e, page) => {
    updatePage(page);
  };

  const handleLimitChange = e => {
    updateLimit(parseInt(e.target.value, 10));
  };

  const searchKeys = [
    { id: "userId", label: "User ID" },
    { id: "dealId", label: "Deal ID" },
    { id: "shoppingId", label: "Shopping ID" },
    { id: "transactionId", label: "EF Conversion ID" }
  ];

  const availableColumns = [
    { id: "countryCode", label: "Country" },
    { id: "conversionDate", label: "Transaction Date" },
    { id: "userId", label: "User ID" },
    { id: "dealId", label: "Deal ID" },
    { id: "shoppingId", label: "Shopping ID" },
    { id: "transactionId", label: "EF Conversion ID" },
    { id: "transactionValue", label: "Transaction Value" },
    { id: "commission", label: "Wasla Commission" },
    { id: "cashback", label: "User CB Value" },
    { id: "preApprovedCashbackPoints", label: "CB Points" },
    { id: "preApprovedExchangeRate", label: "Exchange Rate Pending" },
    { id: "postApprovedExchangeRate", label: "Exchange Rate for approvals" },
    { id: "postApprovedCashbackPoints", label: "Rewarded Points" },
    { id: "status", label: "Status" },
    { id: "details", label: "Action" },
    { id: "actionBy", label: "Action Taken by" },
    { id: "actionAt", label: "Action Taken at" }
  ];

  const sortingKeys = [
    { id: "createdDate", label: "Most Recent" },
    { id: "conversionDate", label: "Transaction Date" }
  ];

  const DateRangeIcon = () => (
    <img src={CalendarIcon} alt="" style={{ width: "16px" }} />
  );

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Stack
          spacing={4}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            py: "12px",
            px: 2
          }}
        >
          <Stack spacing={4}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link
                underline="none"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    color: "inherit"
                  }
                }}
                color="inherit"
                href={[configurations.dashboard.basePath, cashbacksPath].join(
                  ""
                )}
              >
                Select a country for Cashback
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="inherit"
              >
                Cashbacks Table
              </Typography>
            </Breadcrumbs>
          </Stack>
          <CSVLink
            filename={"cashbacks-conversions.csv"}
            headers={[
              "Country",
              "Transaction Date",
              "User ID",
              "Deal ID",
              "Shopping ID",
              "EF Conversion ID",
              "Transaction Value",
              "Wasla Commission",
              "User CB Value",
              "CB Points",
              "Exchange Rate Pending",
              "Exchange Rate for approvals",
              "Rewarded Points",
              "Status",
              "Action Taken by",
              "Action Taken at"
            ]}
            data={selectedItems.map(selectedItem => {
              const conversionDate = new Date(selectedItem.conversionDate);
              const actionAt = new Date(selectedItem.action.modificationDate);

              return [
                selectedItem.country,
                `${conversionDate.getDate()} ${conversionDate.toLocaleString(
                  "default",
                  {
                    month: "short"
                  }
                )}, ${conversionDate.getFullYear()}`,
                selectedItem.userId,
                selectedItem.dealId,
                selectedItem.shoppingId,
                selectedItem.externalId,
                `${selectedItem.transactionValue} ${selectedItem.currency}`,
                `${selectedItem.commission} ${selectedItem.currency}`,
                `${selectedItem.cashbackValue} ${selectedItem.currency}`,
                `${selectedItem.preApprovedCashbackPoints?.toLocaleString(
                  "en-US",
                  {}
                )} pts`,
                selectedItem.preApprovedExchangeRate,
                selectedItem.postApprovedExchangeRate,
                `${selectedItem.postApprovedCashbackPoints?.toLocaleString(
                  "en-US",
                  {}
                )} pts`,
                selectedItem.status,
                selectedItem.action.modifiedBy,
                `${actionAt.getDate()} ${actionAt.toLocaleString("default", {
                  month: "short"
                })} ${actionAt.getFullYear()} at ${String(
                  actionAt.getHours() % 12
                ).padStart(2, "0")}:${String(actionAt.getMinutes()).padStart(
                  2,
                  "0"
                )} ${actionAt.getHours() < 12 ? "AM" : "PM"}`
              ];
            })}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Button
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              sx={{ textTransform: "capitalize" }}
              disabled={!selectedItems.length}
            >
              Download CSV{" "}
              {selectedItems.length ? `(${selectedItems.length})` : ""}
            </Button>
          </CSVLink>
        </Stack>
        <Stack
          spacing={4}
          direction="row"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#F9FAFB",
            py: "12px",
            px: 2,
            borderRadius: 3
          }}
        >
          <Stack spacing={1.5} direction="row" justifyContent="space-between">
            <TextField
              label=""
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              sx={{ minWidth: "460px" }}
              value={query}
              placeholder="Search for User ID, EF Conversion ID, Deal ID, Shopping ID..."
              onChange={e => updateQuery(e.target.value)}
            />
            <FormControl>
              <Select
                value={searchBy}
                onChange={e => updateSearchBy(e.target.value)}
                displayEmpty
                id="search-by-dropdown"
                inputProps={{
                  id: "search-by"
                }}
                MenuProps={{
                  id: "search-by-menu"
                }}
              >
                {searchKeys.map(searchKey => (
                  <MenuItem key={searchKey.id} value={searchKey.id}>
                    {searchKey.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack spacing={1.5} direction="row" justifyContent="space-between">
            <StackDate direction="column" spacing={8} alignItems="flex-start">
              <DateRangePicker
                ranges={predefinedRanges}
                placeholder="MM dd, yyyy - MM dd, yyyy"
                caretAs={DateRangeIcon}
                style={{ width: 200, direction: "rtl" }}
                renderValue={value => {
                  return (
                    moment(value[0]).format("ll") +
                    " - " +
                    moment(value[1]).format("ll")
                  );
                }}
                onOk={value => {
                  updateDateRange(value);
                }}
                onClean={() => {
                  updateDateRange([undefined, undefined]);
                }}
              />
            </StackDate>
            <FormControl>
              <Select
                value={displayedColumns}
                onChange={handleDisplayedColumnsChange}
                displayEmpty
                id="displayed-columns-dropdown"
                inputProps={{
                  id: "displayed-columns"
                }}
                MenuProps={{
                  id: "displayed-columns-menu"
                }}
                multiple
                renderValue={value => "Column"}
              >
                {availableColumns.map(column => (
                  <MenuItem
                    key={column.id}
                    value={column.id}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "transparent"
                      }
                    }}
                  >
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Checkbox
                        color="primary"
                        checked={Boolean(
                          displayedColumns.find(
                            displayedColumn => displayedColumn === column.id
                          )
                        )}
                        icon={
                          <SvgIcon sx={{ color: "transparent" }}>
                            <rect
                              x="0.5"
                              y="0.5"
                              width="19"
                              height="19"
                              rx="3.5"
                              fill="white"
                            />
                            <rect
                              x="2"
                              y="2"
                              width="19"
                              height="19"
                              rx="3.5"
                              stroke="#D0D5DD"
                            />
                          </SvgIcon>
                        }
                        checkedIcon={
                          <SvgIcon sx={{ color: "transparent" }}>
                            <rect
                              x="2"
                              y="2"
                              width="19"
                              height="19"
                              rx="3.5"
                              fill="#FFF8E2"
                            />
                            <path
                              d="M16.6666 8.5L10.24992 14.9167L7.33325 12"
                              stroke="#FFCC0A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <rect
                              x="2"
                              y="2"
                              width="19"
                              height="19"
                              rx="3.5"
                              stroke="#FFCC0A"
                            />
                          </SvgIcon>
                        }
                      />
                      <span>{column.label}</span>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                value={orderBy}
                onChange={e => updateOrderBy(e.target.value)}
                displayEmpty
                id="order-by-dropdown"
                inputProps={{
                  id: "order-by"
                }}
                MenuProps={{
                  id: "order-by-menu"
                }}
              >
                {sortingKeys.map(sortingKey => (
                  <MenuItem key={sortingKey.id} value={sortingKey.id}>
                    {sortingKey.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={
                      1 +
                      (displayedColumns.length
                        ? displayedColumns.length
                        : availableColumns.length) +
                      (!displayedColumns.length ||
                      Boolean(
                        displayedColumns.find(
                          displayedColumn => displayedColumn === "status"
                        )
                      )
                        ? 2
                        : 0)
                    }
                  >
                    <Typography variant="inherit">All Cashbacks</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.length ? (
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          items.some(item =>
                            Boolean(
                              selectedItems.find(
                                selectedItem => selectedItem.id === item.id
                              )
                            )
                          ) &&
                          !items.every(item =>
                            Boolean(
                              selectedItems.find(
                                selectedItem => selectedItem.id === item.id
                              )
                            )
                          )
                        }
                        checked={
                          Boolean(selectedItems.length) &&
                          items.every(item =>
                            Boolean(
                              selectedItems.find(
                                selectedItem => selectedItem.id === item.id
                              )
                            )
                          )
                        }
                        onChange={handleSelectChange}
                        inputProps={{
                          "aria-label": "select all desserts"
                        }}
                        icon={
                          <SvgIcon sx={{ color: "transparent" }}>
                            <rect
                              x="0.5"
                              y="0.5"
                              width="19"
                              height="19"
                              rx="3.5"
                              fill="white"
                            />
                            <rect
                              x="2"
                              y="2"
                              width="19"
                              height="19"
                              rx="3.5"
                              stroke="#D0D5DD"
                            />
                          </SvgIcon>
                        }
                        indeterminateIcon={
                          <SvgIcon sx={{ color: "transparent" }}>
                            <rect
                              x="2"
                              y="2"
                              width="19"
                              height="19"
                              rx="3.5"
                              fill="#FFF8E2"
                            />
                            <path
                              d="M16.6666 8.5L10.24992 14.9167L7.33325 12"
                              stroke="#FFCC0A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <rect
                              x="2"
                              y="2"
                              width="19"
                              height="19"
                              rx="3.5"
                              stroke="#FFCC0A"
                            />
                          </SvgIcon>
                        }
                        checkedIcon={
                          <SvgIcon sx={{ color: "transparent" }}>
                            <rect
                              x="2"
                              y="2"
                              width="19"
                              height="19"
                              rx="3.5"
                              fill="#FFF8E2"
                            />
                            <path
                              d="M16.6666 8.5L10.24992 14.9167L7.33325 12"
                              stroke="#FFCC0A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <rect
                              x="2"
                              y="2"
                              width="19"
                              height="19"
                              rx="3.5"
                              stroke="#FFCC0A"
                            />
                          </SvgIcon>
                        }
                      />
                    </TableCell>
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "countryCode"
                      )) && <TableCell component="th">Country</TableCell>}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "conversionDate"
                      )) && (
                      <TableCell component="th">Transaction Date</TableCell>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "userId"
                      )) && <TableCell component="th">User ID</TableCell>}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "dealId"
                      )) && <TableCell component="th">Deal ID</TableCell>}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "shoppingId"
                      )) && <TableCell component="th">Shopping ID</TableCell>}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "transactionId"
                      )) && (
                      <TableCell component="th">EF Conversion ID</TableCell>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn =>
                          displayedColumn === "transactionValue"
                      )) && (
                      <TableCell component="th">Transaction Value</TableCell>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "commission"
                      )) && (
                      <TableCell component="th">Wasla Commission</TableCell>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "cashback"
                      )) && <TableCell component="th">User CB Value</TableCell>}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn =>
                          displayedColumn === "preApprovedCashbackPoints"
                      )) && <TableCell component="th">CB Points</TableCell>}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn =>
                          displayedColumn === "preApprovedExchangeRate"
                      )) && (
                      <TableCell component="th">
                        Exchange Rate Pending
                      </TableCell>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn =>
                          displayedColumn === "postApprovedExchangeRate"
                      )) && (
                      <TableCell component="th">
                        Exchange Rate for approvals
                      </TableCell>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn =>
                          displayedColumn === "postApprovedCashbackPoints"
                      )) && (
                      <TableCell component="th">Rewarded Points</TableCell>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "status"
                      )) && (
                      <>
                        <TableCell component="th">Status</TableCell>
                        <TableCell component="th">Appropved</TableCell>
                        <TableCell component="th">Rejected</TableCell>
                      </>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "details"
                      )) && <TableCell component="th">Action</TableCell>}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "actionBy"
                      )) && (
                      <TableCell component="th">Action Taken by</TableCell>
                    )}
                    {(!displayedColumns.length ||
                      displayedColumns.find(
                        displayedColumn => displayedColumn === "actionAt"
                      )) && (
                      <TableCell component="th">Action Taken at</TableCell>
                    )}
                  </TableRow>
                ) : (
                  <></>
                )}
                {items.length ? (
                  items.map(item => (
                    <ConversionRecord
                      key={item.id}
                      item={item}
                      checked={Boolean(
                        selectedItems.find(
                          selectedItem => selectedItem.id === item.id
                        )
                      )}
                      displayedColumns={displayedColumns}
                      addSelectedItem={addSelectedItem}
                      deleteSelectedItem={deleteSelectedItem}
                      updateItem={updateItem}
                      addNotification={addNotification}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={
                        1 +
                        (displayedColumns.length
                          ? displayedColumns.length
                          : availableColumns.length) +
                        (!displayedColumns.length ||
                        Boolean(
                          displayedColumns.find(
                            displayedColumn => displayedColumn === "status"
                          )
                        )
                          ? 2
                          : 0)
                      }
                    >
                      <Stack
                        sx={{ minHeight: "240px" }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "30px",
                            color: "#2B2B2B"
                          }}
                        >
                          “No Conversions Found”
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    colSpan={
                      1 +
                      (displayedColumns.length
                        ? displayedColumns.length
                        : availableColumns.length) +
                      (!displayedColumns.length ||
                      Boolean(
                        displayedColumns.find(
                          displayedColumn => displayedColumn === "status"
                        )
                      )
                        ? 2
                        : 0)
                    }
                  >
                    <Pagination
                      page={page}
                      limit={limit}
                      total={total}
                      rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                      handlePageClick={handlePageClick}
                      handleLimitChange={handleLimitChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </WaslaTheme>
  );
};

export default connect(
  state => ({ conversions: state.conversions }),
  {
    updateQuery,
    updateSearchBy,
    updateDateRange,
    updateDisplayedColumns,
    updateOrderBy,
    updateItems,
    addSelectedItems,
    deleteSelectedItems,
    addSelectedItem,
    deleteSelectedItem,
    updateItem,
    updatePage,
    updateLimit,
    updateTotal,
    addNotification
  }
)(Conversions);
