import React from "react";
import { connect } from "react-redux";

import withShield from "../../with-shield";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import RedemptionHead from "../../../../main/redemption/views/redemption-head";
import RedemptionNavigator from "../../../../main/redemption/views/redemption-navigator";
import RedemptionForm from "../../../../main/redemption/views/redemption-form";

const Edit = props => {
  return (
    <Stack spacing={2}>
      <RedemptionHead />
      <RedemptionNavigator value="catalogue" />
      <Grid container justifyContent="center" rowSpacing={2}>
        <Grid item lg={8}>
          <RedemptionForm operation="update" />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default withShield(
  connect(
    state => ({ redemption: state.redemption }),
    {}
  )(Edit)
);
