import {
  FETCH_POINTS_CONFIG,
  RESET_STATE,
  ERROR_FETCH_POINTS_CONFIG
} from "../../actionType";
import { pointsBaseUrl } from "../../../../components/config";

export const fetchPointsConfig = () => async dispatch => {
  await pointsBaseUrl
    .get(`/config`)
    .then(res => {
      dispatch({
        type: FETCH_POINTS_CONFIG,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: ERROR_FETCH_POINTS_CONFIG,
        payload: err
      });
    });
};

export const clearStateValue = () => {
  return {
    type: RESET_STATE
  };
};
