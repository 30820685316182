import React, { useState, useEffect } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import withReducer from "../../../../../store/withReducer";
import MerchantReducers from "../reducers";
import * as dealsService from "../../../../../services/deals";

import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import WaslaTheme from "../../../../../modules/wasla-theme";

import configurations from "../../../../../configurations";
import { dealsMerchantsPath } from "../../../../../main/deals/merchants/paths";

import { updateOrder } from "../actions/local";
import { updateMerchant } from "../actions/origin";
import { addItem as addNotification } from "../../../../../widgets/notifications/actions/list";

import From from "../components/Form";

const AddMerchant = props => {
  const {
    merchant: { origin, local },
    updateOrder,
    updateMerchant,
    addNotification
  } = props;

  const [loading, setLoading] = useState(true);
  const [sync, setSync] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    setLoading(true);
    const res = await dealsService.getMerchants("", "-order", 1, 1);
    updateMerchant({
      ...origin,
      tncEn: "",
      tncAr: "",
      order: res.data[0].order + 1
    });
    updateOrder(res.data[0].order + 1);
    setLoading(false);
  }, []);

  const handleSubmitForm = async merchant => {
    setSync(true);
    await dealsService
      .addMerchant({
        order: merchant.order,
        nameEn: merchant.nameEn,
        nameAr: merchant.nameAr,
        url: merchant.url,
        descriptionEn: merchant.descriptionEn,
        descriptionAr: merchant.descriptionAr,
        isPopular: merchant.isPopular,
        countries: merchant.countries,
        targetUrls: merchant.targetUrls,
        tncEn: merchant.tncEn,
        tncAr: merchant.tncAr
      })
      .then(async addMerchantRes => {
        if (merchant.logoUrl) {
          const updateMerchantImageRes = await dealsService
            .updateMerchantImage(addMerchantRes.id, merchant.logoUrl)
            .catch(error => {});
        }
        history.push(
          [configurations.dashboard.basePath, dealsMerchantsPath].join("")
        );
      })
      .catch(error => {
        if (Array.isArray(error?.response?.data?.errors)) {
          error.response.data.errors.forEach(error => {
            addNotification({
              id: new Date().getTime(),
              type: "error",
              message: error.error_description
            });
          });
        }
      });
    setSync(false);
  };

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            component={RouterLink}
            to={[configurations.dashboard.basePath, dealsMerchantsPath].join(
              ""
            )}
          >
            Merchants
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            Add Merchant
          </Typography>
        </Breadcrumbs>
        <Grid container justifyContent="center">
          <Grid item lg={7}>
            {!loading && (
              <From
                submitForm={handleSubmitForm}
                loading={loading}
                sync={sync}
                operation="add"
              />
            )}
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default withReducer("merchant", MerchantReducers)(
  connect(
    state => ({ merchant: state.merchant }),
    {
      updateOrder,
      updateMerchant,
      addNotification
    }
  )(AddMerchant)
);
