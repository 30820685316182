import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import RedemptionReducers from "../../../main/redemption/reducers";
import withReducer from "../../../store/withReducer";

import WaslaTheme from "../../../modules/wasla-theme";

import configurations from "../../../configurations";
import { redemptionPath } from "../paths";

const withShield = Component =>
  withReducer("redemption", RedemptionReducers)(
    connect(
      state => ({ redemption: state.redemption }),
      {}
    )(props => {
      const { redemption } = props;

      const history = useHistory();
      const location = useLocation();

      useEffect(() => {
        if (
          location.pathname !==
            [configurations.dashboard.basePath, redemptionPath].join("") &&
          !redemption.country
        )
          history.push(
            [configurations.dashboard.basePath, redemptionPath].join("")
          );
      }, []);

      if (
        !(
          location.pathname !==
            [configurations.dashboard.basePath, redemptionPath].join("") &&
          !redemption.country
        )
      ) {
        return (
          <WaslaTheme>
            <Component {...props} />
          </WaslaTheme>
        );
      } else {
        return <></>;
      }
    })
  );

export default withShield;
