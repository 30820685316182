import qs from "qs";

import { dealsBaseurl, authURL } from "../../../components/config";

export const fetchDealsList = ({
  pageNum,
  countrySelected
}) => async dispatch => {
  dispatch({ type: "SET_LOADING_DEALS_LIST", payload: true });
  await dealsBaseurl
    .get(`/store/deals?page=${pageNum}&limit=10`, {
      params: {
        countryCode: countrySelected?.toUpperCase()
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "comma",
          encode: false,
          skipNulls: true
        });
      }
    })
    .then(res => {
      dispatch({ type: "SET_LOADING_DEALS_LIST", payload: false });

      dispatch({
        type: "FETCH_DEALS_LIST",
        payload: res?.data
      });
    })
    .catch(error => {
      dispatch({ type: "SET_LOADING_DEALS_LIST", payload: false });
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const uploadDealsToSubmit = deals => {
  return {
    type: "SET_DEALS_LIST_UPLOAD",
    payload: deals
  };
};

export const handleDeleteDeal = (
  id,
  pageNum,
  countrySelected
) => async dispatch => {
  dispatch(resetUploadDealsList());

  await dealsBaseurl
    .delete(`/store/deals/${id}`)
    .then(() => {
      dispatch(fetchDealsList({ pageNum, countrySelected }));
      dispatch({
        type: "DELETE_DEAL_SUCCESS",
        payload: "Deal is deleted successfully"
      });
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const submitUploadDeals = (deals, countrySelected) => async dispatch => {
  dispatch({ type: "UPLOAD_DEALS_LOADING", payload: true });
  await dealsBaseurl
    .post(`/store/deals`, [...deals])
    .then(res => {
      dispatch({ type: "UPLOAD_DEALS_LOADING", payload: false });
      dispatch({
        type: "UPLOAD_DEALS_SUCCESS",
        payload: "Deal is added successfully"
      });
      dispatch(fetchDealsList({ pageNum: 1, countrySelected }));
    })
    .catch(error => {
      dispatch({ type: "UPLOAD_DEALS_LOADING", payload: false });

      if (error?.response?.status === 401) {
        window.location.assign(authURL);
      }

      if (error?.response?.status === 400) {
        dispatch({
          type: "UPLOAD_DEALS_FAILED",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "UPLOAD_DEALS_FAILED",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const resetUploadDealsList = () => {
  return {
    type: "RESET_UPLOAD_DEALS_LIST"
  };
};

export const closeNotification = () => {
  return {
    type: "RESET_ERROR"
  };
};

export const setTarget = (id, name) => {
  return {
    type: "SET_TARGET",
    payload: {
      id,
      name
    }
  };
};
