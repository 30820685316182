import { ADD_POINTS_FOR_TESTING_BUCKET } from "../actionType";

export const addPointsForSingleUser = amount => async dispatch => {
  console.log("AddPoints", amount);

  // await adminBaseUrl.put(`/points/testing/add`, {
  //   amount
  // });

  dispatch({
    type: ADD_POINTS_FOR_TESTING_BUCKET
  });
};
