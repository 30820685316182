import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { combineReducers } from "redux";

import checkRequiredFieldsReducer from "../redux/reducers/checkRequiredFieldsReducer";
import uploadImageReducer from "../redux/reducers/uploadImageReducer";
import dateReducer from "../redux/reducers/dateReducer";
import uploadFileReducer from "../redux/reducers/uploadFileReducer";
import CashPointsReducer from "../redux/reducers/CashPointsReducer";

import AnnouncementCardsReducer from "../redux/reducers/AnnouncementCards/AnnouncementCardsReducer";
import ThemesReducer from "../redux/reducers/AnnouncementCards/ThemesReducer";
import AnnouncementListViewReducer from "../redux/reducers/AnnouncementCards/AnnouncementListViewReducer";

import LinksListViewReducer from "../redux/reducers/QuickLinks/LinksListViewReducer";
import QuickLinksReducer from "../redux/reducers/QuickLinks/QuickLinksReducer";
import CategoryTypesReducer from "../redux/reducers/QuickLinks/CategoryTypesReducer";

import BucketsListReducer from "../redux/reducers/PointsSystem/BucketsListReducer";
import PointsSystemReducer from "../redux/reducers/PointsSystem/PointsSystemReducer";
import AdsListViewReducer from "../redux/reducers/PointsSystem/AdsListViewReducer";
import AdsRecordReducer from "../redux/reducers/PointsSystem/AdsRecordReducer";
import ReasonsReducer from "../redux/reducers/PointsSystem/ReasonsReducer";
import TestingBucketListReducer from "../redux/reducers/PointsSystem/TestingBucketListReducer";
import MissionsReducer from "../redux/reducers/Missions/MissionsReducer";
import MissionsListViewReducer from "../redux/reducers/Missions/MissionsListViewReducer";
import AvailableErrorReducer from "../redux/reducers/AvailableErrorReducer";
import MissionCategoriesListReducer from "../redux/reducers/Missions/MissionCategoriesListReducer";
import UsersCompletedMissionsReducer from "../redux/reducers/Missions/UsersCompletedMissionsReducer";
import missionTasksReducer from "../redux/reducers/Missions/MissionTasksReducer";
import MissionPromoCodesReducer from "../redux/reducers/Missions/MissionPromoCodesReducer";
import ErrorMessageReducer from "../redux/reducers/ErrorMessageReducer";
import FetchConfigListReducer from "../redux/reducers/Configuration/PointsConfig/FetchConfigListReducer";
import configPointsUpdate from "../redux/reducers/Configuration/PointsConfig/configPoitnsUpdateReducer";
import FetchMissionsConfigReducer from "../redux/reducers/Configuration/MissionsConfig/FetchMissionsConfigReducer";
import configMissionUpdateReducer from "../redux/reducers/Configuration/MissionsConfig/configMissionsUpdateReducer";
import configSplashScreenReducer from "../redux/reducers/Configuration/SplashScreen/configSplashScreenReducer";
import PushNotificationsReducer from "../redux/reducers/PushNotifications/PushNotificationsReducer";
import UsersLogsReducer from "../redux/reducers/WaslaRewards/UsersLogsReducer";
import BalanceListReducer from "../redux/reducers/WaslaRewards/BalanceListReducer";
import RechargeUsersReducer from "../redux/reducers/WaslaRewards/RechargeUsersReducer";
import RolesReducers from "../redux/reducers/RolesReducers";
import EditUserRechargeReducer from "../redux/reducers/WaslaRewards/EditUsersRechargeReducer";
import UploadRechargeReducer from "../redux/reducers/WaslaRewards/uploadRechargeReducer";
import FiltersRewardReducer from "../redux/reducers/WaslaRewards/filterRewardReducer";
import ProjectReducer from "../redux/reducers/WaslaRewards/ProjectReducer";
import BatchesReducer from "../redux/reducers/WaslaRewards/BatchesReduecer";
import SnackBarReducer from "../redux/reducers/SnackBarReducer";
import UserTableReducer from "../redux/reducers/CsOtp/UserTableReducer";
import LogsTableReducer from "../redux/reducers/CsOtp/LogsTableReducer";
import dealsListReducer from "../components/Deals/Reducer/dealsListReducer";
import merchantReducer from "../components/Deals/Reducer/merchantReducer";
import targetReducer from "../components/Deals/Reducer/targetReducer";

import SectionsReducer from "../redux/reducers/Missions/SectionsReducer";
import CashbackConfigReducer from "../redux/reducers/Configuration/CashbackConfig/FetchCashback";
import CashbacksReducer from "../components/Cashbacks/Reducer/CashbackReducer";
import CountryReducer from "../components/CountryComponent/reducer";

import NotificationsReducer from "../widgets/notifications/reducers";

import MerchantsReducer from "../main/deals/merchants/reducers";
import DealsReducer from "../main/deals/deals/reducers";
import CategoriesReducer from "../main/deals/categories/reducers";

import ConversionsReducer from "../main/cashbacks/conversions/reducers";
import ConversionReducer from "../main/cashbacks/conversions/conversion/reducers";
import ConversionEditRequestReducer from "../main/cashbacks/conversions/edit-request/reducers";

const initReducers = {
  shouldSubmit: checkRequiredFieldsReducer,
  imageUrl: uploadImageReducer,
  dates: dateReducer,
  uploadFile: uploadFileReducer,
  announcementCards: AnnouncementCardsReducer,
  announcementCardsList: AnnouncementListViewReducer,
  themes: ThemesReducer,
  quickLinksList: LinksListViewReducer,
  quickLinks: QuickLinksReducer,
  quickLinksCategories: CategoryTypesReducer,
  pointsSystem: PointsSystemReducer,
  cashPointsBudget: CashPointsReducer,
  AdsListView: AdsListViewReducer,
  AdsRecord: AdsRecordReducer,
  reasons: ReasonsReducer,
  testingNumbersList: TestingBucketListReducer,
  missions: MissionsReducer,
  missionsCardsList: MissionsListViewReducer,
  BucketsList: BucketsListReducer,
  availableError: AvailableErrorReducer,
  missionsCategories: MissionCategoriesListReducer,
  errorMessage: ErrorMessageReducer,
  completedMissions: UsersCompletedMissionsReducer,
  configItems: FetchConfigListReducer,
  configItemsUpdate: configPointsUpdate,
  missionUsdEgp: FetchMissionsConfigReducer,
  configSplashScreens: configSplashScreenReducer,
  missionConfigUpdate: configMissionUpdateReducer,
  missionTasks: missionTasksReducer,
  missionPromoCodes: MissionPromoCodesReducer,
  pushNotifications: PushNotificationsReducer,
  waslaRewards: UsersLogsReducer,
  balanceList: BalanceListReducer,
  rechargeUsers: RechargeUsersReducer,
  roles: RolesReducers,
  usersRechargeToEdit: EditUserRechargeReducer,
  UploadUserRecharge: UploadRechargeReducer,
  filtersQuery: FiltersRewardReducer,
  project: ProjectReducer,
  batches: BatchesReducer,
  SnackBar: SnackBarReducer,
  userTable: UserTableReducer,
  logsTable: LogsTableReducer,
  dealsList: dealsListReducer,
  merchantsList: merchantReducer,
  targetList: targetReducer,
  SectionsMissions: SectionsReducer,
  cashbackConfig: CashbackConfigReducer,
  CashbacksList: CashbacksReducer,
  Countries: CountryReducer,
  notifications: NotificationsReducer,
  merchants: MerchantsReducer,
  deals: DealsReducer,
  categories: CategoriesReducer,
  conversions: ConversionsReducer,
  conversion: ConversionReducer,
  ConversionEditRequest: ConversionEditRequestReducer
};

const createReducer = asyncReducers => {
  return combineReducers({
    ...initReducers,
    ...asyncReducers
  });
};

const store = createStore(
  createReducer(),
  composeWithDevTools(applyMiddleware(thunk))
);

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key] || initReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

export default store;
