import { UPDATE_POINTS_CONFIG } from "../../actionType";
import { pointsBaseUrl } from "../../../../components/config";

export const UpdatePointsConfig = data => {
  return {
    type: UPDATE_POINTS_CONFIG,
    payload: {
      MinAdsReserveLimit: data.MinAdsReserveLimit,
      RewardValues: data.RewardValues
    }
  };
};

export const submitUpdatePointsConfig = data => async dispatch => {
  console.log("submitUpdatePointsConfig ==>", data);
  // await pointsBaseUrl.put(`/config`, data).then(()=>{
  //   dispatch({

  //   })
  // })
};
