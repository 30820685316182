import {
  SET_MISSIONS_DEFINITION,
  SET_MISSION_CATEGORY_TYPE,
  SET_SURVEY_COMPLETION_TIME,
  SET_IS_FEATURED,
  SET_MISSION_ORDER
} from "../actionType";

export const setMissionCardDefinition = data => {
  return {
    type: SET_MISSIONS_DEFINITION,
    payload: { ...data }
  };
};

export const setMissionCategory = (id, name) => {
  return {
    type: SET_MISSION_CATEGORY_TYPE,
    payload: { id, name }
  };
};
export const setMissionSection = (id, name) => {
  return {
    type: "SET_MISSION_SECTION_TYPE",
    payload: { id, name }
  };
};

export const setMissionType = (id, name) => {
  return {
    type: "SET_MISSION_TYPE",
    payload: { id, name }
  };
};

export const setMissionUrlPrams = urlParams => {
  return {
    type: "SET_MISSION_URL_PARAMS",
    payload: urlParams
  };
};

export const setMissionOrder = order => {
  return {
    type: SET_MISSION_ORDER,
    payload: order
  };
};

export const setMissionCompletionTime = time => {
  return {
    type: SET_SURVEY_COMPLETION_TIME,
    payload: time
  };
};

export const setMissionIsFeatured = () => {
  return {
    type: SET_IS_FEATURED
  };
};
