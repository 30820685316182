import Moment from "moment";

import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";

import FormCard from "../../../../components/FormCard";
import FormCardHead from "../../../../components/FormCardHead";
import FormCardBody from "../../../../components/FormCardBody";
import FormCardBodyContent from "../../../../components/FormCardBodyContent";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import Loader from "../../../../components/loader";

const CsActivity = ({ activity, loading }) => (
  <Box component="form" autoComplete="off">
    <Stack spacing={4}>
      <FormCard>
        <FormCardHead variant="underline">
          <Typography
            variant="p"
            component="p"
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "28px",
              color: "#101828"
            }}
          >
            History
          </Typography>
          <Typography
            variant="p"
            component="p"
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              color: "#667085"
            }}
          >
            User History
          </Typography>
        </FormCardHead>
        <FormCardBody>
          <FormCardBodyContent>
            {loading ? (
              <Loader />
            ) : (
              <Stack spacing={3}>
                <Grid container spacing={3}>
                  {activity.sourceType === "cs" && (
                    <>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="merchant"
                            sx={{ transform: "none" }}
                          >
                            Merchant
                          </InputLabel>
                          <OutlinedInput
                            id="merchant"
                            value={activity.titleEn}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="date"
                            sx={{ transform: "none" }}
                          >
                            Date
                          </InputLabel>
                          <OutlinedInput
                            id="date"
                            value={
                              activity.timestamp
                                ? Moment(new Date(activity.timestamp)).format(
                                    "MMMM DD, YYYY"
                                  )
                                : ""
                            }
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="status"
                            sx={{ transform: "none" }}
                          >
                            Status
                          </InputLabel>
                          <OutlinedInput
                            id="status"
                            value={activity.status}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="shopping-id"
                            sx={{ transform: "none" }}
                          >
                            Reason
                          </InputLabel>
                          <OutlinedInput
                            id="shopping-id"
                            value={activity?.additionalInfo?.reasonEn}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="cashback-{activity.currency}"
                            sx={{ transform: "none" }}
                          >
                            Value in {activity.currency}
                          </InputLabel>
                          <OutlinedInput
                            id="cashback-{activity.currency}"
                            value={`${activity.moneyAmount} ${activity.currency}`}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="cashback-points"
                            sx={{ transform: "none" }}
                          >
                            Value in PTS
                          </InputLabel>
                          <OutlinedInput
                            id="cashback-points"
                            value={`${activity.pointsAmount} Pts`}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  {activity.sourceType === "promocode" && (
                    <>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="merchant"
                            sx={{ transform: "none" }}
                          >
                            Gift card Merchant
                          </InputLabel>
                          <OutlinedInput
                            id="merchant"
                            value={activity.titleEn}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="cashback-{activity.currency}"
                            sx={{ transform: "none" }}
                          >
                            Gift Card amount
                          </InputLabel>
                          <OutlinedInput
                            id="cashback-{activity.currency}"
                            value={`${activity.moneyAmount} ${activity.currency}`}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="voucher"
                            sx={{ transform: "none" }}
                          >
                            Shopping Id
                          </InputLabel>
                          <OutlinedInput
                            id="voucher"
                            value={activity.activityId}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl error={false} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="status"
                            sx={{ transform: "none" }}
                          >
                            Status
                          </InputLabel>
                          <OutlinedInput
                            id="status"
                            value={activity.status}
                            label=""
                            placeholder=""
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
            )}
          </FormCardBodyContent>
        </FormCardBody>
      </FormCard>
    </Stack>
  </Box>
);

export default CsActivity;
