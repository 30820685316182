import authenticatedHttpClientService from "../authenticated-http-client";

export const getUserSummary = id =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_POINTS_BASE_URL}/admin/points/active?userId=${id}&page=1&limit=10`
    )
    .then(response => response.data);

export const getUserActivities = (id, page, limit) =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_POINTS_BASE_URL}/v2/admin/history?userId=${id}&page=${page}&limit=${limit}`
    )
    .then(response => response.data);

export const getUserActivity = (id, activityId) =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_POINTS_BASE_URL}/v2/admin/history/activity?userId=${id}&activityId=${activityId}`
    )
    .then(response => response.data);

export const getBuckets = () =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_POINTS_BASE_URL}/buckets`)
    .then(response => response.data);

export const getBonusBucket = () =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_POINTS_BASE_URL}/buckets/bonus`)
    .then(response => response.data);

export const getFraudBucket = () =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_POINTS_BASE_URL}/buckets/fraud`)
    .then(response => response.data);

export const getTransferPointsReasons = type =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_POINTS_BASE_URL}/points/reasons?type=${type}`)
    .then(response => response.data);

export const transferPoints = (ids, points, reason) =>
  authenticatedHttpClientService.put(
    `${process.env.REACT_APP_POINTS_BASE_URL}/points/bonus/transfer`,
    {
      userIds: ids,
      amount: points,
      reason
    }
  );

export const getCurrencyRates = () =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_POINTS_BASE_URL}/currency/rates`)
    .then(response => response.data);

export const getCurrencyRateHistory = (id, page, limit) =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_POINTS_BASE_URL}/currency/rates/history/${id}?page=${page}&limit=${limit}`
    )
    .then(response => response.data);

export const updateCurrencyRate = data =>
  authenticatedHttpClientService.patch(
    `${process.env.REACT_APP_POINTS_BASE_URL}/currency/rates`,
    data
  );
