import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import Button from "@material-ui/core/Button";

// redux actions
import {
  uploadUsersFile,
  uploadUsersPhonesFile
} from "../../../../redux/action/Missions/CompletedMissionActions";
// core components
import FileUpload from "../../../FileUpload";

const UploadMissionFile = props => {
  const {
    itemId,
    fileUpload,
    uploadUsersFile,
    uploadUsersPhonesFile,
    fileType
  } = props;

  const handleUpload = () => {
    // if (fileType === "Ids") {
    uploadUsersFile(itemId, fileUpload);
    // }
    // else {
    //   uploadUsersPhonesFile(itemId, fileUpload);
    // }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBlockStart: "16px",
        marginInlineStart: "-32px"
      }}
    >
      <FileUpload fileType="convertToText" />
      {fileUpload.length !== 0 && (
        <Button
          variant="contained"
          color="primary"
          style={{
            alignSelf: "center",
            marginBlockStart: "18px"
          }}
          onClick={handleUpload}
        >
          Submit CSV file
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    fileUpload: state.uploadFile.fileAsArray
  };
};

export default connect(
  mapStateToProps,
  { uploadUsersFile, uploadUsersPhonesFile }
)(UploadMissionFile);

UploadMissionFile.propTypes = {
  fileUpload: PropTypes.string,
  itemId: PropTypes.string,
  fileType: PropTypes.string,
  uploadUsersFile: PropTypes.func,
  uploadUsersPhonesFile: PropTypes.func
};
