import { UPDATE_ORDER_BY, UPDATE_ITEMS, DELETE_ITEM } from "../../actions/list";

const initialState = {
  orderBy: "order",
  items: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload.orderBy
      };
    case UPDATE_ITEMS:
      return {
        ...state,
        items: action.payload.items
      };
    case DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload.id)
      };
    default:
      return state;
  }
};
