import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// redux actions
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem.js";
import CardContainer from "../../CardContainer";
import DateComponent from "../../DateComponent";
import styles from "../styles.css";
import { content } from "./MissionsConfig";

const useStyles = makeStyles(styles);

const MissionExpirationLogic = ({ dates, checkRequiredFields }) => {
  const classes = useStyles();

  useEffect(() => {
    if (dates.startDate && dates.endDate) {
      checkRequiredFields(true);
    }
  }, [dates, checkRequiredFields]);

  return (
    <CardContainer title={`4. ${content.stepsTitle[3]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <Grid
            className={`${classes.container} ${classes.marginBlock}`}
            style={{ marginLeft: "8px" }}
          >
            <DateComponent disablePastDays={true} />
          </Grid>
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => ({
  numberOfImpressions: state.announcementCards.numberOfImpressions,
  dates: state.dates
});

export default connect(
  mapStateToProps,
  {
    checkRequiredFields
  }
)(MissionExpirationLogic);

MissionExpirationLogic.propTypes = {
  dates: PropTypes.object,
  setNumberOfImpressions: PropTypes.func,
  checkRequiredFields: PropTypes.func
};
