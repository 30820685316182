import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import withReducer from "../../store/withReducer";

import CurrencyNPointsConfigurationReducers from "../../main/currency-n-points-configuration/reducers";
import CurrencyNPointsConfigurationActions from "../../main/currency-n-points-configuration/actions";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WaslaTheme from "../../modules/wasla-theme";
import Loader from "../../components/loader";
import PageTitle from "../../components/page-title";
import SectionHead from "../../components/section-head";
import Pagination from "../../components/Pagination";

import configurations from "../../configurations";
import { currencyNPointsConfigurationPath } from "./paths";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const CurrencyNPointsConfigurationInput = connect(
  state => ({
    currencyNPointsConfiguration: state.currencyNPointsConfiguration
  }),
  {
    updateExchangeRate:
      CurrencyNPointsConfigurationActions.layout.exchangeRates.updateItem
  }
)(props => {
  const {
    currencyNPointsConfiguration,
    layoutExchangeRate,
    updateExchangeRate
  } = props;
  const stateExchangeRate = currencyNPointsConfiguration.exchangeRates.data.find(
    exchangeRate => exchangeRate.id === layoutExchangeRate.id
  );

  const handleExchangeRateChange = e => {
    updateExchangeRate(layoutExchangeRate.id, e.target.value);
  };

  return (
    <FormControl error={!layoutExchangeRate.exchangeRate}>
      <InputLabel
        shrink
        htmlFor={layoutExchangeRate.id}
        sx={{ transform: "none", color: "#344054" }}
      >
        {stateExchangeRate.fromCurrency.isoCode} to{" "}
        {stateExchangeRate.toCurrency.isoCode}
      </InputLabel>
      <OutlinedInput
        type="number"
        id={layoutExchangeRate.id}
        value={layoutExchangeRate.exchangeRate}
        onChange={handleExchangeRateChange}
        label=""
        sx={{ mt: 4 }}
      />
      {!layoutExchangeRate.exchangeRate && (
        <FormHelperText>
          {stateExchangeRate.fromCurrency.isoCode} to{" "}
          {stateExchangeRate.toCurrency.isoCode} is required
        </FormHelperText>
      )}
    </FormControl>
  );
});

const CurrencyNPointsConfiguration = props => {
  const {
    currencyNPointsConfiguration,
    getExchangeRates,
    updateExchangeRatesChangesStatus,
    updateExchangeRatesErrorsStatus,
    updateExchangeRates,
    resetExchangeRates,
    syncExchangeRates,
    getExchangeRatesHistory,
    initExchangeRatesHistoryPagination,
    changeExchangeRatesHistoryPage,
    syncExchangeRatesHistory
  } = props;

  const paginationPage =
    currencyNPointsConfiguration.layout.exchangeRatesHistory.pagination.page;
  const paginationSize =
    currencyNPointsConfiguration.layout.exchangeRatesHistory.pagination.size;
  const paginationPages = paginationSize
    ? Math.ceil(
        currencyNPointsConfiguration.exchangeRatesHistory.meta.total /
          paginationSize
      )
    : 0;
  const changes = currencyNPointsConfiguration.layout.exchangeRates.data.filter(
    layoutExchangeRate => {
      const stateExchangeRate = currencyNPointsConfiguration.exchangeRates.data.find(
        exchangeRate => exchangeRate.id === layoutExchangeRate.id
      );
      return layoutExchangeRate.exchangeRate != stateExchangeRate.exchangeRate;
    }
  );
  const exchangeRatesChangeStatus =
    currencyNPointsConfiguration.layout.exchangeRates.hasChanges;
  const exchangeRatesErrosStatus =
    currencyNPointsConfiguration.layout.exchangeRates.hasErrors;
  const exchangeRatesSyncing =
    currencyNPointsConfiguration.layout.exchangeRates.syncing;
  const exchangeRatesHistorySyncing =
    currencyNPointsConfiguration.layout.exchangeRatesHistory.syncing;

  const handleExchangeRatesSubmit = e => {
    syncExchangeRates(true);
    updateExchangeRates(changes)
      .then(data => {
        if (paginationPage === 1) {
          syncExchangeRatesHistory(true);
          getExchangeRatesHistory(paginationPage, paginationSize).then(data => {
            syncExchangeRatesHistory(false);
          });
        } else {
          changeExchangeRatesHistoryPage(1);
        }
        syncExchangeRates(false);
      })
      .catch(error => {
        syncExchangeRates(false);
      });
  };

  const handleExchangeRatesReset = e => {
    resetExchangeRates(currencyNPointsConfiguration.exchangeRates.data);
  };

  const handlePageClick = (e, page) => {
    changeExchangeRatesHistoryPage(page);
  };

  useEffect(() => {
    getExchangeRates();
    initExchangeRatesHistoryPagination(10);
  }, []);

  useEffect(() => {
    if (paginationPage && paginationSize) {
      syncExchangeRatesHistory(true);
      getExchangeRatesHistory(paginationPage, paginationSize).then(data => {
        syncExchangeRatesHistory(false);
      });
    }
  }, [paginationPage]);

  useEffect(() => {
    updateExchangeRatesChangesStatus(Boolean(changes.length));
    updateExchangeRatesErrorsStatus(
      Boolean(changes.filter(exchangeRate => !exchangeRate.exchangeRate).length)
    );
  }, [
    currencyNPointsConfiguration.layout.exchangeRates.data,
    currencyNPointsConfiguration.exchangeRates.data
  ]);

  return (
    <WaslaTheme>
      <Stack spacing={2}>
        <PageTitle title="Currency & Points Configuration" />
        <Box>
          <Tabs value={0} aria-label="basic tabs example" color="primary">
            <Tab
              component={Link}
              label="Redemption"
              {...a11yProps(1)}
              to={[
                configurations.dashboard.basePath,
                currencyNPointsConfigurationPath
              ].join("")}
              sx={{ textTransform: "capitalize" }}
            />
          </Tabs>
          <Divider />
        </Box>
        <SectionHead
          title="Redemptions"
          description="Manage all redemption currency conversion details"
        />
        <Divider />
        <Grid container justifyContent="center" rowSpacing={2}>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            {currencyNPointsConfiguration.layout.exchangeRates.loaded ? (
              <Card variant="outlined">
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                      display: "grid",
                      gap: 2
                    }}
                  >
                    {currencyNPointsConfiguration.layout.exchangeRates.data.map(
                      exchangeRate => (
                        <CurrencyNPointsConfigurationInput
                          key={exchangeRate.id}
                          layoutExchangeRate={exchangeRate}
                        />
                      )
                    )}
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    pb: 1,
                    justifyContent: "end"
                  }}
                >
                  <Stack spacing={2} p={1} direction="row">
                    <Button
                      variant="outlined"
                      disabled={
                        !exchangeRatesChangeStatus || exchangeRatesSyncing
                      }
                      onClick={handleExchangeRatesReset}
                      startIcon={
                        exchangeRatesSyncing && <CircularProgress size={24} />
                      }
                      sx={{ textTransform: "capitalize" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={
                        !exchangeRatesChangeStatus ||
                        exchangeRatesErrosStatus ||
                        exchangeRatesSyncing
                      }
                      onClick={handleExchangeRatesSubmit}
                      startIcon={
                        exchangeRatesSyncing && <CircularProgress size={24} />
                      }
                      sx={{ textTransform: "capitalize" }}
                    >
                      Save Changes
                    </Button>
                  </Stack>
                </CardActions>
              </Card>
            ) : (
              <Loader />
            )}
          </Grid>
        </Grid>
        <SectionHead
          title="History"
          description="View all changes to the currency conversion"
        />
        <Divider />
        {currencyNPointsConfiguration.layout.exchangeRates.loaded ? (
          <Card variant="outlined">
            <CardHeader title="All Requests" />
            <Divider />
            <CardContent sx={{ p: 0 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Executed by</TableCell>
                      <TableCell>Date</TableCell>
                      {currencyNPointsConfiguration.exchangeRates.data.map(
                        exchangeRate => (
                          <TableCell key={exchangeRate.id}>
                            {exchangeRate.fromCurrency.isoCode} to{" "}
                            {exchangeRate.toCurrency.isoCode}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exchangeRatesHistorySyncing ? (
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          colSpan={
                            currencyNPointsConfiguration.exchangeRates.data
                              .length + 2
                          }
                        >
                          <Loader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      currencyNPointsConfiguration.exchangeRatesHistory.data.map(
                        exchangeRateLog => {
                          const date = new Date(exchangeRateLog.date);
                          const changeIds = exchangeRateLog.changedRates.map(
                            changedRate => changedRate.id
                          );
                          return (
                            <TableRow key={exchangeRateLog.id}>
                              <TableCell component="th" scope="row">
                                {exchangeRateLog.executedBy}
                              </TableCell>
                              <TableCell>
                                {`${date.toLocaleString("default", {
                                  month: "short"
                                })} ${date.getDate()}, ${date.getFullYear()}`}
                              </TableCell>
                              {currencyNPointsConfiguration.exchangeRates.data.map(
                                exchangeRate => {
                                  if (
                                    changeIds.indexOf(exchangeRate.id) !== -1
                                  ) {
                                    return (
                                      <TableCell key={exchangeRate.id}>
                                        {
                                          exchangeRateLog.changedRates.find(
                                            changedRate =>
                                              changedRate.id === exchangeRate.id
                                          ).exchangeRate
                                        }
                                      </TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell key={exchangeRate.id}>
                                      -
                                    </TableCell>
                                  );
                                }
                              )}
                            </TableRow>
                          );
                        }
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions>
              <Pagination
                page={paginationPage}
                limit={paginationSize}
                total={
                  currencyNPointsConfiguration.exchangeRatesHistory.meta.total
                }
                rowsPerPageOptions={[]}
                handlePageClick={handlePageClick}
                sx={{ width: "100%" }}
              />
            </CardActions>
          </Card>
        ) : (
          <Loader />
        )}
      </Stack>
    </WaslaTheme>
  );
};

export default withReducer(
  "currencyNPointsConfiguration",
  CurrencyNPointsConfigurationReducers
)(
  connect(
    state => ({
      currencyNPointsConfiguration: state.currencyNPointsConfiguration
    }),
    {
      getExchangeRates: CurrencyNPointsConfigurationActions.exchangeRates.get,
      updateExchangeRates:
        CurrencyNPointsConfigurationActions.exchangeRates.update,
      updateExchangeRatesChangesStatus:
        CurrencyNPointsConfigurationActions.layout.exchangeRates.status.changes
          .update,
      updateExchangeRatesErrorsStatus:
        CurrencyNPointsConfigurationActions.layout.exchangeRates.status.errors
          .update,
      getExchangeRatesHistory:
        CurrencyNPointsConfigurationActions.exchangeRatesHistory.get,
      resetExchangeRates:
        CurrencyNPointsConfigurationActions.layout.exchangeRates.reset,
      syncExchangeRates:
        CurrencyNPointsConfigurationActions.layout.exchangeRates.sync,
      initExchangeRatesHistoryPagination:
        CurrencyNPointsConfigurationActions.layout.exchangeRatesHistory
          .pagination.init,
      changeExchangeRatesHistoryPage:
        CurrencyNPointsConfigurationActions.layout.exchangeRatesHistory
          .pagination.change,
      syncExchangeRatesHistory:
        CurrencyNPointsConfigurationActions.layout.exchangeRatesHistory.sync
    }
  )(CurrencyNPointsConfiguration)
);
