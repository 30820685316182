const styles = theme => ({
  containerType: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& h3": {
      fontSize: "16px",
      fontWeight: 500,
      marginTop: 0
    },
    "& .MuiBox-root": {
      padding: 0
    }
  },

  btnContainer: {
    margin: "15px 0px",
    "& button": {
      backgroundColor: "transparent",
      border: "1px solid #E8E8E8",
      boxShadow: "none"
    },
    "& button + button": {
      textTransform: "capitalize",
      color: "#000",
      backgroundColor: "#fc8f04",
      "&:hover": {
        backgroundColor: "#e98301",
        borderColor: "#E8E8E8"
      }
    },
    "& .MuiButton-contained.Mui-disabled": {
      backgroundColor: "transparent",
      border: "1px solid #E8E8E8",
      marginRight: "10px"
    }
  },

  btnContainerFinish: {
    padding: "15px 0px",
    borderTop: "1px solid #95989A",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& button": {
      border: "1px solid transparent",
      borderRadius: 5,
      backgroundColor: "#fc8f04"
    },
    "& .MuiButton-contained:disabled": {
      backgroundColor: "#EBEBEB"
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fc8f04",
      border: "1px solid transparent"
    }
  },
  containerField: {
    "& h3": {
      fontSize: "16px",
      fontWeight: 500,
      marginTop: 0,
      textAlign: "left"
    },
    "& .MuiBox-root": {
      padding: 0,
      border: "1px solid #E8E8E8",
      borderRadius: 5,
      margin: "23px 0",
      "& .MuiInputBase-root": {
        width: "100%",
        padding: "5px 10px",
        height: "38px",
        boxSizing: "border-box"
      }
    }
  },
  containerError: {
    padding: "80px 0",
    "& p": {
      margin: "5px auto"
    },
    "& p:last-child": {
      width: "65%"
    }
  },
  media: {
    height: 0,
    paddingTop: "22.25%", // 16:9
    backgroundSize: "24%"
  }
});
export default styles;
