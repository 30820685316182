import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
// core components
import CustomModal from "../../WaslaRewards/Modal";
import UploadFile from "../file";
import { resetFile } from "../../../redux/action/uploadFileActions";
import { Validation } from "./Validtion";

import {
  submitUploadMerchant,
  resetUploadMerchantList,
  uploadMerchantToSubmit
} from "../Actions/FetchMerchantList";

import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";

const uploadErrors = {
  title: "Please check the title on line(s): ",
  description: "Please check the description on line(s): ",
  logo: "Please check the logo on line(s): ",
  domain: "Please check the domain on line(s): ",
  tnc: "Please check the Terms & Conditions on line(s): ",
  countryCode: "Please check the Countries on line(s): ",
  isPopular: "Please check the (Is Popular) on line(s): ",
  order: "Please check the order on line(s): ",
  notMatched: "* Error with CSV, please check format."
};
function CreateProject(props) {
  const {
    open,
    handleClose,
    resetFile,
    title,
    isLoading,
    error,
    submitUploadMerchant,
    success,
    resetUploadMerchantList,
    merchantsUploadList,
    fileUpload,
    uploadMerchantToSubmit,
    checkRequiredFields
  } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [merchants, setMerchants] = useState([]);
  const factoryDeals = useCallback(() => {
    for (const [index, element] of fileUpload.entries()) {
      let merchant = {};
      let itemArray = element.trim().split(",");
      if (itemArray.length === 1) {
        setErrorMessage(`${uploadErrors.notMatched}`);
        setMerchants([]);
        return;
      }

      for (let i = 0; i < itemArray.length; ) {
        if (!itemArray[i]?.trim() || !itemArray[i + 1]?.trim()) {
          setErrorMessage(
            `${uploadErrors.title} ${index + 1}.... \n Name ${
              !itemArray[i + 1]?.trim() ? "(ar)" : "(en)"
            } field is blank`
          );
          return;
        }
        if (
          itemArray[i]?.trim().length > 30 ||
          itemArray[i + 1]?.trim().length > 30
        ) {
          setErrorMessage(
            `${uploadErrors.title} ${index + 1}.... \n Name ${
              itemArray[i + 1]?.trim().length > 30 ? "(ar)" : "(en)"
            } is more than 30 character`
          );
          return;
        }

        merchant.nameEn = itemArray[i].trim();
        merchant.nameAr = itemArray[i + 1].trim();
        i = i + 2;

        if (!itemArray[i]?.trim() || !itemArray[i + 1]?.trim()) {
          setErrorMessage(
            `${uploadErrors.description} ${index + 1}.... \n Description ${
              !itemArray[i + 1]?.trim() ? "(ar)" : "(en)"
            } field is blank`
          );
          return;
        }
        if (
          itemArray[i]?.trim().length > 50 ||
          itemArray[i + 1]?.trim().length > 50
        ) {
          setErrorMessage(
            `${uploadErrors.description} ${index + 1}.... \n Description ${
              itemArray[i + 1]?.trim().length > 50 ? "(ar)" : "(en)"
            } is more than 50-character`
          );
          return;
        }

        merchant.descriptionEn = itemArray[i].trim();
        merchant.descriptionAr = itemArray[i + 1].trim();
        i = i + 2;
        if (!itemArray[i]?.trim()) {
          setErrorMessage(
            `${uploadErrors.logo}  ${index + 1}.... \n Logo field is blank`
          );
          return;
        }
        if (Validation.urlValidation(itemArray[i]?.trim())) {
          setErrorMessage(
            `${uploadErrors.logo}  ${index +
              1}.... \n Please enter logo a http or https url`
          );
          return;
        }

        merchant.logo = itemArray[i].trim();
        i++;

        if (!itemArray[i]?.trim() || !itemArray[i + 1]?.trim()) {
          setErrorMessage(
            `${uploadErrors.tnc} ${index + 1}.... \n Terms & Conditions ${
              !itemArray[i + 1]?.trim() ? "(ar)" : "(en)"
            } fields are blank`
          );
          return;
        }

        if (
          itemArray[i]?.trim().length > 1000 ||
          itemArray[i + 1]?.trim().length > 1000
        ) {
          setErrorMessage(
            `${uploadErrors.tnc} ${index + 1}.... \n Terms & Conditions ${
              itemArray[i + 1]?.trim().length > 1000 ? "(ar)" : "(en)"
            } are more than 1000 character`
          );
          return;
        }

        merchant.tncEn = itemArray[i].trim();
        merchant.tncAr = itemArray[i + 1].trim();
        i = i + 2;

        if (!itemArray[i]?.trim().length) {
          setErrorMessage(
            `${uploadErrors.country}  ${index +
              1}.... \n Please enter Countries spareted by ,`
          );
          return;
        }

        merchant.countryCode = itemArray[i]
          .trim()
          .split("-")
          .map(countryCode => countryCode.trim())
          .join(", ");
        i++;

        if (Validation.urlValidation(itemArray[i]?.trim())) {
          setErrorMessage(
            `${uploadErrors.domain}  ${index +
              1}.... \n Please enter Domain a http or https url`
          );
          return;
        }

        merchant.url = itemArray[i].trim();
        i++;

        if (!itemArray[i]?.trim()) {
          setErrorMessage(
            `${uploadErrors.isPopular} ${index +
              1}.... \n IsPopular field is blank`
          );
          return;
        }

        if (
          !(itemArray[i]?.trim()?.toLowerCase() == "false") &&
          !(itemArray[i]?.trim()?.toLowerCase() == "true")
        ) {
          setErrorMessage(
            `${uploadErrors.isPopular} ${index +
              1}.... \n Is popular type is boolean value (True or false) `
          );
          return;
        }
        merchant.isPopular =
          itemArray[i]?.trim()?.toLowerCase() == "false" ? false : true;
        i++;

        if (!itemArray[i]?.trim()) {
          setErrorMessage(
            `${uploadErrors.order} ${index + 1}.... \n Order field is blank`
          );
          return;
        }

        if (!Number.isInteger(+itemArray[i]) || +itemArray[i] < 0) {
          setErrorMessage(
            `${uploadErrors.order} ${index +
              1}.... \n Order is integer and positive integer`
          );
          return;
        }

        merchant.order = +itemArray[i].trim();
        i++;
        break;
      }

      setErrorMessage("");
      setMerchants(initialState => [...initialState, merchant]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (fileUpload.length !== 0) {
      setMerchants([]);
      factoryDeals();
    }
  }, [fileUpload]);
  useEffect(() => {
    if (merchants.length !== 0 && !errorMessage) {
      uploadMerchantToSubmit(merchants);
      checkRequiredFields(true);
    } else {
      checkRequiredFields(false);
    }
  }, [merchants, errorMessage]);

  useEffect(() => {
    return () => {
      resetFile();
      setMerchants([]);
      setErrorMessage("");
      resetUploadMerchantList();
    };
  }, []);

  return (
    <CustomModal open={open} title={title} close={handleClose}>
      <UploadFile
        onCloseModal={handleClose}
        isLoading={isLoading}
        error={error}
        onSubmit={submitUploadMerchant}
        success={success}
        resetUpload={resetUploadMerchantList}
        list={merchantsUploadList}
        type="Merchant"
        uploadErrors={uploadErrors}
        errorMessage={errorMessage}
      />
    </CustomModal>
  );
}
const mapStateToProps = state => {
  return {
    isLoading: state.merchantsList.UploadLoading,
    error: state.merchantsList.error,
    success: state.merchantsList.success,
    merchantsUploadList: state.merchantsList.merchantsUploadList,
    fileUpload: state.uploadFile.fileAsArray
  };
};

export default connect(
  mapStateToProps,
  {
    resetFile,
    submitUploadMerchant,
    resetUploadMerchantList,
    uploadMerchantToSubmit,
    checkRequiredFields
  }
)(CreateProject);

CreateProject.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  resetFile: PropTypes.func
};
