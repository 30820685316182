import { waslaRewardBaseurl, authURL } from "../../../components/config";

import {
  FETCH_RECHARGE_VALUES,
  SELECT_RECHARGE_VALUE,
  RESET_RECHARGE_USERS_STATE,
  SUBMIT_USERS_RECHARGE,
  USERS_RECHARGE_SUCCESS,
  USERS_RECHARGE_FAILED,
  OPEN_SNACK_BAR
} from "../actionType";
import { fetchRewardApiList } from "./UsersLogsActions";
export const fetchRechargeValuesList = () => async dispatch => {
  await waslaRewardBaseurl
    .get(`/external/cashout/charge/options`)
    .then(res => {
      dispatch({
        type: FETCH_RECHARGE_VALUES,
        payload: res.data
      });
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_SERVER_RECHARGE",
          payload: "Authentication expired"
        });
      }
      dispatch({
        type: "SET_ERROR_SERVER_RECHARGE",
        payload: "Something went wrong"
      });
    });
};

export const selectRechargeValue = value => {
  return {
    type: SELECT_RECHARGE_VALUE,
    payload: value
  };
};

export const resetRechargeState = () => {
  return {
    type: RESET_RECHARGE_USERS_STATE
  };
};

export const submitUsersRecharge = (
  projectId,
  phoneObjects,
  type
) => async dispatch => {
  dispatch({ type: SUBMIT_USERS_RECHARGE });
  await waslaRewardBaseurl
    .post(`/external/project/${projectId}/batch`, { phoneObjects })
    .then(res => {
      dispatch({ type: USERS_RECHARGE_SUCCESS });
      if (type?.toLowerCase() !== "manual") {
        setTimeout(() => {
          dispatch(fetchRewardApiList({ projectId }));
        }, 5000);
      }
    })
    .catch(error => {
      dispatch({
        type: USERS_RECHARGE_FAILED,
        payload: error?.response?.data?.errors?.[0]?.error
      });
      dispatch({ type: OPEN_SNACK_BAR, payload: true });
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_SERVER_RECHARGE",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status !== 400) {
        dispatch({
          type: "SET_ERROR_SERVER_RECHARGE",
          payload:
            error?.response?.data?.error ||
            error?.response?.statusText ||
            "Something went wrong"
        });
      }
    });
};
