import { Redeem } from "@mui/icons-material";
import Redemption from "./Redemption";
import userRequestsConfig from "./user-requests/userRequestsConfig";
import catalogueConfig from "./catalogue/catalogueConfig";
import { redemptionPath } from "./paths";

export default {
  routes: [
    {
      path: redemptionPath,
      name: "Rewards",
      icon: Redeem,
      component: Redemption,
      layout: "main",
      isPrivate: null,
      roles: ["admin"],
      routes: [...userRequestsConfig.routes, ...catalogueConfig.routes]
    }
  ]
};
