import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RepeatIcon from "@mui/icons-material/Repeat";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Loader from "../../../../components/loader";
import SectionHead from "../../../../components/section-head";

import configurations from "../../../../configurations";
import { redemptionCataloguePath } from "../../paths";

import RedemptionActions from "../../../../main/redemption/actions";

import { initialState as optionInitialState } from "../../reducers/option";

import egypt from "./assets/egypt.png";
import nigeria from "./assets/nigeria.png";

const BasicDetails = connect(
  state => ({ redemption: state.redemption }),
  {
    updateOptionValue: RedemptionActions.layout.option.updateItem,
    updateOptionChangesStatus:
      RedemptionActions.layout.option.status.changes.update,
    updateOptionErrorsStatus:
      RedemptionActions.layout.option.status.errors.update
  }
)(props => {
  const {
    redemption,
    updateOptionValue,
    updateOptionChangesStatus,
    updateOptionErrorsStatus,
    operation,
    setImageFile
  } = props;

  const imageElement = useRef(null);

  const getFlag = countryCode => {
    switch (countryCode) {
      case "EG":
        return egypt;
      case "NG":
        return nigeria;
      default:
        return null;
    }
  };

  const handleTitleEnChange = e => {
    updateOptionValue("titleEn", e.target.value);
  };

  const handleTitleArChange = e => {
    updateOptionValue("titleAr", e.target.value);
  };

  const handleProviderChange = e => {
    updateOptionValue(
      "provider",
      redemption.configurations.providers.find(
        provider => provider.id === e.target.value
      )
    );
  };

  const handleInputsChange = e => {
    updateOptionValue(
      "requestInputs",
      redemption.configurations.inputs.filter(
        input => e.target.value.indexOf(input.id) !== -1
      )
    );
  };

  const handleTypeChange = e => {
    updateOptionValue("type", e.target.value);
  };

  const handleProductIdChange = e => {
    updateOptionValue("productId", e.target.value);
  };

  const handleCategoryChange = e => {
    updateOptionValue(
      "category",
      redemption.configurations.categories.find(
        category => category.id === e.target.value
      )
    );
  };

  const handleCurrencyChange = e => {
    updateOptionValue("currency", e.target.value);
  };

  const handleBackgroundColorChange = e => {
    if (!(e.target.value && !e.target.value.match(/^#[0-9a-f]{0,6}$/i)))
      updateOptionValue("imageBackground", e.target.value);
  };

  const handleImageUploadClick = e => {
    imageElement.current.click();
  };

  const handleImageUploadChange = e => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
      updateOptionValue("imageUrl", URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    updateOptionChangesStatus(
      !(
        JSON.stringify(redemption.layout.option.values) ===
        JSON.stringify(redemption.option)
      )
    );
    updateOptionErrorsStatus(
      !redemption.layout.option.values.titleEn ||
        !(
          (redemption.country === "EG" &&
            redemption.layout.option.values.titleAr) ||
          redemption.country !== "EG"
        ) ||
        !redemption.layout.option.values.provider.id ||
        !redemption.layout.option.values.requestInputs.length ||
        !redemption.layout.option.values.type ||
        !redemption.layout.option.values.category.id ||
        !redemption.country ||
        !redemption.layout.option.values.currency ||
        (!redemption.layout.option.values.imageBackground ||
          !redemption.layout.option.values.imageBackground.match(
            /^#([0-9a-f]{3}){1,2}$/i
          )) ||
        !redemption.layout.option.values.imageUrl ||
        !redemption.layout.option.values.denominations.length
    );
  }, [redemption.layout.option.values, redemption.option]);

  return (
    <>
      <SectionHead
        title="Brand Details"
        description="Update or create a redemption option"
      />
      <Divider />
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={2}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                display: "grid",
                gridTemplateColumns: { sm: "1fr 1fr" },
                gap: 2
              }}
            >
              <FormControl
                error={Boolean(
                  !redemption.layout.option.values.titleEn &&
                    redemption.option.titleEn
                )}
              >
                <InputLabel shrink htmlFor="name-en" sx={{ transform: "none" }}>
                  Redemption Name (EN)
                </InputLabel>
                <OutlinedInput
                  sx={{ mt: 4 }}
                  id="name-en"
                  value={redemption.layout.option.values.titleEn}
                  onChange={handleTitleEnChange}
                  label=""
                  inputProps={{ maxLength: 40 }}
                  disabled={operation === "update"}
                />
                {Boolean(
                  !redemption.layout.option.values.titleEn &&
                    redemption.option.titleEn
                ) && (
                  <FormHelperText>
                    Redemption Name (EN) is required
                  </FormHelperText>
                )}
              </FormControl>
              {redemption.country === "EG" && (
                <FormControl
                  error={Boolean(
                    !redemption.layout.option.values.titleAr &&
                      redemption.option.titleAr
                  )}
                >
                  <InputLabel
                    shrink
                    htmlFor="name-ar"
                    sx={{ transform: "none" }}
                  >
                    Redemption Name (AR)
                  </InputLabel>
                  <OutlinedInput
                    sx={{ mt: 4 }}
                    id="name-ar"
                    value={redemption.layout.option.values.titleAr}
                    onChange={handleTitleArChange}
                    label=""
                    inputProps={{ maxLength: 40 }}
                    disabled={operation === "update"}
                  />
                  {Boolean(
                    !redemption.layout.option.values.titleAr &&
                      redemption.option.titleAr
                  ) && (
                    <FormHelperText>
                      Redemption Name (AR) is required
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              <FormControl
                error={Boolean(
                  !redemption.layout.option.values.provider.id &&
                    redemption.option.provider.id
                )}
              >
                <InputLabel
                  shrink
                  htmlFor="provider"
                  sx={{ transform: "none" }}
                >
                  Provider
                </InputLabel>
                <Select
                  displayEmpty
                  value={redemption.layout.option.values.provider.id}
                  onChange={handleProviderChange}
                  sx={{ mt: 4 }}
                  id="provider-dropdown"
                  inputProps={{
                    id: "provider"
                  }}
                  MenuProps={{
                    id: "provider-menu"
                  }}
                  disabled={operation === "update"}
                >
                  <MenuItem value={""} sx={{ display: "none" }}>
                    <em>Select Provider</em>
                  </MenuItem>
                  {redemption.configurations.providers.map(provider => (
                    <MenuItem key={provider.id} value={provider.id}>
                      {provider.name}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(
                  !redemption.layout.option.values.provider.id &&
                    redemption.option.provider.id
                ) && <FormHelperText>Provider is required</FormHelperText>}
              </FormControl>
              <FormControl>
                <InputLabel shrink htmlFor="inputs" sx={{ transform: "none" }}>
                  User Input Requests
                </InputLabel>
                <Select
                  displayEmpty
                  value={
                    redemption.layout.option.values.requestInputs.length
                      ? redemption.layout.option.values.requestInputs.map(
                          input => input.id
                        )
                      : [""]
                  }
                  onChange={handleInputsChange}
                  multiple
                  sx={{ mt: 4 }}
                  id="inputs-dropdown"
                  inputProps={{
                    id: "inputs"
                  }}
                  MenuProps={{
                    id: "inputs-menu"
                  }}
                >
                  <MenuItem value={""} sx={{ display: "none" }}>
                    <em>Select Inputs</em>
                  </MenuItem>
                  {redemption.configurations.inputs.map(input => (
                    <MenuItem key={input.id} value={input.id}>
                      {input.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                error={Boolean(
                  !redemption.layout.option.values.type &&
                    redemption.option.type
                )}
              >
                <InputLabel shrink htmlFor="type" sx={{ transform: "none" }}>
                  Type
                </InputLabel>
                <Select
                  displayEmpty
                  value={redemption.layout.option.values.type}
                  onChange={handleTypeChange}
                  sx={{ mt: 4 }}
                  id="type-dropdown"
                  inputProps={{
                    id: "type"
                  }}
                  MenuProps={{
                    id: "type-menu"
                  }}
                  disabled={operation === "update"}
                >
                  <MenuItem value={""} sx={{ display: "none" }}>
                    <em>Select Type</em>
                  </MenuItem>
                  {redemption.configurations.types.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(
                  !redemption.layout.option.values.type &&
                    redemption.option.type
                ) && <FormHelperText>Type is required</FormHelperText>}
              </FormControl>
              <FormControl
                error={Boolean(
                  !redemption.layout.option.values.productId &&
                    redemption.option.productId
                )}
              >
                <InputLabel
                  shrink
                  htmlFor="product-id"
                  sx={{ transform: "none" }}
                >
                  Product ID
                </InputLabel>
                <OutlinedInput
                  sx={{ mt: 4 }}
                  id="product-id"
                  value={redemption.layout.option.values.productId}
                  onChange={handleProductIdChange}
                  label=""
                />
                {Boolean(
                  !redemption.layout.option.values.productId &&
                    redemption.option.productId
                ) && <FormHelperText>Product ID is required</FormHelperText>}
              </FormControl>
              <FormControl
                error={Boolean(
                  !redemption.layout.option.values.category.id &&
                    redemption.option.category.id
                )}
              >
                <InputLabel
                  shrink
                  htmlFor="category"
                  sx={{ transform: "none" }}
                >
                  Category
                </InputLabel>
                <Select
                  displayEmpty
                  value={redemption.layout.option.values.category.id}
                  onChange={handleCategoryChange}
                  sx={{ mt: 4 }}
                  id="category-dropdown"
                  inputProps={{
                    id: "category"
                  }}
                  MenuProps={{
                    id: "category-menu"
                  }}
                  disabled={operation === "update"}
                >
                  <MenuItem value={""} sx={{ display: "none" }}>
                    <em>Select Category</em>
                  </MenuItem>
                  {redemption.configurations.categories.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(
                  !redemption.layout.option.values.category.id &&
                    redemption.option.category.id
                ) && <FormHelperText>Category is required</FormHelperText>}
              </FormControl>
              <FormControl error={Boolean(!redemption.country)}>
                <InputLabel shrink htmlFor="country" sx={{ transform: "none" }}>
                  Country
                </InputLabel>
                <Select
                  displayEmpty
                  value={redemption.country}
                  sx={{ mt: 4 }}
                  id="country-dropdown"
                  inputProps={{
                    id: "country"
                  }}
                  MenuProps={{
                    id: "country-menu"
                  }}
                  disabled
                >
                  {configurations.application.countries.map(country => (
                    <MenuItem key={country.code} value={country.code}>
                      <Stack spacing={1} direction="row">
                        <img src={getFlag(country.code)} />
                        <span>{country.name}</span>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(!redemption.country) && (
                  <FormHelperText>Country is required</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={Boolean(
                  !redemption.layout.option.values.currency &&
                    redemption.option.currency
                )}
              >
                <InputLabel
                  shrink
                  htmlFor="currency"
                  sx={{ transform: "none" }}
                >
                  Currency
                </InputLabel>
                <Select
                  displayEmpty
                  value={redemption.layout.option.values.currency}
                  onChange={handleCurrencyChange}
                  sx={{ mt: 4 }}
                  id="currency-dropdown"
                  inputProps={{
                    id: "currency"
                  }}
                  MenuProps={{
                    id: "currency-menu"
                  }}
                  disabled={operation === "update"}
                >
                  <MenuItem value={""} sx={{ display: "none" }}>
                    <em>Select Currency</em>
                  </MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem
                    value={
                      configurations.application.countries.find(
                        country => country.code === redemption.country
                      ).currency
                    }
                  >
                    {
                      configurations.application.countries.find(
                        country => country.code === redemption.country
                      ).currency
                    }
                  </MenuItem>
                </Select>
                {Boolean(
                  !redemption.layout.option.values.currency &&
                    redemption.option.currency
                ) && <FormHelperText>Currency is required</FormHelperText>}
              </FormControl>
              <FormControl
                error={Boolean(
                  !redemption.layout.option.values.imageBackground &&
                    redemption.option.imageBackground
                )}
              >
                <InputLabel
                  shrink
                  htmlFor="background-color"
                  sx={{ transform: "none" }}
                >
                  Gift Color Background Color (Hex)
                </InputLabel>
                <OutlinedInput
                  sx={{ mt: 4 }}
                  id="background-color"
                  value={redemption.layout.option.values.imageBackground ?? ""}
                  onChange={handleBackgroundColorChange}
                  label=""
                  disabled={operation === "update"}
                />
                {Boolean(
                  !redemption.layout.option.values.imageBackground &&
                    redemption.option.imageBackground
                ) && (
                  <FormHelperText>
                    Gift Color Background Color (Hex) is required
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Stack spacing={2} direction="row" justifyContent="stretch">
              <Avatar
                alt={redemption.layout.option.values.titleEn}
                src={redemption.layout.option.values.imageUrl}
                sx={{ width: 64, height: 64, textTransform: "uppercase" }}
              >
                {redemption.layout.option.values.titleEn
                  .split(" ")
                  .map(word => word[0])
                  .join("")}
              </Avatar>
              <Button
                variant="outlined"
                sx={{ width: "100%" }}
                onClick={handleImageUploadClick}
                id="background-image-button"
                disabled={operation === "update"}
              >
                <div>
                  <CloudUploadIcon />
                  <br />
                  Click to upload
                  <br />
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </div>
                <input
                  ref={imageElement}
                  id="background-image"
                  onChange={handleImageUploadChange}
                  type="file"
                  style={{ display: "none" }}
                />
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
});

const DenominationsDetails = connect(
  state => ({ redemption: state.redemption }),
  {
    createOptionDenomination:
      RedemptionActions.layout.option.denominations.denomination.create,
    deleteOptionDenomination:
      RedemptionActions.layout.option.denominations.denomination.delete
  }
)(props => {
  const {
    redemption,
    operation,
    createOptionDenomination,
    deleteOptionDenomination
  } = props;

  const [moneyValue, setMoneyValue] = useState(0);
  const [localMoneyValue, setLocalMoneyValue] = useState(0);
  const [pointsValue, setPointsValue] = useState(0);

  const costConstraints =
    configurations.dashboard.redemption.option.denomination.constraints;

  const handleMoneyValueChange = e => {
    const expectedLocalMoneyValue =
      e.target.value * redemption.configurations.usdRate;
    if (
      !(
        expectedLocalMoneyValue &&
        (expectedLocalMoneyValue < costConstraints.min ||
          expectedLocalMoneyValue > costConstraints.max)
      )
    ) {
      setMoneyValue(e.target.value);
      setLocalMoneyValue(expectedLocalMoneyValue);
      setPointsValue(
        Math.floor(
          expectedLocalMoneyValue * redemption.configurations.pointsRate
        )
      );
    }
  };

  const handleLocalMoneyValueChange = e => {
    const expectedLocalMoneyValue = e.target.value;
    if (
      !(
        expectedLocalMoneyValue &&
        (expectedLocalMoneyValue < costConstraints.min ||
          expectedLocalMoneyValue > costConstraints.max)
      )
    ) {
      setMoneyValue(e.target.value / redemption.configurations.usdRate);
      setLocalMoneyValue(expectedLocalMoneyValue);
      setPointsValue(
        Math.floor(e.target.value * redemption.configurations.pointsRate)
      );
    }
  };

  const handleAddDenominationClick = e => {
    createOptionDenomination({ moneyValue, localMoneyValue, pointsValue });
    setMoneyValue(0);
    setLocalMoneyValue(0);
    setPointsValue(0);
  };

  const handleDeleteDenominationClick = index => e => {
    deleteOptionDenomination(index);
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent
          sx={{
            borderBottom: 1,
            borderColor: "divider"
          }}
        >
          <SectionHead
            title="New Denomination"
            description="Create a new denomination under this redemption option"
          />
        </CardContent>
        <CardContent>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="stretch"
            alignItems="center"
          >
            {redemption.layout.option.values.currency === "USD" && (
              <>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    htmlFor="money-value"
                    sx={{ transform: "none" }}
                  >
                    USD cost
                  </InputLabel>
                  <OutlinedInput
                    sx={{ mt: 4 }}
                    id="money-value"
                    type="number"
                    value={moneyValue}
                    onChange={handleMoneyValueChange}
                    label=""
                  />
                </FormControl>
                <RepeatIcon />
              </>
            )}
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                shrink
                htmlFor="local-money-value"
                sx={{ transform: "none" }}
              >
                {
                  configurations.application.countries.find(
                    country => country.code === redemption.country
                  ).currency
                }{" "}
                cost
              </InputLabel>
              <OutlinedInput
                sx={{ mt: 4 }}
                id="local-money-value"
                type="number"
                value={localMoneyValue}
                onChange={handleLocalMoneyValueChange}
                label=""
                inputProps={{ ...costConstraints }}
              />
            </FormControl>
            <ArrowForwardIcon />
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                shrink
                htmlFor="points-value"
                sx={{ transform: "none" }}
              >
                Points cost
              </InputLabel>
              <OutlinedInput
                sx={{ mt: 4 }}
                id="points-value"
                type="number"
                value={pointsValue}
                label=""
                disabled
              />
            </FormControl>
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            borderTop: 1,
            borderColor: "divider",
            justifyContent: "end"
          }}
        >
          <Stack spacing={2} p={1} direction="row">
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              startIcon={<AddIcon />}
              id="add-denomination"
              onClick={handleAddDenominationClick}
              disabled={!localMoneyValue}
            >
              Add Denomination
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <SectionHead
        title="Current Denominations"
        description="A list of all the denominations available under this gift card. These should map the provider list"
      />
      <Divider />
      <Card variant="outlined">
        <CardContent sx={{ p: 0 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Currency</TableCell>
                  <TableCell>
                    Local Cost (
                    {
                      configurations.application.countries.find(
                        country => country.code === redemption.country
                      ).currency
                    }
                    )
                  </TableCell>
                  <TableCell>Pts Cost</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {redemption.layout.option.values.denominations.map(
                  (denomination, denominationIndex) => {
                    return (
                      <TableRow key={denominationIndex}>
                        <TableCell component="td" scope="row">
                          {redemption.layout.option.values.currency}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {denomination.localMoneyValue}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {denomination.pointsValue}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          <Stack direction="row">
                            <IconButton
                              aria-label="delete"
                              onClick={handleDeleteDenominationClick(
                                denominationIndex
                              )}
                              id={`delete-denomination-${denominationIndex}`}
                            >
                              <DeleteForeverIcon sx={{ color: "#667085" }} />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
});

const RedemptionForm = props => {
  const {
    redemption,
    setOption,
    createOption,
    updateOption,
    updateOptionImage,
    resetOption,
    syncOption,
    operation
  } = props;

  const [imageFile, setImageFile] = useState(null);

  const history = useHistory();

  const optionChangeStatus = redemption.layout.option.hasChanges;
  const optionErrosStatus = redemption.layout.option.hasErrors;
  const optionSyncing = redemption.layout.option.syncing;

  const handleOptionCancel = e => {
    history.push(
      [configurations.dashboard.basePath, redemptionCataloguePath].join("")
    );
  };

  const handleOptionReset = e => {
    resetOption(redemption.option);
  };

  const handleOptionSubmit = e => {
    syncOption(true);
    if (operation === "create") {
      createOption({
        ...redemption.layout.option.values,
        country: redemption.country
      })
        .then(data => {
          updateOptionImage(data.id, {
            image: imageFile,
            color: redemption.layout.option.values.imageBackground
          })
            .then(data => {
              syncOption(false);
              history.push(
                [
                  configurations.dashboard.basePath,
                  redemptionCataloguePath
                ].join("")
              );
            })
            .catch(error => {
              syncOption(false);
              history.push(
                [
                  configurations.dashboard.basePath,
                  redemptionCataloguePath
                ].join("")
              );
            });
        })
        .catch(error => {
          syncOption(false);
        });
    }
    if (operation === "update") {
      const updatedOption = {};
      if (
        redemption.layout.option.values.productId !==
        redemption.option.productId
      ) {
        updatedOption["productId"] = redemption.layout.option.values.productId;
      }
      if (
        JSON.stringify(redemption.layout.option.values.denominations) !==
        JSON.stringify(redemption.option.denominations)
      ) {
        updatedOption[
          "denominations"
        ] = redemption.layout.option.values.denominations.map(denomination =>
          redemption.layout.option.values.currency === "USD"
            ? Number(denomination.moneyValue)
            : Number(denomination.localMoneyValue)
        );
      }
      if (
        JSON.stringify(redemption.layout.option.values.requestInputs) !==
        JSON.stringify(redemption.option.requestInputs)
      ) {
        updatedOption[
          "requestInputIds"
        ] = redemption.layout.option.values.requestInputs.map(
          input => input.id
        );
      }
      updateOption(redemption.layout.option.values.id, updatedOption)
        .then(data => {
          syncOption(false);
          history.push(
            [configurations.dashboard.basePath, redemptionCataloguePath].join(
              ""
            )
          );
        })
        .catch(error => {
          syncOption(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      setOption(optionInitialState);
    };
  }, []);

  return (
    <>
      {redemption.layout.option.loaded ? (
        <Stack spacing={2}>
          <BasicDetails operation={operation} setImageFile={setImageFile} />
          <DenominationsDetails operation={operation} />
          <Divider />
          <Stack spacing={2} p={1} direction="row" justifyContent="end">
            <Button
              variant="outlined"
              // disabled={!optionChangeStatus || optionSyncing}
              onClick={handleOptionCancel}
              id="cancel"
              startIcon={optionSyncing && <CircularProgress size={24} />}
              sx={{ textTransform: "capitalize" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={
                !optionChangeStatus || optionErrosStatus || optionSyncing
              }
              onClick={handleOptionSubmit}
              id="save"
              startIcon={optionSyncing && <CircularProgress size={24} />}
              sx={{ textTransform: "capitalize" }}
            >
              Save Changes
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default connect(
  state => ({ redemption: state.redemption }),
  {
    setOption: RedemptionActions.option.set,
    createOption: RedemptionActions.option.create,
    updateOption: RedemptionActions.option.update,
    updateOptionImage: RedemptionActions.option.image.update,
    resetOption: RedemptionActions.layout.option.reset,
    syncOption: RedemptionActions.layout.option.sync
  }
)(RedemptionForm);
