const styles = {
  cardCountryForm: {
    boxShadow:
      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    borderRadius: '8px !important',
    border: '0px !important',
  },
  nextBTN: {
    fontSize: '14px !important',
    lineHeight: "20px !important",
    textTransform: 'initial !important',
  }
};
export default styles;
