export const buttonTextEnglish = [
  {
    value: "Play Now",
    label: "Play Now"
  },
  {
    value: "Answer Now",
    label: "Answer Now"
  },
  {
    value: "Let’s Go",
    label: "Let’s Go"
  },
  {
    value: "Upgrade",
    label: "Upgrade"
  },
  {
    value: "Download",
    label: "Download"
  },
  {
    value: "Other",
    label: "Other"
  }
];

export const buttonTextArabic = [
  {
    value: "العب",
    label: "العب"
  },
  {
    value: "جاوب",
    label: "جاوب"
  },
  {
    value: "يلا نبدأ",
    label: "يلا نبدأ"
  },
  {
    value: "تحديث",
    label: "تحديث"
  },
  {
    value: "تحميل",
    label: "تحميل"
  },
  {
    value: "أخرى",
    label: "أخرى"
  }
];
