import {
  TOGGLE_ERROR_MESSAGE,
  TOGGLE_SUCCESS_MESSAGE
} from "../action/actionType";

const initialState = {
  error: false,
  success: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ERROR_MESSAGE:
      return {
        ...state,
        error: !state.error
      };

    case TOGGLE_SUCCESS_MESSAGE:
      return {
        ...state,
        success: !state.success
      };

    default:
      return state;
  }
};
