import { combineReducers } from "redux";

import search from "./search";
import list from "./list";
import pagination from "./pagination";

export default combineReducers({
  search,
  list,
  pagination
});
