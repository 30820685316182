import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
// core components
import CustomModal from "../../WaslaRewards/Modal";
import UploadFile from "../file";
import { resetFile } from "../../../redux/action/uploadFileActions";
import {
  submitUploadDeals,
  resetUploadDealsList,
  uploadDealsToSubmit
} from "../Actions/FetchDealsList";
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";
import { Validation } from "./Validtion";

const uploadErrors = {
  title: "Please check the title on line(s): ",
  description: "Please check the description on line(s): ",
  url: "Please check the url on line(s): ",
  category: "Please check the category  on line(s): ",
  dealType: "Please check the deal type on line(s): ",
  discountType: "Please check the discount type on line(s): ",
  discountValue: "Please check the Discount/Cash Back Value on line(s): ",
  dealActivationType: "Please check the deal activation type on line(s): ",
  usageMethodValue: "Please check the usage method type on line(s): ",
  tags: "Please check the tags on line(s): ",
  // domain: "Please check the domain on line(s): ",
  startDate: "Please check the start date on line(s): ",
  endDate: "Please check the end date on line(s): ",
  dealSource: "Please check the deal source on line(s):",
  comment: "Please check the comment on line(s):",
  notMatched: "* Error with CSV, please check format.",
  currency: "Please check the currency on line(s):"
};
function CreateProject(props) {
  const {
    open,
    handleClose,
    resetFile,
    title,
    isLoading,
    error,
    submitUploadDeals,
    success,
    resetUploadDealsList,
    dealsUploadList,
    uploadDealsToSubmit,
    checkRequiredFields,
    fileUpload,
    targetIdSelected,
    countrySelected
  } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [deals, setDeals] = useState([]);

  const factoryDeals = useCallback(() => {
    let removeHeadersFromFile = [];
    if (fileUpload) {
      removeHeadersFromFile = fileUpload?.slice(7);
    }

    for (const [index, element] of removeHeadersFromFile.entries()) {
      let deal = {};
      let itemArray = element?.trim()?.split(",");
      deal.countryCode = countrySelected?.toUpperCase();

      if (itemArray.length === 1) {
        setErrorMessage(`${uploadErrors.notMatched}`);
        setDeals([]);
        return;
      }

      for (let i = 0; i < itemArray.length; ) {
        if (!itemArray[i]?.trim() || !itemArray[i + 2]?.trim()) {
          setErrorMessage(
            `${uploadErrors.title} ${index + 1}.... \n Title ${
              !itemArray[i + 2]?.trim() ? "(ar)" : "(en)"
            } field is blank`
          );
          return;
        }

        if (
          itemArray[i]?.trim()?.length > 30 ||
          itemArray[i + 2]?.trim()?.length > 30
        ) {
          setErrorMessage(
            `${uploadErrors.title} ${index +
              1}.... \n 30-character limit on title ${
              itemArray[i + 2]?.trim()?.length > 30 ? "ar" : "en"
            } field exceeded`
          );
          return;
        }

        deal.titleEn = itemArray[i]?.trim();
        deal.titleAr = itemArray[i + 2]?.trim();
        i++;

        if (!itemArray[i]?.trim() || !itemArray[i + 2]?.trim()) {
          setErrorMessage(
            `${uploadErrors.description} ${index + 1}.... \n Description ${
              !itemArray[i + 2]?.trim() ? "(ar)" : "(en)"
            } field is blank`
          );
          return;
        }

        if (
          itemArray[i]?.trim()?.length > 50 ||
          itemArray[i + 2]?.trim()?.length > 50
        ) {
          setErrorMessage(
            `${uploadErrors.description} ${index +
              1}.... \n 50-character limit on description ${
              itemArray[i + 2]?.trim().length > 50 ? "ar" : "en"
            } field exceeded`
          );
          return;
        }
        deal.descriptionEn = itemArray[i]?.trim();
        deal.descriptionAr = itemArray[i + 2]?.trim();
        i = i + 3;

        // if (
        //   Validation.urlValidation(itemArray[i]?.trim()) ||
        //   !itemArray[i]?.trim()
        // ) {
        //   setErrorMessage(
        //     `${uploadErrors.url}  ${index +
        //       1}.... \n Please enter a http or https url`
        //   );
        //   return;
        // }

        // deal.imageUrl = itemArray[i].trim();
        // i++;

        // if (
        //   Validation.categoryValidation(itemArray[i]?.trim()) ||
        //   !itemArray[i]?.trim()
        // ) {
        //   setErrorMessage(
        //     `${uploadErrors.category}  ${index +
        //       1}.... \n category type should be entered: “Fashion,Health & Beauty,Supermarket,Mobiles & Tablets,Electronics,TVs,Home Accessories,Appliances,Toys,Moms & Babies,Sports,Automotive,Office,Gaming,Education,Travel”`
        //   );
        //   return;
        // }
        deal.category = itemArray[i]?.trim();
        i++;

        if (
          Validation.dealTypeValidation(itemArray[i]?.trim()) ||
          !itemArray[i]?.trim()
        ) {
          setErrorMessage(
            `${uploadErrors.dealType}  ${index +
              1}.... \n Deal type should be entered: “Discount, Cash Back”`
          );
          return;
        }

        if (itemArray[i]?.trim()?.toLowerCase() === "discount") {
          deal.type = 0;
        } else {
          deal.type = 1;
        }
        // deal.type = itemArray[i].trim();
        i++;

        // if (
        //   Validation.discountTypeValidation(itemArray[i]?.trim()) ||
        //   !itemArray[i]?.trim()
        // ) {
        //   setErrorMessage(
        //     `${uploadError.discountType}  ${index +
        //       1}.... \n Discount type should be entered: “fixed, percentage”`
        //   );
        //   return;
        // }

        deal.valueUnitEn = itemArray[i]?.trim();
        deal.valueUnitAr = itemArray[i]?.trim();
        i++;

        if (
          Validation.isNumber(itemArray[i]?.trim()) ||
          !itemArray[i]?.trim()
        ) {
          setErrorMessage(
            `${uploadErrors.discountValue}  ${index +
              1}.... \n Discount/Cash Back Value should be entered: “INT”`
          );
          return;
        }

        deal.value = +itemArray[i]?.trim();
        i++;

        if (
          Validation.dealActivationTypeValidation(itemArray[i]?.trim()) ||
          !itemArray[i]?.trim()
        ) {
          setErrorMessage(
            `${uploadErrors.dealActivationType}  ${index +
              1}.... \n Deal activation type should be entered: “promo code, affiliate url”`
          );
          return;
        }

        deal.usageMethod = itemArray[i]?.trim();
        let isPromo = deal.usageMethod === "promo code";
        let isUrl = deal.usageMethod === "affiliate url";
        deal.usageMethod = isPromo ? 1 : 0;
        i++;

        if (
          (isUrl && Validation.urlValidation(itemArray[i]?.trim())) ||
          (isPromo && !Validation.urlValidation(itemArray[i]?.trim())) ||
          !itemArray[i]?.trim()
        ) {
          setErrorMessage(
            `${uploadErrors.usageMethodValue}  ${index +
              1}.... \n Please enter a usage method matching the selected activation method`
          );
          return;
        }

        deal.usageMethodValue = itemArray[i]?.trim();
        i++;

        let factoryTags = Validation.tagsValidation(itemArray[i]?.trim());
        if (factoryTags.isValid) {
          setErrorMessage(
            `${uploadErrors.tags}  ${index +
              1}.... \n Please enter Tags no space between word`
          );
          return;
        }

        deal.tags = factoryTags?.items;
        i++;

        if (
          Validation.dateValidation(itemArray[i]?.trim()) ||
          !itemArray[i]?.trim()
        ) {
          setErrorMessage(
            `${uploadErrors.startDate}  ${index +
              1}.... \n Please enter a start date format: YYYY-MM-DD`
          );
          return;
        }

        deal.startDate = itemArray[i]?.trim();
        i++;

        if (
          Validation.dateValidation(itemArray[i]?.trim()) ||
          !itemArray[i]?.trim()
        ) {
          setErrorMessage(
            `${uploadErrors.endDate}  ${index +
              1}.... \n Please enter a end date format: YYYY-MM-DD`
          );
          return;
        }

        deal.endDate = itemArray[i]?.trim();
        i++;

        if (!itemArray[i]?.trim()) {
          setErrorMessage(
            `${uploadErrors.dealSource}  ${index +
              1}.... \n Deal source type should be entered`
          );
          return;
        }

        deal.provider = itemArray[i]?.trim();
        i++;

        if (
          deal.type &&
          Validation.CurrencyValidation(itemArray[i]?.trim()) &&
          !itemArray[i]?.trim()
        ) {
          setErrorMessage(
            `${uploadErrors.currency} ${index +
              1}.... \n Currency must equal: EGP, USD, AED, SAR, KWD, EUR, NGN`
          );
          return;
        }
        deal.currency = itemArray[i]?.trim()?.toUpperCase() ?? null;
        i++;

        if (itemArray[i]?.trim()?.length > 50) {
          setErrorMessage(
            `${uploadErrors.comment} ${index +
              1}.... \n 50-character limit on Comment field exceeded`
          );
          return;
        }
        deal.comment = itemArray[i]?.trim() ?? null;
        i++;

        break;
      }
      setErrorMessage("");
      setDeals(initialState => [...initialState, deal]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (fileUpload.length !== 0) {
      setDeals([]);
      factoryDeals();
    }
  }, [fileUpload]);

  useEffect(() => {
    if (deals.length !== 0 && !errorMessage && targetIdSelected) {
      uploadDealsToSubmit(deals);
      checkRequiredFields(true);
    } else {
      checkRequiredFields(false);
    }
  }, [deals, targetIdSelected, errorMessage]);

  useEffect(() => {
    return () => {
      resetFile();
      setDeals([]);
      setErrorMessage("");
      resetUploadDealsList();
    };
  }, []);

  return (
    <CustomModal open={open} title={title} close={handleClose}>
      <UploadFile
        onCloseModal={handleClose}
        isLoading={isLoading}
        error={error}
        onSubmit={submitUploadDeals}
        success={success}
        resetUpload={resetUploadDealsList}
        list={dealsUploadList}
        type="Deals"
        uploadErrors={uploadErrors}
        errorMessage={errorMessage}
        countrySelected={countrySelected}
      />
    </CustomModal>
  );
}
const mapStateToProps = state => ({
  isLoading: state.dealsList.UploadLoading,
  error: state.dealsList.error,
  success: state.dealsList.success,
  dealsUploadList: state.dealsList.dealsUploadList,
  fileUpload: state.uploadFile.fileAsArray,
  targetIdSelected: state.dealsList.targetIdSelected,
  countrySelected: state.Countries.countryNameSelected
});

export default connect(
  mapStateToProps,
  {
    resetFile,
    submitUploadDeals,
    resetUploadDealsList,
    uploadDealsToSubmit,
    checkRequiredFields
  }
)(CreateProject);

CreateProject.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  resetFile: PropTypes.func,
  uploadDealsToSubmit: PropTypes.func,
  checkRequiredFields: PropTypes.func,
  countrySelected: PropTypes.string
};
