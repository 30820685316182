import {
  SET_MISSION_CLAIM,
  EDIT_MISSION_CLAIM,
  MISSION_CLAIM_SUCCESS
} from "../actionType";

export const setMissionClaimTitles = data => {
  return {
    type: SET_MISSION_CLAIM,
    payload: data
  };
};

export const editMissionClaimTitles = data => {
  return {
    type: EDIT_MISSION_CLAIM,
    payload: data
  };
};

export const missionClaimSuccess = status => {
  return {
    type: MISSION_CLAIM_SUCCESS,
    payload: status
  };
};
