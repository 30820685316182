const customStyle = {
  modalContainer: {
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -48%)",
    textAlign: "center",
    position: "absolute",
    width: 500,
    backgroundColor: "#fff",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
    padding: "16px 32px 24px"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  containerContentSpaceBetween: {
    justifyContent: "space-between"
  },
  tableHeader: {
    display: "flex",
    paddingInlineStart: 0,
    listStyle: "none",
    justifyContent: "space-between",
    background: "#F8F9FA 0% 0% no-repeat padding-box",
    padding: "10px",
    marginBlock: "2px",
    "& li": {
      flexBasis: "24%",
      fontWeight: 500
    },
    "& li:last-child": {
      flexBasis: "15%"
    }
  },
  tableContent: {
    paddingInlineStart: 0,
    listStyle: "none",
    padding: "10px",
    marginBlock: "2px"
  },
  tableRow: {
    display: "flex",
    justifyContent: "space-between",
    "& > p": {
      flexBasis: "25%",
      fontWeight: 400,
      textAlign: "start",
      marginBottom: "3px"
    },
    "& p:last-child": {
      flexBasis: "14%",
      textAlign: "center"
    }
  },
  headerInfo: {
    marginTop: 0,
    marginBottom: 0,
    marginInlineStart: "5px",
    fontSize: "16px",
    fontWeight: 500
  },
  listInfo: {
    marginBottom: 0,
    paddingInlineStart: "26px",
    textAlign: "start",
    marginTop: "2px",
    "& li": {
      fontWeight: 400
    }
  }
};
export default customStyle;
