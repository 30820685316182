import { useState, useEffect } from "react";
import { generatePath } from "react-router-dom";
import { connect } from "react-redux";

import withReducer from "../../store/withReducer";

import WaslaTheme from "../../modules/wasla-theme";

import * as userProfileService from "../../services/user-profile";

import usersReducers from "../../main/users/reducers";

import { updateQuery } from "../../main/users/actions/search";
import { updateItems, updateItem } from "../../main/users/actions/list";
import {
  updatePage,
  updateLimit,
  updateTotal
} from "../../main/users/actions/pagination";

import configurations from "../../configurations";

import { userPath } from "../../main/users/user/paths";

import Stack from "@mui/material/Stack";

import Paper from "@mui/material/Paper";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CircularProgress from "@mui/material/CircularProgress";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";

import Loader from "../../components/loader";

import Pagination from "../../components/Pagination";

const UserRecord = props => {
  const { item, updateItem } = props;

  const [sync, setSync] = useState(false);

  const handleBlockToggleClick = (id, isBlocked) => async e => {
    setSync(true);
    await (isBlocked =>
      isBlocked
        ? userProfileService.unblockUser
        : userProfileService.blockUser)(isBlocked)(id).then(res => {
      updateItem(id, { ...item, isBlocked: !isBlocked });
    });
    setSync(false);
  };

  return (
    <TableRow
      sx={{
        ...(() =>
          item.isDeleted
            ? {
                backgroundColor: "rgba(217, 217, 217, 0.4)"
              }
            : {})()
      }}
    >
      <TableCell padding="checkbox">{item.phoneNumber}</TableCell>
      <TableCell padding="checkbox">{item.id}</TableCell>
      <TableCell padding="checkbox">{item.alias}</TableCell>
      <TableCell padding="checkbox">
        <Button
          variant="contained"
          startIcon={sync ? <CircularProgress size={24} /> : <BlockIcon />}
          sx={{
            borderColor: "#D92D20",
            padding: "8px 14px",
            backgroundColor: "#D92D20",
            textTransform: "capitalize",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#FCFCFD",
            "&:hover": {
              backgroundColor: "#D92D20"
            }
          }}
          onClick={handleBlockToggleClick(item.id, item.isBlocked)}
          disabled={sync || item.isDeleted}
        >
          {item.isBlocked ? "Unblock" : "Block"}
        </Button>
      </TableCell>
      <TableCell padding="checkbox">
        <Link
          underline="none"
          sx={{
            display: "flex",
            alignItems: "center",
            "&:hover": {
              color: "inherit"
            }
          }}
          color="inherit"
          href={[
            configurations.dashboard.basePath,
            generatePath(userPath, {
              id: item.id
            })
          ].join("")}
        >
          <Button variant="pagination" className="action" color="neutral">
            History
          </Button>
        </Link>
      </TableCell>
      <TableCell padding="checkbox">
        <Link
          underline="none"
          sx={{
            "&:hover": {
              color: "inherit"
            }
          }}
          color="inherit"
          href={item.deleteRequestUrl}
          target="_blank"
        >
          {item.deleteRequestUrl}
        </Link>
      </TableCell>
    </TableRow>
  );
};

const Users = props => {
  const {
    users: {
      search: { query },
      list: { items },
      pagination: { page, limit, total }
    },
    updateQuery,
    updateItems,
    updatePage,
    updateItem,
    updateLimit,
    updateTotal
  } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    updatePage(1);
  }, [query, limit]);

  useEffect(async () => {
    setLoading(true);
    const res = await userProfileService
      .getUsers(query.trim(), page, limit)
      .catch(error => ({ data: [], meta: { total: 0 } }));
    updateItems(res.data);
    updateTotal(res.meta.total);
    setLoading(false);
  }, [page, query, limit]);

  const handleSearchChange = e => {
    updateQuery(e.target.value);
  };

  const handlePageClick = (e, page) => {
    updatePage(page);
  };

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={configurations.dashboard.basePath}
          >
            CS Dashboard
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            CS Table
          </Typography>
        </Breadcrumbs>
        <Stack
          spacing={4}
          direction="row"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#F9FAFB",
            py: "12Px",
            px: 4,
            borderRadius: 3
          }}
        >
          <TextField
            label=""
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            sx={{ minWidth: "460px" }}
            value={query}
            placeholder="Search by User ID or Email"
            onChange={handleSearchChange}
          />
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="inherit">CS Dashboard</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.length ? (
                  <TableRow>
                    <TableCell component="th">User Number</TableCell>
                    <TableCell component="th">User ID</TableCell>
                    <TableCell component="th">User Alias</TableCell>
                    <TableCell component="th">Block</TableCell>
                    <TableCell component="th">History</TableCell>
                    <TableCell component="th">Deletion Link</TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
                {items.length ? (
                  items.map(item => (
                    <UserRecord
                      key={item.id}
                      item={item}
                      updateItem={updateItem}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Stack
                        sx={{ minHeight: "240px" }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "30px",
                            color: "#2B2B2B"
                          }}
                        >
                          “No Users Found”
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Pagination
                      page={page}
                      limit={limit}
                      total={total}
                      rowsPerPageOptions={[]}
                      handlePageClick={handlePageClick}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </WaslaTheme>
  );
};

export default withReducer("users", usersReducers)(
  connect(
    state => ({ users: state.users }),
    {
      updateQuery,
      updateItems,
      updateItem,
      updatePage,
      updateLimit,
      updateTotal
    }
  )(Users)
);
