import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
//redux
import {
  editMissions,
  submitMissionData,
  submitMissionsEdits
} from "../../../redux/action/Missions/CreateMissionActions";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { toggleErrorMessage } from "../../../redux/action/ErrorMessageActions";

import Stepper from "../../Stepper/refactorStepper";
import MissionDefinition from "./MissionDefinition";
import MissionBudget from "./MissionBudget";
import CallToActionComponent from "./CallToActionComponent";
import MissionTargeting from "./MissionTargeting";
import MissionExpirationLogic from "./MissionExpirationLogic";
import ErrorMessage from "../../ErrorMessage";
import { content } from "./MissionsConfig";
import styles from "../styles.css";

function Form({
  redirect,
  location,
  editMissions,
  errorMessage,
  missions,
  dates,
  imageUrl,
  fileUrl,
  iconUrl,
  submitMissionsEdits,
  submitMissionData,
  countrySelected,
  toggleErrorMessage
}) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const viewType = location.state ? location.state.viewType : "";
  const { pathname } = useLocation();

  useEffect(() => {
    if (viewType === "Edit") {
      editMissions(location.state.item);
    }
  }, [viewType]);

  const handleSubmit = () => {
    if (viewType === "Edit") {
      submitMissionsEdits(
        missions,
        dates,
        imageUrl,
        fileUrl,
        iconUrl,
        countrySelected
      );
    } else {
      submitMissionData(
        missions,
        dates,
        imageUrl,
        fileUrl,
        iconUrl,
        countrySelected
      );
    }
  };

  useEffect(() => {
    let timer1;
    if (errorMessage) {
      timer1 = setTimeout(() => toggleErrorMessage(), 2 * 1000);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [errorMessage]);

  const removeLastDirectoryPartOf = () => {
    let the_arr = pathname.split("/");

    if (viewType === "Edit") {
      the_arr.pop();
      the_arr.pop();
      return the_arr.join("/");
    }
    the_arr.pop();

    return the_arr.join("/");
  };

  return (
    <>
      <GridContainer justify="center">
        <Typography
          component="h1"
          variant="h4"
          color="textPrimary"
          className={classes.formHeader}
        >
          Add a new Mission
        </Typography>
        <Stepper
          stepsTitle={content.stepsTitle}
          formType="missions"
          viewType={viewType}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
        >
          <MissionDefinition viewType={viewType} />
          <MissionBudget />
          <CallToActionComponent viewType={viewType} />
          <MissionExpirationLogic />
          <MissionTargeting />
        </Stepper>
      </GridContainer>
      {errorMessage && <ErrorMessage message="Sorry something went wrong!" />}
      {redirect && (
        <Redirect from="/:url*(/+)" to={removeLastDirectoryPartOf()} />
      )}
    </>
  );
}

{
  /* <MissionTargeting /> */
}

const mapStateToProps = state => ({
  redirect: state.missions.redirect,
  errorMessage: state.errorMessage.error,
  missions: state.missions,
  dates: state.dates,
  imageUrl: state.imageUrl.url,
  iconUrl: state.imageUrl.iconUrlMission,
  fileUrl: state.uploadFile.fileAsArray,
  countrySelected: state.Countries.countryNameSelected
});

export default connect(
  mapStateToProps,
  { editMissions, submitMissionsEdits, submitMissionData, toggleErrorMessage }
)(Form);

Form.propTypes = {
  redirect: PropTypes.bool,
  location: PropTypes.object,
  editMissions: PropTypes.func,
  errorMessage: PropTypes.bool,
  toggleErrorMessage: PropTypes.func
};
