import { combineReducers } from "redux";

import search from "./search";
import filtration from "./filtration";
import layout from "./layout";
import list from "./list";
import pagination from "./pagination";

export default combineReducers({
  search,
  filtration,
  layout,
  list,
  pagination
});
