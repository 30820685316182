const nameValidation = input => {
  let name = input.trim();
  if (name.length > 16 || name.length === 0) {
    return true;
  }
};

const imageValidation = image => {
  if (!image) {
    return true;
  }
};

const orderValidation = order => {
  if (!order || order === "") {
    return true;
  }
};

const categoryValidation = category => {
  if (!category || category === "none") {
    return true;
  }
};
const urlValidation = url => {
  var expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

  var regex = new RegExp(expression);
  let result = url.match(regex);
  return !result;
};

export const formValidation = (formData, icon) => {
  let englishName = nameValidation(formData.englishName);
  let arabicName = nameValidation(formData.arabicName);
  let imageIcon = imageValidation(icon);
  let order = orderValidation(formData.order);
  let category = categoryValidation(formData.categoryId);
  let linkUrl = urlValidation(formData.url);
  if (!englishName && !arabicName && !imageIcon && !linkUrl && !category && !order) {
    return true;
  } else {
    return { arabicName, englishName, imageIcon, category, order, linkUrl };
  }
};
