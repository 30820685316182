import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import CardsListView from "./CardsListView";
import Form from "./Form";
import AnnouncementCardsDetails from "./AnnouncementCardsDetails";

export default function AnnouncementCards(props) {
  return (
    <>
      <Route exact path={props.match.path} component={CardsListView} />
      <Route path={`${props.match.path}/form`} component={Form} />
      <Route
        path={`${props.match.path}/details/:cardId`}
        component={AnnouncementCardsDetails}
      />
      <Route path={`${props.match.path}/edit/:_id`} component={Form} />
    </>
  );
}

AnnouncementCards.propTypes = {
  match: PropTypes.object
};
