import {
  SET_ALL_ITEMS_RECHARGE,
  USERS_RECHARGE_FAILED,
  FETCH_USERS_RECHARGE_FAILED,
  LOADING_USERS_RECHARGE_FAILED,
  SET_EDIT_ITEM_RECHARGE,
  SET_CURRENT_RECHARGES,
  SET_FINISH_ALL_EDIT,
  SET_FINIAL_TOTAL_EDIT_RECHARGE,
  RESET_EDIT_RECHARGE_STATE
} from "../../action/actionType";

const initialState = {
  totalUsersRechargeFailed: [],
  usersRechargeFailed: [],
  finialTotalEditRecharge: [],
  hasNext: false,
  isLoading: false,
  error: false,
  isFinish: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_USERS_RECHARGE_FAILED:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_USERS_RECHARGE_FAILED:
      return {
        ...state,
        usersRechargeFailed: action.payload.data,
        hasNext: action.payload.meta.has_next,
        isLoading: false
      };
    case SET_CURRENT_RECHARGES:
      return {
        ...state,
        usersRechargeFailed: action.payload
      };

    case SET_EDIT_ITEM_RECHARGE:
      return {
        ...state,
        usersRechargeFailed: action.payload
      };

    case SET_ALL_ITEMS_RECHARGE:
      let total = [action.payload, ...state.totalUsersRechargeFailed];
      let result = total.reduce((unique, o) => {
        if (!unique.some(obj => obj.pageNum === o.pageNum)) {
          unique.push(o);
        }
        return unique;
      }, []);
      return {
        ...state,
        totalUsersRechargeFailed: result
      };

    case USERS_RECHARGE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case SET_FINISH_ALL_EDIT:
      return {
        ...state,
        isFinish: action.payload
      };
    case SET_FINIAL_TOTAL_EDIT_RECHARGE:
      return {
        ...state,
        finialTotalEditRecharge: action.payload
      };
    case RESET_EDIT_RECHARGE_STATE:
      return {
        totalUsersRechargeFailed: [],
        usersRechargeFailed: [],
        finialTotalEditRecharge: [],
        hasNext: false,
        isLoading: false,
        error: false,
        isFinish: false
      };

    default:
      return state;
  }
};
