import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// redux actions
import { clearCashPointsState } from "../../../redux/action/CashPointsActions";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import styles from "../styles.css";
import BucketItem from "./bucketItem";
const useStyles = makeStyles(styles);

const PointSystemList = props => {
  const classes = useStyles();
  const { BucketsList, clearCashPointsState, availableError } = props;

  useEffect(() => {
    clearCashPointsState();
  }, []);
  if (availableError) {
    throw new Error();
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Points System</h4>
          </CardHeader>
        </Card>
      </GridItem>
      {BucketsList.map(bucket => {
        return (
          <GridItem xs={12} sm={12} md={6} key={bucket.type}>
            <BucketItem bucket={bucket} />
          </GridItem>
        );
      })}
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    BucketsList: state.BucketsList.bucketsItem,
    availableError: state.availableError.availableError
  };
};
export default connect(
  mapStateToProps,
  { clearCashPointsState }
)(PointSystemList);

PointSystemList.propTypes = {
  BucketsList: PropTypes.array,
  clearCashPointsState: PropTypes.func,
  availableError: PropTypes.bool
};
