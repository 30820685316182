// GENERAL_ACTIONS
export const CHECK_REQUIRED_FIELDS = "CHECK_REQUIRED_FIELDS";
export const SET_IMAGE_URL = "SET_IMAGE_URL";
export const SET_IMAGE_ICON_URL = "SET_IMAGE_ICON_URL";

export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_SPECIFIC_CSV_FILE = "SET_SPECIFIC_CSV_FILE";
export const SET_CSV_FILE_NAME = "SET_CSV_FILE_NAME";
export const SAVE_CSV_FILE_AS_ARRAY = "SAVE_CSV_FILE_AS_ARRAY";
export const RESET_FILE_STATE = "RESET_FILE_STATE";
export const RESET_STATE = "RESET_STATE";
export const AVAILABLE_ERROR = "AVAILABLE_ERROR";
export const TOGGLE_ERROR_MESSAGE = "TOGGLE_ERROR_MESSAGE";
export const TOGGLE_SUCCESS_MESSAGE = "TOGGLE_SUCCESS_MESSAGE";

// SET_PROMO_CODES_CAMPAIGN_ACTIONS
export const CALCULATE_POINTS_BUDGET = "CALCULATE_POINTS_BUDGET";
export const CALCULATE_CASH_BUDGET = "CALCULATE_CASH_BUDGET";
// SET_ANNOUNCEMENT_CARD_ACTIONS
export const SET_ANNOUNCEMENT_CARD_DEFINITION =
  "SET_ANNOUNCEMENT_CARD_DEFINITION";
export const SET_CALL_TO_ACTION = "SET_CALL_TO_ACTION";
export const CLEAR_CTA_VALUES = "CLEAR_CTA_VALUES";

export const SET_CTA_BUTTON_TYPE = "SET_CTA_BUTTON_TYPE";
export const SET_CTA_BUTTON_TEXT = "SET_CTA_BUTTON_TEXT";
export const SET_BUTTON_URL = "SET_BUTTON_URL";
export const SET_PWA_TYPE = "SET_PWA_TYPE";
export const SET_IN_APP_TYPE = "SET_IN_APP_TYPE";

export const SET_TARGET_TYPE = "SET_TARGET_TYPE";
export const SET_CARD_LOCATION = "SET_CARD_LOCATION";
export const SET_CARD_POSITION = "SET_CARD_POSITION";

export const GET_ANNOUNCEMENT_CARD_THEMES = "GET_ANNOUNCEMENT_CARD_THEMES";
export const SET_CARD_THEME = "SET_CARD_THEME";

export const SET_NUMBER_OF_IMPRESSIONS = "SET_NUMBER_OF_IMPRESSIONS";

export const SUBMIT_ANNOUNCEMENT_CARD_DATA = "SUBMIT_ANNOUNCEMENT_CARD_DATA";

export const FETCH_ANNOUNCEMENT_CARDS = "FETCH_ANNOUNCEMENT_CARDS";
export const EDIT_ANNOUNCEMENT_CARDS = "EDIT_ANNOUNCEMENT_CARDS";
export const EDIT_ANNOUNCEMENT_CARDS_DATA = "EDIT_ANNOUNCEMENT_CARDS_DATA";

// SET_QUICK_LINKS_ACTIONS
export const SET_QUICK_LINK_DEFINITION = "SET_QUICK_LINK_DEFINITION";
export const SET_IS_FEATURED = "SET_IS_FEATURED";
export const SET_IS_NATIVE = "SET_IS_NATIVE";

export const FETCH_CATEGORY_TYPES = "FETCH_CATEGORY_TYPES";

export const FETCH_QUICK_LINKS = "FETCH_QUICK_LINKS";
export const SUBMIT_QUICK_LINK_DATA = "SUBMIT_QUICK_LINK_DATA";
export const EDIT_QUICK_LINKS = "EDIT_QUICK_LINKS";
export const EDIT_QUICK_LINK_DATA = "EDIT_QUICK_LINK_DATA";
export const DELETE_QUICK_LINK = "DELETE_QUICK_LINK";

// SET_POINTS_SYSTEM_ACTIONS
export const GET_BUCKETS_LIST = "GET_BUCKETS_LIST";
export const FETCH_ADS_REWARDS = "FETCH_ADS_REWARDS";

export const SET_TYPE_OF_TRANSFER = "SET_TYPE_OF_TRANSFER";
export const CLEAR_TYPE_OF_TRANSFER = "CLEAR_TYPE_OF_TRANSFER";
export const SET_AMOUNT_OF_POINTS = "SET_AMOUNT_OF_POINTS";
export const SET_USER_MOBILE_NUMBER = "SET_USER_MOBILE_NUMBER";
export const FETCH_ADS_BUCKET = "FETCH_ADS_BUCKET";
export const CREATE_AND_UPDATE_ADS_RECORD = "CREATE_AND_UPDATE_ADS_RECORD";

export const FETCH_TESTING_NUMBERS_BUCKET = "FETCH_TESTING_NUMBERS_BUCKET";
export const DELETE_TESTING_NUMBER = "DELETE_TESTING_NUMBER";

export const ADD_BONUS_POINTS = "ADD_BONUS_POINTS";
export const BONUS_ADD_ERROR = "BONUS_ADD_ERROR";
export const REWARD_POINTS_FOR_SINGLE_USER = "REWARD_POINTS_FOR_SINGLE_USER";

export const ADD_POINTS_FOR_TESTING_BUCKET = "ADD_POINTS_FOR_TESTING_BUCKET";
export const TRANSFER_POINTS_FOR_SINGLE_NUMBER =
  "TRANSFER_POINTS_FOR_SINGLE_NUMBER";
export const TRANSFER_SUCCESS = "TRANSFER_SUCCESS";
export const TRANSFER_ERROR = "TRANSFER_ERROR";

export const FETCH_ALL_REASONS = "FETCH_ALL_REASONS";
export const ADD_SELECTED_REASON = "ADD_SELECTED_REASON";

// SET_MISSIONS_ACTIONS
export const SET_MISSIONS_DEFINITION = "SET_MISSIONS_DEFINITION";
export const SET_MISSION_CATEGORY_TYPE = "SET_MISSION_CATEGORY_TYPE";
export const SET_MISSION_MAX_USAGE = "SET_MISSION_MAX_USAGE";
export const SET_SURVEY_COMPLETION_TIME = "SET_SURVEY_COMPLETION_TIME";
export const SET_MISSION_REWARDED_POINTS = "SET_MISSION_REWARDED_POINTS";
export const SET_MIN_AND_MAX_REWARDED_POINTS =
  "SET_MIN_AND_MAX_REWARDED_POINTS";
export const SET_MISSION_ORDER = "SET_MISSION_ORDER";
export const SET_MISSION_TOTAL_POINTS = "SET_MISSION_TOTAL_POINTS";

export const SET_MISSIONS_CTA_BUTTON_TEXT = "SET_MISSIONS_CTA_BUTTON_TEXT";
export const SET_CTA_BUTTON_TEXT_SELECTION = "SET_CTA_BUTTON_TEXT_SELECTION";
export const SET_MISSIONS_BUTTON_URL = "SET_MISSIONS_BUTTON_URL";

export const SET_MISSION_TARGET_TYPE = "SET_MISSION_TARGET_TYPE";

export const SUBMIT_MISSION_DATA = "SUBMIT_MISSION_DATA";

export const FETCH_MISSIONS = "FETCH_MISSIONS";
export const EDIT_MISSIONS = "EDIT_MISSIONS";
export const EDIT_MISSIONS_DATA = "EDIT_MISSIONS_DATA";

export const FETCH_MISSIONS_CATEGORIES = "FETCH_MISSIONS_CATEGORIES";

export const UPLOAD_CSV_FILE_TYPE = "UPLOAD_CSV_FILE_TYPE";

export const SET_MISSION_TASKS = "SET_MISSION_TASKS";
export const SET_CTA_BUTTON_TEXT_SELECTION_TASKS =
  "SET_CTA_BUTTON_TEXT_SELECTION_TASKS";
export const SET_MISSIONS_CTA_BUTTON_TEXT_TASKS =
  "SET_MISSIONS_CTA_BUTTON_TEXT_TASKS";

export const SET_ALL_MISSION_TASKS = "SET_ALL_MISSION_TASKS";
export const EDIT_MISSION_TASKS = "EDIT_MISSION_TASKS";
export const CLEAR_MISSION_TASK = "CLEAR_MISSION_TASK";
export const DELETE_MISSION_TASKS = "DELETE_MISSION_TASKS";
export const SET_TASK_TYPE = "SET_TASK_TYPE";

export const SET_MISSION_CLAIM = "SET_MISSION_CLAIM";
export const SET_MISSIONS_CTA_BUTTON_TEXT_CLAIM =
  "SET_MISSIONS_CTA_BUTTON_TEXT_CLAIM";
export const EDIT_MISSION_CLAIM = "EDIT_MISSION_CLAIM";
export const SET_MISSION_DETAILS = "SET_MISSION_DETAILS";
export const FETCH_MISSIONS_PROMO_CODES = "FETCH_MISSIONS_PROMO_CODES";
export const SET_MISSIONS_PROMO_CODES = "SET_MISSIONS_PROMO_CODES";
export const MISSION_CLAIM_SUCCESS = "MISSION_CLAIM_SUCCESS";
export const SET_MISSION_MAX_USAGE_PER_USER = "SET_MISSION_MAX_USAGE_PER_USER";
// Points Config
export const FETCH_MISSIONS_CONFIG = "FETCH_MISSIONS_CONFIG";
export const FETCH_POINTS_CONFIG = "FETCH_POINTS_CONFIG";
export const ERROR_FETCH_POINTS_CONFIG = "ERROR_FETCH_POINTS_CONFIG";
export const ERROR_FETCH_MISSIONS_CONFIG = "ERROR_FETCH_MISSIONS_CONFIG";

export const UPDATE_POINTS_CONFIG = "UPDATE_POINTS_CONFIG";
export const UPDATE_Mission_CONFIG = "UPDATE_Mission_CONFIG";
export const UPDATE_Mission_CONFIG__SUSSES = "UPDATE_Mission_CONFIG__SUSSES";
export const UPDATE_Mission_CONFIG__Error = " UPDATE_Mission_CONFIG__Error";

// Splash screen Config
export const UPDATE_SPLASH_SCREEN = "UPDATE_SPLASH_SCREEN";
export const UPDATE_SPLASH_SCREEN_TYPE = "UPDATE_SPLASH_SCREEN_TYPE";
export const UPDATE_SPLASH_SCREEN_SUCCESS = "UPDATE_SPLASH_SCREEN_SUCCESS";

// SET_PUSH_NOTIFICATIONS
export const PUSH_NOTIFICATIONS_DEFINITION = "PUSH_NOTIFICATIONS_DEFINITION";
export const PUSH_NOTIFICATIONS_CTA = "PUSH_NOTIFICATIONS_CTA";
export const SUBMIT_PUSH_NOTIFICATIONS = "SUBMIT_PUSH_NOTIFICATIONS";
export const PUSH_NOTIFICATIONS_IMAGE_URL = "PUSH_NOTIFICATIONS_IMAGE_URL";

// WASLA_REWARDS_ACTIONS
export const FETCH_BALANCE_API_LIST = "FETCH_BALANCE_API_LIST";
export const RESET_REWARD_API_STATE = "RESET_REWARD_API_STATE";
export const FETCH_REWARD_API_LIST = "FETCH_REWARD_API_LIST";
export const FETCH_RECHARGE_VALUES = "FETCH_RECHARGE_VALUES";
export const SELECT_RECHARGE_VALUE = "SELECT_RECHARGE_VALUE";
export const SELECT_USERS_TO_RECHARGE = "SELECT_USERS_TO_RECHARGE";
export const RESET_RECHARGE_USERS_STATE = "RESET_RECHARGE_USERS_STATE";
export const SUBMIT_USERS_RECHARGE = "SUBMIT_USERS_RECHARGE";
export const USERS_RECHARGE_SUCCESS = "USERS_RECHARGE_SUCCESS";
export const USERS_RECHARGE_FAILED = "USERS_RECHARGE_FAILED";
export const UPLOAD_USERS_TO_RECHARGE = "UPLOAD_USERS_TO_RECHARGE";
export const UPLOAD_USERS_SUCCESS = "UPLOAD_USERS_SUCCESS";
export const UPLOAD_USERS_FAILED = "UPLOAD_USERS_FAILED";
export const UPLOAD_USERS_LOADING = "UPLOAD_USERS_LOADING";
export const OPEN_UPLOAD_MODAL = "OPEN_UPLOAD_MODAL";
export const SET_ALL_ITEMS_RECHARGE = "SET_ALL_ITEMS_RECHARGE";
export const FETCH_USERS_RECHARGE_FAILED = "FETCH_USERS_RECHARGE_FAILED";
export const LOADING_USERS_RECHARGE_FAILED = "LOADING_USERS_RECHARGE_FAILED";
export const SET_EDIT_ITEM_RECHARGE = "SET_EDIT_ITEM_RECHARGE";
export const SET_CURRENT_RECHARGES = "SET_CURRENT_RECHARGES";
export const RESET_UPLOAD_RECHARGE_STATE = "RESET_UPLOAD_RECHARGE_STATE";
export const SET_FINISH_ALL_EDIT = "SET_FINISH_ALL_EDIT";
export const SET_FINIAL_TOTAL_EDIT_RECHARGE = "SET_FINIAL_TOTAL_EDIT_RECHARGE";
export const RESET_EDIT_RECHARGE_STATE = "RESET_EDIT_RECHARGE_STATE";
export const SET_SEARCH_PHONE_NUMBER = "SET_SEARCH_PHONE_NUMBER";
export const SET_LOADING_REWARD_LIST = "SET_LOADING_REWARD_LIST";
export const SET_OPERATOR_FILTER_TYPE = "SET_OPERATOR_FILTER_TYPE";
export const SET_STATUS_FILTER_TYPE = "SET_STATUS_FILTER_TYPE";
export const ADD_SELECTED_VALUES = "ADD_SELECTED_VALUES";
export const REMOVE_SELECT_OPERATOR = "REMOVE_SELECT_OPERATOR";
export const ADD_START_DATE_FILTER = "ADD_START_DATE_FILTER";
export const ADD_END_DATE_FILTER = "ADD_END_DATE_FILTER";
export const REST_FILTER_VALUES = "REST_FILTER_VALUES";
export const SELECT_USERS_TO_Export = "SELECT_USERS_TO_Export";
export const SET_PROJECT_NAME = "SET_PROJECT_NAME";
export const FETCH_PROJECT_LIST = "FETCH_PROJECT_LIST";
export const SET_ERROR_CREATE_PROJECT = "SET_ERROR_CREATE_PROJECT";
export const SET_SUCCESS_CREATE_PROJECT = "SET_SUCCESS_CREATE_PROJECT";
export const SET_LOADING_PROJECT_LIST = "SET_LOADING_PROJECT_LIST";
export const REST_PROJECT_STATE = "REST_PROJECT_STATE";
export const FETCH_BATCHES_LIST = "FETCH_BATCHES_LIST";
export const SET_LOADING_BATCHES_LIST = "SET_LOADING_BATCHES_LIST";
export const RESET_BATCH_STATE = "RESET_BATCH_STATE";
export const ERROR_EXPORT = "ERROR_EXPORT";
export const RESET_ERROR_EXPORT = "RESET_ERROR_EXPORT";
// roles
export const FETCH_ROLES_OF_USERS = "FETCH_ROLES_OF_USERS";

//snackbar
export const OPEN_SNACK_BAR = "OPEN_SNACK_BAR";
export const CLOSE_SNACK_BAR = "CLOSE_SNACK_BAR";

// cs-otp actions
export const FETCH_CS_OTP_Table = "FETCH_CS_OTP_Table";
export const RESET_CS_OTP_STATE = "RESET_CS_OTP_STATE";
export const SET_LOADING_OTP_Table = "SET_LOADING_OTP_Table";
