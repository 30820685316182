import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";

import configurations from "../../../../configurations";
import {
  redemptionUserRequestsPath,
  redemptionCataloguePath
} from "../../paths";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const RedemptionNavigator = props => {
  const { value } = props;

  const getValueIndex = value => {
    switch (value) {
      case "user-requests":
        return 0;
      case "catalogue":
        return 1;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Tabs
        value={getValueIndex(value)}
        aria-label="basic tabs example"
        style={{ margin: 0 }}
      >
        <Tab
          component={Link}
          label="User Requests"
          {...a11yProps(0)}
          sx={{ textTransform: "capitalize" }}
          to={[
            configurations.dashboard.basePath,
            redemptionUserRequestsPath
          ].join("")}
        />
        <Tab
          component={Link}
          label="Catalogue"
          {...a11yProps(1)}
          sx={{ textTransform: "capitalize" }}
          to={[configurations.dashboard.basePath, redemptionCataloguePath].join(
            ""
          )}
        />
      </Tabs>
      <Divider />
    </Box>
  );
};

export default RedemptionNavigator;
