import { combineReducers } from "redux";

import search from "./search";
import pagination from "./pagination";
import list from "./list";

export default combineReducers({
  search,
  pagination,
  list
});
