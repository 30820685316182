import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "../../Snackbar/Snackbar";

// @material-ui/core components
import {
  Table,
  Paper,
  TableContainer,
  makeStyles,
  Typography,
  TableRow,
  TableBody,
  TableCell
} from "@material-ui/core";

// core components
import TableHeader from "../customTable/TableHeader";
import TableToolbar from "../customTable/TableToolbar";
import styles from "./styles.css";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles(styles);

function CustomTable(props) {
  const classes = useStyles();
  const refLimit = useRef(0);
  const refTotalPages = useRef(0);
  const {
    headCells,
    list,
    isLoading,
    error,
    closeNotification,
    children,
    setOrderBy,
    setOrder,
    orderBy,
    order,
    title,
    BtnType,
    setPageNum,
    pageNum,
    handleOpenModal,
    success,
    updateMessage,
    totalPages,
    limit,
    handleChangePagination,
    createSuccessMessage,
    deleteSuccessMessage
  } = props;

  useEffect(() => {
    if (!refLimit.current || !refTotalPages.current) {
      refLimit.current = limit;
      refTotalPages.current = totalPages;
    }
    return () => {
      refTotalPages.current = 0;
      refLimit.current = 0;
    };
  }, [totalPages, limit]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Paper className={classes.paper}>
      {list.length === 0 && (
        <>
          <TableToolbar
            pageNumber={pageNum}
            setPageNum={() => setPageNum(1)}
            title={title}
            BtnType={BtnType}
            handleOpenModal={handleOpenModal}
          />
          {isLoading && (
            <div
              style={{ textAlign: "center", marginTop: 15, marginBottom: 15 }}
            >
              <CircularProgress aria-label="is empty" />
            </div>
          )}

          {!isLoading && (
            <Typography
              component="p"
              variant="subtitle1"
              align="center"
              gutterBottom={true}
              style={{ width: "100%", padding: "1rem 0" }}
              aria-label="is empty"
            >
              There is no records of users yet.
            </Typography>
          )}
        </>
      )}
      {list.length !== 0 && (
        <>
          <TableToolbar
            pageNumber={pageNum}
            setPageNum={() => setPageNum(1)}
            title={title}
            BtnType={BtnType}
            handleOpenModal={handleOpenModal}
          />

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHeader
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={list.length}
                headCells={headCells}
              />
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        textAlign: "center",
                        marginTop: 15,
                        marginBottom: 15,
                        position: "sticky",
                        left: "40%"
                      }}
                    >
                      <CircularProgress aria-label="is empty" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {!isLoading && children}
            </Table>
          </TableContainer>
        </>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1em"
        }}
      >
        <Pagination
          count={Math.ceil(refTotalPages.current / refLimit.current)}
          size="large"
          onChange={handleChangePagination}
        />
      </div>

      {!!error && (
        <Snackbar
          message={error}
          open={!!error}
          color="danger"
          place="bc"
          close={!!error}
          closeNotification={closeNotification}
        />
      )}
      {success && (
        <Snackbar
          message={
            updateMessage ||
            createSuccessMessage ||
            deleteSuccessMessage ||
            "Process is completed"
          }
          open={success}
          color="success"
          place="bc"
          close={!success}
          closeNotification={closeNotification}
        />
      )}
    </Paper>
  );
}

export default CustomTable;

CustomTable.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  closeNotification: PropTypes.func,
  setOrderBy: PropTypes.func,
  setOrder: PropTypes.func,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  hasNext: PropTypes.bool,
  title: PropTypes.string,
  BtnType: PropTypes.string,
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func,
  pageNum: PropTypes.number,
  setPageNum: PropTypes.func,
  handleOpenModal: PropTypes.func,
  success: PropTypes.bool,
  updateMessage: PropTypes.string
};
