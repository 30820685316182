import React from "react";
import { Link, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// redux actions
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardBody from "../../../../components/Card/CardBody.js";

import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const AdsBucketList = props => {
  const { adsItems, location, match, availableError } = props;
  const classes = useStyles();

  if (availableError) {
    throw new Error();
  }
  const renderAdsList = () => {
    const items = location.state || adsItems || [];

    return items.map(item => {
      return (
        <GridItem xs={12} sm={12} md={6} key={item.id}>
          <Card>
            <CardBody className={classes.cardBody}>
              <Typography
                component="h2"
                variant="h5"
                color="textPrimary"
                style={{
                  textDecoration: "underline"
                }}
              >
                <Link
                  to={{
                    pathname: `${match.path}/${item.id}`,
                    state: item
                  }}
                >
                  {item.type}
                </Link>
              </Typography>
            </CardBody>
          </Card>
        </GridItem>
      );
    });
  };
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Ads Bucket System</h4>
            </CardHeader>
          </Card>
        </GridItem>
        {renderAdsList()}
      </GridContainer>
    </>
  );
};
const mapStateToProps = state => {
  return {
    adsItems: state.AdsListView.adsItems,
    availableError: state.availableError.availableError
  };
};
export default connect(mapStateToProps)(withRouter(AdsBucketList));

AdsBucketList.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  adsItems: PropTypes.array,
  availableError: PropTypes.bool
};
