import {
  FETCH_BATCHES_LIST,
  SET_LOADING_BATCHES_LIST,
  RESET_BATCH_STATE
} from "../../action/actionType";

const initialState = {
  list: [],
  isLoading: false,
  error: false,
  totalTransaction: 0,
  totalCompleted: 0,
  name: "",
  serverError: "",
  hasNext: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BATCHES_LIST:
      return {
        ...state,
        list: action.payload?.data,
        hasNext: action.payload?.meta?.has_next
      };

    case "PROGRESS_SUCCESS": {
      return {
        ...state,
        totalTransaction: action.payload.totalCount,
        totalCompleted: action.payload.completedCount
      };
    }
    case SET_LOADING_BATCHES_LIST: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case "SET_ERROR_SERVER_PROGRESS": {
      return {
        ...state,
        serverError: action.payload
      };
    }

    case RESET_BATCH_STATE: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};
