import { CREATE_AND_UPDATE_ADS_RECORD } from "../actionType";
import { pointsBaseUrl } from "../../../components/config";

export const createAndUpdateRecord = data => {
  return {
    type: CREATE_AND_UPDATE_ADS_RECORD,
    payload: { ...data }
  };
};

export const submitCreateRecord = (id, data) => async disPatch => {
  await pointsBaseUrl
    .post(`/ad/${id}/rewards`, {
      compensationPercentage: Number(data.compensationPercentage),
      estimatedValue: Number(data.estimatedValue)
    })
    .then(res => {
      disPatch({
        type: "submit"
      });
    });
};

export const submitUpdateRecord = (id, data) => async disPatch => {
  await pointsBaseUrl
    .put(`/ad/${id}/rewards`, {
      compensationPercentage: Number(data.compensationPercentage),
      actualValue: Number(data.actualValue)
    })
    .then(res => {
      disPatch({
        type: "submit"
      });
    });
};
