import {
  SET_MISSION_REWARDED_POINTS,
  SET_MISSION_MAX_USAGE,
  SET_MIN_AND_MAX_REWARDED_POINTS,
  SET_MISSION_TOTAL_POINTS,
  SET_MISSION_MAX_USAGE_PER_USER
} from "../actionType";

export const setMissionRewardedPoints = points => {
  return {
    type: SET_MISSION_REWARDED_POINTS,
    payload: points
  };
};

export const setMissionMaxUsage = numOfTimes => {
  return {
    type: SET_MISSION_MAX_USAGE,
    payload: numOfTimes
  };
};
export const setMissionMaxUsagePerUser = numOfTimes => {
  return {
    type: SET_MISSION_MAX_USAGE_PER_USER,
    payload: numOfTimes
  };
};

export const setMinAndMaxRewardedPoints = data => {
  return {
    type: SET_MIN_AND_MAX_REWARDED_POINTS,
    payload: { ...data }
  };
};

export const setMissionTotalPoints = totalPoints => {
  return {
    type: SET_MISSION_TOTAL_POINTS,
    payload: totalPoints
  };
};
