import Activity from "./Activity";
import { activityPath } from "./paths";

export default {
  routes: [
    {
      path: activityPath,
      component: Activity,
      layout: "main",
      isPrivate: false,
      roles: ["admin", "cs"],
      routes: []
    }
  ]
};
