import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
// redux actions
import {
  fetchThemes,
  setCardTheme
} from "../../../../redux/action/AnnouncementCards/CardStylingActions";
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
import ThemeComponent from "./ThemeComponent";
import styles from "../../styles.css";
import content from "../ContentConfig";

const useStyles = makeStyles(styles);

const CardStyling = ({
  cardTheme,
  themes,
  setCardTheme,
  fetchThemes,
  checkRequiredFields
}) => {
  const classes = useStyles();

  const handleRadioChange = event => {
    setCardTheme(event.target.value);
  };

  useEffect(() => {
    fetchThemes();
  }, []);

  useEffect(() => {
    if (cardTheme) {
      checkRequiredFields(true);
    }
  }, [cardTheme]);

  return (
    <CardContainer title={`3. ${content.stepsTitle[2]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <Grid className={`${classes.container} ${classes.marginBlock}`}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="card-theme"
                name="card-theme"
                style={{ flexDirection: "row" }}
                value={cardTheme.toString()}
                onChange={handleRadioChange}
              >
                {themes.map(item => (
                  <ThemeComponent
                    key={item._id || item.id}
                    value={item._id || item.id}
                    bgPrimaryColor={item.startBgColor}
                    bgSecondaryColor={item.endBgColor}
                    textColor={item.textColor}
                    btnBgColor={item.btnBgColor}
                    btnTextColor={item.btnTextColor}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};
const mapStateToProps = state => {
  return {
    cardTheme: state.announcementCards.cardTheme,
    themes: state.themes.themes
  };
};

export default connect(
  mapStateToProps,
  { setCardTheme, fetchThemes, checkRequiredFields }
)(CardStyling);

CardStyling.propTypes = {
  cardTheme: PropTypes.number,
  themes: PropTypes.arrayOf(PropTypes.object),
  setCardTheme: PropTypes.func,
  fetchThemes: PropTypes.func,
  checkRequiredFields: PropTypes.func
};
