import {
  SUBMIT_ANNOUNCEMENT_CARD_DATA,
  RESET_STATE,
  EDIT_ANNOUNCEMENT_CARDS,
  EDIT_ANNOUNCEMENT_CARDS_DATA
} from "../actionType";
import { announcementBaseUrl } from "../../../components/config";
import { toggleErrorMessage } from "../ErrorMessageActions";

export const submitAnnouncementCardData = (
  data,
  dates,
  imageUrl,
  fileUrl
) => async dispatch => {
  const response = await announcementBaseUrl
    .post(`/announcements`, {
      title: {
        en: data.englishTitle,
        ar: data.arabicTitle
      },
      body: {
        en: data.englishSubtitle,
        ar: data.arabicSubtitle
      },
      btn: {
        text: {
          en: data.btnEnglishText,
          ar: data.btnArabicText
        },
        action: {
          type: data.ctaBtnType,
          url: data.btnUrl || data.pwaType || data.inAppType
        }
      },
      imgUrl: imageUrl,
      fileUrl: fileUrl || null,
      themeId: data.cardTheme,
      targetType: data.targetType,
      location: data.cardLocation,
      position: data.cardPosition,
      numOfImpressions: data.numberOfImpressions,
      startDate: dates.startDate,
      endDate: dates.endDate
    })
    .then(() => {
      dispatch({
        type: SUBMIT_ANNOUNCEMENT_CARD_DATA
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });

  return response;
};

export const clearAnnouncementCardsState = () => {
  return {
    type: RESET_STATE
  };
};

export const editAnnouncementCards = data => {
  return {
    type: EDIT_ANNOUNCEMENT_CARDS,
    payload: data
  };
};

export const submitAnnouncementCardsEdits = (
  data,
  dates,
  imageUrl,
  fileUrl
) => async dispatch => {
  const body = {
    title: {
      en: data.englishTitle,
      ar: data.arabicTitle
    },
    body: {
      en: data.englishSubtitle,
      ar: data.arabicSubtitle
    },
    btn: {
      text: {
        en: data.btnEnglishText,
        ar: data.btnArabicText
      },
      action: {
        type: data.ctaBtnType,
        url: data.btnUrl || data.pwaType || data.inAppType
      }
    },
    imgUrl: imageUrl,
    fileUrl: fileUrl || null,
    themeId: data.cardTheme.toString(),
    targetType: data.targetType,
    location: data.cardLocation,
    position: data.cardPosition,
    numOfImpressions: data.numberOfImpressions,
    startDate: dates.startDate,
    endDate: dates.endDate
  };
  // Check if imageUrl is not base64
  if (!imageUrl) {
    delete body.imgUrl;
  }

  await announcementBaseUrl
    .patch(`/announcements/${data.cardId}`, body)
    .then(() => {
      dispatch({
        type: EDIT_ANNOUNCEMENT_CARDS_DATA
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};
