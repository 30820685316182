import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// core components
import RechargeSteps from "./RechargeSteps";
import CustomModal from "../Modal";

import { clearRewardApiState } from "../../../redux/action/WaslaRewards/UsersLogsActions";

function RechargeUsersModal(props) {
  const { clearRewardApiState } = props;
  useEffect(() => {
    return () => {
      clearRewardApiState();
    };
  }, []);

  return (
    <CustomModal
      open={props.open}
      title="Send Recharge"
      close={props.closeModal}
      style={{ width: 550, padding: "16px 22px 24px" }}
    >
      <RechargeSteps onCloseModal={props.closeModal} />
    </CustomModal>
  );
}

export default connect(
  null,
  {
    clearRewardApiState
  }
)(RechargeUsersModal);

RechargeUsersModal.propTypes = {
  clearRewardApiState: PropTypes.func
};
