import { UPLOAD_CSV_FILE_TYPE, RESET_STATE } from "../actionType";
import { missionsBaseUrl, dealsBaseurl } from "../../../components/config";
import {
  toggleErrorMessage,
  toggleSuccessMessage
} from "../ErrorMessageActions";

export const uploadUsersFile = (id, file) => async dispatch => {
  //apigw.waslabrowser.com/affiliate/admin/missions/bulkFinish
  await dealsBaseurl
    .post(`/admin/missions/bulkFinish`, { transactionsTokens: file })
    .then(() => {
      dispatch(toggleSuccessMessage());
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};

export const uploadUsersPhonesFile = (id, file) => async dispatch => {
  await missionsBaseUrl
    .post(`/missions/${id}/finishedPhoneNumbers`, { phoneNumbers: file })
    .then(() => {
      dispatch(toggleSuccessMessage());
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};

export const setUploadedFileType = type => {
  return {
    type: UPLOAD_CSV_FILE_TYPE,
    payload: type
  };
};

export const resetUploadedFileState = () => {
  return {
    type: RESET_STATE
  };
};
