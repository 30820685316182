import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setNumberOfImpressions } from "../../../redux/action/AnnouncementCards/CardExpirationActions";
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem.js";
import CardContainer from "../../CardContainer";
import DateComponent from "../../DateComponent";
import styles from "../styles.css";
import content from "./ContentConfig";

const useStyles = makeStyles(styles);

const CardExpirationLogic = ({
  numberOfImpressions,
  dates,
  setNumberOfImpressions,
  checkRequiredFields
}) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);

  const handleChange = event => {
    const fieldValue = event.target.value;
    if (fieldValue > 0 && fieldValue < 101) {
      setError(false);
      setNumberOfImpressions(event.target.value);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (numberOfImpressions && dates) {
      checkRequiredFields(true);
    }
  }, [numberOfImpressions, dates, checkRequiredFields]);

  return (
    <CardContainer title={`5. ${content.stepsTitle[4]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <Grid
            className={`${classes.container} ${classes.marginBlock}`}
            style={{ marginLeft: "8px" }}
          >
            <DateComponent />
          </Grid>

          <Grid className={`${classes.container} ${classes.marginBlock}`}>
            <TextField
              id="number-of-impressions"
              label="Number of impressions"
              type="number"
              className={classes.textField}
              style={{ width: "37.5%" }}
              value={numberOfImpressions}
              onChange={handleChange}
              error={error}
              helperText="Enter a value between 1 and 100"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: 1,
                max: 100
              }}
            />
          </Grid>
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => ({
  numberOfImpressions: state.announcementCards.numberOfImpressions,
  dates: state.dates
});

export default connect(
  mapStateToProps,
  {
    setNumberOfImpressions,
    checkRequiredFields
  }
)(CardExpirationLogic);

CardExpirationLogic.propTypes = {
  numberOfImpressions: PropTypes.number,
  dates: PropTypes.object,
  setNumberOfImpressions: PropTypes.func,
  checkRequiredFields: PropTypes.func
};
