import { updateCountry } from "./country";
import { getConfigurations } from "./configuartions";
import { getUserRequests } from "./user-requests";
import { updateUserRequestStatus } from "./user-request";
import {
  getOptions,
  deleteOption,
  updateOptionDenominationStatus
} from "./options";
import {
  setOption,
  createOption,
  updateOption,
  updateOptionImage
} from "./option";
import {
  initUserRequestsPagination,
  changeUserRequestsPage,
  updateUserRequestsQuery,
  updateUserRequestsDate,
  updateUserRequestsProviders,
  updateUserRequestsCategories,
  updateUserRequestsTypes,
  updateUserRequestsCurrencies,
  updateUserRequestsOrderBy,
  syncUserRequests,
  addUserRequestsExporting,
  removeUserRequestsExporting,
  emptyUserRequestsExporting,
  addUserRequestExporting,
  removeUserRequestExporting,
  initOptionsPagination,
  changeOptionsPage,
  updateQuery,
  updateProviders,
  updateCategories,
  updateTypes,
  syncOptions,
  updateOptionValue,
  createOptionDenomination,
  deleteOptionDenomination,
  updateOptionChangesStatus,
  updateOptionErrorsStatus,
  resetOption,
  syncOption
} from "./layout";

export default {
  country: {
    update: updateCountry
  },
  configurations: {
    get: getConfigurations
  },
  userRequests: {
    get: getUserRequests
  },
  userRequest: {
    status: {
      update: updateUserRequestStatus
    }
  },
  options: {
    get: getOptions,
    option: {
      delete: deleteOption,
      denominations: {
        denomination: {
          update: updateOptionDenominationStatus
        }
      }
    }
  },
  option: {
    set: setOption,
    create: createOption,
    update: updateOption,
    image: {
      update: updateOptionImage
    }
  },
  layout: {
    userRequests: {
      pagination: {
        init: initUserRequestsPagination,
        change: changeUserRequestsPage
      },
      query: {
        update: updateUserRequestsQuery
      },
      date: {
        update: updateUserRequestsDate
      },
      providers: {
        update: updateUserRequestsProviders
      },
      categories: {
        update: updateUserRequestsCategories
      },
      types: {
        update: updateUserRequestsTypes
      },
      currencies: {
        update: updateUserRequestsCurrencies
      },
      orderBy: {
        update: updateUserRequestsOrderBy
      },
      sync: syncUserRequests,
      exporting: {
        add: addUserRequestsExporting,
        remove: removeUserRequestsExporting,
        empty: emptyUserRequestsExporting
      },
      userRequest: {
        exporting: {
          add: addUserRequestExporting,
          remove: removeUserRequestExporting
        }
      }
    },
    options: {
      pagination: {
        init: initOptionsPagination,
        change: changeOptionsPage
      },
      query: {
        update: updateQuery
      },
      providers: {
        update: updateProviders
      },
      categories: {
        update: updateCategories
      },
      types: {
        update: updateTypes
      },
      sync: syncOptions
    },
    option: {
      updateItem: updateOptionValue,
      status: {
        changes: {
          update: updateOptionChangesStatus
        },
        errors: {
          update: updateOptionErrorsStatus
        }
      },
      denominations: {
        denomination: {
          create: createOptionDenomination,
          delete: deleteOptionDenomination
        }
      },
      reset: resetOption,
      sync: syncOption
    }
  }
};
