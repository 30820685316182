import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "transparent"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "600px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& .MuiFormControl-root": {
      width: "48% !important",
      marginBlockEnd: "15px !important",
      marginBlockStart: 0
    }
  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const {
    item,
    handleCloseEdit,
    open,
    updateFieldChanged,
    validatedFiled,
    errorMessage,
    itemIndex,
    handleSaveEdit,
    handleRestEdit,
    setErrorMessage
  } = props;
  useEffect(() => {
    if (item) validatedFiled("countryCode", item?.countryCode);
    return () => {
      setErrorMessage({
        nameEn: "",
        nameAr: "",
        id: "",
        descriptionEn: "",
        descriptionAr: "",
        logo: "",
        tncEn: "",
        tncAr: "",
        countryCode: "",
        url: "",
        order: ""
      });
    };
  }, []);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TextField
              id="nameEn"
              name="nameEn"
              label="Name (En)"
              type="text"
              value={item?.nameEn}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.nameEn}
              helperText={errorMessage?.nameEn}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="nameAr"
              name="nameAr"
              label="Name (Ar)"
              type="text"
              value={item?.nameAr}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.nameAr}
              helperText={errorMessage?.nameAr}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="descriptionEn"
              name="descriptionEn"
              type="text"
              label="Description (En)"
              value={item?.descriptionEn}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.descriptionEn}
              helperText={errorMessage?.descriptionEn}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="descriptionAr"
              name="descriptionAr"
              label="Description (Ar)"
              type="text"
              value={item?.descriptionAr}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.descriptionAr}
              helperText={errorMessage?.descriptionAr}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="logo"
              name="logo"
              type="text"
              value={item?.logo}
              label="Logo"
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.logo}
              helperText={errorMessage?.logo}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <FormControl error={!!errorMessage?.countryCode}>
              <InputLabel shrink required id="country-code-label">
                Countries
              </InputLabel>
              <Select
                displayEmpty
                value={
                  item?.countryCode
                    ?.split(",")
                    .map(countryCode => countryCode.trim().toUpperCase()) || [
                    ""
                  ]
                }
                onChange={updateFieldChanged(itemIndex)}
                multiple
                label="Countries"
                labelId="country-code-label"
                inputProps={{
                  id: "countryCode",
                  name: "countryCode"
                }}
              >
                <MenuItem value={""} sx={{ display: "none" }}>
                  <em>Select Countries</em>
                </MenuItem>
                <MenuItem value="EG">EG</MenuItem>
                <MenuItem value="NG">NG</MenuItem>
              </Select>
              {!!errorMessage?.countryCode && (
                <FormHelperText>{errorMessage?.countryCode}</FormHelperText>
              )}
            </FormControl>
            <TextField
              id="url"
              name="url"
              type="text"
              value={item?.url}
              label="Url"
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.url}
              helperText={errorMessage?.url}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="order"
              name="order"
              type="text"
              value={item?.order}
              label="order"
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.order}
              helperText={errorMessage?.order}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="tncEn"
              name="tncEn"
              type="text"
              value={item?.tncEn}
              label="TNC (En)"
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.tncEn}
              helperText={errorMessage?.tncEn}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="tncAr"
              name="tncAr"
              type="text"
              value={item?.tncAr}
              margin="normal"
              label="TNC (Ar)"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.tncAr}
              helperText={errorMessage?.tncAr}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <FormControlLabel
              className={classes.checkboxField}
              control={
                <Checkbox
                  name="isPopular"
                  onChange={updateFieldChanged(itemIndex)}
                  color="primary"
                  checked={!!item?.isPopular}
                />
              }
              label="isPopular"
            />
            <div
              style={{
                flexBasis: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button onClick={() => handleRestEdit(itemIndex)}>Cancel</Button>
              <Button
                onClick={() => handleSaveEdit(itemIndex)}
                disabled={Object.values(errorMessage).some(val => val)}
              >
                Save
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
