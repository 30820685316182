const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "46px",
    left: "0",
    right: "0",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "#fff",
    textAlign: "center",
    zIndex: 2
  },
  iconButton: {
    position: "absolute",
    right: "12px",
    top: "10px"
  }
});
export default styles;
