const initialState = {
  list: [
    {
      id: "1",
      country: "eg"
    },
    {
      id: "2",
      country: "ng"
    }
  ],
  countryIdSelected: "1",
  countryNameSelected: "eg"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_COUNTRY": {
      return {
        ...state,
        countryIdSelected: action.payload?.id,
        countryNameSelected: action.payload?.name
      };
    }

    case "REST_COUNTRY_STATE": {
      return initialState;
    }
    default:
      return state;
  }
};
