export const UPDATE_NAME_EN = "update local deals merchant en name";
export const UPDATE_NAME_AR = "update local deals merchant ar name";
export const UPDATE_LOGO_URL = "update local deals merchant logo url";
export const UPDATE_STATUS = "update local deals category status";
export const GET_DEALS_COUNTS = "get local deals counts in this category";
export const SHOW_ERROR = "get local response error in this category";


export const updateNameEn = nameEn => dispatch =>
  dispatch({
    type: UPDATE_NAME_EN,
    payload: { nameEn }
  });

export const updateNameAr = nameAr => dispatch =>
  dispatch({
    type: UPDATE_NAME_AR,
    payload: { nameAr }
  });

export const updateLogoUrl = logoUrl => dispatch =>
  dispatch({
    type: UPDATE_LOGO_URL,
    payload: { logoUrl }
  });

export const updateStatus = status => dispatch =>
  dispatch({
    type: UPDATE_STATUS,
    payload: { status }
  });

export const getDealsCounts = dealsCount => dispatch =>
  dispatch({
    type: GET_DEALS_COUNTS,
    payload: { dealsCount }
  });  

  export const getErrorMessage = error => dispatch =>
  dispatch({
    type: SHOW_ERROR,
    payload: { error }
  }); 
