import React from "react";
import PropTypes from "prop-types";
import { DateTimePicker } from "@material-ui/pickers";

const DateTimePickers = React.memo(
  ({ date, title, dateError, onChange, disableFuture, disablePast }) => {
    return (
      <DateTimePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        error={dateError}
        helperText={dateError && "End date should be greater than Start date"}
        style={{ margin: "8px", width: "37.5%" }}
        variant="outlined"
        margin="normal"
        id={title}
        label={title}
        value={date || null}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        inputVariant="outlined"
      />
    );
  }
);

export default DateTimePickers;

DateTimePickers.propTypes = {
  title: PropTypes.string,
  dateError: PropTypes.bool,
  onChange: PropTypes.func
};
