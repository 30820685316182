const styles = theme => ({
  root: {
    display: "flex",
    marginRight: "10px",
    width: "190px",
    position: "relative",
    alignItems: "center",
    zIndex: "6",
    "@media screen and (max-width: 1350px) ": {
      width: "130px"
    }
  },
  paper: {
    marginRight: theme.spacing(2),
    width: "100%",
    position: "absolute",
    left: "0",
    right: "0",
    paddingTop: "3px"
  },
  exportBtn: {
    textTransform: "none",
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "92px",
    transform: "translate3d(-41px, 0px, 0px)",
    "@media screen and (max-width: 1350px) ": {
      marginLeft: "auto"
    },
    minWidth: "140px",
    padding: "6px 16px"
  },
  exportBtnActive: {
    textTransform: "none",
    width: "100%",
    position: "absolute",
    left: "0",
    right: "0",
    minWidth: "140px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "10px 10px 0 0",
    minHeight: "50px",
    backgroundColor: "white",
    marginLeft: "51px",
    transform: "translate3d(-41px, 0px, 0px)"
  },
  overlay: {
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    background: "#000",
    zIndex: "-1",
    opacity: ".2"
  }
});

export default styles;
