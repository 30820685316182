import { TOGGLE_ERROR_MESSAGE, TOGGLE_SUCCESS_MESSAGE } from "./actionType";

export const toggleErrorMessage = () => {
  return {
    type: TOGGLE_ERROR_MESSAGE
  };
};

export const toggleSuccessMessage = () => {
  return {
    type: TOGGLE_SUCCESS_MESSAGE
  };
};
