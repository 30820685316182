import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// redux actions
import {
  setTargetType,
  setCardLocation,
  setCardPosition
} from "../../../../redux/action/AnnouncementCards/CardTargetingActions";
import { setSpecificCSVFile } from "../../../../redux/action/uploadFileActions";
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
import FileUpload from "../../../FileUpload";

import styles from "../../styles.css";
import content from "../ContentConfig";
import { targetingType, cardLocation } from "./CardTargetingConfig";

const useStyles = makeStyles(styles);

const CardTargeting = ({
  announcementCards,
  fileUpload,
  setTargetType,
  setCardLocation,
  setCardPosition,
  setSpecificCSVFile,
  checkRequiredFields
}) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);

  const handleTargetSelection = event => {
    const value = event.target.value;
    if (value !== "specific") {
      setSpecificCSVFile("");
    }
    setTargetType(value);
  };

  const handleLocationSelection = event => {
    setCardLocation(event.target.value);
  };

  const handleCardPosition = event => {
    const fieldValue = event.target.value;
    if (fieldValue > 0 && fieldValue < 11) {
      setError(false);
      setCardPosition(event.target.value);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (announcementCards.targetType && announcementCards.cardLocation) {
      if (announcementCards.targetType === "specific" && fileUpload) {
        checkRequiredFields(true);
      } else {
        checkRequiredFields(true);
      }
    }
  }, [announcementCards]);

  return (
    <CardContainer title={`4. ${content.stepsTitle[3]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <Grid className={`${classes.container} ${classes.marginBlock}`}>
            <TextField
              id="target-type"
              select
              label="Target Type"
              className={classes.textField}
              name="targetType"
              value={announcementCards.targetType}
              onChange={handleTargetSelection}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
              required
            >
              <option value="none">Select an Option</option>
              {targetingType.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            {announcementCards.targetType === "specific" && <FileUpload />}
          </Grid>

          <Grid className={`${classes.container} ${classes.marginBlock}`}>
            <TextField
              id="card-location"
              select
              label="Card Location"
              className={classes.textField}
              name="cardLocation"
              value={announcementCards.cardLocation}
              onChange={handleLocationSelection}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
              required
            >
              <option value="none">Select an Option</option>
              {cardLocation.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <TextField
              id="card-position"
              label="Card position in page"
              type="number"
              className={classes.textField}
              value={announcementCards.cardPosition}
              onChange={handleCardPosition}
              error={error}
              helperText="Enter a value between 1 and 10"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: 1,
                max: 10
              }}
            />
          </Grid>
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    announcementCards: state.announcementCards,
    fileUpload: state.uploadFile.specificCSV
  };
};

export default connect(
  mapStateToProps,
  {
    setTargetType,
    setCardLocation,
    setCardPosition,
    setSpecificCSVFile,
    checkRequiredFields
  }
)(CardTargeting);

CardTargeting.propTypes = {
  announcementCards: PropTypes.object,
  fileUpload: PropTypes.string,
  setTargetType: PropTypes.func,
  setCardLocation: PropTypes.func,
  setCardPosition: PropTypes.func,
  setSpecificCSVFile: PropTypes.func,
  checkRequiredFields: PropTypes.func
};
