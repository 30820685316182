import * as pointsService from "../../../../services/points";
import { toggleErrorMessage } from "../../ErrorMessageActions";
export const fetchCashbackConfig = () => async dispatch => {
  await pointsService
    .getCurrencyRates()
    .then(data => {
      dispatch({
        type: "FETCH_CASHBACK_CONFIG",
        payload: data
      });
    })
    .catch(err => {
      dispatch({
        type: "ERROR_FETCH_CASHBACK_CONFIG",
        payload: err
      });
    });
};

export const clearStateValue = () => {
  return {
    type: "RESET_STATE"
  };
};

export const clearHistoryList = () => {
  return {
    type: "CLEAR_HISTORY_LIST"
  };
};

export const UpdateCashbackConfig = data => {
  return {
    type: "UPDATE_CASHBACK_CONFIG",
    payload: data
  };
};

export const submitUpdateCashbackConfig = data => async dispatch => {
  await pointsService
    .updateCurrencyRate(data)
    .then(() => {
      dispatch({
        type: "UPDATE_CASHBACK_CONFIG_SUCCESS"
      });
    })
    .catch(err => {
      dispatch({
        type: "UPDATE_CASHBACK_CONFIG_ERROR"
      });
      dispatch(toggleErrorMessage());
    });
};

export const getHistoryList = ({
  id,
  page = 1,
  limit = 10
}) => async dispatch => {
  dispatch({ type: "FETCH_LOADING_HISTORY_CASHBACK" });
  await pointsService
    .getCurrencyRateHistory(id, page, limit)
    .then(data => {
      dispatch({
        type: "FETCH_HISTORY_CASHBACK",
        payload: { res: data, id }
      });
    })
    .catch(err => {
      dispatch({
        type: "FETCH_HISTORY_CASHBACK_FAILED"
      });
      dispatch(toggleErrorMessage());
    });
};
