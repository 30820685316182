import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// redux actions
import {
  fetchQuickLinks,
  deleteQuickLinks,
  clearQuickLinksState
} from "../../redux/action/QuickLinks/LinksListViewActions";
// core components
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import QuickLinkCard from "./QuickLinkCard";
import ErrorMessage from "../ErrorMessage";

import styles from "./QuickLinkStyles.css";
import SelectCountry from "../CountryComponent";
const useStyles = makeStyles(styles);

function QuickLinksList({
  match,
  quickLinksList,
  fetchQuickLinks,
  deleteQuickLinks,
  clearQuickLinksState,
  errorMessage,
  countrySelected
}) {
  const classes = useStyles();

  const deleteQuickLink = id => {
    deleteQuickLinks(id);
  };

  useEffect(() => {
    fetchQuickLinks({
      countrySelected
    });
    clearQuickLinksState();
  }, [countrySelected]);

  return (
    <>
      <GridContainer>
        <div className={classes.cardLinkContainer}>
          <SelectCountry />
          <Link to={`${match.path}/form`} className={classes.cardLinkAdd}>
            Add Quick Link
          </Link>
        </div>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Quick Links</h4>
            </CardHeader>
          </Card>
        </GridItem>
        {quickLinksList.length === 0 ? (
          <Typography
            component="p"
            variant="subtitle1"
            align="center"
            gutterBottom={true}
            style={{ width: "100%" }}
          >
            No Quick links added yet!
          </Typography>
        ) : (
          quickLinksList.map(item => {
            return (
              <GridItem xs={12} sm={12} md={6} key={item.id}>
                <QuickLinkCard item={item} deleteQuickLink={deleteQuickLink} />
              </GridItem>
            );
          })
        )}
      </GridContainer>
      {errorMessage && <ErrorMessage message="Sorry something went wrong!" />}
    </>
  );
}

const mapStateToProps = state => {
  return {
    quickLinksList: state.quickLinksList.linksList,
    errorMessage: state.errorMessage.error,
    countrySelected: state.Countries.countryNameSelected
  };
};

export default connect(
  mapStateToProps,
  {
    fetchQuickLinks,
    deleteQuickLinks,
    clearQuickLinksState
  }
)(QuickLinksList);

QuickLinksList.propTypes = {
  match: PropTypes.object,
  quickLinksList: PropTypes.arrayOf(PropTypes.object),
  fetchQuickLinks: PropTypes.func,
  deleteQuickLinks: PropTypes.func,
  clearQuickLinksState: PropTypes.func,
  errorMessage: PropTypes.bool,
  countrySelected: PropTypes.string
};
