import * as redemptionService from "../../../../services/redemption";

export const UPDATE_USER_REQUEST_STATUS = "update user request status";

export const updateUserRequestStatus = (id, userRequest) => dispatch =>
  redemptionService.updateUserRequestStatus(id, userRequest).then(data => {
    dispatch({
      type: UPDATE_USER_REQUEST_STATUS,
      payload: {
        id,
        userRequest: {
          ...userRequest,
          ...data
        }
      }
    });
  });
