import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  clearStateValue,
  fetchMissionConfig
} from "../../../redux/action/Configuration/MissionsConfig/FetchMissionsConfig";

// redux actions
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import styles from "../styles.css";
import CardBody from "../../../components/Card/CardBody.js";
import { Typography } from "@material-ui/core";
import ActionButtons from "../../ActionButtons/ActionButtons";
import ErrorMessage from "../../ErrorMessage";

const useStyles = makeStyles(styles);

const MissionConfig = props => {
  const classes = useStyles();
  const {
    availableError,
    usdToEgp,
    errMissionsConfigPoints,
    clearStateValue,
    redirect,
    fetchMissionConfig,
    errUpdateMissionsConfig
  } = props;

  if (availableError) {
    throw new Error();
  }
  useEffect(() => {
    if (redirect || errUpdateMissionsConfig) {
      clearStateValue();
      fetchMissionConfig();
    }
  }, [redirect]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Missions Config</h4>
          </CardHeader>
        </Card>
      </GridItem>
      {errMissionsConfigPoints ? (
        <ErrorMessage message="Sorry something went wrong!" />
      ) : (
        <>
          {Object.keys(usdToEgp).length !== 0 && (
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ alignItems: "center" }}>
                <CardBody
                  className={classes.cardBody}
                  style={{
                    width: "70%",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Card>
                    <CardBody className={classes.cardBody}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBlockStart: "16px",
                          alignItems: "center"
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          color="textPrimary"
                        >
                          USD-EGP
                        </Typography>

                        <Typography
                          component="h2"
                          variant="subtitle1"
                          style={{ color: "#0abb87" }}
                        >
                          {`${usdToEgp.usdToEgp} EGP`}
                        </Typography>
                      </div>
                    </CardBody>
                  </Card>
                  <ActionButtons
                    item={usdToEgp}
                    viewType="Edit"
                    deleteButton={false}
                    editButton={true}
                    typeFieldConfig="MissionsConfigUpdate"
                  />
                </CardBody>
              </Card>
            </GridItem>
          )}
        </>
      )}
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    availableError: state.availableError.availableError,
    usdToEgp: state.missionUsdEgp.usdToEgp,
    redirect: state.missionConfigUpdate.redirect,
    errMissionsConfigPoints: state.missionUsdEgp.errMissionsConfigPoints,
    errUpdateMissionsConfig: state.missionConfigUpdate.errUpdateMissionsConfig
  };
};
export default connect(
  mapStateToProps,
  { clearStateValue, fetchMissionConfig }
)(MissionConfig);

MissionConfig.propTypes = {
  availableError: PropTypes.bool,
  usdToEgp: PropTypes.object,
  errMissionsConfigPoints: PropTypes.bool,
  clearStateValue: PropTypes.func,
  redirect: PropTypes.bool,
  fetchMissionConfig: PropTypes.func,
  errUpdateMissionsConfig: PropTypes.bool
};
