import {
  UPDATE_PAGE,
  UPDATE_LIMIT,
  UPDATE_TOTAL
} from "../../../actions/activities/pagination";

const initialState = {
  page: 1,
  limit: 10,
  total: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAGE:
      return {
        ...state,
        page: action.payload.page
      };
    case UPDATE_LIMIT:
      return {
        ...state,
        limit: action.payload.limit
      };
    case UPDATE_TOTAL:
      return {
        ...state,
        total: action.payload.total
      };
    default:
      return state;
  }
};
