export const UPDATE_EXCHANGE_RATE = "update exchange rate";
export const UPDATE_EXCHANGE_RATES_CHANGES_STATUS =
  "update exchange rates changes status";
export const UPDATE_EXCHANGE_RATES_ERRORS_STATUS =
  "update exchange rates errors status";
export const RESET_EXCHANGE_RATES = "reset exchange rates";
export const SYNC_EXCHANGE_RATES = "sync exchange rates";
export const INIT_EXCHANGE_RATES_HISTORY_PAGINATION =
  "init exchange rates history pagination";
export const CHANGE_EXCHANGE_RATES_HISTORY_PAGE =
  "change exchange rates history page";
export const SYNC_EXCHANGE_RATES_HISTORY = "sync exchange rates history";

export const updateExchangeRate = (id, exchangeRate) => dispatch =>
  dispatch({
    type: UPDATE_EXCHANGE_RATE,
    payload: { id, exchangeRate }
  });

export const updateExchangeRatesChangesStatus = status => dispatch =>
  dispatch({
    type: UPDATE_EXCHANGE_RATES_CHANGES_STATUS,
    payload: { status }
  });

export const updateExchangeRatesErrorsStatus = status => dispatch =>
  dispatch({
    type: UPDATE_EXCHANGE_RATES_ERRORS_STATUS,
    payload: { status }
  });

export const resetExchangeRates = data => dispatch =>
  dispatch({
    type: RESET_EXCHANGE_RATES,
    payload: { data }
  });

export const syncExchangeRates = status => dispatch =>
  dispatch({
    type: SYNC_EXCHANGE_RATES,
    payload: { status }
  });

export const initExchangeRatesHistoryPagination = size => dispatch =>
  dispatch({
    type: INIT_EXCHANGE_RATES_HISTORY_PAGINATION,
    payload: { size }
  });

export const changeExchangeRatesHistoryPage = page => dispatch =>
  dispatch({
    type: CHANGE_EXCHANGE_RATES_HISTORY_PAGE,
    payload: { page }
  });

export const syncExchangeRatesHistory = status => dispatch =>
  dispatch({
    type: SYNC_EXCHANGE_RATES_HISTORY,
    payload: { status }
  });
