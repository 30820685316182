import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";

import { updateQuery } from "../../../main/deals/merchants/actions/search";
import {
  updatePage,
  updateLimit,
  updateTotal
} from "../../../main/deals/merchants/actions/pagination";
import {
  updateOrderBy,
  updateItems,
  deleteItem
} from "../../../main/deals/merchants/actions/list";
import { addItem as addNotification } from "../../../widgets/notifications/actions/list";

import * as dealsService from "../../../services/deals";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Toolbar from "@mui/material/Toolbar";

import Link from "@mui/material/Link";

import Avatar from "@mui/material/Avatar";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import Button from "@mui/material/Button";

import Slide from "@mui/material/Slide";

import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import SearchImg from "./assets/img/search.png";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import WaslaTheme from "../../../modules/wasla-theme";

import Loader from "../../../components/loader";

import Pagination from "../../../components/Pagination";

import configurations from "../../../configurations";
import { dealsMerchantsPath } from "../../../main/deals/merchants/paths";
import { dealsMerchantsAddPath } from "../../../main/deals/merchants/add-n-edit/add/paths";

import avatar from "./assets/avatar.svg";
import WarningIcon from "./assets/warning.png";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 480,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MerchantRecord = props => {
  const { item, deleteItem } = props;
  const [showTargetUrls, setShowTargetUrls] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [sync, setSync] = useState(false);

  const handleDeleteClick = e => {
    setConfirmDelete(true);
  };

  const handleConfirmDeleteClose = e => {
    setConfirmDelete(false);
  };

  const handleConfirmDeleteCancel = e => {
    setConfirmDelete(false);
  };

  const handleConfirmDeleteApprove = id => async e => {
    setSync(true);
    await deleteItem(id);
    setSync(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>{`#${item.order}`}</TableCell>
        <TableCell>{item.nameEn}</TableCell>
        <TableCell>{item.nameAr}</TableCell>
        <TableCell sx={{ fontWeight: 500, color: "#101828" }}>
          {item.id}
        </TableCell>
        <TableCell>{item.descriptionEn}</TableCell>
        <TableCell>{item.descriptionAr}</TableCell>
        <TableCell>
          <Avatar
            alt={item.nameEn}
            src={item.logoUrl || avatar}
            sx={{ width: 56, height: 56 }}
          />
        </TableCell>
        <TableCell>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Box
                  sx={{
                    fontWeight: 500,
                    color: "#101828"
                  }}
                  dangerouslySetInnerHTML={{ __html: item.tncEn }}
                ></Box>
              </React.Fragment>
            }
          >
            <Box
              sx={{
                fontWeight: 500,
                color: "#101828",
                maxWidth: "320px",
                maxHeight: "60px",
                overflow: "hidden",
                "& * ": {
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }
              }}
              dangerouslySetInnerHTML={{ __html: item.tncEn }}
            ></Box>
          </HtmlTooltip>
        </TableCell>
        <TableCell>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Box
                  sx={{
                    fontFamily: "Cairo",
                    fontSize: "16px",
                    lineHeight: "24px"
                  }}
                  dangerouslySetInnerHTML={{ __html: item.tncAr }}
                ></Box>
              </React.Fragment>
            }
          >
            <Box
              sx={{
                fontFamily: "Cairo",
                fontSize: "16px",
                lineHeight: "24px",
                maxWidth: "320px",
                maxHeight: "72px",
                overflow: "hidden",
                "& * ": {
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }
              }}
              dangerouslySetInnerHTML={{ __html: item.tncAr }}
            ></Box>
          </HtmlTooltip>
        </TableCell>
        <TableCell>{item.countries.join(", ")}</TableCell>
        <TableCell>
          <Link
            underline="none"
            sx={{
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            {item.url}
          </Link>
        </TableCell>
        <TableCell>{item.isPopular ? "True" : "False"}</TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            <IconButton aria-label="delete" onClick={handleDeleteClick}>
              <DeleteOutlinedIcon />
            </IconButton>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              component={RouterLink}
              to={[
                configurations.dashboard.basePath,
                `${dealsMerchantsPath}/${item.id}`
              ].join("")}
            >
              <IconButton aria-label="edit">
                <ModeEditOutlinedIcon />
              </IconButton>
            </Link>
            <Typography
              sx={{
                py: "2px",
                px: "8px",
                borderRadius: 8,
                display: "inline-block",
                textAlign: "center",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "18px",
                alignSelf: "center",
                ...(showTargetUrls
                  ? {
                      backgroundColor: "#F8F9FC",
                      color: "#363F72"
                    }
                  : {
                      backgroundColor: "#FFF8E2",
                      color: "#FFCC0A"
                    })
              }}
            >
              +{item.targetUrls.length}
            </Typography>
            <IconButton
              aria-label="expand"
              onClick={e => setShowTargetUrls(!showTargetUrls)}
            >
              {showTargetUrls ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </Stack>
          <Dialog
            open={confirmDelete}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleConfirmDeleteClose}
            aria-describedby={`delete-${item.id}`}
            maxWidth="xs"
          >
            <DialogTitle>
              <Stack spacing={2}>
                <Box>
                  <img src={WarningIcon} alt="warning" />
                </Box>
                <Typography variant="inherit">
                  Confirm {item.nameEn} deletion
                </Typography>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You are about to delete a merchant with {item.dealsCount} active
                deals. If you confirm the merchant and the related deals will be
                removed from the database permenantly
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Stack
                direction="row"
                sx={{ width: "100%" }}
                spacing={2}
                justifyContent="stretch"
              >
                <Button
                  onClick={handleConfirmDeleteCancel}
                  variant="outlined"
                  sx={{
                    width: "100%",
                    textTransform: "capitalize",
                    color: "#344054",
                    borderColor: "#D0D5DD"
                  }}
                  disabled={sync}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmDeleteApprove(item.id)}
                  variant="contained"
                  color="error"
                  sx={{
                    width: "100%",
                    textTransform: "capitalize",
                    borderColor: "#D92D20"
                  }}
                  disabled={sync}
                >
                  Delete
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableRow>
      {showTargetUrls &&
        item.targetUrls.map(targetUrl => (
          <TableRow key={`${targetUrl.country}-${targetUrl.url}`}>
            <TableCell colSpan={10}></TableCell>
            <TableCell>
              <Link
                underline="none"
                sx={{
                  "&:hover": {
                    color: "inherit"
                  }
                }}
                color="inherit"
                href={targetUrl.url}
                target="_blank"
                rel="noreferrer"
              >
                {targetUrl.url}
              </Link>
            </TableCell>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
        ))}
    </>
  );
};

const Merchants = props => {
  const {
    merchants: {
      search: { query },
      pagination: { page, limit, total },
      list: { orderBy, items }
    },
    updateQuery,
    updatePage,
    updateLimit,
    updateTotal,
    updateOrderBy,
    updateItems,
    deleteItem,
    addNotification
  } = props;
  const [loading, setLoading] = useState(true);

  const retrieveMerchants = async (query, orderBy, page, limit) => {
    setLoading(true);
    const res = await dealsService.getMerchants(
      query.trim(),
      orderBy,
      page,
      limit
    );
    updateItems(res.data);
    updateTotal(res.meta.total);
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      updateQuery("");
    };
  }, []);

  useEffect(() => {
    retrieveMerchants(query, orderBy, page, limit);
  }, [page]);

  useEffect(() => {
    if (page == 1) {
      retrieveMerchants(query, orderBy, page, limit);
    } else {
      updatePage(1);
    }
  }, [query, orderBy, limit]);

  const handlePageClick = (e, page) => {
    updatePage(page);
  };

  const handleDeleteMerchant = async id => {
    const res = await dealsService.deleteMerchant(id);
    addNotification({
      id: new Date().getTime(),
      type: "success",
      message: "Successfully Deleted",
      autoHideTime: new Date().getTime() + 3 * 1000
    });
    deleteItem(id);
    if (page != Math.ceil(total / limit)) {
      retrieveMerchants(query, orderBy, page, limit);
    } else if (!items.length) {
      updatePage(Math.max(page - 1, 1));
    }
  };

  const SearchIcon = () => (
    <img src={SearchImg} alt="" style={{ width: "20px" }} />
  );

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            component={RouterLink}
            to={[configurations.dashboard.basePath, "/Deals&Merchant"].join("")}
          >
            Deals
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            Merchants
          </Typography>
        </Breadcrumbs>
        <Stack
          spacing={4}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TextField
            label=""
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            sx={{ minWidth: "360px" }}
            value={query}
            placeholder="Search for Merchant ID or Name"
            onChange={e => updateQuery(e.target.value)}
          />
          <Link
            underline="none"
            color="inherit"
            component={RouterLink}
            to={[configurations.dashboard.basePath, dealsMerchantsAddPath].join(
              ""
            )}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ textTransform: "capitalize" }}
            >
              Create Merchant
            </Button>
          </Link>
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={13}>
                    <Toolbar
                      sx={{
                        minHeight: "auto !important",
                        px: "0 !important"
                      }}
                    >
                      <Typography sx={{ flex: "1 1 100%" }} variant="inherit">
                        All Merchants
                      </Typography>
                    </Toolbar>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">Order No.</TableCell>
                  <TableCell component="th">Name - English</TableCell>
                  <TableCell component="th">Name - Arabic</TableCell>
                  <TableCell component="th">Merchant ID</TableCell>
                  <TableCell component="th">En Description</TableCell>
                  <TableCell component="th">AR Description</TableCell>
                  <TableCell component="th">Logo</TableCell>
                  <TableCell component="th">EN Terms & Conditions</TableCell>
                  <TableCell component="th">AR Terms & Conditions</TableCell>
                  <TableCell component="th">Countries</TableCell>
                  <TableCell component="th">URL</TableCell>
                  <TableCell component="th">Is Popular</TableCell>
                  <TableCell component="th">Action</TableCell>
                </TableRow>
                {items.map(item => (
                  <MerchantRecord
                    key={item.id}
                    item={item}
                    deleteItem={handleDeleteMerchant}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={13}>
                    <Pagination
                      page={page}
                      limit={limit}
                      total={total}
                      rowsPerPageOptions={[]}
                      handlePageClick={handlePageClick}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </WaslaTheme>
  );
};

export default connect(
  state => ({ merchants: state.merchants }),
  {
    updateQuery,
    updatePage,
    updateLimit,
    updateTotal,
    updateOrderBy,
    updateItems,
    deleteItem,
    addNotification
  }
)(Merchants);
