import { OPEN_SNACK_BAR, CLOSE_SNACK_BAR } from "../action/actionType";

const initialState = {
  open: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SNACK_BAR:
      return {
        ...state,
        open: action.payload
      };
    case CLOSE_SNACK_BAR:
      return {
        ...state,
        open: action.payload
      };

    default:
      return state;
  }
};
