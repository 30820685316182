const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  paper: {
    margin: 0,
    height: 128,
    borderRadius: 5,
    border: "1px solid #95989A",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.87)",
    "& button": {
      "&:hover": {
        color: "#0854FF",
        backgroundColor: "transparent"
      }
    },
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      lineHeight: 1.3
    }
  }
});

export default styles;
