import Conversions from "./Conversions";
import { cashbacksConversionsPath } from "./paths";
import conversionConfig from "./conversion/conversionConfig";
import editRequestConfig from "./edit-request/editRequestConfig";

export default {
  routes: [
    ...editRequestConfig.routes,
    ...conversionConfig.routes,
    {
      path: cashbacksConversionsPath,
      component: Conversions,
      layout: "main",
      isPrivate: false,
      roles: ["admin", "cs"],
      routes: []
    }
  ]
};
