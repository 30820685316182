import * as React from "react";
import Link from "@mui/material/Link";

import ReorderIcon from "../assets/reorder.png";
import EditIcon from "../assets/edit.png";

import configurations from "../../../../configurations";
import { CategoriesPath } from "../../../../main/deals/categories/paths";

export const DragHandleCell = props => (
  <td
    {...props}
    className={
      !props.dataItem.isComplete ? "incompleteColumn actionTd" : "actionTd"
    }
  >
    <Link
      underline="none"
      sx={{
        display: "flex",
        alignItems: "center",
        "&:hover": {
          color: "inherit"
        }
      }}
      color="inherit"
      href={[
        configurations.dashboard.basePath,
        `${CategoriesPath}/${props.dataItem.slug}`
      ].join("")}
    >
      <img
        src={EditIcon}
        style={{
          cursor: "pointer",
          marginRight: "27px"
        }}
        data-drag-handle={false}
      />
    </Link>
    <img
      src={ReorderIcon}
      style={{
        cursor: "grab"
      }}
      data-drag-handle={true}
    />
  </td>
);
