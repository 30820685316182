const styles = theme => ({
  icon: {
    "& .material-icons": {
      width: "32px !important",
      height: "32px !important",
      lineHeight: "32px !important",
      marginBottom: "0 !important",
      fontSize: "19px !important"
    }
  },
  title: {
    lineHeight: "42px",
    textAlign: "start"
  },
  cardLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  cardLinkAdd: {
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    padding: "10px 25px",
    borderRadius: "5px",
    color: "#ffff",
    border: "1px solid transparent",
    fontWeight: "500",
    "&:hover": {
      background: "transparent",
      color: "#ffa726",
      border: "1px solid #ffa726"
    }
  },
  iconWarning: {
    color: "#ffa726"
  },
  modalContainer: {
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -48%)",
    textAlign: "center"
  },
  paper: {
    position: "absolute",
    maxWidth: 480,
    width: "80%",
    backgroundColor: "#fff",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
    padding: "16px 32px 24px"
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  proceed: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText
  },
  cancel: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText
  }
});

export default styles;
