import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// core components
import styles from "../styles.css";

const useStyles = makeStyles(styles);

function CustomModal(props) {
  const classes = useStyles();
  const { title, children, open, close, style } = props;

  return (
    <Modal
      aria-labelledby="recharge users"
      aria-describedby="modal represents recharge users process"
      open={open}
      onClose={close}
    >
      <div className={classes.modalContainer} style={style}>
        <div
          className={`${classes.container} ${classes.containerContentSpaceBetween}`}
        >
          <Typography
            className={classes.textAlignLeft}
            variant="h6"
            id="modalTitle"
            component="h2"
          >
            {title}
          </Typography>
          <Button onClick={close} aria-label="close">
            <HighlightOffIcon />
          </Button>
        </div>
        {children}
      </div>
    </Modal>
  );
}

export default CustomModal;

CustomModal.propTypes = {
  close: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node
};
