export default class logger {
  configs = {
    types: ["log", "info", "warn", "error", "debug"]
  };
  subscribers = [];

  constructor(configs = {}) {
    this.configs = { ...this.configs, ...configs };
  }

  // id
  // main
  // types
  // tags

  subscribe(subscriber) {
    this.subscribers = [...this.subscribers, subscriber];
  }

  unsubscribe(id) {
    this.subscribers = this.subscribers.filter(
      subscriber => subscriber.id != id
    );
  }

  // type
  // tags
  // payload

  log(record) {
    if (this.configs.types.indexOf(record.type) != -1)
      this.subscribers
        .filter(
          subscriber =>
            (!subscriber.types.length ||
              subscriber.types.indexOf(record.type) != -1) &&
            (!subscriber.tags.length ||
              record.tags.some(tag => subscriber.tags.indexOf(tag) != -1))
        )
        .forEach(subscriber => subscriber.main(record.payload));
  }
}
