import * as redemptionService from "../../../../../services/redemption";

export const GET_EXCHANGE_RATES_HISTORY = "get exchange rates history";

export const getExchangeRatesHistory = (page, size) => dispatch =>
  redemptionService.getExchangeRatesHistory(page, size).then(data => {
    dispatch({
      type: GET_EXCHANGE_RATES_HISTORY,
      payload: data
    });
  });
