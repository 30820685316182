import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
// core components
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardDetails from "./CardDetails";
import RecordStatus from "../../Shared/RecordStatus";
import GamesPoints from "./GamesPoints";
import DatesDetails from "./DatesDetails";
import UploadCompletedUsers from "./UploadCompletedUsers";
import ErrorMessage from "../../ErrorMessage";

import { missionsBaseUrl } from "../../config";
import styles from "../styles.css";
const useStyles = makeStyles(styles);

const MissionsCardsDetails = props => {
  const classes = useStyles();

  const item = props?.location?.state ? props?.location?.state?.item : "";
  const sectionName = props?.location?.state?.sectionName;
  return (
    <>
      <Card>
        <CardHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography component="h1" variant="h6">
            Name:
            <Typography
              component="p"
              variant="subtitle1"
              style={{ display: "inline-flex", marginLeft: "8px" }}
            >
              {item.titleEn}
            </Typography>
          </Typography>
          <RecordStatus startDate={item.startDate} endDate={item.endDate} />
        </CardHeader>
        <CardContent>
          <Typography
            component="h1"
            variant="h6"
            style={{ textDecoration: "underline", marginBottom: "1em" }}
          >
            Mission Details
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              MissionType:
              <Typography
                component="span"
                variant="body1"
                style={{ marginLeft: "8px", color: "#3f51b5" }}
              >
                {item.type}
              </Typography>
            </Typography>
            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              Section:
              <Typography
                component="span"
                variant="body1"
                style={{
                  marginLeft: "8px",
                  color: "#3f51b5",
                  textTransform: "capitalize"
                }}
              >
                {sectionName}
              </Typography>
            </Typography>
            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              URL Params:
              <Typography
                component="span"
                variant="body1"
                style={{
                  marginLeft: "8px",
                  color: "#3f51b5",
                  textTransform: "capitalize"
                }}
              >
                {item?.urlParams}
              </Typography>
            </Typography>

            {item.totalPoints && (
              <Typography
                component="p"
                variant="body1"
                style={{ flexBasis: "33%" }}
                gutterBottom={true}
              >
                Mission Total Points:
                <Typography
                  component="span"
                  variant="body1"
                  style={{ marginLeft: "8px", color: "#009900" }}
                >
                  {`${item.totalPoints} pts`}
                </Typography>
              </Typography>
            )}

            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              Rewarded Points:
              <Typography
                component="span"
                variant="body1"
                style={{ marginLeft: "8px", color: "#009900" }}
              >
                {`${item.points} pts`}
              </Typography>
            </Typography>

            {item.order && (
              <Typography
                component="p"
                variant="body1"
                style={{ flexBasis: "33%" }}
                gutterBottom={true}
              >
                Mission Order:
                <Typography
                  component="span"
                  variant="body1"
                  style={{ marginLeft: "8px" }}
                >
                  {item.order}
                </Typography>
              </Typography>
            )}

            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              {item.isFeatured ? (
                <Typography
                  component="span"
                  variant="body1"
                  style={{ color: "#009900" }}
                >
                  Featured
                </Typography>
              ) : (
                <Typography
                  component="span"
                  variant="body1"
                  style={{ color: "red" }}
                >
                  Not Featured
                </Typography>
              )}
            </Typography>

            {item.maxUsage > 0 && (
              <Typography
                component="p"
                variant="body1"
                style={{ flexBasis: "33%" }}
                gutterBottom={true}
              >
                Mission Usage:
                <Typography
                  component="span"
                  variant="body1"
                  style={{ marginLeft: "8px" }}
                >
                  {item.maxUsage}
                </Typography>
              </Typography>
            )}

            <DatesDetails startDate={item.startDate} endDate={item.endDate} />

            {/* {item.time > 0 && (
              <Typography
                component="p"
                variant="body1"
                style={{ flexBasis: "33%" }}
                gutterBottom={true}
              >
                Completion Time:
                <Typography
                  component="span"
                  variant="body1"
                  style={{ marginLeft: "8px" }}
                >
                  {item.time}
                </Typography>
              </Typography>
            )} */}
            {/* 
            {item.category.name.en.toLowerCase() === "games" && (
              <GamesPoints
                minPoints={item.minPoints}
                maxPoints={item.maxPoints}
              />
            )} */}
            {/* 
            {item.targetType && (
              <Typography
                component="p"
                variant="body1"
                style={{ flexBasis: "33%" }}
                gutterBottom={true}
              >
                Targeting:
                <Typography
                  component="span"
                  variant="body1"
                  style={{ marginLeft: "8px" }}
                >
                  {item.targetType}
                </Typography>
              </Typography>
            )} */}

            {/* {item.targetType === "specific" && (
              <Button
                className={classes.buttonExport}
                href={`${missionsBaseUrl}/missions/${item._id ||
                  item.id}/codesCSV`}
              >
                Export CSV Here
              </Button>
            )}*/}
          </div>
          <UploadCompletedUsers itemId={item._id || item.id} />
        </CardContent>
      </Card>

      <CardDetails item={item} />

      {props.success && (
        <ErrorMessage message="Submitted successfully!" msgStatus="success" />
      )}
      {props.errorMessage && (
        <ErrorMessage message="Sorry something went wrong!" />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    errorMessage: state.errorMessage.error,
    success: state.errorMessage.success
  };
};

export default connect(mapStateToProps)(MissionsCardsDetails);

MissionsCardsDetails.propTypes = {
  location: PropTypes.object,
  errorMessage: PropTypes.bool,
  success: PropTypes.bool
};
