import {
  SET_QUICK_LINK_DEFINITION,
  SET_IS_FEATURED,
  SET_IS_NATIVE,
  FETCH_CATEGORY_TYPES,
  EDIT_QUICK_LINKS
} from "../actionType";
import authenticatedHttpClientService from "../../../services/authenticated-http-client";

export const setQuickLink = data => {
  return {
    type: SET_QUICK_LINK_DEFINITION,
    payload: { ...data }
  };
};

export const setIsFeatured = () => {
  return {
    type: SET_IS_FEATURED
  };
};

export const setIsNative = () => {
  return {
    type: SET_IS_NATIVE
  };
};

export const fetchCategoryTypes = () => async dispatch => {
  await authenticatedHttpClientService
    .get(`${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/categories`)
    .then(res => {
      dispatch({
        type: FETCH_CATEGORY_TYPES,
        payload: res.data
      });
    });
};

export const editQuickLink = data => {
  return {
    type: EDIT_QUICK_LINKS,
    payload: data
  };
};
