import { UPDATE_MERCHANT } from "../../actions/origin";

const initialState = {
  order: null,
  nameEn: "",
  nameAr: "",
  logoUrl: null,
  url: "",
  descriptionEn: "",
  descriptionAr: "",
  isPopular: false,
  countries: [],
  targetUrls: [],
  tncEn: "",
  tncAr: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MERCHANT:
      return {
        ...state,
        ...action.payload.merchant
      };
    default:
      return state;
  }
};
