import * as redemptionService from "../../../../services/redemption";

export const GET_USER_REQUESTS = "get user requests";

export const getUserRequests = (
  country,
  page,
  size,
  query,
  from,
  to,
  provider,
  category,
  types,
  currency,
  orderBy
) => dispatch =>
  redemptionService
    .getUserRequests(
      country,
      page,
      size,
      query,
      from,
      to,
      provider,
      category,
      types,
      currency,
      orderBy
    )
    .then(data => {
      dispatch({
        type: GET_USER_REQUESTS,
        payload: data
      });
    });
