import { UPDATE_POINTS_CONFIG } from "../../../action/actionType";

const initialState = {
  MinAdsReserveLimit: 0,
  RewardValues: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_POINTS_CONFIG:
      return {
        ...state,
        MinAdsReserveLimit: action.payload.MinAdsReserveLimit,
        RewardValues: action.payload.RewardValues
      };
    default:
      return state;
  }
};
