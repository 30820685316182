import Cashbacks from "./Cashbacks";
import WaslaGreyIcon from "../../assets/img/greyWaslaIcon.svg";

import { cashbacksPath } from "./paths";

import conversionsConfig from "./conversions/conversionsConfig";

export default {
  routes: [
    {
      path: cashbacksPath,
      name: "Cashbacks",
      icon: WaslaGreyIcon,
      component: Cashbacks,
      layout: "main",
      isPrivate: null,
      roles: ["admin", "cs"],
      routes: [...conversionsConfig.routes]
    }
  ]
};
