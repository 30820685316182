const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: theme.spacing(4)
  },
  fileFieldWrapper: {
    display: "flex",
    alignItems: "center"
  },
  fileViewContainer: {
    display: "flex",
    alignItems: "center",
    width: "270px",
    height: "30px",
    padding: theme.spacing(1),
    border: "1px solid #ffa726",
    marginLeft: theme.spacing(1)
  },
  textFieldFile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "130px",
    lineHeight: "40px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: "4px",
    textAlign: "center",
    borderRadius: "5px",
    color: "#fff",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    cursor: "pointer"
  }
});
export default styles;
