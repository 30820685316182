import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components

// redux actions
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";

// core components
import FileUpload from "../fileField";
import SelectTarget from "../../DealsTable/SelectTarget";
import SelectMerchant from "../../TargetTable/SelectMerchant";

const UploadStep = ({ errorMessage, type }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "8px 0 15px"
      }}
    >
      <h4 style={{ fontSize: "16px", fontWeight: 600 }}>Upload CSV: </h4>
      <FileUpload errorMessage={errorMessage} />
      {type === "Deals" && (
        <>
          <h4 style={{ fontSize: "16px", fontWeight: 600, marginBottom: 0 }}>
            Target Name:
          </h4>
          <SelectTarget />
        </>
      )}
      {type === "Target" && (
        <>
          <h4 style={{ fontSize: "16px", fontWeight: 600, marginBottom: 0 }}>
            Merchant Name:
          </h4>
          <SelectMerchant />
        </>
      )}
    </div>
  );
};

export default connect(
  null,
  {
    checkRequiredFields
  }
)(UploadStep);

UploadStep.propTypes = {
  checkRequiredFields: PropTypes.func
};
