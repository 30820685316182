import Add from "./Add";
import { quickLinksCategoriesAddPath } from "../../paths";

export default {
  routes: [
    {
      path: quickLinksCategoriesAddPath,
      component: Add,
      layout: "main",
      isPrivate: false,
      roles: ["admin"]
    }
  ]
};
