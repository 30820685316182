import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../../assets/img/logo-type-horizontal-primary-english.svg";
import styles from "./styles.css";

const useStyles = makeStyles(styles);

export default function BlankAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="sticky" color="transparent" className={classes.appBar}>
        <Toolbar>
          <img className={classes.logo} src={logo} alt="Wasla" />
        </Toolbar>
      </AppBar>
    </div>
  );
}
