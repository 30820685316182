import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import AnnouncementCard from "../../AnnouncementCard";

import styles from "../../styles.css";
import cardContent from "./cardStylingConfig";

const useStyles = makeStyles(styles);

const ThemeComponent = ({
  value,
  bgPrimaryColor,
  bgSecondaryColor,
  textColor,
  btnBgColor,
  btnTextColor
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value.toString()}
      style={{ flexBasis: "48.5%", marginLeft: "0px" }}
      control={
        <Radio
          checkedIcon={
            <span
              className={`${classes.radioBtnIcon} ${classes.checkedIcon}`}
            />
          }
          icon={<span className={classes.radioBtnIcon} />}
        />
      }
      label={
        <AnnouncementCard
          title={cardContent.title}
          body={cardContent.body}
          btnText={cardContent.btnText}
          imgUrl={cardContent.imgUrl}
          bgPrimaryColor={bgPrimaryColor}
          bgSecondaryColor={bgSecondaryColor}
          textColor={textColor}
          btnBgColor={btnBgColor}
          btnTextColor={btnTextColor}
        />
      }
    />
  );
};
const mapStateToProps = state => {
  return {
    announcementCards: state.announcementCards
  };
};

export default connect(mapStateToProps)(ThemeComponent);

ThemeComponent.propTypes = {
  value: PropTypes.number,
  bgPrimaryColor: PropTypes.string,
  bgSecondaryColor: PropTypes.string,
  textColor: PropTypes.string,
  btnBgColor: PropTypes.string,
  btnTextColor: PropTypes.string
};
