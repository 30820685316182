import {
  FETCH_CS_OTP_Table,
  RESET_CS_OTP_STATE,
  SET_SEARCH_PHONE_NUMBER,
  SET_LOADING_OTP_Table
} from "../../action/actionType";

const initialState = {
  userTableData: [],
  selectedUsers: [],
  hasNext: false,
  phoneNumber: "",
  isLoading: false,
  error: "",
  totalPages: 0,
  limit: 10,
  success: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CS_OTP_Table:
      return {
        ...state,
        isLoading: false,
        userTableData: action.payload?.data,
        hasNext: action.payload?.meta?.hasNext,
        totalPages: action.payload?.meta?.total,
        limit: initialState.limit
      };
    case SET_SEARCH_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload
      };
    case RESET_CS_OTP_STATE:
      return {
        ...state,
        selectedUsers: [],
        isLast: false
      };
    case SET_LOADING_OTP_Table:
      return {
        ...state,
        isLoading: action.payload
      };
    case "SET_LOADING_OTP_Table_ERROR":
      return {
        ...state,
        error: action.payload
      };
    case "BLOCKING_SUCCESS":
      return {
        ...state,
        success: action.payload
      };
    case "RESET_ERROR":
      return {
        ...state,
        error: ""
      };
    default:
      return state;
  }
};
