const initialState = {
  isLoading: false,
  list: [],
  error: false,
  success: false,
  hasNext: false,
  serverError: "",
  dealsUploadList: [],
  targetIdSelected: "",
  targetNameSelected: "",
  UploadLoading: false,
  limit: 10,
  totalPages: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING_DEALS_LIST": {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case "FETCH_DEALS_LIST": {
      return {
        ...state,
        list: action.payload?.data,
        hasNext: action.payload?.meta?.has_next,
        totalPages: action.payload?.meta?.total,
        limit: action.payload?.meta?.limit || state.limit
      };
    }
    case "SET_ERROR_LIST": {
      return {
        ...state,
        serverError: action.payload
      };
    }
    case "DELETE_DEAL_SUCCESS": {
      return {
        ...state,
        success: true,
        deleteSuccessMessage: action.payload
      };
    }
    case "SET_DEALS_LIST_UPLOAD": {
      const setTargetIdToUpload = action.payload.map(item => {
        return {
          ...item,
          targetId: state.targetIdSelected
        };
      });
      return {
        ...state,
        dealsUploadList: setTargetIdToUpload
      };
    }
    case "UPLOAD_DEALS_LOADING": {
      return {
        ...state,
        UploadLoading: action.payload
      };
    }
    case "UPLOAD_DEALS_SUCCESS": {
      return {
        ...state,
        success: true,
        uploadSuccessMessage: action.payload
      };
    }
    case "UPLOAD_DEALS_FAILED": {
      return {
        ...state,
        error: true,
        serverError: action.payload
      };
    }
    case "RESET_ERROR": {
      return {
        ...state,
        serverError: "",
        success: false
      };
    }
    case "SET_TARGET": {
      return {
        ...state,
        targetIdSelected: action.payload?.id,
        targetNameSelected: action.payload?.name
      };
    }
    case "RESET_UPLOAD_DEALS_LIST": {
      return {
        ...state,
        dealsUploadList: [],
        UploadLoading: false,
        success: false,
        error: false,
        targetIdSelected: "",
        targetNameSelected: "",
        deleteSuccessMessage: "",
        uploadSuccessMessage: "",
        serverError: ""
      };
    }
    case "REST_DEALS_STATE": {
      return initialState;
    }
    default:
      return state;
  }
};
