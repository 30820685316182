import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { fetchBucketsList } from "../../redux/action/PointsSystem/FetchBucketsListAction";
import { fetchAdsBucket } from "../../redux/action/PointsSystem/adsListViewActions";
import { clearTypeOfTransfer } from "../../redux/action/PointsSystem/TransferPointsActions";
import { connect } from "react-redux";
// core components
import PointsSystemList from "./ListView";
import AddPoints from "./AddPoints";
import TransferBonusPoints from "./BonusPoints/Form";
// import TestingNumbersList from "./TestingPoints/NumbersList";
// import TransferTestingPoints from "./TestingPoints/Form";
import AdsBucketList from "./AdsBucketSystem/ListView";
import AdsItem from "./AdsBucketSystem/AdsItem";
import CreateAndUpdateAds from "./AdsBucketSystem/Form";
import ErrorBoundary from "../ErrorBoundary";
function PointsSystem(props) {
  const { fetchBucketsList, fetchAdsBucket, clearTypeOfTransfer } = props;
  useEffect(() => {
    fetchBucketsList();
    clearTypeOfTransfer();
    fetchAdsBucket();
  }, []);
  return (
    <>
      <Route
        exact
        path={props.match.path}
        render={routeProps => (
          <ErrorBoundary>
            <PointsSystemList {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route
        path={`${props.match.path}/bonusPoints/addPoints`}
        render={routeProps => (
          <ErrorBoundary>
            <AddPoints {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route
        path={`${props.match.path}/bonusPoints/transferPoints`}
        component={TransferBonusPoints}
      />
      {/* <Route
        path={`${props.match.path}/testingPoints/list`}
        component={TestingNumbersList}
      />
      <Route
        path={`${props.match.path}/testingPoints/addPoints`}
        component={AddPoints}
      />
      <Route
        path={`${props.match.path}/testingPoints/transferPoints`}
        component={TransferTestingPoints}
      /> */}
      <Route
        exact
        path={`${props.match.path}/adsSystem`}
        render={routeProps => (
          <ErrorBoundary>
            <AdsBucketList {...routeProps} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path={`${props.match.path}/adsSystem/:id`}
        render={routeProps => (
          <ErrorBoundary>
            <AdsItem {...routeProps} />
          </ErrorBoundary>
        )}
      />

      <Route
        path={`${props.match.path}/adsSystem/:id/edit/:id`}
        render={routeProps => (
          <ErrorBoundary>
            <CreateAndUpdateAds {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route
        path={`${props.match.path}/adsSystem/:id/add`}
        render={routeProps => (
          <ErrorBoundary>
            <CreateAndUpdateAds {...routeProps} />
          </ErrorBoundary>
        )}
      />
    </>
  );
}

export default connect(
  null,
  { fetchBucketsList, fetchAdsBucket, clearTypeOfTransfer }
)(PointsSystem);
PointsSystem.propTypes = {
  match: PropTypes.object,
  fetchBucketsList: PropTypes.func,
  fetchAdsBucket: PropTypes.func,
  clearTypeOfTransfer: PropTypes.func
};
