import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// redux actions
import { setCardImage } from "../../redux/action/uploadImageActions";
// core components
import ErrorsContainer from "./ErrorsContainer";
import { getBase64 } from "./helper";
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const ImageUpload = ({
  fieldTitle,
  imageUrl,
  setCardImage,
  isRequiredError,
  largeImg
}) => {
  const classes = useStyles();
  const [uploadErrors, setUploadErrors] = useState({
    isRequired: false,
    extensions: false,
    size: false,
    dimension: false
  });

  useEffect(() => {
    if (isRequiredError) {
      setUploadErrors({ isRequired: true });
    }
  }, [isRequiredError]);

  const handleChangeImage = e => {
    if (e.target.files.length === 0) return;
    if (/\.(jpg|jpeg|png|webp|svg)$/i.test(e.target.files[0].name)) {
      let sizeImage = Math.round(e.target.files[0].size / 1024);
      if (sizeImage <= 1000) {
        getBase64(e.target.files[0], (result, widthImage, heightImage) => {
          if (widthImage !== heightImage && largeImg) {
            setCardImage(e.target.files[0]);
            setUploadErrors(null);
          } else if (widthImage === heightImage) {
            setCardImage(e.target.files[0]);
            setUploadErrors(null);
          } else {
            setUploadErrors({
              dimension: true
            });
          }
        });
      } else {
        setUploadErrors({ size: true });
      }
    } else {
      setUploadErrors({ extensions: true });
    }
  };

  return (
    <div className={classes.imageFieldWrapper}>
      <div
        className={
          largeImg ? classes.wideImageViewContainer : classes.imageViewContainer
        }
      >
        {imageUrl && (
          <img
            src={typeof imageUrl === "object" ? URL.createObjectURL(imageUrl) : imageUrl}
            className={largeImg ? classes.wideImageIcon : classes.imageIcon}
            alt="preview"
          />
        )}
      </div>
      <div className={classes.columnFlexContainer}>
        <Typography
          component="h3"
          variant="h5"
          color="textPrimary"
          className={classes.lightFont}
        >
          {fieldTitle}
          <Typography
            component="span"
            variant="caption"
            color="textPrimary"
            style={{ marginLeft: "2px", verticalAlign: "super" }}
          >
            *
          </Typography>
        </Typography>
        <Typography
          component="p"
          variant="body2"
          color="textPrimary"
          paragraph={true}
          className={classes.lightFont}
        >
          Upload a .png or .jpeg or .jpg or .webp or .svg image with max size of
          1MB.
        </Typography>
        {largeImg && (
          <Typography
            component="p"
            variant="body2"
            color="textPrimary"
            paragraph={true}
            className={classes.lightFont}
          >
            Recommended dimensions is 640px * 480px.
          </Typography>
        )}
        {uploadErrors && <ErrorsContainer errors={uploadErrors} />}
        <TextField
          type="file"
          id="file"
          label="file"
          name="file"
          onChange={handleChangeImage}
          margin="normal"
          variant="outlined"
          style={{ display: "none" }}
          className={classes.textFieldFile}
        />
        <label htmlFor="file" className={classes.textFieldFile}>
          <CloudUploadIcon />
          <span>Upload Image</span>
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return { imageUrl: state.imageUrl.url };
};

export default connect(
  mapStateToProps,
  { setCardImage }
)(ImageUpload);

ImageUpload.propTypes = {
  fieldTitle: PropTypes.string,
  imageUrl: PropTypes.string,
  setCardImage: PropTypes.func,
  isRequiredError: PropTypes.bool,
  largeImg: PropTypes.bool
};
