import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const waslaTheme = createTheme({
  palette: {
    primary: {
      light: "#FFCC0A",
      main: "#FFCC0A",
      dark: "#DAAC22",
      contrastText: "#101828"
    },
    secondary: {
      light: "#D0D5DD",
      main: "#344054",
      dark: "#344054",
      contrastText: "#fff"
    },
    neutral: {
      main: "#D0D5DD",
      contrastText: "#344054"
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            textDecoration: "none"
          }
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontFamily: "Urbanist",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          color: "#2B2B2B"
        },
        ol: {},
        li: {
          "&:last-of-type": {
            color: "#BFBFBF"
          }
        },
        separator: {
          color: "#BFBFBF"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {}
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "10px 14px",
          lineHeight: "24px",
          fontFamily: "Inter",
          fontSize: "14px",
          color: "#667085",
          backgroundColor: "#ffffff",
          "&.pagination": {
            marginTop: "0"
          }
        },
        input: {
          padding: 0,
          height: "24px"
        },
        notchedOutline: {
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          border: "1px solid #D0D5DD",
          backgroundColor: "transparent",
          ".Mui-focused > &": {
            border: "1px solid #D0D5DD !important"
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-shrink": {
            position: "static",
            marginBottom: "4px"
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {}
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "#667085"
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "pagination" },
          style: {
            boxShadow: "none",
            borderWidth: "0",
            padding: "10px 16px",
            minWidth: "40px",
            fontFamily: "Inter",
            lineHeight: "20px",
            fontSize: "14px",
            "&:hover, &.active": {
              backgroundColor: "#FFF8E2",
              color: "#FFCC0A"
            },
            "&.action": {
              border: "1px solid #D0D5DD",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              fontFamily: "Roboto",
              "&:hover": {
                borderColor: "#FFCC0A"
              }
            }
          }
        },
        {
          props: { variant: "neutral" },
          style: {
            borderColor: "#D0D5DD",
            "&:hover": {
              backgroundColor: "#FFF8E2",
              color: "#FFCC0A"
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          border: "1px solid #FFCC0A",
          borderRadius: "8px",
          padding: "10px 18px",
          lineHeight: "22px",
          fontSize: "16px",
          fontWeight: 500,
          "&.Mui-disabled": {
            borderColor: "transparent"
          },
          "&:hover": {
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)"
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
          border: "1px solid #E4E7EC",
          borderRadius: "8px"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "16px 24px",
          color: "#667085"
        },
        head: {
          padding: "20px 24px",
          fontFamily: "Inter",
          fontSize: "18px",
          lineHeight: "28px",
          color: "#101828"
        },
        body: ({ ownerState }) => ({
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: 400,
          ...(ownerState.component === "th" && {
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px",
            backgroundColor: "#F9FAFB"
          })
        }),
        footer: {
          padding: "12px 24px 18px"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
        paper: {
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
          borderRadius: "12px",
          padding: "24px"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "28px",
          color: "#101828"
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#667085"
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(52, 64, 84, 0.6)",
          backdropFilter: "blur(8px)"
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#E4E7EC"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          color: "#344054",
          "&.Mui-focused": {
            color: "#344054"
          }
        }
      }
    }
  }
});

export default function WaslaTheme(props) {
  const { children } = props;

  return <ThemeProvider theme={waslaTheme}>{children}</ThemeProvider>;
}
