import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
// core components
import CustomModal from "../Modal";
import UploadFile from "../UploadFile";
import { resetFile } from "../../../redux/action/uploadFileActions";

function CreateProject(props) {
  const { open, handleClose, resetFile } = props;

  useEffect(() => {
    return () => {
      resetFile();
    };
  }, []);

  return (
    <CustomModal open={open} title="Send Recharge" close={handleClose}>
      <UploadFile onCloseModal={handleClose} />
    </CustomModal>
  );
}

export default connect(
  null,
  { resetFile }
)(CreateProject);

CreateProject.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  resetFile: PropTypes.func
};
