const customStyle = {
  root: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "5px",
    "& .MuiInputBase-root": {
      height: "41px",
      "& .MuiSelect-root": {
        backgroundColor: "transparent"
      }
    },
    "& .MuiFormControl-root": {
      margin: "0px 8px"
    }
  },
  popperRoot: {
    transform: "translate3d(13px, 57px, 0px) !important"
  },
  triangleUp: {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: " 20px solid #fff",
    position: "absolute",
    borderRadius: 5,
    top: "0%",
    "&::after": {
      content: '" "',
      display: "block",
      height: 1,
      position: "absolute",
      bottom: "-18px",
      left: "8%",
      width: "89%",
      backgroundColor: "#bdbdbd"
    }
  },
  containerDate: {
    marginBottom: "25px",
    "& > MuiGrid-container": {
      "& div": {
        width: "43.5%"
      }
    },
    "& > .MuiGrid-root": {
      justifyContent: "center",
      alignItems: "center"
    },
    "& > .MuiFormControl-root": {
      flexBasis: "38%"
    }
  },
  title: {
    fontWeight: 500,
    fontSize: "16px",
    marginBottom: "15px"
  },
  filterContainer: { marginBottom: "25px" },

  titleLegend: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px"
  },
  selectGroup: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    "& div:first-child": {
      marginRight: "16px"
    },
    "& div:not(:last-child)": {
      flexBasis: "44%"
    }
  },

  addBtnContainer: {
    marginLeft: "10px",
    height: "41px",
    width: "41px",
    lineHeight: "41px"
  },
  addBtn: {
    backgroundColor: "#278BFF",
    border: 0,
    color: "#fff",
    borderRadius: 5,
    height: "41px",
    width: "41px",
    fontSize: "21px",
    fontWeight: "bold",
    cursor: "pointer"
  },
  containerViewItems: {
    display: "flex",
    marginRight: "10px",
    marginLeft: "10px",
    alignItems: "center",
    margin: "15px"
  },
  containerListItem: {
    display: "flex",
    flexDirection: "column",
    margin: "0 10px"
  },
  itemView: {
    backgroundColor: "#278BFF",
    padding: "10px 20px",
    borderRadius: "30px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 500,
    flexBasis: "20%",
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    textTransform: "capitalize"
  },
  removeBtn: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "50%",
    marginLeft: "20px"
  },
  btnsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
    paddingTop: "15px",
    marginBottom: "5px",
    "& button": {
      border: "1px solid #E2E2E2",
      borderRadius: 5,
      color: "#000000",
      padding: "10px 20px"
    }
  },
  iconContainer: {
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  modifyIconContainer: {
    width: 25,
    height: 25
  }
};
export default customStyle;
