export const ADD_ITEM = "add notification";
export const DELETE_ITEM = "delete notification";

export const addItem = item => dispatch =>
  dispatch({
    type: ADD_ITEM,
    payload: { item }
  });

export const deleteItem = id => dispatch =>
  dispatch({
    type: DELETE_ITEM,
    payload: { id }
  });
