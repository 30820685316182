import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useRef,
  useCallback
} from "react";
import { v4 as uuidv4 } from "uuid";

import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  fetchProjectList,
  restProjectState
} from "../../../redux/action/WaslaRewards/UsersLogsActions";
import { resetBatchState } from "../../../redux/action/WaslaRewards/BatchesAction";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CreateProject from "./CreateProject";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./styles.css";
import CustomizedSnackBars from "../SnackBar/SnackBar";
import BalanceDetails from "../BalanceDetails";

const ProjectCard = lazy(() => import("./projectCard"));

const useStyles = makeStyles(styles);

const HomePage = React.memo(props => {
  let location = useLocation();

  const {
    availableError,
    fetchProjectList,
    projectList,
    isLoading,
    hasNext,
    resetBatchState,
    serverError
  } = props;

  if (availableError) {
    throw new Error();
  }

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [list, setList] = useState([]);
  const loader = useRef(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (projectList.length !== 0) {
      setList(projectList);
      setIsFetching(false);
    }
  }, [projectList]);

  useEffect(() => {
    if (projectList.length === 0) {
      fetchProjectList(pageCount);
    }
    resetBatchState();
  }, []);

  const handleSeeMore = () => {
    if (hasNext) {
      setPageCount(pageCount + 1);
      setIsFetching(true);
    }
  };

  useEffect(() => {
    if (!isFetching) return;
    fetchProjectList(pageCount);
  }, [isFetching, pageCount]);

  // implement infinite scrolling with intersection observer
  const scrollObserver = useCallback(
    node => {
      new IntersectionObserver(entries => {
        entries.forEach(en => {
          if (en.intersectionRatio > 0) {
            setIsFetching(true);
          }
        });
      }).observe(node);
    },
    [setPageCount, setIsFetching]
  );
  useEffect(() => {
    if (loader.current && hasNext && !isFetching) {
      setPageCount(prevCount => ++prevCount);
      scrollObserver(loader.current);
    }
  }, [scrollObserver, loader, hasNext, isFetching]);

  return (
    <Grid container className={classes.parent} spacing={2}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={2}
        style={{ padding: "8px" }}
      >
        <Grid item>
          <h1 className={classes.title}>Projects</h1>
        </Grid>
        <Grid item>
          <Link
            to={{
              pathname: `${location.pathname}/allTransaction`
            }}
          >
            <Button
              variant="contained"
              size="medium"
              className={classes.btnAllTransactions}
            >
              All Transactions
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BalanceDetails />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <div className={classes.paper}>
              <Button type="button" onClick={handleOpenModal}>
                <span>Create Project</span>
                <span>+</span>
              </Button>
            </div>
          </Grid>

          {list.length !== 0 && (
            <>
              {list.map(item => (
                <Suspense
                  key={uuidv4()}
                  fallback={
                    <Grid item>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        className={classes.paper}
                      />
                    </Grid>
                  }
                >
                  <ProjectCard cardItem={item} />
                </Suspense>
              ))}
            </>
          )}

          {isLoading && (
            <Grid item aria-label="loading">
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.paper}
              />
            </Grid>
          )}
        </Grid>
        {hasNext && (
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", visibility: "hidden" }}
            ref={loader}
          >
            <Button
              onClick={handleSeeMore}
              type="button"
              aria-label="see more"
              variant="contained"
              size="medium"
              className={classes.btnAllTransactions}
              style={{ margin: "10px 0" }}
            >
              See More
            </Button>
          </Grid>
        )}
      </Grid>
      {openModal && (
        <CreateProject open={openModal} closeModal={handleCloseModal} />
      )}
      {!!serverError && (
        <CustomizedSnackBars
          severity={"error"}
          messege={serverError}
          errorMsg={!!serverError}
        />
      )}
    </Grid>
  );
});

const mapStateToProps = state => {
  return {
    availableError: state.availableError.availableError,
    projectList: state.project.list,
    isLoading: state.project.isLoading,
    hasNext: state.project.hasNext,
    serverError: state.project.serverError
  };
};

export default connect(
  mapStateToProps,
  { fetchProjectList, resetBatchState, restProjectState }
)(HomePage);

HomePage.propTypes = {
  availableError: PropTypes.bool,
  projectList: PropTypes.arrayOf(PropTypes.object),
  fetchProjectList: PropTypes.func,
  resetBatchState: PropTypes.func,
  restProjectState: PropTypes.func,
  isLoading: PropTypes.bool,
  hasNext: PropTypes.bool,
  serverError: PropTypes.string
};
