import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, generatePath } from "react-router-dom";

import withReducer from "../../../store/withReducer";

import WaslaTheme from "../../../modules/wasla-theme";

import * as pointsService from "../../../services/points";

import userReducers from "../../../main/users/user/reducers";

import { updateSummary } from "../../../main/users/user/actions/summary";
import {
  updateItems as updateActivitiesItems,
  updateItem as updateActivitiesItem
} from "../../../main/users/user/actions/activities/list";
import {
  updatePage as updateActivitiesPage,
  updateLimit as updateActivitiesLimit,
  updateTotal as updateActivitiesTotal
} from "../../../main/users/user/actions/activities/pagination";

import configurations from "../../../configurations";

import { usersPath } from "../paths";
import { activityPath } from "./activity/paths";

import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";

import Paper from "@mui/material/Paper";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";

import Avatar from "@mui/material/Avatar";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Loader from "../../../components/loader";

import Pagination from "../../../components/Pagination";

import avatar from "./assets/avatar.svg";

const ActivityRecord = props => {
  const { item } = props;

  const history = useHistory();
  let { id } = useParams();

  const sourceTypeCheck =
    item.sourceType === "cashback" || item.sourceType === "cs";

  const handleRowClick = e => {
    if (sourceTypeCheck)
      history.push(
        [
          configurations.dashboard.basePath,
          generatePath(activityPath, {
            id,
            activityId: item.activityId
          })
        ].join("")
      );
  };

  return (
    <TableRow
      onClick={handleRowClick}
      hover={sourceTypeCheck}
      sx={{
        ...(() => (sourceTypeCheck ? { cursor: "pointer" } : {}))()
      }}
    >
      <TableCell padding="checkbox">
        <Avatar
          alt={item.id}
          src={item.iconUrl || avatar}
          sx={{ width: 56, height: 56 }}
        />
      </TableCell>
      <TableCell padding="checkbox">{item.titleEn}</TableCell>
      <TableCell padding="checkbox">{item.titleAr}</TableCell>
      <TableCell
        padding="checkbox"
        sx={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          textTransform: "capitalize",
          ...(type => {
            switch (type) {
              case "declined":
                return {
                  color: "#D92D20"
                };
              case "pending":
                return {
                  color: "#667085"
                };
              case "earned":
                return {
                  color: "#DAAC22"
                };
              case "deducted":
                return {
                  color: "#D92D20"
                };
              case "rewarded":
                return {
                  color: "#667085"
                };
              default:
                return {};
            }
          })(item.status)
        }}
      >
        {item.status === "deducted"
          ? "Points Removed"
          : item.status === "rewarded"
          ? "Points Added"
          : `${item.sourceType} ${item.status}`}
        {item.additionalInfo.reasonEn && ` - ${item.additionalInfo.reasonEn}`}
      </TableCell>
      <TableCell padding="checkbox">
        {item.pointsAmount.toLocaleString("en-US", {})} PTS
      </TableCell>
      <TableCell padding="checkbox">
        {item.moneyAmount.toLocaleString("en-US", {})} {item.currency}
      </TableCell>
    </TableRow>
  );
};

const User = props => {
  const {
    user: {
      summary,
      activities: {
        list: { items: activitiesItems },
        pagination: {
          page: activitiesPage,
          limit: activitiesLimit,
          total: activitiesTotal
        }
      }
    },
    updateSummary,
    updateActivitiesItems,
    updateActivitiesPage,
    updateActivitiesLimit,
    updateActivitiesTotal
  } = props;

  const [summaryLoading, setSummaryLoading] = useState(true);
  const [activitiesLoading, setActivitiesLoading] = useState(true);

  let { id } = useParams();

  useEffect(async () => {
    setSummaryLoading(true);
    const res = await pointsService.getUserSummary(id).catch(error => ({}));
    updateSummary(res);
    setSummaryLoading(false);
  }, []);

  useEffect(() => {
    updateActivitiesPage(1);
  }, [activitiesLimit]);

  useEffect(async () => {
    setActivitiesLoading(true);
    const res = await pointsService
      .getUserActivities(id, activitiesPage, activitiesLimit)
      .catch(error => ({ data: [], meta: { total: 0 } }));
    updateActivitiesItems(res.data);
    updateActivitiesTotal(res.meta.total);
    setActivitiesLoading(false);
  }, [activitiesPage, activitiesLimit]);

  const handleActivitiesPageClick = (e, page) => {
    updateActivitiesPage(page);
  };

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={configurations.dashboard.basePath}
          >
            CS Dashboard
          </Link>
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, usersPath].join("")}
          >
            CS Table
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            History
          </Typography>
        </Breadcrumbs>
        {summaryLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Stack
                spacing={1}
                justifyContent="center"
                alignItems="start"
                sx={{
                  padding: "8px 20px",
                  borderRadius: 6,
                  border: "2px solid #F9FAFB",
                  height: "100%"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#555555"
                  }}
                >
                  Total Balance
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    padding: "4px 12px",
                    borderRadius: 8,
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: "20px",
                    backgroundColor: "#FFEFBF",
                    color: "#DAAC22"
                  }}
                >
                  {summary?.ActivePoints?.toLocaleString("en-US", {})}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack
                spacing={1}
                justifyContent="center"
                alignItems="start"
                sx={{
                  padding: "12px 24px",
                  borderRadius: 6,
                  backgroundColor: "#F9FAFB"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#555555"
                  }}
                >
                  Pending
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: "28px",
                    color: "#9847F2"
                  }}
                >
                  {summary.PointsSummery &&
                    summary.PointsSummery[2].pointsValue.toLocaleString(
                      "en-US",
                      {}
                    )}{" "}
                  Pts
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#6B6B6B"
                  }}
                >
                  {summary.PointsSummery &&
                    summary.PointsSummery[2].moneyValue.toLocaleString(
                      "en-US",
                      {}
                    )}{" "}
                  {summary.PointsSummery && summary.PointsSummery[2].unit}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack
                spacing={1}
                justifyContent="center"
                alignItems="start"
                sx={{
                  padding: "12px 24px",
                  borderRadius: 6,
                  backgroundColor: "#F9FAFB"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#555555"
                  }}
                >
                  Earned
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: "28px",
                    color: "#9847F2"
                  }}
                >
                  {summary.PointsSummery &&
                    summary.PointsSummery[0].pointsValue.toLocaleString(
                      "en-US",
                      {}
                    )}{" "}
                  Pts
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#6B6B6B"
                  }}
                >
                  {summary.PointsSummery &&
                    summary.PointsSummery[0].moneyValue.toLocaleString(
                      "en-US",
                      {}
                    )}{" "}
                  {summary.PointsSummery && summary.PointsSummery[0].unit}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack
                spacing={1}
                justifyContent="center"
                alignItems="start"
                sx={{
                  padding: "12px 24px",
                  borderRadius: 6,
                  backgroundColor: "#F9FAFB"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#555555"
                  }}
                >
                  Spent
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: "28px",
                    color: "#9847F2"
                  }}
                >
                  {summary.PointsSummery &&
                    summary.PointsSummery[1].pointsValue.toLocaleString(
                      "en-US",
                      {}
                    )}{" "}
                  Pts
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#6B6B6B"
                  }}
                >
                  {summary.PointsSummery &&
                    summary.PointsSummery[1].moneyValue.toLocaleString(
                      "en-US",
                      {}
                    )}{" "}
                  {summary.PointsSummery && summary.PointsSummery[1].unit}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack
                spacing={1}
                justifyContent="center"
                alignItems="start"
                sx={{
                  padding: "12px 24px",
                  borderRadius: 6,
                  backgroundColor: "#F9FAFB"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#555555"
                  }}
                >
                  Declined
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: "28px",
                    color: "#9847F2"
                  }}
                >
                  {summary.PointsSummery &&
                    summary.PointsSummery[3].pointsValue.toLocaleString(
                      "en-US",
                      {}
                    )}{" "}
                  Pts
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#6B6B6B"
                  }}
                >
                  {summary.PointsSummery &&
                    summary.PointsSummery[3].moneyValue.toLocaleString(
                      "en-US",
                      {}
                    )}{" "}
                  {summary.PointsSummery && summary.PointsSummery[3].unit}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
        {activitiesLoading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="inherit">History</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activitiesItems.length ? (
                  <TableRow>
                    <TableCell component="th">Logo</TableCell>
                    <TableCell component="th">Name - English</TableCell>
                    <TableCell component="th">Name - Arabic</TableCell>
                    <TableCell component="th">Status</TableCell>
                    <TableCell component="th">Points</TableCell>
                    <TableCell component="th">Amount in EGP</TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
                {activitiesItems.length ? (
                  activitiesItems.map(item => (
                    <ActivityRecord key={item.id} item={item} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Stack
                        sx={{ minHeight: "240px" }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "30px",
                            color: "#2B2B2B"
                          }}
                        >
                          “No History”
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Pagination
                      page={activitiesPage}
                      limit={activitiesLimit}
                      total={activitiesTotal}
                      rowsPerPageOptions={[]}
                      handlePageClick={handleActivitiesPageClick}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </WaslaTheme>
  );
};

export default withReducer("user", userReducers)(
  connect(
    state => ({ user: state.user }),
    {
      updateSummary,
      updateActivitiesItems,
      updateActivitiesItem,
      updateActivitiesPage,
      updateActivitiesLimit,
      updateActivitiesTotal
    }
  )(User)
);
