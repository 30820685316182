import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import withShield from "../with-shield";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { CSVLink } from "react-csv";

import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap-daterangepicker/daterangepicker.css";

import Loader from "../../../components/loader";
import SectionHead from "../../../components/section-head";
import Pagination from "../../../components/Pagination";

import RedemptionHead from "../../../main/redemption/views/redemption-head";
import RedemptionNavigator from "../../../main/redemption/views/redemption-navigator";

import configurations from "../../../configurations";

import RedemptionActions from "../../../main/redemption/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserRequest = connect(
  state => ({
    redemption: state.redemption
  }),
  {
    updateUserRequestStatus: RedemptionActions.userRequest.status.update,
    addUserRequestExporting:
      RedemptionActions.layout.userRequests.userRequest.exporting.add,
    removeUserRequestExporting:
      RedemptionActions.layout.userRequests.userRequest.exporting.remove
  }
)(props => {
  const {
    redemption,
    userRequest,
    updateUserRequestStatus,
    addUserRequestExporting,
    removeUserRequestExporting
  } = props;
  const [confirmReject, setConfirmReject] = useState(false);
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [approveMessage, setApproveMessage] = useState("");
  const [sync, setSync] = useState(false);

  const userRequestsExporting = redemption.layout.userRequests.exporting;
  const userRequestsExportingIds = userRequestsExporting.map(
    userRequestExporting => userRequestExporting.id
  );
  const date = new Date(userRequest.creationTimestamp);
  const userRequestInputsNames = userRequest.inputs.map(input => input.name);

  const handleRejectClick = e => {
    setConfirmReject(true);
  };

  const handleConfirmRejectClose = e => {
    setConfirmReject(false);
  };

  const handleConfirmRejectCancel = e => {
    setConfirmReject(false);
  };

  const handleConfirmRejectApprove = e => {
    setSync(true);
    updateUserRequestStatus(userRequest.id, { status: "DECLINED" })
      .then(data => {
        setSync(false);
        setConfirmReject(false);
        // window.analytics.track("Redemption Declined", {
        //   redemption_category: userRequest.redemptionOption.category.name,
        //   redemption_points:
        //     userRequest.redemptionOption.selectedDenomination.pointsValue,
        //   redemption_currency: userRequest.redemptionOption.currency,
        //   redemption_type: userRequest.redemptionOption.type,
        //   redemption_provider: userRequest.redemptionOption.provider.name,
        //   redemption_name: userRequest.redemptionOption.name,
        //   redemption_cost:
        //     userRequest.redemptionOption.currency === "USD"
        //       ? userRequest.redemptionOption.selectedDenomination.moneyValue
        //       : userRequest.redemptionOption.selectedDenomination
        //           .localMoneyValue,
        //   redemption_productID: userRequest.redemptionOption.productId,
        //   redemption_pointbalance: userRequest.user.pointsBalance
        // });
      })
      .catch(error => {
        setSync(false);
      });
  };

  const handleApproveClick = e => {
    setConfirmApprove(true);
  };

  const handleConfirmApproveClose = e => {
    setConfirmApprove(false);
  };

  const handleConfirmApproveCancel = e => {
    setConfirmApprove(false);
  };

  const handleConfirmApproveApprove = e => {
    setSync(true);
    updateUserRequestStatus(userRequest.id, {
      status: "REDEEMED",
      redemptionValue: approveMessage
    })
      .then(data => {
        setSync(false);
        setConfirmApprove(false);
        // window.analytics.track("Redemption Redeemed", {
        //   redemption_category: userRequest.redemptionOption.category.name,
        //   redemption_points:
        //     userRequest.redemptionOption.selectedDenomination.pointsValue,
        //   redemption_currency: userRequest.redemptionOption.currency,
        //   redemption_type: userRequest.redemptionOption.type,
        //   redemption_provider: userRequest.redemptionOption.provider.name,
        //   redemption_name: userRequest.redemptionOption.name,
        //   redemption_cost:
        //     userRequest.redemptionOption.currency === "USD"
        //       ? userRequest.redemptionOption.selectedDenomination.moneyValue
        //       : userRequest.redemptionOption.selectedDenomination
        //           .localMoneyValue,
        //   redemption_productID: userRequest.redemptionOption.productId,
        //   redemption_pointbalance: userRequest.user.pointsBalance
        // });
      })
      .catch(error => {
        setSync(false);
      });
  };

  const handleApproveMessageChange = e => {
    setApproveMessage(e.target.value);
  };

  const handleUserRequestExportChange = e => {
    if (e.target.checked) {
      addUserRequestExporting(userRequest);
    } else {
      removeUserRequestExporting(userRequest.id);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Checkbox
            checked={Boolean(
              userRequestsExportingIds.find(
                userRequestExporting => userRequestExporting === userRequest.id
              )
            )}
            onChange={handleUserRequestExportChange}
          />
        </TableCell>
        <TableCell>
          {`${date.toLocaleString("default", {
            month: "short"
          })} ${date.getDate()}, ${date.getFullYear()}`}
        </TableCell>
        <TableCell>{userRequest.user.id}</TableCell>
        <TableCell>{userRequest.user.userName}</TableCell>
        <TableCell>{userRequest.user.pointsBalance}</TableCell>
        <TableCell>{userRequest.redemptionOption.productId}</TableCell>
        <TableCell>{userRequest.redemptionOption.provider.name}</TableCell>
        <TableCell>{userRequest.redemptionOption.type}</TableCell>
        <TableCell>{userRequest.redemptionOption.titleEn}</TableCell>
        <TableCell>{userRequest.redemptionOption.currency}</TableCell>
        <TableCell>
          {userRequest.redemptionOption.currency === "USD"
            ? userRequest.redemptionOption.selectedDenomination.moneyValue
            : userRequest.redemptionOption.selectedDenomination.localMoneyValue}
        </TableCell>
        <TableCell>
          {userRequest.redemptionOption.selectedDenomination.pointsValue}
        </TableCell>
        <TableCell>
          <Typography
            sx={{
              backgroundColor: "#FFF8E2",
              color: "#DAAC22",
              py: "2px",
              px: "16px",
              borderRadius: 8,
              display: "inline-block",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "9pt"
            }}
          >
            {userRequest.redemptionOption.selectedDenomination.pointsValue}pts (
            {userRequest.redemptionOption.currency === "USD"
              ? userRequest.redemptionOption.selectedDenomination.moneyValue
              : userRequest.redemptionOption.selectedDenomination
                  .localMoneyValue}
            {userRequest.redemptionOption.currency})
          </Typography>
        </TableCell>
        {redemption.configurations.inputs.map(input =>
          userRequestInputsNames.indexOf(input.name) !== -1 ? (
            <TableCell key={input.id}>
              {
                userRequest.inputs.find(
                  userRequestInput => userRequestInput.name === input.name
                ).value
              }
            </TableCell>
          ) : (
            <TableCell key={input.id}>-</TableCell>
          )
        )}
        <TableCell>
          <Typography
            sx={{
              ...(status => {
                switch (status) {
                  case "REDEEMED":
                    return { backgroundColor: "#ECFDF3", color: "#027A48" };
                  case "DECLINED":
                    return { backgroundColor: "#FEF3F2", color: "#B42318" };
                  case "PENDING":
                    return { backgroundColor: "#F2F4F7", color: "#344054" };
                  default:
                    return { backgroundColor: "#F2F4F7", color: "#344054" };
                }
              })(userRequest.status),
              py: "2px",
              px: "16px",
              borderRadius: 8,
              display: "flex",
              gap: "4px",
              alignItems: "center",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "9pt",
              "&::before": {
                content: "''",
                display: "inline-block",
                width: 8,
                height: 8,
                borderRadius: "50%",
                ...(status => {
                  switch (status) {
                    case "REDEEMED":
                      return { backgroundColor: "#027A48" };
                    case "DECLINED":
                      return { backgroundColor: "#B42318" };
                    case "PENDING":
                      return { backgroundColor: "#344054" };
                    default:
                      return { backgroundColor: "#344054" };
                  }
                })(userRequest.status)
              }
            }}
          >
            {userRequest.status}
          </Typography>
        </TableCell>
        <TableCell>
          {userRequest.adminAction.adminUser.username !== null
            ? userRequest.adminAction.adminUser.username
            : "-"}
        </TableCell>
        <TableCell>
          {userRequest.adminAction.dateTaken !== null ? (
            <>
              {(() => {
                const date = new Date(userRequest.adminAction.dateTaken);
                return `${date.getHours() % 12}:${date.getMinutes()}${
                  date.getHours() < 12 ? "AM" : "PM"
                } ${date.toLocaleString("default", {
                  month: "short"
                })} ${date.getDate()}, ${date.getFullYear()}`;
              })()}
            </>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row">
            <IconButton
              aria-label="reject"
              onClick={handleRejectClick}
              disabled={
                userRequest.status === "REDEEMED" ||
                userRequest.status === "DECLINED"
              }
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              aria-label="approve"
              onClick={handleApproveClick}
              disabled={
                userRequest.status === "REDEEMED" ||
                userRequest.status === "DECLINED"
              }
            >
              <CheckCircleOutlineIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <Dialog
        open={confirmReject}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleConfirmRejectClose}
        aria-describedby={`reject-${userRequest.id}`}
      >
        <DialogTitle>
          <Stack spacing={2}>
            <Box>
              <IconButton
                sx={{
                  backgroundColor: "#FEE4E2",
                  border: "8px solid #FEF3F2",
                  "&:hover": { backgroundColor: "#FEE4E2" }
                }}
              >
                <ErrorOutlineOutlinedIcon color="error" />
              </IconButton>
            </Box>
            <Typography>Rejection confirmation</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={`reject-${userRequest.id}`}>
            You are about to reject a redemption request
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            spacing={2}
            justifyContent="stretch"
          >
            <Button
              onClick={handleConfirmRejectCancel}
              variant="outlined"
              sx={{ width: "100%" }}
              disabled={sync}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmRejectApprove}
              variant="contained"
              color="error"
              sx={{ width: "100%" }}
              disabled={sync}
            >
              Reject
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmApprove}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleConfirmApproveClose}
        aria-describedby={`approve-${userRequest.id}`}
      >
        <DialogTitle>
          <Stack spacing={2}>
            <Box>
              <IconButton
                sx={{
                  backgroundColor: "#D1FADF",
                  border: "8px solid #ECFDF3",
                  "&:hover": { backgroundColor: "#D1FADF" }
                }}
              >
                <CheckCircleOutlineIcon color="success" />
              </IconButton>
            </Box>
            <Typography>If applicable, enter promo code or URL</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <DialogContentText id={`approve-${userRequest.id}`}>
              Click approve if no URL or promo code is needed
            </DialogContentText>
            <FormControl>
              <InputLabel
                shrink
                htmlFor="name-en"
                sx={{ transform: "none", color: "#344054" }}
              >
                URL or Promo Code
              </InputLabel>
              <OutlinedInput
                sx={{ mt: 4 }}
                id={`approve-${userRequest.id}-message`}
                value={approveMessage}
                onChange={handleApproveMessageChange}
                label=""
                disabled={sync}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            spacing={2}
            justifyContent="stretch"
          >
            <Button
              onClick={handleConfirmApproveCancel}
              variant="outlined"
              sx={{ width: "100%" }}
              disabled={sync}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmApproveApprove}
              variant="contained"
              sx={{ width: "100%" }}
              disabled={sync}
            >
              Approve
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
});

const UserRequests = props => {
  const {
    redemption,
    getConfigurations,
    getUserRequests,
    initUserRequestsPagination,
    changeUserRequestsPage,
    updateUserRequestsQuery,
    updateUserRequestsDate,
    updateUserRequestsProviders,
    updateUserRequestsCategories,
    updateUserRequestsTypes,
    updateUserRequestsCurrencies,
    updateUserRequestsOrderBy,
    syncUserRequests,
    addUserRequestsExporting,
    removeUserRequestsExporting,
    emptyUserRequestsExporting
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const userRequests = redemption.userRequests.data;
  const userRequestsIds = userRequests.map(userRequest => userRequest.id);
  const paginationPage = redemption.layout.userRequests.pagination.page;
  const paginationSize = redemption.layout.userRequests.pagination.size;
  const paginationPages = paginationSize
    ? Math.ceil(redemption.userRequests.meta.total / paginationSize)
    : 0;
  const userRequestsQuery = redemption.layout.userRequests.query;
  const userRequestsDate = redemption.layout.userRequests.date;
  const userRequestsProviders = redemption.layout.userRequests.providers;
  const userRequestsCategories = redemption.layout.userRequests.categories;
  const userRequestsTypes = redemption.layout.userRequests.types;
  const userRequestsCurrencies = redemption.layout.userRequests.currencies;
  const userRequestsOrderBy = redemption.layout.userRequests.orderBy;
  const userRequestsSyncing = redemption.layout.userRequests.syncing;
  const userRequestsExporting = redemption.layout.userRequests.exporting;
  const userRequestsExportingIds = userRequestsExporting.map(
    userRequestExporting => userRequestExporting.id
  );
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const syncingUserRequests = () => {
    syncUserRequests(true);
    getUserRequests(
      redemption.country,
      paginationPage,
      paginationSize,
      userRequestsQuery,
      userRequestsDate.from
        ? new Date(`${userRequestsDate.from} UTC`).toISOString()
        : "",
      userRequestsDate.to
        ? new Date(
            new Date(`${userRequestsDate.to} UTC`).getTime() + 86399999
          ).toISOString()
        : "",
      redemption.configurations.providers.find(
        provider => provider.id === userRequestsProviders[0]
      )?.name || "",
      redemption.configurations.categories.find(
        category => category.id === userRequestsCategories[0]
      )?.name || "",
      redemption.configurations.types.find(
        type => type.id === userRequestsTypes[0]
      )?.name || "",
      userRequestsCurrencies[0] || "",
      userRequestsOrderBy
    ).then(data => {
      syncUserRequests(false);
    });
  };

  const handlePageClick = (e, page) => {
    changeUserRequestsPage(page);
  };

  const handleUserRequestsQueryChange = e => {
    updateUserRequestsQuery(e.target.value);
  };

  const handleUserRequestsDateChange = (e, picker) => {
    updateUserRequestsDate(
      `${picker.startDate.get("month") + 1}/${picker.startDate.get(
        "date"
      )}/${picker.startDate.get("year")}`,
      `${picker.endDate.get("month") + 1}/${picker.endDate.get(
        "date"
      )}/${picker.endDate.get("year")}`
    );
  };

  const handleUserRequestsDateCancel = (event, picker) => {
    updateUserRequestsDate("", "");
  };

  const handleUserRequestsProviderChange = e => {
    updateUserRequestsProviders(e.target.checked ? [e.target.value] : []);
  };

  const handleUserRequestsCategoryChange = e => {
    updateUserRequestsCategories(e.target.checked ? [e.target.value] : []);
  };

  const handleUserRequestsTypesChange = e => {
    updateUserRequestsTypes(e.target.checked ? [e.target.value] : []);
  };

  const handleUserRequestsCurrenciesChange = e => {
    updateUserRequestsCurrencies(e.target.checked ? [e.target.value] : []);
  };

  const handleUserRequestsOrderByClick = orderBy => e => {
    updateUserRequestsOrderBy(orderBy);
  };

  const handleUserRequestsExportChange = e => {
    if (e.target.checked) {
      addUserRequestsExporting(userRequests);
    } else {
      removeUserRequestsExporting(userRequestsIds);
    }
  };

  useEffect(() => {
    initUserRequestsPagination(10);
    getConfigurations(redemption.country);
    return () => {
      changeUserRequestsPage(0);
      updateUserRequestsQuery("");
      updateUserRequestsDate("", "");
      updateUserRequestsProviders([]);
      updateUserRequestsCategories([]);
      updateUserRequestsTypes([]);
      updateUserRequestsCurrencies([]);
      updateUserRequestsOrderBy("-adminAction.dateTaken,creationTimestamp");
    };
  }, []);

  useEffect(() => {
    if (paginationPage && paginationSize) syncingUserRequests();
  }, [paginationPage]);

  useEffect(() => {
    emptyUserRequestsExporting();
    if (paginationPage === 1) {
      syncingUserRequests();
    } else {
      changeUserRequestsPage(1);
    }
  }, [
    userRequestsQuery,
    userRequestsDate.from,
    userRequestsDate.to,
    userRequestsProviders,
    userRequestsCategories,
    userRequestsTypes,
    userRequestsCurrencies,
    userRequestsOrderBy
  ]);

  useEffect(() => {
    if (paginationPage > paginationPages)
      changeUserRequestsPage(paginationPages);
  }, [redemption.userRequests.meta.total]);

  return (
    <Stack spacing={2}>
      <RedemptionHead />
      <RedemptionNavigator value="user-requests" />
      <Stack
        spacing={4}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SectionHead
          title="Requests"
          description="Easily manage incoming requestes and process redemptions"
        />
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          sx={{ textTransform: "capitalize" }}
          disabled={!userRequestsExporting.length}
          color="secondary"
        >
          <CSVLink
            filename={"user-requests.csv"}
            headers={[
              "Request Date",
              "User ID",
              "Mobile Number",
              "Pt Balance",
              "Product ID",
              "Provider",
              "Type",
              "Name (EN)",
              "Currency",
              `Cost (${
                configurations.application.countries.find(
                  country => country.code === redemption.country
                ).currency
              })`,
              "Cost (Pts)",
              "Denomination",
              ...redemption.configurations.inputs.map(
                input => input.displayNameEn
              ),
              "Status",
              "Executed by",
              "Executed at"
            ]}
            data={userRequestsExporting.map(userRequestExporting => {
              const date = new Date(userRequestExporting.creationTimestamp);
              const userRequestInputsNames = userRequestExporting.inputs.map(
                input => input.name
              );
              return [
                `${date.toLocaleString("default", {
                  month: "short"
                })} ${date.getDate()}, ${date.getFullYear()}`,
                userRequestExporting.user.id,
                userRequestExporting.user.userName,
                userRequestExporting.user.pointsBalance,
                userRequestExporting.redemptionOption.productId,
                userRequestExporting.redemptionOption.provider.name,
                userRequestExporting.redemptionOption.type,
                userRequestExporting.redemptionOption.titleEn,
                userRequestExporting.redemptionOption.currency,
                userRequestExporting.redemptionOption.currency === "USD"
                  ? userRequestExporting.redemptionOption.selectedDenomination
                      .moneyValue
                  : userRequestExporting.redemptionOption.selectedDenomination
                      .localMoneyValue,
                userRequestExporting.redemptionOption.selectedDenomination
                  .pointsValue,
                `${
                  userRequestExporting.redemptionOption.selectedDenomination
                    .pointsValue
                }pts (
                  ${
                    userRequestExporting.redemptionOption.currency === "USD"
                      ? userRequestExporting.redemptionOption
                          .selectedDenomination.moneyValue
                      : userRequestExporting.redemptionOption
                          .selectedDenomination.localMoneyValue
                  }
                  ${userRequestExporting.redemptionOption.currency})`,
                ...redemption.configurations.inputs.map(input =>
                  userRequestInputsNames.indexOf(input.name) !== -1
                    ? userRequestExporting.inputs.find(
                        userRequestInput => userRequestInput.name === input.name
                      ).value
                    : "-"
                ),
                userRequestExporting.status,
                userRequestExporting.adminAction.adminUser.username !== null
                  ? userRequestExporting.adminAction.adminUser.username
                  : "-",
                userRequestExporting.adminAction.dateTaken !== null
                  ? (() => {
                      const date = new Date(
                        userRequestExporting.adminAction.dateTaken
                      );
                      return `${date.getHours() % 12}:${date.getMinutes()}${
                        date.getHours() < 12 ? "AM" : "PM"
                      } ${date.toLocaleString("default", {
                        month: "short"
                      })} ${date.getDate()}, ${date.getFullYear()}`;
                    })()
                  : "-"
              ];
            })}
            style={{ color: "inherit" }}
          >
            Download CSV
          </CSVLink>
        </Button>
      </Stack>
      <Divider />
      <Stack
        spacing={4}
        direction="row"
        justifyContent="space-between"
        sx={{ backgroundColor: "#F9FAFB", py: "12Px", px: 2, borderRadius: 4 }}
      >
        <TextField
          id="input-with-icon-textfield"
          label=""
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          sx={{ minWidth: "560px", backgroundColor: "white" }}
          value={userRequestsQuery}
          placeholder="Search by user ID, mobile number, product ID, name, or receiver info"
          onChange={handleUserRequestsQueryChange}
        />
        <Stack spacing={1} direction="row">
          <DateRangePicker
            initialSettings={
              userRequestsDate.from && userRequestsDate.to
                ? {
                    startDate: userRequestsDate.from,
                    endDate: userRequestsDate.to
                  }
                : {}
            }
            onApply={handleUserRequestsDateChange}
            onCancel={handleUserRequestsDateCancel}
          >
            <TextField
              label=""
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon />
                  </InputAdornment>
                )
              }}
              sx={{ minWidth: "240px", backgroundColor: "white" }}
              value={
                userRequestsDate.from && userRequestsDate.to
                  ? `${userRequestsDate.from} - ${userRequestsDate.to}`
                  : ""
              }
            />
          </DateRangePicker>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={handleClick}
            startIcon={<FilterListIcon />}
          >
            Filters
          </Button>
        </Stack>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              maxHeight: 48 * 9
            }
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <MenuItem disableRipple>Provider</MenuItem>
          {redemption.configurations.providers.map(provider => (
            <MenuItem key={provider.id}>
              <FormControlLabel
                label={provider.name}
                control={
                  <Checkbox
                    checked={userRequestsProviders.indexOf(provider.id) !== -1}
                    onChange={handleUserRequestsProviderChange}
                    value={provider.id}
                  />
                }
                sx={{ color: "#000" }}
              />
            </MenuItem>
          ))}
          <Divider sx={{ my: 0.5 }} />
          <MenuItem disableRipple>Category</MenuItem>
          {redemption.configurations.categories.map(category => (
            <MenuItem key={category.id}>
              <FormControlLabel
                label={category.name}
                control={
                  <Checkbox
                    checked={userRequestsCategories.indexOf(category.id) !== -1}
                    onChange={handleUserRequestsCategoryChange}
                    value={category.id}
                  />
                }
                sx={{ color: "#000" }}
              />
            </MenuItem>
          ))}
          <Divider sx={{ my: 0.5 }} />
          <MenuItem disableRipple>Type</MenuItem>
          {redemption.configurations.types.map(type => (
            <MenuItem key={type.id}>
              <FormControlLabel
                label={type.name}
                control={
                  <Checkbox
                    checked={userRequestsTypes.indexOf(type.id) !== -1}
                    onChange={handleUserRequestsTypesChange}
                    value={type.id}
                  />
                }
                sx={{ color: "#000" }}
              />
            </MenuItem>
          ))}
          <Divider sx={{ my: 0.5 }} />
          <MenuItem disableRipple>Currency</MenuItem>
          <MenuItem>
            <FormControlLabel
              label="USD"
              control={
                <Checkbox
                  checked={userRequestsCurrencies.indexOf("USD") !== -1}
                  onChange={handleUserRequestsCurrenciesChange}
                  value="USD"
                />
              }
              sx={{ color: "#000" }}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              label={
                configurations.application.countries.find(
                  country => country.code === redemption.country
                ).currency
              }
              control={
                <Checkbox
                  checked={
                    userRequestsCurrencies.indexOf(
                      configurations.application.countries.find(
                        country => country.code === redemption.country
                      ).currency
                    ) !== -1
                  }
                  onChange={handleUserRequestsCurrenciesChange}
                  value={
                    configurations.application.countries.find(
                      country => country.code === redemption.country
                    ).currency
                  }
                />
              }
              sx={{ color: "#000" }}
            />
          </MenuItem>
        </Menu>
      </Stack>
      {redemption.layout.userRequests.loaded ? (
        <Card variant="outlined">
          <CardHeader title="All Requests" />
          <Divider />
          <CardContent sx={{ p: 0 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {userRequests && userRequests.length ? (
                        <Checkbox
                          checked={userRequestsIds.every(
                            userRequestId =>
                              userRequestsExportingIds.indexOf(
                                userRequestId
                              ) !== -1
                          )}
                          onChange={handleUserRequestsExportChange}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell
                      sortDirection={
                        userRequestsOrderBy ===
                        "creationTimestamp,adminAction.dateTaken"
                          ? "asc"
                          : userRequestsOrderBy ===
                            "-creationTimestamp,adminAction.dateTaken"
                          ? "desc"
                          : false
                      }
                      onClick={handleUserRequestsOrderByClick(
                        userRequestsOrderBy ===
                          "-creationTimestamp,adminAction.dateTaken"
                          ? "creationTimestamp,adminAction.dateTaken"
                          : "-creationTimestamp,adminAction.dateTaken"
                      )}
                    >
                      {userRequestsOrderBy ===
                        "creationTimestamp,adminAction.dateTaken" ||
                      userRequestsOrderBy ===
                        "-creationTimestamp,adminAction.dateTaken" ? (
                        <TableSortLabel
                          active={true}
                          direction={
                            userRequestsOrderBy ===
                            "creationTimestamp,adminAction.dateTaken"
                              ? "asc"
                              : userRequestsOrderBy ===
                                "-creationTimestamp,adminAction.dateTaken"
                              ? "desc"
                              : ""
                          }
                        >
                          Request Date
                        </TableSortLabel>
                      ) : (
                        <TableSortLabel>Request Date</TableSortLabel>
                      )}
                    </TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Pt Balance</TableCell>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Name (EN)</TableCell>
                    <TableCell>Currency</TableCell>
                    <TableCell>Cost (Currency)</TableCell>
                    <TableCell>Cost (Pts)</TableCell>
                    <TableCell>Denomination</TableCell>
                    {redemption.configurations.inputs.map(input => (
                      <TableCell key={input.id}>
                        {input.displayNameEn}
                      </TableCell>
                    ))}
                    <TableCell>Status</TableCell>
                    <TableCell>Executed by</TableCell>
                    <TableCell
                      sortDirection={
                        userRequestsOrderBy ===
                        "adminAction.dateTaken,creationTimestamp"
                          ? "asc"
                          : userRequestsOrderBy ===
                            "-adminAction.dateTaken,creationTimestamp"
                          ? "desc"
                          : false
                      }
                      onClick={handleUserRequestsOrderByClick(
                        userRequestsOrderBy ===
                          "-adminAction.dateTaken,creationTimestamp"
                          ? "adminAction.dateTaken,creationTimestamp"
                          : "-adminAction.dateTaken,creationTimestamp"
                      )}
                    >
                      {userRequestsOrderBy ===
                        "adminAction.dateTaken,creationTimestamp" ||
                      userRequestsOrderBy ===
                        "-adminAction.dateTaken,creationTimestamp" ? (
                        <TableSortLabel
                          active={true}
                          direction={
                            userRequestsOrderBy ===
                            "adminAction.dateTaken,creationTimestamp"
                              ? "asc"
                              : userRequestsOrderBy ===
                                "-adminAction.dateTaken,creationTimestamp"
                              ? "desc"
                              : ""
                          }
                        >
                          Executed at
                        </TableSortLabel>
                      ) : (
                        <TableSortLabel>Executed at</TableSortLabel>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userRequestsSyncing ? (
                    <TableRow>
                      <TableCell
                        component="td"
                        scope="row"
                        colSpan={17 + redemption.configurations.inputs.length}
                      >
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {userRequests && userRequests.length ? (
                        userRequests.map(userRequest => {
                          return (
                            <UserRequest
                              key={userRequest.id}
                              userRequest={userRequest}
                            />
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            colSpan={
                              17 + redemption.configurations.inputs.length
                            }
                            sx={{ p: 4, textAlign: "center" }}
                          >
                            {userRequestsQuery ||
                            userRequestsProviders.length ||
                            userRequestsCategories.length ||
                            userRequestsTypes.length ||
                            userRequestsCurrencies.length ? (
                              <>
                                No Requests!
                                <br />
                                Please check you search and filters.
                              </>
                            ) : (
                              "No Requests added yet!"
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardActions>
            <Pagination
              page={paginationPage}
              limit={paginationSize}
              total={redemption.userRequests.meta.total}
              rowsPerPageOptions={[]}
              handlePageClick={handlePageClick}
              sx={{ width: "100%" }}
            />
          </CardActions>
        </Card>
      ) : (
        <Loader />
      )}
    </Stack>
  );
};

export default withShield(
  connect(
    state => ({ redemption: state.redemption }),
    {
      getConfigurations: RedemptionActions.configurations.get,
      getUserRequests: RedemptionActions.userRequests.get,
      initUserRequestsPagination:
        RedemptionActions.layout.userRequests.pagination.init,
      changeUserRequestsPage:
        RedemptionActions.layout.userRequests.pagination.change,
      updateUserRequestsQuery:
        RedemptionActions.layout.userRequests.query.update,
      updateUserRequestsDate: RedemptionActions.layout.userRequests.date.update,
      updateUserRequestsProviders:
        RedemptionActions.layout.userRequests.providers.update,
      updateUserRequestsCategories:
        RedemptionActions.layout.userRequests.categories.update,
      updateUserRequestsTypes:
        RedemptionActions.layout.userRequests.types.update,
      updateUserRequestsCurrencies:
        RedemptionActions.layout.userRequests.currencies.update,
      updateUserRequestsOrderBy:
        RedemptionActions.layout.userRequests.orderBy.update,
      syncUserRequests: RedemptionActions.layout.userRequests.sync,
      addUserRequestsExporting:
        RedemptionActions.layout.userRequests.exporting.add,
      removeUserRequestsExporting:
        RedemptionActions.layout.userRequests.exporting.remove,
      emptyUserRequestsExporting:
        RedemptionActions.layout.userRequests.exporting.empty
    }
  )(UserRequests)
);
