import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, generatePath } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import * as dealsService from "../../../../../services/deals";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Link from "@mui/material/Link";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import FormCard from "../../../../../components/FormCard";
import FormCardHead from "../../../../../components/FormCardHead";
import FormCardBody from "../../../../../components/FormCardBody";
import FormCardBodyContent from "../../../../../components/FormCardBodyContent";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";

import WaslaTheme from "../../../../../modules/wasla-theme";

import configurations from "../../../../../configurations";

import { cashbacksPath } from "../../../paths";
import { cashbacksConversionsPath } from "../../paths";

import {
  updateReasons,
  addReason
} from "../../../../../main/cashbacks/conversions/edit-request/actions/reasons";
import {
  updateOldTransactionValue,
  updateOldCommission,
  updateOldCashback,
  updateNewTransactionValue,
  updateNewCommission,
  updateNewCashback,
  updateStatus,
  updateReason,
  updateNotes
} from "../../../../../main/cashbacks/conversions/edit-request/actions/local";
import { updateEditRequest } from "../../../../../main/cashbacks/conversions/edit-request/actions/origin";
import {
  addItem as addNotification,
  deleteItem as deleteNotification
} from "../../../../../widgets/notifications/actions/list";

import FlagIcon from "../assets/flag.svg";
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const EditConversionEditRequest = props => {
  const {
    conversionEditRequest: { reasons, origin, local },
    updateReasons,
    addReason,
    updateOldTransactionValue,
    updateOldCommission,
    updateOldCashback,
    updateNewTransactionValue,
    updateNewCommission,
    updateNewCashback,
    updateStatus,
    updateReason,
    updateNotes,
    updateEditRequest,
    addNotification,
    deleteNotification
  } = props;
  const classes = useStyles();

  const [newReasonSync, setNewReasonSync] = useState(false);
  const [showAddReasonForm, setShowAddReasonForm] = useState(false);
  const [newReason, setNewReason] = useState("");
  const [loading, setLoading] = useState(true);
  const [sync, setSync] = useState(false);

  const history = useHistory();

  const { countryCode, id } = useParams();

  useEffect(async () => {
    setLoading(true);
    const [
      conversionEditRequestReasonsRes,
      convertionEditRequestRes
    ] = await Promise.all([
      dealsService.getConversionEditRequestReasons(1, 1000),
      dealsService.getConversionEditRequest(id)
    ]);
    updateReasons(conversionEditRequestReasonsRes.data);
    updateEditRequest({
      ...convertionEditRequestRes,
      oldTransactionValue: convertionEditRequestRes.oldTransactionValue ?? "",
      oldCommission: convertionEditRequestRes.oldCommission ?? "",
      oldCashBack: convertionEditRequestRes.oldCashBack ?? "",
      reason: convertionEditRequestRes.reason ?? "",
      notes: convertionEditRequestRes.notes ?? ""
    });
    updateOldTransactionValue(
      convertionEditRequestRes.oldTransactionValue ?? ""
    );
    updateOldCommission(convertionEditRequestRes.oldCommission ?? "");
    updateOldCashback(convertionEditRequestRes.oldCashBack ?? "");
    updateNewTransactionValue(convertionEditRequestRes.newTransactionValue);
    updateNewCommission(convertionEditRequestRes.newCommission);
    updateNewCashback(convertionEditRequestRes.newCashBack);
    updateStatus(convertionEditRequestRes.status);
    updateReason(convertionEditRequestRes.reason ?? "");
    updateNotes(convertionEditRequestRes.notes ?? "");
    setLoading(false);
  }, []);

  useEffect(() => {
    if (local.reason) {
      const currentReason = reasons.find(item => item.value === local.reason);
      if (currentReason) updateReason(currentReason.id);
    }
  }, [local.reason]);

  const handleSubmitForm = async e => {
    e.preventDefault();
    setSync(true);
    await dealsService
      .approveConversionEditRequest(
        origin.id,
        reasons.find(reason => reason.id === local.reason)
      )
      .then(res => {
        history.push(
          [
            configurations.dashboard.basePath,
            generatePath(cashbacksConversionsPath, {
              countryCode
            })
          ].join("")
        );
      })
      .catch(error => {
        deleteNotification("approveResponseNotificationId");
        addNotification({
          id: "approveResponseNotificationId",
          type: "error",
          message: "Error in Approving the edit request please try again."
        });
      });
    setSync(false);
  };

  const handleRejectButtonClick = async e => {
    setSync(true);
    await dealsService
      .rejectConversionEditRequest(
        origin.id,
        reasons.find(reason => reason.id === local.reason)
      )
      .then(res => {
        history.push(
          [
            configurations.dashboard.basePath,
            generatePath(cashbacksConversionsPath, {
              countryCode
            })
          ].join("")
        );
      })
      .catch(error => {
        deleteNotification("rejectResponseNotificationId");
        addNotification({
          id: "rejectResponseNotificationId",
          type: "error",
          message: "Error in Rejecting the edit request please try again."
        });
      });
    setSync(false);
  };

  const handleNewReasonSaveButtonClick = async e => {
    setNewReasonSync(true);
    await dealsService
      .addConversionEditRequestReason(newReason)
      .then(res => {
        addReason(res);
        setNewReason("");
        setShowAddReasonForm(false);
      })
      .catch(error => {
        deleteNotification("addingReasonResponseNotificationId");
        addNotification({
          id: "addingReasonResponseNotificationId",
          type: "error",
          message: "Error in Adding new reason please try again."
        });
      });
    setNewReasonSync(false);
  };

  const handleNewReasonChange = e => {
    setNewReason(e.target.value);
  };

  const handleReasonChange = e => {
    updateReason(e.target.value);
  };

  const handleNotesChange = e => {
    updateNotes(e.target.value);
  };

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, cashbacksPath].join("")}
          >
            Select a country for Cashback
          </Link>
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[
              configurations.dashboard.basePath,
              generatePath(cashbacksConversionsPath, {
                countryCode
              })
            ].join("")}
          >
            Cashbacks Table
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            Edit Request
          </Typography>
        </Breadcrumbs>
        <Grid container justifyContent="center">
          <Grid item lg={7}>
            <Box
              component="form"
              autoComplete="off"
              onSubmit={handleSubmitForm}
            >
              <Stack spacing={4}>
                <FormCard>
                  <FormCardHead variant="underline">
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "28px",
                        color: "#101828"
                      }}
                    >
                      Edit Cashback
                    </Typography>
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085"
                      }}
                    >
                      Update Cashback
                    </Typography>
                  </FormCardHead>
                  <FormCardBody>
                    <FormCardBodyContent>
                      <Stack spacing={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="reasons"
                                sx={{ transform: "none" }}
                              >
                                <span>
                                  Select Reason
                                  <span style={{ color: "red" }}>*</span>
                                </span>
                              </InputLabel>
                              <Select
                                displayEmpty
                                value={local.reason}
                                id="reasons-dropdown"
                                inputProps={{
                                  id: "reasons"
                                }}
                                MenuProps={{
                                  id: "reasons-menu"
                                }}
                                onChange={handleReasonChange}
                                required
                                disabled={false}
                                autoFocus={false}
                              >
                                <Box
                                  px={2}
                                  py={1}
                                  sx={{
                                    backgroundColor: "#F5FAFF",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#1570EF",
                                    cursor: "pointer"
                                  }}
                                  onClick={e => setShowAddReasonForm(true)}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <AddCircleOutlineOutlinedIcon />
                                    <Typography>Add New Reason</Typography>
                                  </Stack>
                                </Box>
                                <Box
                                  hidden={!showAddReasonForm}
                                  px={2}
                                  py={1}
                                  onKeyDown={e => e.stopPropagation()}
                                >
                                  <Grid container spacing={2} py={1}>
                                    <Grid item xs={12}>
                                      <FormControl error={false} fullWidth>
                                        <OutlinedInput
                                          value={newReason}
                                          onChange={handleNewReasonChange}
                                          label=""
                                          multiline={true}
                                          minRows="4"
                                          maxRows="4"
                                          required
                                          disabled={false}
                                          placeholder="Detailed notes for the edit*"
                                        />
                                        {false && (
                                          <FormHelperText>
                                            Reason is required
                                          </FormHelperText>
                                        )}
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Stack
                                        direction="row"
                                        spacing={2}
                                        px={2}
                                        justifyContent="flex-end"
                                      >
                                        <Button
                                          variant="neutral"
                                          sx={{ textTransform: "capitalize" }}
                                          onClick={e =>
                                            setShowAddReasonForm(false)
                                          }
                                          color="neutral"
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="contained"
                                          sx={{ textTransform: "capitalize" }}
                                          disabled={!newReason || newReasonSync}
                                          onClick={
                                            handleNewReasonSaveButtonClick
                                          }
                                          startIcon={
                                            newReasonSync && (
                                              <CircularProgress size={24} />
                                            )
                                          }
                                        >
                                          Save
                                        </Button>
                                      </Stack>
                                      <Divider sx={{ mt: 1 }} />
                                    </Grid>
                                  </Grid>
                                </Box>
                                {reasons.map(reason => (
                                  <MenuItem key={reason.id} value={reason.id}>
                                    {reason.value}
                                  </MenuItem>
                                ))}
                              </Select>
                              {false && (
                                <FormHelperText>
                                  Reason is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="old-transaction-value"
                                sx={{ transform: "none" }}
                              >
                                GMV old
                              </InputLabel>
                              <Typography>
                                {local.oldTransactionValue
                                  ? `${local.oldTransactionValue} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  GMV old is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="new-transaction-value"
                                sx={{ transform: "none" }}
                              >
                                <span>
                                  <img src={FlagIcon} alt="GMV new" /> GMV new
                                </span>
                              </InputLabel>
                              <Typography sx={{ marginLeft: "26px" }}>
                                {local.newTransactionValue
                                  ? `${local.newTransactionValue} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  GMV new is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="old-commission"
                                sx={{ transform: "none" }}
                              >
                                Wasla commission old
                              </InputLabel>
                              <Typography>
                                {local.oldCommission
                                  ? `${local.oldCommission} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  Wasla commission old is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="new-commission"
                                sx={{ transform: "none" }}
                              >
                                <span>
                                  <img
                                    src={FlagIcon}
                                    alt="Wasla commission new"
                                  />
                                  Wasla commission new
                                </span>
                              </InputLabel>
                              <Typography sx={{ marginLeft: "26px" }}>
                                {local.newCommission
                                  ? `${local.newCommission} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  Wasla commission new is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="old-cashback"
                                sx={{ transform: "none" }}
                              >
                                CB Value old
                              </InputLabel>
                              <Typography>
                                {local.oldCashback
                                  ? `${local.oldCashback} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  CB Value old is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="new-cashback"
                                sx={{ transform: "none" }}
                              >
                                <span>
                                  <img src={FlagIcon} alt="CB Value new" />
                                  CB Value new
                                </span>
                              </InputLabel>
                              <Typography sx={{ marginLeft: "26px" }}>
                                {local.newCashback
                                  ? `${local.newCashback} ${origin.currency}`
                                  : ""}
                              </Typography>
                              {false && (
                                <FormHelperText>
                                  CB Value new is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl error={false} fullWidth>
                              <OutlinedInput
                                value={local.notes}
                                onChange={handleNotesChange}
                                label=""
                                multiline={true}
                                minRows="4"
                                maxRows="4"
                                required
                                disabled={false}
                                placeholder="Detailed notes for the edit*"
                              />
                              {false && (
                                <FormHelperText>
                                  Notes is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Stack>
                    </FormCardBodyContent>
                  </FormCardBody>
                </FormCard>
                <Divider />
                <Stack
                  direction="row"
                  spacing={2}
                  px={3}
                  justifyContent="flex-end"
                >
                  <Button
                    variant="neutral"
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleRejectButtonClick}
                    color="neutral"
                    disabled={!local.reason || !local.notes || sync}
                    startIcon={sync && <CircularProgress size={24} />}
                  >
                    Reject Changes
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ textTransform: "capitalize" }}
                    disabled={!local.reason || !local.notes || sync}
                    startIcon={sync && <CircularProgress size={24} />}
                    className={classes.submitBTN}
                  >
                    Approve Changes
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default connect(
  state => ({ conversionEditRequest: state.ConversionEditRequest }),
  {
    updateReasons,
    addReason,
    updateOldTransactionValue,
    updateOldCommission,
    updateOldCashback,
    updateNewTransactionValue,
    updateNewCommission,
    updateNewCashback,
    updateStatus,
    updateReason,
    updateNotes,
    updateEditRequest,
    addNotification,
    deleteNotification
  }
)(EditConversionEditRequest);
