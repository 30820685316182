export const setCountry = (id, name) => {
  return {
    type: "SET_COUNTRY",
    payload: {
      id,
      name
    }
  };
};

export const restCountryState = () => {
  return {
    type: "REST_COUNTRY_STATE"
  };
};
