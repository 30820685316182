import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { Typography } from "@material-ui/core";

const GamesPoints = ({ minPoints, maxPoints }) => {
  return (
    <>
      <Typography
        component="p"
        variant="body1"
        style={{ flexBasis: "33%" }}
        gutterBottom={true}
      >
        Min rewarded points:
        <Typography
          component="span"
          variant="body1"
          style={{ marginLeft: "8px", color: "#009900" }}
        >
          {`${minPoints} pts`}
        </Typography>
      </Typography>
      <Typography
        component="p"
        variant="body1"
        style={{ flexBasis: "33%" }}
        gutterBottom={true}
      >
        Max rewarded points:
        <Typography
          component="span"
          variant="body1"
          style={{ marginLeft: "8px", color: "#009900" }}
        >
          {`${maxPoints} pts`}
        </Typography>
      </Typography>
    </>
  );
};

export default GamesPoints;

GamesPoints.propTypes = {
  minPoints: PropTypes.number,
  maxPoints: PropTypes.number
};
