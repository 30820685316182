const styles = theme => ({
  page: {
    display: "grid",
    placeContent: "center"
  },
  box: {
    display: "grid",
    gap: 16,
    margin: "0 auto",
    width: "80%",
    borderRadius: 24,
    padding: "32px 48px",
    background: "#FFFFFF",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)"
  },
  img: {
    display: "block",
    margin: "0 auto",
    maxWidth: 64,
    maxHeight: 64
  },
  title: {
    margin: 0,
    fontWeight: 500,
    fontSize: 48,
    lineHeight: "60px",
    textAlign: "center",
    letterSpacing: "-0.02em",
    color: "#333333"
  },
  content: {
    margin: 0,
    maxWidth: 480,
    fontSize: 24,
    lineHeight: "32px",
    textAlign: "center",
    color: "#828282"
  }
});

export default styles;
