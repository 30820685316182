import { combineReducers } from "redux";

import reasons from "./reasons";
import origin from "./origin";
import local from "./local";

export default combineReducers({
  reasons,
  origin,
  local
});
