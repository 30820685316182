import { UPLOAD_CSV_FILE_TYPE } from "../../action/actionType";

const initialState = {
  uploadFileType: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_CSV_FILE_TYPE:
      return {
        ...state,
        uploadFileType: action.payload
      };

    default:
      return state;
  }
};
