const content = {
  stepsTitle: [
    "Card definition",
    "Call to action",
    "Card styling",
    "Card targeting",
    "Card Expiration"
  ]
};

export default content;
