const initialState = {
  isLoading: false,
  list: [],
  error: false,
  success: false,
  hasNext: false,
  serverError: "",
  updateMessage: "",
  limit: 10,
  totalPages: 0,
  deleteSuccessMessage: "",
  createSuccessMessage: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING_SECTIONS_LIST": {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case "FETCH_SECTIONS_LIST": {
      return {
        ...state,
        list: action.payload?.data,
        hasNext: action.payload?.meta?.has_next,
        totalPages: action.payload?.meta?.total,
        limit: action.payload?.meta?.limit || state.limit
      };
    }
    case "SET_ERROR_LIST": {
      return {
        ...state,
        serverError: action.payload
      };
    }
    case "DELETE_SECTION_SUCCESS": {
      console.log("DELETE_SECTION_SUCCESS", action.payload);
      return {
        ...state,
        success: true,
        deleteSuccessMessage: action.payload
      };
    }

    case "SUCCESS_UPDATE_SECTION": {
      return {
        ...state,
        success: true,
        updateMessage: action.payload
      };
    }

    case "CREATE_SECTIONS_FAILED": {
      return {
        ...state,
        error: true,
        serverError: action.payload
      };
    }

    case "CREATE_SECTIONS_SUCCESS": {
      return {
        ...state,
        success: true,
        createSuccessMessage: action.payload
      };
    }
    case "ERROR_UPDATE_SECTION": {
      return {
        ...state,
        error: true,
        serverError: action.payload
      };
    }
    case "RESET_ERROR": {
      return {
        ...state,
        serverError: "",
        success: false,
        updateMessage: "",
        error: false,
        deleteSuccessMessage: "",
        createSuccessMessage: ""
      };
    }
    case "RESET_SECTION_LIST": {
      return {
        ...state,
        success: false,
        error: false,
        updateMessage: "",
        deleteSuccessMessage: "",
        createSuccessMessage: ""
      };
    }
    case "REST_SECTION_STATE": {
      return initialState;
    }
    default:
      return state;
  }
};
