import * as userProfileService from "../../../../services/user-profile";

export const GET_STATUS = "get status";

export const getStatus = id => dispatch =>
  userProfileService
    .getDeleteRequest(id)
    .then(data => {
      dispatch({
        type: GET_STATUS,
        payload: data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_STATUS,
        payload: "ERROR"
      });
    });
