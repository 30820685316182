import React, { useEffect } from "react";

import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles.css";

import pending from "./assets/pending.svg";
import approved from "./assets/approved.svg";
import error from "./assets/error.svg";

import withReducer from "../../store/withReducer";
import userDeletionReducers from "./reducers";

import userDeletionActions from "./actions";

const useStyles = makeStyles(styles);

const UserDeletionStatus = props => {
  const { userDeletion, getStatus } = props;

  useEffect(() => {
    getStatus(props?.match?.params?.id);
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.page}>
      {userDeletion.status && (
        <div className={classes.box}>
          {(status => {
            switch (status) {
              case "PENDING":
                return (
                  <>
                    <img className={classes.img} src={pending} alt="" />
                    <h1 className={classes.title}>Pending</h1>
                    <p className={classes.content}>
                      Your data deletion request is being processed at the
                      moment
                    </p>
                  </>
                );
              case "APPROVED":
                return (
                  <>
                    <img className={classes.img} src={approved} alt="" />
                    <h1 className={classes.title}>Approved</h1>
                    <p className={classes.content}>
                      Your data deletion has been successfully deleted
                    </p>
                  </>
                );
              default:
                return (
                  <>
                    <img className={classes.img} src={error} alt="" />
                    <h1 className={classes.title}>Error</h1>
                    <p className={classes.content}>
                      An error has been occurred
                    </p>
                  </>
                );
            }
          })(userDeletion.status.data)}
        </div>
      )}
    </div>
  );
};

export default withReducer("userDeletion", userDeletionReducers)(
  connect(
    state => ({ userDeletion: state.userDeletion }),
    {
      getStatus: userDeletionActions.status.get
    }
  )(UserDeletionStatus)
);
