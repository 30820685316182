import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useLocation } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { submitUsersRecharge } from "../../../redux/action/WaslaRewards/RechargeUsersActions";
// core components
import Stepper from "../../Stepper/refactorStepper";

import styles from "./styles.css";
import Warning from "../../../assets/img/Warning.1.svg";
import Success from "../../../assets/img/success.svg";
import ResponseModal from "../RechargeUsersModal/RechargeSteps/ResponseModal";
import EditRechargesTable from "./EditRechargesTable";
import RechargeInfo from "./RechargeInfo";
import CustomizedSnackBars from "../SnackBar/SnackBar";

const stepsTitle = ["Retry Failed", "Confirm Order"];

const useStyles = makeStyles(styles);

function EditRechargeSteps(props) {
  const classes = useStyles();
  const [progressBar, setProgressBar] = useState(false);
  const {
    isLoading,
    error,
    onCloseModal,
    submitUsersRecharge,
    success,
    finialTotalEditRecharge,
    serverError
  } = props;
  const location = useLocation();
  const projectId = location?.state?.projectId || location?.state?.id;

  useEffect(() => {
    const enableClass = document.querySelector(".MuiStep-completed");
    setProgressBar(!!enableClass);
  }, [progressBar]);

  const handleSubmit = () => {
    submitUsersRecharge(
      projectId,
      finialTotalEditRecharge,
      location?.state?.integrationType
    );
  };

  return (
    <div className={classes.containerSteps}>
      <Stepper stepsTitle={stepsTitle} handleSubmit={handleSubmit}>
        <EditRechargesTable />
        <RechargeInfo changeProgressValue={setProgressBar} />
      </Stepper>
      <LinearProgress
        variant="determinate"
        value={progressBar ? 100 : 50}
        style={{ width: "95%" }}
      />
      {isLoading && (
        <div className={classes.containerLoading}>
          <CircularProgress />
          <p>Payment Loading</p>
        </div>
      )}
      {error && (
        <ResponseModal
          image={Warning}
          onCloseModal={onCloseModal}
          error={error}
        />
      )}
      {success && (
        <ResponseModal
          image={Success}
          onCloseModal={onCloseModal}
          success={success}
        />
      )}
      {!!serverError && (
        <CustomizedSnackBars
          severity={"error"}
          messege={`Server ${serverError} `}
          errorMsg={!!serverError}
        />
      )}
    </div>
  );
}
const mapStateToProps = state => ({
  isLoading: state.rechargeUsers.isLoading,
  error: state.rechargeUsers.error,
  success: state.rechargeUsers.success,
  finialTotalEditRecharge: state.usersRechargeToEdit.finialTotalEditRecharge,
  isFinish: state.usersRechargeToEdit.isFinish,
  serverError: state.rechargeUsers.serverError
});

export default connect(
  mapStateToProps,
  { submitUsersRecharge }
)(EditRechargeSteps);

EditRechargeSteps.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isFinish: PropTypes.bool,
  onCloseModal: PropTypes.func,
  submitUsersRecharge: PropTypes.func,
  finialTotalEditRecharge: PropTypes.arrayOf(PropTypes.object),
  serverError: PropTypes.string
};
