import React, { useEffect } from "react";
import { connect } from "react-redux";
import { closeSnackBar } from "../../../redux/action/SnackBarAction";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { AlertTitle } from "@material-ui/lab";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  pointSystemErr: {
    maxHeight: "36vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey"
    },
    "& button": {
      placeSelf: "flex-start",
      padding: " 8px"
    }
  }
  // warningCentered: {
  //   top: "33%"
  // }
}));

function CustomizedSnackbars(props) {
  const { severity, messege, openSnack, closeSnackBar, alertTitleProp } = props;
  const classes = useStyles();
  /*   const [open, setOpen] = React.useState(errorMsg);
   */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    closeSnackBar();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        onClose={handleClose}
        className={severity == "warning" ? classes.warningCentered : null}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          className={severity == "warning" ? classes.pointSystemErr : null}
        >
          <AlertTitle>{alertTitleProp ? alertTitleProp : null}</AlertTitle>
          {messege}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    openSnack: state.SnackBar.open
  };
};

export default connect(
  mapStateToProps,
  { closeSnackBar }
)(CustomizedSnackbars);
