export const UPDATE_DATA_FROM =
  "update cashbacks conversions filtration date from";
export const UPDATE_DATA_TO = "update cashbacks conversions filtration date to";
export const UPDATE_DATE_RANGE = "update cashbacks conversions filtration date range"

export const updateDateFrom = dateFrom => dispatch =>
  dispatch({
    type: UPDATE_DATA_FROM,
    payload: { dateFrom }
  });

export const updateDateTo = dateTo => dispatch =>
  dispatch({
    type: UPDATE_DATA_TO,
    payload: { dateTo }
  });

  export const updateDateRange = dateRange => dispatch =>
  dispatch({
    type: UPDATE_DATE_RANGE,
    payload: { dateRange }
  });
