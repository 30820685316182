import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
// redux actions
import {
  setCTAButtonText,
  setCTAButtonType
} from "../../../../redux/action/AnnouncementCards/CTAButtonActions";
// core components
import styles from "../../styles.css";
import { buttonLinkType } from "./CallToActionConfig";

const useStyles = makeStyles(styles);

const LinkTypeComponent = ({
  announcementCards,
  setCTAButtonText,
  setCTAButtonType
}) => {
  const classes = useStyles();

  const handleChange = event => {
    setCTAButtonText({
      ...announcementCards,
      [event.target.name]: event.target.value
    });
  };

  const handleSelection = event => {
    setCTAButtonType(event.target.value);
  };

  return (
    <Grid className={classes.container}>
      <TextField
        id="button-english-text"
        name="btnEnglishText"
        label="Button text (En)"
        className={classes.textField}
        value={announcementCards.btnEnglishText}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        id="button-arabic-text"
        name="btnArabicText"
        label="Button text (Ar)"
        className={classes.textField}
        value={announcementCards.btnArabicText}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        id="button-link-type"
        select
        label="Button link Type"
        name="buttonLinkType"
        className={classes.textField}
        value={announcementCards.ctaBtnType}
        onChange={handleSelection}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        helperText="Select button link type"
        margin="normal"
        variant="outlined"
        required
      >
        <option value="none">Select an Option</option>
        {buttonLinkType.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    announcementCards: state.announcementCards
  };
};

export default connect(
  mapStateToProps,
  { setCTAButtonText, setCTAButtonType }
)(LinkTypeComponent);

LinkTypeComponent.propTypes = {
  announcementCards: PropTypes.object,
  setCTAButtonText: PropTypes.func,
  setCTAButtonType: PropTypes.func
};
