const styles = theme => ({
  modalContainer: {
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -48%)",
    textAlign: "center"
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
    padding: "16px 32px 24px"
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  primaryBackground: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  warningModalContainer: {
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -48%)",
    boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
    borderRadius: '12px',
    "& h2": {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 18,
      lineHeight: "28px",
      color: "#101828",
      marginTop: 20
    },
    "& p": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
      color: "#667085",
      marginTop: 8,
      marginBottom: 33,
    },
    "& button": {
      background: '#DE1306 !important',
      border: '1px solid #D92D20 !important',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05) !important',
      borderRadius: '8px !important',
      textTransform: 'capitalize',
      width: '100%',
    },
  }
});

export default styles;
