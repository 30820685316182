import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "transparent"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "600px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& .MuiFormControl-root": {
      width: "48% !important",
      marginBlockEnd: "15px !important",
      marginBlockStart: 0
    }
  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const {
    item,
    // handleCloseEdit,
    open,
    updateFieldChanged,
    errorMessage,
    itemIndex,
    handleSave,
    handleRest,
    setErrorMessage,
    setItem
  } = props;
  useEffect(() => {
    return () => {
      setErrorMessage({
        titleEn: "",
        titleAr: "",
        id: "",
        infoEn: "",
        infoAr: "",
        infoTitleEn: "",
        infoTitleAr: "",
        order: ""
      });
      setItem({
        titleEn: "",
        titleAr: "",
        infoEn: "",
        infoAr: "",
        infoTitleEn: "",
        infoTitleAr: "",
        order: ""
      });
    };
  }, []);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={handleCloseEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TextField
              id="titleEn"
              name="titleEn"
              label="Title (En)"
              type="text"
              value={item?.titleEn}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.titleEn}
              helperText={errorMessage?.titleEn}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="titleAr"
              name="titleAr"
              label="Title (Ar)"
              type="text"
              value={item?.titleAr}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.titleAr}
              helperText={errorMessage?.titleAr}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="infoTitleEn"
              name="infoTitleEn"
              label="Note Title (En)"
              type="text"
              value={item?.infoTitleEn}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.infoTitleEn}
              helperText={errorMessage?.infoTitleEn}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="infoTitleAr"
              name="infoTitleAr"
              label="Note Title (Ar)"
              type="text"
              value={item?.infoTitleAr}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.infoTitleAr}
              helperText={errorMessage?.infoTitleAr}
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="noteEn"
              name="infoEn"
              type="text"
              label="Note (En)"
              value={item?.infoEn}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.infoEn}
              helperText={errorMessage?.infoEn}
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 3000
              }}
              multiline
              fullWidth
              rows="4"
            />
            <TextField
              id="noteAr"
              name="infoAr"
              label="Note (Ar)"
              type="text"
              value={item?.infoAr}
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.infoAr}
              helperText={errorMessage?.infoAr}
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 3000
              }}
              multiline
              fullWidth
              rows="4"
            />
            <TextField
              id="order"
              name="order"
              type="number"
              value={item?.order}
              label="Order"
              margin="normal"
              variant="outlined"
              style={{ width: "91%", margin: 0 }}
              onChange={updateFieldChanged(itemIndex)}
              error={!!errorMessage?.index}
              helperText={errorMessage?.index}
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: 1
              }}
            />

            <div
              style={{
                flexBasis: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button onClick={() => handleRest(itemIndex)}>Cancel</Button>
              <Button
                onClick={() => handleSave(itemIndex)}
                disabled={
                  Object.values(errorMessage).some(val => val) ||
                  !Object.values(item).every(val => val)
                }
              >
                Save
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
