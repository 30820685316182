import {
  FETCH_MISSIONS_CONFIG,
  ERROR_FETCH_MISSIONS_CONFIG
} from "../../../action/actionType";

const initialState = {
  usdToEgp: {},
  errMissionsConfigPoints: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MISSIONS_CONFIG:
      return {
        ...state,
        usdToEgp: action.payload,
        errMissionsConfigPoints: false
      };
    case ERROR_FETCH_MISSIONS_CONFIG:
      return {
        ...state,
        errMissionsConfigPoints: true
      };
    default:
      return state;
  }
};
