import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CardContent } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
// core components
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody.js";

import styles from "./styles.css";

const useStyles = makeStyles(styles);

const MissionCard = ({
  title,
  body,
  btnText,
  imgUrl,
  flexBasis,
  direction,
  buttonUrl,
  sectionName,
  iconUrl
}) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.cardContainer}
      style={{
        flexBasis: flexBasis,
        direction: direction
      }}
    >
      <CardBody className={classes.cardBody}>
        {sectionName && (
          <Typography component="h5" variant="h5">
            Section: {sectionName}
          </Typography>
        )}
        <CardMedia className={classes.media} image={imgUrl} title="" />
        {btnText && (
          <Button
            variant="contained"
            size="medium"
            className={classes.cardButton}
            href={buttonUrl}
            target="_blank"
          >
            {btnText}
          </Button>
        )}
        <div className={classes.containerContent}>
          <img src={iconUrl} className={classes.imgIconUrl} />
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {title}
            </Typography>
            {body && (
              <Typography
                variant="body2"
                style={{
                  color: "#656565"
                }}
              >
                {body}
              </Typography>
            )}
          </CardContent>
        </div>
      </CardBody>
    </Card>
  );
};

export default MissionCard;

MissionCard.propTypes = {
  bgPrimaryColor: PropTypes.string,
  textColor: PropTypes.string,
  bgSecondaryColor: PropTypes.string,
  btnBgColor: PropTypes.string,
  btnTextColor: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  btnText: PropTypes.string,
  imgUrl: PropTypes.string,
  flexBasis: PropTypes.string,
  direction: PropTypes.string,
  sectionName: PropTypes.string
};
