import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import { connect } from "react-redux";
import { fetchExportFileUrl } from "../../../redux/action/WaslaRewards/FetchExportFIleUrlAction";
import styles from "./style.css";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);

const ExportButton = props => {
  const { selectedUsersIds, fetchExportFileUrl } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const location = useLocation();
  const batchId = location?.state?.batchId;

  if (selectedUsersIds.length > 0) {
    var listOfUserIds = selectedUsersIds.map(a => {
      return a.id;
    });
    var allUserIds = listOfUserIds.toString();
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleExportPDF = (event, userIds) => {
    fetchExportFileUrl("pdf", userIds, null);
    handleClose(event);
  };
  const handleExportCSV = (event, userIds) => {
    fetchExportFileUrl("csv", userIds, null);
    handleClose(event);
  };
  const handleExportBatchPDF = (event, batchId) => {
    fetchExportFileUrl("pdf", null, batchId);
    handleClose(event);
  };
  const handleExportBatchCSV = (event, batchId) => {
    fetchExportFileUrl("csv", null, batchId);
    handleClose(event);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Button
        className={open ? classes.exportBtnActive : classes.exportBtn}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <VerticalAlignBottomIcon />
        Export
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper style={{ borderRadius: "0 0 10px 10px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={event => handleExportPDF(event, allUserIds)}
                  >
                    Export Selection - PDF
                  </MenuItem>
                  <MenuItem
                    onClick={event => handleExportCSV(event, allUserIds)}
                  >
                    Export Selection - CSV
                  </MenuItem>
                  {batchId && (
                    <>
                      <MenuItem
                        onClick={event => handleExportBatchPDF(event, batchId)}
                      >
                        Export Batch - PDF
                      </MenuItem>
                      <MenuItem
                        onClick={event => handleExportBatchCSV(event, batchId)}
                      >
                        Export Batch - CSV
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {open && <div className={classes.overlay}></div>}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedUsersIds: state.waslaRewards.selectedUsersIds
  };
};

export default connect(
  mapStateToProps,
  { fetchExportFileUrl }
)(ExportButton);
