export const UPDATE_REASONS =
  "update cashbacks conversion edit request reasons";
export const ADD_REASON = "add cashbacks conversion edit request reason";

export const updateReasons = reasons => dispatch =>
  dispatch({
    type: UPDATE_REASONS,
    payload: { reasons }
  });

export const addReason = reason => dispatch =>
  dispatch({
    type: ADD_REASON,
    payload: { reason }
  });
