import React from "react";
import { connect } from "react-redux";

import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import PageTitle from "../../../../components/page-title";

import configurations from "../../../../configurations";

import egypt from "./assets/egypt.png";
import nigeria from "./assets/nigeria.png";

const RedemptionHead = props => {
  const { redemption } = props;

  const getFlag = countryCode => {
    switch (countryCode) {
      case "EG":
        return egypt;
      case "NG":
        return nigeria;
      default:
        return null;
    }
  };

  return (
    <Stack spacing={4} direction="row" justifyContent="space-between">
      <PageTitle title="Redemptions" />
      <FormControl sx={{ minWidth: 120 }}>
        <Select
          value={redemption.country}
          onChange={e => {}}
          displayEmpty
          disabled
        >
          {configurations.application.countries.map(country => (
            <MenuItem key={country.code} value={country.code}>
              <Stack spacing={1} direction="row">
                <img src={getFlag(country.code)} />
                <span>{country.name}</span>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default connect(
  state => ({ redemption: state.redemption }),
  {}
)(RedemptionHead);
