import { UPDATE_COUNTRY_CODE } from "../../actions/filtration";
import configurations from "../../../../../configurations";

const initialState = {
  countryCode: configurations.application.defaultCountryCode
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload.countryCode
      };
    default:
      return state;
  }
};
