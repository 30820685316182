import * as quickLinksService from "../../../../services/quick-links";

export const ADD_CATEGORY = "add category";
export const EDIT_CATEGORY = "edit category";
export const REMOVE_CATEGORY = "remove category";
export const GET_CATEGORIES = "get categories";
export const EDIT_CATEGORIES = "edit categories";

export const addCategory = (en, ar) => dispatch =>
  quickLinksService.addCategory(en, ar).then(data => {
    dispatch({
      type: ADD_CATEGORY,
      payload: data
    });
  });

export const editCategory = (id, data) => dispatch =>
  quickLinksService.editCategory(id, data).then(() => {
    dispatch({
      type: EDIT_CATEGORY,
      payload: { id, data }
    });
  });

export const removeCategory = id => dispatch =>
  quickLinksService.removeCategory(id).then(() => {
    dispatch({
      type: REMOVE_CATEGORY,
      payload: id
    });
  });

export const getCategories = () => dispatch =>
  quickLinksService.getCategories().then(data => {
    dispatch({
      type: GET_CATEGORIES,
      payload: data
    });
  });

export const editCategories = categories => dispatch =>
  quickLinksService.editCategories(categories).then(data => {
    dispatch({
      type: EDIT_CATEGORIES,
      payload: data
    });
  });
