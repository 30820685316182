import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import MoneyOutlinedIcon from "@material-ui/icons/MoneyOutlined";
import styles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import customStyle from "./style.css";

// Actions
import { fetchBalanceList } from "../../../redux/action/WaslaRewards/FetchBalanceListAction";

const useStyles = makeStyles({ ...styles, ...customStyle });

function BalanceDetails(props) {
  const classes = useStyles();

  const { fetchBalanceList, balanceList, availableError } = props;

  useEffect(() => {
    fetchBalanceList();
  }, []);

  if (availableError) {
    throw new Error();
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card className={classes.cardContainer}>
            <CardHeader color="info" stats icon>
              <div className={classes.containerHeader}>
                <div className={classes.cardContent}>
                  <p className={classes.cardCategory}>Current Balance</p>
                  <h3 className={classes.cardTitle}>
                    {`${balanceList?.currentBalance
                      ?.toFixed(2)
                      .toLocaleString() || 0} EGP`}
                  </h3>
                </div>
                <CardIcon color="info" className={classes.cardIconContainer}>
                  <AccountBalanceOutlinedIcon />
                </CardIcon>
              </div>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card className={classes.cardContainer}>
            <CardHeader color="info" stats icon>
              <div className={classes.containerHeader}>
                <div className={classes.cardContent}>
                  <p className={classes.cardCategory}>Redeemed Today</p>
                  <h3 className={classes.cardTitle}>
                    {`${balanceList?.numberRedeemedToday?.toLocaleString() ||
                      0}`}
                  </h3>
                </div>
                <CardIcon color="info" className={classes.cardIconContainer}>
                  <MoneyOutlinedIcon />
                </CardIcon>
              </div>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card className={classes.cardContainer}>
            <CardHeader color="info" stats icon>
              <div className={classes.containerHeader}>
                <div className={classes.cardContent}>
                  <p className={classes.cardCategory}>Pay Today</p>
                  <h3 className={classes.cardTitle}>
                    {`${balanceList?.payToday?.toFixed(2).toLocaleString() ||
                      0} EGP`}
                  </h3>
                </div>
                <CardIcon color="info" className={classes.cardIconContainer}>
                  <AccountBalanceWalletOutlinedIcon />
                </CardIcon>
              </div>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card className={classes.cardContainer}>
            <CardHeader color="info" stats icon>
              <div className={classes.containerHeader}>
                <div className={classes.cardContent}>
                  <p className={classes.cardCategory}>Paid Yesterday</p>
                  <h3 className={classes.cardTitle}>
                    {`${balanceList?.paidYesterday
                      ?.toFixed(2)
                      .toLocaleString() || 0} EGP`}
                  </h3>
                </div>
                <CardIcon color="info" className={classes.cardIconContainer}>
                  <AccountBalanceWalletOutlinedIcon />
                </CardIcon>
              </div>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const mapStateToProps = state => {
  return {
    balanceList: state.balanceList.balanceList,
    availableError: state.availableError.availableError
  };
};

export default connect(
  mapStateToProps,
  {
    fetchBalanceList
  }
)(BalanceDetails);

BalanceDetails.propTypes = {
  fetchBalanceList: PropTypes.func,
  balanceList: PropTypes.object,
  availableError: PropTypes.bool
};
