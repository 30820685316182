export const headCells = [
  {
    id: "titleEn",
    numeric: false,
    disablePadding: false,
    label: "Title (En)",
    minWidth: 140
  },
  {
    id: "titleAr",
    numeric: false,
    disablePadding: true,
    label: "Title (Ar)",
    minWidth: 140
  },
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
    minWidth: 170
  },
  {
    id: "noteTitleEn",
    numeric: false,
    disablePadding: true,
    label: "Note Title(En)",
    minWidth: 170
  },
  {
    id: "noteTitleAr",
    numeric: false,
    disablePadding: true,
    label: "Note Title (Ar)",
    minWidth: 170
  },
  {
    id: "noteEn",
    numeric: false,
    disablePadding: true,
    label: "Note (En)",
    minWidth: 170
  },
  {
    id: "noteAr",
    numeric: false,
    disablePadding: true,
    label: "Note (Ar)",
    minWidth: 170
  },

  {
    id: "order",
    numeric: false,
    disablePadding: true,
    label: "Order",
    minWidth: 170
  },

  {
    id: "delete-action",
    numeric: false,
    disablePadding: true,
    label: "Delete",
    minWidth: 100
  },
  {
    id: "edit-action",
    numeric: false,
    disablePadding: true,
    label: "Edit",
    minWidth: 100
  }
];
