import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
// redux actions
import {
  setCTAButtonText,
  setCTAButtonTextSelection
} from "../../../../redux/action/Missions/CTAButtonActions";
// core components
import styles from "../../styles.css";
import { buttonTextEnglish, buttonTextArabic } from "./CallToActionConfig";

const useStyles = makeStyles(styles);

const ButtonTextComponent = ({
  missions,
  setCTAButtonText,
  setCTAButtonTextSelection,
  viewType
}) => {
  const classes = useStyles();

  const handleTextChange = event => {
    setCTAButtonText({
      ...missions,
      [event.target.name]: event.target.value
    });
  };

  const handleSelection = event => {
    const value = event.target.value !== "none" ? event.target.value : "";

    setCTAButtonTextSelection({
      ...missions,
      [event.target.name]: value
    });
  };

  useEffect(() => {
    if (viewType === "Edit") {
      if (
        missions.btnEnglishTextSelection !== "Play Now" &&
        missions.btnEnglishTextSelection !== "Answer Now" &&
        missions.btnEnglishTextSelection !== "Let’s Go" &&
        missions.btnEnglishTextSelection !== "Upgrade" &&
        missions.btnEnglishTextSelection !== "Download"
      ) {
        setCTAButtonTextSelection({
          ...missions,
          btnEnglishTextSelection: "Other"
        });
        setCTAButtonText({
          ...missions,
          btnEnglishText: missions.btnEnglishTextSelection,
          btnArabicText: missions.btnArabicTextSelection
        });
      }
    }
  }, [viewType]);

  return (
    <Grid className={classes.container} style={{ marginBlockStart: "24px" }}>
      <TextField
        id="button-english-text-selection"
        select
        label="Button text (En)"
        name="btnEnglishTextSelection"
        className={classes.textField}
        value={missions.btnEnglishTextSelection}
        onChange={handleSelection}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        helperText="Select button text (En)"
        margin="normal"
        variant="outlined"
        required
      >
        <option value="none">Select an Option</option>
        {buttonTextEnglish.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
      <TextField
        id="button-arabic-text-selection"
        select
        label="Button text (Ar)"
        name="btnArabicTextSelection"
        className={classes.textField}
        value={missions.btnArabicTextSelection}
        onChange={handleSelection}
        disabled={missions.btnEnglishTextSelection === "Other"}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        helperText="Select button text (Ar)"
        margin="normal"
        variant="outlined"
        required
      >
        <option value="none">Select an Option</option>
        {buttonTextArabic.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>

      {missions.btnEnglishTextSelection === "Other" && (
        <>
          <TextField
            id="button-english-text"
            name="btnEnglishText"
            label="Button text (En)"
            className={classes.textField}
            value={missions.btnEnglishText}
            onChange={handleTextChange}
            helperText="Max no. of characters is 10"
            margin="normal"
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="button-arabic-text"
            name="btnArabicText"
            label="Button text (Ar)"
            className={classes.textField}
            value={missions.btnArabicText}
            onChange={handleTextChange}
            helperText="Max no. of characters is 10"
            margin="normal"
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true
            }}
          />
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    missions: state.missions
  };
};

export default connect(
  mapStateToProps,
  { setCTAButtonText, setCTAButtonTextSelection }
)(ButtonTextComponent);

ButtonTextComponent.propTypes = {
  missions: PropTypes.object,
  setCTAButtonText: PropTypes.func,
  setCTAButtonTextSelection: PropTypes.func,
  viewType: PropTypes.string
};
