export const UPDATE_ORDER_BY = "update cashbacks conversions list order by";
export const UPDATE_ITEMS = "update cashbacks conversions list items";
export const ADD_SELECTED_ITEMS =
  "add cashbacks conversions list selected items";
export const DELETE_SELECTED_ITEMS =
  "delete cashbacks conversions list selected items";
export const ADD_SELECTED_ITEM = "add cashbacks conversions list selected item";
export const DELETE_SELECTED_ITEM =
  "delete cashbacks conversions list selected item";
export const UPDATE_ITEM = "update cashbacks conversions list item";

export const updateOrderBy = orderBy => dispatch =>
  dispatch({
    type: UPDATE_ORDER_BY,
    payload: { orderBy }
  });

export const updateItems = items => dispatch =>
  dispatch({
    type: UPDATE_ITEMS,
    payload: { items }
  });

export const addSelectedItems = selectedItems => dispatch =>
  dispatch({
    type: ADD_SELECTED_ITEMS,
    payload: { selectedItems }
  });

export const deleteSelectedItems = ids => dispatch =>
  dispatch({
    type: DELETE_SELECTED_ITEMS,
    payload: { ids }
  });

export const addSelectedItem = selectedItem => dispatch =>
  dispatch({
    type: ADD_SELECTED_ITEM,
    payload: { selectedItem }
  });

export const deleteSelectedItem = id => dispatch =>
  dispatch({
    type: DELETE_SELECTED_ITEM,
    payload: { id }
  });

export const updateItem = (id, item) => dispatch =>
  dispatch({
    type: UPDATE_ITEM,
    payload: { id, item }
  });
