import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  clearStateValue,
  fetchCashbackConfig,
  getHistoryList
} from "../../../redux/action/Configuration/cashbackConfig/FetchCashbackConfig";

// redux actions
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import styles from "../styles.css";
import CardBody from "../../../components/Card/CardBody.js";
import { Typography } from "@material-ui/core";
import ActionButtons from "../../ActionButtons/ActionButtons";
import ModalContainer from "./ModalContainer";
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from "@material-ui/icons/History";
const useStyles = makeStyles(styles);

const CashBackConfig = props => {
  const classes = useStyles();
  const {
    availableError,
    list,
    errMissionsConfigPoints,
    clearStateValue,
    redirect,
    fetchCashbackConfig,
    errCashbackConfigPoints,
    getHistoryList,
    historySuccess
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  if (availableError) {
    throw new Error();
  }

  useEffect(() => {
    if (redirect || errCashbackConfigPoints) {
      clearStateValue();
      fetchCashbackConfig();
    }
  }, [redirect]);

  const handleOpenModal = id => {
    setSelectedId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Cashback Config</h4>
          </CardHeader>
        </Card>
      </GridItem>
      {/* <ErrorMessage message="Sorry something went wrong!" /> */}

      {list.map(item => {
        return (
          <GridItem xs={12} sm={12} md={12} key={item.id}>
            <Card style={{ alignItems: "center" }}>
              <CardBody
                className={classes.cardBody}
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Card>
                  <CardBody className={classes.cardBody}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBlockStart: "16px",
                        alignItems: "center"
                      }}
                    >
                      <div>
                        <Typography
                          component="h2"
                          variant="h5"
                          color="textPrimary"
                        >
                          {item?.fromCurrency?.nameEn} to{" "}
                          {item?.toCurrency?.nameEn}
                        </Typography>
                      </div>

                      <Typography
                        component="p"
                        variant="p"
                        style={{
                          color: "#0abb87",
                          fontSize: "12px",
                          fontWight: 500
                        }}
                      >
                        Rate:{" "}
                        {item?.exchangeRate &&
                          Number.parseFloat(item?.exchangeRate)?.toFixed(3)}
                      </Typography>
                    </div>
                  </CardBody>
                </Card>
                <ActionButtons
                  item={item}
                  viewType="Edit"
                  deleteButton={false}
                  editButton={
                    !(
                      item?.fromCurrency?.nameEn?.toLowerCase() === "points" ||
                      item?.toCurrency?.nameEn?.toLowerCase() === "points"
                    )
                  }
                  typeFieldConfig="MissionsConfigUpdate"
                />
                <IconButton
                  color="primary"
                  component="span"
                  className={`${classes.defaultButton}`}
                  onClick={() => handleOpenModal(item.id)}
                  title="History"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 50,
                    boxShadow: " 0 1px 4px 0 rgb(0 0 0 / 14%)",
                    padding: "9px"
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </CardBody>
            </Card>
          </GridItem>
        );
      })}
      {openModal && selectedId && (
        <ModalContainer
          open={openModal}
          handleClose={handleCloseModal}
          title="History cashback"
          currentId={selectedId}
        />
      )}
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    availableError: state.availableError.availableError,
    list: state.cashbackConfig.list,
    redirect: state.cashbackConfig.redirect,
    errMissionsConfigPoints: state.cashbackConfig.errMissionsConfigPoints,
    errCashbackConfigPoints: state.cashbackConfig.errCashbackConfigPoints,
    historySuccess: state.cashbackConfig.historySuccess
  };
};
export default connect(
  mapStateToProps,
  { clearStateValue, fetchCashbackConfig, getHistoryList }
)(CashBackConfig);

CashBackConfig.propTypes = {
  availableError: PropTypes.bool,
  list: PropTypes.array,
  errMissionsConfigPoints: PropTypes.bool,
  clearStateValue: PropTypes.func,
  redirect: PropTypes.bool,
  fetchCashbackConfig: PropTypes.func,
  getHistoryList: PropTypes.func,
  errCashbackConfigPoints: PropTypes.bool
};
