/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { basename } from "./config";
import dashboardLogger from "./modules/dashboard-logger";
import loggerSubscriberConsole from "./logger-subscribers/console";

dashboardLogger.subscribe(loggerSubscriberConsole);

import "./assets/css/material-dashboard-react.css?v=1.8.0";
import App from "./app";

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist} basename={basename}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
