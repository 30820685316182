import {
  UPDATE_ORDER_BY,
  UPDATE_ITEMS,
  ADD_SELECTED_ITEMS,
  DELETE_SELECTED_ITEMS,
  ADD_SELECTED_ITEM,
  DELETE_SELECTED_ITEM,
  UPDATE_ITEM
} from "../../actions/list";

const initialState = {
  orderBy: "createdDate",
  items: [],
  selectedItems: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload.orderBy
      };
    case UPDATE_ITEMS:
      return {
        ...state,
        items: action.payload.items
      };
    case ADD_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: [...state.selectedItems, ...action.payload.selectedItems]
      };
    case DELETE_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: state.selectedItems.filter(
          selectedItem => action.payload.ids.indexOf(selectedItem.id) === -1
        )
      };
    case ADD_SELECTED_ITEM:
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.payload.selectedItem]
      };
    case DELETE_SELECTED_ITEM:
      return {
        ...state,
        selectedItems: state.selectedItems.filter(
          selectedItem => selectedItem.id !== action.payload.id
        )
      };
    case UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id
            ? { ...item, ...action.payload.item }
            : item
        )
      };
    default:
      return state;
  }
};
