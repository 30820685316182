import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles.css";
import Upload from "./assets/upload.png";

const useStyles = makeStyles(styles);

const UploadContainer = props => {
  const {
    handleLogoUrlClick,
    logoUrl,
    logoUrlElement,
    handleLogoUrlChange,
    accept,
    label1,
    label2,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.uploadContainer} onClick={handleLogoUrlClick}>
      <div>
        <img src={Upload} alt="upload icon" />
        <br />
        <span>
          <p>Click to upload</p> or drag and drop
        </span>
        <br />
        {label1 || "SVG, PNG, JPG or GIF (max. 800x400px)"}
        <span>{label2}</span>
      </div>
      <input
        ref={logoUrlElement}
        onChange={handleLogoUrlChange}
        type="file"
        accept={accept || "*"}
        style={{ position: "absolute", opacity: 0 }}
        required={!Boolean(logoUrl)}
      />
    </div>
  );
};

export default UploadContainer;
