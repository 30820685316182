import * as redemptionService from "../../../../services/redemption";

export const GET_EXCHANGE_RATES = "get exchange rates";
export const UPDATE_EXCHANGE_RATES = "update exchange rates";

export const getExchangeRates = () => dispatch =>
  redemptionService.getExchangeRates().then(data => {
    dispatch({
      type: GET_EXCHANGE_RATES,
      payload: data
    });
  });

export const updateExchangeRates = exchangeRates => dispatch =>
  redemptionService.updateExchangeRates(exchangeRates).then(data => {
    dispatch({
      type: UPDATE_EXCHANGE_RATES,
      payload: exchangeRates
    });
  });
