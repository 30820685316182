import { FETCH_ANNOUNCEMENT_CARDS } from "../../action/actionType";

const initialState = {
  announcementCardsList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ANNOUNCEMENT_CARDS:
      return {
        ...state,
        announcementCardsList: action.payload
      };
    default:
      return state;
  }
};
