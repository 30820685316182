import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import withShield from "./with-shield";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import PageTitle from "../../components/page-title";
import SectionHead from "../../components/section-head";

import configurations from "../../configurations";
import { redemptionUserRequestsPath } from "./paths";

import redemptionActions from "./actions";

import egypt from "./assets/egypt.png";
import nigeria from "./assets/nigeria.png";

const Redemption = props => {
  const { updateCountry } = props;

  const [countryCode, setCountryCode] = useState(
    configurations.application.defaultCountryCode
  );

  const history = useHistory();

  const getFlag = countryCode => {
    switch (countryCode) {
      case "EG":
        return egypt;
      case "NG":
        return nigeria;
      default:
        return null;
    }
  };

  const handleCountrySubmit = e => {
    updateCountry(countryCode);
    history.push(
      [configurations.dashboard.basePath, redemptionUserRequestsPath].join("")
    );
  };

  const handleCountryChange = e => {
    setCountryCode(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <PageTitle title="Redemptions" />
      <Grid container justifyContent="center" rowSpacing={2}>
        <Grid item lg={6}>
          <Stack spacing={2}>
            <SectionHead
              title="Country"
              description="Select country to proceed"
            />
            <Divider />
            <Card variant="outlined">
              <CardContent>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={countryCode}
                    onChange={handleCountryChange}
                    displayEmpty
                    id="redemption-country-dropdown"
                    inputProps={{
                      id: "redemption-country"
                    }}
                    MenuProps={{
                      id: "redemption-country-menu"
                    }}
                    disabled={configurations.application.countries.length === 1}
                  >
                    {configurations.application.countries.map(country => (
                      <MenuItem key={country.code} value={country.code}>
                        <Stack spacing={1} direction="row">
                          <img src={getFlag(country.code)} />
                          <span>{country.name}</span>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
              <CardActions
                sx={{
                  borderTop: 1,
                  borderColor: "divider",
                  pb: 1,
                  justifyContent: "end"
                }}
              >
                <Stack spacing={2} p={1} direction="row">
                  <Button
                    variant="contained"
                    onClick={handleCountrySubmit}
                    id="redemption-next"
                  >
                    Next
                  </Button>
                </Stack>
              </CardActions>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default withShield(
  connect(
    null,
    {
      updateCountry: redemptionActions.country.update
    }
  )(Redemption)
);
