import authenticatedHttpClientService from "../authenticated-http-client";

export const getExchangeRates = () =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_REDEMPTION_BASE_URL}/exchangerates`)
    .then(response => response.data);

export const updateExchangeRates = exchangeRates =>
  authenticatedHttpClientService.patch(
    `${process.env.REACT_APP_REDEMPTION_BASE_URL}/exchangerates`,
    {
      updatedExchangeRates: exchangeRates.map(exchangeRate => ({
        exchangeRateId: exchangeRate.id,
        exchangeRateValue: exchangeRate.exchangeRate
      }))
    }
  );

export const getExchangeRatesHistory = (page, size) =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_REDEMPTION_BASE_URL}/exchangerates/history?page=${page}&size=${size}`,
      {
        data: { page, size }
      }
    )
    .then(response => response.data);

export const getMeta = country =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_REDEMPTION_BASE_URL}/redemption/options/meta?country=${country}`
    )
    .then(response => response.data);

export const createOption = option =>
  authenticatedHttpClientService.post(
    `${process.env.REACT_APP_REDEMPTION_BASE_URL}/redemption-options`,
    {
      providerId: option.provider.id,
      categoryId: option.category.id,
      type: option.type,
      titleEn: option.titleEn,
      titleAr: option.titleAr,
      productId: option.productId,
      requestInputs: option.requestInputs.map(input => input.id),
      countryCode: option.country,
      currencyCode: option.currency,
      denominations: option.denominations.map(denomination =>
        option.currency === "USD"
          ? Number(denomination.moneyValue)
          : Number(denomination.localMoneyValue)
      )
    }
  );

export const updateOption = (id, option) =>
  authenticatedHttpClientService.patch(
    `${process.env.REACT_APP_REDEMPTION_BASE_URL}/redemption-options/${id}`,
    option
  );

export const updateOptionImage = (id, option) => {
  let formData = new FormData();
  formData.append("image", option.image);
  formData.append("color", option.color);
  return authenticatedHttpClientService.post(
    `${process.env.REACT_APP_REDEMPTION_BASE_URL}/redemption-options/${id}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
};

export const getOptions = (
  country,
  page,
  size,
  query,
  productId,
  cost,
  provider,
  category,
  type
) =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_REDEMPTION_BASE_URL}/redemption-options?country=${country}&page=${page}&limit=${size}&titleEn=${query}&productId=${productId}&denominationPointsValue=${cost}&providerId=${provider}&categoryId=${category}&type=${type}`
    )
    .then(response => response.data);

export const deleteOption = option =>
  authenticatedHttpClientService.delete(
    `${process.env.REACT_APP_REDEMPTION_BASE_URL}/redemption-options/${option}`
  );

export const updateOptionDenominationStatus = (option, denomination, status) =>
  authenticatedHttpClientService.patch(
    `${process.env.REACT_APP_REDEMPTION_BASE_URL}/redemption-options/${option}/denomination/${denomination}`,
    {
      isActive: status
    }
  );

export const updateUserRequestStatus = (id, payload) =>
  authenticatedHttpClientService
    .patch(
      `${process.env.REACT_APP_REDEMPTION_BASE_URL}/requests/${id}`,
      payload
    )
    .then(response => response.data);

export const getUserRequests = (
  country,
  page,
  size,
  query,
  from,
  to,
  provider,
  category,
  type,
  currency,
  orderBy
) => {
  const params = [];
  params[params.length] = { key: "country", value: country };
  params[params.length] = { key: "page", value: page };
  params[params.length] = { key: "size", value: size };
  if (query) params[params.length] = { key: "query", value: query };
  if (from) params[params.length] = { key: "from", value: from };
  if (to) params[params.length] = { key: "to", value: to };
  if (provider) params[params.length] = { key: "provider", value: provider };
  if (category) params[params.length] = { key: "category", value: category };
  if (type) params[params.length] = { key: "type", value: type };
  if (currency) params[params.length] = { key: "currency", value: currency };
  if (orderBy) params[params.length] = { key: "orderBy", value: orderBy };
  return authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_REDEMPTION_BASE_URL}/requests?${params
        .map(param => `${param.key}=${param.value}`)
        .join("&")}`
    )
    .then(response => response.data);
};
