import qs from "qs";
import Moment from "moment";
import { waslaRewardBaseurl, authURL } from "../../../components/config";

import {
  FETCH_REWARD_API_LIST,
  SET_LOADING_REWARD_LIST,
  SET_OPERATOR_FILTER_TYPE,
  SET_STATUS_FILTER_TYPE,
  ADD_SELECTED_VALUES,
  REMOVE_SELECT_OPERATOR,
  ADD_START_DATE_FILTER,
  ADD_END_DATE_FILTER,
  REST_FILTER_VALUES
} from "../actionType";

export const setOperatorType = operator => {
  return {
    type: SET_OPERATOR_FILTER_TYPE,
    payload: operator
  };
};

export const setStatusType = status => {
  return {
    type: SET_STATUS_FILTER_TYPE,
    payload: status
  };
};
export const addSelectedValues = ({ operatorSelected, statusSelected }) => {
  return {
    type: ADD_SELECTED_VALUES,
    payload: {
      operatorSelected,
      statusSelected
    }
  };
};

export const removeSelectOperator = item => {
  return {
    type: REMOVE_SELECT_OPERATOR,
    payload: item
  };
};

export const setStartDateFilter = date => {
  return {
    type: ADD_START_DATE_FILTER,
    payload: date
  };
};
export const setEndDateFilter = date => {
  return {
    type: ADD_END_DATE_FILTER,
    payload: date
  };
};
export const restFiltersValues = () => {
  return {
    type: REST_FILTER_VALUES
  };
};
export const onSubmitFilter = ({
  pageNum,
  startDate,
  endDate,
  operator,
  success,
  batchId,
  projectId
}) => async dispatch => {
  const convertStartDate = startDate && Moment(startDate).format("YYYY-MM-DD");
  const convertEndDate = endDate && Moment(endDate).format("YYYY-MM-DD");
  dispatch({ type: SET_LOADING_REWARD_LIST, payload: true });

  await waslaRewardBaseurl
    .get(`/external/cashout/charge/?pageNo=${pageNum || 1}&pageSize=10`, {
      params: {
        startDate: convertStartDate,
        endDate: convertEndDate,
        operator,
        success,
        bulkId: batchId,
        projectId
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "comma",
          encode: false,
          skipNulls: true
        });
      }
    })
    .then(res => {
      dispatch({
        type: FETCH_REWARD_API_LIST,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING_REWARD_LIST, payload: false });
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_SERVER_RECHARGE",
          payload: "Authentication expired"
        });
      }
      dispatch({
        type: "SET_ERROR_SERVER_RECHARGE",
        payload: "Something went wrong"
      });
    });
};
