import Users from "./Users";
import WaslaGreyIcon from "../../assets/img/greyWaslaIcon.svg";

import { usersPath } from "./paths";

import userConfig from "./user/userConfig";

export default {
  routes: [
    {
      path: usersPath,
      name: "CS - Users",
      icon: WaslaGreyIcon,
      component: Users,
      layout: "main",
      isPrivate: null,
      roles: ["admin", "cs"],
      routes: [...userConfig.routes]
    }
  ]
};
