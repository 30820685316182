import {
  SET_MISSIONS_CTA_BUTTON_TEXT,
  SET_CTA_BUTTON_TEXT_SELECTION,
  SET_BUTTON_URL
} from "../actionType";

export const setCTAButtonText = data => {
  return {
    type: SET_MISSIONS_CTA_BUTTON_TEXT,
    payload: { ...data }
  };
};

export const setCTAButtonTextSelection = data => {
  return {
    type: SET_CTA_BUTTON_TEXT_SELECTION,
    payload: { ...data }
  };
};

export const setButtonUrl = url => {
  return {
    type: SET_BUTTON_URL,
    payload: url
  };
};
