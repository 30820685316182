export const Validation = {
  isOperator: function(str) {
    let operators = ["Orange", "Vodafone", "Etisalat", "WE"];
    const theSame = element =>
      this.isSame(element.toLowerCase(), str.toLowerCase());

    return !operators.some(theSame);
  },
  isNotEmpty: function(str) {
    var pattern = new RegExp("/S+/");
    return !pattern.test(str); // returns a boolean
  },
  isPhoneNumber: function(str) {
    var pattern = new RegExp("^01[0125][0-9]{8}$");
    return !pattern.test(str); // returns a boolean
  },
  validateAmount: function(amount) {
    let netAmount = amount / 1.428;
    netAmount = parseFloat(netAmount).toFixed(2);

    const netValue = netAmount % Math.floor(netAmount);
    if (+netValue.toFixed(2) === 0.0 || +netValue.toFixed(2) === 0.5) {
      return true;
    }
    return false;
  },
  isNumber: function(str, operator) {
    let pattern = new RegExp("^-?\\d+.?\\d*$");
    let isBetween;
    if (operator?.toLowerCase() === "Vodafone".toLowerCase()) {
      isBetween = str >= 7.14 && this.validateAmount(str) && str <= 285.6;
    }
    if (operator?.toLowerCase() === "Orange".toLowerCase()) {
      isBetween = this.validateAmount(str) && str <= 714;
    }
    if (operator?.toLowerCase() === "Etisalat".toLowerCase()) {
      isBetween = str >= 7.14 && this.validateAmount(str) && str <= 357;
    }
    if (operator?.toLowerCase() === "WE".toLowerCase()) {
      isBetween = str >= 7.14 && this.validateAmount(str) && str <= 428.4;
    }
    return !pattern.test(str) || !isBetween; // returns a boolean
  },
  isSame: function(str1, str2) {
    return str1.toLowerCase() === str2.toLowerCase();
  }
};
