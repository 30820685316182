import { getExchangeRates, updateExchangeRates } from "./exchange-rates";
import { getExchangeRatesHistory } from "./exchange-rates/history";
import {
  updateExchangeRate,
  updateExchangeRatesChangesStatus,
  updateExchangeRatesErrorsStatus,
  resetExchangeRates,
  syncExchangeRates,
  initExchangeRatesHistoryPagination,
  changeExchangeRatesHistoryPage,
  syncExchangeRatesHistory
} from "./layout";

export default {
  exchangeRates: {
    get: getExchangeRates,
    update: updateExchangeRates
  },
  exchangeRatesHistory: {
    get: getExchangeRatesHistory
  },
  layout: {
    exchangeRates: {
      updateItem: updateExchangeRate,
      status: {
        changes: {
          update: updateExchangeRatesChangesStatus
        },
        errors: {
          update: updateExchangeRatesErrorsStatus
        }
      },
      reset: resetExchangeRates,
      sync: syncExchangeRates
    },
    exchangeRatesHistory: {
      pagination: {
        init: initExchangeRatesHistoryPagination,
        change: changeExchangeRatesHistoryPage
      },
      sync: syncExchangeRatesHistory
    }
  }
};
