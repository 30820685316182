import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
// core components
import PointsView from "./View";
import ErrorBoundary from "../ErrorBoundary";

const CsPoints = props => {
  return (
    <>
      <Route
        exact
        path={props.match.path}
        render={routeProps => (
          <ErrorBoundary>
            <PointsView {...routeProps} />
          </ErrorBoundary>
        )}
      />
    </>
  );
};

export default connect(null)(CsPoints);

CsPoints.propTypes = {
  match: PropTypes.object
};
