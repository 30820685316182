import { SET_IMAGE_URL, SET_IMAGE_ICON_URL } from "./actionType";

export const setCardImage = url => {
  return {
    type: SET_IMAGE_URL,
    payload: url
  };
};

export const setIconImage = url => {
  return {
    type: SET_IMAGE_ICON_URL,
    payload: url
  };
};
