import qs from "qs";
import authenticatedHttpClientService from "../../../services/authenticated-http-client";
import { toggleErrorMessage } from "../ErrorMessageActions";

import {
  FETCH_QUICK_LINKS,
  DELETE_QUICK_LINK,
  RESET_STATE
} from "../actionType";

export const fetchQuickLinks = ({ countrySelected }) => async dispatch => {
  await authenticatedHttpClientService
    .get(`${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/links`, {
      params: {
        country_code: countrySelected
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "comma",
          encode: false,
          skipNulls: true
        });
      }
    })
    .then(res => {
      dispatch({
        type: FETCH_QUICK_LINKS,
        payload: res.data
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};

export const deleteQuickLinks = id => async dispatch => {
  await authenticatedHttpClientService
    .delete(`${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/links/${id}`)
    .then(() => {
      dispatch({
        type: DELETE_QUICK_LINK,
        payload: id
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};

export const clearQuickLinksState = () => {
  return {
    type: RESET_STATE
  };
};
