import {
  ADD_CATEGORY,
  EDIT_CATEGORY,
  REMOVE_CATEGORY,
  GET_CATEGORIES,
  EDIT_CATEGORIES
} from "../../actions/categories";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return [...state, action.payload];
    case EDIT_CATEGORY:
      return state.map(category =>
        category.id === action.payload.id
          ? { ...category, ...action.payload.data }
          : category
      );
    case REMOVE_CATEGORY:
      return state.filter(category => category.id !== action.payload);
    case GET_CATEGORIES:
    case EDIT_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};
