import axios from "axios";
import Cookies from "js-cookie";
import tokenProvider from "axios-token-interceptor";

import { config } from "../env";

export const adminBaseUrl = axios.create({
  baseURL: config.url.adminBaseUrl
});

export const announcementBaseUrl = axios.create({
  baseURL: config.url.announcementBaseUrl
});

export const missionsBaseUrl = axios.create({
  baseURL: config.url.missionsBaseUrl
});

export const pointsBaseUrl = axios.create({
  baseURL: config.url.pointsBaseUrl
});

export const pushNotificationBaseurl = axios.create({
  baseURL: config.url.pushNotificationBaseurl
});

export const missionsPromoCodeBaseUrl = axios.create({
  baseURL: config.url.missionsPromoCodeUrl
});

export const waslaRewardBaseurl = axios.create({
  baseURL: config.url.waslaRewardBaseUrl
});

export const rolesUsersBaseUrl = axios.create({
  baseURL: config.url.rolesUsersBaseUrl
});
export const identityBaseUrl = axios.create({
  baseURL: config.url.identityBaseUrl
});

export const userProfileBaseUrl = axios.create({
  baseURL: config.url.userProfileBaseUrl
});

export const dealsBaseurl = axios.create({
  baseURL: config.url.dealsBaseurl
});

export const cashbackConfigBaseurl = axios.create({
  baseURL: config.url.cashbackConfigBaseUrl
});

rolesUsersBaseUrl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);

missionsBaseUrl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);
missionsPromoCodeBaseUrl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);
adminBaseUrl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  })
);

waslaRewardBaseurl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);

pointsBaseUrl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);

identityBaseUrl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);

userProfileBaseUrl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);

dealsBaseurl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);

cashbackConfigBaseurl.interceptors.request.use(
  tokenProvider({
    getToken: () => localStorage.getItem("access_token")
  }),
  error => {
    return Promise.reject(error);
  }
);

export const isDev = false;
export const authURL = config.url.authenticationBaseUrl;
