import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "moment";

import ActionButtons from "../../ActionButtons/ActionButtons";

// @material-ui/core components
import { TableBody, TableCell, TableRow, Typography } from "@material-ui/core";

// redux actions
import {
  fetchDealsList,
  handleDeleteDeal,
  closeNotification,
  resetUploadDealsList
} from "../Actions/FetchDealsList";

// core components
import CustomTable from "../customTable";

import {
  getComparator,
  stableSort
} from "../../WaslaRewards/UsersLogsTable/helper";
import { headCells } from "./headCells";
import ModalContainer from "./ModalContainer";
import { fetchTargetList } from "../Actions/FetchTargetList";
import { fetchMerchantList } from "../Actions/FetchMerchantList";

function DealsTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [dense, setDense] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const {
    list,
    hasNext,
    fetchDealsList,
    error,
    isLoading,
    handleDeleteDeal,
    closeNotification,
    fetchTargetList,
    targetList,
    merchantsList,
    fetchMerchantList,
    success,
    totalPages,
    limit,
    deleteSuccessMessage,
    resetUploadDealsList,
    uploadSuccessMessage,
    countrySelected
  } = props;
  useEffect(() => {
    fetchTargetList({
      pageNum,
      limit: 1000000
    });
    fetchMerchantList({
      pageNum,
      limit: 1000000
    });
  }, []);

  const convertUtcToMilliseconds = date => {
    let convertDate = new Date(date);
    return date && Moment(convertDate).valueOf();
  };

  const createRows = useCallback(() => {
    const retrieveRechargeList = list.map(item => {
      const getTarget = targetList.find(
        target => target.id === item?.target?.id
      );
      const getMerchant = merchantsList.find(
        merchant => merchant.id === item?.target?.merchantId
      );
      return {
        titleEn: item.titleEn,
        titleAr: item.titleAr,
        dealValue: `${item.dealValue} ${item.dealValueUnitEn || ""}`,
        id: item.id,
        descriptionEn: item.descriptionEn,
        descriptionAr: item.descriptionAr,
        startDate: convertUtcToMilliseconds(item.startDate),
        endDate: convertUtcToMilliseconds(item.endDate),
        status: item.isExpired,
        dealType: item.dealType,
        categoryEn: item.categoryEn,
        categoryAr: item.categoryAr,
        countryCode: item.countryCode,
        dealActivationValue: item.dealActivationValue,
        dealActivationMethod: item.dealActivationMethod,
        // domain: item.domain,
        merchantId: getMerchant?.id,
        merchantNameAr: getMerchant?.nameAr,
        merchantNameEn: getMerchant?.nameEn,
        targetNameAr: getTarget?.nameAr,
        targetNameEn: getTarget?.nameEn,
        targetId: getTarget?.id,
        comment: item.comment
      };
    });
    setRows([...retrieveRechargeList]);
  }, [list, targetList, merchantsList]);

  useEffect(() => {
    if (list.length !== 0) {
      createRows(list);
    }
  }, [list, targetList, merchantsList]);

  const emptyRows = 10 - Math.min(10, rows.length - 0 * 10);

  useEffect(() => {
    if (pageNum >= 1) {
      fetchDealsList({
        pageNum,
        countrySelected
      });
    }
  }, [pageNum, countrySelected]);

  useEffect(() => {
    if (countrySelected && pageNum !== 1) {
      setPageNum(1);
      fetchDealsList({ pageNum: 1, countrySelected });
    }
  }, [countrySelected]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    resetUploadDealsList();
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleChangePagination = (event, value) => {
    setPageNum(value);
  };

  return (
    <>
      <CustomTable
        headCells={headCells}
        list={list}
        isLoading={isLoading}
        error={error}
        closeNotification={closeNotification}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        orderBy={orderBy}
        order={order}
        hasNext={hasNext}
        title="All Deals"
        BtnType="Create Deals"
        pageNum={pageNum}
        setPageNum={setPageNum}
        handleOpenModal={handleOpenModal}
        success={success}
        totalPages={totalPages}
        limit={limit}
        handleChangePagination={handleChangePagination}
        deleteSuccessMessage={deleteSuccessMessage}
        uploadSuccessMessage={uploadSuccessMessage}
        type="deal"
      >
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={labelId}
                style={{ padding: 12 }}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "10px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    minWidth: "140px"
                  }}
                  title={row.titleEn}
                >
                  {row.titleEn}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    minWidth: "140px",
                    paddingRight: "10px"
                  }}
                  title={row.titleAr}
                >
                  {row.titleAr}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    paddingRight: "10px"
                  }}
                >
                  {row.dealValue}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{ paddingRight: "12px" }}
                >
                  {row.id}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.descriptionEn}
                >
                  {row.descriptionEn}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.descriptionAr}
                >
                  {row.descriptionAr}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.startDate
                    ? Moment(row.startDate).format("DD MMM, YYYY, LT")
                    : ""}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.endDate
                    ? Moment(row.endDate).format("DD MMM, YYYY, LT")
                    : ""}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {!row.status ? (
                    <Typography
                      variant="subtitle1"
                      component="p"
                      style={{
                        backgroundColor: "rgba(10,187,135,.1)",
                        color: "#0abb87",
                        padding: "6px 16px",
                        display: "inline-block",
                        margin: "0.2em 0",
                        borderRadius: "50px",
                        fontSize: "14px"
                      }}
                    >
                      on
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      component="p"
                      style={{
                        backgroundColor: "rgba(253,57,122,.1)",
                        color: "#fd397a",
                        padding: "6px 32px",
                        display: "inline-block",
                        margin: "0.2em 0",
                        borderRadius: "50px",
                        fontSize: "14px"
                      }}
                    >
                      Expired
                    </Typography>
                  )}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.dealType}
                </TableCell>

                <TableCell
                  scope="row"
                  padding="none"
                  style={{ paddingRight: "12px" }}
                >
                  {row.dealActivationValue}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.dealActivationMethod}
                </TableCell>
                {/* <TableCell
                scope="row"
                padding="none"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "160px",
                  minWidth: "170px",
                  paddingRight: "10px"
                }}
                title={row.domain}
              >
                {row.domain}
              </TableCell> */}
                <TableCell scope="row" padding="none">
                  {row.categoryEn}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.categoryAr}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.countryCode}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{ paddingRight: "12px" }}
                >
                  {row.merchantId}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.merchantNameEn}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.merchantNameAr}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{ paddingRight: "12px" }}
                >
                  {row.targetId}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.targetNameEn}
                </TableCell>
                <TableCell scope="row" padding="none">
                  {row.targetNameAr}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{ paddingRight: "12px" }}
                >
                  <p
                    title={row.comment}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "140px",
                      minWidth: "120px"
                    }}
                  >
                    {row.comment}
                  </p>
                </TableCell>
                <TableCell scope="row" padding="none">
                  <ActionButtons
                    item={row}
                    deleteButton={true}
                    editButton={false}
                    handleDelete={() =>
                      handleDeleteDeal(row.id, pageNum, countrySelected)
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </CustomTable>
      {openModal && (
        <ModalContainer
          open={openModal}
          handleClose={handleCloseModal}
          title="Create Deals"
        />
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    list: state.dealsList.list,
    hasNext: state.dealsList.hasNext,
    isLoading: state.dealsList.isLoading,
    error: state.dealsList.serverError,
    success: state.dealsList.success,
    targetList: state.targetList.list,
    merchantsList: state.merchantsList.list,
    totalPages: state.dealsList.totalPages,
    limit: state.dealsList.limit,
    deleteSuccessMessage: state.dealsList.deleteSuccessMessage,
    uploadSuccessMessage: state.dealsList.uploadSuccessMessage,
    countrySelected: state.Countries.countryNameSelected
  };
};

export default connect(
  mapStateToProps,
  {
    fetchDealsList,
    handleDeleteDeal,
    closeNotification,
    fetchTargetList,
    fetchMerchantList,
    resetUploadDealsList
  }
)(DealsTable);

DealsTable.propTypes = {
  list: PropTypes.array,
  hasNext: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleDeleteDeal: PropTypes.func,
  closeNotification: PropTypes.func,
  fetchTargetList: PropTypes.func,
  fetchMerchantList: PropTypes.func,
  error: PropTypes.string,
  uploadSuccessMessage: PropTypes.string,
  countrySelected: PropTypes.string
};
