import Edit from "./Edit";
import { redemptionCatalogueEditPath } from "../../paths";

export default {
  routes: [
    {
      path: redemptionCatalogueEditPath,
      component: Edit,
      layout: "main",
      isPrivate: false,
      roles: ["admin"],
      routes: []
    }
  ]
};
