import { FETCH_MISSIONS } from "../../action/actionType";

const initialState = {
  missionsCardsList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MISSIONS:
      return {
        ...state,
        missionsCardsList: action.payload
      };
    default:
      return state;
  }
};
