const styles = {
  cardContainer: {
    width: "100%",
    marginBottom: "0px",
    padding: "0px",
    borderRadius: "18px"
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    paddingBottom: "0px"
  },
  cardButton: {
    width: "25%",
    alignSelf: "flex-end",
    background: "linear-gradient(90deg, #fb6d3a, #fec70c)",
    color: "#fff",
    borderRadius: "30px",
    marginBlockStart: "-18px",
    marginInlineEnd: "28px"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "0px"
  },
  imgIconUrl: {
    width: "60px",
    objectFit: "cover",
    height: "60px",
    borderRadius: "50%",
    marginRight: "10px"
  },
  containerContent: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
    "& div": {
      paddingBottom: 0
    }
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
    borderRadius: "15px"
  }
};

export default styles;
