import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
/// Action
import {
  fetchProgressRecharges,
  resetBatchState
} from "../../../redux/action/WaslaRewards/BatchesAction";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomizedSnackBars from "../SnackBar/SnackBar";
import "./style.css";
const useStyles = makeStyles(theme => ({
  nameType: {
    fontSize: 12,
    marginBlock: 0,
    fontWeight: 500,
    color: "#000"
  }
}));
const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#35C28F"
  }
}))(LinearProgress);

const ProgressComponent = props => {
  const {
    fetchProgressRecharges,
    totalTransaction,
    totalCompleted,
    serverError,
    resetBatchState
  } = props;
  const [progress, setProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const location = useLocation();
  const projectId = location.state.projectId;
  const batchId = location.state.batchId;
  let time;
  useEffect(() => {
    if (totalTransaction === totalCompleted) {
      setProgress((totalCompleted / totalTransaction) * 100);
      setLoading(false);
    }
    if (totalTransaction > totalCompleted) {
      setProgress((totalCompleted / totalTransaction) * 100);
      setLoading(true);

      time = setTimeout(() => {
        fetchProgressRecharges(projectId, batchId);
      }, 10000);
    }
    return () => {
      clearTimeout(time);
    };
  }, [totalTransaction, totalCompleted]);

  useEffect(() => {
    fetchProgressRecharges(projectId, batchId);
  }, []);

  useEffect(() => {
    return () => {
      resetBatchState();
    };
  }, []);

  return (
    <Grid container spacing={2} alignItems="center">
      {loading && (
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      <Grid item xs={4}>
        <p className={classes.nameType}>
          Transaction Processing: Batch - {batchId}
        </p>
        <BorderLinearProgress variant="determinate" value={progress} />
        <p
          className={classes.nameType}
        >{`${totalCompleted} of ${totalTransaction} Transaction Completed`}</p>
      </Grid>
      {!!serverError && (
        <CustomizedSnackBars
          severity={"error"}
          messege={serverError}
          errorMsg={!!serverError}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    totalTransaction: state.batches.totalTransaction,
    totalCompleted: state.batches.totalCompleted,
    name: state.batches.name,
    serverError: state.batches.serverError
  };
};

export default connect(
  mapStateToProps,
  { fetchProgressRecharges, resetBatchState }
)(ProgressComponent);

ProgressComponent.propTypes = {
  fetchProgressRecharges: PropTypes.func,
  resetBatchState: PropTypes.func,
  totalTransaction: PropTypes.number,
  totalCompleted: PropTypes.number,
  name: PropTypes.string,
  errorServer: PropTypes.string
};
