import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
// core components
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardDetails from "./CardDetails";
import RecordStatus from "../../Shared/RecordStatus";
import { announcementBaseUrl } from "../../config";
import styles from "../styles.css";

const useStyles = makeStyles(styles);

const AnnouncementCardsDetails = props => {
  const classes = useStyles();
  const item = props.location.state ? props.location.state.item : "";
  const isEnded = Moment(item.endDate) > Moment(Date.now());

  return (
    <>
      <Card>
        <CardHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography component="h1" variant="h6">
            Name:
            <Typography
              component="p"
              variant="body1"
              style={{ display: "inline-flex", marginLeft: "8px" }}
            >
              {item.title.en}
            </Typography>
          </Typography>
          <RecordStatus startDate={item.startDate} endDate={item.endDate} />
        </CardHeader>
        <CardContent>
          <Typography
            component="h1"
            variant="h6"
            style={{ textDecoration: "underline", marginBottom: "1em" }}
          >
            Card Details
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              Start Date:
              <Typography
                component="span"
                variant="body1"
                style={{ marginLeft: "8px" }}
              >
                {Moment(item.startDate).format("MMMM DD, YYYY")}
              </Typography>
            </Typography>
            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              End Date:
              <Typography
                component="span"
                variant="body1"
                style={{ marginLeft: "8px" }}
              >
                {Moment(item.endDate).format("MMMM DD, YYYY")}
              </Typography>
            </Typography>
            {isEnded && (
              <Typography
                component="p"
                variant="body1"
                style={{ flexBasis: "33%" }}
                gutterBottom={true}
              >
                Remaining Days:
                <Typography
                  component="span"
                  variant="body1"
                  style={{
                    marginLeft: "8px",
                    color: "#009900"
                  }}
                >
                  {`${Moment(item.endDate).diff(
                    Moment(Date.now()),
                    "days"
                  )} days`}
                </Typography>
              </Typography>
            )}
            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              Location:
              <Typography
                component="span"
                variant="body1"
                style={{ marginLeft: "8px" }}
              >
                {item.location}
              </Typography>
            </Typography>
            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              No. of impressions:
              <Typography
                component="span"
                variant="body1"
                style={{ marginLeft: "8px" }}
              >
                {item.numOfImpressions}
              </Typography>
            </Typography>
            <Typography
              component="p"
              variant="body1"
              style={{ flexBasis: "33%" }}
              gutterBottom={true}
            >
              Targeting:
              <Typography
                component="span"
                variant="body1"
                style={{ marginLeft: "8px" }}
              >
                {item.targetType}
              </Typography>
            </Typography>
            {item.targetType === "specific" && (
              <Button
                className={classes.buttonExport}
                href={`${announcementBaseUrl}/announcement/${item._id ||
                  item.id}/codesCSV`}
              >
                Export CSV Here
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
      <CardDetails item={item} />
    </>
  );
};

export default AnnouncementCardsDetails;

AnnouncementCardsDetails.propTypes = {
  location: PropTypes.object
};
