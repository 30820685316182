import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ActionButtons from "../../ActionButtons/ActionButtons";
import {
  fetchSectionsList,
  closeNotification,
  handleDeleteSection,
  submitUpdateSection,
  resetSectionList,
  submitCreateSection
} from "../../../redux/action/Missions/SectionsActions";
// @material-ui/core components
import { TableBody, TableCell, TableRow, Button } from "@material-ui/core";

// core components
import CustomTable from "../customTable";
import EditSection from "./formSections";

import {
  getComparator,
  stableSort
} from "../../WaslaRewards/UsersLogsTable/helper";
import { headCells } from "./headCells";
// import ModalContainer from "./ModalContainer";
import EditIcon from "../../../assets/img/EditIcon.svg";
// import { Validation } from "./Validtion";

function SectionsTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [dense, setDense] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [editRow, setEditRow] = useState(false);
  const [item, setItem] = useState({
    titleEn: "",
    titleAr: "",
    infoEn: "",
    infoAr: "",
    infoTitleEn: "",
    infoTitleAr: "",
    order: ""
  });
  const [itemIndex, setItemIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    titleEn: "",
    titleAr: "",
    id: "",
    infoEn: "",
    infoAr: "",
    infoTitleEn: "",
    infoTitleAr: "",
    order: ""
  });

  const {
    list,
    hasNext,
    fetchSectionsList,
    error,
    isLoading,
    handleDeleteSection,
    closeNotification,
    submitUpdateSection,
    success,
    updateMessage,
    totalPages,
    limit,
    createSuccessMessage,
    deleteSuccessMessage,
    resetSectionList,
    submitCreateSection
  } = props;

  const createRows = useCallback(() => {
    const retrieveRechargeList = list.map(item => {
      return {
        titleEn: item.titleEn,
        titleAr: item.titleAr,
        id: item.id,
        infoEn: item.infoEn,
        infoAr: item.infoAr,
        infoTitleEn: item.infoTitleEn,
        infoTitleAr: item.infoTitleAr,
        order: item.index
      };
    });
    setRows([...retrieveRechargeList]);
  }, [list]);

  useEffect(() => {
    if (list) {
      createRows(list);
    }
  }, [list]);

  const emptyRows = 10 - Math.min(10, rows.length - 0 * 10);

  useEffect(() => {
    if (pageNum >= 1) {
      fetchSectionsList({
        pageNum
      });
    }
  }, [pageNum]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    resetSectionList();
    setOpenModal(true);
  };

  const validatedFiledTags = value => {
    const retrieveValue = value?.split("\n")?.join("")?.length;
    const regexp = /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>.*?|<h[1-6].*<\/h[1-6]>/gi;
    const matches = [...value.matchAll(regexp)];
    let tagsMatches = [];
    for (const match of matches) {
      tagsMatches.push(match[0]);
    }
    tagsMatches = [...tagsMatches]?.join("").length;
    return retrieveValue !== tagsMatches;
  };

  const validatedFiled = (name, value) => {
    if (name === "titleEn") {
      if (value.length > 20 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            titleEn: "Invalid title (En) 20-character limit"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          titleEn: ""
        }));
      }
    }
    if (name === "titleAr") {
      if (value.length > 20 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            titleAr: "Invalid title (Ar) 20-character limit"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          titleAr: ""
        }));
      }
    }
    if (name === "infoEn") {
      if (value.length >= 3000 || !value.trim() || validatedFiledTags(value)) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            infoEn:
              "Invalid note (En) 3000-character limit and include text on HTML Tags and before every line remove white space."
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          infoEn: ""
        }));
      }
    }
    if (name === "infoAr") {
      if (value.length >= 3000 || !value.trim() || validatedFiledTags(value)) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            infoAr:
              "Invalid note (Ar) 3000-character limit and include text on HTML Tags and before every line remove white space"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          infoAr: ""
        }));
      }
    }
    if (name === "infoTitleEn") {
      if (value.length > 20 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            infoTitleEn: "Invalid note title (En) 20-character limit"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          infoTitleEn: ""
        }));
      }
    }
    if (name === "infoTitleAr") {
      if (value.length > 20 || !value.trim()) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            infoTitleAr: "Invalid note title (Ar) 20-character limit"
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          infoTitleAr: ""
        }));
      }
    }

    if (name === "order") {
      if (!Number.isInteger(+value) || +value < 0) {
        setErrorMessage(prevState => {
          return {
            ...prevState,
            index: `Invalid order field integer and positive value only`
          };
        });
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          index: ""
        }));
      }
    }
  };

  const handleEdit = index => {
    handleOpenEdit();
    setItemIndex(index);
    setItem(rows[index]);
    setEditRow(true);
  };

  const updateFieldChanged = index => e => {
    validatedFiled(e.target.name, e.target.value);
    setItem({
      ...item,
      [e.target.name]: e.target.value
    });
  };
  const handleCreate = () => {
    item.order = +item.order;
    submitCreateSection(item);
    setOpenModal(false);
  };
  const handleSaveEdit = i => {
    let newItem = (rows[i] = item);
    setRows(prev => [...new Set(prev, newItem)]);
    setEditRow(false);
    rows[i].order = +rows[i].order;
    submitUpdateSection({
      data: item,
      id: rows[i].id
    });
    setEditRow(false);
    setPageNum(1);
  };

  const handleRest = () => {
    setEditRow(false);
    setOpenModal(false);
  };

  const handleOpenEdit = () => {
    setEditRow(true);
  };

  const handleClose = () => {
    setEditRow(false);
    setOpenModal(false);
  };
  const handleChangePagination = (event, value) => {
    setPageNum(value);
  };

  return (
    <>
      <CustomTable
        headCells={headCells}
        list={list}
        isLoading={isLoading}
        error={error}
        closeNotification={closeNotification}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        orderBy={orderBy}
        order={order}
        hasNext={hasNext}
        title="All Sections"
        BtnType="Create Sections"
        pageNum={pageNum}
        setPageNum={setPageNum}
        handleOpenModal={handleOpenModal}
        success={success}
        updateMessage={updateMessage}
        totalPages={totalPages}
        limit={limit}
        handleChangePagination={handleChangePagination}
        createSuccessMessage={createSuccessMessage}
        deleteSuccessMessage={deleteSuccessMessage}
      >
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map((row, i) => {
            const labelId = `enhanced-table-checkbox-${i}`;
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={labelId}
                style={{ padding: 12 }}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "10px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    minWidth: "140px"
                  }}
                  title={row.titleEn}
                >
                  {row.titleEn}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    minWidth: "140px",
                    paddingRight: "10px"
                  }}
                  title={row.titleAr}
                >
                  {row.titleAr}
                </TableCell>

                <TableCell
                  scope="row"
                  padding="none"
                  style={{ paddingRight: "12px" }}
                >
                  {row.id}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.infoTitleEn}
                >
                  {row.infoTitleEn}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.infoTitleAr}
                >
                  {row.infoTitleAr}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.infoEn}
                >
                  {row.infoEn}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.infoAr}
                >
                  {row.infoAr}
                </TableCell>

                <TableCell
                  scope="row"
                  padding="none"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    minWidth: "170px",
                    paddingRight: "10px"
                  }}
                  title={row.order}
                >
                  {row.order}
                </TableCell>

                <TableCell scope="row" padding="none">
                  <ActionButtons
                    item={row}
                    deleteButton={true}
                    editButton={false}
                    handleDelete={() => handleDeleteSection(row.id, pageNum)}
                  />
                </TableCell>
                <TableCell scope="row" padding="none">
                  <Button onClick={() => handleEdit(i)}>
                    <img src={EditIcon} alt="" />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {editRow && (
          <EditSection
            item={item}
            open={editRow}
            updateFieldChanged={updateFieldChanged}
            errorMessage={errorMessage}
            handleSave={handleSaveEdit}
            handleRest={handleRest}
            handleClose={handleClose}
            setErrorMessage={setErrorMessage}
            setItem={setItem}
          />
        )}
      </CustomTable>
      {openModal && (
        <EditSection
          open={openModal}
          updateFieldChanged={updateFieldChanged}
          errorMessage={errorMessage}
          itemIndex={itemIndex}
          handleSave={handleCreate}
          handleRest={handleRest}
          handleClose={handleClose}
          setErrorMessage={setErrorMessage}
          item={item}
          setItem={setItem}
        />
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    list: state.SectionsMissions.list,
    hasNext: state.SectionsMissions.hasNext,
    isLoading: state.SectionsMissions.isLoading,
    error: state.SectionsMissions.serverError,
    success: state.SectionsMissions.success,
    updateMessage: state.SectionsMissions.updateMessage,
    totalPages: state.SectionsMissions.totalPages,
    limit: state.SectionsMissions.limit,
    createSuccessMessage: state.SectionsMissions.createSuccessMessage,
    deleteSuccessMessage: state.SectionsMissions.deleteSuccessMessage
  };
};

export default connect(
  mapStateToProps,
  {
    fetchSectionsList,
    handleDeleteSection,
    closeNotification,
    submitUpdateSection,
    resetSectionList,
    submitCreateSection
  }
)(SectionsTable);

SectionsTable.propTypes = {
  list: PropTypes.array,
  hasNext: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleDeleteSection: PropTypes.func,
  closeNotification: PropTypes.func,
  error: PropTypes.string,
  submitUpdateSection: PropTypes.func,
  resetSectionList: PropTypes.func,
  submitCreateSection: PropTypes.func,
  updateMessage: PropTypes.string,
  totalPages: PropTypes.number,
  limit: PropTypes.number,
  createSuccessMessage: PropTypes.string,
  deleteSuccessMessage: PropTypes.string
};
