import editConfig from "./edit/editConfig";
import historyConfig from "./history/historyConfig";

export default {
  routes: [
    {
      routes: [...editConfig.routes, ...historyConfig.routes]
    }
  ]
};
