import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter, generatePath } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// core components
import Danger from "../../../components/Typography/Danger.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardFooter from "../../../components/Card/CardFooter.js";
import Snackbar from "../../../components/Snackbar/Snackbar.js";
import Sortable from "react-drag-sort/es/index";

import dashboardStyles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import styles from "./styles.css";

import quickLinksActions from "../../../main/quick-links/actions";

import { basename } from "../../../config";
import {
  quickLinksCategoriesAddPath,
  quickLinksCategoriesEditPath,
  quickLinksLinksPath
} from "../paths";

const useDashboardStyles = makeStyles(dashboardStyles);
const useStyles = makeStyles(styles);

const orderCategories = categories =>
  categories.map((category, categoryIndex) => ({
    ...category,
    order: categoryIndex + 1
  }));

const CategoryRow = connect(
  null,
  {
    removeCategory: quickLinksActions.categories.remove
  }
)(props => {
  const {
    category,
    removeCategory,
    decorateHandle,
    setDeleting,
    showDeleteSuccessMessage,
    showerrorMessage
  } = props;
  const [expand, setExpand] = useState(false);
  const [displayDeleting, setDisplayDeleting] = useState(false);
  const [confirmDeleting, setConfirmDeleting] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (confirmDeleting && !category?.links?.length) {
      removeCategory(category.id)
        .then(() => {
          setDeleting(true);
          showDeleteSuccessMessage();
        })
        .catch(error => {
          setDisplayDeleting(false);
          showerrorMessage(error);
        });
    }
  }, [confirmDeleting]);

  useEffect(() => {
    if (!displayDeleting) setConfirmDeleting(false);
  }, [displayDeleting]);

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow className={classes.tableBodyRow} aria-label="table row">
          <TableCell className={classes.tableCell} style={{ cursor: "move" }}>
            {decorateHandle(<span>{category.order}</span>)}
          </TableCell>
          <TableCell className={classes.tableCell} style={{ minWidth: 180 }}>
            {category.name.en}
          </TableCell>
          <TableCell className={classes.tableCell} style={{ minWidth: 180 }}>
            {category.name.ar}
          </TableCell>
          <TableCell className={classes.tableCell} style={{ width: "60%" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpand(expand => !expand)}
            >
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Link
                  to={generatePath(
                    [basename, quickLinksCategoriesEditPath].join(""),
                    { id: category.id }
                  )}
                >
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="edit"
                    className={classes.fab}
                  >
                    <EditIcon className={classes.editIcon} />
                  </Fab>
                </Link>
              </Grid>
              <Grid item>
                <Fab
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={classes.fab}
                  onClick={() => {
                    setDisplayDeleting(true);
                  }}
                >
                  <DeleteIcon />
                </Fab>
              </Grid>
              <Grid
                item
                style={{
                  cursor: "move",
                  alignSelf: "center",
                  display: "grid",
                  placeContent: "center"
                }}
              >
                {decorateHandle(
                  <span>
                    <DragIndicatorIcon />
                  </span>
                )}
              </Grid>
            </Grid>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={displayDeleting}
              onClose={() => {
                setDisplayDeleting(false);
              }}
            >
              <div className={`${classes.paper} ${classes.modalContainer}`}>
                <Icon
                  className={classes.iconWarning}
                  style={{ fontSize: 128 }}
                  color="action"
                >
                  error_outline
                </Icon>
                {confirmDeleting && category?.links?.length ? (
                  <>
                    <h3 id="simple-modal-title">Failure</h3>
                    <p>
                      It appears that there is a Quick Link still tied to this
                      category, please check again.
                    </p>
                    <div className={classes.buttonsContainer}>
                      <Link to={[basename, quickLinksLinksPath].join("")}>
                        <Button variant="contained" className={classes.proceed}>
                          Check Quicklinks
                        </Button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <h3 id="simple-modal-title">Are you sure?</h3>
                    <p>
                      To delete a category it must not be linked to any stores.
                      Please relink all stores under this category to another
                      category
                    </p>
                    <div className={classes.buttonsContainer}>
                      <Button
                        variant="contained"
                        className={classes.cancel}
                        onClick={() => {
                          setDisplayDeleting(false);
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.proceed}
                        onClick={() => {
                          setConfirmDeleting(true);
                        }}
                      >
                        YES, DELETE IT!
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Modal>
          </TableCell>
        </TableRow>
        {expand &&
          category?.links?.map(link => (
            <TableRow
              key={link.id}
              className={classes.tableBodyRow}
              aria-label="table row"
            >
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>
                {link.name.en} {link.name.ar}
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
});

const Categories = props => {
  const { quickLinks, getCategories, editCategories } = props;
  const [loading, setLoading] = useState(true);
  const [ordering, setOrdering] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [collection, setCollection] = useState([]);
  const [messagesId, setMessagesId] = useState(1);
  const [messages, setMessages] = useState([]);

  const addMessage = message => {
    setMessages(messages => [...messages, message]);
  };

  const removeMessage = id => {
    setMessages(messages => messages.filter(message => message.id !== id));
  };

  const showReorderSuccessMessage = () => {
    addMessage({
      id: messagesId,
      type: "success",
      content: "Category is updated successfully"
    });
    setMessagesId(setMessagesId => setMessagesId + 1);
  };

  const showDeleteSuccessMessage = () => {
    addMessage({
      id: messagesId,
      type: "success",
      content: "Category is deleted successfully"
    });
    setMessagesId(setMessagesId => setMessagesId + 1);
  };

  const showerrorMessage = error => {
    addMessage({
      id: messagesId,
      type: "danger",
      content: error.message
    });
    setMessagesId(setMessagesId => setMessagesId + 1);
  };

  useEffect(() => {
    getCategories().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setCollection(
      quickLinks.categories.map(category => ({
        key: category.order,
        value: category
      }))
    );
  }, [quickLinks]);

  useEffect(() => {
    if (ordering) {
      setOrdering(false);
      setLoading(true);
      editCategories(orderCategories(collection.map(item => item.value))).then(
        () => {
          showReorderSuccessMessage();
          setLoading(false);
        }
      );
    }
  }, [collection, ordering]);

  useEffect(() => {
    if (deleting) {
      setDeleting(false);
      setLoading(true);
      editCategories(orderCategories(quickLinks.categories)).then(() => {
        setLoading(false);
      });
    }
  }, [deleting]);

  const handleCollectionChange = collection => {
    setCollection(collection);
  };

  const Item = props => {
    const { value, index, onChange, onRemove, decorateHandle } = props;
    return (
      <CategoryRow
        category={value}
        decorateHandle={decorateHandle}
        setDeleting={setDeleting}
        showDeleteSuccessMessage={showDeleteSuccessMessage}
        showerrorMessage={showerrorMessage}
      />
    );
  };

  const classes = useStyles();
  const dashboardClasses = useDashboardStyles();
  if (loading) return <></>;
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.cardLinkContainer}>
            <Link
              to={[basename, quickLinksCategoriesAddPath].join("")}
              className={classes.cardLinkAdd}
            >
              Add Categories
            </Link>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon className={classes.icon} color="danger">
                <Icon fontSize="small">content_copy</Icon>
              </CardIcon>
              <h3
                className={`${[dashboardClasses.cardTitle, classes.title].join(
                  " "
                )}`}
              >
                Categories
              </h3>
            </CardHeader>
            <CardBody>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      #
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      style={{ minWidth: 180 }}
                    >
                      EN Name
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      style={{ minWidth: 180 }}
                    >
                      AR Name
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      style={{ width: "60%" }}
                    >
                      Store List
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    ></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div
                onDrop={e => {
                  setOrdering(true);
                }}
              >
                <Sortable
                  collection={collection}
                  onChange={handleCollectionChange}
                  Component={Item}
                />
              </div>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      {messages.map(message => (
        <Snackbar
          key={message.id}
          place="tc"
          color={message.type}
          message={message.content}
          open={true}
          closeNotification={() => removeMessage(message.id)}
          close
        />
      ))}
    </>
  );
};

export default connect(
  state => ({ quickLinks: state._quickLinks }),
  {
    getCategories: quickLinksActions.categories.get,
    editCategories: quickLinksActions.categories.edit
  }
)(withRouter(Categories));
