const initialState = {
  isLoading: false,
  list: [],
  error: false,
  success: false,
  hasNext: false,
  serverError: "",
  targetsUploadList: [],
  merchantIdSelected: "",
  merchantNameSelected: "",
  UploadLoading: false,
  updateMessage: "",
  limit: 10,
  totalPages: 0,
  deleteSuccessMessage: "",
  uploadSuccessMessage: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING_TARGET_LIST": {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case "FETCH_TARGET_LIST": {
      return {
        ...state,
        list: action.payload?.data,
        hasNext: action.payload?.meta?.has_next,
        totalPages: action.payload?.meta?.total,
        limit: action.payload?.meta?.limit || state.limit
      };
    }
    case "SET_ERROR_LIST": {
      return {
        ...state,
        serverError: action.payload
      };
    }
    case "DELETE_TARGET_SUCCESS": {
      return {
        ...state,
        success: true,
        deleteSuccessMessage: action.payload
      };
    }
    case "SET_TARGET_LIST_UPLOAD": {
      const setMerchantIdToUpload = action.payload.map(item => {
        return {
          ...item,
          merchantId: state.merchantIdSelected
        };
      });
      return {
        ...state,
        targetsUploadList: setMerchantIdToUpload
      };
    }
    case "UPLOAD_TARGET_LOADING": {
      return {
        ...state,
        UploadLoading: action.payload
      };
    }
    case "UPLOAD_TARGET_SUCCESS": {
      return {
        ...state,
        success: true,
        uploadSuccessMessage: action.payload
      };
    }
    case "UPLOAD_TARGET_FAILED": {
      return {
        ...state,
        error: true,
        serverError: action.payload
      };
    }
    case "RESET_ERROR": {
      return {
        ...state,
        serverError: "",
        success: false,
        updateMessage: ""
      };
    }
    case "SET_MERCHANT": {
      return {
        ...state,
        merchantIdSelected: action.payload?.id,
        merchantNameSelected: action.payload?.name
      };
    }
    case "SUCCESS_UPDATE_TARGET": {
      return {
        ...state,
        success: true,
        updateMessage: action.payload
      };
    }
    case "ERROR_UPDATE_TARGET": {
      return {
        ...state,
        error: true
      };
    }
    case "RESET_UPLOAD_TARGET_LIST": {
      return {
        ...state,
        targetsUploadList: [],
        UploadLoading: false,
        success: false,
        error: false,
        merchantIdSelected: "",
        merchantNameSelected: "",
        deleteSuccessMessage: "",
        updateMessage: "",
        uploadSuccessMessage: ""
      };
    }
    case "REST_TARGET_STATE": {
      return initialState;
    }
    default:
      return state;
  }
};
