import { GET_USER_REQUESTS } from "../../actions/user-requests";
import { UPDATE_USER_REQUEST_STATUS } from "../../actions/user-request";

const initialState = {
  data: [],
  meta: {
    total: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUESTS:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_USER_REQUEST_STATUS:
      return {
        ...state,
        data: state.data.map(userRequest => {
          if (userRequest.id === action.payload.id) {
            return {
              ...userRequest,
              ...action.payload.userRequest
            };
          } else {
            return userRequest;
          }
        })
      };
    default:
      return state;
  }
};
