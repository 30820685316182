import { CHECK_REQUIRED_FIELDS } from "../action/actionType";

const initialState = {
  shouldSubmit: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_REQUIRED_FIELDS:
      return {
        ...state,
        shouldSubmit: action.payload
      };

    default:
      return state;
  }
};
