import { SET_OPTION, UPDATE_OPTION } from "../../actions/option";

export const initialState = {
  titleEn: "",
  titleAr: "",
  provider: {
    id: ""
  },
  category: {
    id: ""
  },
  type: "",
  requestInputs: [],
  productId: "",
  country: "",
  currency: "",
  imageBackground: "",
  imageUrl: "",
  denominations: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OPTION:
      return {
        ...state,
        ...action.payload.option
      };
    case UPDATE_OPTION:
      return {
        ...state,
        ...action.payload.option
      };
    default:
      return state;
  }
};
