import authenticatedHttpClientService from "../authenticated-http-client";

export const getDeals = (countryCode = "", page = 1, limit = 1000) =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/deals?country=${countryCode}&page=${page}&limit=${limit}`
    )
    .then(res => res.data);

export const addCategory = category =>
  authenticatedHttpClientService
    .post(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/categories`,
      JSON.stringify({
        ...category
      }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);

export const addCategoryImage = (slug, image) => {
  let formData = new FormData();
  formData.append("logo", image);
  return authenticatedHttpClientService
    .post(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/categories/${slug}/logo`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    )
    .then(response => response.data);
};

export const updateCategory = category => {
  const data = { ...category };
  delete data.slug;
  return authenticatedHttpClientService
    .patch(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/categories/${category.slug}`,
      JSON.stringify({
        ...data
      }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);
};

export const getCategories = (filterBy = "") =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/categories?active=${filterBy}`
    )
    .then(response => response.data);

export const getCategory = slug =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_DEALS_BASE_URL}/admin/categories/${slug}`)
    .then(response => response.data);

export const addMerchant = merchant =>
  authenticatedHttpClientService
    .post(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/merchants`,
      JSON.stringify({
        order: merchant.order,
        nameEn: merchant.nameEn,
        nameAr: merchant.nameAr,
        url: merchant.url,
        descriptionEn: merchant.descriptionEn,
        descriptionAr: merchant.descriptionAr,
        isPopular: merchant.isPopular,
        countries: merchant.countries,
        targetUrls: merchant.targetUrls,
        tncEn: merchant.tncEn,
        tncAr: merchant.tncAr
      }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);

export const updateMerchant = (id, merchant) =>
  authenticatedHttpClientService
    .put(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/merchants/${id}`,
      JSON.stringify({
        order: merchant.order,
        nameEn: merchant.nameEn,
        nameAr: merchant.nameAr,
        url: merchant.url,
        descriptionEn: merchant.descriptionEn,
        descriptionAr: merchant.descriptionAr,
        isPopular: merchant.isPopular,
        countries: merchant.countries,
        targetUrls: merchant.targetUrls,
        tncEn: merchant.tncEn,
        tncAr: merchant.tncAr
      }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);

export const updateMerchantImage = (id, image) => {
  let formData = new FormData();
  formData.append("logo", image);
  return authenticatedHttpClientService
    .post(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/merchants/${id}/logo`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    )
    .then(response => response.data);
};

export const getMerchants = (query = "", orderBy = "", page = 1, limit = 0) =>
  authenticatedHttpClientService
    .get(`${process.env.REACT_APP_DEALS_BASE_URL}/admin/merchants`, {
      params: {
        query,
        orderBy,
        page,
        limit
      }
    })
    .then(response => response.data);

export const deleteMerchant = id =>
  authenticatedHttpClientService
    .delete(`${process.env.REACT_APP_DEALS_BASE_URL}/admin/merchants/${id}`)
    .then(response => response.data);

export const getConversions = (
  countryCode,
  query,
  searchBy,
  dateFrom,
  dateTo,
  orderBy,
  page,
  limit
) =>
  authenticatedHttpClientService
    .get(
      `${
        process.env.REACT_APP_DEALS_BASE_URL
      }/admin/conversions?countryCode=${countryCode}${
        query ? `&value=${query}&key=${searchBy}` : ""
      }${
        dateFrom
          ? `&dateFrom=${new Date(dateFrom)
              .toISOString()
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-")}`
          : ""
      }${
        dateTo
          ? `&dateTo=${new Date(dateTo)
              .toISOString()
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-")}`
          : ""
      }&page=${page}&size=${limit}&orderBy=${orderBy}`
    )
    .then(response => response.data);

export const approveConversion = (id, onApprovedExchangeRate) =>
  authenticatedHttpClientService
    .post(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/conversions/approve`,
      JSON.stringify({
        conversionId: id,
        exchangeRate: onApprovedExchangeRate
      }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);

export const rejectConversion = id =>
  authenticatedHttpClientService
    .post(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/conversions/reject`,
      JSON.stringify({
        conversionId: id
      }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);

export const getConversionEditRequestReasons = (page, limit) =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/reason?page=${page}&size=${limit}`
    )
    .then(response => response.data);

export const addConversionEditRequestReason = reason =>
  authenticatedHttpClientService
    .post(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/reason`,
      JSON.stringify({ reason }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);

export const getConversionEditRequest = id =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/conversions/${id}/edit-requests`
    )
    .then(response => response.data);

export const approveConversionEditRequest = (id, reason) =>
  authenticatedHttpClientService
    .patch(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/conversions/edit-requests/${id}/approve`,
      JSON.stringify({ reasonId: reason.id, description: reason.value }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);

export const rejectConversionEditRequest = (id, reason) =>
  authenticatedHttpClientService
    .patch(
      `${process.env.REACT_APP_DEALS_BASE_URL}/admin/conversions/edit-requests/${id}/reject`,
      JSON.stringify({ reasonId: reason.id, description: reason.value }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);
