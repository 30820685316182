import {
  SET_OPERATOR_FILTER_TYPE,
  SET_STATUS_FILTER_TYPE,
  ADD_SELECTED_VALUES,
  REMOVE_SELECT_OPERATOR,
  ADD_START_DATE_FILTER,
  ADD_END_DATE_FILTER,
  REST_FILTER_VALUES
} from "../../action/actionType";

const initialState = {
  operator: "",
  status: "",
  startDate: "",
  endDate: "",
  operatorSelectedList: [],
  statusSelectedList: []
};
const filterUniqueAndEmpty = arr => {
  return arr
    .filter((item, index) => arr.indexOf(item) === index)
    .filter(item => item);
};
const removeItem = (arr, value) => {
  return arr.filter(item => !value.includes(item));
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OPERATOR_FILTER_TYPE:
      return {
        ...state,
        operator: action.payload
      };
    case SET_STATUS_FILTER_TYPE:
      return {
        ...state,
        status: action.payload
      };
    case ADD_START_DATE_FILTER:
      return {
        ...state,
        startDate: action.payload
      };
    case ADD_END_DATE_FILTER:
      return {
        ...state,
        endDate: action.payload
      };
    case ADD_SELECTED_VALUES:
      return {
        ...state,
        operatorSelectedList: filterUniqueAndEmpty([
          ...state.operatorSelectedList,
          action.payload.operatorSelected
        ]),
        operator: "",
        status: "",
        statusSelectedList: filterUniqueAndEmpty([
          ...state.statusSelectedList,
          action.payload.statusSelected
        ])
      };
    case REMOVE_SELECT_OPERATOR:
      return {
        ...state,
        operatorSelectedList: removeItem(
          state.operatorSelectedList,
          action.payload
        ),
        statusSelectedList: removeItem(state.statusSelectedList, action.payload)
      };

    case REST_FILTER_VALUES:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
