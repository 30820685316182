const styles = theme => ({
  root: {
    width: "90%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between"
  },
  customButton: {
    color: "#fff",
    backgroundColor: "#fc8f04",
    "&:hover": {
      backgroundColor: "#e98301",
      borderColor: "#fc8f04",
      boxShadow: "none"
    }
  }
});

export default styles;
