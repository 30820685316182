import Conversion from "./Conversion";
import { cashbacksConversionsConversionPath } from "./paths";

export default {
  routes: [
    {
      path: cashbacksConversionsConversionPath,
      component: Conversion,
      layout: "main",
      isPrivate: false,
      roles: ["admin", "cs"],
      routes: []
    }
  ]
};
