import React from "react";

import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = props => {
  return (
    <Paper
      variant="outlined"
      sx={{ p: 8, display: "flex", justifyContent: "center" }}
    >
      <CircularProgress />
    </Paper>
  );
};

export default Loader;
