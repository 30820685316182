import React, { useEffect } from "react";
import { connect } from "react-redux";

import { deleteItem } from "./actions/list";

import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

const Notification = props => {
  const { id, type, message, autoHideTime, deleteItem } = props;

  useEffect(() => {
    if (autoHideTime) {
      const timer = setTimeout(() => {
        deleteItem(id);
      }, autoHideTime - new Date().getTime());
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    deleteItem(id);
  };

  return (
    <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
      {message}
    </Alert>
  );
};

const Notifications = props => {
  const {
    notifications: { list },
    deleteItem
  } = props;

  return (
    <Snackbar open={Boolean(list.length)}>
      <Box>
        <Stack spacing={2}>
          {list.map(item => (
            <Notification
              key={item.id}
              id={item.id}
              type={item.type}
              message={item.message}
              autoHideTime={item.autoHideTime}
              deleteItem={deleteItem}
            />
          ))}
        </Stack>
      </Box>
    </Snackbar>
  );
};

export default connect(
  state => ({ notifications: state.notifications }),
  {
    deleteItem
  }
)(Notifications);
