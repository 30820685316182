import User from "./User";

import { userPath } from "./paths";

import activityConfig from "./activity/activityConfig";

export default {
  routes: [
    {
      path: userPath,
      component: User,
      layout: "main",
      isPrivate: false,
      roles: ["admin", "cs"],
      routes: [...activityConfig.routes]
    }
  ]
};
