import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import styles from "./StepperStyles.css";
import { checkRequiredFields } from "../../redux/action/checkRequiredFieldsActions";
import {
  submitAnnouncementCardData,
  submitAnnouncementCardsEdits
} from "../../redux/action/AnnouncementCards/CreateAnnouncementCardActions";
import {
  submitMissionData,
  submitMissionsEdits
} from "../../redux/action/Missions/CreateMissionActions";

import { submitUsersRecharge } from "../../redux/action/WaslaRewards/RechargeUsersActions";
import {
  setAllMissionTasks,
  editMissionTasks
} from "../../redux/action/Missions/MissionTasksActions";
import { missionClaimSuccess } from "../../redux/action/Missions/MissionClaimActions";
import { setMissionClaimTitles } from "../../redux/action/Missions/MissionClaimActions";
import { modifyUsers } from "../../redux/reducers/WaslaRewards/selectors";
import { setFinishAllEdit } from "../../redux/action/WaslaRewards/EditUsersRechargeAction";
const useStyles = makeStyles(styles);

function getSteps(stepsTitle) {
  return stepsTitle;
}

function getStepContent(stepIndex, contentArray) {
  return contentArray[stepIndex];
}

function HorizontalLabelPositionBelowStepper(props) {
  const {
    stepsTitle,
    children,
    formType,
    announcementCards,
    dates,
    imageUrl,
    fileUrl,
    missions,
    // selectedUsers,
    submitUsersRecharge,
    missionTasks,
    setAllMissionTasks,
    editMissionTasks,
    checkRequiredFields,
    viewType,
    submitMissionsEdits,
    submitMissionData,
    setMissionClaimTitles,
    iconUrl,
    missionClaimSuccess,
    // uploadUsersRecharge,
    openManualModal,
    isFinish,
    finialTotalEditRecharge,
    setFinishAllEdit,
    modalType
    // projectId
  } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(stepsTitle);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    checkRequiredFields(false);
    if (modalType === "edit" && formType === "waslaReward") {
      setFinishAllEdit(true);
    }
    if (formType === "announcement-cards" && activeStep === steps.length - 1) {
      if (props.viewType === "Edit") {
        const shouldSendUrl = imageUrl.includes("data:image") ? imageUrl : "";

        props.submitAnnouncementCardsEdits(
          announcementCards,
          dates,
          shouldSendUrl,
          fileUrl
        );
      } else {
        props.submitAnnouncementCardData(
          announcementCards,
          dates,
          imageUrl,
          fileUrl
        );
      }
    } else if (formType === "missions" && activeStep === steps.length - 1) {
      if (viewType === "Edit") {
        const shouldSendUrl = imageUrl.includes("data:image") ? imageUrl : "";
        submitMissionsEdits(missions, dates, shouldSendUrl, fileUrl, iconUrl);
      } else {
        submitMissionData(missions, dates, imageUrl, fileUrl, iconUrl);
      }
    }
    if (formType === "waslaReward" && activeStep === steps.length - 1) {
      if (openManualModal) {
        // submitUsersRecharge("upload", projectId, uploadUsersRecharge);
      } else if (isFinish) {
        submitUsersRecharge(null, finialTotalEditRecharge);
      }
      // else {
      //   submitUsersRecharge(null, selectedUsers);
      // }
    }

    if (formType === "tasks" && activeStep === steps.length - 1) {
      missionTasks.success = true;

      if (missionTasks.viewType === "Edit") {
        setAllMissionTasks(missionTasks);
        return;
      }
      if (!missionTasks.id) {
        missionTasks.id = uuidv4();
      }
      setAllMissionTasks(missionTasks);
    }
    if (formType === "claim" && activeStep === steps.length - 1) {
      missionClaimSuccess(true);
      let missionClaim = {
        claimArabicTitle: missions.claimArabicTitle,
        claimEnglishTitle: missions.claimEnglishTitle,
        claimBtnEnglishText: missions.claimBtnEnglishText,
        claimBtnArabicText: missions.claimBtnArabicText,
        claimEnglishSubtitle: missions.claimEnglishSubtitle,
        claimArabicSubtitle: missions.claimArabicSubtitle
      };
      setMissionClaimTitles(missionClaim);
    }
  };
  useEffect(() => {
    return () => {
      missionClaimSuccess(false);
    };
  }, []);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div></div>
        ) : (
          <div>
            {getStepContent(activeStep, children)}
            <div className={classes.buttonGroup}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant="contained"
                disabled={!props.shouldSubmit}
                onClick={handleNext}
                className={classes.customButton}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  shouldSubmit: state.shouldSubmit.shouldSubmit,
  announcementCards: state.announcementCards,
  dates: state.dates,
  imageUrl: state.imageUrl.url,
  iconUrl: state.imageUrl.iconUrlMission,
  fileUrl: state.uploadFile.specificCSV,
  missions: state.missions,
  // selectedUsers: modifyUsers(
  //   state.rechargeUsers.selectedRechargeValue,
  //   state.waslaRewards.selectedUsers
  // ),
  missionTasks: state.missionTasks,
  openManualModal: state.UploadUserRecharge.openModal,
  isFinish: state.usersRechargeToEdit.isFinish,
  finialTotalEditRecharge: state.usersRechargeToEdit.finialTotalEditRecharge
});

export default connect(
  mapStateToProps,
  {
    checkRequiredFields,
    submitAnnouncementCardData,
    submitAnnouncementCardsEdits,
    submitMissionData,
    submitMissionsEdits,
    submitUsersRecharge,
    setAllMissionTasks,
    editMissionTasks,
    setMissionClaimTitles,
    missionClaimSuccess,
    setFinishAllEdit
  }
)(HorizontalLabelPositionBelowStepper);

HorizontalLabelPositionBelowStepper.propTypes = {
  stepsTitle: PropTypes.array,
  children: PropTypes.array,
  formType: PropTypes.string,
  shouldSubmit: PropTypes.bool,
  checkRequiredFields: PropTypes.func,
  announcementCards: PropTypes.object,
  submitAnnouncementCardData: PropTypes.func,
  dates: PropTypes.object,
  imageUrl: PropTypes.string,
  fileUrl: PropTypes.string,
  viewType: PropTypes.string,
  submitAnnouncementCardsEdits: PropTypes.func,
  missions: PropTypes.object,
  missionTasks: PropTypes.object,
  missionClaim: PropTypes.object,
  submitMissionData: PropTypes.func,
  submitMissionsEdits: PropTypes.func,
  setAllMissionTasks: PropTypes.func,
  editMissionTasks: PropTypes.func,
  setMissionClaimTitles: PropTypes.func,
  iconUrl: PropTypes.string,
  missionClaimSuccess: PropTypes.func,
  projectId: PropTypes.number
};
