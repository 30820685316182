import {
  UPLOAD_USERS_TO_RECHARGE,
  OPEN_UPLOAD_MODAL,
  RESET_UPLOAD_RECHARGE_STATE,
  UPLOAD_USERS_LOADING,
  UPLOAD_USERS_SUCCESS,
  UPLOAD_USERS_FAILED
} from "../actionType";
import { waslaRewardBaseurl, authURL } from "../../../components/config";
import { fetchBatchesList } from "./BatchesAction";
import { fetchRewardApiList } from "./UsersLogsActions";
export const resetUploadRechargeState = () => {
  return {
    type: RESET_UPLOAD_RECHARGE_STATE
  };
};
export const openManualModal = () => {
  return {
    type: OPEN_UPLOAD_MODAL
  };
};
export const UploadUsersToRecharge = ({ projectId, usersRecharge }) => {
  return {
    type: UPLOAD_USERS_TO_RECHARGE,
    payload: { projectId, usersRecharge }
  };
};

export const submitUploadUsers = (
  projectId,
  phoneObjects,
  type
) => async dispatch => {
  dispatch({ type: UPLOAD_USERS_LOADING, payload: true });
  await waslaRewardBaseurl
    .post(`/external/project/${projectId}/batch`, { phoneObjects })
    .then(res => {
      dispatch({ type: UPLOAD_USERS_LOADING, payload: false });
      dispatch({ type: UPLOAD_USERS_SUCCESS });
      dispatch(fetchBatchesList(projectId));
      if (type?.toLowerCase() !== "manual") {
        setTimeout(() => {
          dispatch(fetchRewardApiList({ projectId }));
        }, 5000);
      }
    })
    .catch(error => {
      dispatch({ type: UPLOAD_USERS_LOADING, payload: false });
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
      }
      dispatch({
        type: UPLOAD_USERS_FAILED
      });
    });
};
