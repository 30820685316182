import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { ApmRoute } from "@elastic/apm-rum-react";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// Action
import {
  fetchRolesOfUsers,
  assignRolesOfUsers
} from "../../redux/action/RolesAction";
import { fetchAccessToken } from "../../redux/action/AccessToken";
// core components
import Navbar from "../../components/Navbars/Navbar.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import { dashboardRoutes } from "../../routes";
import styles from "../../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "../../assets/img/sidebar-2.jpg";
import logo from "../../assets/img/Wasla_logo-All.svg";
import { hasRole } from "../../layouts/auth";

import Notifications from "../../widgets/notifications/Notifications";

import WithAuth from "../../modules/auth";

let ps;

const useStyles = makeStyles(styles);

function Admin({ ...rest }) {
  const { handleLogout, authorized } = rest;
  const classes = useStyles();
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  // const [color, setColor] = React.useState("orange");
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [routes, setRoutes] = React.useState(dashboardRoutes);
  // const [countRequest, setCountRequest] = React.useState(0);
  let match = useRouteMatch();

  // React.useEffect(() => {
  //   rest.fetchAccessToken();
  // }, []);

  // React.useEffect(() => {
  //   if (rest.roles.length !== 0) {
  //     setRoutes(dashboardRoutes);
  //   }

  //   if (rest.roles.length === 0 && countRequest < 1) {
  //     setCountRequest(countRequest + 1);
  //     rest.fetchRolesOfUsers();
  //   }
  // }, [rest.roles, countRequest]);

  React.useEffect(() => {
    rest.assignRolesOfUsers(
      localStorage.getItem("access_token")
        ? JSON.parse(atob(localStorage.getItem("access_token").split(".")[1]))
            .realm_access.roles
        : []
    );
  }, [authorized]);

  const appRoutes = [];

  const generateRoutes = routes => {
    routes.forEach(route => {
      route.routes && generateRoutes(route.routes);
      appRoutes.push(route);
    });
  };

  generateRoutes(routes);

  let switchRoutes = (
    <Switch>
      {appRoutes.map((prop, index) => {
        if (prop.layout === "/admin" || prop.layout === "main") {
          prop.isPrivate = hasRole(prop.roles, rest.roles);
          return (
            <ApmRoute
              path={match.path + prop.path}
              component={prop.isPrivate && prop.component}
              key={index}
            />
          );
        }
        return null;
      })}
      {(routes.length !== 0 || routes.length > 2) &&
        rest.roles.find(role => role.toLowerCase() === "admin") && (
          <Redirect to={`${match.path}/dashboard`} />
        )}
      {routes.length !== 0 &&
        rest.roles.find(role => role.toLowerCase() === "merchant") && (
          <Redirect to={`${match.path}/waslaRewards`} />
        )}
      {routes.length !== 0 &&
        rest.roles.find(role => role.toLowerCase() === "cs") && (
          <Redirect to={`${match.path}/cs-users`} />
        )}
    </Switch>
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflowY = "scroll";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Wasla"}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"orange"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          handleLogout={handleLogout}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {authorized ? (
          getRoute() ? (
            <div
              className={classes.content}
              style={{ backgroundColor: "white" }}
            >
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )
        ) : (
          <div className={classes.content}>
            <div className={classes.container}></div>
          </div>
        )}
        <Notifications />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    roles: state.roles.roles
  };
};

export default connect(
  mapStateToProps,
  { fetchAccessToken, fetchRolesOfUsers, assignRolesOfUsers }
)(WithAuth(Admin));
