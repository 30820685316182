import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// redux actions
import { setCallToAction } from "../../../../redux/action/AnnouncementCards/CTAButtonActions";
// core components
import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const QuestionComponent = ({ callToActionBtn, setCallToAction }) => {
  const classes = useStyles();

  const handleRadioChange = event => {
    setCallToAction(event.target.value);
  };

  return (
    <Grid className={`${classes.container} ${classes.marginBlock}`}>
      <div className={classes.radioBtnContainer}>
        <p className={classes.radioBtnQuestion}>Is there a call to action?</p>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="position"
            name="position"
            value={callToActionBtn}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="Yes"
              style={{ color: "#000" }}
              control={
                <Radio
                  checkedIcon={
                    <span
                      className={`${classes.radioBtnIcon} ${classes.checkedIcon}`}
                    />
                  }
                  icon={<span className={classes.radioBtnIcon} />}
                />
              }
              label="Yes (Try new app, Complete survey)"
            />
            <FormControlLabel
              value="No"
              style={{ color: "#000" }}
              control={
                <Radio
                  disableRipple
                  checkedIcon={
                    <span
                      className={`${classes.radioBtnIcon} ${classes.checkedIcon}`}
                    />
                  }
                  icon={<span className={classes.radioBtnIcon} />}
                />
              }
              label="No (Your app has been updated)"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    callToActionBtn: state.announcementCards.callToActionBtn
  };
};

export default connect(
  mapStateToProps,
  { setCallToAction }
)(QuestionComponent);

QuestionComponent.propTypes = {
  callToActionBtn: PropTypes.string,
  setCallToAction: PropTypes.func
};
