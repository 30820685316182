import {
  FETCH_ALL_REASONS,
  ADD_SELECTED_REASON
} from "../../action/actionType";

const initialState = {
  reasons: [],
  selectedReason: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_REASONS:
      return {
        ...state,
        reasons: action.payload
      };
    case ADD_SELECTED_REASON:
      return {
        ...state,
        selectedReason: action.payload
      };
    default:
      return state;
  }
};
