import Edit from "./Edit";
import { dealsMerchantsEditPath } from "./paths";

export default {
  routes: [
    {
      path: dealsMerchantsEditPath,
      component: Edit,
      layout: "main",
      isPrivate: false,
      roles: ["admin"],
      routes: []
    }
  ]
};
