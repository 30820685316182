import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import withReducer from "../../../../../../store/withReducer";
import CategoryReducers from "../../reducers";

import Link from "@mui/material/Link";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import Avatar from "@mui/material/Avatar";

import FormCard from "../../../../../../components/FormCard";
import FormCardHead from "../../../../../../components/FormCardHead";
import FormCardBody from "../../../../../../components/FormCardBody";
import FormCardBodyContent from "../../../../../../components/FormCardBodyContent";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

import configurations from "../../../../../../configurations";
import { CategoriesPath } from "../../../../../../main/deals/categories/paths";

import {
  updateNameEn,
  updateNameAr,
  updateLogoUrl,
  updateStatus,
  getErrorMessage
} from "../../actions/local";

import avatar from "./assets/avatar.png";
import styles from "./styles.css";
import WarningModal from "../../../../../../components/Modals/WarningModal";
import UploadContainer from "../../../../../../components/UploadContainer";

const useStyles = makeStyles(styles);

const Form = props => {
  const classes = useStyles();
  const {
    category: { origin, local },
    submitForm,
    updateNameEn,
    updateNameAr,
    updateLogoUrl,
    updateStatus,
    getErrorMessage
  } = props;
  const { slug } = useParams();

  const [logoUrlFile, setLogoUrlFile] = useState(null);

  const { error, title, message } = local;

  const logoUrlElement = useRef(null);

  useEffect(() => {
    if (local.dealsCount > 0) {
      updateStatus(true);
      getErrorMessage({
        error: true,
        title: "Unable to pause the category",
        message: `We are unable to pause "${local.nameEn}" category with ${local.dealsCount} active deals. Please re-link all the deals from Everflow to be able to pause the category`
      });
    }
  }, [local.status]);

  const handleSubmit = e => {
    e.preventDefault();
    submitForm({
      nameEn: local.nameEn,
      nameAr: local.nameAr,
      logoUrl: logoUrlFile,
      status: local.status
    });
  };

  const handleNameEnChange = e => {
    updateNameEn(e.target.value);
  };

  const handleNameArChange = e => {
    updateNameAr(e.target.value);
  };

  const handleLogoUrlClick = e => {
    logoUrlElement.current.click();
  };

  const handleLogoUrlChange = e => {
    if (e.target.files && e.target.files[0]) {
      setLogoUrlFile(e.target.files[0]);
      updateLogoUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleCloseModalWarning = () => {
    getErrorMessage({
      error: false,
      title: "",
      message: ""
    });
  };

  const filteringKeys = [
    { id: false, label: "Paused" },
    { id: true, label: "Active" }
  ];

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <FormCard>
          <FormCardHead variant="underline">
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                color: "#101828"
              }}
            >
              {slug ? "Edit Category" : "Create Cateogry"}
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                color: "#667085"
              }}
            >
              {slug ? "Update or Edit a category" : "Create a Category"}
            </Typography>
          </FormCardHead>
          <FormCardBody>
            <FormCardBodyContent>
              <Stack spacing={8}>
                <Grid container spacing={5}>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="name-en"
                        sx={{ transform: "none" }}
                      >
                        English Category Name
                      </InputLabel>
                      <OutlinedInput
                        id="name-en"
                        value={local.nameEn}
                        onChange={handleNameEnChange}
                        placeholder="English Category Name"
                        label=""
                        required
                        disabled={false}
                      />
                      {false && (
                        <FormHelperText>
                          English Title is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="name-ar"
                        sx={{ transform: "none" }}
                      >
                        Arabic Category Name
                      </InputLabel>
                      <OutlinedInput
                        id="name-ar"
                        value={local.nameAr}
                        onChange={handleNameArChange}
                        placeholder="ادخل الاسم باللغة العربية"
                        label=""
                        required
                        disabled={false}
                      />
                      {false && (
                        <FormHelperText>
                          Arabic Title is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <FormControl error={false} fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="name-ar"
                        sx={{ transform: "none" }}
                      >
                        Status
                      </InputLabel>
                      <Select
                        value={local.status}
                        onChange={e => updateStatus(e.target.value)}
                        displayEmpty
                        required
                        id="status-dropdown"
                        inputProps={{
                          id: "status"
                        }}
                        MenuProps={{
                          id: "status-menu"
                        }}
                      >
                        {filteringKeys.map(filteringKey => (
                          <MenuItem
                            key={filteringKey.id}
                            value={filteringKey.id}
                          >
                            {filteringKey.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {false && (
                        <FormHelperText>Status is required</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}></Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={7}></Grid>
                  <Grid item xs={12}>
                    <Stack
                      spacing={2.5}
                      direction="row"
                      justifyContent="stretch"
                    >
                      <Avatar
                        alt={local.nameEn}
                        src={local.logoUrl || avatar}
                        sx={{
                          width: 64,
                          height: 64,
                          textTransform: "uppercase"
                        }}
                      ></Avatar>
                      <UploadContainer
                        handleLogoUrlClick={handleLogoUrlClick}
                        logoUrlElement={logoUrlElement}
                        logoUrl={local.logoUrl}
                        handleLogoUrlChange={handleLogoUrlChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </FormCardBodyContent>
          </FormCardBody>
        </FormCard>
        <Divider />
        <Stack direction="row" spacing={2} px={7} justifyContent="flex-end">
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            className={classes.cancelBTN}
            color="inherit"
            component={RouterLink}
            to={[configurations.dashboard.basePath, CategoriesPath].join("")}
          >
            <Button sx={{ textTransform: "capitalize" }}>
              {slug ? "Ignore Edits " : "Cancel"}
            </Button>
          </Link>
          <Button
            variant="contained"
            type="submit"
            sx={{ textTransform: "capitalize" }}
            className={classes.submitBTN}
          >
            {slug ? "Save Edits" : "Create Category"}
          </Button>
        </Stack>
      </Stack>
      {error && (
        <WarningModal
          open={error}
          onClose={handleCloseModalWarning}
          title={title}
          message={message}
        />
      )}
    </Box>
  );
};

export default withReducer("category", CategoryReducers)(
  connect(
    state => ({ category: state.category }),
    {
      updateNameEn,
      updateNameAr,
      updateLogoUrl,
      updateStatus,
      getErrorMessage
    }
  )(Form)
);
