import React, { useEffect } from "react";

import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles.css";

import pending from "./assets/pending.svg";
import approved from "./assets/approved.svg";
import error from "./assets/error.svg";

import withReducer from "../../store/withReducer";
import UserDataDeletionReducers from "./reducers";

import userDataDeletionActions from "./actions";

const useStyles = makeStyles(styles);

const UserDataDeletionStatus = props => {
  const { userDataDeletion, getStatus } = props;

  useEffect(() => {
    getStatus(props?.match?.params?.id);
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.page}>
      <div className={classes.box}>
        {(status => {
          switch (status) {
            case "PENDING":
              return (
                <>
                  <img className={classes.img} src={pending} alt="" />
                  <h1 className={classes.title}>Pending</h1>
                  <p className={classes.content}>
                    Your request is being reviewed at the moment
                  </p>
                </>
              );
            case "APPROVED":
              return (
                <>
                  <img className={classes.img} src={approved} alt="" />
                  <h1 className={classes.title}>Approved</h1>
                  <p className={classes.content}>
                    Your request is being reviewed at the moment
                  </p>
                </>
              );
            default:
              return (
                <>
                  <img className={classes.img} src={error} alt="" />
                  <h1 className={classes.title}>Error</h1>
                  <p className={classes.content}>
                    Your request is being reviewed at the moment
                  </p>
                </>
              );
          }
        })(userDataDeletion.status)}
      </div>
    </div>
  );
};

export default withReducer("userDataDeletion", UserDataDeletionReducers)(
  connect(
    state => ({ userDataDeletion: state.userDataDeletion }),
    {
      getStatus: userDataDeletionActions.status.get
    }
  )(UserDataDeletionStatus)
);
