/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import StarsIcon from "@material-ui/icons/Stars";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

// General icons
import WaslaGreyIcon from "./assets/img/greyWaslaIcon.svg";

// Pages and Min Apps Configurations
import quickLinksConfig from "./main/quick-links/quickLinksConfig";
import currencyNPointsConfigurationConfig from "./main/currency-n-points-configuration/currencyNPointsConfigurationConfig";
import redemptionConfig from "./main/redemption/redemptionConfig";
import usersConfig from "./main/users/usersConfig";
import dealsConfig from "./main/deals/dealsConfig";
import cashbacksConfig from "./main/cashbacks/cashbacksConfig";
import userDataDeletionStatusConfig from "./main/user-data-deletion-status/userDataDeletionStatusConfig";
import userDeletionStatusConfig from "./main/user-deletion-status/userDeletionStatusConfig";
const routeConfigs = [
  ...userDataDeletionStatusConfig.routes,
  ...userDeletionStatusConfig.routes
];

// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard.js";
import AnnouncementCards from "./components/AnnouncementCards";
import PointsSystem from "./components/PointsSystem";
import Missions from "./components/Missions";
import Configuration from "./components/Configuration";
import PushNotifications from "./components/PushNotifications";
import WaslaRewards from "./components/WaslaRewards";
import CsPoints from "./components/CsPoints";
import Deals from "./components/Deals";
// import CashbackList from "./components/Cashbacks";

import { basename } from "./config";

export const dashboardRoutes = [
  ...routeConfigs,
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    isPrivate: null,
    roles: ["admin"]
  },
  ...quickLinksConfig.routes,
  {
    path: "/AnnouncementCards",
    name: "Announcement Cards",
    rtlName: "بطاقات الإعلان",
    icon: ViewCarouselIcon,
    component: AnnouncementCards,
    layout: "/admin",
    isPrivate: null,
    roles: ["admin"]
  },
  {
    path: "/PointsSystem",
    name: "Points System",
    rtlName: "نظام النقاط",
    icon: AddCircleIcon,
    component: PointsSystem,
    layout: "/admin",
    isPrivate: null,
    roles: ["admin"]
  },
  {
    path: "/Sections&Missions",
    name: "Sections & Missions",
    rtlName: "المهام",
    icon: StarsIcon,
    component: Missions,
    layout: "/admin",
    isPrivate: null,
    roles: ["admin"]
  },
  {
    path: "/Config",
    name: "Configuration",
    rtlName: "تكوين النقاط",
    icon: AddToPhotosIcon,
    component: Configuration,
    layout: "/admin",
    isPrivate: null,
    roles: ["admin"]
  },
  {
    path: "/pushNotifications",
    name: "Push Notifications",
    rtlName: "إشعارات",
    icon: NotificationsIcon,
    component: PushNotifications,
    layout: "/admin",
    isPrivate: null,
    roles: ["admin"]
  },
  {
    path: "/waslaRewards",
    name: "Wasla Rewards",
    rtlName: "المكافآت",
    icon: MonetizationOnIcon,
    component: WaslaRewards,
    layout: "/admin",
    isPrivate: null,
    roles: ["merchant"]
  },
  ...usersConfig.routes,
  {
    path: "/cs-points",
    name: "CS - Points",
    rtlName: "CS - Points",
    icon: `${WaslaGreyIcon}`,
    component: CsPoints,
    layout: "/admin",
    isPrivate: null,
    roles: ["cs"]
  },
  {
    path: "/Deals&Merchant",
    name: "Deals & Merchant",
    rtlName: "إشعارات",
    icon: `${WaslaGreyIcon}`,
    component: Deals,
    layout: "/admin",
    isPrivate: null,
    roles: ["admin"]
  },
  ...currencyNPointsConfigurationConfig.routes,
  ...redemptionConfig.routes,
  ...dealsConfig.routes,
  ...cashbacksConfig.routes
];

const routes = [];

const generateRoutes = (source, routes) => {
  source.forEach(route => {
    route?.routes && generateRoutes(route.routes, routes);
    if (route?.path && route?.component && route?.layout)
      routes.push({ ...route, path: basename + route.path });
  });
};

generateRoutes(dashboardRoutes, routes);

export default routes;
