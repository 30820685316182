export const UPDATE_ORDER = "update local deals merchant order";
export const UPDATE_NAME_EN = "update local deals merchant en name";
export const UPDATE_NAME_AR = "update local deals merchant ar name";
export const UPDATE_LOGO_URL = "update local deals merchant logo url";
export const UPDATE_URL = "update local deals merchant url";
export const UPDATE_DESCRIPTION_EN =
  "update local deals merchant en description";
export const UPDATE_DESCRIPTION_AR =
  "update local deals merchant ar description";
export const UPDATE_IS_POPULAR = "update local deals merchant is popular";
export const UPDATE_COUNTRIES = "update local deals merchant countries";
export const UPDATE_TARGET_URLS = "update local deals merchant target urls";
export const UPDATE_TNC_EN = "update local deals merchant en tnc";
export const UPDATE_TNC_AR = "update local deals merchant ar tnc";

export const updateOrder = order => dispatch =>
  dispatch({
    type: UPDATE_ORDER,
    payload: { order }
  });

export const updateNameEn = nameEn => dispatch =>
  dispatch({
    type: UPDATE_NAME_EN,
    payload: { nameEn }
  });

export const updateNameAr = nameAr => dispatch =>
  dispatch({
    type: UPDATE_NAME_AR,
    payload: { nameAr }
  });

export const updateLogoUrl = logoUrl => dispatch =>
  dispatch({
    type: UPDATE_LOGO_URL,
    payload: { logoUrl }
  });

export const updateUrl = url => dispatch =>
  dispatch({
    type: UPDATE_URL,
    payload: { url }
  });

export const updateDescriptionEn = descriptionEn => dispatch =>
  dispatch({
    type: UPDATE_DESCRIPTION_EN,
    payload: { descriptionEn }
  });

export const updateDescriptionAr = descriptionAr => dispatch =>
  dispatch({
    type: UPDATE_DESCRIPTION_AR,
    payload: { descriptionAr }
  });

export const updateIsPopular = isPopular => dispatch =>
  dispatch({
    type: UPDATE_IS_POPULAR,
    payload: { isPopular }
  });

export const updateCountries = countries => dispatch =>
  dispatch({
    type: UPDATE_COUNTRIES,
    payload: { countries }
  });

export const updateTargetUrls = targetUrls => dispatch =>
  dispatch({
    type: UPDATE_TARGET_URLS,
    payload: { targetUrls }
  });

export const updateTncEn = tncEn => dispatch =>
  dispatch({
    type: UPDATE_TNC_EN,
    payload: { tncEn }
  });

export const updateTncAr = tncAr => dispatch =>
  dispatch({
    type: UPDATE_TNC_AR,
    payload: { tncAr }
  });
