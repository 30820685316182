import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// redux actions
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
import { clearCallToActionValues } from "../../../../redux/action/AnnouncementCards/CTAButtonActions";
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
import QuestionComponent from "./QuestionComponent";
import LinkTypeComponent from "./LinkTypeComponent";
import UrlComponent from "./UrlComponent";
import PwaComponent from "./PwaComponent";
import InAppComponent from "./InAppComponent";

import styles from "../../styles.css";
import content from "../ContentConfig";

const useStyles = makeStyles(styles);

const CallToActionComponent = ({
  announcementCards,
  checkRequiredFields,
  clearCallToActionValues
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (announcementCards.callToActionBtn === "Yes") {
      checkRequiredFields(false);
      if (
        announcementCards.btnEnglishText &&
        announcementCards.btnArabicText &&
        announcementCards.ctaBtnType
      ) {
        if (
          announcementCards.btnUrl ||
          announcementCards.pwaType ||
          announcementCards.inAppType
        ) {
          checkRequiredFields(true);
        }
      }
    }
  }, [announcementCards]);

  useEffect(() => {
    if (announcementCards.callToActionBtn === "No") {
      checkRequiredFields(true);
      clearCallToActionValues();
    }
  }, [announcementCards.callToActionBtn]);

  return (
    <CardContainer title={`2. ${content.stepsTitle[1]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <QuestionComponent />
          {announcementCards.callToActionBtn === "Yes" && <LinkTypeComponent />}
          {announcementCards.ctaBtnType === "url" && <UrlComponent />}
          {announcementCards.ctaBtnType === "pwa" && <PwaComponent />}
          {announcementCards.ctaBtnType === "inApp" && <InAppComponent />}
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    announcementCards: state.announcementCards
  };
};

export default connect(
  mapStateToProps,
  { checkRequiredFields, clearCallToActionValues }
)(CallToActionComponent);

CallToActionComponent.propTypes = {
  announcementCards: PropTypes.object,
  checkRequiredFields: PropTypes.func,
  clearCallToActionValues: PropTypes.func
};
