const styles = {
  fab: {
    margin: "8px"
  },
  extendedIcon: {
    marginRight: "8px"
  },
  editLink: {
    color: "#fff",
    "&:hover": {
      color: "#fff"
    }
  },
  editIcon: {
    verticalAlign: "middle"
  }
};
export default styles;
