import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AuthorizationServiceConfiguration,
  AuthorizationRequest,
  RedirectRequestHandler,
  FetchRequestor,
  LocalStorageBackend,
  DefaultCrypto,
  TokenRequest,
  BaseTokenRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  AuthorizationNotifier
} from "@openid/appauth";
import { NoHashQueryStringUtils } from "../../utils/noHashQueryStringUtils";

function WithAuth(WrappedComponent) {
  const realmName = process.env.REACT_APP_AUTH_SERVER.split("/")[
    process.env.REACT_APP_AUTH_SERVER.split("/").length - 1
  ];
  const Wrapper = props => {
    const { requiredRoles, basename } = props;
    const roles = requiredRoles;
    const [authError, setAuthError] = useState(null);
    const [authLoading, setAuthLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [authorized, setAuthorized] = useState(!roles.length);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    );
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
    const notifier = new AuthorizationNotifier();
    authorizationHandler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener((request, response, error) => {
      if (response) {
        let extras = null;
        if (request && request.internal) {
          extras = {};
          extras.code_verifier = request.internal.code_verifier;
        }

        const tokenRequest = new TokenRequest({
          client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
          redirect_uri: decodeURIComponent(searchParams.get("state")),
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: response.code,
          refresh_token: undefined,
          extras
        });
        setAuthLoading(true);
        AuthorizationServiceConfiguration.fetchFromIssuer(
          process.env.REACT_APP_AUTH_SERVER,
          new FetchRequestor()
        )
          .then(oResponse => {
            const configuration = oResponse;
            configuration.authorizationEndpoint = (endpoint => {
              const parts = endpoint.split(`realms/${realmName}`);
              parts[0] = process.env.REACT_APP_AUTH_SERVER;
              return parts.join("");
            })(configuration.authorizationEndpoint);
            configuration.endSessionEndpoint = (endpoint => {
              const parts = endpoint.split(`realms/${realmName}`);
              parts[0] = process.env.REACT_APP_AUTH_SERVER;
              return parts.join("");
            })(configuration.endSessionEndpoint);
            configuration.revocationEndpoint = (endpoint => {
              const parts = endpoint.split(`realms/${realmName}`);
              parts[0] = process.env.REACT_APP_AUTH_SERVER;
              return parts.join("");
            })(configuration.revocationEndpoint);
            configuration.tokenEndpoint = (endpoint => {
              const parts = endpoint.split(`realms/${realmName}`);
              parts[0] = process.env.REACT_APP_AUTH_SERVER;
              return parts.join("");
            })(configuration.tokenEndpoint);
            configuration.userInfoEndpoint = (endpoint => {
              const parts = endpoint.split(`realms/${realmName}`);
              parts[0] = process.env.REACT_APP_AUTH_SERVER;
              return parts.join("");
            })(configuration.userInfoEndpoint);
            return tokenHandler.performTokenRequest(
              configuration,
              tokenRequest
            );
          })
          .then(oResponse => {
            localStorage.setItem("access_token", oResponse.accessToken);
            window.location.assign(
              decodeURIComponent(searchParams.get("state"))
            );
            setAuthLoading(false);
          })
          .catch(oError => {
            setAuthError(oError);
            setAuthLoading(false);
          });
      }
    });

    useEffect(() => {
      if (
        localStorage.getItem("access_token") &&
        JSON.parse(atob(localStorage.getItem("access_token").split(".")[1]))
          .exp > Math.floor(new Date().getTime() / 1000)
      ) {
        setAuthenticated(true);
      } else {
        if (roles.length) {
          if (!searchParams.get("code")) {
            login();
            return;
          }
        }
        if (searchParams.get("code")) {
          authorizationHandler.completeAuthorizationRequestIfPossible();
        }
      }
    }, []);

    useEffect(() => {
      if (!roles.length) {
        setAuthorized(true);
      } else {
        if (authenticated) {
          setAuthorized(
            roles.some(
              role =>
                JSON.parse(
                  atob(localStorage.getItem("access_token").split(".")[1])
                ).realm_access.roles.indexOf(role) != -1
            )
          );
        } else {
          setAuthorized(false);
        }
      }
    }, [authenticated]);

    const login = () => {
      setAuthLoading(true);
      // window.analytics.track("Authentication Started", {
      //   client_source: "dashboard"
      // });
      return AuthorizationServiceConfiguration.fetchFromIssuer(
        process.env.REACT_APP_AUTH_SERVER,
        new FetchRequestor()
      )
        .then(response => {
          const authRequest = new AuthorizationRequest({
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            redirect_uri: window.location,
            scope: "openid",
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            state: window.location.href,
            extras: { ui_locales: "en" }
          });
          response.authorizationEndpoint = (endpoint => {
            const parts = endpoint.split(`realms/${realmName}`);
            parts[0] = process.env.REACT_APP_AUTH_SERVER;
            return parts.join("");
          })(response.authorizationEndpoint);
          response.endSessionEndpoint = (endpoint => {
            const parts = endpoint.split(`realms/${realmName}`);
            parts[0] = process.env.REACT_APP_AUTH_SERVER;
            return parts.join("");
          })(response.endSessionEndpoint);
          response.revocationEndpoint = (endpoint => {
            const parts = endpoint.split(`realms/${realmName}`);
            parts[0] = process.env.REACT_APP_AUTH_SERVER;
            return parts.join("");
          })(response.revocationEndpoint);
          response.tokenEndpoint = (endpoint => {
            const parts = endpoint.split(`realms/${realmName}`);
            parts[0] = process.env.REACT_APP_AUTH_SERVER;
            return parts.join("");
          })(response.tokenEndpoint);
          response.userInfoEndpoint = (endpoint => {
            const parts = endpoint.split(`realms/${realmName}`);
            parts[0] = process.env.REACT_APP_AUTH_SERVER;
            return parts.join("");
          })(response.userInfoEndpoint);
          authorizationHandler.performAuthorizationRequest(
            response,
            authRequest
          );
          setAuthLoading(false);
        })
        .catch(error => {
          setAuthError(error);
          setAuthLoading(false);
        });
    };

    const logout = () => {
      return AuthorizationServiceConfiguration.fetchFromIssuer(
        process.env.REACT_APP_AUTH_SERVER,
        new FetchRequestor()
      ).then(oResponse => {
        const configuration = oResponse;
        localStorage.removeItem("access_token");
        window.location.assign(
          (endpoint => {
            const parts = endpoint.split(`realms/${realmName}`);
            parts[0] = process.env.REACT_APP_AUTH_SERVER;
            return parts.join("");
          })(configuration.endSessionEndpoint) +
            "?redirect_uri=" +
            encodeURIComponent(window.location.origin) +
            basename
        );
      });
    };

    const handleLogout = e => {
      return logout();
    };

    const handleLogin = e => {
      return login();
    };

    return (
      <>
        <WrappedComponent
          {...{ ...props, handleLogin, handleLogout }}
          authenticated={authenticated}
          authorized={authorized}
          authError={authError}
          authLoading={authLoading}
        />
      </>
    );
  };

  Wrapper.defaultProps = {
    roles: []
  };

  return Wrapper;
}

export default WithAuth;
