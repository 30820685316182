import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";

// redux actions
// core components
import Card from "../../../../components/Card/Card.js";
import CardBody from "../../../../components/Card/CardBody.js";

import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const ConfigItem = props => {
  const { configItem } = props;

  const classes = useStyles();

  return (
    <Card>
      <CardBody className={classes.cardBody}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBlockStart: "16px",
            alignItems: "center"
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            color="textPrimary"
            style={{
              textTransform: "capitalize"
            }}
          >
            {configItem.event && configItem.event.replace("_", " ")}
          </Typography>

          <Typography
            component="h2"
            variant="subtitle1"
            style={{ color: "#0abb87" }}
          >
            {configItem.rewardPoints &&
              `${configItem.rewardPoints.toLocaleString()} pts`}
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
};
const mapStateToProps = state => {
  return {
    adsItems: state.AdsListView.adsItems
  };
};
export default connect(mapStateToProps)(withRouter(ConfigItem));

ConfigItem.propTypes = {
  match: PropTypes.object,
  configItem: PropTypes.object
};
