import {
  SET_START_DATE,
  SET_END_DATE,
  RESET_STATE,
  EDIT_ANNOUNCEMENT_CARDS,
  EDIT_MISSIONS
} from "../action/actionType";
import Moment from "moment";

let dateNow = Moment(Date.now()).utc();

const initialState = {
  startDate: "",
  endDate: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_START_DATE:
      return {
        ...state,
        startDate: action.payload
      };

    case SET_END_DATE:
      return {
        ...state,
        endDate: action.payload
      };

    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };

    case EDIT_ANNOUNCEMENT_CARDS:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };

    case EDIT_MISSIONS:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };

    default:
      return state;
  }
};
