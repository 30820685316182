export const UPDATE_QUERY = "update cashbacks conversions search query";
export const UPDATE_SEARCH_BY = "update cashbacks conversions search search by";

export const updateQuery = query => dispatch =>
  dispatch({
    type: UPDATE_QUERY,
    payload: { query }
  });

export const updateSearchBy = searchBy => dispatch =>
  dispatch({
    type: UPDATE_SEARCH_BY,
    payload: { searchBy }
  });
