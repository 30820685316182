import { UPDATE_CONVERSION } from "../../actions/item";

const initialState = {
  transactionValue: "",
  commission: "",
  cashbackValue: "",
  currency: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONVERSION:
      return {
        ...state,
        ...action.payload.conversion
      };
    default:
      return state;
  }
};
