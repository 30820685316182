import {
  SET_SPECIFIC_CSV_FILE,
  SET_CSV_FILE_NAME,
  SAVE_CSV_FILE_AS_ARRAY,
  RESET_FILE_STATE
} from "./actionType";

export const setSpecificCSVFile = file => {
  return {
    type: SET_SPECIFIC_CSV_FILE,
    payload: file
  };
};

export const setFileName = name => {
  return {
    type: SET_CSV_FILE_NAME,
    payload: name
  };
};

export const saveFileAsArray = file => {
  return {
    type: SAVE_CSV_FILE_AS_ARRAY,
    payload: file
  };
};

export const resetFile = () => {
  return {
    type: RESET_FILE_STATE
  };
};
