const styles = theme => ({
  containerSteps: {
    "& > div": {
      width: "100%",
      "& .MuiPaper-root.MuiStepper-root": {
        padding: "24px 0",
        "& .MuiStep-root.MuiStep-horizontal": {
          backgroundColor: "rgba(238, 240, 253, 1)",
          padding: "15px 0",
          "& .MuiStepLabel-root.MuiStepLabel-horizontal": {
            flexDirection: "row",
            justifyContent: "center"
          },
          "& .MuiStepLabel-labelContainer": {
            width: "inherit"
          },
          "& .MuiTypography-root.MuiStepLabel-label": {
            marginTop: 0,
            marginLeft: "10px"
          },
          "& .MuiStepConnector-alternativeLabel": {
            display: "none"
          }
        }
      }
    },
    "& .MuiLinearProgress-root ": {
      position: "absolute",
      top: "146px",
      width: "89%",
      height: "5px",
      borderRadius: "5px"
    }
  },
  containerLoading: {
    padding: "80px 0",
    "& p": {
      fontWeight: "500"
    }
  },
  containerError: {
    padding: "80px 0",
    "& p": {
      margin: "5px auto"
    },
    "& p:last-child": {
      width: "65%"
    }
  },
  media: {
    height: 0,
    paddingTop: "22.25%", // 16:9
    backgroundSize: "24%"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  fileFieldWrapper: {
    display: "flex",
    alignItems: "center"
  },
  fileViewContainer: {
    display: "flex",
    alignItems: "center",
    width: "270px",
    height: "30px",
    padding: theme.spacing(1),
    border: "1px solid #ffa726",
    marginLeft: theme.spacing(1)
  },
  textFieldFile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "150px",
    lineHeight: "40px",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    textAlign: "center",
    borderRadius: "5px",
    color: "#fff",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)"
  }
});
export default styles;
