import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
// core components
import CustomModal from "../../WaslaRewards/Modal";
import HistoryCashbackTable from "./HistoryCashbackTable";

import { clearHistoryList } from "../../../redux/action/Configuration/cashbackConfig/FetchCashbackConfig";

function HistoryModal(props) {
  const {
    open,
    handleClose,
    historyLoading,
    historyError,
    historyList,
    title,
    hasNext,
    currentId,
    clearHistoryList
  } = props;

  useEffect(() => {
    return () => {
      clearHistoryList();
    };
  }, []);

  return (
    <CustomModal
      open={open}
      title={title}
      close={handleClose}
      style={{ width: "650px" }}
    >
      <HistoryCashbackTable
        historyList={historyList}
        isLoading={historyLoading}
        error={historyError}
        hasNext={hasNext}
        currentId={currentId}
      />
    </CustomModal>
  );
}
const mapStateToProps = state => ({
  historyLoading: state.cashbackConfig.historyLoading,
  historyError: state.cashbackConfig.historyError,
  historyList: state.cashbackConfig.historyList,
  hasNext: state.cashbackConfig.hasNext
});

export default connect(
  mapStateToProps,
  { clearHistoryList }
)(HistoryModal);

HistoryModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  historyError: PropTypes.bool,
  historyLoading: PropTypes.bool,
  historySuccess: PropTypes.bool,
  hasNext: PropTypes.bool,
  historyLoading: PropTypes.array,
  clearHistoryList: PropTypes.func
};
