import {
  FETCH_MISSIONS_CONFIG,
  RESET_STATE,
  ERROR_FETCH_MISSIONS_CONFIG
} from "../../actionType";

import { adminBaseUrl } from "../../../../components/config";

export const fetchMissionConfig = () => async dispatch => {
  await adminBaseUrl
    .get(`/config`)
    .then(res => {
      dispatch({
        type: FETCH_MISSIONS_CONFIG,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: ERROR_FETCH_MISSIONS_CONFIG,
        payload: err
      });
    });
};

export const clearStateValue = () => {
  return {
    type: RESET_STATE
  };
};
