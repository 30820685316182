import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useLocation } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { submitUsersRecharge } from "../../../../redux/action/WaslaRewards/RechargeUsersActions";
import { modifyUsers } from "../../../../redux/reducers/WaslaRewards/selectors";

// core components
// import Stepper from "../../../Stepper";
import RechargeInfo from "./RechargeInfo";
import ConfirmOrder from "../ConfirmOrder";
import styles from "./styles.css";
import Warning from "../../../../assets/img/Warning.1.svg";
import Success from "../../../../assets/img/success.svg";
import Stepper from "../../../Stepper/refactorStepper";
import CustomizedSnackBars from "../../SnackBar/SnackBar";

import ResponseModal from "./ResponseModal";
const stepsTitle = ["Select Recharge Info", "Confirm Order"];
const useStyles = makeStyles(styles);

function RechargeSteps(props) {
  const classes = useStyles();
  const [progressBar, setProgressBar] = useState(false);
  const {
    isLoading,
    error,
    onCloseModal,
    submitUsersRecharge,
    selectedUsers,
    success,
    serverError
  } = props;
  const location = useLocation();
  const projectId = location?.state?.projectId || location?.state?.id;

  useEffect(() => {
    const enableClass = document.querySelector(".MuiStep-completed");
    setProgressBar(!!enableClass);
  }, [progressBar]);

  const handleSubmit = () => {
    submitUsersRecharge(
      projectId,
      selectedUsers,
      location?.state?.integrationType
    );
  };

  return (
    <div className={classes.container}>
      <Stepper stepsTitle={stepsTitle} handleSubmit={handleSubmit}>
        <RechargeInfo />
        <ConfirmOrder changeProgressValue={setProgressBar} />
      </Stepper>
      <LinearProgress
        variant="determinate"
        value={progressBar ? 100 : 50}
        style={{ width: "93%" }}
      />
      {isLoading && (
        <div className={classes.containerLoading}>
          <CircularProgress />
          <p>Payment Loading</p>
        </div>
      )}
      {error && (
        <ResponseModal
          image={Warning}
          onCloseModal={onCloseModal}
          error={error}
        />
      )}
      {success && (
        <ResponseModal
          image={Success}
          onCloseModal={onCloseModal}
          success={success}
        />
      )}
      {!!serverError && (
        <CustomizedSnackBars
          severity={"error"}
          messege={`Server ${serverError} `}
          errorMsg={!!serverError}
        />
      )}
    </div>
  );
}
const mapStateToProps = state => ({
  isLoading: state.rechargeUsers.isLoading,
  error: state.rechargeUsers.error,
  selectedUsers: modifyUsers(
    state.rechargeUsers.selectedRechargeValue,
    state.waslaRewards.selectedUsers
  ),
  success: state.rechargeUsers.success,
  serverError: state.rechargeUsers.serverError
});

export default connect(
  mapStateToProps,
  { submitUsersRecharge }
)(RechargeSteps);

RechargeSteps.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onCloseModal: PropTypes.func,
  submitUsersRecharge: PropTypes.func,
  selectedUsers: PropTypes.array,
  serverError: PropTypes.string
};
