const initialState = {
  hasNext: false,
  isLoading: false,
  logsList: [],
  error: "",
  totalPages: 0,
  limit: 10
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CS_OTP_LOGS_Table":
      return {
        ...state,
        isLoading: false,
        logsList: action.payload?.data,
        hasNext: action.payload?.meta?.has_next,
        totalPages: action.payload?.meta?.total,
        limit: action.payload?.meta?.limit || state.limit
      };
    case "SET_LOADING_OTP_LOGS_Table":
      return {
        ...state,
        isLoading: action.payload
      };
    case "SET_LOADING_OTP_LOGS_Table_ERROR":
      return {
        ...state,
        error: action.payload
      };
    case "RESET_ERROR":
      return {
        ...state,
        error: ""
      };
    default:
      return state;
  }
};
