import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// redux actions
import {
  submitQuickLink,
  submitQuickLinkEdits
} from "../../redux/action/QuickLinks/CreateQuickLink";
import {
  setQuickLink,
  setIsFeatured,
  setIsNative,
  fetchCategoryTypes,
  editQuickLink
} from "../../redux/action/QuickLinks/QuickLinkFormActions";
import { formValidation } from "./Validations";
import { uploadImage } from "services/upload-image";
// core components
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import ConfirmModal from "../Modals/ConfirmModal";
import ImageUpload from "../ImageUpload";
import ErrorMessage from "../ErrorMessage";
import styles from "./QuickLinkStyles.css";

const useStyles = makeStyles(styles);

function QuickLinkForm(props) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [redirect, setRedirect] = useState(false);
  const viewType = props.location.state ? props.location.state.viewType : "";
  const { pathname } = useLocation();

  useEffect(() => {
    props.fetchCategoryTypes();
  }, []);

  useEffect(() => {
    if (viewType === "Edit") {
      props.editQuickLink(props.location.state.item);
    }
  }, [viewType]);

  const handleFieldChange = event => {
    props.setQuickLink({
      ...props.quickLinks,
      [event.target.name]: event.target.value
    });
  };

  const getImageUrl = async () => {
    let url = "";
    await uploadImage(props.imageUrl)
      .then(res => {
        url = res.data.location;
      })
      .catch(error => {
        console.log("error in upload", error);
      });
    return url;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let validate = formValidation(props.quickLinks, props.imageUrl);
    setErrors(validate);

    let imageURL = props.imageUrl;
    if (typeof props.imageUrl === "object") imageURL = await getImageUrl();

    if (typeof validate === "boolean") {
      if (viewType === "Edit") {
        props
          .submitQuickLinkEdits(
            props.quickLinks.id,
            props.quickLinks,
            imageURL,
            props.countrySelected
          )
          .then(() => setRedirect(true));
      } else {
        props
          .submitQuickLink(props.quickLinks, imageURL, props.countrySelected)
          .then(() => setRedirect(true));
      }
    } else {
      setOpenModal(false);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };
  const removeLastDirectoryPartOf = () => {
    let the_arr = pathname.split("/");
    if (viewType === "Edit") {
      the_arr.pop();
      the_arr.pop();
      return the_arr.join("/");
    }
    the_arr.pop();

    return the_arr.join("/");
  };
  return (
    <>
      <GridContainer justify="space-around">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Add new quick link</h4>
            </CardHeader>
            <form
              className={classes.container}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <ImageUpload
                fieldTitle="Icon photo"
                isRequiredError={errors.imageIcon}
              />
              <TextField
                type="text"
                id="english-name"
                label="English Name"
                name="englishName"
                error={errors.englishName}
                helperText={errors.englishName && "Max characters length is 15"}
                className={classes.textField}
                onChange={handleFieldChange}
                margin="normal"
                variant="outlined"
                value={props.quickLinks.englishName}
                required
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                type="text"
                id="arabic-name"
                label="Arabic Name"
                name="arabicName"
                className={classes.textField}
                onChange={handleFieldChange}
                margin="normal"
                variant="outlined"
                error={errors.arabicName}
                helperText={errors.arabicName && "Max characters length is 15"}
                value={props.quickLinks.arabicName}
                required
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="link"
                label="Link"
                name="url"
                type="text"
                error={errors.linkUrl}
                helperText={
                  errors.linkUrl && "Please enter a http or https url"
                }
                className={classes.textField}
                onChange={handleFieldChange}
                margin="normal"
                variant="outlined"
                value={props.quickLinks.url}
                required
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="order"
                label="Order"
                name="order"
                type="number"
                className={classes.textField}
                onChange={handleFieldChange}
                margin="normal"
                variant="outlined"
                error={errors.order}
                helperText={errors.order && "Please enter order number"}
                value={props.quickLinks.order || ""}
                inputProps={{ min: 1 }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="category"
                select
                label="Category"
                name="categoryId"
                className={classes.textField}
                onChange={handleFieldChange}
                value={props.quickLinks.categoryId}
                error={errors.category}
                helperText={errors.category && "This field is required"}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                variant="outlined"
              >
                <option value="none" disabled hidden>
                  Select an Option
                </option>
                {props.categoryTypes.map(option => {
                  return (
                    <option key={option.id} value={option.id}>
                      {`${option.name.en} - ${option.name.ar}`}
                    </option>
                  );
                })}
              </TextField>
              <div className={classes.checkboxGroup}>
                <FormControlLabel
                  className={classes.checkboxField}
                  control={
                    <Checkbox
                      name="isFeatured"
                      onChange={props.setIsFeatured}
                      color="primary"
                      checked={props.quickLinks.isFeatured}
                    />
                  }
                  label="Featured"
                />
                <FormControlLabel
                  className={classes.checkboxField}
                  control={
                    <Checkbox
                      name="isNative"
                      onChange={props.setIsNative}
                      color="primary"
                      checked={props.quickLinks.isNative}
                    />
                  }
                  label="Native"
                />
              </div>
              {viewType === "Edit" ? (
                <Button
                  style={{ margin: "20px", display: "block" }}
                  className={classes.button}
                  variant="contained"
                  type="button"
                  onClick={handleOpen}
                >
                  Save
                </Button>
              ) : (
                <Button
                  style={{ margin: "20px", display: "block" }}
                  className={classes.button}
                  variant="contained"
                  type="submit"
                >
                  submit
                </Button>
              )}
            </form>
          </Card>
        </GridItem>
        {redirect ? (
          <Redirect from="/:url*(/+)" to={removeLastDirectoryPartOf()} />
        ) : null}
        {openModal && (
          <ConfirmModal
            open={openModal}
            handleEdit={handleSubmit}
            onClose={handleClose}
            type="edit"
          />
        )}
        {props.errorMessage && (
          <ErrorMessage message="Sorry something went wrong!" />
        )}
      </GridContainer>
    </>
  );
}

const mapStateToProps = state => {
  return {
    quickLinks: state.quickLinks,
    categoryTypes: state.quickLinksCategories.categoryTypes,
    imageUrl: state.imageUrl.url,
    errorMessage: state.errorMessage.error,
    countrySelected: state.Countries.countryNameSelected
  };
};

export default connect(
  mapStateToProps,
  {
    setQuickLink,
    setIsFeatured,
    setIsNative,
    fetchCategoryTypes,
    submitQuickLink,
    submitQuickLinkEdits,
    editQuickLink
  }
)(QuickLinkForm);

QuickLinkForm.propTypes = {
  location: PropTypes.object,
  quickLinks: PropTypes.object,
  categoryTypes: PropTypes.arrayOf(PropTypes.object),
  setQuickLink: PropTypes.func,
  submitQuickLink: PropTypes.func,
  submitQuickLinkEdits: PropTypes.func,
  editQuickLink: PropTypes.func,
  setIsFeatured: PropTypes.func,
  setIsNative: PropTypes.func,
  fetchCategoryTypes: PropTypes.func,
  imageUrl: PropTypes.string,
  errorMessage: PropTypes.bool,
  countrySelected: PropTypes.string
};
