import * as redemptionService from "../../../../services/redemption";

export const SET_OPTION = "set option";
export const CREATE_OPTION = "create option";
export const UPDATE_OPTION = "update option";

export const setOption = option => dispatch =>
  dispatch({
    type: SET_OPTION,
    payload: { option }
  });

export const createOption = option => dispatch =>
  redemptionService.createOption(option).then(data => {
    dispatch({
      type: CREATE_OPTION,
      payload: { option }
    });
    return data;
  });

export const updateOption = (id, option) => dispatch =>
  redemptionService.updateOption(id, option).then(data => {
    dispatch({
      type: UPDATE_OPTION,
      payload: { id, option }
    });
  });

export const updateOptionImage = (id, option) => dispatch =>
  redemptionService.updateOptionImage(id, option);
