import qs from "qs";
import { waslaRewardBaseurl, authURL } from "../../../components/config";

import {
  SET_ALL_ITEMS_RECHARGE,
  FETCH_USERS_RECHARGE_FAILED,
  LOADING_USERS_RECHARGE_FAILED,
  SET_EDIT_ITEM_RECHARGE,
  SET_CURRENT_RECHARGES,
  SET_FINISH_ALL_EDIT,
  SET_FINIAL_TOTAL_EDIT_RECHARGE,
  RESET_EDIT_RECHARGE_STATE
} from "../actionType";

export const fetchRechargesFailedList = ({
  pageNum,
  batchId,
  projectId
}) => async dispatch => {
  dispatch({
    type: LOADING_USERS_RECHARGE_FAILED
  });
  await waslaRewardBaseurl
    .get(`/external/cashout/charge/?pageNo=${pageNum || 1}&pageSize=10`, {
      params: {
        bulkId: batchId,
        projectId,
        success: false
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          arrayFormat: "comma",
          encode: false,
          skipNulls: true
        });
      }
    })
    .then(res => {
      dispatch({
        type: FETCH_USERS_RECHARGE_FAILED,
        payload: res.data
      });
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_SERVER_RECHARGE",
          payload: "Authentication expired"
        });
      }
      dispatch({
        type: "SET_ERROR_SERVER_RECHARGE",
        payload: "Something went wrong"
      });
    });
};

export const setCurrentRecharges = arr => {
  return {
    type: SET_CURRENT_RECHARGES,
    payload: arr
  };
};
export const setEditItemRecharge = arr => {
  return {
    type: SET_EDIT_ITEM_RECHARGE,
    payload: arr
  };
};

export const setAllItemsRecharge = (items, pageNum, hasNext) => {
  return {
    type: SET_ALL_ITEMS_RECHARGE,
    payload: { items, pageNum, hasNext }
  };
};
export const setFinishAllEdit = status => {
  return {
    type: SET_FINISH_ALL_EDIT,
    payload: status
  };
};

export const setFinialTotalRecharge = data => {
  return {
    type: SET_FINIAL_TOTAL_EDIT_RECHARGE,
    payload: data
  };
};

export const resetEditRechargeState = () => {
  return {
    type: RESET_EDIT_RECHARGE_STATE
  };
};
