import {
  UPDATE_OLD_TRANSACTION_VALUE,
  UPDATE_OLD_COMMISSION,
  UPDATE_OLD_CASHBACK,
  UPDATE_NEW_TRANSACTION_VALUE,
  UPDATE_NEW_COMMISSION,
  UPDATE_NEW_CASHBACK,
  UPDATE_STATUS,
  UPDATE_REASON,
  UPDATE_NOTES
} from "../../actions/local";

const initialState = {
  oldTransactionValue: "",
  oldCommission: "",
  oldCashback: "",
  newTransactionValue: "",
  newCommission: "",
  newCashback: "",
  status: "",
  reason: "",
  notes: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OLD_TRANSACTION_VALUE:
      return {
        ...state,
        oldTransactionValue: action.payload.oldTransactionValue
      };
    case UPDATE_OLD_COMMISSION:
      return {
        ...state,
        oldCommission: action.payload.oldCommission
      };
    case UPDATE_OLD_CASHBACK:
      return {
        ...state,
        oldCashback: action.payload.oldCashback
      };
    case UPDATE_NEW_TRANSACTION_VALUE:
      return {
        ...state,
        newTransactionValue: action.payload.newTransactionValue
      };
    case UPDATE_NEW_COMMISSION:
      return {
        ...state,
        newCommission: action.payload.newCommission
      };
    case UPDATE_NEW_CASHBACK:
      return {
        ...state,
        newCashback: action.payload.newCashback
      };
    case UPDATE_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
    case UPDATE_REASON:
      return {
        ...state,
        reason: action.payload.reason
      };
    case UPDATE_NOTES:
      return {
        ...state,
        notes: action.payload.notes
      };
    default:
      return state;
  }
};
