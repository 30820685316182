import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// redux actions
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";
// core components
import Info from "./Info";

function ConfirmOrder(props) {
  const { checkRequiredFields, changeProgressValue, list, type } = props;

  useEffect(() => {
    checkRequiredFields(true);
    changeProgressValue(true);
    return () => {
      changeProgressValue(false);
    };
  }, [checkRequiredFields]);

  return <Info list={list} type={type} />;
}

export default connect(
  null,
  {
    checkRequiredFields
  }
)(ConfirmOrder);

ConfirmOrder.propTypes = {
  checkRequiredFields: PropTypes.func,
  type: PropTypes.string,
  list: PropTypes.array
};
