import {
  FETCH_ADS_BUCKET,
  FETCH_ADS_REWARDS,
  RESET_STATE
} from "../actionType";
import { pointsBaseUrl } from "../../../components/config";

export const fetchAdsBucket = () => async dispatch => {
  await pointsBaseUrl.get(`/ad`).then(res => {
    dispatch({
      type: FETCH_ADS_BUCKET,
      payload: res.data.ads
    });
  });
};

export const fetchAdsRewards = (id, pageNo) => async dispatch => {
  await pointsBaseUrl
    .get(`/ad/${id}/rewards/?pageNo=${pageNo || 1}&pageSize=10`)
    .then(res => {
      dispatch({
        type: FETCH_ADS_REWARDS,
        payload: res.data.data
      });
    });
};

export const clearStateValue = () => {
  return {
    type: RESET_STATE
  };
};
