import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setTargetType } from "../../../../redux/action/Missions/MissionsTargetingActions";
import { setSpecificCSVFile } from "../../../../redux/action/uploadFileActions";
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
import FileUpload from "../../../FileUpload";

import styles from "../../styles.css";
import { content } from "../MissionsConfig";
import { targetingType } from "./MissionTargetingConfig";

const useStyles = makeStyles(styles);

const CardTargeting = ({
  missions,
  fileUpload,
  setTargetType,
  setSpecificCSVFile,
  checkRequiredFields
}) => {
  const classes = useStyles();

  const handleTargetSelection = event => {
    const value = event.target.value;
    if (value !== "specific") {
      setSpecificCSVFile("");
    }
    setTargetType(value);
  };

  useEffect(() => {
    if (missions.targetType === "generic") {
      checkRequiredFields(true);
    } else if (missions.targetType === "specific" && fileUpload.length !== 0) {
      checkRequiredFields(true);
    } else {
      checkRequiredFields(false);
    }
  }, [missions, fileUpload]);

  return (
    <CardContainer title={`3. ${content.stepsTitle[2]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <Grid className={`${classes.container} ${classes.marginBlock}`}>
            <TextField
              id="target-type"
              select
              label="Target Type"
              className={classes.textField}
              name="targetType"
              value={missions.targetType}
              onChange={handleTargetSelection}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
              required
            >
              <option value="none">Select an Option</option>
              {targetingType.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            {missions.targetType === "specific" && (
              <FileUpload fileType="convertToText" />
            )}
          </Grid>
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    missions: state.missions,
    fileUpload: state.uploadFile.fileAsArray
  };
};

export default connect(
  mapStateToProps,
  {
    setTargetType,
    setSpecificCSVFile,
    checkRequiredFields
  }
)(CardTargeting);

CardTargeting.propTypes = {
  missions: PropTypes.object,
  fileUpload: PropTypes.string,
  setTargetType: PropTypes.func,
  setSpecificCSVFile: PropTypes.func,
  checkRequiredFields: PropTypes.func
};
