import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setButtonUrl } from "../../../../redux/action/AnnouncementCards/CTAButtonActions";
// core components
import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const UrlComponent = ({ btnUrl, setButtonUrl }) => {
  const classes = useStyles();

  const handleChange = event => {
    setButtonUrl(event.target.value);
  };

  return (
    <Grid className={classes.container}>
      <TextField
        id="url"
        label="URL"
        name="url"
        type="url"
        pattern="https://.*"
        helperText="Enter http or https url"
        className={classes.textField}
        value={btnUrl}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    btnUrl: state.announcementCards.btnUrl
  };
};

export default connect(
  mapStateToProps,
  { setButtonUrl }
)(UrlComponent);

UrlComponent.propTypes = {
  btnUrl: PropTypes.string,
  setButtonUrl: PropTypes.func
};
