import {
  SET_TYPE_OF_TRANSFER,
  SET_AMOUNT_OF_POINTS,
  SET_USER_MOBILE_NUMBER,
  CLEAR_TYPE_OF_TRANSFER,
  BONUS_ADD_ERROR,
  ADD_BONUS_POINTS,
  TRANSFER_SUCCESS,
  TRANSFER_ERROR
} from "../../action/actionType";

const initialState = {
  typeOfTransfer: "",
  amountOfPoints: null,
  userMobileNumber: "",
  bonusAddError: null,
  bonusAddSuccess: null,
  transferSuccess: null,
  transferError: null,
  typeOfPointsAction: "",
  csAllPointsBonus: 0,
  csAllPointsFraud: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TYPE_OF_TRANSFER:
      return {
        ...state,
        typeOfTransfer: action.payload
      };

    case SET_AMOUNT_OF_POINTS:
      return {
        ...state,
        amountOfPoints: parseInt(action.payload)
      };
    case SET_USER_MOBILE_NUMBER:
      return {
        ...state,
        userMobileNumber: action.payload
      };
    case ADD_BONUS_POINTS:
      return {
        ...state,
        bonusAddSuccess: action.payload
      };
    case BONUS_ADD_ERROR:
      return {
        ...state,
        bonusAddError: action.payload
      };
    case TRANSFER_SUCCESS:
      return {
        ...state,
        transferSuccess: action.payload
      };
    case TRANSFER_ERROR:
      return {
        ...state,
        transferError: action.payload
      };
    case "REST_TRANSFER_REQUEST_RESULT":
      return {
        ...state,
        transferSuccess: null,
        transferError: null
      };
    case "Fetch_BUCKETS_BONUS_POINTS_CS": {
      return {
        ...state,
        csAllPointsBonus: action.payload
      };
    }
    case "Fetch_BUCKETS_FRAUD_POINTS_CS": {
      return {
        ...state,
        csAllPointsFraud: action.payload
      };
    }
    case "SET_TYPE_OF_POINTS":
      return {
        ...state,
        typeOfPointsAction: action.payload
      };
    case CLEAR_TYPE_OF_TRANSFER:
      return {
        ...state,
        ...initialState,
        csAllPointsBonus: state.csAllPointsBonus,
        csAllPointsFraud: state.csAllPointsFraud
      };

    default:
      return state;
  }
};
