import LinkIcon from "@material-ui/icons/Link";
import Links from "../../components/QuickLink";
import QuickLinks from "./QuickLinks";
import categoriesConfig from "./categories/categoriesConfig";
import { quickLinksPath, quickLinksLinksPath } from "./paths";

export default {
  routes: [
    {
      path: quickLinksPath,
      name: "Quick Links",
      rtlName: "اللينكات السريعة",
      icon: LinkIcon,
      component: QuickLinks,
      layout: "main",
      isPrivate: null,
      roles: ["admin"],
      routes: [
        {
          path: quickLinksLinksPath,
          name: "Quick Links",
          rtlName: "اللينكات السريعة",
          icon: LinkIcon,
          component: Links,
          layout: "main",
          isPrivate: null,
          roles: ["admin"]
        },
        ...categoriesConfig.routes
      ]
    }
  ]
};
