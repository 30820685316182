import React from "react";
import PropTypes from "prop-types";
// core components
import MissionCard from "../MissionCard";

const CardDetails = ({ item }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <MissionCard
        flexBasis="48.5%"
        title={item.titleEn}
        body={item.descriptionEn}
        btnText={item.buttonTextEn}
        imgUrl={item.thumbnailUrl}
        buttonUrl={item.buttonUrl}
        className="announcementCardContainer"
      />
      <MissionCard
        flexBasis="48.5%"
        direction="rtl"
        title={item.titleAr}
        body={item.descriptionAr}
        btnText={item.buttonTextAr}
        imgUrl={item.thumbnailUrl}
        buttonUrl={item.buttonUrl}
        iconUrl={item?.iconUrl}
        className="announcementCardContainer"
      />
    </div>
  );
};

export default CardDetails;

CardDetails.propTypes = {
  item: PropTypes.object
};
