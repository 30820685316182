import {
  CALCULATE_POINTS_BUDGET,
  CALCULATE_CASH_BUDGET,
  RESET_STATE
} from "../action/actionType";

const initialState = {
  cash: 0,
  points: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CALCULATE_CASH_BUDGET:
      return {
        ...state,
        cash: parseInt(action.payload)
      };

    case CALCULATE_POINTS_BUDGET:
      return {
        ...state,
        points: parseInt(action.payload)
      };

    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};
