import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkRequiredFields } from "../../redux/action/checkRequiredFieldsActions";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import styles from "./StepperStyles.css";

const useStyles = makeStyles(styles);

function getSteps(stepsTitle) {
  return stepsTitle;
}

function getStepContent(stepIndex, contentArray) {
  return contentArray[stepIndex];
}

function HorizontalLabelPositionBelowStepper(props) {
  const {
    stepsTitle,
    children,
    handleSubmit,
    checkRequiredFields,
    errorMessage
  } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(stepsTitle);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    checkRequiredFields(false);

    if (activeStep === steps.length - 1) {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (errorMessage) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  }, [errorMessage]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div></div>
        ) : (
          <div>
            {getStepContent(activeStep, children)}
            <div className={classes.buttonGroup}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant="contained"
                disabled={!props.shouldSubmit}
                onClick={handleNext}
                className={classes.customButton}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  shouldSubmit: state.shouldSubmit.shouldSubmit
});

export default connect(
  mapStateToProps,
  { checkRequiredFields }
)(HorizontalLabelPositionBelowStepper);

HorizontalLabelPositionBelowStepper.propTypes = {
  stepsTitle: PropTypes.array,
  children: PropTypes.array,
  formType: PropTypes.string,
  shouldSubmit: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  checkRequiredFields: PropTypes.func,
  errorMessage: PropTypes.string
};
