export default {
  id: "console",
  main: record => {
    if (
      record.message &&
      record.type &&
      ["log", "info", "warn", "error", "debug"].indexOf(record.type) != -1
    )
      console[record.type](record.message);
  },
  types: [],
  tags: []
};
