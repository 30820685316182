import { RESET_STATE } from "../actionType";
import { pushNotificationBaseurl } from "../../../components/config";
import {
  toggleErrorMessage,
  toggleSuccessMessage
} from "../ErrorMessageActions";

export const submitPushNotification = (data, file) => async dispatch => {
  const body = {
    title: data.title,
    body: data.body,
    image: data.imageUrl,
    cta: data.callToAction
  };

  if (!body.cta) {
    delete body.cta;
  }
  if (!body.image) {
    delete body.image;
  }

  await pushNotificationBaseurl
    .post(
      "",
      {
        message: {
          ...body
        },
        targets: file
      },
      {
        headers: {
          Authorization: "Y!a;m=C#.{O4/sz6><SZ7zSO#}vS~u"
        }
      }
    )
    .then(() => {
      dispatch(resetFields());
      dispatch(toggleSuccessMessage());
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};

export const resetFields = () => {
  return {
    type: RESET_STATE
  };
};
