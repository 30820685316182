import { ADD_BONUS_POINTS, BONUS_ADD_ERROR } from "../actionType";
import { pointsBaseUrl } from "../../../components/config";

export const addBonusPoints = (type, amount) => async dispatch => {
  await pointsBaseUrl
    .post(`/buckets/${type}/addPoints`, {
      amount
    })
    .then(res => {
      dispatch({
        type: ADD_BONUS_POINTS,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: BONUS_ADD_ERROR,
        payload: err.response
      });
    });
};
