export const checkFieldLimit = (input, limit) => {
  if (input.length <= limit) {
    return true;
  } else {
    return false;
  }
};

export const urlValidation = url => {
  var expression = /https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

  var regex = new RegExp(expression);

  return url.match(regex);
};
