import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
// redux actions
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
// core components
import styles from "../../styles.css";
import MobileDevice from "../../../../assets/img/Mobile-Devices-Wifi.svg";

const useStyles = makeStyles(styles);

function ConfirmOrder(props) {
  const classes = useStyles();

  const { checkRequiredFields, changeProgressValue } = props;

  useEffect(() => {
    checkRequiredFields(true);
    changeProgressValue(true);
    return () => {
      changeProgressValue(false);
    };
  }, [checkRequiredFields]);

  return (
    <div>
      <CardMedia
        className={classes.media}
        image={MobileDevice}
        title="Mobile Device"
      />
      <Typography component="p" variant="h6" style={{ marginBottom: "48px" }}>
        Confirm recharge request?
      </Typography>
    </div>
  );
}

export default connect(
  null,
  {
    checkRequiredFields
  }
)(ConfirmOrder);

ConfirmOrder.propTypes = {
  checkRequiredFields: PropTypes.func
};
