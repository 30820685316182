import authenticatedHttpClientService from "../authenticated-http-client";

export const uploadImage = image => {
  const formData = new FormData();
  formData.append("file", image);
  return authenticatedHttpClientService.post(
    `${process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
};
