import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CircularProgress from "@material-ui/core/CircularProgress";
// redux actions
import ErrorMessage from "../../ErrorMessage";
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import TableHeader from "./TableHeader";
import styles from "../styles.css";
import { getHistoryList } from "../../../redux/action/Configuration/cashbackConfig/FetchCashbackConfig";

const useStyles = makeStyles(styles);

function HistoryCashbackTable(props) {
  const {
    getHistoryList,
    hasNext,
    historyList,
    error,
    isLoading,
    currentId
  } = props;
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [pageNum, setPageNum] = useState(1);

  const createRows = () => {
    const list = historyList.map(item => {
      const convertUtcToMilliseconds = Moment(item.modifiedAt).valueOf();

      let historyItem = {
        modifiedBy: item.modifiedBy,
        modifiedAt: convertUtcToMilliseconds,
        oldValue: item.oldValue,
        newValue: item.newValue
      };
      return historyItem;
    });
    setRows([...list]);
  };

  useEffect(() => {
    if (pageNum >= 1 && currentId) {
      getHistoryList({
        id: currentId,
        page: pageNum
      });
    }
  }, [pageNum, currentId]);

  useEffect(() => {
    if (historyList.length !== 0) createRows(historyList);
  }, [historyList]);

  const handleNext = () => {
    if (hasNext) {
      setPageNum(pageNum + 1);
    }
  };

  const handlePrevious = () => {
    if (pageNum !== 1) {
      setPageNum(pageNum - 1);
    }
  };

  const emptyRows = 10 - Math.min(10, rows.length - 0 * 10);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Paper className={classes.paper}>
          {error && <ErrorMessage message="Sorry something went wrong!" />}
          {isLoading && rows.length === 0 && <CircularProgress />}
          {!isLoading && rows.length === 0 && (
            <Typography
              component="p"
              variant="subtitle1"
              align="center"
              gutterBottom={true}
              style={{ width: "100%", padding: "1rem 0" }}
              aria-label="is empty"
            >
              There is no History yet.
            </Typography>
          )}
          {!isLoading && rows.length !== 0 && (
            <>
              <div style={{ maxHeight: "350px", overflowY: "scroll" }}>
                <Table
                  className={`${classes.table} ${classes.editTable}`}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <TableHeader classes={classes} />

                  <TableBody>
                    {rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={labelId}
                          style={{
                            padding: 12
                          }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            style={{ paddingLeft: 5 }}
                          >
                            {row.modifiedBy}
                          </TableCell>
                          <TableCell scope="row" padding="none">
                            {Moment(row.modifiedAt).format("DD MMM, YYYY, LT")}
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            style={{
                              color: "#0abb87"
                            }}
                          >
                            {row.oldValue &&
                              Number.parseFloat(row.oldValue).toFixed(3)}
                          </TableCell>
                          <TableCell scope="row" padding="none">
                            <Typography
                              variant="subtitle1"
                              component="p"
                              style={{
                                color: "#0abb87"
                              }}
                            >
                              {row.newValue &&
                                Number.parseFloat(row.newValue)?.toFixed(3)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 25 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "space-between",
                  justifyContent: "space-between",
                  padding: "0.3em"
                }}
              >
                <Button
                  type="button"
                  onClick={handlePrevious}
                  disabled={pageNum === 1}
                  color="primary"
                  className={`${classes.arrowButton} ${pageNum !== 1 &&
                    classes.primaryButton}`}
                >
                  <NavigateBeforeIcon />
                </Button>

                <Button
                  type="button"
                  onClick={handleNext}
                  color="primary"
                  disabled={!hasNext}
                  className={`${classes.arrowButton} ${hasNext &&
                    classes.primaryButton}`}
                >
                  <NavigateNextIcon />
                </Button>
              </div>
            </>
          )}
        </Paper>
      </GridItem>
    </GridContainer>
  );
}

export default connect(
  null,
  { getHistoryList }
)(HistoryCashbackTable);

HistoryCashbackTable.propTypes = {
  usersRechargeFailed: PropTypes.array,
  hasNext: PropTypes.bool,
  error: PropTypes.bool,
  getHistoryList: PropTypes.func,
  isLoading: PropTypes.bool
};
