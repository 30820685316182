import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

// @material-ui/core components

// redux actions
import { UploadUsersToRecharge } from "../../../../redux/action/WaslaRewards/uploadRechargeActions";
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";

// core components
import FileUpload from "../fileField";
import { Validation } from "../Validtion";

const uploadError = {
  phoneNumber: "Please check the phone number(s) on line(s): ",
  operator: "Please check the operator on line(s): ",
  amount: "Please check the amount on line(s): ",
  comment: "Please check the comment on line(s):",
  notMatched: "* Error with CSV, please check format."
};

const ManualRecharge = ({
  fileUpload,
  UploadUsersToRecharge,
  checkRequiredFields
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [usersRecharge, setUsersRecharge] = useState([]);
  const location = useLocation();

  const factoryPhoneNumbers = useCallback(() => {
    for (const [index, element] of fileUpload.entries()) {
      let itemArray = element.split(",");
      if (itemArray.length === 1) {
        setErrorMessage(`${uploadError.notMatched}`);
        setUsersRecharge([]);
        return;
      }

      if (Validation.isPhoneNumber(itemArray[0]?.trim())) {
        setErrorMessage(
          `${uploadError.phoneNumber} ${index +
            1}.... \n Phone numbers should be entered: “01234567891”`
        );
        return;
      }

      if (Validation.isOperator(itemArray[1]?.trim())) {
        setErrorMessage(
          `${uploadError.operator} ${index +
            1}.... \n Operators should be entered: “Vodafone, Orange, Etisalat, We”`
        );
        return;
      }

      if (Validation.isNumber(itemArray[2]?.trim(), itemArray[1]?.trim())) {
        setErrorMessage(
          `${uploadError.amount}  ${index +
            1}.... \n NET amount should either be a whole numbers, or 0.5 increment.  
            TIP: Divide the entered amount by 1.428`
        );
        return;
      }

      if (itemArray[3]?.trim().length >= 50) {
        setErrorMessage(
          `${uploadError.comment} ${index +
            1}.... \n 50-character limit on Comment field exceeded`
        );
        return;
      }
      setErrorMessage("");
      let user = {
        phoneNumber: itemArray[0]?.trim(),
        operator: itemArray[1]?.trim(),
        price: parseFloat(itemArray[2]?.trim()),
        comment: itemArray[3]?.trim() ?? null
      };
      setUsersRecharge(initialState => [...initialState, user]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (fileUpload.length !== 0) {
      factoryPhoneNumbers();
    }
  }, [fileUpload]);
  useEffect(() => {
    if (usersRecharge.length !== 0 && !errorMessage) {
      const projectId = location?.state?.id;
      UploadUsersToRecharge({ projectId, usersRecharge });
      checkRequiredFields(true);
    } else {
      checkRequiredFields(false);
    }
  }, [usersRecharge]);

  useEffect(() => {
    return () => {
      setUsersRecharge([]);
      setErrorMessage("");
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "8px 0 15px"
      }}
    >
      <h4 style={{ fontSize: "16px", fontWeight: 600 }}>Upload CSV: </h4>
      <FileUpload errorMessage={errorMessage} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    fileUpload: state.uploadFile.fileAsArray
  };
};

export default connect(
  mapStateToProps,
  {
    UploadUsersToRecharge,
    checkRequiredFields
  }
)(ManualRecharge);

ManualRecharge.propTypes = {
  fileUpload: PropTypes.array,
  UploadUsersToRecharge: PropTypes.func,
  checkRequiredFields: PropTypes.func
};
