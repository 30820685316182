import {
  SET_MISSION_TASKS,
  SET_MISSIONS_CTA_BUTTON_TEXT_TASKS,
  SET_CTA_BUTTON_TEXT_SELECTION_TASKS,
  SET_ALL_MISSION_TASKS,
  CLEAR_MISSION_TASK,
  EDIT_MISSION_TASKS,
  DELETE_MISSION_TASKS,
  SET_TASK_TYPE
} from "../actionType";

export const setMissionTasks = data => {
  return {
    type: SET_MISSION_TASKS,
    payload: { ...data }
  };
};

export const setCTAButtonTextTasks = data => {
  return {
    type: SET_MISSIONS_CTA_BUTTON_TEXT_TASKS,
    payload: { ...data }
  };
};

export const setCTAButtonTextSelectionTasks = data => {
  return {
    type: SET_CTA_BUTTON_TEXT_SELECTION_TASKS,
    payload: { ...data }
  };
};

export const setTaskType = (id, name) => {
  return {
    type: SET_TASK_TYPE,
    payload: { id, name }
  };
};

export const setAllMissionTasks = data => {
  return {
    type: SET_ALL_MISSION_TASKS,
    payload: data
  };
};

export const editMissionTasks = task => {
  return {
    type: EDIT_MISSION_TASKS,
    payload: task
  };
};

export const deleteMissionTasks = id => {
  return {
    type: DELETE_MISSION_TASKS,
    payload: id
  };
};

export const clearMissionTasks = () => {
  return {
    type: CLEAR_MISSION_TASK
  };
};
