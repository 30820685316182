import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CardContent } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { CardMedia } from "@material-ui/core";
import { Typography } from "@material-ui/core";

// core components
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";

import styles from "./QuickLinkStyles.css";
import ActionButtons from "../ActionButtons/ActionButtons.js";

const useStyles = makeStyles(styles);

export default function QuickLinkCard(props) {
  const classes = useStyles();
  const { item, deleteQuickLink } = props;
  return (
    <Card>
      <CardBody className={classes.cardBody}>
        <CardContent className={classes.content}>
          <div>
            <Typography component="h5" variant="h5">
              {`${item.name.en} - ${item.name.ar}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {item.category &&
                `${item.category.name.en} - ${item.category.name.ar}`}
            </Typography>
            <Typography className={classes.recordLink}>
              <Link href={item.url} className={classes.link}>
                {item.url}
              </Link>
            </Typography>
            <div className={classes.cardSituation}>
              {item.isFeatured ? (
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.ItemSituation}
                  style={{ color: "green" }}
                >
                  Featured
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.ItemSituation}
                  style={{ color: "red" }}
                >
                  Not Featured
                </Typography>
              )}
              {item.isNative ? (
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.ItemSituation}
                  style={{ color: "green" }}
                >
                  Native
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.ItemSituation}
                  style={{ color: "red" }}
                >
                  Not Native
                </Typography>
              )}
            </div>
          </div>

          <ActionButtons
            ItemId={item.id}
            item={item}
            RedirectionLink="/form"
            handleDelete={deleteQuickLink}
            deleteButton={true}
            editButton={true}
          />
        </CardContent>
        <CardMedia
          className={classes.cover}
          image={item.iconUrl}
          title="Live from space album cover"
        />
      </CardBody>
    </Card>
  );
}

QuickLinkCard.propTypes = {
  item: PropTypes.object
};
