import { GET_USER_REQUESTS } from "../../actions/user-requests";
import { GET_OPTIONS } from "../../actions/options";
import { SET_OPTION } from "../../actions/option";
import { initialState as optionInitialState } from "../option";
import {
  INIT_USER_REQUESTS_PAGINATION,
  CHANGE_USER_REQUESTS_PAGE,
  UPDATE_USER_REQUESTS_QUERY,
  UPDATE_USER_REQUESTS_DATE,
  UPDATE_USER_REQUESTS_PROVIDERS,
  UPDATE_USER_REQUESTS_CATEGORIES,
  UPDATE_USER_REQUESTS_TYPES,
  UPDATE_USER_REQUESTS_CURRENCIES,
  UPDATE_USER_REQUESTS_ORDER_BY,
  SYNC_USER_REQUESTS,
  ADD_USER_REQUESTS_EXPORTING,
  REMOVE_USER_REQUESTS_EXPORTING,
  EMPTY_USER_REQUESTS_EXPORTING,
  ADD_USER_REQUEST_EXPORTING,
  REMOVE_USER_REQUEST_EXPORTING,
  INIT_OPTIONS_PAGINATION,
  CHANGE_OPTIONS_PAGE,
  UPDATE_QUERY,
  UPDATE_PROVIDERS,
  UPDATE_CATEGORIES,
  UPDATE_TYPES,
  SYNC_OPTIONS,
  UPDATE_OPTION_VALUE,
  CREATE_OPTION_DENOMINATION,
  DELETE_OPTION_DENOMINATION,
  UPDATE_OPTION_CHANGES_STATUS,
  UPDATE_OPTION_ERRORS_STATUS,
  RESET_OPTION,
  SYNC_OPTION
} from "../../actions/layout";

const initialState = {
  userRequests: {
    loaded: false,
    syncing: false,
    pagination: {
      page: 0,
      size: 0,
      pages: 0
    },
    query: "",
    date: {
      from: "",
      to: ""
    },
    providers: [],
    categories: [],
    types: [],
    currencies: [],
    orderBy: "-creationTimestamp,adminAction.dateTaken",
    exporting: []
  },
  options: {
    loaded: false,
    syncing: false,
    pagination: {
      page: 0,
      size: 0,
      pages: 0
    },
    query: "",
    providers: [],
    categories: [],
    types: []
  },
  option: {
    syncing: false,
    hasErros: false,
    hasChanges: false,
    values: optionInitialState
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUESTS:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          loaded: true,
          pagination: {
            ...state.userRequests.pagination,
            pages: Math.ceil(
              action.payload.meta.total / state.userRequests.pagination.size
            )
          }
        }
      };
    case INIT_USER_REQUESTS_PAGINATION:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          pagination: {
            ...state.userRequests.pagination,
            size: action.payload.size,
            page: 1
          }
        }
      };
    case CHANGE_USER_REQUESTS_PAGE:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          pagination: {
            ...state.userRequests.pagination,
            page: action.payload.page
          }
        }
      };
    case UPDATE_USER_REQUESTS_QUERY:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          query: action.payload.query
        }
      };
    case UPDATE_USER_REQUESTS_DATE:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          date: {
            ...state.userRequests.date,
            from: action.payload.from,
            to: action.payload.to
          }
        }
      };
    case UPDATE_USER_REQUESTS_PROVIDERS:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          providers: action.payload.providers
        }
      };
    case UPDATE_USER_REQUESTS_CATEGORIES:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          categories: action.payload.categories
        }
      };
    case UPDATE_USER_REQUESTS_TYPES:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          types: action.payload.types
        }
      };
    case UPDATE_USER_REQUESTS_CURRENCIES:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          currencies: action.payload.currencies
        }
      };
    case UPDATE_USER_REQUESTS_ORDER_BY:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          orderBy: action.payload.orderBy
        }
      };
    case SYNC_USER_REQUESTS:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          syncing: action.payload.status
        }
      };
    case ADD_USER_REQUESTS_EXPORTING:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          exporting: [
            ...state.userRequests.exporting,
            ...action.payload.userRequests
          ]
        }
      };
    case REMOVE_USER_REQUESTS_EXPORTING:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          exporting: state.userRequests.exporting.filter(
            userRequest => action.payload.ids.indexOf(userRequest.id) === -1
          )
        }
      };
    case EMPTY_USER_REQUESTS_EXPORTING:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          exporting: []
        }
      };
    case ADD_USER_REQUEST_EXPORTING:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          exporting: [
            ...state.userRequests.exporting,
            action.payload.userRequest
          ]
        }
      };
    case REMOVE_USER_REQUEST_EXPORTING:
      return {
        ...state,
        userRequests: {
          ...state.userRequests,
          exporting: state.userRequests.exporting.filter(
            userRequest => userRequest.id !== action.payload.id
          )
        }
      };
    case GET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          loaded: true,
          pagination: {
            ...state.options.pagination,
            pages: Math.ceil(
              action.payload.meta.total / state.options.pagination.size
            )
          }
        }
      };
    case INIT_OPTIONS_PAGINATION:
      return {
        ...state,
        options: {
          ...state.options,
          pagination: {
            ...state.options.pagination,
            size: action.payload.size,
            page: 1
          }
        }
      };
    case CHANGE_OPTIONS_PAGE:
      return {
        ...state,
        options: {
          ...state.options,
          pagination: {
            ...state.options.pagination,
            page: action.payload.page
          }
        }
      };
    case UPDATE_QUERY:
      return {
        ...state,
        options: {
          ...state.options,
          query: action.payload.query
        }
      };
    case UPDATE_PROVIDERS:
      return {
        ...state,
        options: {
          ...state.options,
          providers: action.payload.providers
        }
      };
    case UPDATE_CATEGORIES:
      return {
        ...state,
        options: {
          ...state.options,
          categories: action.payload.categories
        }
      };
    case UPDATE_TYPES:
      return {
        ...state,
        options: {
          ...state.options,
          types: action.payload.types
        }
      };
    case SYNC_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          syncing: action.payload.status
        }
      };
    case SET_OPTION:
      return {
        ...state,
        option: {
          ...state.option,
          loaded: true,
          values: {
            ...state.option.values,
            ...action.payload.option
          }
        }
      };
    case UPDATE_OPTION_VALUE:
      return {
        ...state,
        option: {
          ...state.option,
          values: {
            ...state.option.values,
            [action.payload.key]: action.payload.value
          }
        }
      };
    case CREATE_OPTION_DENOMINATION:
      return {
        ...state,
        option: {
          ...state.option,
          values: {
            ...state.option.values,
            denominations: [
              ...state.option.values.denominations,
              action.payload.denomination
            ]
          }
        }
      };
    case DELETE_OPTION_DENOMINATION:
      return {
        ...state,
        option: {
          ...state.option,
          values: {
            ...state.option.values,
            denominations: state.option.values.denominations.filter(
              (denomination, denominationIndex) =>
                denominationIndex !== action.payload.index
            )
          }
        }
      };
    case UPDATE_OPTION_CHANGES_STATUS:
      return {
        ...state,
        option: {
          ...state.option,
          hasChanges: action.payload.status
        }
      };
    case UPDATE_OPTION_ERRORS_STATUS:
      return {
        ...state,
        option: {
          ...state.option,
          hasErrors: action.payload.status
        }
      };
    case RESET_OPTION:
      return {
        ...state,
        option: {
          ...state.option,
          values: {
            ...state.option.values,
            ...action.payload.option
          }
        }
      };
    case SYNC_OPTION:
      return {
        ...state,
        option: {
          ...state.option,
          syncing: action.payload.status
        }
      };
    default:
      return state;
  }
};
