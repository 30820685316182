import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

// redux actions
// core components
// import Moment from "moment";
import {
  createAndUpdateRecord,
  submitCreateRecord,
  submitUpdateRecord
} from "../../../../redux/action/PointsSystem/AdsItemAction";
// import Table from "components/Table/Table.js";
import CardBody from "../../../../components/Card/CardBody.js";
// import ConfirmModal from "../Modals/ConfirmModal";
import ConfirmModal from "../../../Modals/ConfirmModal";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";

import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const CreateAndUpdateAds = props => {
  const [openModal, setOpenModal] = React.useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);
  const [messageErr, setMessageErr] = useState("");
  const [errorCompensation, setErrorCompensation] = useState(false);
  const [errorEstimated, setErrorEstimated] = useState(false);

  const classes = useStyles();

  const {
    location,
    createAndUpdateRecord,
    adsRecord,
    submitCreateRecord,
    submitUpdateRecord,
    checkRequiredFields,
    shouldSubmit,
    availableError
  } = props;

  useEffect(() => {
    if (location.state.viewType === "Edit") {
      createAndUpdateRecord(location.state.item);
    }
  }, [location.state.viewType]);

  useEffect(() => {
    if (adsRecord.compensationPercentage && adsRecord.estimatedValue) {
      checkRequiredFields(true);
    } else {
      checkRequiredFields(false);
    }
  }, [adsRecord]);

  const handleChangeEstimated = event => {
    if (event.target.value < 0) {
      setErrorEstimated(true);
    } else {
      setErrorEstimated(false);
      setMessageErr("");
      createAndUpdateRecord({
        ...adsRecord,
        [event.target.name]: event.target.value
      });
    }
  };
  const handleChangeCompensation = event => {
    if (event.target.value > 1) {
      setErrorCompensation(true);
    } else {
      setErrorCompensation(false);
      setMessageErr("");
      createAndUpdateRecord({
        ...adsRecord,
        [event.target.name]: event.target.value
      });
    }
  };
  const handleSubmit = () => {
    if (!shouldSubmit) {
      setMessageErr("Fields is required");
      setOpenModal(false);
    } else {
      if (location.state.viewType !== "Edit") {
        submitCreateRecord(location.state.adsItem.id, adsRecord)
          .then(() => {
            setRedirect(true);
          })
          .catch(err => {
            setOpenModal(false);
            if (err.response.status === 400) {
              setMessageErr(err.response.data.errors[0].error);
            } else {
              setError(err.response.status);
            }
          });
      } else {
        submitUpdateRecord(location.state.adsItem.id, adsRecord)
          .then(() => {
            handleClose();
            setRedirect(true);
          })
          .catch(err => {
            if (err.response.status === 400) {
              setMessageErr(err.response.data.errors[0].error);
            } else {
              setError(err.response.status);
            }
          });
      }
    }
  };

  const handleError = () => {
    switch (error) {
      case 404:
        return "Ads Not found";
      case 401:
        return "Your action is Unauthorized";
      case 403:
        return "You have no permission for this action";
      default:
        return;
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  if (availableError) {
    throw new Error();
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Ads </h4>
          </CardHeader>
          <CardBody>
            <Grid>
              <TextField
                id="estimated-reward"
                name="estimatedValue"
                label="Estimated Reward"
                value={adsRecord.estimatedValue}
                className={classes.textField}
                onChange={handleChangeEstimated}
                margin="normal"
                variant="outlined"
                type="number"
                required
                error={errorEstimated}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  readOnly: location.state.viewType === "Edit"
                }}
              />
              {location.state.viewType === "Edit" && (
                <TextField
                  id="actual-reward"
                  name="actualValue"
                  label="Actual Reward"
                  className={classes.textField}
                  value={adsRecord.actualValue}
                  onChange={handleChangeEstimated}
                  margin="normal"
                  variant="outlined"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              <TextField
                id="compensation-percent"
                name="compensationPercentage"
                label="compensation Percent"
                className={classes.textField}
                value={adsRecord.compensationPercentage}
                onChange={handleChangeCompensation}
                helperText="Enter percentage number between 0 and 1"
                margin="normal"
                variant="outlined"
                type="number"
                error={errorCompensation}
                required
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  min: 0,
                  max: 1
                }}
              />
            </Grid>
            {location.state.viewType !== "Edit" ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                style={{
                  width: "10%",
                  margin: "16px"
                }}
                onClick={handleSubmit}
              >
                Add
              </Button>
            ) : (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                style={{
                  width: "10%",
                  margin: "16px"
                }}
                onClick={handleOpen}
              >
                save
              </Button>
            )}
            <>
              {(error || messageErr) && (
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="error"
                  align="center"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  {messageErr || handleError()}
                </Typography>
              )}
            </>
          </CardBody>
        </Card>
      </GridItem>
      {redirect ? (
        <Redirect
          to={{
            pathname: `/admin/PointsSystem/adsSystem/${location.state.adsItem.id}`,
            state: location.state.adsItem
          }}
        />
      ) : null}
      {openModal && (
        <ConfirmModal
          open={openModal}
          handleEdit={handleSubmit}
          onClose={handleClose}
          type="edit"
        />
      )}
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    adsRecord: state.AdsRecord,
    shouldSubmit: state.shouldSubmit.shouldSubmit,
    availableError: state.availableError.availableError
  };
};

export default connect(
  mapStateToProps,
  {
    createAndUpdateRecord,
    submitCreateRecord,
    submitUpdateRecord,
    checkRequiredFields
  }
)(CreateAndUpdateAds);

CreateAndUpdateAds.propTypes = {
  location: PropTypes.object,
  adsRecord: PropTypes.object,
  createAndUpdateRecord: PropTypes.func,
  submitCreateRecord: PropTypes.func,
  submitUpdateRecord: PropTypes.func,
  checkRequiredFields: PropTypes.func,
  shouldSubmit: PropTypes.bool,
  availableError: PropTypes.bool
};
