import { GET_BUCKETS_LIST } from "../actionType";
import * as pointsServise from "../../../services/points";

export const fetchBucketsList = () => async dispatch => {
  await pointsServise.getBuckets().then(data => {
    dispatch({
      type: GET_BUCKETS_LIST,
      payload: data
    });
  });
};
