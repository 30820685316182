import { SUBMIT_QUICK_LINK_DATA, EDIT_QUICK_LINK_DATA } from "../actionType";
import authenticatedHttpClientService from "../../../services/authenticated-http-client";
import { toggleErrorMessage } from "../ErrorMessageActions";

export const submitQuickLink = (
  data,
  imageUrl,
  countrySelected
) => async dispatch => {
  const response = await authenticatedHttpClientService
    .post(`${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/links/`, {
      name: {
        en: data.englishName,
        ar: data.arabicName
      },
      order: parseInt(data.order) || null,
      url: data.url,
      isFeatured: data.isFeatured,
      isNative: data.isNative,
      categoryId: data.categoryId,
      iconUrl: imageUrl,
      countryCode: countrySelected
    })
    .then(() => {
      dispatch({
        type: SUBMIT_QUICK_LINK_DATA
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
  return response;
};

export const submitQuickLinkEdits = (
  id,
  editData,
  imageUrl,
  countrySelected
) => async dispatch => {
  const body = {
    name: {
      en: editData.englishName,
      ar: editData.arabicName
    },
    order: parseInt(editData.order) || null,
    url: editData.url,
    isFeatured: editData.isFeatured,
    isNative: editData.isNative,
    categoryId: editData.categoryId,
    countryCode: countrySelected,
    iconUrl: imageUrl,
  };

  // if (imageUrl.includes("data:image")) {
  //   body.icon = imageUrl;
  // } else {
  //   body.iconUrl = imageUrl;
  // }

  const response = await authenticatedHttpClientService
    .put(
      `${process.env.REACT_APP_QUICK_LINKS_BASE_URL}/admin/links/${id}`,
      body
    )
    .then(() => {
      dispatch({
        type: EDIT_QUICK_LINK_DATA
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });

  return response;
};
