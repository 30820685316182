export const targetingType = [
  {
    value: "generic",
    label: "Generic"
  },
  {
    value: "specific",
    label: "Specific"
  }
];

export const cardLocation = [
  {
    value: "for-you",
    label: "For you"
  },
  {
    value: "trending",
    label: "Trending"
  },
  {
    value: "videos",
    label: "Videos"
  }
];
