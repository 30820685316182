export const headCells = [
  {
    id: "titleEn",
    numeric: false,
    disablePadding: false,
    label: "Title (En)",
    minWidth: 140
  },
  {
    id: "merchantAr",
    numeric: false,
    disablePadding: true,
    label: "Title (Ar)",
    minWidth: 140
  },
  {
    id: "dealsValue",
    numeric: false,
    disablePadding: true,
    label: "Deals Value",
    minWidth: 120
  },
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
    minWidth: 170
  },
  {
    id: "descriptionEn",
    numeric: false,
    disablePadding: true,
    label: "Description (En)",
    minWidth: 170
  },
  {
    id: "descriptionAr",
    numeric: false,
    disablePadding: true,
    label: "Description (Ar)",
    minWidth: 170
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: true,
    label: "Start Date",
    minWidth: 170
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: true,
    label: "End Date",
    minWidth: 170
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    minWidth: 140
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    minWidth: 120
  },
  {
    id: "Deal-Activation-Type",
    numeric: false,
    disablePadding: true,
    label: "Deal Activation Type",
    minWidth: 170
  },
  {
    id: "Deal-Activation-Method",
    numeric: false,
    disablePadding: true,
    label: "Deal Activation Method",
    minWidth: 170
  },
  // {
  //   id: "domain",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Domain",
  //   minWidth: 170
  // },
  {
    id: "categoryEn",
    numeric: false,
    disablePadding: true,
    label: "Category (En)",
    minWidth: 170
  },
  {
    id: "categoryAr",
    numeric: false,
    disablePadding: true,
    label: "Category (Ar)",
    minWidth: 170
  },
  {
    id: "countires",
    numeric: false,
    disablePadding: true,
    label: "Countries",
    minWidth: 170
  },

  {
    id: "merchantId",
    numeric: false,
    disablePadding: true,
    label: "Merchant (Id)",
    minWidth: 170
  },
  {
    id: "merchantNameEn",
    numeric: false,
    disablePadding: true,
    label: "Merchant Name (En)",
    minWidth: 170
  },
  {
    id: "merchantNameAr",
    numeric: false,
    disablePadding: true,
    label: "Merchant Name (Ar)",
    minWidth: 170
  },
  {
    id: "targetId",
    numeric: false,
    disablePadding: true,
    label: "Target (Id)",
    minWidth: 170
  },
  {
    id: "targetNameEn",
    numeric: false,
    disablePadding: true,
    label: "Target Name (En)",
    minWidth: 170
  },
  {
    id: "targetNameAr",
    numeric: false,
    disablePadding: true,
    label: "Target Name (Ar)",
    minWidth: 170
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: true,
    label: "Comment",
    minWidth: 165
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
    minWidth: 100
  }
];
