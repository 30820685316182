import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";

import withShield from "../with-shield";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import Loader from "../../../components/loader";
import SectionHead from "../../../components/section-head";
import Pagination from "../../../components/Pagination";

import RedemptionHead from "../../../main/redemption/views/redemption-head";
import RedemptionNavigator from "../../../main/redemption/views/redemption-navigator";

import configurations from "../../../configurations";
import {
  redemptionCatalogueAddPath,
  redemptionCatalogueEditPath
} from "../paths";

import RedemptionActions from "../../../main/redemption/actions";

const label = { inputProps: { "aria-label": "Switch demo" } };

const RedemptionOptionDenomination = connect(
  state => ({
    redemption: state.redemption
  }),
  {
    updateOptionDenominationStatus:
      RedemptionActions.options.option.denominations.denomination.update
  }
)(props => {
  const {
    redemption,
    option,
    denomination,
    updateOptionDenominationStatus
  } = props;
  const [sync, setSync] = useState(false);

  const handleDenominationSwitchChange = e => {
    setSync(true);
    updateOptionDenominationStatus(
      option.id,
      denomination.id,
      !denomination.isActive
    ).then(data => {
      setSync(false);
    });
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row"></TableCell>
      <TableCell></TableCell>
      {redemption.country === "EG" && <TableCell></TableCell>}
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>{denomination.localMoneyValue}</TableCell>
      <TableCell>{denomination.pointsValue}</TableCell>
      <TableCell>
        <Typography
          sx={{
            backgroundColor: "#FFF8E2",
            color: "#DAAC22",
            py: "2px",
            px: "16px",
            borderRadius: 8,
            display: "inline-block",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "9pt"
          }}
        >
          {denomination.pointsValue}pts (
          {option.currency === "USD"
            ? denomination.moneyValue
            : denomination.localMoneyValue}
          {option.currency})
        </Typography>
      </TableCell>
      <TableCell>
        <Switch
          {...label}
          checked={denomination.isActive}
          onChange={handleDenominationSwitchChange}
          disabled={sync}
        />
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RedemptionOption = connect(
  state => ({
    redemption: state.redemption
  }),
  {
    setOption: RedemptionActions.option.set,
    deleteOption: RedemptionActions.options.option.delete
  }
)(props => {
  const { redemption, option, setOption, deleteOption } = props;
  const [expand, setExpand] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [sync, setSync] = useState(false);

  const history = useHistory();

  const handleDeleteClick = e => {
    setConfirmDelete(true);
  };

  const handleConfirmDeleteClose = e => {
    setConfirmDelete(false);
  };

  const handleConfirmDeleteCancel = e => {
    setConfirmDelete(false);
  };

  const handleConfirmDeleteApprove = e => {
    setSync(true);
    deleteOption(option.id);
  };

  const handleEditOptionClick = option => e => {
    setOption(option);
    history.push(
      generatePath(
        [configurations.dashboard.basePath, redemptionCatalogueEditPath].join(
          ""
        ),
        { id: option.id }
      )
    );
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Avatar
            alt={option.titleEn}
            src={option.imageUrl}
            sx={{
              backgroundColor: option.imageBackground,
              textTransform: "uppercase"
            }}
          >
            {option.titleEn
              .split(" ")
              .map(word => word[0])
              .join("")}
          </Avatar>
        </TableCell>
        <TableCell>{option.titleEn}</TableCell>
        {redemption.country === "EG" && <TableCell>{option.titleAr}</TableCell>}
        <TableCell>{option.type}</TableCell>
        <TableCell>{option.provider.name}</TableCell>
        <TableCell>{option.category.nameEn}</TableCell>
        <TableCell>{option.productId}</TableCell>
        <TableCell>{option.currency}</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>
          <Typography
            sx={{
              backgroundColor: "#F2F4F7",
              color: "#344054",
              py: "2px",
              px: "16px",
              borderRadius: 8,
              display: "inline-block",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "9pt"
            }}
          >
            +{option.denominations.length}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExpand(expand => !expand)}
          >
            {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Stack direction="row">
            <IconButton
              aria-label="edit"
              onClick={handleEditOptionClick(option)}
            >
              <EditIcon sx={{ color: "#667085" }} />
            </IconButton>
            <IconButton aria-label="delete" onClick={handleDeleteClick}>
              <DeleteForeverIcon sx={{ color: "#667085" }} />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      {expand &&
        option.denominations.map(denomination => (
          <RedemptionOptionDenomination
            key={denomination.id}
            option={option}
            denomination={denomination}
          />
        ))}
      <Dialog
        open={confirmDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleConfirmDeleteClose}
        aria-describedby={`delete-${option.id}`}
      >
        <DialogTitle>
          <Stack spacing={2}>
            <Box>
              <IconButton
                sx={{
                  backgroundColor: "#FEE4E2",
                  border: "8px solid #FEF3F2",
                  "&:hover": { backgroundColor: "#FEE4E2" }
                }}
              >
                <ErrorOutlineOutlinedIcon color="error" />
              </IconButton>
            </Box>
            <Typography>Are you sure?</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={`delete-${option.id}`}>
            You are about to delete this redemption option
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            spacing={2}
            justifyContent="stretch"
          >
            <Button
              onClick={handleConfirmDeleteCancel}
              variant="outlined"
              sx={{ width: "100%" }}
              disabled={sync}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDeleteApprove}
              variant="contained"
              color="error"
              sx={{ width: "100%" }}
              disabled={sync}
            >
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
});

const Catalogue = props => {
  const {
    redemption,
    getConfigurations,
    getOptions,
    initOptionsPagination,
    changeOptionsPage,
    setOption,
    updateQuery,
    updateProviders,
    updateCategories,
    updateTypes,
    syncOptions
  } = props;

  const history = useHistory();

  const paginationPage = redemption.layout.options.pagination.page;
  const paginationSize = redemption.layout.options.pagination.size;
  const paginationPages = paginationSize
    ? Math.ceil(redemption.options.meta.total / paginationSize)
    : 0;
  const optionsQuery = redemption.layout.options.query;
  const optionsProviders = redemption.layout.options.providers;
  const optionsCategories = redemption.layout.options.categories;
  const optionsTypes = redemption.layout.options.types;
  const optionsSyncing = redemption.layout.options.syncing;

  const syncingOptions = () => {
    syncOptions(true);
    const costConstraints =
      configurations.dashboard.redemption.option.denomination.constraints;
    getOptions(
      redemption.country,
      paginationPage,
      paginationSize,
      optionsQuery,
      optionsQuery,
      isNaN(Number(optionsQuery)) ||
        !Number.isInteger(Number(optionsQuery) || !optionsQuery)
        ? ""
        : Number(optionsQuery) >= costConstraints.min &&
          Number(optionsQuery) <= costConstraints.max
        ? Number(optionsQuery)
        : "",
      optionsProviders[0] || "",
      optionsCategories[0] || "",
      optionsTypes[0] || ""
    ).then(data => {
      syncOptions(false);
    });
  };

  const handleAddOptionClick = e => {
    setOption({});
    history.push(
      [configurations.dashboard.basePath, redemptionCatalogueAddPath].join("")
    );
  };

  const handlePageClick = (e, page) => {
    changeOptionsPage(page);
  };

  const handleQueryChange = e => {
    updateQuery(e.target.value);
  };

  const handleProviderChange = e => {
    updateProviders(
      optionsProviders.length
        ? e.target.value.find(value => !value) !== undefined
          ? []
          : e.target.value.length
          ? [e.target.value[e.target.value.length - 1]]
          : []
        : e.target.value.filter(value => value)
    );
  };

  const handleCategoryChange = e => {
    updateCategories(
      optionsCategories.length
        ? e.target.value.find(value => !value) !== undefined
          ? []
          : e.target.value.length
          ? [e.target.value[e.target.value.length - 1]]
          : []
        : e.target.value.filter(value => value)
    );
  };

  const handleTypesChange = e => {
    updateTypes(
      optionsTypes.length
        ? e.target.value.find(value => !value) !== undefined
          ? []
          : e.target.value.length
          ? [e.target.value[e.target.value.length - 1]]
          : []
        : e.target.value.filter(value => value)
    );
  };

  useEffect(() => {
    initOptionsPagination(10);
    getConfigurations(redemption.country);
    return () => {
      changeOptionsPage(0);
      updateQuery("");
      updateProviders([]);
      updateCategories([]);
      updateTypes([]);
    };
  }, []);

  useEffect(() => {
    if (paginationPage && paginationSize) syncingOptions();
  }, [paginationPage]);

  useEffect(() => {
    if (paginationPage === 1) {
      syncingOptions();
    } else {
      changeOptionsPage(1);
    }
  }, [optionsQuery, optionsProviders, optionsCategories, optionsTypes]);

  useEffect(() => {
    if (paginationPage > paginationPages) changeOptionsPage(paginationPages);
  }, [redemption.options.meta.total]);

  return (
    <Stack spacing={2}>
      <RedemptionHead />
      <RedemptionNavigator value="catalogue" />
      <Stack
        spacing={4}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SectionHead
          title="Catalogue"
          description="View and update the full catalogue Wasla users access"
        />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ textTransform: "capitalize" }}
          onClick={handleAddOptionClick}
        >
          Add Redemption
        </Button>
      </Stack>
      <Divider />
      <Stack
        spacing={4}
        direction="row"
        justifyContent="space-between"
        sx={{ backgroundColor: "#F9FAFB", py: "12Px", px: 2, borderRadius: 4 }}
      >
        <TextField
          id="input-with-icon-textfield"
          label=""
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          sx={{ minWidth: "360px", backgroundColor: "white" }}
          value={optionsQuery}
          placeholder="Search by name, product ID, or points"
          onChange={handleQueryChange}
        />
        <Stack spacing={1} direction="row">
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              displayEmpty
              multiple
              value={optionsProviders.length ? optionsProviders : [""]}
              onChange={handleProviderChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={""} sx={{ display: "none" }}>
                <em>Select Provider</em>
              </MenuItem>
              {redemption.configurations.providers.map(provider => (
                <MenuItem key={provider.id} value={provider.id}>
                  {provider.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              displayEmpty
              multiple
              value={optionsCategories.length ? optionsCategories : [""]}
              onChange={handleCategoryChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={""} sx={{ display: "none" }}>
                <em>Select Category</em>
              </MenuItem>
              {redemption.configurations.categories.map(category => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              displayEmpty
              multiple
              value={optionsTypes.length ? optionsTypes : [""]}
              onChange={handleTypesChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={""} sx={{ display: "none" }}>
                <em>Select Type</em>
              </MenuItem>
              {redemption.configurations.types.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {redemption.layout.options.loaded ? (
        <Card variant="outlined">
          <CardHeader title="Catalogue" />
          <Divider />
          <CardContent sx={{ p: 0 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Logo</TableCell>
                    <TableCell>Name (EN)</TableCell>
                    {redemption.country === "EG" && (
                      <TableCell>Name (AR)</TableCell>
                    )}
                    <TableCell>Type</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Currency</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Pts Cost</TableCell>
                    <TableCell>Denominations</TableCell>
                    <TableCell>Preview</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {optionsSyncing ? (
                    <TableRow>
                      <TableCell
                        component="td"
                        scope="row"
                        colSpan={redemption.country === "EG" ? 13 : 12}
                      >
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {redemption.options.data &&
                      redemption.options.data.length ? (
                        redemption.options.data.map(option => {
                          return (
                            <RedemptionOption key={option.id} option={option} />
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            colSpan={redemption.country === "EG" ? 13 : 12}
                            sx={{ p: 4, textAlign: "center" }}
                          >
                            {optionsQuery ||
                            optionsProviders.length ||
                            optionsCategories.length ||
                            optionsTypes.length ? (
                              <>
                                No Redemption options!
                                <br />
                                Please check you search and filters.
                              </>
                            ) : (
                              "No Redemption options added yet!"
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardActions>
            <Pagination
              page={paginationPage}
              limit={paginationSize}
              total={redemption.options.meta.total}
              rowsPerPageOptions={[]}
              handlePageClick={handlePageClick}
              sx={{ width: "100%" }}
            />
          </CardActions>
        </Card>
      ) : (
        <Loader />
      )}
    </Stack>
  );
};

export default withShield(
  connect(
    state => ({ redemption: state.redemption }),
    {
      getConfigurations: RedemptionActions.configurations.get,
      getOptions: RedemptionActions.options.get,
      initOptionsPagination: RedemptionActions.layout.options.pagination.init,
      changeOptionsPage: RedemptionActions.layout.options.pagination.change,
      setOption: RedemptionActions.option.set,
      updateQuery: RedemptionActions.layout.options.query.update,
      updateProviders: RedemptionActions.layout.options.providers.update,
      updateCategories: RedemptionActions.layout.options.categories.update,
      updateTypes: RedemptionActions.layout.options.types.update,
      syncOptions: RedemptionActions.layout.options.sync
    }
  )(Catalogue)
);
