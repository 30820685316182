import React from "react";

import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Pagination = ({
  page,
  limit,
  total,
  rowsPerPageOptions,
  boundaryCount,
  siblingCount,
  handlePageClick,
  handleLimitChange,
  ...props
}) => {
  const lastPage = Math.ceil(total / limit);
  const minSiblingPage = Math.max(1, page - siblingCount, boundaryCount + 1);
  const maxSiblingPage = Math.min(
    lastPage,
    page + siblingCount,
    lastPage - boundaryCount
  );
  return (
    <Stack
      spacing={8}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      {...props}
    >
      <Button
        variant="pagination"
        className="action"
        disabled={page <= 1}
        onClick={e => handlePageClick(e, page - 1)}
        startIcon={<ArrowBackIcon />}
        color="neutral"
      >
        Previous
      </Button>
      <Stack
        spacing={16}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {rowsPerPageOptions.length ? (
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#667085"
              }}
            >
              Items Per Page
            </Typography>
            <FormControl>
              <Select
                value={limit}
                onChange={handleLimitChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="pagination"
              >
                {rowsPerPageOptions.map(rowsPerPageOption => (
                  <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                    {rowsPerPageOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        ) : (
          <></>
        )}
        <Stack spacing={0.5} direction="row">
          {lastPage > boundaryCount * 2 + siblingCount * 2 + 1 ? (
            <>
              {Array(Math.min(boundaryCount))
                .fill(0)
                .map((item, itemIndex) => itemIndex + 1)
                .map(number => (
                  <Button
                    key={number}
                    variant="pagination"
                    color="neutral"
                    className={page === number ? "active" : ""}
                    onClick={e => handlePageClick(e, number)}
                  >
                    {number}
                  </Button>
                ))}
              {minSiblingPage > boundaryCount + 1 ? (
                <Button variant="pagination" color="neutral">
                  ...
                </Button>
              ) : (
                <></>
              )}
              {Array(Math.max(0, maxSiblingPage - minSiblingPage + 1))
                .fill(0)
                .map((item, itemIndex) => minSiblingPage + itemIndex)
                .map(number => (
                  <Button
                    key={number}
                    variant="pagination"
                    color="neutral"
                    className={page === number ? "active" : ""}
                    onClick={e => handlePageClick(e, number)}
                  >
                    {number}
                  </Button>
                ))}
              {maxSiblingPage < lastPage - boundaryCount ? (
                <Button variant="pagination" color="neutral">
                  ...
                </Button>
              ) : (
                <></>
              )}
              {Array(boundaryCount)
                .fill(0)
                .map(
                  (item, itemIndex) => lastPage - boundaryCount + itemIndex + 1
                )
                .map(number => (
                  <Button
                    key={number}
                    variant="pagination"
                    color="neutral"
                    className={page === number ? "active" : ""}
                    onClick={e => handlePageClick(e, number)}
                  >
                    {number}
                  </Button>
                ))}
            </>
          ) : (
            <>
              {Array(lastPage)
                .fill(0)
                .map((item, itemIndex) => itemIndex + 1)
                .map(number => (
                  <Button
                    key={number}
                    variant="pagination"
                    color="neutral"
                    className={page === number ? "active" : ""}
                    onClick={e => handlePageClick(e, number)}
                  >
                    {number}
                  </Button>
                ))}
            </>
          )}
        </Stack>
      </Stack>
      <Button
        variant="pagination"
        className="action"
        disabled={page >= lastPage}
        onClick={e => handlePageClick(e, page + 1)}
        endIcon={<ArrowForwardIcon />}
        color="neutral"
      >
        Next
      </Button>
    </Stack>
  );
};

Pagination.defaultProps = {
  rowsPerPageOptions: [10, 25, 50, 100],
  boundaryCount: 3,
  siblingCount: 1
};

export default Pagination;
