import { GET_CONFIGURATIONS } from "../../actions/configuartions";

const initialState = {
  providers: [],
  categories: [],
  types: [],
  inputs: [],
  usdRate: 1,
  pointsRate: 1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIGURATIONS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
