import Merchants from "./Merchants";
import { dealsMerchantsPath } from "./paths";
import addNEditConfig from "./add-n-edit/addNEditConfig";

export default {
  routes: [
    {
      path: dealsMerchantsPath,
      component: Merchants,
      layout: "main",
      isPrivate: false,
      roles: ["admin"],
      routes: [...addNEditConfig.routes]
    }
  ]
};
