import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";

const headCells = [
  {
    id: "modifiedBy",
    numeric: false,
    disablePadding: true,
    label: "Modified By",
    paddingLeft: 5
  },
  {
    id: "modifiedAt",
    numeric: false,
    disablePadding: true,
    label: "Modified At",
    paddingLeft: 5
  },
  {
    id: "oldValue",
    numeric: false,
    disablePadding: true,
    label: "Old Rate",
    paddingLeft: 0
  },
  {
    id: "newValue",
    numeric: false,
    disablePadding: true,
    label: "New Rate",
    paddingLeft: 0
  }
];

const TableHeader = React.memo(props => {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              paddingLeft: headCell?.paddingLeft,
              textAlign: headCell?.textAlign
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

TableHeader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default TableHeader;
