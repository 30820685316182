import {
  CALCULATE_POINTS_BUDGET,
  CALCULATE_CASH_BUDGET,
  RESET_STATE
} from "./actionType";

export const calculatePoints = points => {
  return {
    type: CALCULATE_POINTS_BUDGET,
    payload: points || 0
  };
};

export const calculateCash = cash => {
  return {
    type: CALCULATE_CASH_BUDGET,
    payload: cash || 0
  };
};

export const clearCashPointsState = () => {
  return {
    type: RESET_STATE
  };
};
