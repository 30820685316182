import httpClientService from "../http-client";
import authenticatedHttpClientService from "../authenticated-http-client";

export const getDeleteRequest = id =>
  httpClientService.get(
    `${process.env.REACT_APP_USER_PROFILE_BASE_URL}/profiles/delete-requests/${id}`
  );

export const getFacebookDataDeleteStatus = id =>
  httpClientService.get(
    `${process.env.REACT_APP_USER_PROFILE_BASE_URL}/privacy/facebook/deletion/${id}`
  );

export const getUsers = (query, page, limit) =>
  authenticatedHttpClientService
    .get(
      `${process.env.REACT_APP_USER_PROFILE_BASE_URL}/admin/profiles?q=${query}&page=${page}&limit=${limit}`
    )
    .then(response => response.data);

export const blockUser = id =>
  authenticatedHttpClientService
    .patch(
      `${process.env.REACT_APP_USER_PROFILE_BASE_URL}/admin/profiles/blockingstatus`,
      JSON.stringify({
        id,
        shouldBlock: true
      }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);

export const unblockUser = id =>
  authenticatedHttpClientService
    .patch(
      `${process.env.REACT_APP_USER_PROFILE_BASE_URL}/admin/profiles/blockingstatus`,
      JSON.stringify({
        id,
        shouldBlock: false
      }),
      {
        headers: {
          "content-type": "application/json"
        }
      }
    )
    .then(response => response.data);
