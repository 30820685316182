import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as dealsService from "../../../services/deals";

import { updateCountryCode } from "../../../main/deals/deals/actions/filtration";
import { updateItems } from "../../../main/deals/deals/actions/list";
import {
  updatePage,
  updateLimit,
  updateTotal
} from "../../../main/deals/deals/actions/pagination";

import Stack from "@mui/material/Stack";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Paper from "@mui/material/Paper";

import TableContainer from "@mui/material/TableContainer";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";

import Link from "@mui/material/Link";

import Typography from "@mui/material/Typography";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Loader from "../../../components/loader";

import Pagination from "../../../components/Pagination";

import WaslaTheme from "../../../modules/wasla-theme";

import configurations from "../../../configurations";

import egypt from "./assets/egypt.png";
import nigeria from "./assets/nigeria.png";

const DealRecord = props => {
  const { item } = props;

  const startDate = new Date(item.startDate);
  const endDate = new Date(item.endDate);

  return (
    <TableRow>
      <TableCell>{item.titleEn}</TableCell>
      <TableCell>{item.titleAr}</TableCell>
      <TableCell>{item.id}</TableCell>
      <TableCell>{item.descriptionEn}</TableCell>
      <TableCell>{item.descriptionAr}</TableCell>
      <TableCell>{`${startDate.getDate()} ${startDate.toLocaleString(
        "default",
        {
          month: "short"
        }
      )} ${startDate.getFullYear()} at ${String(
        startDate.getHours() % 12
      ).padStart(2, "0")}:${String(startDate.getMinutes()).padStart(2, "0")} ${
        startDate.getHours() < 12 ? "AM" : "PM"
      }`}</TableCell>
      <TableCell>{`${endDate.getDate()} ${endDate.toLocaleString("default", {
        month: "short"
      })} ${endDate.getFullYear()} at ${String(
        endDate.getHours() % 12
      ).padStart(2, "0")}:${String(endDate.getMinutes()).padStart(2, "0")} ${
        endDate.getHours() < 12 ? "AM" : "PM"
      }`}</TableCell>
      <TableCell>
        <Typography
          sx={{
            ...(status => {
              switch (status) {
                case false:
                  return { backgroundColor: "#EBFFE5", color: "#3D9530" };
                case true:
                  return { backgroundColor: "#FEF3F2", color: "#B42318" };
                default:
                  return { backgroundColor: "#F2F4F7", color: "#344054" };
              }
            })(item.isExpired),
            py: "2px",
            px: "8px",
            borderRadius: "16px",
            width: "max-content",
            display: "flex",
            gap: "6px",
            alignItems: "center",
            textAlign: "center",
            textTransform: "capitalize",
            fontFamily: "Inter",
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "18px",
            "&::before": {
              content: "''",
              display: "inline-block",
              width: 6,
              height: 6,
              borderRadius: "50%",
              ...(status => {
                switch (status) {
                  case false:
                    return { backgroundColor: "#3D9530" };
                  case true:
                    return { backgroundColor: "#F04438" };
                  default:
                    return { backgroundColor: "#667085" };
                }
              })(item.isExpired)
            }
          }}
        >
          {item.isExpired ? "Expired" : "On"}
        </Typography>
      </TableCell>
      <TableCell sx={{ fontWeight: 500, color: "#101828" }}>
        {item.type}
      </TableCell>
      <TableCell
        sx={{ fontWeight: 500, color: "#101828" }}
        dangerouslySetInnerHTML={{ __html: item.activationValue }}
      ></TableCell>
      <TableCell sx={{ fontWeight: 500, color: "#101828" }}>
        {item.usageMethod}
      </TableCell>
      <TableCell>{item.category?.nameEn}</TableCell>
      <TableCell>{item.category?.nameAr}</TableCell>
      <TableCell>
        {item.countries
          .split(configurations.dashboard.deals.countriesDelimiter)
          .join(", ")}
      </TableCell>
      <TableCell>{item.merchant.id}</TableCell>
      <TableCell>{item.merchant?.nameEn}</TableCell>
      <TableCell>{item.merchant?.nameAr}</TableCell>
    </TableRow>
  );
};

const Deals = props => {
  const {
    deals: {
      filtration: { countryCode },
      list: { items },
      pagination: { page, limit, total }
    },
    updateCountryCode,
    updateItems,
    updatePage,
    updateLimit,
    updateTotal
  } = props;

  const [loading, setLoading] = useState(true);

  const retrieveDeals = async (countryCode, page, limit) => {
    setLoading(true);
    const res = await dealsService.getDeals(countryCode, page, limit);
    updateItems(res.data);
    updateTotal(res.meta.total);
    setLoading(false);
  };

  useEffect(() => {
    retrieveDeals(countryCode, page, limit);
  }, [page]);

  useEffect(() => {
    if (page == 1) {
      retrieveDeals(countryCode, page, limit);
    } else {
      updatePage(1);
    }
  }, [countryCode, limit]);

  const handlePageClick = (e, page) => {
    updatePage(page);
  };

  const handleLimitChange = e => {
    updateLimit(parseInt(e.target.value, 10));
  };

  const getFlag = countryCode => {
    switch (countryCode) {
      case "EG":
        return egypt;
      case "NG":
        return nigeria;
      default:
        return null;
    }
  };

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, "/Deals&Merchant"].join(
              ""
            )}
          >
            Deals
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            Deals Table
          </Typography>
        </Breadcrumbs>
        <Stack
          spacing={4}
          direction="row"
          justifyContent="end"
          sx={{
            backgroundColor: "#F9FAFB",
            py: "12Px",
            px: 2,
            borderRadius: 3
          }}
        >
          <FormControl>
            <Select
              value={countryCode}
              onChange={e => updateCountryCode(e.target.value)}
              displayEmpty
              id="country-code-dropdown"
              inputProps={{
                id: "country-code"
              }}
              MenuProps={{
                id: "country-code-menu"
              }}
              disabled={configurations.application.countries.length === 1}
            >
              {configurations.application.countries.map(country => (
                <MenuItem key={country.code} value={country.code}>
                  <Stack spacing={1} direction="row">
                    <img src={getFlag(country.code)} />
                    <span>{country.name}</span>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={17}>
                    <Typography variant="inherit">All Deals</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">Title (En)</TableCell>
                  <TableCell component="th">Title (Ar)</TableCell>
                  <TableCell component="th">ID</TableCell>
                  <TableCell component="th">Description (En)</TableCell>
                  <TableCell component="th">Description (Ar)</TableCell>
                  <TableCell component="th">Start Date</TableCell>
                  <TableCell component="th">End Date</TableCell>
                  <TableCell component="th">Status</TableCell>
                  <TableCell component="th">Type</TableCell>
                  <TableCell component="th">Deal Activation Value</TableCell>
                  <TableCell component="th">Deal Activation Method</TableCell>
                  <TableCell component="th">Category (En)</TableCell>
                  <TableCell component="th">Category (Ar)</TableCell>
                  <TableCell component="th">Country</TableCell>
                  <TableCell component="th">Merchant ID</TableCell>
                  <TableCell component="th">Merchant Name (EN)</TableCell>
                  <TableCell component="th">Merchant Name (Ar)</TableCell>
                </TableRow>
                {items.map(item => (
                  <DealRecord key={item.id} item={item} />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={17}>
                    <Pagination
                      page={page}
                      limit={limit}
                      total={total}
                      handlePageClick={handlePageClick}
                      handleLimitChange={handleLimitChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </WaslaTheme>
  );
};

export default connect(
  state => ({ deals: state.deals }),
  { updateCountryCode, updateItems, updatePage, updateLimit, updateTotal }
)(Deals);
