import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
// redux actions
import { setPWAType } from "../../../../redux/action/AnnouncementCards/CTAButtonActions";
// core components
import styles from "../../styles.css";
import { pwaType } from "./CallToActionConfig";

const useStyles = makeStyles(styles);

const PwaComponent = ({ announcementCards, setPWAType }) => {
  const classes = useStyles();

  const handleSelection = event => {
    setPWAType(event.target.value);
  };

  return (
    <Grid className={classes.container}>
      <TextField
        id="pwa-type"
        select
        label="PWA Type"
        className={classes.textField}
        name="pwaType"
        value={announcementCards.pwaType}
        onChange={handleSelection}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="outlined"
        required
      >
        <option value="none">Select an Option</option>
        {pwaType.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    announcementCards: state.announcementCards
  };
};

export default connect(
  mapStateToProps,
  { setPWAType }
)(PwaComponent);

PwaComponent.propTypes = {
  announcementCards: PropTypes.object,
  setPWAType: PropTypes.func
};
