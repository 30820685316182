export const INIT_USER_REQUESTS_PAGINATION = "init user requests pagination";
export const CHANGE_USER_REQUESTS_PAGE = "change user requests page";
export const UPDATE_USER_REQUESTS_QUERY = "update user requests query";
export const UPDATE_USER_REQUESTS_DATE = "update user requests date";
export const UPDATE_USER_REQUESTS_PROVIDERS = "update user requests providers";
export const UPDATE_USER_REQUESTS_CATEGORIES =
  "update user requests categories";
export const UPDATE_USER_REQUESTS_TYPES = "update user requests types";
export const UPDATE_USER_REQUESTS_CURRENCIES =
  "update user requests currencies";
export const UPDATE_USER_REQUESTS_ORDER_BY = "update user requests order by";
export const SYNC_USER_REQUESTS = "sync user requests";
export const ADD_USER_REQUESTS_EXPORTING = "add user requests exporting";
export const REMOVE_USER_REQUESTS_EXPORTING = "remove user requests exporting";
export const EMPTY_USER_REQUESTS_EXPORTING = "empty user requests exporting";
export const ADD_USER_REQUEST_EXPORTING = "add user request exporting";
export const REMOVE_USER_REQUEST_EXPORTING = "remove user request exporting";
export const INIT_OPTIONS_PAGINATION = "init options pagination";
export const CHANGE_OPTIONS_PAGE = "change options page";
export const UPDATE_QUERY = "update query";
export const UPDATE_PROVIDERS = "update providers";
export const UPDATE_CATEGORIES = "update categories";
export const UPDATE_TYPES = "update types";
export const SYNC_OPTIONS = "sync options";
export const UPDATE_OPTION_VALUE = "update option value";
export const CREATE_OPTION_DENOMINATION = "denomination option denomination";
export const DELETE_OPTION_DENOMINATION = "delete option denomination";
export const UPDATE_OPTION_CHANGES_STATUS = "update option changes status";
export const UPDATE_OPTION_ERRORS_STATUS = "update option errors status";
export const RESET_OPTION = "reset option";
export const SYNC_OPTION = "sync option";

export const initUserRequestsPagination = size => dispatch =>
  dispatch({
    type: INIT_USER_REQUESTS_PAGINATION,
    payload: { size }
  });

export const changeUserRequestsPage = page => dispatch =>
  dispatch({
    type: CHANGE_USER_REQUESTS_PAGE,
    payload: { page }
  });

export const updateUserRequestsQuery = query => dispatch =>
  dispatch({
    type: UPDATE_USER_REQUESTS_QUERY,
    payload: { query }
  });

export const updateUserRequestsDate = (from, to) => dispatch =>
  dispatch({
    type: UPDATE_USER_REQUESTS_DATE,
    payload: { from, to }
  });

export const updateUserRequestsProviders = providers => dispatch =>
  dispatch({
    type: UPDATE_USER_REQUESTS_PROVIDERS,
    payload: { providers }
  });

export const updateUserRequestsCategories = categories => dispatch =>
  dispatch({
    type: UPDATE_USER_REQUESTS_CATEGORIES,
    payload: { categories }
  });

export const updateUserRequestsTypes = types => dispatch =>
  dispatch({
    type: UPDATE_USER_REQUESTS_TYPES,
    payload: { types }
  });

export const updateUserRequestsCurrencies = currencies => dispatch =>
  dispatch({
    type: UPDATE_USER_REQUESTS_CURRENCIES,
    payload: { currencies }
  });

export const updateUserRequestsOrderBy = orderBy => dispatch =>
  dispatch({
    type: UPDATE_USER_REQUESTS_ORDER_BY,
    payload: { orderBy }
  });

export const syncUserRequests = status => dispatch =>
  dispatch({
    type: SYNC_USER_REQUESTS,
    payload: { status }
  });

export const addUserRequestsExporting = userRequests => dispatch =>
  dispatch({
    type: ADD_USER_REQUESTS_EXPORTING,
    payload: { userRequests }
  });

export const removeUserRequestsExporting = ids => dispatch =>
  dispatch({
    type: REMOVE_USER_REQUESTS_EXPORTING,
    payload: { ids }
  });

export const emptyUserRequestsExporting = () => dispatch =>
  dispatch({
    type: EMPTY_USER_REQUESTS_EXPORTING
  });

export const addUserRequestExporting = userRequest => dispatch =>
  dispatch({
    type: ADD_USER_REQUEST_EXPORTING,
    payload: { userRequest }
  });

export const removeUserRequestExporting = id => dispatch =>
  dispatch({
    type: REMOVE_USER_REQUEST_EXPORTING,
    payload: { id }
  });

export const initOptionsPagination = size => dispatch =>
  dispatch({
    type: INIT_OPTIONS_PAGINATION,
    payload: { size }
  });

export const changeOptionsPage = page => dispatch =>
  dispatch({
    type: CHANGE_OPTIONS_PAGE,
    payload: { page }
  });

export const updateQuery = query => dispatch =>
  dispatch({
    type: UPDATE_QUERY,
    payload: { query }
  });

export const updateProviders = providers => dispatch =>
  dispatch({
    type: UPDATE_PROVIDERS,
    payload: { providers }
  });

export const updateCategories = categories => dispatch =>
  dispatch({
    type: UPDATE_CATEGORIES,
    payload: { categories }
  });

export const updateTypes = types => dispatch =>
  dispatch({
    type: UPDATE_TYPES,
    payload: { types }
  });

export const syncOptions = status => dispatch =>
  dispatch({
    type: SYNC_OPTIONS,
    payload: { status }
  });

export const updateOptionValue = (key, value) => dispatch =>
  dispatch({
    type: UPDATE_OPTION_VALUE,
    payload: { key, value }
  });

export const createOptionDenomination = denomination => dispatch =>
  dispatch({
    type: CREATE_OPTION_DENOMINATION,
    payload: { denomination }
  });

export const deleteOptionDenomination = index => dispatch =>
  dispatch({
    type: DELETE_OPTION_DENOMINATION,
    payload: { index }
  });

export const updateOptionChangesStatus = status => dispatch =>
  dispatch({
    type: UPDATE_OPTION_CHANGES_STATUS,
    payload: { status }
  });

export const updateOptionErrorsStatus = status => dispatch =>
  dispatch({
    type: UPDATE_OPTION_ERRORS_STATUS,
    payload: { status }
  });

export const resetOption = option => dispatch =>
  dispatch({
    type: RESET_OPTION,
    payload: { option }
  });

export const syncOption = status => dispatch =>
  dispatch({
    type: SYNC_OPTION,
    payload: { status }
  });
