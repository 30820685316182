import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
// core components
import SelectRechargeValue from "./SelectRechargeValue";
import styles from "../../styles.css";

const useStyles = makeStyles(styles);

function RechargeInfo(props) {
  const classes = useStyles();
  const {
    selectedRechargeValue,
    checkRequiredFields,
    selectedUsers,
    currentBalance
  } = props;
  const [error, setError] = useState(false);
  const transactionValue = selectedUsers.length * selectedRechargeValue;

  useEffect(() => {
    if (selectedRechargeValue > 0) {
      checkRequiredFields(true);
    } else {
      checkRequiredFields(false);
    }
  }, [selectedRechargeValue]);

  useEffect(() => {
    if (transactionValue > currentBalance) {
      setError(true);
      checkRequiredFields(false);
    } else {
      setError(false);
    }
  }, [transactionValue, currentBalance]);

  let transactionNetValue = (transactionValue / 1.4285).toFixed(2);

  return (
    <div className={classes.container}>
      <TextField
        id="number-of-selected-users"
        name="selectedUsers"
        label="Number of users selected"
        type="number"
        className={classes.textField}
        value={selectedUsers.length}
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          readOnly: true
        }}
      />
      <SelectRechargeValue />
      <div className={classes.textFieldsContainer}>
        <TextField
          id="Gross Value (Amount Paid)"
          name="grossValue"
          label="Gross Value (Amount Paid)"
          className={classes.textField}
          value={`${transactionValue.toLocaleString()} EGP`}
          margin="normal"
          variant="outlined"
          error={error}
          helperText={error && "* Balance not enough!"}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          id="Net Value (Amount send)"
          name="netValue"
          label="Net Value (Amount send)"
          className={classes.textField}
          value={`${transactionNetValue.toLocaleString()} EGP`}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedRechargeValue: state.rechargeUsers.selectedRechargeValue,
  selectedUsers: state.waslaRewards.selectedUsers,
  currentBalance: state.balanceList.balanceList.currentBalance
});

export default connect(
  mapStateToProps,
  {
    checkRequiredFields
  }
)(RechargeInfo);

RechargeInfo.propTypes = {
  selectedRechargeValue: PropTypes.number,
  checkRequiredFields: PropTypes.func,
  selectedUsers: PropTypes.array,
  currentBalance: PropTypes.number
};
