import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// redux actions
import {
  setUserMobileNumber,
  addTransferPoints,
  restTransferRequestResult
} from "../../../redux/action/PointsSystem/TransferPointsActions";
import { openSnackBar } from "../../../redux/action/SnackBarAction";
import { fetchAllReasons, addSelectedReason } from "../../../redux/action/PointsSystem/reasonsAction";
// core components
import AmountOfPoints from "../AmountOfPoints";
import styles from "../styles.css";
import SnackBar from "../../WaslaRewards/SnackBar/SnackBar";
import FileUpload from "../../FileUpload";
import ConfirmModal from "../../Modals/ConfirmModal";

const useStyles = makeStyles(styles);

const TransferPoints = ({
  pointsSystem,
  setUserMobileNumber,
  addTransferPoints,
  transferError,
  transferSuccess,
  availableError,
  fileAsArray,
  showCsPage,
  openSnackBar,
  restTransferRequestResult,
  fetchAllReasons,
  reasons,
  addSelectedReason,
  selectedReason,
}) => {
  const classes = useStyles();
  const [messageErr, setMessageErr] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [warning, setWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorAmount, setErrorAmount] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleMobileChange = event => {
    setUserMobileNumber([event.target.value]);
  };
  const checkFields = () => {
    const nonEmpty = pointsSystem.userMobileNumber.indexOf("") === -1;

    setErrorAmount(false);
    if (
      !pointsSystem.amountOfPoints ||
      ((pointsSystem.userMobileNumber?.length === 0 || !nonEmpty) &&
        pointsSystem.typeOfTransfer === "Yes")
    ) {
      setWarning(true);
      openSnackBar();
      return true;
    }
    if (
      !pointsSystem.amountOfPoints ||
      (fileAsArray.length === 0 && pointsSystem.typeOfTransfer === "No")
    ) {
      setWarning(true);
      openSnackBar();
      return true;
    }

    return false;
  };
  const checkAmount = () => {
    if (
      pointsSystem.amountOfPoints > pointsSystem.csAllPointsBonus &&
      pointsSystem.typeOfPointsAction === "Give Points"
    ) {
      setErrorAmount(true);
      openSnackBar();
      return true;
    }
    return false;
  };
  const onSubmitHandler = () => {
    restTransferRequestResult();
    if (pointsSystem.amountOfPoints) {
      let numbers = /[^0-9]/;
      const onlyNums = numbers.test(pointsSystem.amountOfPoints);
      setErrorMessage(onlyNums);
      openSnackBar();
      if (onlyNums) {
        setWarning(false);
        return;
      }
    }
    if (checkFields()) return;
    if (checkAmount()) return;
    setWarning(false);
    setErrorAmount(false);
    setMessageErr("");

    if (pointsSystem.typeOfTransfer === "Yes") {
      addTransferPoints(
        pointsSystem.amountOfPoints,
        pointsSystem.userMobileNumber,
        selectedReason,
        pointsSystem.typeOfPointsAction
      );
    } else {
      addTransferPoints(
        pointsSystem.amountOfPoints,
        fileAsArray,
        selectedReason,
        pointsSystem.typeOfPointsAction
      );
    }
  };

  useEffect(() => {
    switch (transferError) {
      case 500:
        setMessageErr("Server error");
        break;
      case 400:
        setMessageErr("Insufficient bonus bucket points");
        break;
      case 404:
        setMessageErr("User Id number not found");
        break;
      case 401:
        setMessageErr("Your action is Unauthorized");
        break;
      case 403:
        setMessageErr("You have no permission for this action");
        break;
      default:
        break;
    }
  }, [transferSuccess, transferError]);

  if (availableError) {
    throw new Error();
  }
  useEffect(() => {
    if (warning) {
      setMessageErr("This field is required");
    }
  }, [warning]);
  useEffect(() => {
    if (errorAmount) {
      setMessageErr("The available points balance isn`t enough");
    }
  }, [errorAmount]);
  useEffect(() => {
    if (transferSuccess) {
      setOpenModal(false);
    }
  }, [transferSuccess]);

  useEffect(() => {
    addSelectedReason({});
    if (pointsSystem.typeOfPointsAction === "Give Points") {
      fetchAllReasons('addition');
    } else if (pointsSystem.typeOfPointsAction === "Take Points") {
      fetchAllReasons('deduction');
    }
  }, [pointsSystem.typeOfPointsAction]);

  const addReason = value => {
    addSelectedReason(value);
  };

  return (
    <>
      <Grid className={classes.container}>
        <AmountOfPoints />
        {pointsSystem.typeOfTransfer === "Yes" ? (
          <TextField
            id="user-id"
            name="userId"
            label="User Id"
            type="text"
            placeholder=""
            className={classes.textField}
            value={pointsSystem.userMobileNumber}
            onChange={handleMobileChange}
            margin="normal"
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              minLength: 11
            }}
          />
        ) : (
          <FileUpload fileType="convertToText" />
        )}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" className={classes.select}>
            Reason
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Reason"
            value={selectedReason}
            className={classes.select}
            onChange={e => addReason(e.target.value)}
            inputProps={{
              id: "search-by"
            }}
            MenuProps={{
              id: "search-by-menu"
            }}
          >
            {reasons.map(item => (
              <MenuItem key={item.value_en} value={item}>
                {item.value_en}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(pointsSystem.typeOfPointsAction === "Give Points" || !showCsPage) && (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => setOpenModal(true)}
            style={{
              width: "10%",
              margin: "16px"
            }}
          >
            Transfer
          </Button>
        )}
        {pointsSystem.typeOfPointsAction === "Take Points" && showCsPage && (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => setOpenModal(true)}
            style={{
              width: "10%",
              margin: "16px"
            }}
          >
            Take
          </Button>
        )}
        {openModal && (
          <ConfirmModal
            open={openModal}
            handleDelete={() => onSubmitHandler()}
            onClose={() => setOpenModal(false)}
            type="blocking"
          />
        )}
        {/*  {redirect ? (
          <Redirect
            to={{
              pathname: `/admin/PointsSystem/`
            }}
          />
        ) : null} */}
      </Grid>

      {errorMessage && !warning && (
        <SnackBar
          severity={"error"}
          messege={"Number should be numeric values only"}
        />
      )}
      {errorAmount && <SnackBar severity={"error"} messege={messageErr} />}
      {transferError && !warning && !errorMessage && (
        <SnackBar severity={"error"} messege={messageErr} />
      )}
      {warning && <SnackBar severity={"error"} messege={messageErr} />}
      {transferSuccess?.data?.invalidUserIds.length > 0 &&
        pointsSystem.typeOfPointsAction === "Give Points" &&
        !warning && (
          <SnackBar
            severity={"warning"}
            style={{ top: 0 }}
            messege={
              <ol className={classes.ListNumber}>
                {transferSuccess?.data?.invalidUserIds.map(userId => (
                  <li key={userId}>{userId}</li>
                ))}
              </ol>
            }
            alertTitleProp={"These user id(s) not transferred"}
          />
        )}
      {transferSuccess?.data?.invalidUserIds.length > 0 &&
        pointsSystem.typeOfPointsAction === "Take Points" &&
        !warning && (
          <SnackBar
            severity={"warning"}
            style={{ top: 0 }}
            messege={
              <ol className={classes.ListNumber}>
                {transferSuccess?.data?.invalidUserIds.map(userId => (
                  <li key={userId}>{userId}</li>
                ))}
              </ol>
            }
            alertTitleProp={"these user id(s) not taken"}
          />
        )}
      {transferSuccess?.data?.invalidUserIds.length === 0 &&
        pointsSystem.typeOfPointsAction === "Give Points" && (
          <SnackBar
            severity={"success"}
            messege={"The points was transferred successfully"}
          />
        )}
      {transferSuccess?.data?.invalidUserIds.length === 0 &&
        pointsSystem.typeOfPointsAction === "Take Points" && (
          <SnackBar
            severity={"success"}
            messege={"The points taken successfully "}
          />
        )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    pointsSystem: state.pointsSystem,
    transferSuccess: state.pointsSystem.transferSuccess,
    transferError: state.pointsSystem.transferError,
    availableError: state.availableError.availableError,
    fileAsArray: state.uploadFile.fileAsArray,
    reasons: state.reasons.reasons,
    selectedReason: state.reasons.selectedReason
  };
};

export default connect(
  mapStateToProps,
  {
    setUserMobileNumber,
    addTransferPoints,
    openSnackBar,
    restTransferRequestResult,
    fetchAllReasons,
    addSelectedReason
  }
)(TransferPoints);

TransferPoints.propTypes = {
  pointsSystem: PropTypes.object,
  setUserMobileNumber: PropTypes.func,
  addTransferPoints: PropTypes.func,
  openSnackBar: PropTypes.func,
  availableError: PropTypes.bool,
  restTransferRequestResult: PropTypes.func
};
