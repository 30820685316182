import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// redux actions
import {
  setTypeOfPoints,
  clearTypeOfTransfer
} from "../../../redux/action/PointsSystem/TransferPointsActions";
// core components
import styles from "../../PointsSystem/styles.css";

const useStyles = makeStyles(styles);

const QuestionComponent = ({
  typeOfPointsAction,
  setTypeOfPoints,
  question
}) => {
  const classes = useStyles();

  const handleRadioChange = event => {
    setTypeOfPoints(event.target.value);
  };

  return (
    <Grid className={`${classes.container} ${classes.marginBlock}`}>
      <div className={classes.radioBtnContainer}>
        <p className={classes.radioBtnQuestion}>{question}</p>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="position"
            name="position"
            value={typeOfPointsAction}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="Give Points"
              style={{ color: "#000" }}
              control={
                <Radio
                  checkedIcon={
                    <span
                      className={`${classes.radioBtnIcon} ${classes.checkedIcon}`}
                    />
                  }
                  icon={<span className={classes.radioBtnIcon} />}
                />
              }
              label="Give Points"
            />
            <FormControlLabel
              value="Take Points"
              style={{ color: "#000" }}
              control={
                <Radio
                  disableRipple
                  checkedIcon={
                    <span
                      className={`${classes.radioBtnIcon} ${classes.checkedIcon}`}
                    />
                  }
                  icon={<span className={classes.radioBtnIcon} />}
                />
              }
              label="Take Points"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    typeOfPointsAction: state.pointsSystem.typeOfPointsAction
  };
};

export default connect(
  mapStateToProps,
  { setTypeOfPoints, clearTypeOfTransfer }
)(QuestionComponent);

QuestionComponent.propTypes = {
  question: PropTypes.string,
  typeOfPointsAction: PropTypes.string,
  setTypeOfPoints: PropTypes.func
};
