const styles = {
  cardContainer: {
    width: "100%",
    marginBottom: "0px",
    padding: "0px"
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexBasis: "70%",
    paddingLeft: "0px"
  },
  cover: {
    height: "120px",
    width: "120px"
  }
};

export default styles;
