const styles = theme => ({
  columnFlexContainer: {
    display: "flex",
    flexDirection: "column"
  },
  lightFont: {
    fontWeight: 300
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "47%"
  },
  imageFieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  imageViewContainer: {
    width: "100px",
    height: "100px",
    padding: theme.spacing(1),
    border: "1px solid #ffa726",
    marginRight: theme.spacing(3)
  },
  wideImageViewContainer: {
    width: "200px",
    height: "120px",
    padding: theme.spacing(1),
    border: "1px solid #ffa726",
    marginRight: theme.spacing(3)
  },
  imageIcon: {
    width: "100px",
    height: "100px",
    objectFit: "cover"
  },
  wideImageIcon: {
    maxWidth: "200px",
    height: "120px",
    objectFit: "cover"
  },
  textFieldFile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "120px",
    lineHeight: "40px",
    padding: "5px 20px",
    textAlign: "center",
    borderRadius: "5px",
    marginTop: theme.spacing(1),
    color: "#fff",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)"
  }
});
export default styles;
