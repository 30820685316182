import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// core components
// import QuestionComponent from "./QuestionComponent";
import UploadMissionFile from "./UploadMissionFile";
// import UploadMissionPhonesFile from "../UploadMissionPhonesFile";
import { Typography } from "@material-ui/core";

const UploadCompletedUsers = props => {
  const { uploadFileType, itemId } = props;

  return (
    <div style={{ marginTop: 10, display: "flex", alignItems: "center" }}>
      {/* <QuestionComponent /> */}
      <Typography
        component="p"
        variant="body1"
        style={{ marginRight: "8px" }}
        gutterBottom={true}
      >
        <Typography component="span" variant="body1">
          Upload Users Id`s:
        </Typography>
      </Typography>
      <UploadMissionFile itemId={itemId} fileType={uploadFileType} />
      {/* {uploadFileType === "Phones" && (
        <UploadMissionFile itemId={itemId} fileType={uploadFileType} />
      )} */}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    uploadFileType: state.completedMissions.uploadFileType
  };
};

export default connect(mapStateToProps)(UploadCompletedUsers);

UploadCompletedUsers.propTypes = {
  uploadFileType: PropTypes.string,
  itemId: PropTypes.string
};
