import React from "react";
import PropTypes from "prop-types";
import { Route, useRouteMatch } from "react-router-dom";

import MissionsListView from "./MissionsListView";
import Form from "./Form";
import MissionsCardsDetails from "./MissionsCardsDetails";
import ErrorBoundary from "../ErrorBoundary";
import HomePage from "./HomePage";
import Sections from "./Sections";
export default function Missions() {
  let match = useRouteMatch();

  return (
    <>
      <Route
        exact
        path={match.path}
        render={routeProps => (
          <ErrorBoundary>
            <HomePage {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route exact path={`${match.path}/sections`} component={Sections} />
      <Route
        exact
        path={`${match.path}/missions`}
        component={MissionsListView}
      />
      <Route path={`${match.path}/missions/form`} component={Form} />
      <Route
        path={`${match.path}/missions/details/:cardId`}
        component={MissionsCardsDetails}
      />
      <Route path={`${match.path}/missions/edit/:_id`} component={Form} />
    </>
  );
}

Missions.propTypes = {
  match: PropTypes.object
};
