import {
  RESET_UPLOAD_RECHARGE_STATE,
  UPLOAD_USERS_TO_RECHARGE,
  OPEN_UPLOAD_MODAL,
  UPLOAD_USERS_SUCCESS,
  UPLOAD_USERS_FAILED,
  UPLOAD_USERS_LOADING
} from "../../action/actionType";

const initialState = {
  list: [],
  openModal: false,
  projectId: null,
  isLoading: false,
  error: false,
  success: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_USERS_TO_RECHARGE:
      return {
        ...state,
        list: action.payload?.usersRecharge,
        projectId: action.payload?.projectId
      };
    case OPEN_UPLOAD_MODAL:
      return {
        ...state,
        openModal: true
      };
    case UPLOAD_USERS_SUCCESS:
      return {
        ...state,
        success: true
      };
    case UPLOAD_USERS_FAILED:
      return {
        ...state,
        error: true
      };
    case UPLOAD_USERS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case RESET_UPLOAD_RECHARGE_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
