export const UPDATE_ORDER_BY = "update deals merchants list order by";
export const UPDATE_ITEMS = "update deals merchants list items";
export const DELETE_ITEM = "delete deals merchant item";

export const updateOrderBy = orderBy => dispatch =>
  dispatch({
    type: UPDATE_ORDER_BY,
    payload: { orderBy }
  });

export const updateItems = items => dispatch =>
  dispatch({
    type: UPDATE_ITEMS,
    payload: { items }
  });

export const deleteItem = id => dispatch =>
  dispatch({
    type: DELETE_ITEM,
    payload: { id }
  });
