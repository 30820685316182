const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 10
  },
  title: {
    padding: 2,
    fontSize: "24px",
    fontWeight: 500
  },

  paper: {
    height: 150,
    width: 246,
    borderRadius: 5,
    border: "1px solid #95989A",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    "& button": {
      "&:hover": {
        color: "#0854FF",
        backgroundColor: "transparent"
      }
    },
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      lineHeight: 1.3
    }
  },
  btnAllTransactions: {
    backgroundColor: "#fff",
    border: "1px solid #95989A",
    borderRadius: 5,
    textTransform: "capitalize",
    boxShadow: "none"
  }
});

export default styles;
