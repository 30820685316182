import { GET_EXCHANGE_RATES } from "../../actions/exchange-rates";
import { GET_EXCHANGE_RATES_HISTORY } from "../../actions/exchange-rates/history";
import {
  UPDATE_EXCHANGE_RATE,
  UPDATE_EXCHANGE_RATES_CHANGES_STATUS,
  UPDATE_EXCHANGE_RATES_ERRORS_STATUS,
  RESET_EXCHANGE_RATES,
  SYNC_EXCHANGE_RATES,
  INIT_EXCHANGE_RATES_HISTORY_PAGINATION,
  CHANGE_EXCHANGE_RATES_HISTORY_PAGE,
  SYNC_EXCHANGE_RATES_HISTORY
} from "../../actions/layout";

const initialState = {
  exchangeRates: {
    loaded: false,
    syncing: false,
    hasErros: false,
    hasChanges: false,
    data: []
  },
  exchangeRatesHistory: {
    loaded: false,
    syncing: false,
    pagination: {
      page: 0,
      size: 0,
      pages: 0
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          loaded: true,
          data: action.payload.data.map(exchangeRate => ({
            id: exchangeRate.id,
            exchangeRate: exchangeRate.exchangeRate
          }))
        }
      };
    case UPDATE_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          data: state.exchangeRates.data.map(exchangeRate =>
            exchangeRate.id === action.payload.id
              ? { ...exchangeRate, exchangeRate: action.payload.exchangeRate }
              : exchangeRate
          )
        }
      };
    case UPDATE_EXCHANGE_RATES_CHANGES_STATUS:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          hasChanges: action.payload.status
        }
      };
    case UPDATE_EXCHANGE_RATES_ERRORS_STATUS:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          hasErrors: action.payload.status
        }
      };
    case RESET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          data: action.payload.data.map(exchangeRate => ({
            id: exchangeRate.id,
            exchangeRate: exchangeRate.exchangeRate
          }))
        }
      };
    case SYNC_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          syncing: action.payload.status
        }
      };
    case GET_EXCHANGE_RATES_HISTORY:
      return {
        ...state,
        exchangeRatesHistory: {
          ...state.exchangeRatesHistory,
          loaded: true,
          pagination: {
            ...state.exchangeRatesHistory.pagination,
            pages: Math.ceil(
              action.payload.meta.total /
                state.exchangeRatesHistory.pagination.size
            )
          }
        }
      };
    case INIT_EXCHANGE_RATES_HISTORY_PAGINATION:
      return {
        ...state,
        exchangeRatesHistory: {
          ...state.exchangeRatesHistory,
          pagination: {
            ...state.exchangeRatesHistory.pagination,
            size: action.payload.size,
            page: 1
          }
        }
      };
    case CHANGE_EXCHANGE_RATES_HISTORY_PAGE:
      return {
        ...state,
        exchangeRatesHistory: {
          ...state.exchangeRatesHistory,
          pagination: {
            ...state.exchangeRatesHistory.pagination,
            page: action.payload.page
          }
        }
      };
    case SYNC_EXCHANGE_RATES_HISTORY:
      return {
        ...state,
        exchangeRatesHistory: {
          ...state.exchangeRatesHistory,
          syncing: action.payload.status
        }
      };
    default:
      return state;
  }
};
