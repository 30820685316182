import {
  SET_MISSION_TASKS,
  SET_MISSIONS_CTA_BUTTON_TEXT_TASKS,
  SET_CTA_BUTTON_TEXT_SELECTION_TASKS,
  CLEAR_MISSION_TASK,
  EDIT_MISSION_TASKS,
  SET_TASK_TYPE,
  SET_BUTTON_URL,
  SET_MISSIONS_PROMO_CODES
} from "../../action/actionType";

const initialState = {
  id: "",
  englishTitle: "",
  englishSubTitle: "",
  arabicTitle: "",
  arabicSubTitle: "",
  btnEnglishTextSelection: "",
  btnArabicTextSelection: "",
  btnEnglishText: "",
  btnArabicText: "",
  categoryId: "",
  category: "",
  btnUrl: "",
  viewType: "",
  campaignId: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MISSION_TASKS:
      return {
        ...state,
        englishTitle: action.payload.englishTitle,
        englishSubTitle: action.payload.englishSubTitle,
        arabicTitle: action.payload.arabicTitle,
        arabicSubTitle: action.payload.arabicSubTitle
      };
    case SET_CTA_BUTTON_TEXT_SELECTION_TASKS:
      return {
        ...state,
        btnEnglishTextSelection: action.payload.btnEnglishTextSelection,
        btnArabicTextSelection: action.payload.btnArabicTextSelection,
        btnEnglishText: "",
        btnArabicText: ""
      };

    case SET_MISSIONS_CTA_BUTTON_TEXT_TASKS:
      return {
        ...state,
        btnArabicTextSelection: "",
        btnEnglishTextSelection: state.btnEnglishTextSelection,
        btnEnglishText: action.payload.btnEnglishText,
        btnArabicText: action.payload.btnArabicText
      };

    case EDIT_MISSION_TASKS:
      return {
        ...state,
        id: action.payload.id,
        englishTitle: action.payload.englishTitle,
        englishSubTitle: action.payload.englishSubTitle,
        arabicTitle: action.payload.arabicTitle,
        arabicSubTitle: action.payload.arabicSubTitle,
        btnEnglishTextSelection: action.payload.btnEnglishTextSelection,
        btnArabicTextSelection: action.payload.btnArabicTextSelection,
        btnEnglishText: action.payload.btnEnglishText,
        btnArabicText: action.payload.btnArabicText,
        btnUrl: action.payload.btnUrl,
        categoryId: action.payload.categoryId,
        category: action.payload.category,
        campaignId: action.payload.campaignId,
        viewType: "Edit"
      };

    case SET_TASK_TYPE:
      return {
        ...state,
        categoryId: action.payload.id,
        category: action.payload.name
      };
    case SET_MISSIONS_PROMO_CODES:
      return {
        ...state,
        campaignId: action.payload.id,
        campaignName: action.payload.name
      };

    case SET_BUTTON_URL:
      return {
        ...state,
        btnUrl: action.payload
      };

    case CLEAR_MISSION_TASK:
      return {
        ...state,
        englishTitle: "",
        englishSubTitle: "",
        arabicTitle: "",
        arabicSubTitle: "",
        btnEnglishTextSelection: "",
        btnArabicTextSelection: "",
        btnEnglishText: "",
        btnArabicText: "",
        success: false,
        categoryId: "",
        category: "",
        btnUrl: "",
        viewType: ""
      };
    default:
      return state;
  }
};
