import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
// redux actions
import { clearTypeOfTransfer } from "../../../redux/action/PointsSystem/TransferPointsActions";
// core components
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import styles from "../styles.css";

const useStyles = makeStyles(styles);

const BucketItem = props => {
  const {
    match: { path = "" },
    bucket,
    adsItems,
    clearTypeOfTransfer
  } = props;

  const classes = useStyles();

  useEffect(() => {
    clearTypeOfTransfer();
  }, []);

  return (
    <Card>
      <CardBody className={classes.cardBody}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBlockStart: "16px",
            alignItems: "center"
          }}
        >
          {bucket.name.toLowerCase() === "ads" ? (
            <Link
              to={{
                pathname: `${path}/adsSystem`,
                state: adsItems
              }}
            >
              <Typography
                component="h2"
                variant="h5"
                color="textPrimary"
                style={{
                  textDecoration: "underline"
                }}
              >
                {bucket.name} Bucket System
              </Typography>
            </Link>
          ) : (
            <Typography
              component="h2"
              variant="h5"
              color="textPrimary"
              style={{
                textDecoration:
                  bucket.name.toLowerCase() === "bonus" ? "underline" : "none"
              }}
            >
              {bucket.name} Bucket System
            </Typography>
          )}
          <Typography
            component="h2"
            variant="subtitle1"
            style={{ color: "#0abb87" }}
          >
            {`${bucket.value.toLocaleString()} pts`}
          </Typography>
        </div>
        {bucket.name.toLowerCase() === "bonus" && (
          <div
            style={{
              display: "flex",
              marginBlockStart: "16px"
            }}
          >
            <Link
              to={{
                pathname: `${props.match.path}/bonusPoints/addPoints`
              }}
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                style={{
                  marginInlineEnd: "24px"
                }}
              >
                Add points
              </Button>
            </Link>
            <Link
              to={{
                pathname: `${props.match.path}/bonusPoints/transferPoints`
              }}
            >
              <Button variant="contained" size="medium" color="primary">
                Transfer points
              </Button>
            </Link>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
const mapStateToProps = state => {
  return {
    adsItems: state.AdsListView.adsItems
  };
};
export default connect(
  mapStateToProps,
  { clearTypeOfTransfer }
)(withRouter(BucketItem));

BucketItem.propTypes = {
  match: PropTypes.object,
  bucket: PropTypes.object,
  adsItems: PropTypes.array,
  clearTypeOfTransfer: PropTypes.func
};
