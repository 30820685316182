import React, { Children } from "react";

import Card from "@mui/material/Card";

const FormCardBody = ({ sx, ...props }) => {
  return (
    <Card
      {...props}
      sx={{
        ...sx,
        boxShadow:
          "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
      }}
    />
  );
};

export default FormCardBody;
