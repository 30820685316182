const Styles = {
    uploadContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "16px 24px",
      gap: "4px",
      background: "#FFFFFF",
      border: "1px dashed #E4E7EC",
      borderRadius: "8px",
      width: "100%",
      textAlign: "center",
      cursor: "pointer",
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "18px",
      color: "#667085",
      "& span": {
        display: "flex",
        marginTop: 12,
        alignItems: "center",
        justifyContent: "center",
        "& p": {
          color: "#6941C6",
          marginRight: 4
        }
      }
    },
  };
  
  export default Styles;
  