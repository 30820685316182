import {
  SET_TARGET_TYPE,
  SET_CARD_LOCATION,
  SET_CARD_POSITION
} from "../actionType";

export const setTargetType = value => {
  return {
    type: SET_TARGET_TYPE,
    payload: value
  };
};

export const setCardLocation = value => {
  return {
    type: SET_CARD_LOCATION,
    payload: value
  };
};

export const setCardPosition = value => {
  return {
    type: SET_CARD_POSITION,
    payload: value
  };
};
