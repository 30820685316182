export const targetingType = [
  {
    value: "generic",
    label: "Generic"
  },
  {
    value: "specific",
    label: "Specific"
  }
];
