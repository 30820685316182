import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
import { totalUsersAndValue } from "../../../../redux/reducers/WaslaRewards/selectors";
// core components
import styles from "../../styles.css";

const useStyles = makeStyles(styles);

function RechargeInfo(props) {
  const classes = useStyles();
  const { checkRequiredFields, uploadUsersRecharge, currentBalance } = props;
  const [error, setError] = useState(false);
  const [totalTransactionValue, setTotalTransactionValue] = useState(0);
  const [totalTransactionNetValue, setTotalTransactionNetValue] = useState(0);

  useEffect(() => {
    setTotalTransactionValue(uploadUsersRecharge.transactionGrossValue);
    setTotalTransactionNetValue(uploadUsersRecharge.transactionNetValue);
  }, [uploadUsersRecharge]);

  useEffect(() => {
    if (currentBalance && totalTransactionValue > currentBalance) {
      setError(true);
      checkRequiredFields(false);
    } else {
      setError(false);
    }
  }, [totalTransactionValue, currentBalance]);

  return (
    <div className={classes.container}>
      <TextField
        id="number-of-users"
        name="uploadUsersRecharge"
        label="Number of users"
        type="number"
        className={classes.textField}
        value={uploadUsersRecharge.numOfUsers}
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          readOnly: true
        }}
      />
      <div className={classes.textFieldsContainer}>
        <TextField
          id="Gross Value (Amount Paid)"
          name="grossValue"
          label="Gross Value (Amount Paid)"
          aria-label="gross value"
          className={classes.textField}
          value={`${totalTransactionValue.toLocaleString()} EGP`}
          margin="normal"
          variant="outlined"
          error={error}
          aria-label="grossValue"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          id="Net Value (Amount send)"
          name="netValue"
          label="Net Value (Amount send)"
          aria-label="net value"
          className={classes.textField}
          value={`${totalTransactionNetValue.toLocaleString()} EGP`}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
        />
      </div>
      {error && (
        <p
          aria-label="error message"
          style={{
            color: "#f44336",
            marginTop: "-16px",
            fontSize: "0.8rem",
            marginLeft: "16px",
            marginRight: "16px"
          }}
        >
          * Not enough balance. Please contact Wasla team to topup your account
        </p>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    uploadUsersRecharge: totalUsersAndValue(state.UploadUserRecharge.list),
    currentBalance: state.balanceList.balanceList.currentBalance
  };
};

export default connect(
  mapStateToProps,
  { checkRequiredFields }
)(RechargeInfo);

RechargeInfo.propTypes = {
  checkRequiredFields: PropTypes.func,
  uploadUsersRecharge: PropTypes.object,
  currentBalance: PropTypes.number
};
