import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "moment";
import { useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from "@material-ui/core/CircularProgress";
import Danger from "../../Typography/Danger";
// redux actions
import { fetchBalanceList } from "../../../redux/action/WaslaRewards/FetchBalanceListAction";
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";
import {
  fetchRechargesFailedList,
  setAllItemsRecharge,
  setEditItemRecharge,
  setCurrentRecharges,
  setFinishAllEdit
} from "../../../redux/action/WaslaRewards/EditUsersRechargeAction";

// core components
import EditIcon from "../../../assets/img/EditIcon.svg";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import TableHeader from "./TableHeader";
import styles from "../styles.css";
import { updateRecord, getPage } from "./helper";
import ErrorMessage from "../../ErrorMessage";
import { Validation } from "../UploadFile/Validtion";

function createData(
  phoneNumber,
  operator,
  transactionValue,
  success,
  id,
  edit
) {
  return {
    phoneNumber,
    operator,
    transactionValue,
    success,
    id,
    edit
  };
}

const useStyles = makeStyles(styles);

function EditRechargesTable(props) {
  const {
    usersRechargeFailed,
    hasNext,
    fetchRechargesFailedList,
    error,
    checkRequiredFields,
    setAllItemsRecharge,
    isLoading,
    setEditItemRecharge,
    totalUsersRechargeFailed,
    setCurrentRecharges,
    setFinishAllEdit,
    isFinish
  } = props;
  const classes = useStyles();
  const location = useLocation();

  const [rows, setRows] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [editRow, setEditRow] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    phoneNumber: "",
    operator: "",
    transactionValue: ""
  });
  const batchId = location?.state?.batchId;

  const createRows = () => {
    const retrieveRechargeList = usersRechargeFailed.map(item => {
      let usersRecharge = {
        phoneNumber: item.phoneNumber,
        operator: item.operator,
        transactionValue: item.price || item.transactionValue,
        edit: (item.edit = false)
      };
      return usersRecharge;
    });
    setAllItemsRecharge(retrieveRechargeList, pageNum, nextPage);
    setRows([...retrieveRechargeList]);
  };

  useEffect(() => {
    if (usersRechargeFailed.length !== 0 && !isFinish) {
      createRows(usersRechargeFailed);
    }
  }, [usersRechargeFailed, nextPage, isFinish]);

  useEffect(() => {
    if (pageNum >= 1 && hasNext) {
      fetchRechargesFailedList({
        pageNum,
        batchId,
        projectId: location?.state?.id
      });
      setNextPage(hasNext);
    }
  }, [pageNum]);

  useEffect(() => {
    if (totalUsersRechargeFailed.length !== 0 && isFinish) {
      const getItem = getPage(totalUsersRechargeFailed, pageNum);

      if (getItem.length === 0) return;
      setNextPage(getItem.hasNext);
      setRows(getItem.items);
    }
  }, [isFinish, totalUsersRechargeFailed]);

  useEffect(() => {
    if (!hasNext && !isFinish) {
      checkRequiredFields(true);
      setNextPage(hasNext);
    } else {
      checkRequiredFields(false);
    }
  }, [hasNext, isFinish]);

  const handleNext = () => {
    if (hasNext) {
      setPageNum(pageNum + 1);
    } else {
      setPageNum(pageNum + 1);
      setFinishAllEdit(false);

      const getNextPage = getPage(totalUsersRechargeFailed, pageNum + 1);

      if (getNextPage.length === 0) return;
      setNextPage(getNextPage.hasNext);
      setCurrentRecharges(getNextPage.items);
    }
  };

  const handlePrevious = () => {
    if (pageNum !== 1) {
      setPageNum(pageNum - 1);

      const getPreviousPage = getPage(totalUsersRechargeFailed, pageNum - 1);

      if (getPreviousPage.length === 0) return;
      setNextPage(getPreviousPage.hasNext);
      setCurrentRecharges(getPreviousPage.items);
    }
  };

  const validatedFiled = (name, value, obj) => {
    if (name === "phoneNumber") {
      if (Validation.isPhoneNumber(value)) {
        setErrorMessage(prevState => ({
          ...prevState,
          phoneNumber: "Invalid Phone Number"
        }));
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          phoneNumber: ""
        }));
      }
    }
    if (name === "operator") {
      if (Validation.isOperator(value)) {
        setErrorMessage(prevState => ({
          ...prevState,
          operator: "Invalid Operator"
        }));
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          operator: ""
        }));
      }
    }
    if (name === "transactionValue") {
      if (Validation.isNumber(value, obj.operator)) {
        setErrorMessage(prevState => ({
          ...prevState,
          transactionValue: "Invalid Transaction Value"
        }));
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          transactionValue: ""
        }));
      }
    }
  };

  const handleEdit = index => {
    let newArr = updateRecord(rows, index, "edit", true);
    setEditRow(true);
    checkRequiredFields(editRow);
    setRows(newArr);
  };

  const updateFieldChanged = index => e => {
    validatedFiled(e.target.name, e.target.value, rows[index]);
    let newArr = updateRecord(rows, index, [e.target.name], e.target.value);
    setRows(newArr);
  };

  const handleSaveEdit = index => {
    let newArr = updateRecord(rows, index, "edit", false);
    setEditRow(false);
    setAllItemsRecharge(newArr, pageNum, nextPage);

    setEditItemRecharge(newArr);
    if (!hasNext) {
      checkRequiredFields(editRow);
    }
  };

  const emptyRows = 10 - Math.min(10, rows.length - 0 * 10);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Paper className={classes.paper}>
          {error && <ErrorMessage message="Sorry something went wrong!" />}
          {isLoading && rows.length === 0 && <CircularProgress />}
          {rows.length !== 0 && (
            <>
              <div style={{ maxHeight: "350px", overflowY: "scroll" }}>
                <Table
                  className={`${classes.table} ${classes.editTable}`}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <TableHeader classes={classes} />

                  <TableBody>
                    {rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={labelId}
                          style={{
                            padding: 12
                          }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            style={{ paddingLeft: 5 }}
                          >
                            {!row.edit && row.phoneNumber}
                            {row.edit && (
                              <TextField
                                id="phoneNumber"
                                name="phoneNumber"
                                type="number"
                                className={classes.textField}
                                value={row.phoneNumber}
                                margin="normal"
                                variant="standard"
                                style={{ width: "91%", margin: 0 }}
                                onChange={updateFieldChanged(index)}
                                error={!!errorMessage.phoneNumber}
                                // helperText={errorMessage.phoneNumber}
                              />
                            )}
                          </TableCell>
                          <TableCell scope="row" padding="none">
                            {!row.edit && row.operator}
                            {row.edit && (
                              <TextField
                                id="operator"
                                name="operator"
                                type="text"
                                className={classes.textField}
                                value={row.operator}
                                margin="normal"
                                variant="standard"
                                style={{ width: "91%", margin: 0 }}
                                onChange={updateFieldChanged(index)}
                                error={!!errorMessage.operator}
                                // helperText={errorMessage.operator}
                              />
                            )}
                          </TableCell>

                          <TableCell scope="row" padding="none">
                            {!row.edit && row.transactionValue}
                            {row.edit && (
                              <TextField
                                id="transactionValue"
                                name="transactionValue"
                                type="number"
                                className={classes.textField}
                                value={row.transactionValue}
                                margin="normal"
                                variant="standard"
                                style={{ margin: 0, width: "91%" }}
                                onChange={updateFieldChanged(index)}
                                error={!!errorMessage.transactionValue}
                                // helperText={errorMessage.transactionValue}
                              />
                            )}
                          </TableCell>
                          <TableCell scope="row" padding="none">
                            {row.success ? (
                              <Typography
                                variant="subtitle1"
                                component="p"
                                style={{
                                  backgroundColor: "rgba(10,187,135,.1)",
                                  color: "#0abb87",
                                  padding: "6px 16px",
                                  display: "inline-block",
                                  margin: "0.2em 0",
                                  borderRadius: "50px",
                                  fontSize: "14px"
                                }}
                              >
                                Successful
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                component="p"
                                style={{
                                  backgroundColor: "rgba(253,57,122,.1)",
                                  color: "#fd397a",
                                  padding: "6px 32px",
                                  display: "inline-block",
                                  margin: "0.2em 0",
                                  borderRadius: "50px",
                                  fontSize: "14px"
                                }}
                              >
                                Failed
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell scope="row" padding="none">
                            {(!editRow || !row.edit) && (
                              <Button
                                onClick={() => handleEdit(index)}
                                disabled={editRow || row.edit}
                              >
                                <img src={EditIcon} alt="" />
                              </Button>
                            )}
                            {editRow && row.edit && (
                              <Button
                                onClick={() => handleSaveEdit(index)}
                                disabled={Object.values(errorMessage).some(
                                  val => val
                                )}
                              >
                                <DoneIcon />
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 25 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "space-between",
                  justifyContent: "space-between",
                  padding: "0.3em"
                }}
              >
                <Button
                  type="button"
                  onClick={handlePrevious}
                  disabled={pageNum === 1 || editRow}
                  color="primary"
                  className={`${classes.arrowButton} ${pageNum !== 1 &&
                    !editRow &&
                    classes.primaryButton}`}
                >
                  <NavigateBeforeIcon />
                </Button>
                {Object.values(errorMessage).some(item => !!item) && (
                  <Danger>
                    <p style={{ fontWeight: 500, color: "#f44336" }}>
                      {errorMessage.phoneNumber ||
                        errorMessage.operator ||
                        errorMessage.transactionValue}
                    </p>
                  </Danger>
                )}
                <Button
                  type="button"
                  onClick={handleNext}
                  color="primary"
                  disabled={editRow || !nextPage}
                  className={`${classes.arrowButton} ${!editRow &&
                    nextPage &&
                    classes.primaryButton}`}
                >
                  <NavigateNextIcon />
                </Button>
              </div>
            </>
          )}
        </Paper>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    usersRechargeFailed: state.usersRechargeToEdit.usersRechargeFailed,
    hasNext: state.usersRechargeToEdit.hasNext,
    error: state.errorMessage.error,
    isLoading: state.usersRechargeToEdit.isLoading,
    totalUsersRechargeFailed:
      state.usersRechargeToEdit.totalUsersRechargeFailed,
    isFinish: state.usersRechargeToEdit.isFinish
  };
};

export default connect(
  mapStateToProps,
  {
    fetchRechargesFailedList,
    fetchBalanceList,
    checkRequiredFields,
    setAllItemsRecharge,
    setEditItemRecharge,
    setCurrentRecharges,
    setFinishAllEdit
  }
)(EditRechargesTable);

EditRechargesTable.propTypes = {
  usersRechargeFailed: PropTypes.array,
  hasNext: PropTypes.bool,
  error: PropTypes.bool,
  fetchBalanceList: PropTypes.func,
  fetchRechargesFailedList: PropTypes.func,
  setAllItemsRecharge: PropTypes.func,
  checkRequiredFields: PropTypes.func,
  setEditItemRecharge: PropTypes.func,
  setCurrentRecharges: PropTypes.func,
  isLoading: PropTypes.bool,
  setFinishAllEdit: PropTypes.func
};
