import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import styles from "./styles.css";
import Success from "../../../Typography/Success";
const useStyles = makeStyles(styles);

function ResponseModal(props) {
  const { image, onCloseModal, error, success } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.containerError}>
        <CardMedia className={classes.media} image={image} title="Warning" />
        {error && (
          <>
            <Typography color="error">
              Payment Failed {error ? error : ""}
            </Typography>
            <Typography>
              Try again and if issue continues contact Wasla Support
            </Typography>
          </>
        )}
        {success && (
          <>
            <Success>Batch Created Successfully!</Success>
            <Typography>Transactions Processing...</Typography>
          </>
        )}
      </div>
      <div
        className={classes.containerButton}
        style={{ justifyContent: "center" }}
      ></div>
    </>
  );
}

export default ResponseModal;
