import qs from "qs";

import { dealsBaseurl, authURL } from "../../../components/config";

export const fetchMerchantList = ({
  pageNum,
  limit = 10
}) => async dispatch => {
  dispatch({ type: "SET_LOADING_MERCHANT_LIST", payload: true });
  await dealsBaseurl
    .get(`/store/merchants?page=${pageNum}&limit=${limit}`)
    .then(res => {
      dispatch({ type: "SET_LOADING_MERCHANT_LIST", payload: false });

      dispatch({
        type: "FETCH_MERCHANT_LIST",
        payload: res?.data
      });
    })
    .catch(error => {
      dispatch({ type: "SET_LOADING_MERCHANT_LIST", payload: false });
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const uploadMerchantToSubmit = deals => {
  return {
    type: "SET_MERCHANT_LIST_UPLOAD",
    payload: deals
  };
};

export const handleDeleteMerchant = (id, pageNum) => async dispatch => {
  dispatch(resetUploadMerchantList());

  await dealsBaseurl
    .delete(`/store/merchants/${id}`)
    .then(() => {
      dispatch(fetchMerchantList({ pageNum }));
      dispatch({
        type: "DELETE_MERCHANT_SUCCESS",
        payload: "Merchant is deleted successfully"
      });
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const submitUploadMerchant = merchants => async dispatch => {
  dispatch({ type: "UPLOAD_MERCHANT_LOADING", payload: true });
  await dealsBaseurl
    .post(`/store/merchants`, [...merchants])
    .then(res => {
      dispatch({ type: "UPLOAD_MERCHANT_LOADING", payload: false });
      dispatch({
        type: "UPLOAD_MERCHANT_SUCCESS",
        payload: "Merchant is added successfully"
      });
      dispatch(fetchMerchantList({ pageNum: 1 }));
    })
    .catch(error => {
      dispatch({ type: "UPLOAD_MERCHANT_LOADING", payload: false });

      if (error?.response?.status === 401) {
        window.location.assign(authURL);
      }

      if (error?.response?.status === 400) {
        dispatch({
          type: "UPLOAD_MERCHANT_FAILED",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "UPLOAD_MERCHANT_FAILED",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const resetUploadMerchantList = () => {
  return {
    type: "RESET_UPLOAD_MERCHANT_LIST"
  };
};

export const closeNotification = () => {
  return {
    type: "RESET_ERROR"
  };
};
export const submitUpdateMerchant = ({ data, id }) => async dispatch => {
  await dealsBaseurl
    .put(`/store/Merchants/${id}`, data)
    .then(() => {
      dispatch(fetchMerchantList({ pageNum: 1 }));
      dispatch({
        type: "SUCCESS_UPDATE_MERCHANT",
        payload: "Merchant is updated successfully"
      });
    })
    .catch(err => {
      dispatch({
        type: "ERROR_UPDATE_MERCHANT"
      });
    });
};
