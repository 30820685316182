import { combineReducers } from "redux";

import filtration from "./filtration";
import pagination from "./pagination";
import list from "./list";

export default combineReducers({
  filtration,
  pagination,
  list
});
