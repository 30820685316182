import React from "react";
import PropTypes from "prop-types";

import CustomModal from "../Modal";

// core components
import ServiceCatalogueContent from "./ServiceCatalogue.js";

function ServiceCatalogue(props) {
  const { open, closeModal } = props;
  return (
    <CustomModal
      open={open}
      title="Service Catalogue"
      close={closeModal}
      style={{ width: "45%", margin: "10px 0" }}
    >
      <ServiceCatalogueContent closeModal={closeModal} />
    </CustomModal>
  );
}

export default ServiceCatalogue;

ServiceCatalogue.propTypes = {
  openManualModal: PropTypes.bool,
  open: PropTypes.bool,
  closeModal: PropTypes.func
};
