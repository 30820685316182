import History from "./History";
import { cashbacksConversionsEditRequestHistoryPath } from "./paths";

export default {
  routes: [
    {
      path: cashbacksConversionsEditRequestHistoryPath,
      component: History,
      layout: "main",
      isPrivate: false,
      roles: ["admin", "cs"],
      routes: []
    }
  ]
};
