import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import withReducer from "../../../../../store/withReducer";
import CategoryReducers from "../reducers";

import * as dealsService from "../../../../../services/deals";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import WaslaTheme from "../../../../../modules/wasla-theme";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import configurations from "../../../../../configurations";
import { CategoriesPath } from "../../../../../main/deals/categories/paths";

import {
  updateNameEn,
  updateNameAr,
  updateLogoUrl,
  updateStatus,
  getDealsCounts,
  getErrorMessage
} from "../actions/local";

import Form from "../components/Form";

const EditCategory = props => {
  const {
    category: { origin, local },
    updateNameEn,
    updateNameAr,
    updateLogoUrl,
    updateStatus,
    getDealsCounts,
    getErrorMessage
  } = props;
  const [categoryData, setCategory] = useState();

  let { slug } = useParams();
  const history = useHistory();

  useEffect(async () => {
    const res = await dealsService.getCategory(slug);
    updateNameEn(res.titleEn);
    updateNameAr(res.titleAr);
    updateLogoUrl(`${res.icon}?${new Date()}`);
    updateStatus(res.isActive);
    getDealsCounts(res.dealsCount);
    setCategory(res);
  }, []);

  const handleSubmitForm = async category => {
    const editCategoryResponse = await dealsService.updateCategory({
      order: categoryData.order,
      slug: categoryData.slug,
      titleEn: category.nameEn,
      titleAr: category.nameAr,
      active: category.status
    });
    if (editCategoryResponse.id) {
      if (category.logoUrl) {
        const categoryImageResponse = await dealsService.addCategoryImage(
          editCategoryResponse.slug,
          category.logoUrl
        );
      }
      history.push(
        [configurations.dashboard.basePath, CategoriesPath].join("")
      );
    } else {
      getErrorMessage({
        error: true,
        title: "Category Name Already Exists",
        message:
          "Please choose a unique name for the category.The name entered is currecntly used"
      });
    }
  };
  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, "/Deals&Merchant"].join(
              ""
            )}
          >
            Deals
          </Link>
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, CategoriesPath].join("")}
          >
            Categories
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            Edit Category
          </Typography>
        </Breadcrumbs>
        <Grid container justifyContent="center">
          <Grid item lg={9}>
            <Form submitForm={handleSubmitForm} />
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default withReducer("category", CategoryReducers)(
  connect(
    state => ({ category: state.category }),
    {
      updateNameEn,
      updateNameAr,
      updateLogoUrl,
      updateStatus,
      getDealsCounts,
      getErrorMessage
    }
  )(EditCategory)
);
