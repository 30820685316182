import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withReducer from "../../../../../store/withReducer";
import CategoryReducers from "../reducers";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as dealsService from "../../../../../services/deals";

import WaslaTheme from "../../../../../modules/wasla-theme";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import configurations from "../../../../../configurations";
import { CategoriesPath } from "../../../../../main/deals/categories/paths";

import { getErrorMessage } from "../actions/local";

import Form from "../components/Form";

const AddCategory = props => {
  const { getErrorMessage } = props;
  const history = useHistory();
  const [orderNo, setOrderNo] = useState(1);

  const retrieveCategories = async () => {
    const res = await dealsService.getCategories();
    const biggestOrder = Math.max.apply(
      Math,
      res.map(function(item) {
        return item.order;
      })
    );
    setOrderNo(biggestOrder + 1);
  };

  useEffect(() => {
    retrieveCategories();
  }, []);

  const handleSubmitForm = async category => {
    const addCategoryResponse = await dealsService.addCategory({
      order: orderNo,
      titleEn: category.nameEn,
      titleAr: category.nameAr,
      icon: "icon",
      active: category.status
    });
    if (addCategoryResponse.id) {
      if (category.logoUrl) {
        const categoryImageResponse = await dealsService.addCategoryImage(
          addCategoryResponse.slug,
          category.logoUrl
        );
      }
      history.push(
        [configurations.dashboard.basePath, CategoriesPath].join("")
      );
    } else {
      getErrorMessage({
        error: true,
        title: "Category Name Already Exists",
        message:
          "Please choose a unique name for the category.The name entered is currecntly used"
      });
    }
  };
  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, "/Deals&Merchant"].join(
              ""
            )}
          >
            Deals
          </Link>
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, CategoriesPath].join("")}
          >
            Categories
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            Create Category
          </Typography>
        </Breadcrumbs>
        <Grid container justifyContent="center">
          <Grid item lg={9}>
            <Form submitForm={handleSubmitForm} />
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default withReducer("category", CategoryReducers)(
  connect(
    state => ({ category: state.category }),
    {
      getErrorMessage
    }
  )(AddCategory)
);
