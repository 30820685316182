import {
  SET_MISSIONS_DEFINITION,
  SET_MISSION_REWARDED_POINTS,
  SET_MISSION_CATEGORY_TYPE,
  SET_SURVEY_COMPLETION_TIME,
  SET_CTA_BUTTON_TEXT_SELECTION,
  SET_MISSIONS_CTA_BUTTON_TEXT,
  SET_BUTTON_URL,
  SET_MISSION_MAX_USAGE,
  SET_MISSION_TARGET_TYPE,
  SET_IS_FEATURED,
  SUBMIT_MISSION_DATA,
  RESET_STATE,
  EDIT_MISSIONS,
  EDIT_MISSIONS_DATA,
  SET_MIN_AND_MAX_REWARDED_POINTS,
  SET_MISSION_ORDER,
  SET_MISSION_TOTAL_POINTS,
  SET_MISSION_MAX_USAGE_PER_USER
} from "../../action/actionType";

const initialState = {
  englishTitle: "",
  englishDescription: "",
  arabicTitle: "",
  arabicDescription: "",
  rewardedPoints: "",
  minRewardedPoints: "",
  maxRewardedPoints: "",
  category: "",
  categoryId: "",
  surveyCompletionTime: "",
  maxUsage: "",
  maxUsagePerUser: "",
  btnEnglishTextSelection: "",
  btnArabicTextSelection: "",
  btnEnglishText: "",
  btnArabicText: "",
  btnUrl: "",
  targetType: "generic",
  cardPosition: "",
  isFeatured: false,
  redirect: false,
  cardId: "",
  order: "",
  totalPoints: "",
  sectionId: "",
  sectionName: "",
  missionTypeId: "",
  missionTypeName: "",
  urlParams: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MISSIONS_DEFINITION:
      return {
        ...state,
        englishTitle: action.payload.englishTitle,
        englishDescription: action.payload.englishDescription,
        arabicTitle: action.payload.arabicTitle,
        arabicDescription: action.payload.arabicDescription
      };

    case SET_MISSION_REWARDED_POINTS:
      return {
        ...state,
        rewardedPoints: +action.payload || ""
      };

    case SET_MISSION_CATEGORY_TYPE:
      return {
        ...state,
        categoryId: action.payload.id,
        category: action.payload.name,
        surveyCompletionTime: "",
        minRewardedPoints: "",
        maxRewardedPoints: ""
      };
    case "SET_MISSION_SECTION_TYPE":
      return {
        ...state,
        sectionId: action.payload.id,
        sectionName: action.payload.name
      };
    case "SET_MISSION_TYPE":
      return {
        ...state,
        missionTypeId: action.payload.id,
        missionTypeName: action.payload.name
      };
    case "SET_MISSION_URL_PARAMS":
      return {
        ...state,
        urlParams: action.payload
      };
    case SET_SURVEY_COMPLETION_TIME:
      return {
        ...state,
        surveyCompletionTime: Number(action.payload)
      };

    case SET_MISSION_ORDER:
      return {
        ...state,
        order: Number(action.payload)
      };

    case SET_MISSION_MAX_USAGE:
      return {
        ...state,
        maxUsage: +action.payload || ""
      };
    case SET_MISSION_MAX_USAGE_PER_USER:
      return {
        ...state,
        maxUsagePerUser: Number(action.payload)
      };

    case SET_MISSION_TOTAL_POINTS:
      return {
        ...state,
        totalPoints: Number(action.payload)
      };

    case SET_CTA_BUTTON_TEXT_SELECTION:
      return {
        ...state,
        btnEnglishTextSelection: action.payload.btnEnglishTextSelection,
        btnArabicTextSelection: action.payload.btnArabicTextSelection,
        btnEnglishText: "",
        btnArabicText: ""
      };

    case SET_MISSIONS_CTA_BUTTON_TEXT:
      return {
        ...state,
        btnArabicTextSelection: "",
        btnEnglishText: action.payload.btnEnglishText,
        btnArabicText: action.payload.btnArabicText
      };

    case SET_BUTTON_URL:
      return {
        ...state,
        btnUrl: action.payload
      };

    case SET_MIN_AND_MAX_REWARDED_POINTS:
      return {
        ...state,
        rewardedPoints: "",
        minRewardedPoints: Number(action.payload.minRewardedPoints),
        maxRewardedPoints: Number(action.payload.maxRewardedPoints)
      };

    case SET_MISSION_TARGET_TYPE:
      return {
        ...state,
        targetType: action.payload
      };

    case SET_IS_FEATURED:
      return {
        ...state,
        isFeatured: !state.isFeatured
      };

    case SUBMIT_MISSION_DATA:
      return {
        ...state,
        redirect: true
      };

    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };

    case EDIT_MISSIONS:
      return {
        ...state,
        englishTitle: action.payload?.titleEn,
        englishDescription: action.payload?.descriptionEn,
        arabicTitle: action.payload?.titleAr,
        arabicDescription: action.payload?.descriptionAr,
        // categoryId: action.payload.categoryId,
        rewardedPoints: action.payload?.points,
        // minRewardedPoints: Number(action.payload.minRewardedPoints) || "",
        // maxRewardedPoints: Number(action.payload.maxRewardedPoints) || "",
        btnEnglishTextSelection: action.payload?.buttonTextEn || "",
        btnArabicTextSelection: action.payload?.buttonTextAr || "",
        btnEnglishText: action.payload?.buttonTextEn || "",
        btnArabicText: action.payload?.buttonTextAr || "",
        btnUrl: action.payload?.buttonUrl,
        maxUsage: action.payload?.maxUsage,
        // maxUsagePerUser: action.payload.maxUsagePerUser,
        // surveyCompletionTime: action.payload.estimatedTime,
        targetType: action.payload.targetType || "generic",
        order: action.payload?.order,
        cardId: action.payload?._id || action.payload?.id,
        isFeatured: action.payload?.isFeatured,
        missionTypeName: action.payload?.type,
        sectionId: action.payload?.sectionId,
        urlParams: action.payload?.urlParams
      };

    case EDIT_MISSIONS_DATA:
      return {
        ...state,
        redirect: true
      };

    default:
      return state;
  }
};
