import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

// core components
import QuickLinksList from "./QuickLinksList";
import QuickLinkForm from "./QuickLinkForm";

export default function QuickLink(props) {
  return (
    <>
      <Route exact path={props.match.path} component={QuickLinksList} />
      <Route path={`${props.match.path}/form`} component={QuickLinkForm} />
      <Route path={`${props.match.path}/edit/:id`} component={QuickLinkForm} />
    </>
  );
}

QuickLink.propTypes = {
  match: PropTypes.object
};
