import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { fetchPointsConfig } from "../../redux/action/Configuration/PointsConfig/FetchConfigListAction";
import { fetchMissionConfig } from "../../redux/action/Configuration/MissionsConfig/FetchMissionsConfig";
import { fetchCashbackConfig } from "../../redux/action/Configuration/cashbackConfig/FetchCashbackConfig";
// core components
import PointConfig from "./Points";
import MissionConfig from "./Missions";
import ConfigPointUpdate from "./Points/Form";
import ConfigList from "./configListView";
import ConfigMissionsUpdate from "./Missions/Form";
import ErrorBoundary from "../ErrorBoundary";

import CashbackConfig from "./Cashback";
import ConfigCashbackUpdate from "./Cashback/Form";

import SplashScreenConfig from "./SplashScreen";


function Configuration(props) {
  const { fetchPointsConfig, fetchMissionConfig, fetchCashbackConfig } = props;

  useEffect(() => {
    fetchPointsConfig();
    fetchMissionConfig();
    fetchCashbackConfig();
  }, []);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        render={routeProps => (
          <ErrorBoundary>
            <ConfigList {...routeProps} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path={`${props.match.path}/pointConfig`}
        render={routeProps => (
          <ErrorBoundary>
            <PointConfig {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route
        path={`${props.match.path}/pointConfig/edit/PointConfigUpdate`}
        render={routeProps => (
          <ErrorBoundary>
            <ConfigPointUpdate {...routeProps} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path={`${props.match.path}/MissionsConfig`}
        render={routeProps => (
          <ErrorBoundary>
            <MissionConfig {...routeProps} />
          </ErrorBoundary>
        )}
      />

      <Route
        path={`${props.match.path}/MissionsConfig/edit/MissionsConfigUpdate`}
        render={routeProps => (
          <ErrorBoundary>
            <ConfigMissionsUpdate {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={`${props.match.path}/cashBackConfig`}
        render={routeProps => (
          <ErrorBoundary>
            <CashbackConfig {...routeProps} />
          </ErrorBoundary>
        )}
      />

      <Route
        path={`${props.match.path}/cashBackConfig/edit/:id`}
        render={routeProps => (
          <ErrorBoundary>
            <ConfigCashbackUpdate {...routeProps} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path={`${props.match.path}/splashScreenConfig`}
        render={routeProps => (
          <ErrorBoundary>
            <SplashScreenConfig {...routeProps} />
          </ErrorBoundary>
        )}
      />
    </>
  );
}

export default connect(
  null,
  { fetchPointsConfig, fetchMissionConfig, fetchCashbackConfig }
)(Configuration);
Configuration.propTypes = {
  match: PropTypes.object,
  fetchPointsConfig: PropTypes.func,
  fetchMissionConfig: PropTypes.func,
  fetchCashbackConfig: PropTypes.func
};
