import {
  SET_SPECIFIC_CSV_FILE,
  SET_CSV_FILE_NAME,
  RESET_STATE,
  SAVE_CSV_FILE_AS_ARRAY,
  RESET_FILE_STATE
} from "../action/actionType";

const initialState = {
  specificCSV: "",
  fileName: "",
  fileAsArray: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SPECIFIC_CSV_FILE:
      return {
        ...state,
        specificCSV: action.payload
      };

    case SET_CSV_FILE_NAME:
      return {
        ...state,
        fileName: action.payload
      };

    case SAVE_CSV_FILE_AS_ARRAY:
      return {
        ...state,
        fileAsArray: action.payload.split(/\r?\n/).filter(item => item)
      };

    case RESET_FILE_STATE:
      return {
        ...state,
        ...initialState
      };

    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};
