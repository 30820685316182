import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// redux actions
import { setIconImage } from "../../redux/action/uploadImageActions";
// core components
import ErrorsContainer from "./ErrorsContainer";
import { getBase64 } from "./helper";
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const IconUpload = ({ fieldTitle, isRequiredError, iconUrl, setIconImage }) => {
  const classes = useStyles();
  const [uploadErrors, setUploadErrors] = useState({
    isRequired: false,
    extensions: false,
    size: false,
    dimension: false
  });

  useEffect(() => {
    if (isRequiredError) {
      setUploadErrors({ isRequired: true });
    }
  }, [isRequiredError]);

  const handleChangeIcon = e => {
    if (e.target.files.length === 0) return;
    if (/\.(jpg|jpeg|png|webp|svg)$/i.test(e.target.files[0].name)) {
      let sizeImage = Math.round(e.target.files[0].size / 1024);
      if (sizeImage <= 1000) {
        getBase64(e.target.files[0], (result, widthImage, heightImage) => {
          if (widthImage === heightImage) {
            setIconImage(result);
            setUploadErrors(null);
          } else {
            setUploadErrors({
              dimension: true
            });
          }
        });
      } else {
        setUploadErrors({ size: true });
      }
    } else {
      setUploadErrors({ extensions: true });
    }
  };

  return (
    <div className={classes.imageFieldWrapper}>
      <div className={classes.imageViewContainer}>
        {iconUrl && (
          <img src={iconUrl} className={classes.imageIcon} alt="preview" />
        )}
      </div>
      <div className={classes.columnFlexContainer}>
        <Typography
          component="h3"
          variant="h5"
          color="textPrimary"
          className={classes.lightFont}
        >
          {fieldTitle}
          <Typography
            component="span"
            variant="caption"
            color="textPrimary"
            style={{ marginLeft: "2px", verticalAlign: "super" }}
          >
            *
          </Typography>
        </Typography>
        <Typography
          component="p"
          variant="body2"
          color="textPrimary"
          paragraph={true}
          className={classes.lightFont}
        >
          Upload a .png or .jpeg or .jpg or .webp or .svg image with max size of
          1MB.
        </Typography>
        {uploadErrors && <ErrorsContainer errors={uploadErrors} />}
        <TextField
          type="file"
          id="file-icon"
          label="file"
          name="file"
          onChange={handleChangeIcon}
          margin="normal"
          variant="outlined"
          style={{ display: "none" }}
          className={classes.textFieldFile}
        />
        <label htmlFor="file-icon" className={classes.textFieldFile}>
          <CloudUploadIcon />
          <span>Upload Image</span>
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    iconUrl: state.imageUrl.iconUrlMission
  };
};

export default connect(
  mapStateToProps,
  { setIconImage }
)(IconUpload);

IconUpload.propTypes = {
  fieldTitle: PropTypes.string,
  isRequiredError: PropTypes.bool,
  iconUrl: PropTypes.string,
  setIconImage: PropTypes.func
};
