import { OPEN_SNACK_BAR, CLOSE_SNACK_BAR } from "./actionType";

export const openSnackBar = () => {
  return {
    type: OPEN_SNACK_BAR,
    payload: true
  };
};
export const closeSnackBar = () => {
  return {
    type: CLOSE_SNACK_BAR,
    payload: false
  };
};
