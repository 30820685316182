const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    boxShadow: "none"
  },
  logo: {
    margin: "16px 0",
    maxHeight: 64
  }
});

export default styles;
