import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// redux actions
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
import QuestionComponent from "../../QuestionComponent";
import Question from "../../../CsPoints/View/question";
import TransferPoints from "../../TransferPoints";
import { Button } from "@material-ui/core";

import styles from "../../styles.css";
import {
  clearTypeOfTransfer,
  fetchAllBonusPoints,
  fetchAllFraudPoints
} from "../../../../redux/action/PointsSystem/TransferPointsActions";
const useStyles = makeStyles(styles);

const TransferPointsComponent = ({
  pointsSystem,
  clearTypeOfTransfer,
  fetchAllBonusPoints,
  fetchAllFraudPoints
}) => {
  const classes = useStyles();
  const location = useLocation();
  const convertStringToArray = location?.pathname?.split("/");
  function isSame(value) {
    return convertStringToArray.some(item => item.includes(value));
  }
  const showCsPage = isSame("cs-points");
  useEffect(() => {
    fetchAllBonusPoints();
    fetchAllFraudPoints();
  }, []);
  return (
    <CardContainer title="Cs - Point System" style={{ position: "relative" }}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <p style={{ marginLeft: 20 }}>
            <span style={{ fontWeight: 500 }}>Bonus buckets: </span>
            {`${pointsSystem?.csAllPointsBonus?.toLocaleString() || 0} pts`}
          </p>
          <p style={{ marginLeft: 20 }}>
            <span style={{ fontWeight: 500 }}>Fraud buckets: </span>
            {`${pointsSystem?.csAllPointsFraud?.toLocaleString() || 0} pts`}
          </p>
        </GridItem>
        <GridItem xs={12} sm={12}>
          {showCsPage && !pointsSystem.typeOfPointsAction && (
            <Question question="What would you like to do?" />
          )}
          {(pointsSystem.typeOfPointsAction === "Give Points" ||
            !showCsPage) && (
            <QuestionComponent question="Do you want to transfer points to a single user?" />
          )}
          {pointsSystem.typeOfPointsAction === "Take Points" && (
            <QuestionComponent question="Do you want to take points from a single user?" />
          )}
          {pointsSystem.typeOfTransfer && (
            <TransferPoints showCsPage={showCsPage} />
          )}
          {
            <Button
              variant="contained"
              size="medium"
              style={{
                width: "10%",
                margin: "16px",
                position: "absolute",
                top: 153,
                right: 0
              }}
              onClick={clearTypeOfTransfer}
            >
              Reset
            </Button>
          }
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    pointsSystem: state.pointsSystem
  };
};

export default connect(
  mapStateToProps,
  { clearTypeOfTransfer, fetchAllBonusPoints, fetchAllFraudPoints }
)(TransferPointsComponent);

TransferPointsComponent.propTypes = {
  pointsSystem: PropTypes.object,
  checkRequiredFields: PropTypes.func,
  clearCallToActionValues: PropTypes.func,
  clearTypeOfTransfer: PropTypes.func,
  fetchAllBonusPoints: PropTypes.func,
  fetchAllFraudPoints: PropTypes.func
};
