import {
  FETCH_REWARD_API_LIST,
  RESET_REWARD_API_STATE,
  SELECT_USERS_TO_RECHARGE,
  SET_SEARCH_PHONE_NUMBER,
  SET_LOADING_REWARD_LIST,
  SELECT_USERS_TO_Export,
  ERROR_EXPORT,
  RESET_ERROR_EXPORT
} from "../../action/actionType";

const initialState = {
  rewardApiList: [],
  selectedUsers: [],
  hasNext: false,
  phoneNumber: "",
  isLoading: false,
  selectedUsersIds: [],
  errorExport: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REWARD_API_LIST:
      return {
        ...state,
        isLoading: false,
        rewardApiList: action.payload?.data,
        hasNext: action.payload?.meta?.has_next
      };
    case SET_SEARCH_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload
      };
    case SELECT_USERS_TO_RECHARGE:
      return {
        ...state,
        selectedUsers: action.payload
      };

    case RESET_REWARD_API_STATE:
      return {
        ...state,
        selectedUsers: [],
        isLast: false
      };
    case SET_LOADING_REWARD_LIST:
      return {
        ...state,
        isLoading: action.payload
      };
    case SELECT_USERS_TO_Export:
      return {
        ...state,
        selectedUsersIds: action.payload
      };
    case ERROR_EXPORT:
      return {
        ...state,
        errorExport: action.payload || "Something went wrong"
      };
    case RESET_ERROR_EXPORT:
      return {
        ...state,
        errorExport: ""
      };
    default:
      return state;
  }
};
