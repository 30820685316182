import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setTarget } from "../../Actions/FetchDealsList";
// core components
import styles from "../../customTable/styles.css";

const useStyles = makeStyles(styles);

const SelectTarget = ({ targetList, setTarget, targetIdSelected }) => {
  const classes = useStyles();

  const handleSelection = event => {
    const selectedValue = event.target.value;
    // Send selected category name to the reducer
    const currentTarget = targetList.find(item => item.id === selectedValue);

    const targetName = selectedValue !== "none" ? currentTarget.nameEn : "";
    setTarget(selectedValue, targetName);
  };

  return (
    <>
      <TextField
        id="Target-name"
        select
        label="Target Name"
        className={classes.textField}
        name="targetName"
        value={targetIdSelected}
        onChange={handleSelection}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="outlined"
        required
      >
        <option value="none">Select an Option</option>
        {targetList.map(option => (
          <option key={option.id} value={option.id}>
            {option.nameEn}
          </option>
        ))}
      </TextField>
    </>
  );
};

const mapStateToProps = state => {
  return {
    targetList: state.targetList.list,
    targetIdSelected: state.dealsList.targetIdSelected,
    targetNameSelected: state.dealsList.targetNameSelected
  };
};

export default connect(
  mapStateToProps,
  { setTarget }
)(SelectTarget);

SelectTarget.propTypes = {
  merchantsList: PropTypes.arrayOf(PropTypes.object)
};
