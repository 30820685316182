import {
  UPDATE_ORDER,
  UPDATE_NAME_EN,
  UPDATE_NAME_AR,
  UPDATE_LOGO_URL,
  UPDATE_URL,
  UPDATE_DESCRIPTION_EN,
  UPDATE_DESCRIPTION_AR,
  UPDATE_IS_POPULAR,
  UPDATE_COUNTRIES,
  UPDATE_TARGET_URLS,
  UPDATE_TNC_EN,
  UPDATE_TNC_AR
} from "../../actions/local";
import configurations from "../../../../../../configurations";

const initialState = {
  order: 1,
  nameEn: "",
  nameAr: "",
  logoUrl: null,
  url: "",
  descriptionEn: "",
  descriptionAr: "",
  isPopular: false,
  countries:
    configurations.application.countries.length === 1
      ? configurations.application.countries.map(country => country.code)
      : [],
  targetUrls: [
    {
      url: "",
      country:
        configurations.application.countries.length === 1
          ? configurations.application.countries[0].code
          : ""
    }
  ],
  tncEn: "",
  tncAr: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORDER:
      return {
        ...state,
        order: action.payload.order
      };
    case UPDATE_NAME_EN:
      return {
        ...state,
        nameEn: action.payload.nameEn
      };
    case UPDATE_NAME_AR:
      return {
        ...state,
        nameAr: action.payload.nameAr
      };
    case UPDATE_LOGO_URL:
      return {
        ...state,
        logoUrl: action.payload.logoUrl
      };
    case UPDATE_URL:
      return {
        ...state,
        url: action.payload.url
      };
    case UPDATE_DESCRIPTION_EN:
      return {
        ...state,
        descriptionEn: action.payload.descriptionEn
      };
    case UPDATE_DESCRIPTION_AR:
      return {
        ...state,
        descriptionAr: action.payload.descriptionAr
      };
    case UPDATE_IS_POPULAR:
      return {
        ...state,
        isPopular: action.payload.isPopular
      };
    case UPDATE_COUNTRIES:
      return {
        ...state,
        countries: action.payload.countries
      };
    case UPDATE_TARGET_URLS:
      return {
        ...state,
        targetUrls: action.payload.targetUrls
      };
    case UPDATE_TNC_EN:
      return {
        ...state,
        tncEn: action.payload.tncEn
      };
    case UPDATE_TNC_AR:
      return {
        ...state,
        tncAr: action.payload.tncAr
      };
    default:
      return state;
  }
};
