import merchantsConfig from "./merchants/merchantsConfig";
import dealsConfig from "./deals/dealsConfig";
import categoriesConfig from "./categories/categoriesConfig";

export default {
  routes: [
    {
      routes: [...merchantsConfig.routes, ...dealsConfig.routes, ...categoriesConfig.routes]
    }
  ]
};
