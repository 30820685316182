import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// redux actions
import {
  setMissionOrder,
  setMissionIsFeatured,
  setMissionUrlPrams
} from "../../../../redux/action/Missions/MissionsDefinitionActions";
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
import ImageUpload from "../../../ImageUpload";
import IconUpload from "../../../ImageUpload/iconUpload";
import MissionDescription from "./MissionDescription";
// import MissionCategory from "./MissionCategory";
import styles from "../../styles.css";
import { content } from "../MissionsConfig";
import MissionSection from "./MissionSection";
import MissionType from "./MissionType";
const useStyles = makeStyles(styles);

const MissionDefinition = ({
  missions,
  imageUrl,
  checkRequiredFields,
  setMissionOrder,
  setMissionIsFeatured,
  iconUrl,
  viewType,
  setMissionUrlPrams
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const handleOrderChange = event => {
    setMissionOrder(event.target.value);
  };

  const handleUrlParams = event => {
    const checked = /[\W_]/g.test(event.target.value);
    setError(checked);

    setMissionUrlPrams(event.target.value);
  };

  useEffect(() => {
    if (
      imageUrl &&
      iconUrl &&
      missions.englishTitle &&
      missions.englishDescription &&
      missions.arabicTitle &&
      missions.arabicDescription &&
      // missions.categoryId &&
      missions.sectionId &&
      missions.missionTypeId &&
      missions.urlParams &&
      !error
    ) {
      checkRequiredFields(true);
    } else {
      checkRequiredFields(false);
    }
  }, [missions, imageUrl, iconUrl, error]);

  return (
    <CardContainer title={`1. ${content.stepsTitle[0]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <Grid className={`${classes.container} ${classes.marginBlock}`}>
            <Grid className={`${classes.container} ${classes.marginBlock}`}>
              <GridItem xs={6} sm={6}>
                <ImageUpload fieldTitle="Mission card image" largeImg={true} />
              </GridItem>
              <GridItem xs={6} sm={6}>
                <IconUpload fieldTitle="Mission icon image" />
              </GridItem>
            </Grid>
            <MissionDescription />

            <TextField
              id="mission-order"
              label="Mission order"
              type="number"
              className={classes.textField}
              value={missions.order}
              onChange={handleOrderChange}
              helperText="Mission order in the carousel"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: 1
              }}
            />

            {/* <MissionCategory /> */}
            <MissionSection />
            <MissionType viewType={viewType} />
            <TextField
              id="english-urlParams"
              name="urlParams"
              label="URL Params"
              className={classes.textField}
              value={missions.urlParams}
              onChange={handleUrlParams}
              error={error}
              helperText="No spaces and special character"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
            />
            <FormControlLabel
              className={classes.checkboxField}
              control={
                <Checkbox
                  name="isFeatured"
                  onChange={setMissionIsFeatured}
                  color="primary"
                  checked={missions.isFeatured}
                />
              }
              label="Featured"
            />
          </Grid>
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    missions: state.missions,
    imageUrl: state.imageUrl.url,
    iconUrl: state.imageUrl.iconUrlMission
  };
};

export default connect(
  mapStateToProps,
  {
    checkRequiredFields,
    setMissionOrder,
    setMissionIsFeatured,
    setMissionUrlPrams
  }
)(MissionDefinition);

MissionDefinition.propTypes = {
  missions: PropTypes.object,
  imageUrl: PropTypes.string,
  checkRequiredFields: PropTypes.func,
  setMissionOrder: PropTypes.func,
  setMissionUrlPrams: PropTypes.func,
  setMissionIsFeatured: PropTypes.func,
  iconUrl: PropTypes.string
};
