import {
  SET_QUICK_LINK_DEFINITION,
  SET_IS_FEATURED,
  SET_IS_NATIVE,
  EDIT_QUICK_LINKS,
  RESET_STATE
} from "../../action/actionType";

const initialState = {
  englishName: "",
  arabicName: "",
  order: 0,
  url: "",
  isFeatured: false,
  isNative: false,
  categoryId: "none",
  id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_QUICK_LINK_DEFINITION:
      return {
        ...state,
        englishName: action.payload.englishName,
        arabicName: action.payload.arabicName,
        order: parseInt(action.payload.order),
        url: action.payload.url,
        categoryId: action.payload.categoryId
      };

    case SET_IS_FEATURED:
      return {
        ...state,
        isFeatured: !state.isFeatured
      };

    case SET_IS_NATIVE:
      return {
        ...state,
        isNative: !state.isNative
      };

    case EDIT_QUICK_LINKS:
      return {
        ...state,
        englishName: action.payload.name.en,
        arabicName: action.payload.name.ar,
        order: parseInt(action.payload.order) || 0,
        url: action.payload.url,
        categoryId: action.payload.category_id,
        isFeatured: action.payload.isFeatured,
        isNative: action.payload.isNative,
        id: action.payload.id || action.payload._id
      };

    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};
