import { FETCH_ROLES_OF_USERS } from "./actionType";
import { rolesUsersBaseUrl, authURL } from "../../components/config";

export const fetchRolesOfUsers = () => async dispatch => {
  await rolesUsersBaseUrl
    .get("/roles")
    .then(res => {
      dispatch({
        type: FETCH_ROLES_OF_USERS,
        payload: res.data
      });
    })
    .catch(() => {
      window.location.assign(authURL);
    });
};

export const assignRolesOfUsers = roles => {
  return {
    type: FETCH_ROLES_OF_USERS,
    payload: roles
  };
};
