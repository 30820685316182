import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// redux actions
import { checkRequiredFields } from "../../../../redux/action/checkRequiredFieldsActions";
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem.js";
import CardContainer from "../../../CardContainer";
import ButtonTextComponent from "./ButtonTextComponent";
import UrlComponent from "./UrlComponent";
import styles from "../../styles.css";
import { content } from "../MissionsConfig";

const useStyles = makeStyles(styles);

const CallToActionComponent = ({ missions, checkRequiredFields, viewType }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (missions.btnEnglishTextSelection === "Other") {
      if (
        missions.btnEnglishText &&
        missions.btnArabicText &&
        missions.btnUrl &&
        !error
      ) {
        checkRequiredFields(true);
      }
    } else {
      checkRequiredFields(false);
      if (
        missions.btnEnglishTextSelection &&
        missions.btnArabicTextSelection &&
        missions.btnUrl &&
        !error
      ) {
        checkRequiredFields(true);
      }
    }
  }, [missions]);

  return (
    <CardContainer title={`3. ${content.stepsTitle[2]}`}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12}>
          <ButtonTextComponent viewType={viewType} />
          <UrlComponent setError={setError} error={error} />
        </GridItem>
      </GridContainer>
    </CardContainer>
  );
};

const mapStateToProps = state => {
  return {
    missions: state.missions
  };
};

export default connect(
  mapStateToProps,
  { checkRequiredFields }
)(CallToActionComponent);

CallToActionComponent.propTypes = {
  missions: PropTypes.object,
  checkRequiredFields: PropTypes.func,
  viewType: PropTypes.string
};
