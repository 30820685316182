import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import red from '@material-ui/core/colors/red';
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import styles from "./ActionButtons.css";
import ConfirmModal from "../Modals/ConfirmModal";

const useStyles = makeStyles(styles);

function ActionButtons(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (props.handleRecordsState) {
      props.handleRecordsState();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteRecord = id => {
    if (props.countrySelected) {
      props.handleDelete(id, props.countrySelected);
      handleClose();
      return;
    }
    props.handleDelete(id);
    handleClose();
  };

  return (
    <div className="buttons-container">
      {props.editButton && (
        <Fab
          size="small"
          color="primary"
          aria-label="edit"
          className={classes.fab}
        >
          {!props.item.missionTasks ? (
            <Link
              to={{
                pathname: `${props.match.url}/edit/${props.item._id ||
                  props.item.id ||
                  props.typeFieldConfig}`,
                state: {
                  item: props.item,
                  adsItemId: props.adsId || "",
                  adsItem: props.adsItem || "",
                  viewType: "Edit"
                }
              }}
              className={classes.editLink}
            >
              <EditIcon className={classes.editIcon} />
            </Link>
          ) : (
            <EditIcon
              className={classes.editIcon}
              onClick={props.handleEditTasks}
            />
          )}
        </Fab>
      )}
      {props.deleteButton && (
        <Fab
          size="small"
          color="secondary"
          aria-label="add"
          className={classes.fab}
        >
          <DeleteIcon onClick={handleOpen} />
        </Fab>
      )}
      {open && (
        <ConfirmModal
          Id={props.item._id || props.ItemId || props.item.id}
          open={open}
          handleDelete={deleteRecord}
          onClose={handleClose}
          name={props?.item?.nameEn || props?.item?.titleEn}
          message={props.message}
        />
      )}
    </div>
  );
}

export default withRouter(ActionButtons);

ActionButtons.propTypes = {
  match: PropTypes.object,
  item: PropTypes.object,
  RedirectionLink: PropTypes.string,
  handleDelete: PropTypes.func,
  handleRecordsState: PropTypes.func,
  ItemId: PropTypes.string,
  adsId: PropTypes.number,
  deleteButton: PropTypes.bool,
  editButton: PropTypes.bool,
  idType: PropTypes.number,
  adsItem: PropTypes.object,
  message: PropTypes.string
};
