import UserDataDeletionStatus from "./UserDataDeletionStatus";
import { userDataDeletionStatusPath } from "./paths";

export default {
  routes: [
    {
      routes: [
        {
          path: userDataDeletionStatusPath,
          component: UserDataDeletionStatus,
          layout: "blank",
          roles: []
        }
      ]
    }
  ]
};
