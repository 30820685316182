import Moment from "moment";

export const Validation = {
  categoryValidation: function(str) {
    let categoryType = [
      "Fashion",
      "Health & Beauty",
      "Supermarket",
      "Mobiles & Tablets",
      "Electronics",
      "TVs",
      "Home Accessories",
      "Appliances",
      "Toys",
      "Moms & Babies",
      "Sports",
      "Automotive",
      "Office",
      "Gaming",
      "Education",
      "Travel"
    ];
    const theSame = element =>
      this.isSame(element?.toLowerCase(), str?.toLowerCase());

    return !categoryType.some(theSame);
  },

  dealTypeValidation: function(str) {
    let dealType = ["discount", "cash back"];
    const theSame = element =>
      this.isSame(element?.toLowerCase(), str?.toLowerCase());

    return !dealType.some(theSame);
  },
  dealActivationTypeValidation: function(str) {
    let dealActivationType = ["promo code", "affiliate url"];
    const theSame = element =>
      this.isSame(element?.toLowerCase(), str?.toLowerCase());

    return !dealActivationType.some(theSame);
  },
  dealSourceValidation: function(str) {
    let dealSource = ["admitad", "ArabiClicks"];
    const theSame = element =>
      this.isSame(element?.toLowerCase(), str?.toLowerCase());

    return !dealSource.some(theSame);
  },
  discountTypeValidation: function(str) {
    let discountType = ["fixed", "percentage"];
    const theSame = element =>
      this.isSame(element?.toLowerCase(), str?.toLowerCase());

    return !discountType.some(theSame);
  },
  isNotEmpty: function(str) {
    var pattern = new RegExp("/S+/");
    return !pattern.test(str); // returns a boolean
  },
  isNumber: function(str) {
    let checkIsNumber = +str === parseInt(+str, 10);

    return !checkIsNumber; // returns a boolean
  },
  urlValidation: function(url) {
    let expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

    let regex = new RegExp(expression);
    let result = regex.test(url);
    return !result;
  },
  tagsValidation: function(str) {
    let expression = /^\s*$/m;
    expression = /^[a-zA-Z.,\b]+$/;
    let regex = new RegExp(expression);

    let itemArray = str
      .trim()
      .split("-")
      .filter(item => item);

    return {
      isValid: !itemArray.every(i => regex.test(i)),
      items: itemArray
    };
  },
  dateValidation: function(date) {
    let convertDate = Moment(date, "YYYY-MM-DD", true);

    let isValidDate = convertDate.isValid();
    return !isValidDate;
  },
  isSame: function(str1, str2) {
    return str1?.toLowerCase() === str2?.toLowerCase();
  }
};
