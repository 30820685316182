import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
// core components
import CreateProjectSteps from "./CreateProjectSteps";
import { restProjectState } from "../../../../redux/action/WaslaRewards/UsersLogsActions";
import CustomModal from "../../Modal";

function CreateProject(props) {
  const { open, closeModal, restProjectState } = props;

  useEffect(() => {
    return () => {
      restProjectState();
    };
  }, []);

  return (
    <CustomModal open={open} title="Project Setup" close={closeModal}>
      <CreateProjectSteps onCloseModal={closeModal} />
    </CustomModal>
  );
}

export default connect(
  null,
  { restProjectState }
)(CreateProject);

CreateProject.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  restProjectState: PropTypes.func
};
