import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { updateItems } from "../../../main/deals/categories/actions/list";
import { updateFilterBy } from "../../../main/deals/categories/actions/filtration";

import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import WaslaTheme from "../../../modules/wasla-theme";
import configurations from "../../../configurations";
import CategoriesTable from "./table/CategoriesTable";
import Loader from "../../../components/loader";

import * as dealsService from "../../../services/deals";
import { dealsCategoriesAddPath } from "../../../main/deals/categories/add-n-edit/add/paths";

const Categories = props => {
  const {
    categories: {
      list: { items },
      filtration: { filterBy }
    },
    updateItems,
    updateFilterBy
  } = props;
  const [loading, setLoading] = useState(true);

  const retrieveCategories = async filterBy => {
    setLoading(true);
    const active = filterBy && filterBy === "active";
    const res = await dealsService.getCategories(active);
    updateItems(res);
    setLoading(false);
  };

  const updateCategories = category => {
    dealsService.updateCategory(category);
  };

  useEffect(() => {
    if (filterBy === "") updateFilterBy(null);
    else if (filterBy !== null) retrieveCategories(filterBy);
    else retrieveCategories();
  }, [filterBy]);

  const reorderCategories = categories => {
    categories.forEach(async (category, index) => {
      const orderedCategory = { ...category, order: index + 1 };
      delete orderedCategory.orderNo;
      await updateCategories(orderedCategory);
    });
  };

  const filteringKeys = [
    { id: null, label: "Filter By" },
    { id: "", label: "All" },
    { id: "paused", label: "Paused" },
    { id: "active", label: "Active" }
  ];

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, "/Deals&Merchant"].join(
              ""
            )}
          >
            Deals
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            Categories
          </Typography>
        </Breadcrumbs>
        <Stack
          spacing={4}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControl>
            <Select
              value={filterBy}
              onChange={e => updateFilterBy(e.target.value)}
              displayEmpty
              id="filter-by-dropdown"
              inputProps={{
                id: "filter-by"
              }}
              MenuProps={{
                id: "filter-by-menu"
              }}
            >
              {filteringKeys.map(filteringKey => (
                <MenuItem
                  key={filteringKey.id}
                  value={filteringKey.id}
                  disabled={filteringKey.id === null}
                >
                  {filteringKey.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Link
            underline="none"
            color="inherit"
            href={[
              configurations.dashboard.basePath,
              dealsCategoriesAddPath
            ].join("")}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ textTransform: "capitalize" }}
            >
              Create Category
            </Button>
          </Link>
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <CategoriesTable
            categories={items}
            reorderCategories={reorderCategories}
            tableName={filterBy !== null ? `All ${filterBy}` : "All Categories"}
          />
        )}
      </Stack>
    </WaslaTheme>
  );
};

export default connect(
  state => ({ categories: state.categories }),
  { updateItems, updateFilterBy }
)(Categories);
