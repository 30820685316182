import UserDeletionStatus from "./UserDeletionStatus";
import { userDeletionStatusPath } from "./paths";

export default {
  routes: [
    {
      routes: [
        {
          path: userDeletionStatusPath,
          component: UserDeletionStatus,
          layout: "blank",
          roles: []
        }
      ]
    }
  ]
};
