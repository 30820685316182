import Deals from "./Deals";
import { dealsDealsPath } from "./paths";

export default {
  routes: [
    {
      path: dealsDealsPath,
      component: Deals,
      layout: "main",
      isPrivate: false,
      roles: ["admin"],
      routes: []
    }
  ]
};
