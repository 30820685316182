import React, { useRef, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

import FormCard from "../../../../components/FormCard";
import FormCardBody from "../../../../components/FormCardBody";
import UploadContainer from "../../../../components/UploadContainer";

import {
  UpdateSplashScreenConfig,
  uploadSplashScreenConfig,
  getSplashScreensRemoteValues
} from "../../../../redux/action/Configuration/splashScreen/SplashScreenConfigAction";

import avatar from "./assets/avatar.png";

import {
  getValue,
  fetchAndActivate
} from "../../../../services/firebase/remote-config";

const SplashScreenForm = props => {
  const {
    splashScreens,
    uploadSuccess,
    UpdateSplashScreenConfig,
    uploadSplashScreenConfig,
    getSplashScreensRemoteValues
  } = props;

  const splashUrlElementAr = useRef(null);
  const splashUrlElementEn = useRef(null);

  const [splashUrlFileAr, setSplashUrlFileAr] = useState(null);
  const [splashUrlFileEn, setSplashUrlFileEn] = useState(null);

  const [remoteSplashScreens, setRemoteSplashScreens] = useState(null);

  useEffect(async () => {
    await fetchAndActivate();
    const splashEn = await getValue("splash_screen_url_en");
    const splashAr = await getValue("splash_screen_url_ar");
    await setRemoteSplashScreens({
      en: splashEn._value,
      ar: splashAr._value
    });
  }, []);

  useEffect(() => {
    if (remoteSplashScreens) getSplashScreensRemoteValues(remoteSplashScreens);
  }, [remoteSplashScreens]);

  useEffect(() => {
    setSplashUrlFileAr(splashScreens.ar);
    setSplashUrlFileEn(splashScreens.en);
  }, [splashScreens]);

  useEffect(() => {
    if (splashScreens.ar && uploadSuccess.ar)
      UpdateSplashScreenConfig(splashScreens.ar, "ar");
  }, [splashScreens.ar, uploadSuccess.ar]);

  useEffect(() => {
    if (splashScreens.en && uploadSuccess.en)
      UpdateSplashScreenConfig(splashScreens.en, "en");
  }, [splashScreens.en, uploadSuccess.en]);

  const uploadSplashImage = (type, file) => {
    uploadSplashScreenConfig(type, file);
  };

  const handleSplashUrlClickAr = e => {
    splashUrlElementAr.current.click();
  };
  const handleSplashUrlClickEn = e => {
    splashUrlElementEn.current.click();
  };

  const handleSplashUrlChangeAr = e => {
    if (e.target.files && e.target.files[0]) {
      uploadSplashImage("ar", e.target.files[0]);
    }
  };

  const handleSplashUrlChangeEn = e => {
    if (e.target.files && e.target.files[0]) {
      uploadSplashImage("en", e.target.files[0]);
    }
  };

  return (
    <Box>
      <Stack spacing={4}>
        <FormCard>
          <FormCardBody sx={{ padding: "50px" }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Stack spacing={2.5} direction="row" justifyContent="stretch">
                  <Avatar
                    alt={"en"}
                    src={splashUrlFileEn || avatar}
                    sx={{
                      width: 64,
                      height: 64,
                      textTransform: "uppercase"
                    }}
                  ></Avatar>
                  <UploadContainer
                    handleLogoUrlClick={handleSplashUrlClickEn}
                    logoUrlElement={splashUrlElementEn}
                    logoUrl={""}
                    handleLogoUrlChange={handleSplashUrlChangeEn}
                    accept={".svg"}
                    label1="Image Format must be in SVG"
                    label2="Upload for English version"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2.5} direction="row" justifyContent="stretch">
                  <Avatar
                    alt={"ar"}
                    src={splashUrlFileAr || avatar}
                    sx={{
                      width: 64,
                      height: 64,
                      textTransform: "uppercase"
                    }}
                  ></Avatar>
                  <UploadContainer
                    handleLogoUrlClick={handleSplashUrlClickAr}
                    logoUrlElement={splashUrlElementAr}
                    logoUrl={""}
                    handleLogoUrlChange={handleSplashUrlChangeAr}
                    accept={".svg"}
                    label1="Image Format must be in SVG"
                    label2="Upload for Arabic version"
                  />
                </Stack>
              </Grid>
            </Grid>
          </FormCardBody>
        </FormCard>
      </Stack>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    splashScreens: state.configSplashScreens.splashScreens,
    uploadSuccess: state.configSplashScreens.uploadSuccess
  };
};

export default connect(
  mapStateToProps,
  {
    UpdateSplashScreenConfig,
    uploadSplashScreenConfig,
    getSplashScreensRemoteValues
  }
)(SplashScreenForm);

SplashScreenForm.propTypes = {
  splashScreens: PropTypes.object,
  uploadSuccess: PropTypes.object
};
