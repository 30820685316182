const initialState = {
  cashbackItems: [],
  selectedItems: [],
  hasNext: false,
  searchType: "",
  isLoading: false,
  error: "",
  totalPages: 0,
  limit: 10,
  success: false,
  totalCashBacksValue: [],
  requestListSelected: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CASHBACK_Table":
      return {
        ...state,
        isLoading: false,
        cashbackItems: action.payload?.data,
        hasNext: action.payload?.meta?.hasNext,
        totalPages: action.payload?.meta?.total,
        limit: action.payload?.meta?.limit || state.limit
      };
    case "SET_SEARCH_ID":
      return {
        ...state,
        searchType: action.payload
      };
    case "RESET_CASHBACK_STATE":
      return {
        ...state,
        selectedUsers: [],
        isLast: false
      };
    case "SET_LOADING_CASHBACK_TABLE":
      return {
        ...state,
        isLoading: action.payload
      };
    case "SET_LOADING_CASHBACK_TABLE_ERROR":
      return {
        ...state,
        error: action.payload
      };
    case "APPROVE_SUCCESS":
      return {
        ...state,
        success: action.payload
      };
    case "SELECTED_ROWS":
      const totalCashBack = action.payload?.reduce(
        (accumulator, currentValue) => {
          const totalCashBacks =
            currentValue.cashbackValue + (accumulator.totalCashBacks || 0);
          const totalPayout =
            currentValue.payout + (accumulator.totalPayout || 0);
          return {
            totalCashBacks,
            totalPayout
          };
        },
        {}
      );
      return {
        ...state,
        selectedItems: action.payload,
        totalCashBacksValue: totalCashBack
      };
    case "REST_SELECTED_ROWS":
      return {
        ...state,
        selectedItems: []
      };
    case "SELECT_VALUE": {
      return {
        ...state,
        idSelected: action.payload?.id,
        nameSelected: action.payload?.name,
        requestListSelected: state.selectedItems?.map(item => {
          return {
            id: item.id,
            status: action.payload?.name
          };
        })
      };
    }
    case "REST_SELECTED_VALUE": {
      return {
        ...state,
        idSelected: "",
        nameSelected: "",
        requestListSelected: []
      };
    }
    case "RESET_ERROR":
      return {
        ...state,
        error: ""
      };
    default:
      return state;
  }
};
