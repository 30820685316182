const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  columnFlexContainer: {
    display: "flex",
    flexDirection: "column"
  },
  marginBlock: {
    marginBlockEnd: "16px",
    marginBlockStart: "24px"
  },
  cardLinkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardLinkAdd: {
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    padding: "10px 25px",
    borderRadius: "5px",
    color: "#fff",
    border: "1px solid transparent",
    fontWeight: "500",
    "&:hover": {
      background: "transparent",
      color: "#ffa726",
      border: "1px solid #ffa726"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: theme.spacing(0),
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  formHeader: {
    marginBottom: theme.spacing(4)
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0),
    width: "47%"
  },
  radioBtnContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 24,
    marginLeft: 18
  },
  radioBtnQuestion: {
    marginTop: 0,
    fontSize: "18px"
  },
  radioBtnIcon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    root: {
      "&.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2
      }
    },
    "input:hover ~ &": {
      backgroundColor: "#ffa726"
    }
  },
  checkedIcon: {
    backgroundColor: "#ffa726",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#ffa726"
    }
  },
  textFieldFile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "130px",
    lineHeight: "40px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: "4px",
    textAlign: "center",
    borderRadius: "5px",
    color: "#fff",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)"
  },
  customContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& .announcementCardContainer": {
      height: "85%"
    },
    "& .buttons-container": {
      alignSelf: "center",
      marginTop: "30px",
      flexBasis: "10%"
    }
  },
  buttonExport: {
    textTransform: "capitalize",
    backgroundColor: "#3f51b5",
    color: "#fff",
    border: "1px solid #3f51b5",
    "&:hover": {
      color: "#3f51b5",
      backgroundColor: "transparent"
    }
  },
  checkboxField: {
    display: "block",
    width: "100%",
    margin: "12px"
  }
});
export default styles;
