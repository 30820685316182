import { UPDATE_REASONS, ADD_REASON } from "../../actions/reasons";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REASONS:
      return action.payload.reasons;
    case ADD_REASON:
      return [...state, action.payload.reason];
    default:
      return state;
  }
};
