import React, { Component } from "react";

class ErrorBoundary extends Component {
  state = { error: null };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.error) {
      // Error path
      return (
        <div
          style={{
            position: "absolute",
            top: "8.9em",
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0, 0, 0, 0.75)"
          }}
        >
          <div
            style={{
              width: "75%",
              backgroundColor: "#fff",
              border: "1px solid red",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              fontSize: "1em",
              fontWeight: "bold",
              fontFamily: "inherit",
              textTransform: "capitalize",
              padding: "10px"
            }}
          >
            <p>Process Incomplete</p>
            <p>Please try again later.</p>
          </div>
        </div>
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
