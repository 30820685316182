import {
  UPDATE_SPLASH_SCREEN,
  UPDATE_SPLASH_SCREEN_TYPE,
  UPDATE_SPLASH_SCREEN_SUCCESS
} from "../../actionType";
import { updateRemoteConfig } from "services/remote-config";
import { uploadImage } from "services/upload-image";

export const getSplashScreensRemoteValues = data => async dispatch => {
  dispatch({
    type: UPDATE_SPLASH_SCREEN,
    payload: data
  });
  dispatch({
    type: UPDATE_SPLASH_SCREEN_SUCCESS,
    payload: { en: false, ar: false }
  });
};

export const uploadSplashScreenConfig = (type, file) => async dispatch => {
  await uploadImage(file)
    .then(res => {
      dispatch({
        type: UPDATE_SPLASH_SCREEN_TYPE,
        payload: { [type]: res.data.location }
      });
      dispatch({
        type: UPDATE_SPLASH_SCREEN_SUCCESS,
        payload: { [type]: true }
      });
    })
    .catch(err => {
      console.log("err", err);
    });
};

export const UpdateSplashScreenConfig = (value, type) => async dispatch => {
  let data = { valueType: "STRING" };
  if (type === "ar") {
    data = {
      ...data,
      key: "splash_screen_url_ar",
      value,
      description: "The splash screen in Arabic version"
    };
  } else {
    data = {
      ...data,
      key: "splash_screen_url_en",
      value,
      description: "The splash screen in English version"
    };
  }
  await updateRemoteConfig(data);
};
