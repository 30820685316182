import {
  FETCH_RECHARGE_VALUES,
  SELECT_RECHARGE_VALUE,
  RESET_RECHARGE_USERS_STATE,
  SUBMIT_USERS_RECHARGE,
  USERS_RECHARGE_SUCCESS,
  USERS_RECHARGE_FAILED
} from "../../action/actionType";

const initialState = {
  rechargeValues: [],
  selectedRechargeValue: 0,
  isLoading: false,
  error: false,
  successAll: false,
  successSome: false,
  totalRechargeUsers: {},
  success: false,
  serverError: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECHARGE_VALUES:
      return {
        ...state,
        rechargeValues: action.payload
      };

    case SELECT_RECHARGE_VALUE:
      return {
        ...state,
        selectedRechargeValue: action.payload
      };

    case SUBMIT_USERS_RECHARGE:
      return {
        ...state,
        isLoading: true,
        error: false,
        successAll: false,
        successSome: false,
        totalRechargeUsers: {}
      };

    case USERS_RECHARGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successAll: action.payload?.notChargedPhoneNumbers?.length === 0,
        successSome: action.payload?.notChargedPhoneNumbers?.length > 0,
        totalRechargeUsers: action.payload,
        success: true
      };

    case USERS_RECHARGE_FAILED:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        error: action.payload ?? true
      };
    case "SET_ERROR_SERVER_RECHARGE": {
      return {
        ...state,
        serverError: action.payload
      };
    }
    case RESET_RECHARGE_USERS_STATE:
      return {
        ...initialState,
        rechargeValues: state.rechargeValues
      };

    default:
      return state;
  }
};
