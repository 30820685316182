import {
  SET_ANNOUNCEMENT_CARD_DEFINITION,
  SET_CALL_TO_ACTION,
  CLEAR_CTA_VALUES,
  SET_CTA_BUTTON_TEXT,
  SET_CTA_BUTTON_TYPE,
  SET_BUTTON_URL,
  SET_PWA_TYPE,
  SET_IN_APP_TYPE,
  SET_CARD_THEME,
  SET_NUMBER_OF_IMPRESSIONS,
  SET_TARGET_TYPE,
  SET_CARD_LOCATION,
  SET_CARD_POSITION,
  SUBMIT_ANNOUNCEMENT_CARD_DATA,
  RESET_STATE,
  EDIT_ANNOUNCEMENT_CARDS,
  EDIT_ANNOUNCEMENT_CARDS_DATA
} from "../../action/actionType";

const initialState = {
  englishTitle: "",
  englishSubtitle: "",
  arabicTitle: "",
  arabicSubtitle: "",
  callToActionBtn: "",
  btnEnglishText: "",
  btnArabicText: "",
  ctaBtnType: "",
  btnUrl: "",
  pwaType: "",
  inAppType: "",
  cardTheme: 0,
  numberOfImpressions: 1,
  targetType: "",
  cardLocation: "",
  cardPosition: 1,
  redirect: false,
  cardId: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANNOUNCEMENT_CARD_DEFINITION:
      return {
        ...state,
        englishTitle: action.payload.englishTitle,
        englishSubtitle: action.payload.englishSubtitle,
        arabicTitle: action.payload.arabicTitle,
        arabicSubtitle: action.payload.arabicSubtitle
      };

    case SET_CALL_TO_ACTION:
      return {
        ...state,
        callToActionBtn: action.payload
      };

    case CLEAR_CTA_VALUES:
      return {
        ...state,
        btnEnglishText: "",
        btnArabicText: "",
        ctaBtnType: "",
        btnUrl: "",
        pwaType: "",
        inAppType: ""
      };

    case SET_CTA_BUTTON_TYPE:
      return {
        ...state,
        ctaBtnType: action.payload,
        btnUrl: "",
        pwaType: "",
        inAppType: ""
      };

    case SET_CTA_BUTTON_TEXT:
      return {
        ...state,
        btnEnglishText: action.payload.btnEnglishText,
        btnArabicText: action.payload.btnArabicText
      };

    case SET_BUTTON_URL:
      return {
        ...state,
        btnUrl: action.payload
      };

    case SET_PWA_TYPE:
      return {
        ...state,
        pwaType: action.payload
      };

    case SET_IN_APP_TYPE:
      return {
        ...state,
        inAppType: action.payload
      };

    case SET_CARD_THEME:
      return {
        ...state,
        cardTheme: action.payload
      };

    case SET_NUMBER_OF_IMPRESSIONS:
      return {
        ...state,
        numberOfImpressions: Number(action.payload)
      };

    case SET_TARGET_TYPE:
      return {
        ...state,
        targetType: action.payload
      };

    case SET_CARD_LOCATION:
      return {
        ...state,
        cardLocation: action.payload
      };

    case SET_CARD_POSITION:
      return {
        ...state,
        cardPosition: Number(action.payload)
      };

    case SUBMIT_ANNOUNCEMENT_CARD_DATA:
      return {
        ...state,
        redirect: true
      };

    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };

    case EDIT_ANNOUNCEMENT_CARDS:
      return {
        englishTitle: action.payload.title.en,
        englishSubtitle: action.payload.body.en,
        arabicTitle: action.payload.title.ar,
        arabicSubtitle: action.payload.body.ar,
        callToActionBtn: action.payload.btn.action.type ? "Yes" : "No",
        btnEnglishText: action.payload.btn.text.en || "",
        btnArabicText: action.payload.btn.text.ar || "",
        ctaBtnType: action.payload.btn.action.type,
        btnUrl:
          action.payload.btn.action.type === "url"
            ? action.payload.btn.action.url
            : "",
        pwaType:
          action.payload.btn.action.type === "pwa"
            ? action.payload.btn.action.url
            : "",
        inAppType:
          action.payload.btn.action.type === "inApp"
            ? action.payload.btn.action.url
            : "",
        cardTheme: action.payload.theme._id || action.payload.theme.id,
        numberOfImpressions: action.payload.numOfImpressions,
        targetType: action.payload.targetType,
        cardLocation: action.payload.location,
        cardPosition: action.payload.position,
        cardId: action.payload._id || action.payload.id
      };

    case EDIT_ANNOUNCEMENT_CARDS_DATA:
      return {
        ...state,
        redirect: true
      };

    default:
      return state;
  }
};
