import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import styles from "../../WaslaRewards/SearchBar/style.css";
import { Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SelectCountry from "../../CountryComponent";

const useStyles = makeStyles(styles);

const TableToolbar = props => {
  const classes = useStyles();
  const { title, BtnType, handleOpenModal, type } = props;

  return (
    <>
      <Toolbar className={classes.toolbarRoot}>
        <div
          style={{
            flex: "1 1 50%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="h2"
            style={{
              fontWeight: 500
            }}
          >
            {title}
          </Typography>
          <div
            style={{
              display: "flex",
              flexBasis: "40%",
              justifyContent: `${type === "deal" ? "center" : "flex-end"}`
            }}
          >
            {type === "deal" && <SelectCountry width={35} />}

            <Button
              variant="contained"
              className={`${classes.defaultButton}`}
              onClick={() => handleOpenModal()}
              style={{
                width: "200px",
                display: "flex",
                justifyContent: "space-around",
                backgroundColor: "#fff"
              }}
            >
              <AddCircleOutlineIcon /> <span>{BtnType}</span>
            </Button>
          </div>
        </div>
      </Toolbar>
    </>
  );
};

export default TableToolbar;

TableToolbar.propTypes = {
  title: PropTypes.string
};
