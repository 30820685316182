import { FETCH_MISSIONS_PROMO_CODES } from "../../action/actionType";

const initialState = {
  missionsPromoCodes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MISSIONS_PROMO_CODES:
      return {
        ...state,
        missionsPromoCodes: action.payload
      };
    default:
      return state;
  }
};
