import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// redux actions
import {
  pushNotificationDefinition,
  pushNotificationCTA,
  addPushNotificationImage
} from "../../redux/action/PushNotifications/CreatePushNotifications";
import { submitPushNotification } from "../../redux/action/PushNotifications/SubmitPushNotifications";
// core components
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import FileUpload from "../FileUpload";
import ErrorMessage from "../ErrorMessage";
import styles from "./styles.css";
import { checkFieldLimit, urlValidation } from "./Validations";

const useStyles = makeStyles(styles);

const PushNotifications = props => {
  const classes = useStyles();
  const {
    pushNotifications,
    pushNotificationDefinition,
    pushNotificationCTA,
    addPushNotificationImage,
    submitPushNotification,
    fileAsArray,
    success,
    errorMessage
  } = props;
  const [error, setError] = useState(false);
  const [urlError, setUrlError] = useState(false);

  const handleChange = event => {
    let getLimitNumber = event.target.name.includes("title") ? 128 : 1024;
    let checkLimit = checkFieldLimit(event.target.value, getLimitNumber);

    if (checkLimit) {
      pushNotificationDefinition({
        ...pushNotifications,
        [event.target.name]: event.target.value
      });
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleUrlChange = event => {
    pushNotificationCTA(event.target.value);
  };

  const handleImageUrlChange = event => {
    addPushNotificationImage(event.target.value);
  };

  const submitData = () => {
    if (
      pushNotifications.title &&
      (pushNotifications.body && fileAsArray.length > 0)
    ) {
      submitPushNotification(pushNotifications, fileAsArray);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Check if URLs are valid
    let validationCTA;
    let validationImageUrl;
    if (pushNotifications.callToAction) {
      validationCTA = urlValidation(pushNotifications.callToAction);
    }
    if (pushNotifications.imageUrl) {
      validationImageUrl = urlValidation(pushNotifications.imageUrl);
    }

    if (pushNotifications.callToAction || pushNotifications.imageUrl) {
      if (validationCTA || validationImageUrl) {
        submitData();
        setUrlError(false);
      } else {
        setUrlError(true);
      }
    } else {
      submitData();
    }
  };

  return (
    <GridContainer justify="space-around">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>
              Create a push notification
            </h4>
          </CardHeader>
          <form
            className={`${classes.container} ${classes.marginBlock}`}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              id="notification-title"
              name="title"
              label="Notification Title"
              className={classes.textField}
              value={pushNotifications.title}
              error={error}
              onChange={handleChange}
              helperText="Max no. of characters is 128"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 128
              }}
            />
            <TextField
              type="text"
              id="notification-body"
              name="body"
              label="Notification Body"
              className={classes.textField}
              value={pushNotifications.body}
              error={error}
              onChange={handleChange}
              helperText="Max no. of characters is 1024"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 1024
              }}
            />
            <TextField
              type="text"
              id="call-to-action"
              name="callToAction"
              label="Call To Action"
              value={pushNotifications.callToAction}
              error={urlError}
              helperText="Enter a valid URL or Deep link (optional)"
              className={classes.textField}
              onChange={handleUrlChange}
              margin="normal"
              variant="outlined"
              style={{
                marginBlockStart: "32px",
                marginBlockEnd: "32px"
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              type="text"
              id="image-url"
              name="imageUrl"
              label="Image URL"
              value={pushNotifications.imageUrl}
              error={urlError}
              helperText="Enter a valid image URL (optional)"
              className={classes.textField}
              onChange={handleImageUrlChange}
              margin="normal"
              variant="outlined"
              style={{
                marginBlockStart: "32px",
                marginBlockEnd: "32px"
              }}
              InputLabelProps={{
                shrink: true
              }}
            />

            <FileUpload fileType="convertToText" />

            <Button
              style={{
                margin: "20px",
                marginInlineStart: "auto",
                display: "block"
              }}
              color="primary"
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Card>

        {success && (
          <ErrorMessage message="Submitted successfully!" msgStatus="success" />
        )}
        {errorMessage && <ErrorMessage message="Sorry something went wrong!" />}
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    pushNotifications: state.pushNotifications,
    fileAsArray: state.uploadFile.fileAsArray,
    errorMessage: state.errorMessage.error,
    success: state.errorMessage.success
  };
};

export default connect(
  mapStateToProps,
  {
    pushNotificationDefinition,
    pushNotificationCTA,
    addPushNotificationImage,
    submitPushNotification
  }
)(PushNotifications);

PushNotifications.propTypes = {
  pushNotifications: PropTypes.object,
  pushNotificationDefinition: PropTypes.func,
  pushNotificationCTA: PropTypes.func,
  addPushNotificationImage: PropTypes.func,
  submitPushNotification: PropTypes.func,
  fileAsArray: PropTypes.array,
  errorMessage: PropTypes.bool,
  success: PropTypes.bool
};
