import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Stepper from "../../Stepper/refactorStepper";

import UploadStep from "./uploadStep";
import ConfirmOrder from "./confirmOrder";
import styles from "./styles.css";
// import Warning from "../../../assets/img/Warning.1.svg";
// import Success from "../../../assets/img/success.svg";
// import ResponseModal from "./ResponseModal";

const stepsTitle = ["Upload CSV", "Confirm Order"];

const useStyles = makeStyles(styles);

function UploadSteps(props) {
  const classes = useStyles();

  const [progressBar, setProgressBar] = useState(false);
  const {
    isLoading,
    error,
    onCloseModal,
    onSubmit,
    success,
    resetUpload,
    list,
    uploadErrors,
    type,
    errorMessage,
    countrySelected
  } = props;

  useEffect(() => {
    const enableClass = document.querySelector(".MuiStep-completed");
    setProgressBar(!!enableClass);
  }, [progressBar]);

  const handleSubmit = () => {
    onSubmit(list, countrySelected);
  };

  useEffect(() => {
    if (success || error) {
      onCloseModal();
    }
  }, [success, error]);

  return (
    <div className={classes.containerSteps}>
      <Stepper stepsTitle={stepsTitle} handleSubmit={handleSubmit}>
        <UploadStep
          uploadErrors={uploadErrors}
          errorMessage={errorMessage}
          type={type}
        />
        <ConfirmOrder
          changeProgressValue={setProgressBar}
          list={list}
          type={type}
        />
      </Stepper>
      <LinearProgress variant="determinate" value={progressBar ? 100 : 50} />
      {isLoading && (
        <div className={classes.containerLoading} aria-label="loading">
          <CircularProgress />
        </div>
      )}
      {/* {error && (
        <ResponseModal
          image={Warning}
          onCloseModal={onCloseModal}
          error={error}
        />
      )} */}
      {/* {success && (
        <ResponseModal
          image={Success}
          onCloseModal={onCloseModal}
          success={success}
        />
      )} */}
    </div>
  );
}

export default UploadSteps;

UploadSteps.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSubmit: PropTypes.func,
  resetUpload: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.object),
  uploadErrors: PropTypes.object,
  countrySelected: PropTypes.string
};
