import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

// redux actions
import { selectRechargeValue } from "../../../../redux/action/WaslaRewards/RechargeUsersActions";
// core components
import styles from "../../styles.css";

const useStyles = makeStyles(styles);

function SelectRechargeValue(props) {
  const classes = useStyles();

  const { rechargeValues, selectedRechargeValue, selectRechargeValue } = props;

  const handleRadioButtonChange = event => {
    let btnValue = event.target.value;
    let getActualValue = btnValue.split(" ");
    selectRechargeValue(+getActualValue[0]);
  };

  return (
    <div
      className={clsx(
        classes.container,
        classes.flexDirectionColumn,
        classes.radioBtnContainer
      )}
    >
      <Typography
        component="p"
        variant="body1"
        className={classes.textAlignLeft}
      >
        Select Recharge Value:
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Recharge Value"
          name="Recharge Value"
          row={true}
          value={`${selectedRechargeValue.toString()} EGP`}
          onChange={handleRadioButtonChange}
          style={{ justifyContent: "space-between" }}
        >
          {rechargeValues.map((value, index) => (
            <FormControlLabel
              key={index}
              value={`${value} EGP`}
              control={
                <Radio
                  disableRipple
                  checkedIcon={
                    <span
                      className={clsx(
                        classes.radioBtnIcon,
                        classes.checkedIcon
                      )}
                    />
                  }
                  icon={<span className={classes.radioBtnIcon} />}
                />
              }
              style={{ color: "#000" }}
              label={`${value} EGP`}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    rechargeValues: state.rechargeUsers.rechargeValues,
    selectedRechargeValue: state.rechargeUsers.selectedRechargeValue
  };
};

export default connect(
  mapStateToProps,
  { selectRechargeValue }
)(SelectRechargeValue);

SelectRechargeValue.propTypes = {
  rechargeValues: PropTypes.array,
  selectedRechargeValue: PropTypes.number,
  selectRechargeValue: PropTypes.func
};
