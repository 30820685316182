const Styles = {
  submitBTN: {
    background: "#FFEEBE !important",
    border: "1px solid #FFEEBE !important",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05) !important",
    fontSize: "14px !important",
    color: "#DAAC22 !important",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important"
    }
  },
  cancelBTN: {
    "& button": {
      background: "#FFFFFF !important",
      border: "1px solid #D0D5DD !important",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05) !important",
      fontSize: "14px !important",
      color: "#344054 !important"
    },
    "& button:hover": {
      "& span": {
        textDecoration: "none !important"
      }
    }
  }
};

export default Styles;
