import {
  UPDATE_NAME_EN,
  UPDATE_NAME_AR,
  UPDATE_LOGO_URL,
  UPDATE_STATUS,
  GET_DEALS_COUNTS,
  SHOW_ERROR
} from "../../actions/local";

const initialState = {
  nameEn: "",
  nameAr: "",
  logoUrl: null,
  status: true,
  dealsCount: 0,
  error: false,
  title: "",
  message: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NAME_EN:
      return {
        ...state,
        nameEn: action.payload.nameEn
      };
    case UPDATE_NAME_AR:
      return {
        ...state,
        nameAr: action.payload.nameAr
      };
    case UPDATE_LOGO_URL:
      return {
        ...state,
        logoUrl: action.payload.logoUrl
      };
    case UPDATE_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
    case GET_DEALS_COUNTS:
      return {
        ...state,
        dealsCount: action.payload.dealsCount
      };
    case SHOW_ERROR:
      return {
        ...state,
        error: action.payload.error.error,
        title: action.payload.error.title,
        message: action.payload.error.message
      };
    default:
      return state;
  }
};
