import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ModalContainer from "../BatchHistory/ModalContainer";
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import {
  Table,
  TableBody,
  Paper,
  TableCell,
  TableRow,
  TableContainer,
  makeStyles,
  Checkbox,
  Typography,
  Button
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

// redux actions
import {
  selectUsersToRecharge,
  fetchRewardApiList,
  selectUsersToExport,
  setSearchPhoneNumber
} from "../../../redux/action/WaslaRewards/UsersLogsActions";
import { fetchBalanceList } from "../../../redux/action/WaslaRewards/FetchBalanceListAction";
import { restErrorExport } from "../../../redux/action/WaslaRewards/FetchExportFIleUrlAction";
import { openSnackBar } from "../../../redux/action/SnackBarAction";
import { fetchRechargeValuesList } from "../../../redux/action/WaslaRewards/RechargeUsersActions";
import { fetchRechargesFailedList } from "../../../redux/action/WaslaRewards/EditUsersRechargeAction";
// core components
import TableHeader from "./TableHeader";
import TableToolbar from "./TableToolbar";
import styles from "../styles.css";
import {
  getComparator,
  stableSort,
  convertTypeStatusFilterToBoolean
} from "./helper";
import ErrorMessage from "../../ErrorMessage";
import CustomizedSnackBars from "../SnackBar/SnackBar";
import BalanceDetails from "../BalanceDetails";

function createData({
  phoneNumber,
  transactionId,
  operator,
  date,
  beginningBalance,
  endingBalance,
  transactionValue,
  comment,
  success,
  exportPdf,
  id,
  failReason
}) {
  return {
    phoneNumber,
    transactionId,
    operator,
    date,
    beginningBalance,
    endingBalance,
    transactionValue,
    comment,
    success,
    exportPdf,
    id,
    failReason
  };
}

const useStyles = makeStyles(styles);

function UsersLogsTable(props) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const location = useLocation();
  const [openModalInProgress, setOpenModalInProgress] = useState(false);
  const [openModalCompleted, setOpenModalCompleted] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const {
    rewardApiList,
    selectUsersToRecharge,
    hasNext,
    fetchRewardApiList,
    selectedUsers,
    error,
    success,
    fetchBalanceList,
    successSome,
    phoneNumber,
    startDate,
    endDate,
    operatorSelectedList,
    statusSelectedList,
    isLoading,
    selectUsersToExport,
    errorExport,
    restErrorExport,
    openSnackBar,
    setSearchPhoneNumber,
    fetchRechargeValuesList,
    fetchRechargesFailedList
  } = props;

  const projectName = location?.state?.projectName;
  const projectId = location?.state?.projectId;
  const batchId = location?.state?.batchId;
  const batchStatus = location?.state?.batchStatus;
  useEffect(() => {
    if (batchStatus === "progress") {
      setOpenModalInProgress(true);
    }
    if (batchStatus === "completed") {
      openSnackBar();
      setOpenModalCompleted(true);
    }
  }, [batchStatus]);
  const createRows = useCallback(() => {
    const retrieveRechargeList = rewardApiList.map(item => {
      const convertUtcToMilliseconds = Moment(item.createdAt).valueOf();
      return createData({
        phoneNumber: item.phoneNumber,
        transactionId: item.transaction_id,
        operator: item.operator,
        date: convertUtcToMilliseconds,
        beginningBalance: item.beginningBalance,
        endingBalance: item.endingBalance,
        transactionValue: item.transactionValue,
        comment: item.comment,
        success: item.success,
        exportPdf:
          item.export ||
          "https://wasla-browser.s3.eu-central-1.amazonaws.com/points/331.pdf",
        id: item.id,
        failReason: item.failReason
      });
    });
    setRows([...retrieveRechargeList]);
  }, [rewardApiList]);

  useEffect(() => {
    createRows(rewardApiList);
  }, [rewardApiList]);

  useEffect(() => {
    fetchRechargeValuesList();
    if (batchId || location?.state?.id)
      fetchRechargesFailedList({
        batchId,
        projectId: location?.state?.id
      });
  }, [batchId, location?.state?.id]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const modifyUsersSelected = users =>
    users.map(user => {
      return {
        phoneNumber: user.phoneNumber,
        operator: user.operator,
        price: user.transactionValue
      };
    });
  const usersTrasnctionsIds = users =>
    users.map(user => {
      return {
        id: user.id
      };
    });

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelected(rows);
      selectUsersToRecharge(modifyUsersSelected(rows));
      selectUsersToExport(usersTrasnctionsIds(rows));

      return;
    }
    setSelected([]);
    selectUsersToRecharge([]);
    selectUsersToExport([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    selectUsersToRecharge(modifyUsersSelected(newSelected));
    selectUsersToExport(usersTrasnctionsIds(newSelected));
  };

  const isSelected = row => selected.indexOf(row) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {
    if (pageNum >= 1) {
      const checkOperatorEmpty =
        operatorSelectedList.length === 0 ? null : operatorSelectedList;
      const checkStatusEmpty =
        convertTypeStatusFilterToBoolean(statusSelectedList).length === 0
          ? null
          : convertTypeStatusFilterToBoolean(statusSelectedList);

      fetchRewardApiList({
        pageNum,
        phoneNumber: phoneNumber || null,
        startDate: startDate || null,
        endDate: endDate || null,
        operator: checkOperatorEmpty,
        success: checkStatusEmpty,
        batchId: batchId || null,
        projectId: location?.state?.id || null
      });
    } else {
      fetchRewardApiList({ pageNum, batchId: batchId || null });
    }
  }, [pageNum, location?.state?.id]);

  const handleNext = () => {
    if (hasNext) {
      setPageNum(pageNum + 1);
    }
  };

  const handlePrevious = () => {
    if (pageNum !== 1) {
      setPageNum(pageNum - 1);
    }
  };

  useEffect(() => {
    if (selectedUsers.length === 0) {
      setSelected([]);
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (success || successSome) {
      fetchRewardApiList({ pageNum, batchId: batchId || null });
      fetchBalanceList();
    }
    return () => {
      restErrorExport();
      setSearchPhoneNumber();
    };
  }, [success, successSome]);

  const handleCloseModal = () => {
    setOpenModalInProgress(false);
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      {(projectName || location?.state?.id) && (
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="subtitle1"
              component="h5"
              style={{
                padding: 2,
                fontSize: "24px",
                marginBottom: "16px",
                fontWeight: 500,
                textTransform: "capitalize"
              }}
            >
              Project Name:
              <span style={{ fontWeight: 400, fontSize: "22px" }}>
                {" "}
                {projectName || location?.state?.name}
              </span>
            </Typography>
          </Grid>
          {location?.state?.id && (
            <Grid item>
              <Button
                variant="contained"
                className={`${classes.defaultButton}`}
                onClick={() => handleOpenModal()}
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid transparent"
                }}
              >
                <CloudUploadIcon style={{ marginRight: 15 }} />
                <span>Manual Recharge</span>
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {!projectName && <BalanceDetails />}
      <Paper className={classes.paper}>
        {error && <ErrorMessage message="Sorry something went wrong!" />}
        {rows.length === 0 && (
          <>
            <TableToolbar
              pageNumber={pageNum}
              numSelected={selected.length}
              setPageNum={() => setPageNum(1)}
              projectId={projectId || location?.state?.id}
            />
            {isLoading && (
              <div
                style={{ textAlign: "center", marginTop: 15, marginBottom: 15 }}
              >
                <CircularProgress aria-label="is empty" />
              </div>
            )}

            {!isLoading && (
              <Typography
                component="p"
                variant="subtitle1"
                align="center"
                gutterBottom={true}
                style={{ width: "100%", padding: "1rem 0" }}
                aria-label="is empty"
              >
                There is no records of users yet.
              </Typography>
            )}
          </>
        )}
        {rows.length !== 0 && (
          <>
            <TableToolbar
              pageNumber={pageNum}
              numSelected={selected.length}
              setPageNum={() => setPageNum(1)}
              projectId={projectId || location?.state?.id}
            />
            {isLoading ? (
              <div
                style={{
                  textAlign: "center",
                  marginTop: 15,
                  marginBottom: 15
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  // aria-label="enhanced table"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHeader
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    projectId={projectId || location?.state?.id}
                  />

                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={event => handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={labelId}
                            selected={isItemSelected}
                            style={{ padding: 12 }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                color="primary"
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.phoneNumber}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              style={{ paddingRight: "12px" }}
                            >
                              {row.transactionId}
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              {row.operator}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              style={{ paddingRight: "15px" }}
                            >
                              {Moment(row.date).format("DD MMM, YYYY, LT")}
                            </TableCell>

                            <TableCell scope="row" padding="none">
                              {row.beginningBalance}
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              {row.endingBalance}
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              {parseFloat(row.transactionValue)}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              style={{ paddingRight: "12px" }}
                            >
                              <p
                                title={row.comment}
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "140px",
                                  minWidth: "120px"
                                }}
                              >
                                {row.comment}
                              </p>
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              {row.success ? (
                                <Typography
                                  variant="subtitle1"
                                  component="p"
                                  style={{
                                    backgroundColor: "rgba(10,187,135,.1)",
                                    color: "#0abb87",
                                    padding: "6px 16px",
                                    display: "inline-block",
                                    margin: "0.2em 0",
                                    borderRadius: "50px",
                                    fontSize: "14px"
                                  }}
                                >
                                  Successful
                                </Typography>
                              ) : (
                                <Typography
                                  variant="subtitle1"
                                  component="p"
                                  style={{
                                    backgroundColor: "rgba(253,57,122,.1)",
                                    color: "#fd397a",
                                    padding: "6px 32px",
                                    display: "inline-block",
                                    margin: "0.2em 0",
                                    borderRadius: "50px",
                                    fontSize: "14px"
                                  }}
                                >
                                  Failed
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              <p
                                title={row.failReason}
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "160px",
                                  minWidth: "170px"
                                }}
                              >
                                {row.failReason}
                              </p>
                            </TableCell>
                            {/* <TableCell scope="row" padding="none">
                          <a
                            href={`${row.exportPdf}`}
                            download
                            style={{ color: "#000" }}
                          >
                            <VerticalAlignBottomIcon />
                          </a>
                        </TableCell> */}
                          </TableRow>
                        );
                      }
                    )}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{ height: (dense ? 33 : 53) * emptyRows }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!isLoading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "space-between",
                  justifyContent: "space-between",
                  padding: "1em"
                }}
              >
                <Button
                  type="button"
                  onClick={handlePrevious}
                  disabled={pageNum === 1}
                  color="primary"
                  className={`${classes.defaultButton} ${pageNum !== 1 &&
                    classes.primaryButton}`}
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  onClick={handleNext}
                  color="primary"
                  disabled={!hasNext}
                  className={`${classes.defaultButton} ${hasNext &&
                    classes.primaryButton}`}
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}
        {openModalInProgress && !errorExport && (
          <Dialog
            open={openModalInProgress}
            onClose={handleCloseModal}
            aria-labelledby="simple-dialog-title"
            maxWidth={"xs"}
            className={classes.customDialogContainer}
          >
            <MuiDialogTitle
              disableTypography
              className={classes.rootMuiDialogTitle}
              id="simple-dialog-title"
            >
              <Typography variant="h6">Transaction Processing</Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>

            <DialogContent>
              <DialogContentText style={{ color: "#000000" }}>
                There is a transaction processing. Please Retry Failed once the
                transaction is complete
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
        {openModalCompleted && !errorExport && (
          <CustomizedSnackBars
            severity={"info"}
            messege={
              "If the transactions completed, please retry failed transactions ."
            }
            errorMsg={!!openModalCompleted}
          />
        )}
        {!!errorExport && (
          <CustomizedSnackBars
            severity={"error"}
            messege={errorExport}
            errorMsg={!!errorExport}
          />
        )}
        {openModal && (
          <ModalContainer open={openModal} handleClose={handleCloseModal} />
        )}
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    rewardApiList: state.waslaRewards.rewardApiList,
    hasNext: state.waslaRewards.hasNext,
    selectedUsers: state.waslaRewards.selectedUsers,
    error: state.errorMessage.error,
    success: state.rechargeUsers.successAll,
    successSome: state.rechargeUsers.successSome,
    phoneNumber: state.waslaRewards.phoneNumber,
    startDate: state.filtersQuery.startDate,
    endDate: state.filtersQuery.endDate,
    operatorSelectedList: state.filtersQuery.operatorSelectedList,
    statusSelectedList: state.filtersQuery.statusSelectedList,
    isLoading: state.waslaRewards.isLoading,
    errorExport: state.waslaRewards.errorExport
  };
};

export default connect(
  mapStateToProps,
  {
    selectUsersToRecharge,
    fetchRewardApiList,
    fetchBalanceList,
    selectUsersToExport,
    restErrorExport,
    openSnackBar,
    setSearchPhoneNumber,
    fetchRechargeValuesList,
    fetchRechargesFailedList
  }
)(UsersLogsTable);

UsersLogsTable.propTypes = {
  rewardApiList: PropTypes.array,
  selectUsersToRecharge: PropTypes.func,
  hasNext: PropTypes.bool,
  selectedUsers: PropTypes.array,
  error: PropTypes.bool,
  fetchBalanceList: PropTypes.func,
  success: PropTypes.bool,
  successSome: PropTypes.bool,
  phoneNumber: PropTypes.string,
  operatorSelectedList: PropTypes.array,
  statusSelectedList: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isLoading: PropTypes.bool,
  selectUsersToExport: PropTypes.func,
  errorExport: PropTypes.string,
  restErrorExport: PropTypes.func,
  openSnackBar: PropTypes.func,
  setSearchPhoneNumber: PropTypes.func,
  fetchRechargeValuesList: PropTypes.func,
  fetchRechargesFailedList: PropTypes.func
};
