import { UPDATE_EDIT_REQUEST } from "../../actions/origin";

const initialState = {
  oldTransactionValue: "",
  oldCommission: "",
  oldCashback: "",
  newTransactionValue: "",
  newCommission: "",
  newCashback: "",
  status: "",
  reason: "",
  notes: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EDIT_REQUEST:
      return {
        ...state,
        ...action.payload.editRequest
      };
    default:
      return state;
  }
};
