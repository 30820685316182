import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import withReducer from "../../../../../store/withReducer";
import MerchantReducers from "../../../../../main/deals/merchants/add-n-edit/reducers";
import * as dealsService from "../../../../../services/deals";

import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import WaslaTheme from "../../../../../modules/wasla-theme";

import configurations from "../../../../../configurations";
import { dealsMerchantsPath } from "../../../../../main/deals/merchants/paths";

import {
  updateOrder,
  updateNameEn,
  updateNameAr,
  updateLogoUrl,
  updateUrl,
  updateDescriptionEn,
  updateDescriptionAr,
  updateIsPopular,
  updateCountries,
  updateTargetUrls,
  updateTncEn,
  updateTncAr
} from "../actions/local";
import { updateMerchant } from "../actions/origin";

import From from "../components/Form";

const EditMerchant = props => {
  const {
    merchant: { origin, local },
    updateOrder,
    updateNameEn,
    updateNameAr,
    updateLogoUrl,
    updateUrl,
    updateDescriptionEn,
    updateDescriptionAr,
    updateIsPopular,
    updateCountries,
    updateTargetUrls,
    updateTncEn,
    updateTncAr,
    updateMerchant
  } = props;

  const [loading, setLoading] = useState(true);
  const [sync, setSync] = useState(false);

  const history = useHistory();

  let { id } = useParams();

  useEffect(async () => {
    setLoading(true);
    const res = await dealsService.getMerchants(id, "order", 1, 1);
    updateMerchant({ ...res.data[0] });
    updateOrder(res.data[0].order);
    updateNameEn(res.data[0].nameEn);
    updateNameAr(res.data[0].nameAr);
    updateLogoUrl(res.data[0].logoUrl);
    updateUrl(res.data[0].url);
    updateDescriptionEn(res.data[0].descriptionEn);
    updateDescriptionAr(res.data[0].descriptionAr);
    updateIsPopular(res.data[0].isPopular);
    updateCountries(res.data[0].countries);
    updateTargetUrls(res.data[0].targetUrls);
    updateTncEn(res.data[0].tncEn);
    updateTncAr(res.data[0].tncAr);
    setLoading(false);
  }, []);

  const handleSubmitForm = async merchant => {
    setSync(true);
    const updateMerchantRes = await dealsService.updateMerchant(id, {
      order: merchant.order,
      nameEn: merchant.nameEn,
      nameAr: merchant.nameAr,
      url: merchant.url,
      descriptionEn: merchant.descriptionEn,
      descriptionAr: merchant.descriptionAr,
      isPopular: merchant.isPopular,
      countries: merchant.countries,
      targetUrls: merchant.targetUrls,
      tncEn: merchant.tncEn,
      tncAr: merchant.tncAr
    });
    if (merchant.logoUrl) {
      const updateMerchantImageRes = await dealsService.updateMerchantImage(
        id,
        merchant.logoUrl
      );
    }
    setSync(false);
    history.push(
      [configurations.dashboard.basePath, dealsMerchantsPath].join("")
    );
  };

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            component={RouterLink}
            to={[configurations.dashboard.basePath, dealsMerchantsPath].join(
              ""
            )}
          >
            Merchants
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            Edit Merchant
          </Typography>
        </Breadcrumbs>
        <Grid container justifyContent="center">
          <Grid item lg={7}>
            <From
              submitForm={handleSubmitForm}
              loading={loading}
              sync={sync}
              operation="edit"
            />
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default withReducer("merchant", MerchantReducers)(
  connect(
    state => ({ merchant: state.merchant }),
    {
      updateOrder,
      updateNameEn,
      updateNameAr,
      updateLogoUrl,
      updateUrl,
      updateDescriptionEn,
      updateDescriptionAr,
      updateIsPopular,
      updateCountries,
      updateTargetUrls,
      updateTncEn,
      updateTncAr,
      updateMerchant
    }
  )(EditMerchant)
);
