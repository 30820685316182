import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, generatePath } from "react-router-dom";

import * as dealsService from "../../../../services/deals";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Link from "@mui/material/Link";

import Typography from "@mui/material/Typography";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import FormCard from "../../../../components/FormCard";
import FormCardHead from "../../../../components/FormCardHead";
import FormCardBody from "../../../../components/FormCardBody";
import FormCardBodyContent from "../../../../components/FormCardBodyContent";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import WaslaTheme from "../../../../modules/wasla-theme";

import configurations from "../../../../configurations";

import { cashbacksPath } from "../../paths";
import { cashbacksConversionsPath } from "../paths";

import { updateConversion } from "../../../../main/cashbacks/conversions/conversion/actions/item";

const Conversion = props => {
  const { conversion, updateConversion } = props;

  const [loading, setLoading] = useState(true);

  const { countryCode, id } = useParams();

  useEffect(async () => {
    setLoading(true);
    const res = await dealsService.getConversions(
      countryCode,
      id,
      "shoppingId",
      "",
      "",
      "createdDate",
      1,
      1
    );
    updateConversion(res.data[0]);
    setLoading(false);
  }, []);

  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[configurations.dashboard.basePath, cashbacksPath].join("")}
          >
            Select a country for Cashback
          </Link>
          <Link
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "inherit"
              }
            }}
            color="inherit"
            href={[
              configurations.dashboard.basePath,
              generatePath(cashbacksConversionsPath, {
                countryCode
              })
            ].join("")}
          >
            Cashbacks Table
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="inherit"
          >
            History
          </Typography>
        </Breadcrumbs>
        <Grid container justifyContent="center">
          <Grid item lg={6}>
            <Box component="form" autoComplete="off">
              <Stack spacing={4}>
                <FormCard>
                  <FormCardHead variant="underline">
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "28px",
                        color: "#101828"
                      }}
                    >
                      History
                    </Typography>
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085"
                      }}
                    >
                      History view for the cashback History
                    </Typography>
                  </FormCardHead>
                  <FormCardBody>
                    <FormCardBodyContent>
                      <Stack spacing={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="transaction-value"
                                sx={{ transform: "none" }}
                              >
                                GMV Value
                              </InputLabel>
                              <OutlinedInput
                                id="transaction-value"
                                value={
                                  conversion.transactionValue
                                    ? `${conversion.transactionValue} ${conversion.currency}`
                                    : ""
                                }
                                label=""
                                required
                                disabled={true}
                              />
                              {false && (
                                <FormHelperText>
                                  GMV Value is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}></Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="commission"
                                sx={{ transform: "none" }}
                              >
                                Wasla commission Value
                              </InputLabel>
                              <OutlinedInput
                                id="commission"
                                value={
                                  conversion.commission
                                    ? `${conversion.commission} ${conversion.currency}`
                                    : ""
                                }
                                label=""
                                required
                                disabled={true}
                              />
                              {false && (
                                <FormHelperText>
                                  Wasla commission Value is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}></Grid>
                          <Grid item xs={6}>
                            <FormControl error={false} fullWidth>
                              <InputLabel
                                shrink
                                htmlFor="cashback"
                                sx={{ transform: "none" }}
                              >
                                CB Value
                              </InputLabel>
                              <OutlinedInput
                                id="cashback"
                                value={
                                  conversion.cashbackValue
                                    ? `${conversion.cashbackValue} ${conversion.currency}`
                                    : ""
                                }
                                label=""
                                required
                                disabled={true}
                              />
                              {false && (
                                <FormHelperText>
                                  CB Value is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}></Grid>
                        </Grid>
                      </Stack>
                    </FormCardBodyContent>
                  </FormCardBody>
                </FormCard>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default connect(
  state => ({ conversion: state.conversion }),
  {
    updateConversion
  }
)(Conversion);
