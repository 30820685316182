import { GET_BUCKETS_LIST } from "../../action/actionType";

const initialState = {
  bucketsItem: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BUCKETS_LIST:
      return {
        ...state,
        bucketsItem: action.payload
      };
    default:
      return state;
  }
};
