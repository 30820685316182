import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import customStyle from "./style.css";
import AddIcon from "@material-ui/icons/Add";
import { Typography } from "@material-ui/core";

// reduxAction
import {
  setOperatorType,
  setStatusType,
  onSubmitFilter,
  removeSelectOperator,
  addSelectedValues,
  setStartDateFilter,
  setEndDateFilter,
  restFiltersValues
} from "../../../redux/action/WaslaRewards/FiltersActions";
import { convertTypeStatusFilterToBoolean } from "../UsersLogsTable/helper";
import DateComponent from "../../DateComponent";
import Popper from "@material-ui/core/Popper";
import closeIcon from "../../../assets/img/closeIcon.svg";

const useStyles = makeStyles(customStyle);

const filterTypes = [
  {
    value: "Status",
    label: "Status"
  },
  {
    value: "Operator",
    label: "Operator"
  }
];
const operatorType = [
  {
    value: "orange",
    label: "orange"
  },
  {
    value: "vodafone",
    label: "vodafone"
  },
  {
    value: "etisalat",
    label: "etisalat"
  },
  {
    value: "we",
    label: "we"
  }
];

const statusType = [
  {
    value: "Success",
    label: "Success"
  },
  {
    value: "Failed",
    label: "Failed"
  }
];

function DetailedAccordion(props) {
  const classes = useStyles();
  const {
    setOperatorType,
    operatorSelected,
    setStatusType,
    statusSelected,
    onSubmitFilter,
    startDate,
    endDate,
    closeToggle,
    operatorSelectedList,
    removeSelectOperator,
    addSelectedValues,
    statusSelectedList,
    setStartDateFilter,
    setEndDateFilter,
    restFiltersValues,
    idPopper,
    anchorElement,
    setPageNum,
    pageNum
  } = props;

  const [optionList, setOptionList] = useState([]);
  const [filtersSelected, setFiltersSelected] = useState("");
  const [disabled, setDisabled] = useState(true);
  const location = useLocation();
  const batchId = location?.state?.batchId;

  useEffect(() => {
    if (
      operatorSelectedList.length !== 0 ||
      statusSelectedList.length !== 0 ||
      startDate ||
      endDate
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [operatorSelectedList, statusSelectedList, endDate, startDate]);
  const handleTypeChange = event => {
    if (event.target.value === "none") return setOperatorType("");
    if (event.target.value.toLowerCase() === "operator") {
      setFiltersSelected(event.target.value);
      setOptionList(operatorType);
    }
    if (event.target.value.toLowerCase() === "status") {
      setFiltersSelected(event.target.value);
      setOptionList(statusType);
    }
  };

  const handleOptionType = event => {
    if (event.target.value === "none") return setStatusType("");
    if (filtersSelected.toLowerCase() === "operator")
      setOperatorType(event.target.value);
    if (filtersSelected.toLowerCase() === "status") {
      setStatusType(event.target.value);
    }
  };

  const addFiltersValue = () => {
    addSelectedValues({ operatorSelected, statusSelected });
    setFiltersSelected("");
    setOptionList([]);
  };

  const handleSaveFilter = () => {
    if (pageNum !== 1) {
      setPageNum();
    } else {
      const checkOperatorEmpty =
        operatorSelectedList.length === 0 ? null : operatorSelectedList;
      const checkStatusEmpty =
        convertTypeStatusFilterToBoolean(statusSelectedList).length === 0
          ? null
          : convertTypeStatusFilterToBoolean(statusSelectedList);

      onSubmitFilter({
        pageNum: 1,
        startDate: startDate || null,
        endDate: endDate || null,
        operator: checkOperatorEmpty,
        success: checkStatusEmpty,
        batchId: batchId || null,
        projectId: location?.state?.id || null
      });
    }
    closeToggle();
  };
  const handleCLearFilter = () => {
    if (pageNum !== 1) {
      setPageNum();
    } else {
      onSubmitFilter({
        pageNum: 1,
        startDate: null,
        endDate: null,
        operator: null,
        success: null,
        batchId: batchId || null,
        projectId: location?.state?.id || null
      });
    }
    restFiltersValues();
  };

  return (
    <Popper
      id={idPopper}
      open={!!anchorElement}
      anchorEl={anchorElement}
      placement="bottom"
      disablePortal={true}
      className={classes.popperRoot}
      style={{
        zIndex: 10
      }}
      modifiers={{
        flip: {
          enabled: true
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: "scrollParent"
        }
      }}
    >
      <div className={classes.root}>
        <div className={classes.triangleUp}></div>
        <div className={classes.containerDate}>
          <div className={classes.titleLegend}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Date Range:
            </Typography>
            <div onClick={closeToggle} className={classes.iconContainer}>
              <img
                src={closeIcon}
                alt="..."
                style={{ objectFit: "contain", maxWidth: "100%" }}
              />
            </div>
          </div>
          <DateComponent
            disableFutureDays={true}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            type="filter"
          />
        </div>
        <div className={classes.filterContainer}>
          <div className={classes.titleLegend}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Data Filters:
            </Typography>
          </div>
          <div className={classes.selectGroup}>
            <TextField
              id="Filter Type"
              select
              label="Filter Type"
              className={classes.textField}
              name="type"
              value={filtersSelected}
              onChange={handleTypeChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
            >
              <option value="none">Select filters to add</option>
              {filterTypes.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <TextField
              id="Option Type"
              select
              label="Option Type"
              className={classes.textField}
              name="type"
              value={operatorSelected || statusSelected}
              onChange={handleOptionType}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
            >
              <option value="none">Select Type</option>
              {optionList.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <div className={classes.addBtnContainer}>
              <button
                // disabled={disabled}
                onClick={addFiltersValue}
                className={classes.addBtn}
              >
                +
              </button>
            </div>
          </div>
          {(operatorSelectedList.length !== 0 ||
            statusSelectedList.length !== 0) && (
            <div className={classes.containerViewItems}>
              <div className={classes.containerListItem}>
                {operatorSelectedList.map((item, index) => {
                  return (
                    <div key={item + index} className={classes.itemView}>
                      Operator: {item}
                      <div
                        onClick={() => removeSelectOperator(item)}
                        className={` ${classes.iconContainer}  ${classes.modifyIconContainer}`}
                      >
                        <img
                          src={closeIcon}
                          alt="..."
                          style={{
                            objectFit: "contain",
                            maxWidth: "100%",
                            marginLeft: "15px"
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {statusSelectedList.length !== 0 && (
                <div className={classes.containerListItem}>
                  {statusSelectedList.map((item, index) => {
                    return (
                      <div key={item + index} className={classes.itemView}>
                        Status: {item}
                        <div
                          onClick={() => removeSelectOperator(item)}
                          className={` ${classes.iconContainer}  ${classes.modifyIconContainer}`}
                        >
                          <img
                            src={closeIcon}
                            alt="..."
                            style={{
                              objectFit: "contain",
                              maxWidth: "100%",
                              marginLeft: "15px"
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>

        <div className={classes.btnsContainer}>
          <Button size="small" onClick={handleCLearFilter} disabled={disabled}>
            Clear
          </Button>
          <Button
            size="small"
            disabled={
              disabled || !!(endDate && startDate && startDate > endDate)
            }
            onClick={handleSaveFilter}
          >
            Apply
          </Button>
        </div>
      </div>
    </Popper>
  );
}
const mapStateToProps = state => {
  return {
    operatorSelected: state.filtersQuery.operator,
    statusSelected: state.filtersQuery.status,
    operatorSelectedList: state.filtersQuery.operatorSelectedList,
    statusSelectedList: state.filtersQuery.statusSelectedList,
    startDate: state.filtersQuery.startDate,
    endDate: state.filtersQuery.endDate
  };
};
export default connect(
  mapStateToProps,
  {
    setOperatorType,
    setStatusType,
    onSubmitFilter,
    removeSelectOperator,
    addSelectedValues,
    setStartDateFilter,
    setEndDateFilter,
    restFiltersValues
  }
)(DetailedAccordion);

DetailedAccordion.propTypes = {
  setOperatorType: PropTypes.func,
  setStatusType: PropTypes.func,
  operatorSelected: PropTypes.string,
  statusSelected: PropTypes.string,
  onSubmitFilter: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  closeToggle: PropTypes.func,
  removeSelectOperator: PropTypes.func,
  addSelectedValues: PropTypes.func,
  setEndDateFilter: PropTypes.func,
  setStartDateFilter: PropTypes.func,
  operatorSelectedList: PropTypes.array,
  statusSelectedList: PropTypes.array,
  restFiltersValues: PropTypes.func,
  anchorElement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  idPopper: PropTypes.string,
  setPageNum: PropTypes.func,
  pageNum: PropTypes.number
};
