import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import styles from "./style.css";

// redux actions
import { setSearchPhoneNumber } from "../../../redux/action/WaslaRewards/UsersLogsActions";
/* import { Validation } from "../UploadFile/Validtion"; */
// import CustomizedSnackbars from "../SnackBar/SnackBar";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const Search = props => {
  const {
    phoneNumber,
    setSearchPhoneNumber,
    fetchDataSearch,
    clearSearchType,
    title,
    nav
  } = props;

  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState(false);

  const handleChange = event => {
    setSearchPhoneNumber(event.target.value);
  };
  const handleSearchOnKeyPress = e => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    fetchDataSearch();
  };

  const clearSearch = () => {
    setSearchPhoneNumber("");

    clearSearchType();
  };
  return (
    <div className={classes.root}>
      <div
        className={classNames(classes.search, {
          [classes.navCsWidth]: nav === "csOtp"
        })}
      >
        <div className={classes.searchIcon}>
          <SearchIcon onClick={handleSearch} />
        </div>
        <InputBase
          placeholder={title}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          className={classNames({
            [classes.navCsWidth]: nav === "csOtp"
          })}
          inputProps={{
            "aria-label": "search"
          }}
          onKeyDown={handleSearchOnKeyPress}
          onChange={handleChange}
          value={phoneNumber}
        />
        {phoneNumber && (
          <div className={classes.clearIcon}>
            <HighlightOffIcon
              onClick={clearSearch}
              style={{ backgroundColor: "#fff" }}
            />
          </div>
        )}
      </div>
      {/* {openSnack && (
        <CustomizedSnackbars
          severity={"error"}
          messege={"No Results"}
          errorMsg={errorMsg}
        />
      )} */}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    phoneNumber: state.waslaRewards.phoneNumber
  };
};

export default connect(
  mapStateToProps,
  { setSearchPhoneNumber }
)(Search);

Search.propTypes = {
  phoneNumber: PropTypes.string,
  setSearchPhoneNumber: PropTypes.func,
  nav: PropTypes.string,
  fetchDataSearch: PropTypes.func,
  clearSearchType: PropTypes.func
};
