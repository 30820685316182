import * as remoteConfig from "firebase/remote-config";
import firebaseApp from "../";

const firebaseRemoteConfig = remoteConfig.getRemoteConfig(firebaseApp);
firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 0;

export default firebaseRemoteConfig;

export const getValue = key => remoteConfig.getValue(firebaseRemoteConfig, key);

export const fetchAndActivate = () =>
  remoteConfig.fetchAndActivate(firebaseRemoteConfig);
