import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setMissionSection } from "../../../../redux/action/Missions/MissionsDefinitionActions";
// core components
import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const MissionSection = ({ missions, list, setMissionSection }) => {
  const classes = useStyles();

  const handleSelection = event => {
    const selectedValue = event.target.value;
    // Send selected section name to the reducer
    const currentSection = list.filter(item => item.id === selectedValue);

    const sectionName =
      selectedValue !== "none" ? currentSection[0].titleEn : "";
    const sectionId = selectedValue !== "none" ? selectedValue : "";
    setMissionSection(sectionId, sectionName);
  };

  return (
    <>
      {list && (
        <TextField
          id="mission-section"
          select
          label="Mission Section"
          className={classes.textField}
          name="categories"
          value={missions.sectionId}
          onChange={handleSelection}
          SelectProps={{
            native: true,
            MenuProps: {
              className: classes.menu
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        >
          <option value="none">Select an Option</option>
          {list.map(option => (
            <option key={option?.id} value={option?.id}>
              {option?.titleEn}
            </option>
          ))}
        </TextField>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    missions: state.missions,
    categories: state.missionsCategories.missionsCategories,
    list: state.SectionsMissions.list
  };
};

export default connect(
  mapStateToProps,
  {
    setMissionSection
  }
)(MissionSection);

MissionSection.propTypes = {
  missions: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.object),
  setMissionSection: PropTypes.func
};
