import React from "react";

import { Link, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const HomePage = () => {
  const classes = useStyles();
  let location = useLocation();

  return (
    <Grid container className={classes.parent} spacing={1}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={2}
        style={{ padding: "8px" }}
      >
        <Grid item>
          <h1 className={classes.title}>{`Deals & Merchant`}</h1>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Link
              to={{
                pathname: `${location.pathname}s/merchants`
              }}
              style={{
                flex: "1 1 auto",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.87)",
                textTransform: "capitalize",
                flexDirection: "column",
                justifyContent: "center",
                lineHeight: 1.4
              }}
            >
              <div className={classes.paper}>
                <p
                  style={{
                    flex: "0 1 auto",
                    margin: " 0",
                    alignSelf: "center",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: 400
                  }}
                >
                  Merchant
                </p>
              </div>
            </Link>
          </Grid>
          <Grid item>
            <Link
              to={{
                pathname: `${location.pathname}s/deals`
              }}
              style={{
                flex: "1 1 auto",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.87)",
                textTransform: "capitalize",
                flexDirection: "column",
                justifyContent: "center",
                lineHeight: 1.4
              }}
            >
              <div className={classes.paper}>
                <p
                  style={{
                    flex: "0 1 auto",
                    margin: " 0",
                    alignSelf: "center",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: 400
                  }}
                >
                  Deals
                </p>
              </div>
            </Link>
          </Grid>
          <Grid item>
            <Link
              to={{
                pathname: `${location.pathname}s/categories`
              }}
              style={{
                flex: "1 1 auto",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.87)",
                textTransform: "capitalize",
                flexDirection: "column",
                justifyContent: "center",
                lineHeight: 1.4
              }}
            >
              <div className={classes.paper}>
                <p
                  style={{
                    flex: "0 1 auto",
                    margin: " 0",
                    alignSelf: "center",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: 400
                  }}
                >
                  Categories
                </p>
              </div>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePage;
