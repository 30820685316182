import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import WaslaTheme from "../../modules/wasla-theme";
import CountryForm from "../../components/CountryForm";
import { cashbacksPath } from "./paths";

const Cashbacks = () => {
  return (
    <WaslaTheme>
      <Stack spacing={4}>
        <Box sx={{ height: "36px" }}></Box>
        <Grid container justifyContent="center">
          <Grid item lg={6}>
            {/* form component placeholder */}
            <CountryForm nextPath={cashbacksPath} />
          </Grid>
        </Grid>
      </Stack>
    </WaslaTheme>
  );
};

export default Cashbacks;
