import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DragAndDrop } from "@progress/kendo-react-common";
import { DraggableRow } from "./draggable-row";
import { DragHandleCell } from "./drag-handle-cell";
import "@progress/kendo-theme-default/dist/all.css";
import "./table.css";

export const ReorderContext = React.createContext({
  reorder: () => {},
  dragStart: () => {}
});

const CategoriesTable = ({ categories, tableName, reorderCategories }) => {
  const [gridData, setGridData] = useState(categories);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const allCategories = [];
    categories.forEach((category, index) => {
      allCategories.push({
        ...category,
        orderNo: `#${index + 1}`,
        icon: category.icon
      });
    });
    setGridData(allCategories);
  }, [categories]);

  const reorder = (dataItem, direction) => {
    if (activeItem === dataItem) {
      return;
    }

    let reorderedData = gridData.slice();
    let prevIndex = reorderedData.findIndex(p => p === activeItem);
    let nextIndex = reorderedData.findIndex(p => p === dataItem);
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(
      Math.max(nextIndex + (direction === "before" ? -1 : 0), 0),
      0,
      activeItem || reorderedData[0]
    );
    const reorderData = [];
    const data = [];
    reorderedData.map((item, index) => {
      data.push({
        order: index + 1,
        titleEn: item.titleEn,
        titleAr: item.titleAr,
        icon: item.icon,
        active: item.active,
        slug: item.slug
      });
      reorderData.push({
        ...item,
        order: index + 1,
        orderNo: `#${index + 1}`
      });
    });
    setGridData(reorderData);
    reorderCategories(data);
  };

  const dragStart = dataItem => {
    setActiveItem(dataItem);
  };

  return (
    <ReorderContext.Provider
      value={{
        reorder: reorder,
        dragStart: dragStart
      }}
    >
      <div className="table-title-categories">
        <h3 style={{ textTransform: "capitalize" }}>{tableName}</h3>
      </div>
      <DragAndDrop>
        <Grid
          style={{
            height: "auto",
            width: "auto",
            margin: "auto"
          }}
          data={gridData}
          dataItemKey={"ProductID"}
          rowRender={(row, rowProps) => (
            <DraggableRow elementProps={row.props} {...rowProps} />
          )}
        >
          <Column
            field="orderNo"
            title="Order No."
            cell={props => (
              <td
                className={!props.dataItem.isComplete ? "incompleteColumn" : ""}
              >
                {props.dataItem.orderNo}
              </td>
            )}
          />
          <Column
            field="icon"
            title="Logo"
            cell={props => (
              <td
                className={!props.dataItem.isComplete ? "incompleteColumn" : ""}
              >
                <img
                  src={`${props.dataItem.icon}?${new Date()}`}
                  alt="category logo"
                  className="logo-category"
                />
              </td>
            )}
          />
          <Column
            field="titleEn"
            title="Name - English"
            cell={props => (
              <td
                className={!props.dataItem.isComplete ? "incompleteColumn" : ""}
              >
                {props.dataItem.titleEn}
              </td>
            )}
          />
          <Column
            field="titleAr"
            title="Name - Arabic"
            cell={props => (
              <td
                className={!props.dataItem.isComplete ? "incompleteColumn" : ""}
              >
                {props.dataItem.titleAr}
              </td>
            )}
          />
          <Column
            field="slug"
            title="Slug"
            cell={props => (
              <td
                className={!props.dataItem.isComplete ? "incompleteColumn" : ""}
              >
                {props.dataItem.slug}
              </td>
            )}
          />
          <Column
            field="dealsCount"
            title="Active Deals"
            cell={props => (
              <td
                className={!props.dataItem.isComplete ? "incompleteColumn" : ""}
              >
                {props.dataItem.dealsCount}
              </td>
            )}
          />
          <Column
            field="active"
            title="Status"
            cell={props => {
              if (props.dataItem.isActive) {
                return (
                  <td
                    className={
                      !props.dataItem.isComplete ? "incompleteColumn" : ""
                    }
                  >
                    <div
                      className={
                        !props.dataItem.isComplete
                          ? "incompleteBadge"
                          : "activeColumn"
                      }
                    >
                      <div
                        className={
                          !props.dataItem.isComplete
                            ? "incompleteCircle"
                            : "activeCircle"
                        }
                      />
                      <p>Active</p>
                    </div>
                  </td>
                );
              } else {
                return (
                  <td
                    className={
                      !props.dataItem.isComplete ? "incompleteColumn" : ""
                    }
                  >
                    <div
                      className={
                        !props.dataItem.isComplete
                          ? "incompleteBadge"
                          : "pausedColumn"
                      }
                    >
                      <div
                        className={
                          !props.dataItem.isComplete
                            ? "incompleteCircle"
                            : "pausedCircle"
                        }
                      />
                      <p>Paused</p>
                    </div>
                  </td>
                );
              }
            }}
          />
          <Column title="Action" cell={DragHandleCell} />
        </Grid>
      </DragAndDrop>
    </ReorderContext.Provider>
  );
};

export default CategoriesTable;
