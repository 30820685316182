import {
  FETCH_POINTS_CONFIG,
  ERROR_FETCH_POINTS_CONFIG
} from "../../../action/actionType";

const initialState = {
  configItems: {},
  errConfigPoints: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POINTS_CONFIG:
      return {
        ...state,
        configItems: action.payload
      };
    case ERROR_FETCH_POINTS_CONFIG:
      return {
        ...state,
        errConfigPoints: action.payload.message
      };
    default:
      return state;
  }
};
