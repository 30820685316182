import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
// redux actions
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";
// core components
import RechargeInfo from "./RechargeInfo";

function ConfirmOrder(props) {
  const { checkRequiredFields, changeProgressValue } = props;

  useEffect(() => {
    checkRequiredFields(true);
    changeProgressValue(true);
    return () => {
      changeProgressValue(false);
    };
  }, [checkRequiredFields]);

  return (
    <>
      <RechargeInfo />
    </>
  );
}

export default connect(
  null,
  {
    checkRequiredFields
  }
)(ConfirmOrder);

ConfirmOrder.propTypes = {
  checkRequiredFields: PropTypes.func
};
