import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import {
  setAmountOfPoints,
  setUserMobileNumber
} from "../../../redux/action/PointsSystem/TransferPointsActions";
// core components
import styles from "../styles.css";

const useStyles = makeStyles(styles);

const AmountOfPoints = ({ pointsSystem, setAmountOfPoints }) => {
  const classes = useStyles();

  const handlePointsChange = event => {
    setAmountOfPoints(event.target.value);
  };

  return (
    <TextField
      id="amount-of-points"
      name="amountOfPoints"
      label="Amount of points"
      type="number"
      placeholder="1,000 pts"
      className={classes.textField}
      value={pointsSystem.amountOfPoints || ""}
      onChange={handlePointsChange}
      margin="normal"
      variant="outlined"
      required
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        min: 0
      }}
    />
  );
};

const mapStateToProps = state => {
  return {
    pointsSystem: state.pointsSystem
  };
};

export default connect(
  mapStateToProps,
  { setAmountOfPoints, setUserMobileNumber }
)(AmountOfPoints);

AmountOfPoints.propTypes = {
  pointsSystem: PropTypes.object,
  setAmountOfPoints: PropTypes.func
};
