import qs from "qs";
import { dealsBaseurl, authURL } from "../../config";

export const fetchTargetList = ({ pageNum, limit = 10 }) => async dispatch => {
  dispatch({ type: "SET_LOADING_TARGET_LIST", payload: true });
  await dealsBaseurl
    .get(`/store/targets?page=${pageNum}&limit=${limit}`)
    .then(res => {
      dispatch({ type: "SET_LOADING_TARGET_LIST", payload: false });

      dispatch({
        type: "FETCH_TARGET_LIST",
        payload: res?.data
      });
    })
    .catch(error => {
      dispatch({ type: "SET_LOADING_TARGET_LIST", payload: false });
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const uploadTargetToSubmit = targets => {
  return {
    type: "SET_TARGET_LIST_UPLOAD",
    payload: targets
  };
};

export const handleDeleteTarget = (id, pageNum) => async dispatch => {
  dispatch(resetUploadTargetList());

  await dealsBaseurl
    .delete(`/store/targets/${id}`)
    .then(() => {
      dispatch(fetchTargetList({ pageNum }));
      dispatch({
        type: "DELETE_TARGET_SUCCESS",
        payload: "Target is deleted successfully"
      });
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        window.location.assign(authURL);
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Authentication expired"
        });
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "SET_ERROR_LIST",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "SET_ERROR_LIST",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const submitUploadTarget = targets => async dispatch => {
  dispatch({ type: "UPLOAD_TARGET_LOADING", payload: true });
  await dealsBaseurl
    .post(`/store/targets`, [...targets])
    .then(res => {
      dispatch({ type: "UPLOAD_TARGET_LOADING", payload: false });
      dispatch({
        type: "UPLOAD_TARGET_SUCCESS",
        payload: "Target is added successfully"
      });
      dispatch(fetchTargetList({ pageNum: 1 }));
    })
    .catch(error => {
      dispatch({ type: "UPLOAD_TARGET_LOADING", payload: false });

      if (error?.response?.status === 401) {
        window.location.assign(authURL);
      }
      if (error?.response?.status === 400) {
        dispatch({
          type: "UPLOAD_TARGET_FAILED",
          payload:
            (error?.response?.data?.errors &&
              Object.values(error?.response?.data?.errors)[0][0]) ||
            "Something went wrong"
        });
      } else {
        dispatch({
          type: "UPLOAD_TARGET_FAILED",
          payload: "Something went wrong with the server"
        });
      }
    });
};

export const resetUploadTargetList = () => {
  return {
    type: "RESET_UPLOAD_TARGET_LIST"
  };
};

export const closeNotification = () => {
  return {
    type: "RESET_ERROR"
  };
};
export const setMerchant = (id, name) => {
  return {
    type: "SET_MERCHANT",
    payload: {
      id,
      name
    }
  };
};
export const submitUpdateTarget = (data, id) => async dispatch => {
  let item = {
    domains: data.hosts,
    id: data.id,
    nameEn: data.nameEn,
    nameAr: data.nameAr,
    merchantId: data.merchantId
  };

  await dealsBaseurl
    .put(`/store/targets/${id}`, item)
    .then(() => {
      dispatch(fetchTargetList({ pageNum: 1 }));
      dispatch({
        type: "SUCCESS_UPDATE_TARGET",
        payload: "Target is updated successfully"
      });
    })
    .catch(err => {
      dispatch({
        type: "ERROR_UPDATE_TARGET"
      });
    });
};
