import { GET_EXCHANGE_RATES_HISTORY } from "../../../actions/exchange-rates/history";

const initialState = {
  data: [],
  meta: {
    total: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXCHANGE_RATES_HISTORY:
      return action.payload;
    default:
      return state;
  }
};
