import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// redux actions
import {
  setSpecificCSVFile,
  setFileName,
  saveFileAsArray,
  resetFile
} from "../../../redux/action/uploadFileActions";
// core components
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const FileUpload = ({
  fileName,
  errorMessage,
  setFileName,
  saveFileAsArray,
  resetFile
}) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);

  const handleSpecificCSVFile = event => {
    if (event.target.files.length === 0) return;
    if (/\.(csv)$/i.test(event.target.files[0].name)) {
      let fileSize = Math.round(event.target.files[0].size / 1024);
      if (fileSize <= 2000) {
        setError(false);
        setFileName(event.target.files[0].name);
        let reader = new FileReader();
        reader.onload = event => saveFileAsArray(event.target.result);
        reader.readAsText(event.target.files[0]);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.fileFieldWrapper}>
        <TextField
          type="file"
          id="specific-csv"
          label="CSV file"
          name="specificCSV"
          onChange={handleSpecificCSVFile}
          margin="normal"
          variant="outlined"
          style={{
            display: "none"
          }}
          className={classes.textFieldFile}
        />
        <label
          htmlFor="specific-csv"
          className={classes.textFieldFile}
          style={{ cursor: "pointer" }}
        >
          <CloudUploadIcon />
          <span>Upload CSV file</span>
        </label>
        <div className={classes.fileViewContainer}>
          {!fileName && (
            <Typography
              component="p"
              variant="body2"
              display="block"
              color="textSecondary"
            >
              Upload a .csv file with max size of 2MB
            </Typography>
          )}
          {fileName && (
            <Typography component="p" variant="body2" display="block">
              {fileName}
            </Typography>
          )}
        </div>
      </div>
      {error && (
        <Typography
          component="p"
          variant="body2"
          color="error"
          display="block"
          style={{ marginTop: "10px", maxWidth: "60%" }}
        >
          Upload a .csv file only
        </Typography>
      )}
      {errorMessage && (
        <Typography
          aria-label="error message"
          component="p"
          variant="body2"
          color="error"
          display="block"
          style={{ marginTop: "10px", maxWidth: "60%" }}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    fileName: state.uploadFile.fileName
  };
};

export default connect(
  mapStateToProps,
  { setSpecificCSVFile, setFileName, saveFileAsArray, resetFile }
)(FileUpload);

FileUpload.propTypes = {
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  setSpecificCSVFile: PropTypes.func,
  setFileName: PropTypes.func,
  saveFileAsArray: PropTypes.func,
  resetFile: PropTypes.func,
  errorMessage: PropTypes.string
};
