import {
  SUBMIT_MISSION_DATA,
  RESET_STATE,
  EDIT_MISSIONS,
  EDIT_MISSIONS_DATA
} from "../actionType";
import { missionsBaseUrl, dealsBaseurl } from "../../../components/config";
import { toggleErrorMessage } from "../ErrorMessageActions";

export const submitMissionData = (
  data,
  dates,
  imageUrl,
  fileUrl,
  iconUrl,
  countrySelected
) => async dispatch => {
  const response = await missionsBaseUrl
    .post("/missions", {
      titleEn: data.englishTitle,
      titleAr: data.arabicTitle,
      descriptionEn: data.englishDescription,
      descriptionAr: data.arabicDescription,
      buttonTextEn: data.btnEnglishText || data.btnEnglishTextSelection,
      buttonTextAr: data.btnArabicText || data.btnArabicTextSelection,
      buttonUrl: data.btnUrl, // url
      points: data.rewardedPoints || null,
      // minPoints: data.minRewardedPoints || null,
      // maxPoints: data.maxRewardedPoints || null,
      order: data.order || null,
      // totalPoints: data.totalPoints || null,
      // categoryId: data.categoryId,
      // estimatedTime: data.surveyCompletionTime || null,
      icon: iconUrl,
      thumbnail: imageUrl,
      isFeatured: data.isFeatured,
      targetType: data.targetType,
      users: fileUrl || null,
      maxUsage: data.maxUsage || null,
      // maxUsagePerUser: data.maxUsagePerUser || 1,
      startDate: dates.startDate,
      endDate: dates.endDate,
      sectionId: data.sectionId,
      type: data.missionTypeName,
      urlParams: data.urlParams || null,
      countryCode: countrySelected?.toUpperCase()
    })
    .then(() => {
      dispatch({
        type: SUBMIT_MISSION_DATA
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });

  return response;
};

export const clearMissionsState = () => {
  return {
    type: RESET_STATE
  };
};

export const editMissions = data => {
  return {
    type: EDIT_MISSIONS,
    payload: data
  };
};

export const submitMissionsEdits = (
  data,
  dates,
  imageUrl,
  fileUrl,
  iconUrl,
  countrySelected
) => async dispatch => {
  const body = {
    titleEn: data.englishTitle,
    titleAr: data.arabicTitle,
    descriptionEn: data.englishDescription,
    descriptionAr: data.arabicDescription,
    buttonTextEn: data.btnEnglishText || data.btnEnglishTextSelection,
    buttonTextAr: data.btnArabicText || data.btnArabicTextSelection,
    buttonUrl: data.btnUrl,
    points: data.rewardedPoints || null,
    // minPoints: data.minRewardedPoints || null,
    // maxPoints: data.maxRewardedPoints || null,
    order: data.order || null,
    // totalPoints: data.totalPoints || null,
    // categoryId: data.categoryId,
    // estimatedTime: data.surveyCompletionTime || null,
    icon: iconUrl,
    thumbnail: imageUrl,
    isFeatured: data.isFeatured,
    targetType: data.targetType,
    users: fileUrl || null,
    maxUsage: data.maxUsage || null,
    // maxUsagePerUser: data.maxUsagePerUser || 1,
    startDate: dates.startDate,
    endDate: dates.endDate,
    sectionId: data.sectionId,
    type: data.missionTypeName,
    urlParams: data.urlParams || null,
    countryCode: countrySelected?.toUpperCase()
  };
  // Check if imageUrl is not base64
  if (!imageUrl) {
    delete body.thumbnail;
  }

  await missionsBaseUrl
    .put(`/missions/${data.cardId}`, body)
    .then(() => {
      dispatch({
        type: EDIT_MISSIONS_DATA
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};
