import { UPDATE_ITEMS, UPDATE_ITEM } from "../../actions/list";

const initialState = {
  items: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ITEMS:
      return {
        ...state,
        items: action.payload.items
      };
    case UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id
            ? { ...item, ...action.payload.item }
            : item
        )
      };
    default:
      return state;
  }
};
