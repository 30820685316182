import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//redux
import { editAnnouncementCards } from "../../../redux/action/AnnouncementCards/CreateAnnouncementCardActions";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import GridContainer from "../../../components/Grid/GridContainer.js";

import Stepper from "../../Stepper";
import CardDefinition from "./CardDefinition";
import CallToActionComponent from "./CallToActionComponent";
import CardStyling from "./CardStylingComponent";
import CardTargeting from "./CardTargeting";
import CardExpirationLogic from "./CardExpirationLogic";
import ErrorMessage from "../../ErrorMessage";

import content from "./ContentConfig";
import styles from "../styles.css";

function Form({ redirect, location, editAnnouncementCards, errorMessage }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const viewType = location.state ? location.state.viewType : "";

  useEffect(() => {
    if (viewType === "Edit") {
      editAnnouncementCards(location.state.item);
    }
  }, [viewType]);

  return (
    <>
      <GridContainer justify="center">
        <Typography
          component="h1"
          variant="h4"
          color="textPrimary"
          className={classes.formHeader}
        >
          Add a new Announcement Card
        </Typography>
        <Stepper
          stepsTitle={content.stepsTitle}
          formType="announcement-cards"
          viewType={viewType}
        >
          <CardDefinition />
          <CallToActionComponent />
          <CardStyling />
          <CardTargeting />
          <CardExpirationLogic />
        </Stepper>
      </GridContainer>
      {errorMessage && <ErrorMessage message="Sorry something went wrong!" />}
      {redirect && <Redirect to={`/admin/AnnouncementCards`} />}
    </>
  );
}

const mapStateToProps = state => ({
  redirect: state.announcementCards.redirect,
  errorMessage: state.errorMessage.error
});

export default connect(
  mapStateToProps,
  { editAnnouncementCards }
)(Form);

Form.propTypes = {
  redirect: PropTypes.bool,
  location: PropTypes.object,
  editAnnouncementCards: PropTypes.func,
  errorMessage: PropTypes.bool
};
