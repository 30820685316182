import axios from "axios";

export const getBase64 = (file, cb) => {
  let reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = function(e) {
    var img = new Image();
    img.src = e.target.result;
    img.onload = function() {
      let getWidth = this.width;
      let getHeight = this.height;
      cb(reader.result, getWidth, getHeight);
    };
  };
  reader.onerror = function(error) {
    console.log("Error: ", error);
  };
};

export const convertURLToBase64 = (url, callback) => {
  axios.get(url, { responseType: "blob" }).then(function(response) {
    var reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onload = function() {
      callback(reader.result);
    };
  });
};
