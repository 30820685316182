import { announcementBaseUrl } from "../../../components/config";
import { FETCH_ANNOUNCEMENT_CARDS } from "../actionType";
import { toggleErrorMessage } from "../ErrorMessageActions";

export const fetchAnnouncementCards = () => async dispatch => {
  await announcementBaseUrl
    .get("/announcements")
    .then(res => {
      dispatch({
        type: FETCH_ANNOUNCEMENT_CARDS,
        payload: res.data
      });
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};

export const deleteAnnouncementCard = id => async dispatch => {
  await announcementBaseUrl
    .delete(`/announcements/${id}`)
    .then(() => {
      dispatch(fetchAnnouncementCards());
    })
    .catch(() => {
      dispatch(toggleErrorMessage());
    });
};
