import * as redemptionService from "../../../../services/redemption";

export const GET_OPTIONS = "get options";
export const DELETE_OPTION = "delete option";
export const UPDATE_OPTION_DENOMINATION_STATUS =
  "update option denomination status";

export const getOptions = (
  country,
  page,
  size,
  query,
  productId,
  cost,
  provider,
  category,
  types
) => dispatch =>
  redemptionService
    .getOptions(
      country,
      page,
      size,
      query,
      productId,
      cost,
      provider,
      category,
      types
    )
    .then(data => {
      dispatch({
        type: GET_OPTIONS,
        payload: data
      });
    });

export const deleteOption = option => dispatch =>
  redemptionService.deleteOption(option).then(data => {
    dispatch({
      type: DELETE_OPTION,
      payload: { option }
    });
  });

export const updateOptionDenominationStatus = (
  option,
  denomination,
  status
) => dispatch =>
  redemptionService
    .updateOptionDenominationStatus(option, denomination, status)
    .then(data => {
      dispatch({
        type: UPDATE_OPTION_DENOMINATION_STATUS,
        payload: {
          option,
          denomination,
          status
        }
      });
    });
