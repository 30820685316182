export function updateRecord(arr, index, key, value) {
  let newArr = [...arr]; // copying the old datas array
  newArr[index][key] = value;
  return newArr;
}

export function flatten(data, outputArray) {
  data.map(item => {
    if (Array.isArray(item.items)) {
      flatten(item.items, outputArray);
    } else {
      let user = {
        phoneNumber: item.phoneNumber,
        operator: item.operator,
        price: parseFloat(item.transactionValue)
      };
      outputArray.push(user);
    }
  });
}

export function getPage(arr, pageNum = 1) {
  const getItem = arr.filter(item => item.pageNum === pageNum);
  return getItem[0] || [];
}
