import React, { useState, useEffect, lazy, Suspense } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

/// Action
import { fetchBatchesList } from "../../../redux/action/WaslaRewards/BatchesAction";
import { fetchExportFileUrl } from "../../../redux/action/WaslaRewards/FetchExportFIleUrlAction";
import { openSnackBar } from "../../../redux/action/SnackBarAction";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Skeleton from "@material-ui/lab/Skeleton";

// import Table from "../../Table/Table";
import ModalContainer from "./ModalContainer";
import styles from "./styles.css";
import CustomizedSnackBars from "../SnackBar/SnackBar";

const Table = lazy(() => import("../../Table/Table"));

const useStyles = makeStyles(styles);

const BatchingHistory = props => {
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();
  const {
    availableError,
    fetchBatchesList,
    batchesList,
    fetchExportFileUrl,
    isLoading,
    hasNext,
    openSnackBar
  } = props;

  const project = location?.state;
  const [batches, setBatches] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [exportProgress, setExportProgress] = useState("");
  const [customStyle, setCustomStyle] = useState({
    index: null,
    disableStyle: null,
    enableStyle: classes.enableRow
  });
  const [pageNum, setPageNum] = useState(1);

  if (availableError) {
    throw new Error();
  }

  useEffect(() => {
    if (project?.id && pageNum >= 1) {
      fetchBatchesList(project?.id, pageNum);
    }
  }, [project, pageNum]);

  const checkStatus = item => {
    switch (item) {
      case "completed":
        return (
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.completedType}
          >
            Completed
          </Typography>
        );
      case "progress":
        return (
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.pendingType}
          >
            In Progress
          </Typography>
        );
      case "review":
        return (
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.reviewType}
          >
            Review
          </Typography>
        );
      default:
        return <p></p>;
    }
  };

  const [openExport, setOpenExport] = useState(null);
  const [openExportDrop, setOpenExportDrop] = useState(false);
  const handleOpenExport = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenExport(index);
    setOpenExportDrop(!openExportDrop);
  };
  const retrieveBatchesList = () => {
    const result = batchesList.map((item, index) => {
      const parasDate = Moment(item.date).format("DD/MM/YYYY");
      const exportPdf = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "155px",
            margin: "0 auto"
          }}
          key={index}
        >
          <Button
            onClick={e => handleOpenExport(e, item.id)}
            style={{
              backgroundColor:
                item.id === openExport &&
                openExportDrop &&
                "rgba(0, 0, 0, 0.04)"
            }}
          >
            <VerticalAlignBottomIcon />
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              border: "1px solid transparent",
              backgroundColor: "#fff",
              zIndex: 1,
              marginTop: "33px"
            }}
          >
            <Button
              onClick={e => handleDownloadPdf(e, "pdf", item.id)}
              download
              style={{
                color: "#000"
              }}
              style={{
                display:
                  item.id === openExport && openExportDrop ? "block" : "none"
              }}
            >
              Export Batch - PDF
            </Button>
            <Button
              onClick={e => handleDownloadPdf(e, "csv", item.id)}
              download
              style={{
                color: "#000"
              }}
              style={{
                display:
                  item.id === openExport && openExportDrop ? "block" : "none"
              }}
            >
              Export Batch - CSV
            </Button>
          </div>
        </div>
      );
      // if (item.status === "review") {
      //   setCustomStyle({
      //     index,
      //     disableStyle: classes.disabledRow,
      //     enableStyle: classes.enableRow
      //   });
      // }
      const statusElement = checkStatus(item.status);
      const totalTransaction =
        item.failedTransactionsCount + item.successTransactionsCount;
      const transactionCompleted = `${item.successTransactionsCount} / ${totalTransaction}`;
      return [
        `Batch-${item.id}`,
        parasDate,
        statusElement,
        transactionCompleted,
        exportPdf
      ];
    });
    setBatches(result);
  };

  useEffect(() => {
    retrieveBatchesList();
  }, [batchesList, openExport, openExportDrop]);

  const handleRouting = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    const item = batchesList[index];
    const storeData = {
      projectId: project?.id,
      batchId: item?.id,
      batchStatus: item?.status,
      projectName: project?.name,
      integrationType: project?.integrationType
    };
    history.push(`${location.pathname}/batch/${item.id}`, storeData);
  };

  const handleDownloadPdf = (e, type, id) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenExport(null);
    setOpenExportDrop(!openExportDrop);
    const item = batchesList[batchesList.findIndex(item => item.id === id)];
    if (item?.status?.toLowerCase() === "progress") {
      openSnackBar();
      setExportProgress(
        "you can’t export this batch as the status is still in progress "
      );
      return;
    }
    fetchExportFileUrl(type, null, id);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleNext = () => {
    if (hasNext) {
      setPageNum(pageNum + 1);
    }
  };

  const handlePrevious = () => {
    if (pageNum !== 1) {
      setPageNum(pageNum - 1);
    }
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <h1 className={classes.title} aria-label="project name">
            Project Name:{" "}
            <span style={{ fontWeight: 400, fontSize: "22px" }}>
              {project?.name}
            </span>
          </h1>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={`${classes.defaultButton}`}
            onClick={() => handleOpenModal()}
            style={{
              backgroundColor: "#fff",
              color: "#000",
              border: "1px solid transparent"
            }}
          >
            <CloudUploadIcon style={{ marginRight: 15 }} />
            <span>Manual Recharge</span>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.tableContainer}>
            <Suspense
              fallback={
                <Skeleton
                  animation="wave"
                  variant="rect"
                  aria-label="loading"
                />
              }
            >
              <Table
                tableHead={[
                  "Batch",
                  "Date",
                  "Status",
                  "Transactions Completed",
                  "Export"
                ]}
                tableData={batches}
                handleRouting={handleRouting}
                customStyle={customStyle}
              />
            </Suspense>
            {!isLoading && batchesList.length === 0 && (
              <Typography
                component="p"
                variant="subtitle1"
                align="center"
                gutterBottom={true}
                style={{ width: "100%", padding: "1rem 0" }}
                aria-label="is empty"
              >
                There is no Batches yet.
              </Typography>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "space-between",
                justifyContent: "space-between",
                padding: "1em"
              }}
            >
              <Button
                type="button"
                onClick={handlePrevious}
                disabled={pageNum === 1}
                color="primary"
                className={`${classes.defaultButton} ${pageNum !== 1 &&
                  classes.primaryButton}`}
              >
                Previous
              </Button>
              <Button
                type="button"
                onClick={handleNext}
                color="primary"
                disabled={!hasNext}
                className={`${classes.defaultButton} ${hasNext &&
                  classes.primaryButton}`}
              >
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {openModal && (
        <ModalContainer open={openModal} handleClose={handleCloseModal} />
      )}
      {exportProgress && (
        <CustomizedSnackBars
          severity={"error"}
          messege={exportProgress}
          errorMsg={!!exportProgress}
          restMessage={() => setExportProgress("")}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    availableError: state.availableError.availableError,
    batchesList: state.batches.list,
    isLoading: state.batches.isLoading,
    hasNext: state.batches.hasNext
  };
};

export default connect(
  mapStateToProps,
  { fetchBatchesList, fetchExportFileUrl, openSnackBar }
)(BatchingHistory);

BatchingHistory.propTypes = {
  availableError: PropTypes.bool,
  hasNext: PropTypes.bool,
  fetchBatchesList: PropTypes.func,
  fetchExportFileUrl: PropTypes.func,
  openSnackBar: PropTypes.func,
  batchesList: PropTypes.arrayOf(PropTypes.object)
};
