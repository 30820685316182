export const UPDATE_OLD_TRANSACTION_VALUE =
  "update local cashbacks conversion edit request old transaction value";
export const UPDATE_OLD_COMMISSION =
  "update local cashbacks conversion edit request old commission";
export const UPDATE_OLD_CASHBACK =
  "update local cashbacks conversion edit request old cashback";
export const UPDATE_NEW_TRANSACTION_VALUE =
  "update local cashbacks conversion edit request new transaction value";
export const UPDATE_NEW_COMMISSION =
  "update local cashbacks conversion edit request new commission";
export const UPDATE_NEW_CASHBACK =
  "update local cashbacks conversion edit request new cashback";
export const UPDATE_STATUS =
  "update local cashbacks conversion edit request status";
export const UPDATE_REASON =
  "update local cashbacks conversion edit request reason";
export const UPDATE_NOTES =
  "update local cashbacks conversion edit request notes";

export const updateOldTransactionValue = oldTransactionValue => dispatch =>
  dispatch({
    type: UPDATE_OLD_TRANSACTION_VALUE,
    payload: { oldTransactionValue }
  });

export const updateOldCommission = oldCommission => dispatch =>
  dispatch({
    type: UPDATE_OLD_COMMISSION,
    payload: { oldCommission }
  });

export const updateOldCashback = oldCashback => dispatch =>
  dispatch({
    type: UPDATE_OLD_CASHBACK,
    payload: { oldCashback }
  });

export const updateNewTransactionValue = newTransactionValue => dispatch =>
  dispatch({
    type: UPDATE_NEW_TRANSACTION_VALUE,
    payload: { newTransactionValue }
  });

export const updateNewCommission = newCommission => dispatch =>
  dispatch({
    type: UPDATE_NEW_COMMISSION,
    payload: { newCommission }
  });

export const updateNewCashback = newCashback => dispatch =>
  dispatch({
    type: UPDATE_NEW_CASHBACK,
    payload: { newCashback }
  });

export const updateStatus = status => dispatch =>
  dispatch({
    type: UPDATE_STATUS,
    payload: { status }
  });

export const updateReason = reason => dispatch =>
  dispatch({
    type: UPDATE_REASON,
    payload: { reason }
  });

export const updateNotes = notes => dispatch =>
  dispatch({
    type: UPDATE_NOTES,
    payload: { notes }
  });
