import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

// redux actions
import {
  UpdatePointsConfig,
  submitUpdatePointsConfig
} from "../../../../redux/action/Configuration/PointsConfig/pointConfigAction";
// core components
// import Moment from "moment";

// import Table from "components/Table/Table.js";
import CardBody from "../../../../components/Card/CardBody.js";
// import ConfirmModal from "../Modals/ConfirmModal";
import ConfirmModal from "../../../Modals/ConfirmModal";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";

import styles from "../../styles.css";

const useStyles = makeStyles(styles);

const ConfigPointUpdate = props => {
  const classes = useStyles();
  const {
    location,
    MinAdsReserveLimit,
    UpdatePointsConfig,
    RewardValues,
    submitUpdatePointsConfig
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  // const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (location.state.viewType === "Edit") {
      UpdatePointsConfig(location.state && location.state.item);
    }
  }, [UpdatePointsConfig]);

  const handleChange = event => {
    if (event.target.name === "MinAdsReserveLimit") {
      UpdatePointsConfig({
        RewardValues,
        [event.target.name]: event.target.value
      });
    }
  };
  const handleChangeReward = e => {
    let findeItem = RewardValues.findIndex(
      item => item.event === e.target.name
    );
    RewardValues[findeItem].rewardPoints = Number(e.target.value);

    UpdatePointsConfig({
      MinAdsReserveLimit,
      RewardValues
    });
  };
  const handleSubmit = () => {
    submitUpdatePointsConfig(RewardValues);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Points config</h4>
          </CardHeader>
          <CardBody>
            <Grid>
              {RewardValues.length !== 0 &&
                RewardValues.map((item, index) => {
                  return (
                    <TextField
                      key={index}
                      id={item.event}
                      name={item.event}
                      label={item.event.replace("_", " ")}
                      defaultValue={item.rewardPoints}
                      className={classes.textField}
                      onChange={e => handleChangeReward(e, index)}
                      margin="normal"
                      variant="outlined"
                      type="number"
                      // error={errorEstimated}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  );
                })}
              <TextField
                id="MinAdsReserveLimit"
                name="MinAdsReserveLimit"
                label="Min Ads Reserve Limit"
                value={MinAdsReserveLimit}
                className={classes.textField}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                type="number"
                // error={errorEstimated}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              style={{
                width: "10%",
                margin: "16px"
              }}
              onClick={handleOpen}
            >
              save
            </Button>
            {/* <>
              {(error || messageErr) && (
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="error"
                  align="center"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  {messageErr || handleError()}
                </Typography>
              )}
            </> */}
          </CardBody>
        </Card>
      </GridItem>
      {/* {redirect ? (
        <Redirect
          to={{
            pathname: `/admin/PointsSystem/adsSystem/${location.state.adsItem.id}`,
            state: location.state.adsItem
          }}
        />
      ) : null} */}
      {openModal && (
        <ConfirmModal
          open={openModal}
          handleEdit={handleSubmit}
          onClose={handleClose}
          type="edit"
        />
      )}
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    MinAdsReserveLimit: state.configItemsUpdate.MinAdsReserveLimit,
    RewardValues: state.configItemsUpdate.RewardValues
  };
};

export default connect(
  mapStateToProps,
  { UpdatePointsConfig, submitUpdatePointsConfig }
)(ConfigPointUpdate);

ConfigPointUpdate.propTypes = {};
