import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// redux actions
import { setCountry } from "./actions";
// core components
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const SelectCountry = ({ list, setCountry, countryIdSelected, width }) => {
  const classes = useStyles();

  const handleSelection = event => {
    const selectedValue = event.target.value;
    // Send selected category name to the reducer
    const currentCountry = list.find(item => item.id === selectedValue);

    const countryName = selectedValue !== "none" ? currentCountry.country : "";
    setCountry(selectedValue, countryName);
  };

  return (
    <>
      <TextField
        id="Country-name"
        select
        label="Country"
        className={classes.textField}
        name="countryId"
        value={countryIdSelected}
        onChange={handleSelection}
        style={{ width: `${width}%` }}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="outlined"
        required
      >
        {/* <option value="none">Select an Option</option> */}
        {list.map(option => (
          <option key={option.id} value={option.id}>
            {option.country}
          </option>
        ))}
      </TextField>
    </>
  );
};

const mapStateToProps = state => {
  return {
    list: state.Countries.list,
    countryNameSelected: state.Countries.countryNameSelected,
    countryIdSelected: state.Countries.countryIdSelected
  };
};

export default connect(
  mapStateToProps,
  { setCountry }
)(SelectCountry);

SelectCountry.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object)
};
