import { isDev, authURL } from "../../components/config";

export const fetchAccessToken = () => async dispatch => {
  if (!isDev) {
    var rawUrl = window.location.href;
    let tokenTag = "access_token=";

    if (rawUrl.includes(tokenTag)) {
      let urlEndIndex = rawUrl.indexOf("?");
      let tokenIndex = rawUrl.indexOf(tokenTag) + tokenTag.length;
      var token = rawUrl.substring(tokenIndex);
      document.cookie = "token=" + token;
      window.location.assign(rawUrl.substring(0, urlEndIndex));
    } else {
      if (!document.cookie.includes("token=")) window.location.assign(authURL);
    }
  }
};
