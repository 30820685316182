import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// redux actions
import { clearAnnouncementCardsState } from "../../../redux/action/AnnouncementCards/CreateAnnouncementCardActions";
// core components
import {
  fetchAnnouncementCards,
  deleteAnnouncementCard
} from "../../../redux/action/AnnouncementCards/AnnouncementListViewActions";
import { checkRequiredFields } from "../../../redux/action/checkRequiredFieldsActions";

// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import AnnouncementCard from "../AnnouncementCard";
import ActionButtons from "../../ActionButtons/ActionButtons";
import ErrorMessage from "../../ErrorMessage";

import styles from "../styles.css";

const useStyles = makeStyles(styles);

const CardsListView = props => {
  const classes = useStyles();

  useEffect(() => {
    props.fetchAnnouncementCards();
    props.clearAnnouncementCardsState();
    props.checkRequiredFields(false);
  }, []);

  return (
    <>
      <GridContainer>
        <div className={classes.cardLinkContainer}>
          <Link
            to={{
              pathname: `${props.match.path}/form`,
              state: { viewType: "Add" }
            }}
            className={classes.cardLinkAdd}
          >
            Add Announcement Card
          </Link>
        </div>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Announcement Cards</h4>
            </CardHeader>
          </Card>
        </GridItem>
        {props.announcementCardsList.length === 0 ? (
          <Typography
            component="p"
            variant="subtitle1"
            align="center"
            gutterBottom={true}
            style={{ width: "100%" }}
          >
            No Announcement Cards added yet!
          </Typography>
        ) : (
          props.announcementCardsList.map(card => {
            return (
              <GridItem xs={12} sm={12} md={6} key={card._id || card.id}>
                <div className={classes.customContainer}>
                  <Link
                    to={{
                      pathname: `${props.match.path}/details/${card._id ||
                        card.id}`,
                      state: { item: card }
                    }}
                    style={{ display: "block", width: " 100%" }}
                  >
                    <AnnouncementCard
                      title={card.title.en}
                      body={card.body.en}
                      btnText={card.btn.text ? card.btn.text.en : ""}
                      imgUrl={card.imgUrl}
                      bgPrimaryColor={card.theme.startBgColor}
                      bgSecondaryColor={card.theme.endBgColor}
                      textColor={card.theme.textColor}
                      btnBgColor={card.theme.btnBgColor}
                      btnTextColor={card.theme.btnTextColor}
                      className="announcementCardContainer"
                    />
                  </Link>
                  <ActionButtons
                    item={card}
                    viewType="Edit"
                    deleteButton={true}
                    editButton={true}
                    handleDelete={props.deleteAnnouncementCard}
                  />
                </div>
              </GridItem>
            );
          })
        )}
      </GridContainer>
      {props.errorMessage && (
        <ErrorMessage message="Sorry something went wrong!" />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    announcementCardsList: state.announcementCardsList.announcementCardsList,
    errorMessage: state.errorMessage.error
  };
};

export default connect(
  mapStateToProps,
  {
    clearAnnouncementCardsState,
    fetchAnnouncementCards,
    deleteAnnouncementCard,
    checkRequiredFields
  }
)(CardsListView);

CardsListView.propTypes = {
  match: PropTypes.object,
  announcementCardsList: PropTypes.arrayOf(PropTypes.object),
  clearAnnouncementCardsState: PropTypes.func,
  fetchAnnouncementCards: PropTypes.func,
  deleteAnnouncementCard: PropTypes.func,
  checkRequiredFields: PropTypes.func,
  errorMessage: PropTypes.bool
};
