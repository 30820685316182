import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useLocation } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  resetUploadRechargeState,
  submitUploadUsers
} from "../../../redux/action/WaslaRewards/uploadRechargeActions";

// core components
import Stepper from "../../Stepper/refactorStepper";

import UploadStep from "./uploadStep";
import ConfirmOrder from "./confirmOrder";
import styles from "./styles.css";
import Warning from "../../../assets/img/Warning.1.svg";
import Success from "../../../assets/img/success.svg";
import ResponseModal from "../RechargeUsersModal/RechargeSteps/ResponseModal";

const stepsTitle = ["Upload CSV", "Confirm Order"];

const useStyles = makeStyles(styles);

function UploadSteps(props) {
  const classes = useStyles();
  const location = useLocation();

  const [progressBar, setProgressBar] = useState(false);
  const {
    isLoading,
    error,
    onCloseModal,
    submitUploadUsers,
    success,
    resetUploadRechargeState,
    uploadUsersRecharge
  } = props;

  useEffect(() => {
    const enableClass = document.querySelector(".MuiStep-completed");
    setProgressBar(!!enableClass);
  }, [progressBar]);

  useEffect(() => {
    return () => {
      resetUploadRechargeState();
    };
  }, []);
  const handleSubmit = () => {
    const projectId = location?.state?.id;

    submitUploadUsers(
      projectId,
      uploadUsersRecharge,
      location?.state?.integrationType
    );
  };
  return (
    <div className={classes.containerSteps}>
      <Stepper stepsTitle={stepsTitle} handleSubmit={handleSubmit}>
        <UploadStep />
        <ConfirmOrder changeProgressValue={setProgressBar} />
      </Stepper>
      <LinearProgress variant="determinate" value={progressBar ? 100 : 50} />
      {isLoading && (
        <div className={classes.containerLoading} aria-label="loading">
          <CircularProgress />
        </div>
      )}
      {error && (
        <ResponseModal
          image={Warning}
          onCloseModal={onCloseModal}
          error={error}
        />
      )}
      {success && (
        <ResponseModal
          image={Success}
          onCloseModal={onCloseModal}
          success={success}
        />
      )}
    </div>
  );
}
// UploadRechargeList
const mapStateToProps = state => ({
  isLoading: state.UploadUserRecharge.isLoading,
  error: state.UploadUserRecharge.error,
  success: state.UploadUserRecharge.success,
  uploadUsersRecharge: state.UploadUserRecharge.list
});

export default connect(
  mapStateToProps,
  {
    submitUploadUsers,
    resetUploadRechargeState
  }
)(UploadSteps);

UploadSteps.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  onCloseModal: PropTypes.func,
  submitUploadUsers: PropTypes.func,
  resetUploadRechargeState: PropTypes.func,
  uploadUsersRecharge: PropTypes.arrayOf(PropTypes.object)
};
