import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// redux actions
import {
  setSpecificCSVFile,
  setFileName,
  saveFileAsArray,
  resetFile
} from "../../redux/action/uploadFileActions";
// core components
import { convertToBase64 } from "./helper";
import styles from "./styles.css";

const useStyles = makeStyles(styles);

const FileUpload = ({
  fileName,
  setSpecificCSVFile,
  setFileName,
  saveFileAsArray,
  resetFile,
  fileType
}) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [errorSize, setErrorSize] = React.useState(false);

  const handleSpecificCSVFile = event => {
    if (event.target.files.length === 0) return;
    if (/\.(csv)$/i.test(event.target.files[0].name)) {
      let fileSize = Math.round(event.target.files[0].size / 1024);
      if (fileSize <= 2000) {
        setError(false);
        setErrorSize(false);
        setFileName(event.target.files[0].name);
        if (fileType === "convertToText") {
          let reader = new FileReader();
          reader.onload = event => saveFileAsArray(event.target.result);
          reader.readAsText(event.target.files[0]);
        } else {
          convertToBase64(event.target.files[0], result => {
            setSpecificCSVFile(result);
          });
        }
      } else {
        setErrorSize(true);
        setError(false);
      }
    } else {
      setError(true);
      setErrorSize(false);
    }
  };

  useEffect(() => {
    if (errorSize || error) {
      resetFile();
    }
  }, [errorSize, error]);

  useEffect(() => {
    resetFile();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.fileFieldWrapper}>
        <TextField
          type="file"
          id="specific-csv"
          label="CSV file"
          name="specificCSV"
          onChange={handleSpecificCSVFile}
          margin="normal"
          variant="outlined"
          style={{
            display: "none"
          }}
          className={classes.textFieldFile}
        />
        <label htmlFor="specific-csv" className={classes.textFieldFile}>
          <CloudUploadIcon />
          <span>Upload CSV file</span>
        </label>
        <div className={classes.fileViewContainer}>
          {!fileName && (
            <Typography
              component="p"
              variant="body2"
              display="block"
              color="textSecondary"
            >
              Upload a .csv file with max size of 2MB
            </Typography>
          )}
          {fileName && (
            <Typography component="p" variant="body2" display="block">
              {fileName}
            </Typography>
          )}
        </div>
      </div>
      {error && !errorSize && (
        <Typography
          component="p"
          variant="body2"
          color="error"
          display="block"
          style={{ marginLeft: "48px" }}
        >
          Upload a .csv file only
        </Typography>
      )}
      {errorSize && !error && (
        <Typography
          component="p"
          variant="body2"
          color="error"
          display="block"
          style={{ marginLeft: "48px", marginTop: "10px" }}
        >
          Upload a .csv with max size of 2MB
        </Typography>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    fileName: state.uploadFile.fileName
  };
};

export default connect(
  mapStateToProps,
  {
    setSpecificCSVFile,
    setFileName,
    saveFileAsArray,
    resetFile
  }
)(FileUpload);

FileUpload.propTypes = {
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  setSpecificCSVFile: PropTypes.func,
  setFileName: PropTypes.func,
  saveFileAsArray: PropTypes.func,
  resetFile: PropTypes.func
};
