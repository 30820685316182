import Categories from "./Categories";
import { CategoriesPath } from "./paths";
import addNEditConfig from "./add-n-edit/addNEditConfig";

export default {
  routes: [
    {
      path: CategoriesPath,
      component: Categories,
      layout: "main",
      isPrivate: false,
      roles: ["admin"],
      routes: [...addNEditConfig.routes]
    }
  ]
};
